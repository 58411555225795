
<div class="mt fl aic">
    <div class="f1">
        <h3>Customer Discount Management</h3>
    </div>
    <div class="f1">
        <button class="mr" type="button" mat-mini-fab aria-label="Save Discounts" color="primary"
            title="Save Discounts" (click)="startSave()"
            [disabled]="!discountsForm || !discountsForm.valid || discountsForm.pristine">
            <mat-icon>save</mat-icon>
        </button>
    </div>
    <div class="mr">
        <mat-slide-toggle color="primary" [(ngModel)]="allcustomers" ngDefaultControl (change)="setCustomer()">
            <span>All Customers</span>
        </mat-slide-toggle>
    </div>
    <div>
        <cs-company-search *ngIf="!allcustomers" [iscustomers]="true" [host]="'discounts'"></cs-company-search>
    </div>
</div>
<form>

 
<div *ngIf="discountsForm" [formGroup]="discountsForm">
<div class="fl aic" [formGroup]="minMargin">
    <mat-slide-toggle color="primary" [(ngModel)]="setminimum" [ngModelOptions]="{standalone: true}" ngDefaultControl class="mr">
        <span>Set minimum {{this.customer?this.customer.name:'global'}} quotation margin</span>
    </mat-slide-toggle>
    <mat-form-field class="form-skinny mr" *ngIf="setminimum">
        <mat-label>Fixed £</mat-label>
        <input matInput placeholder="Cash Margin" type="number" formControlName="fixed" autocomplete="new-fixed-discount">
    </mat-form-field>
    <mat-form-field class="form-skinny mr" *ngIf="setminimum">
        <mat-label>Percent</mat-label>
        <input matInput placeholder="Percent Margin" type="number" formControlName="percent" autocomplete="new-percent-discount">
    </mat-form-field>
</div>

<div class="f1" formArrayName="discountsArray">
    <div>
        <div class="margin-section" *ngFor="let discountgroup of discountsArray.controls">
            <cs-discount [discountgroup]="discountgroup" [currencies]="data.currencies">
            </cs-discount>
        </div>
    </div>
</div>

</div>
</form> 