<div>
    <h3>{{title}} Margin</h3>
</div>
<div class="fl aie" [formGroup]="margingroup">
    <mat-form-field class="form-skinny mr">
        <mat-label>Fixed Margin</mat-label>
        <input matInput placeholder="Cash Margin" type="number" formControlName="fixed" autocomplete="new-fixed-margin">
    </mat-form-field>
    <mat-form-field class="mr form-currency">
        <mat-label>Currency</mat-label>
        <mat-select  formControlName="currency">
            <mat-option *ngFor="let country of currencies | withCurrency" [value]="country.id">
                {{country.currencySymbol}} <img class="flag"
                    src="../../../assets/flags/{{country.flag}}.png" />{{country.currencyCode}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="form-currency mr">
        <mat-label>% Markup</mat-label>
        <input matInput placeholder="% Margin" type="number" formControlName="percent" autocomplete="new-percent-margin">
    </mat-form-field>
    <mat-form-field class="mr form-skinny">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker"  formControlName="start">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="mr form-skinny">
        <mat-label>Expiry Date</mat-label>
        <input matInput [matDatepicker]="picker2"  formControlName="expires">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-icon class="pointer mr {{history?'active':''}}" color="{{history?'accent':'primary'}}" (click)="history=!history">history</mat-icon>
   </div>
   <div *ngIf="history">
       <span class="note">no history</span>
   </div>
