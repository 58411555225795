import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreData, ModuleUser } from '../models/models';
import { User, StatusMessage, ChangePasswordModel } from '../models/user';
import { ToolsService } from './tools.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public core:CoreData = new CoreData();
  public user: User;
  public demo = false;
  public sideon = true;
  public signedin = false;

  public logo = "";
  public sitename = "White Label";

  public currentroute = "Dashboard";
  

  public userReady: EventEmitter<boolean> = new EventEmitter();
  
  constructor(public http: HttpClient, public tools: ToolsService, private router:Router) {
    this.loadUser();

  }

  //load local user from store and check the access token is still valid
  loadUser(){
    this.tools.fromStore("cs-user").then((user: User) => {
      this.user = user;
      if (user) {
        this.checkTokenExpiry().then((msg:StatusMessage) => {
          
          if(!msg.success){
            let olduser = new User();
            olduser.email = this.user.email;
            this.user = olduser;
            /*
            let url = this.router.url.toString();
            
            let invite = url.indexOf("invite");
            if(invite>0){
              this.router.navigate([this.router.url]);
            }
            else{
              this.router.navigate(['/']);
            }*/
            
            this.core.user = false;
            this.tools.gracefulError(msg.message);
          }
          else{
            this.user = this.resequenceModules(this.user);
            this.tools.toStore("cs-user", this.user);
            this.core.user = true;
            this.userReady.emit();
          }
          
        });
      }
      else {
        this.user = new User();
        this.core.user = true;
      }
      
    },err=>{
      this.tools.gracefulError(err);
    })

  }

  //set the request header. This could be done with interceptors but tbh they're practically impossible to unit test.
  header() {
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (this.user && this.user.token) {
      httpOptions.headers = httpOptions.headers.append('Authorization', this.user.token);
    }
    else {
      httpOptions.headers = httpOptions.headers.append('Authorization', "");
    }
    return httpOptions;
  }
  headerImage() {
    let httpOptions = { headers: new HttpHeaders() };
    if (this.user && this.user.token) {
      httpOptions.headers = httpOptions.headers.append('Authorization', this.user.token);
    }
    else {
      httpOptions.headers = httpOptions.headers.append('Authorization', "");
    }
    return httpOptions;
  }


  //check whether the current user's token is valid.
  public checkTokenExpiry() {
    return new Promise((resolve, reject) => {
      //only check if one exists
      if (this.user && this.user.token && this.user.token.length > 0) {
        //check the token's expiry date to avoid unnecessary server calls
        let original_token = this.user.token;
        let token = this.tools.decodeToken();
        let expires = moment.unix(token.exp);
        //TODO console.warn("token decode is invalid");  
        if (expires < moment()) {
            
            this.user.token = "";
            resolve({ success: false, message: "Your session has expired, please login again." });
          }
          else {
            //check the server still thinks the token is ok and refresh the user (useful for settings, roles or module access changes)
            //clear the token if it's not still valid
            this.http.get(environment.host + 'auth/check', this.header()).subscribe((result: StatusMessage) => {
              
              if (result.success && result.message) {
                this.user = result.message;
                this.user = this.resequenceModules(this.user);
                this.user.token = original_token;
                if([10,11,12,14,137].indexOf(this.user.Site.companyid)>=0){
                  this.demo = true;
                }
                resolve(result);
              }
              else {
                console.warn("reset token in checkTokenExpiry 2");
                this.user.token = "";
                resolve({ success: false, message: "Your session has expired, please login again." });
              }
            }, err => {
              this.user.token = "";
              resolve({ success: false, message: err });
            });
          }


      }
      else resolve({ success: false, message: "No active session" });

    });
  }

  register(user: User) {
    return this.http.post(environment.host + 'auth/signup', user, this.header());
  }
  signin() {
    return new Promise((resolve,reject)=>{
      this.http.post(environment.host + 'auth/signin', this.user, this.header()).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.user = message.message;
          this.user = this.resequenceModules(this.user);
          this.tools.toStore("cs-user", this.user);
          this.core.user = true;
          if([10,11,12,137].indexOf(this.user.Site.companyid)>=0){
            this.demo = true;
          }
          this.userReady.emit();
          resolve(message);
        }
        else{
          resolve(message);
        }
        
      },err=>{
        reject({success:false,message:err});
      })
    }) 
  }
  changePass(model:ChangePasswordModel) {
    return this.http.post(environment.host + 'auth/changepass', model, this.header());
  }
  signout() {
    console.warn("reset token in signout");
    this.user.token = "";
    this.sitename = "";
    this.tools.toStore("cs-user", this.user);
  }
  //testing purposes only
  deleteTestUser() {
    return this.http.get(environment.host + 'auth/cleanup1', this.header());
  }

  modulePermission(module:string){
    let exists = this.user.ModuleUsers.filter(mu=>mu.Module.name==module);
    if(exists.length>0) return exists[0];
    else return new ModuleUser();
  }
  resequenceModules(user:User):User{
    user.ModuleUsers = this.user.ModuleUsers.sort((mu1,mu2)=>{
      if(mu1.sequence>mu2.sequence) return 1;
      else return -1;
    });
    return user;
  }
  killData(){
    return this.http.get(environment.host + 'services/cleardemodata', this.header());
  }

}
