import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TransportModes } from 'src/app/models/rates';
import { Country } from 'src/app/models/models';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-port-search',
  templateUrl: './port-search.component.html',
  styleUrls: ['./port-search.component.scss']
})
export class PortSearchComponent implements OnInit {

  selectedCountry:Country;
  selectedMode:TransportModes = TransportModes.Shipping;
  text:string="";

  public transportModes = TransportModes;
  
  constructor(public data:DataService) { }

  ngOnInit(): void {
  }
  search(){
    this.data.locodeSearch(this.selectedCountry.iso_alpha_2,[this.text],this.selectedMode).subscribe((message:StatusMessage)=>{
      console.log(message.message);
    })
  }
}
