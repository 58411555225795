<div class="f1 fl">
    <div class="f1">
        <h3>Rate Editor - {{view.name}}</h3>
        <div>
            <mat-form-field>
                <mat-label>Region</mat-label>
                <mat-select [(ngModel)]="selectedRegion" (selectionChange)="changeRegion($event)">
                    <mat-option *ngFor="let region of regions" [value]="region">
                        {{region.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Version</mat-label>
                <mat-select [(ngModel)]="selectedVersion" (selectionChange)="changeVersion($event)">
                    <mat-option *ngFor="let region of versions" [value]="region">
                        {{region.code}} - {{region.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Priority</mat-label>
                <mat-select [(ngModel)]="selectedPriority" (selectionChange)="changePriority($event)">
                    <mat-option *ngFor="let priority of data.priorities" [value]="priority">
                        {{priority.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button type="button" mat-mini-fab aria-label="Add Rate" color="primary" title="Add Rate"
                    (click)="addRate()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <div *ngIf="rate" class="fl mt rateform" [formGroup]="rateForm">
                <div class="f1">
                    <h3>Rate</h3>
                    <div class="fl tal ma mf-date formpadd">
                        <mat-form-field>
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="createdAt">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Expiry Date</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="expires">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="fl formpadd">
                        <mat-form-field class="">
                            <mat-label>Minimum Charge</mat-label>
                            <input matInput placeholder="Fixed Price" formControlName="baseprice">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Includes (kg)</mat-label>
                            <input matInput placeholder="Includes weigth" formControlName="baseIncludesWeight">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Includes (m<sup>3</sup>)</mat-label>
                            <input matInput placeholder="Includes weigth" formControlName="baseIncludesVolume">
                        </mat-form-field>
                    </div>

                    <div formArrayName="RateBreaks">
                        <h3>Breaks</h3>

                        <div class="mt" *ngFor="let pb of ratebreaks.controls;let p = index;">
                            <div [formGroupName]="p">
                                <p>Then for every subsequent: </p>
                                <div class="fl aic">
                                    <mat-form-field class="">
                                        <mat-label>kg (or fraction of)</mat-label>
                                        <input matInput placeholder="Weight Break" formControlName="weightbreak">
                                    </mat-form-field>
                                    <span>or</span>
                                    <mat-form-field class="">
                                        <mat-label>m<sup>3</sup> or fraction of</mat-label>
                                        <input matInput placeholder="Height Break" formControlName="volumebreak">
                                    </mat-form-field>
                                    <mat-form-field class="">
                                        <mat-label>Price per unit</mat-label>
                                        <input matInput placeholder="Price" formControlName="price">
                                    </mat-form-field>
                                </div>
                                <div class="fl aic">
                                    <div class="f1 aic"><span>Up to:</span>
                                        <mat-slide-toggle color="primary" formControlName="minormax"><span class="{{pb[p].get('minormax').value?'notactive':''}}">and including</span>
                                        </mat-slide-toggle>
                                    </div>
                                    <mat-form-field class="">
                                        <mat-label>kg</mat-label>
                                        <input matInput placeholder="Height Break" formControlName="maxweight">
                                    </mat-form-field>
                                    <mat-form-field class="">
                                        <mat-label>or m<sup>3</sup></mat-label>
                                        <input matInput placeholder="Price" formControlName="maxvolume">
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                        <div><button type="button" mat-mini-fab aria-label="Add Break" color="primary" title="Add Break"
                                (click)="addBreak()">
                                <mat-icon>add</mat-icon>
                            </button> </div>
                    </div>
                </div>
                <div class="f1" formArrayName="BandPremiums">
                    <h3>Band Premiums</h3>
                    <div *ngFor="let pb of bandpremiums.controls;let bp = index;">
                        <div [formGroupName]="bp" class="fl">
                        <mat-form-field class="">
                            <mat-label>{{bp==0?"Apply to All (%)":"Band "+bp+ " (%)"}}</mat-label>
                            <input matInput placeholder="Premium %" formControlName="premiumpercent" (change)="bandValueChange($event,0,bp)">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>{{bp==0?"Apply to All ("+currencyString+")":"Band "+bp+" ("+currencyString+")"}}</mat-label>
                            <input matInput placeholder="Premium £" formControlName="premiumvalue" (change)="bandValueChange($event,1,bp)">
                        </mat-form-field>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="f1">
        <h3>Rate Card</h3>
        <p>Based on weight or volume whichever is greater</p>
        <table *ngIf="selectedVersion && rateForm">
            <tr>
                <th class="tal">kg or m<sup>3</sup></th>
                <th *ngFor="let reg of selectedVersion.children;let i = index;">Region {{reg.code?reg.code:i}}</th>
            </tr>
            <tr>
                <td class="tal">0 - {{includesweight}}/0 - {{includesvolume}}</td>
                <td class="tac">{{minimumcharge | currency:'GBP'}}</td>
            </tr>

        </table>
    </div>
</div>