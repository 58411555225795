import { Component, OnInit, Input } from '@angular/core';
import { QuotationPrice } from 'src/app/models/rates';
import { PreferredPorts } from 'src/app/models/ui';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-pure-quote',
  templateUrl: './pure-quote.component.html',
  styleUrls: ['./pure-quote.component.scss']
})
export class PureQuoteComponent implements OnInit {

  @Input() portoption: PreferredPorts;
  @Input() currency: number;

  public currencyCode: string = "GBP";
  public combinedAdmin: QuotationPrice[] = [];
  public balanceAdmin: number = 0;

  constructor(public data: DataService) { }

  ngOnInit(): void {
    if (this.currency) {
      this.currencyCode = this.data.defaultCurrency.currencyCode;
    }
    if (this.portoption) {
      this.portoption.quotesurcharges.forEach(qs => {
        if (qs.include) {
          this.combinedAdmin.push(qs);
        }
        else {
          this.balanceAdmin += qs.discountedprice;
        }
      })
      if (this.portoption.balance) {
        this.balanceAdmin += this.portoption.balance.discountedprice;
      }
    }

  }

}
