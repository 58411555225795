<cs-quoter *ngIf="data.Company" [coid]="data.Company.id" [uselatest]="true"></cs-quoter>
<!--
<form>
    <div [formGroup]="quotationForm">
        <div>
            <mat-form-field class="form-skinny mr">
                <mat-label>Company Id</mat-label>
                <input matInput placeholder="Company Id" type="number" formControlName="companyid"
                    autocomplete="new-fixed-discount">
            </mat-form-field>
        </div>
        <div formArrayName="surchargeArray">
            <div *ngFor="let surch of surchargeArray.controls" [formGroup]="surch">
                <mat-form-field class="form-skinny mr">
                    <mat-label>Price</mat-label>
                    <input matInput placeholder="price" type="number" formControlName="price"
                        autocomplete="new-fixed-discount">
                </mat-form-field>
            </div>
        </div>
    </div>
    <p>dirty: {{quotationForm.dirty}} - valid: {{quotationForm.valid}}</p>
    <button (click)="save()">Save</button>
</form>
-->