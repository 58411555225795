export const environment = {
  production: false,
   host: 'https://app.freightology.io/api/',
   public: 'https://app.freightology.io/api/resources',
   googlemaps: 'AIzaSyC0S65lsXkTZLv6BowHKNmtPejnGrG8DaQ',
   run3rdParty: false,
   test3rdParty: false,
   fakeDriving: false,
   testDBread: false,
   testDBupdates: false,
   testUser: { email: "user@ideafoundry.uk", password: "Password1" },
   testCompanyId: 1

  
    /*host:'http://localhost:3010/api/',
    public:'http://localhost:3010/api/resources',
    googlemaps:'AIzaSyC0S65lsXkTZLv6BowHKNmtPejnGrG8DaQ',
    googleapi:'AIzaSyBvUe6_SWvSZQ-AKOy4O09v5MJPb3vFSDs',
    fakeDriving:false,
    run3rdParty:true,
    test3rdParty:true,
    testDBread:true,
    testDBupdates:false,
    testUser:{email:"demo2@ideafoundry.uk",password:"Password1"},
    testCompanyId:1*/
};
//'https://cargo.qualchain.org/api/
