import { Component, Input, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { View } from 'src/app/models/ui';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { CompanyAssociation, StatusMessage } from 'src/app/models/user';
import { Rate, RateEnvelope, Region, RatePriority, TransportModes, MarginAppliesTo, Margin, PriceBand, RegionTypes } from 'src/app/models/rates';
import { CompanySearchComponent } from 'src/app/widgets/company-search/company-search.component';
import { ToolsService } from 'src/app/services/tools.service';
import { FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { MatDialog } from '@angular/material/dialog';
import { EditPriorityComponent } from 'src/app/dialogs/edit-priority/edit-priority.component';
import { MatTabGroup } from '@angular/material/tabs';
import { ObjectifierComponent } from 'src/app/importers/objectifier/objectifier.component';
import moment from 'moment';

@Component({
  selector: 'cs-rate-editor-provider',
  templateUrl: './rate-editor-provider.component.html',
  styleUrls: ['./rate-editor-provider.component.scss']
})
export class RateEditorProviderComponent implements AfterViewInit {
  @Input() view: View;
  @ViewChildren('cosearch') cosearch: QueryList<CompanySearchComponent>;
  @ViewChildren('prioritiestab') tabs: QueryList<MatTabGroup>;
  @ViewChildren('objectifier') objectifier: QueryList<ObjectifierComponent>;

  public rate: Rate;
  public showimport = false;
  public showcard = false;
  public selectedEnvelope: RateEnvelope;
  public selectedMode: number = 0;
  public editmode = true;
  public selectedVersion: Region;
  public versions: Region[] = [];
  public bands: Region[] = [];

  public margin: Margin;// = [];

  public priorities: RatePriority[] = [];
  public selectedPriority: RatePriority;

  public transportmodes = TransportModes;



  public rateform: FormGroup;

  constructor(public data: DataService, public events: EventsService, public tools: ToolsService, public formtools: FormtoolsService, public dialog: MatDialog) {
    this.events.companyselect.subscribe((co: CompanyAssociation) => {
      if (!this.data.selectedSupplier || this.data.selectedSupplier.Provider.id != co.id) {
        this.data.selectedSupplier = co;
        this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites[0];
        this.changeProvider();

      }

    })

    this.rateform = this.formtools.createRateFormGroup();

  }

  ngAfterViewInit(): void {

    if (this.data.dataIsReady) {
      this.setup();
    }
    else this.data.dataReady.subscribe(() => {
      this.setup();
    })

  }
  setup() {

    this.getEverything().then(success => {
      if (success) {
        if (this.cosearch.first) {
          this.cosearch.first.setDisplayName(this.data.selectedSupplier.Provider.name);

        }
        if (this.tabs.first) {
          this.tabs.first.selectedIndex = 0;
          this.tabs.first.realignInkBar();
        }

      }
      if (this.objectifier.first) {
        this.objectifier.first.add.subscribe((rates: Rate[]) => {
          this.refreshRates(rates);
        })
      }

    });
  }
  /**
   * versions, regions, priorities, bands, rates
   */
  getEverything() {
    return new Promise(resolve => {
      this.data.listGlobalMarginsSpecific(MarginAppliesTo.Delivery_Rates).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.margin = message.message;
          this.data.listRateBracketsProvider(this.data.selectedSupplier.Provider.id).subscribe((message: StatusMessage) => {
            if (message.success) {
              this.data.ratebrackets = message.message;
              this.listVersions().then(success1 => {
                if (success1) {
                  this.getPriorities().then(success3 => {
                    if (success3) {
                      this.generatePriceBands();
                      this.getRates().then(success => {
                        if (success) resolve(true);
                        else resolve(false);
                      });
                    }
                    else {

                      resolve(false);
                    }
                  });
                  /*this.getRegions().then(success2 => {
                    if (success2) {
                      
                    }
                    else {
                      this.priorities = [];
                      resolve(false);
                    }
                  })*/

                }
                else resolve(false);
              });
            }
            else this.tools.gracefulError(message.message);
          })

        }
        else this.tools.gracefulError(message.message);

      }, err => {
        this.tools.gracefulError(err);
      })
    })

  }
  /**version has changed, reload everything */
  getNewVersionData() {
    return new Promise(resolve => {
      this.getPriorities().then(success3 => {
        if (success3) {
          this.generatePriceBands();
          this.getRates().then(success => {
            if (success) {
              resolve(true);
            }
            else resolve(false);
          });
        }
        else resolve(false);
      });

      /*this.getRegions().then(success2 => {
        if (success2) {
          
        }
        else resolve(false);
      })*/
    });
  }
  /**
   * priorities by companyid,regiontype, customerid
   */
  getPriorities() {
    return new Promise((resolve) => {
      let regiontype = RegionTypes.National;
      if (this.view.id == 1) regiontype = RegionTypes.Continental;
      else if (this.view.id == 2) regiontype = RegionTypes.International;

      this.data.listRatePriorities(this.data.selectedSupplier.customerid, regiontype, this.data.Company.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.priorities = message.message;
          if (this.priorities.length > 0) {
            this.selectedPriority = this.priorities[0];
            if (this.tabs.first) {//if not, hasn't loaded yet so will default to zero
              this.tabs.first.selectedIndex = 0;
            }

            resolve(true);
          }
          else {
            this.selectedPriority = null;
            resolve(false);
          }
        }
        else {
          this.selectedPriority = null;
          this.priorities = [];
          resolve(false);
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.selectedPriority = null;
        this.priorities = [];
        resolve(false);
        this.tools.gracefulError(err);
      })
    })

  }
  /**
   * regions from selected version
   
  getRegions() {
    return new Promise((resolve) => {
      if (this.selectedVersion) {
        //listRegionsGrandchildren
        this.data.listRegionsDeep(this.selectedVersion.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.bands = message.message;
            console.log(this.bands);
            resolve(true);
          }
          else {
            this.bands = [];
            this.tools.gracefulError(message.message);
            resolve(false);
          }
        },
          err => {
            this.bands = [];
            this.tools.gracefulError(err);
            resolve(false);
          });
      }
      else {
        this.bands = [];
        resolve(false);
      }
    })
  }*/

  /**
   * generate a list of regionids from either the regions returned in getRegions, or their children if driving distance is involved.
   */
  generatePriceBands() {
    this.selectedPriority.Bands = [];
    this.priorities.forEach(p => {
      this.generateBand(p);

    })

  }
  generateBand(priority: RatePriority) {
    priority.IsPopped = false;
    priority.Bands = [];

    this.bands.forEach(b => {
      priority.Bands.push(this.tools.priceBand(b));

      /*if (b.children && b.children.length > 0) {
        let driving = false;
        b.children.forEach(c => {
          if (c.drivingdistance) {
            driving = true;
            let clone = this.cloneBand(c);
            priority.Bands.push(clone);
          }

        })
        if (!driving) {
          let blone = this.cloneBand(b);
          priority.Bands.push(blone);
        }
      }
      else {
        let blone = this.cloneBand(b);
        priority.Bands.push(blone);
      }*/
    })
  }




  /**
   * get a list of rates for all the relevant regions, filtered by the selected priority
   * TO CONSIDER - get everything and filter afterwards NOW a single query on Regions so no need  
   */
  getRates() {
    return new Promise((resolve) => {
      if (!this.selectedVersion) resolve(false);
      else {
        this.data.listRegionsRates(this.selectedVersion.id, this.selectedPriority.id, this.data.Company.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.selectedPriority.Bands = [];
            let regions: Region[] = message.message;
            let starttimer = moment();
            regions.forEach(r => {
              /*
              if(r.Rates && r.Rates.length>0){
                r.Rates.forEach(rate => {
                if(rate.RateBreaks){
                  rate.RateBreaks.sort((a,b)=>{
                    if(a.maxweight>b.maxweight) return 1;
                    else return -1;
                  })
                }
                this.calcMargin(rate);
              })
              r.Rates.sort((a, b) => {//grandchild sort fix for sequelize
                if(!a.RateBracket || !b.RateBracket){
                  return 1;
                } 

                if (a.RateBracket.sequence > b.RateBracket.sequence) return 1;
                else return -1;
              })
              }*/
              this.orderRatesAndBreaks(r);
              
              this.selectedPriority.Bands.push(this.tools.priceBand(r));
            })
            let endtimer = moment();
            console.log(endtimer.diff(starttimer));

            resolve(true)
            //console.log(this.selectedPriority);
          }
          else {
            this.tools.gracefulError(message.message);
          }
        })
      }
      

      /*
      this.data.listRatesFromRegionArray(regionids, this.selectedPriority.id, this.selectedMode).subscribe((message: StatusMessage) => {
        if (message.success) {
          let rates: Rate[] = message.message;
          rates.forEach(r => {
            let band = this.selectedPriority.Bands.filter(b => b.regionid == r.regionid)[0];
            this.calcMargin(r);
            band.RateList.push(r);

          })
          this.selectedPriority.IsPopped = true;
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);

        }
      },
        err => {
          this.tools.gracefulError(err);
          resolve(false);
        }
      );*/
    })

  }

  orderRatesAndBreaks(region:Region){
    if(region.Rates && region.Rates.length>0){
      region.Rates.sort((a, b) => {
        if(!a.RateBracket || !b.RateBracket){
          return 1;
        } 
        if (a.RateBracket.sequence > b.RateBracket.sequence) return 1;
        else return -1;
      })
      region.Rates.forEach(rate=>{
        if(rate.RateBreaks && rate.RateBreaks.length>0){
          rate.RateBreaks.sort((a,b)=>{
            if(parseInt(a.maxweight.toString())>parseInt(b.maxweight.toString())) return 1;
            else return -1;
          })
        }
      })
      
    }
    if(region.children && region.children.length>0){
      region.children.forEach(ch=>{
        this.orderRatesAndBreaks(ch);
      })
    }
  }

  calcMargin(rate: Rate) {
    if (this.margin) {

      rate._percentmargin = this.tools.cleanFloat(this.margin.percent);
      rate._fixedmargin = this.tools.cleanFloat(this.margin.fixed);
      let usemin = false;
      if (rate.minimum && rate.minimum > 0) {
        usemin = true;
      }
      rate._list = this.tools.cleanFloat(usemin ? rate.minimum : rate.baseprice) * (1 + (rate._percentmargin / 100)) + rate._fixedmargin;
      if (rate._list != 0) {
        rate._totalmargin = (rate._list - (usemin ? rate.minimum : rate.baseprice)) / rate._list * 100;
      }

      rate.RateBreaks.forEach(rb => {
        rb._fixedmargin = this.tools.cleanFloat(this.margin.fixed);
        rb._percentmargin = this.tools.cleanFloat(this.margin.percent);
        rb._listprice = this.tools.cleanFloat(rb.price) * (1 + (rb._percentmargin / 100)) + rb._fixedmargin;
        if (rb._listprice != 0) {
          rb._totalmargin = (rb._listprice - rb.price) / rb._listprice * 100;
        }
        else rb._totalmargin = 0;
      })
    }
    else {
      rate._list = this.tools.cleanFloat(Math.max(rate.baseprice, rate.minimum));
      rate._totalmargin = 0;
      if (rate.RateBreaks) {
        rate.RateBreaks.forEach(rb => {
          rb._listprice = rb.price;
          rb._totalmargin = 0;
        })
      }

    }
  }
  /**
   * list available version regions based on the currently selected supplier's base site
   */
  listVersions() {
    return new Promise((resolve) => {
      this.data.listVersions(this.data.selectedSupplier._baseSite.id, this.view.id).then((message: StatusMessage) => {
        if (message.success) {
          this.versions = message.message;
          if (this.versions.length > 0) {
            this.selectedVersion = this.versions[0];
          }
          else this.selectedVersion = null;
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);
        }
      })
    })

  }

  changeSite(e) {
    this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites.filter(c => c.id == e.value)[0];
    this.getEverything();
  }
  changeVersion(e) {
    this.getNewVersionData();
  }
  changeProvider() {
    this.getEverything();
  }
  changePriority(e) {
    if (e.index < this.priorities.length) {//isn't the add priority tab 
      this.selectedPriority = this.priorities[e.index];
      if (!this.selectedPriority.IsPopped) {
        this.getRates().then(success => {

        });
      }
    }


  }
  changeMode(e) {
    this.getRates().then(success => {

    });
  }
  showImport() {

  }
  showCard() {

  }
  ate() {

  }
  addDummyRate() {

  }
  copyRate() {

  }
  editRate() {

  }
  findBand(id: number, children?: PriceBand[]) {
    let foundband: PriceBand;
    if (!children) children = this.selectedPriority.Bands;
    children.some((band: PriceBand) => {
      if (band.regionid == id) {
        foundband = band;
        return true;
      }
      if (!foundband && band.Children) {
        foundband = this.findBand(id, band.Children);
      }
      if (foundband) {
        return true;
      }
    })
    return foundband;
  }
  saveRate() {
    let rate: Rate = this.rateform.value;
    this.data.createOrUpdateRate(rate, true).then((message: StatusMessage) => {
      if (message.success) {
        let incoming: Rate = message.message;
        incoming.Currency = this.data.countries.filter(c => c.id == incoming.currency)[0];
        if (incoming.id) {
          rate = incoming;
          this.rateform.reset(rate);
        }

        let band = this.findBand(rate.regionid);
        if (band) {
          let exists = false;
          let index = 0;
          band.Rates.forEach(r => {
            if (r.id != rate.id) {
              if (r.ratebrackettype == rate.ratebrackettype) {
                r.expires = rate.createdAt;
              }
              if (!exists) index++
            }
            else {
              exists = true;
            }

          })
          this.calcMargin(rate);
          if (exists) {
            if (rate.expires < new Date()) {
              //remove rate from list
              band.Rates.splice(index, 1);
            }
            else band.Rates.splice(index, 1, rate);//replace rate with edited one
          }
          else {
            this.calcMargin(rate);
            band.Rates.push(rate);
          }

        }
        console.log(this.priorities);
        console.log(this.selectedPriority);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    })
  }

  editPriority(priority?: RatePriority) {
    if (!priority) {
      priority = new RatePriority();
      priority.companyid = this.data.selectedSupplier.customerid;
      priority.customerid = 0;
      priority.regionlevel = this.selectedVersion.regiontypeid;

    }

    const dialogRef = this.dialog.open(EditPriorityComponent, {
      width: 'auto',
      data: { priority: priority }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let changedpriority: RatePriority = result;
        if (result.id) {
          this.data.updateRatePriority(changedpriority).subscribe((message: StatusMessage) => {
            if (message.success) {
              priority = Object.assign(priority, changedpriority);
            }
            else {
              this.tools.gracefulError(message.message);
            }
          }, err => { this.tools.gracefulError(err) })
        }
        else {
          this.data.createRatePriority(changedpriority).subscribe((message: StatusMessage) => {
            if (message.success) {
              changedpriority = message.message;
              this.generateBand(changedpriority);
              this.priorities.push(changedpriority);
              setTimeout(() => {
                this.tabs.first.selectedIndex = this.priorities.length - 1;
              })

            }
            else {
              this.tools.gracefulError(message.message);
            }
          }, err => { this.tools.gracefulError(err) })
        }
      }
    });
  }
  logForm() {
    console.log(this.rateform);
  }

  refreshRates(rates?: Rate[]) {
    if (rates && rates.length > 0) {
      let checkco = this.data.providers.filter(ca => ca.Provider.id == rates[0].providerid);
      if (checkco.length > 0) {
        this.data.selectedSupplier = checkco[0];
        this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites[0];
        this.changeProvider();
      }
      else {
        this.data.listCustomers(false).subscribe((message: StatusMessage) => {
          this.data.providers = message.message;
          checkco = this.data.providers.filter(ca => ca.Provider.id == rates[0].providerid);
          if (checkco.length > 0) {
            this.data.selectedSupplier = checkco[0];
            this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites[0];
            this.changeProvider();
          }

        })
      }
    }
    else this.getNewVersionData();
  }

}
