<div *ngIf="regiongroups && regiongroups.length>0"> <!---->
    <div *ngIf="parentRegion">
        <p>{{parentRegion.name}}'s {{regionName}} can be divided up a number of ways.</p>
        <p>Please choose which option you would prefer to start with.</p>
        <p>You can move sub-regions around and rename groupings after deciding.</p>
    </div>
    <div>
        <mat-radio-group *ngFor="let group of regiongroups" (change)="selectGroup($event)">
            <div class="pad">
                <mat-radio-button [value]="group.id">{{group.description}}</mat-radio-button>
            </div>
        </mat-radio-group>
        <div *ngIf="distance || custom">
            <mat-form-field class="mr">
                <mat-label>How many bands?</mat-label>
                <input matInput type="number" placeholder="name" [(ngModel)]="bands" min="1">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Group Name</mat-label>
                <input matInput type="text" placeholder="group name" [(ngModel)]="groupname"  autocomplete="new-group-name">
            </mat-form-field>
            <mat-form-field *ngIf="applicablezones.length>0" class="mr">
                <mat-label>Type of Band</mat-label>
                <mat-select [(ngModel)]="customzonetype">
                    <mat-option *ngFor="let type of applicablezones" [value]="type.zonetype">
                        {{type.id}} - {{type.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="doSplit()" type="button"
                [disabled]="bands<1">Create</button>
        </div>
        <div class="mt jc">
            <button mat-raised-button color="warn" (click)="nope()" type="button">Cancel</button>
        </div>
        
    </div>
</div>
<div class="jc" *ngIf="!regiongroups || regiongroups.length==0"> 
    <p>There are currently no region groups for areas of {{parentRegion.name}}</p>
    <p>However you can create a number of region groups or select regions from the map.</p>
    <div>
        <mat-form-field>
            <mat-label>How many bands?</mat-label>
            <input matInput type="number" placeholder="bands" [(ngModel)]="bands" min="1" autocomplete="new-band-length">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Group Name</mat-label>
            <input matInput type="text" placeholder="group name" [(ngModel)]="groupname"  autocomplete="new-group-name">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="doSplit()" type="button"
                [disabled]="bands<1">Create Groups</button>
        <button mat-raised-button color="primary" (click)="openmapper()" type="button">Add Regions from Map</button>        
    </div>
    <button mat-raised-button color="warn" (click)="nope()" type="button" class="mr">Cancel</button>
    
</div>