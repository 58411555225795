import { Component, Input} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage, CompanySettingType, NewCompanySettingType } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { SettingEditor } from '../setting-editor';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cs-image-chooser',
  templateUrl: './image-chooser.component.html',
  styleUrls: ['./image-chooser.component.scss']
})
export class ImageChooserComponent  implements SettingEditor {

  @Input() data:any;

  public settingvalue = "";
  public settingtype:CompanySettingType;
  public editmode = false;
  public env = environment;

  constructor(public dataservice: DataService, public tools: ToolsService,public events:EventsService) { }

  ngOnInit(): void {
    this.settingtype = this.data.settingtype;
    this.editmode = this.data.editmode;
    if(this.settingtype.Setting){
      this.settingvalue = this.settingtype.Setting.setting;
    }
  }
  saveChanges() {
    const input = <HTMLInputElement>document.getElementById('fileInputField');

    let file: File;
    file = input.files[0];
    let ind = file.name.lastIndexOf('.');
    let suffix = file.name.substring(ind);
    // file type is only image.
    if (/^image\//.test(file.type)) {
      if (file.size > 1000000) {
        this.tools.gracefulError("Image too large, please select a smaller one");
      }
      else {
        this.settingvalue = "logo_"+this.dataservice.Company.id.toString() + suffix;
        const form: FormData = new FormData();
        form.append('file', file, file.name);
        this.dataservice.uploadDocumentImage(form).subscribe((message: StatusMessage) => {
          if (message.success) {
            let newsettingtype = new NewCompanySettingType();
            newsettingtype.newsetting = message.message;
            newsettingtype.settingtype = this.settingtype;
            this.events.companysettingchange.emit(newsettingtype);
          }
          else {
            this.tools.gracefulError(message.message);
          }
        },
          err => {
            this.tools.gracefulError(err);
          })
      }

    }
    else {
      this.tools.gracefulError("Images only please");
    }
  };
  cancel(){
    this.editmode = false;
  }
}
