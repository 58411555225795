import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'cs-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  public prompttext:string = "";
  public label:string = "";
  public input:string = "";

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>,@Inject(MAT_DIALOG_DATA) public inputs:any) {
    this.prompttext = inputs.prompttext;
    this.label = inputs.label;
    this.input = inputs.input;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
  ok(){
    this.dialogRef.close(this.input);
  }

}
