import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Service } from 'src/app/models/data';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { Surcharge, SurchargeAppliesTo, TransportModes } from 'src/app/models/rates';
import { ToolsService } from 'src/app/services/tools.service';


@Component({
  selector: 'cs-port-surcharges',
  templateUrl: './port-surcharges.component.html',
  styleUrls: ['./port-surcharges.component.scss']
})
export class PortSurchargesComponent implements OnInit {

  @Input() serviceForm: FormGroup;
  @Input() editmode: boolean = false;

  public selectedPort:FormGroup;
  public selectedSite:number;

  public isOrigin = true;

  public surcharges:Surcharge[]=[];

  get service(): Service {
    return this.serviceForm.value;
  }

  get originServicePorts(){
    return <FormArray> this.serviceForm.get("OriginServicePorts");
  }
  get destinationServicePorts(){
    return <FormArray> this.serviceForm.get("DestinationServicePorts");
  }

  changePort(e){
    let o = this.originServicePorts.controls;
    if(e<o.length){
      this.selectedPort = <FormGroup> o[e];
      this.isOrigin = true;
    }
    else{
      let d = this.destinationServicePorts.controls;
      this.selectedPort = <FormGroup> d[e-o.length];
      this.isOrigin = false;
    }
    
    this.resetProvider();
  }
  changeSite(e){

      let provs = this.selectedPort.value.Providers[e];
      this.selectedSite = provs.siteid;
      this.listCharges();

  }
  /*view only, not needed
  get appliesto(): number {
    switch (this.service.transportMode) {
      case TransportModes.Shipping:
        return SurchargeAppliesTo.Port
      case TransportModes.Air:
        return SurchargeAppliesTo.Airport;
      case TransportModes.Rail:
        return SurchargeAppliesTo.RailCC;
      case TransportModes.Road:
        return SurchargeAppliesTo.RDC;
      default:
        return SurchargeAppliesTo.Site;
    }
  }*/

  constructor(public data:DataService,public tools:ToolsService) {

  }

  listCharges(){
    let appliesto:SurchargeAppliesTo;
    if(this.service.transportMode==TransportModes.Shipping){
      appliesto = this.isOrigin?SurchargeAppliesTo.PortOut:SurchargeAppliesTo.PortIn;
    }
    else{
      appliesto = this.isOrigin?SurchargeAppliesTo.AirportOut:SurchargeAppliesTo.AirportIn;
    }
    
    this.data.listSurchargesPort(this.selectedSite,appliesto).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.surcharges = message.message;
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }

  ngOnInit(): void {
    if(this.originServicePorts.controls.length>0){
      this.selectedPort = <FormGroup> this.originServicePorts.controls[0];
    }
    else if(this.destinationServicePorts.controls.length>0){
      this.selectedPort = <FormGroup> this.destinationServicePorts.controls[0];
    }
    if(this.selectedPort){
      this.resetProvider();
    }
  }
  resetProvider(){
    let providers = <FormArray> this.selectedPort.get("Providers");
    if(providers.controls && providers.controls.length){
      this.selectedSite = providers.controls[0].value.siteid;
    }
    if(this.selectedSite){
      this.listCharges();
    }
  }

}
