<mat-tab-group  (selectedTabChange)="changeView($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Outstanding
        </ng-template>
        <ng-template matTabContent>
            <cs-quote-history-list [quotes]="quotes"></cs-quote-history-list>
            
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            In Process
        </ng-template>
        <ng-template matTabContent>
            
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Completed
        </ng-template>
        <ng-template matTabContent>
            
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Drafts
        </ng-template>
        <ng-template matTabContent>
            
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Rejected
        </ng-template>
        <ng-template matTabContent>
            
        </ng-template>
    </mat-tab>

</mat-tab-group>
