<h3>Rate Base</h3>
<div *ngIf="rate && !editmode">
    <div class="fl">
        <div class="field-title">Start Date:</div>
        <div class="field-value">{{rate.createdAt | date:'dd-MM-yyyy'}}</div>
        <div class="field-title">Expiry Date:</div>
        <div class="field-value">{{rate.expires | date:'dd-MM-yyyy'}}</div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Minimum Charge:</div>
        <div class="field-value">{{rate.baseprice | currency:currencyCode}}</div>
        <div class="field-title">Which includes:</div>
        <div class="field-value">{{rate.baseIncludesWeight | number}} kg</div>
        <div class="field-title">and: </div>
        <div class="field-value super">{{rate.baseIncludesVolume | number}} m<sup>3</sup></div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Pricing per:</div>
        <div class="field-value">{{rate.priceperbreak?'band':'kg'}}</div>
        <div class="field-title">Pricing applies:</div>
        <div class="field-value">
            {{rate.breakbarrierincludes?'up to and including next value':'up to but not including next value'}}</div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Volume equivalent</div>
        <div class="field-value">{{rate.volumeequivalent | number}}</div>
    </div>
</div>
<div *ngIf="rate && editmode" class="fl">
    <div>
        <div class="fl formfields">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="rate.createdAt" (dateChange)="rate._isDirty = true;propogateRateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Expiry Date</mat-label>
                <input matInput [matDatepicker]="picker2" [(ngModel)]="rate.expires" (dateChange)="rate._isDirty = true;propogateRateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="fl formfields">
            <mat-form-field>
                <mat-label>Price per</mat-label>
                <mat-select [(ngModel)]="rate.priceperbreak" (selectionChange)="rate._isDirty = true;propogateRateChange()">
                    <mat-option [value]="true">
                        price band
                    </mat-option>
                    <mat-option [value]="false">
                        W/M
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Price applies</mat-label>
                <mat-select [(ngModel)]="rate.breakbarrierincludes" (selectionChange)="rate._isDirty = true;propogateRateChange()">
                    <mat-option [value]="true">
                        up to and including next value
                    </mat-option>
                    <mat-option [value]="false">
                        up to but not including next value
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="fl formfields">
            <mat-form-field class="">
                <mat-label>Volume Equivalent</mat-label>
                <input matInput placeholder="volume equivalent" type="number" [(ngModel)]="rate.volumeequivalent" (change)="rate._isDirty = true;propogateRateChange()">
            </mat-form-field>
            <mat-checkbox *ngIf="index==0 && !unzoned" [(ngModel)]="applytoall" (change)="rate._isDirty = true;propogateRateChange()"> Apply non-pricing values to all zones</mat-checkbox>
        </div>
        <div class="fl formfields">
            <mat-form-field class="">
                <mat-label>Minimum Charge</mat-label>
                <input matInput placeholder="Charge" type="number" [(ngModel)]="rate.baseprice" (change)="rate._isDirty = true;">
            </mat-form-field>

            <mat-form-field class="form-skinny">
                <mat-label>Includes ({{rate.priceperbreak?'unit':'kg'}})</mat-label>
                <input matInput placeholder="Includes weight" type="number" [(ngModel)]="rate.baseIncludesWeight" (change)="rate._isDirty = true;">
            </mat-form-field>

            <mat-form-field class="form-skinny">
                <mat-label>Includes ({{rate.priceperbreak?'unit':'m3'}})</mat-label>
                <input matInput placeholder="Includes volume" type="number" [(ngModel)]="rate.baseIncludesVolume" (change)="rate._isDirty = true;">
            </mat-form-field>
        </div>
        <div class="f1"></div>


    </div>
</div>