<div>
    <table *ngIf="surcharges.length>0" class="c1lcnc">
        <tr>
            <th>Type</th>
            <th>Fixed</th>
            <th>%</th>
            <th>Applies to</th>
            <th>per unit</th>
            <th>Expires</th>
        </tr>
        <tr *ngFor="let surch of surcharges">
            <td>{{surch.SurchargeType.name}}</td>
            <td><span *ngIf="surch._currencyView">{{surch.surchargeFixed | currency:surch._currencyView.code}}</span></td>
            <td>{{surch.surchargePercent}}</td>
            <td><span *ngIf="surch._currencyView">{{surch._currencyView.appliesto}}</span></td>
            <td>{{surch.unit | number:'1.2-2'}}</td>
            <td>{{surch.expires | date:'dd/MM/yy'}}</td>
        </tr>
    </table>
    <p *ngIf="surcharges.length==0"><i>No applicable charges</i></p>
</div>
