<div class="fl" *ngIf="!editmode">
    <div class="field-title">{{settingtype.description}}</div>
    <div class="field-value" (dblclick)="editmode=!editmode">{{settingtype.Setting?settingtype.Setting.setting:"not set"}}</div>

</div>
<div *ngIf="editmode">
    <mat-form-field class="form-skinny">
        <mat-label>{{settingtype.description}}</mat-label>
        <input matInput type="text" placeholder="Search" [(ngModel)]="settingvalue">
    </mat-form-field>
    <mat-icon class="pointer" (click)="saveChanges()" title="update" aria-label="update" color="primary">check</mat-icon>
    <mat-icon class="pointer" (click)="cancel()" title="cancel" aria-label="cancel update" color="error">cancel</mat-icon>
</div>
