import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import ExchangeRate, { Country, ExchangeRates } from 'src/app/models/models';
import { ToolsService } from 'src/app/services/tools.service';
import { CalculationsService } from 'src/app/services/calculations.service';

@Component({
  selector: 'cs-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss']
})
export class ExchangeRatesComponent implements OnInit {

  public amount: number = 1;
  public baseCountry: Country = new Country();
  public loading: boolean = true;
  public reverse = false;
  public rate:ExchangeRates;

  constructor(public data: DataService, public tools: ToolsService, public calc: CalculationsService) {

  }
  ngOnInit() {
    if (this.data.auth.core.user && this.data.auth.core.types) {
      this.populateRates();
    }
    else if (this.data.dataReady) { //conditional for testing.
      this.data.dataReady.subscribe(() => {
        this.populateRates();
      })
    }


  }
  populateRates() {
    this.loading = true;
    this.setBase();
    this.changeBase();

  }
  changeBase(){
    let exists = false;
    if (this.calc.exchangeratesByBase.length > 0) {
      let check = this.calc.exchangeratesByBase.filter(er => er.base == this.baseCountry.currencySymbol);
      if (check.length == 1) {
        exists = true;
        this.rate = check[0];
      }
    }
    if (!exists) {
      this.getRates().then((res) => {
        if (res) {
          this.mapRates(res);
        }
        this.loading = false;
      });
    }
  }
  setBase() {
    if (this.data.auth.user.UserSettings && this.data.auth.user.UserSettings.length > 0) {
      let baseCountry = this.data.auth.user.UserSettings.filter(us => us.UserSettingType.description == "baseCurrency" && us.setting != "");
      if (baseCountry.length > 0) {
        this.baseCountry = this.data.countries.filter(co => co.currencyCode == baseCountry[0].setting)[0];

      }
      else this.baseCountry = this.data.defaultCurrency;
    }
    else this.baseCountry = this.data.defaultCurrency;
  }

  getRates() {
    return new Promise((resolve, reject) => {
      this.data.exchangeRates(this.baseCountry.currencyCode).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      }
      );
    })
  }
  mapRates(res: any) {
    let exr = new ExchangeRates();
    exr.base = res.base;
    exr.date = res.date;
    exr.rates = [];
    Object.keys(res.rates).forEach((key) => {
      let country = this.data.countries.filter(co => co.currencyCode == key);
      if (country.length > 0) {
        let rate = new ExchangeRate();
        rate.country = country[0];
        rate.currency = key;
        rate.rate = res.rates[key];
        exr.rates.push(rate);
      }
    })

    this.calc.exchangeratesByBase.push(exr);
    this.rate = exr;
  }
}
