<div *ngIf="parentRegion">
    <h3>Region Group Editor: {{parentRegion.name}}</h3>
</div>
<div class="fl">
    <div class="f1">
        <div>
            <h3>Region Group Types</h3>
            <div *ngFor="let grouptype of grouptypes">
                <div>
                    <input type="text" [(ngModel)]="grouptype.description">
                    <mat-form-field class="form-skinny">
                        <mat-label>Zone Type</mat-label>
                        <mat-select [(ngModel)]="grouptype.zonetype">
                            <mat-option *ngFor="let type of zonetypes | enumpipe" [value]="type.key">
                                {{type.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button (click)="selectType(grouptype)">Select</button>
                    <button (click)="updateType(grouptype)">Update</button>
                    <button (click)="deleteType(grouptype)">Delete</button>
                </div>
            </div>
            <div>
                <div>
                    <input type="text" [(ngModel)]="newGroupType.description">
                    <button (click)="addGroupType()">Add Group Type</button>
                </div>
            </div>
        </div>
        
        <div *ngIf="selectedGroupType">
            <h3>{{selectedGroupType.description}}</h3>
            <div *ngFor="let group of selectedGroupType.RegionGroups">
                <div *ngIf="group.recordStatus<3">
                    <input type="text" [(ngModel)]="group.description">

                    <button (click)="select(group)">Select</button>
                    <button (click)="update(group)">Update</button>
                    <button (click)="delete(group)">Delete</button>
                </div>
                
            </div>
            <div class="mt">
                <input type="text" [(ngModel)]="newGroup.description">
                <button (click)="addGroup()">Add Group</button>
            </div>
        </div>
    </div>
    <div class="f1">
        <div *ngIf="selectedGroupType">
            <div class="fl">
                <div class="f1"><h3 *ngIf="selectedGroup">{{selectedGroup.description}}</h3>
                    <input type="text" [(ngModel)]="searchText"><button (click)="search()">Search for Regions</button><button (click)="listRegions()">List Regions</button>
                    <h4>Sub - Regions</h4>
                    <div class="fl"><span class="missingregs" *ngFor="let miss of missing">{{miss.name}}</span></div>
                    <ul>
                        <li *ngFor="let res of searchResults" (click)="addChild(res)">{{res.name}}</li>
                    </ul>
                    <h4>Included Regions</h4>
                    <div *ngIf="selectedGroup">
                        <div class="fl" *ngFor="let child of selectedGroup.RegionGroupChildren;let i=index">
                            <div class="f1">{{child.Region.name}}</div>
                            <div><mat-icon (click)="deleteChild(child,i)">delete</mat-icon></div>
                             
                        </div>
                    </div>
                    <h4>Add Region</h4>
                    <div class="fl aic">
                        <input type="text" [(ngModel)]="newregion" class="mr">
                        <mat-form-field class="form-skinny">
                            <mat-label>Zone Type</mat-label>
                            <mat-select [(ngModel)]="newzone">
                                <mat-option *ngFor="let type of zonetypes | enumpipe" [value]="type.key">
                                    {{type.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="form-skinny">
                            <mat-label>Zone Type</mat-label>
                            <mat-select [(ngModel)]="newtype">
                                <mat-option *ngFor="let type of regiontypes | enumpipe" [value]="type.key">
                                    {{type.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button (click)="createRegion()">Create</button>
                    </div>

                </div>
                <div class="f1">
                    <ul>
                        <li *ngFor="let reg of groupChildren" (click)="addChild(reg)">{{reg.name}}</li>
                    </ul>
                </div>
            </div>
            
        </div>
        <input type="number" [(ngModel)]="iterations"><button (click)="regionalise()">Regionaliser</button>
    </div>
</div>