<div class="fl {{vertical?'fdc':''}} aic jc">
    <button mat-mini-fab aria-label="paste some data" color="primary" title="paste some data"
        (click)="generateObject()">
        <mat-icon>content_paste</mat-icon>
    </button>
    <div class="drop-zone" csPdfupload (fileDropped)="onFileDropped($event)">
        <div #fileDropRef id="fileDropRef">
            <input type="file" (change)="handlePdf($event.target.files)" />
            <mat-icon aria-label="convert document" color="primary" title="drop pdf here">description</mat-icon>
        </div>
    </div>
</div>