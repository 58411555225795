import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { GoogleService, DrawnPoly } from './google.service';
import { ToolsService } from './tools.service';
import { Region, ZoneType, RegionTypes, GPS, RegionDisplayInfo } from '../models/rates';
import { MapSelect } from '../models/ui';
import { StatusMessage, Address } from '../models/user';
import { EventsService } from './events.service';

import distinctColors from 'distinct-colors';
import chroma from 'chroma-js';
import { RecordStatus } from '../models/models';
import { REPLCommand } from 'repl';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  public selectedRegion: Region;

  public primaryColour: chroma.Color = chroma('673ab7');
  public excludedColour: chroma.Color = chroma('f44336');
  public deletedColour: chroma.Color = chroma('000000');

  public palette = distinctColors({ count: 20 });

  constructor(public data: DataService, public geo: GoogleService, public tools: ToolsService, public events: EventsService) { }

  checkAdders(region: Region) {
    switch (region.zonetype) {
      case ZoneType.International:
        if(region.children && region.children.length>0){
          region._canAddMap = false;
          region._canAdd = true;
        }
        else{
          region._canAdd = true;
          region._canAddMap = true;
        }
        
        break;
      case ZoneType.Continental:
        region._canAdd = true;
        break;
      case ZoneType.National:
        region._canAdd = true;

        if (region.children && region.children.length > 0) {
          let active = region.children.filter(r => r.recordStatus == 0);
          if (active.length > 0) {
            active.some((ch) => {
              if (ch.zonetype > ZoneType.NationalRegionGroup && ch.zonetype != ZoneType.IncodeGroup) {
                region._canAddMap = true;
                region._canAdd = false;
              }
            })
          }
          else {
            region._canAddMap = true;
          }

        }
        else {
          region._canAddMap = true;
        }
        break;
      case ZoneType.NationalTerritory:
        region._canAdd = true;
        region._canAddDistance = true;
        break;
      case ZoneType.RegionalCountryGroup:
      case ZoneType.PoliticalCountryGroup:
      case ZoneType.CustomCountryGroup:
        region._canAddMap = true;
        break;
      case ZoneType.DistanceCountryGroup:
        region._canAddDistance = true;
        break;
      case ZoneType.NationalRegionGroup:
      case ZoneType.TerritoryGroup:
        region._canAddMap = true;
        region._canAddDistance = true;
      case ZoneType.NationalOutcodeGroup:

        region._canAddMap = true;
        break;
      case ZoneType.NationalOutcode:
        if(!region.children || region.children.length==0){
          region._canAdd = true;
        }
        else region._canAdd = false;
        
        break;
      case ZoneType.NationalRegion:
      case ZoneType.NationalTerritory:
        region._canAddMap = true;
        region._canAddDistance = true;
        break;
      case ZoneType.NationalDistanceGroup:
        region._canAddDistance = true;
        break;
      case ZoneType.NationalCustomGroup:
      case ZoneType.IncodeGroup:
        region._canAddMap = true;
        break;
    }
  }
  /**
   * using a google lat long and a usepostcode flag, add a region child
   * @param placeselect 
   */
  addFromPlace(placeselect: MapSelect) {
    if (this.selectedRegion) {
      
      if (google.maps.places) {
        this.geo.reverseGeocode(placeselect.gps).then((message: StatusMessage) => {
          if (message.success) {
            let place: google.maps.places.PlaceResult = message.message;
            this.addChildFromGooglePlace(place, placeselect.usepostcode);
          }
          else this.tools.gracefulError("No Place found, please try again");

        }, err => {
          this.tools.gracefulError(err);
        })
      }
    }
    else {
      this.tools.gracefulError("No Parent Region selected");
    }

  }
  /**
   * remove the polys from the map
   */
  clearMap() {
    this.geo.selectedPolys.forEach((p: DrawnPoly) => {
      p.poly.forEach(poly => {
        poly.setMap(null);
      })
    })
    this.geo.selectedPolys = [];
  }

  /**
   * take a google place from a map click and add the selected region dependent on level
   * open the region folder so the addition is visible
   * @param place 
   * @param usepostcode 
   */
  addChildFromGooglePlace(place: google.maps.places.PlaceResult, usepostcode: boolean, region?: Region) {
    
    if (!region) region = this.selectedRegion;
    let address = this.tools.addressFromGooglePlace([place], this.data.countries);
    region._folderOpen = true;
    switch (region.zonetype) {
      case ZoneType.International:
        this.createContinent(address.Country.continentid, region);
        break;
      case ZoneType.Continental:
      case ZoneType.CustomCountryGroup:
      case ZoneType.RegionalCountryGroup:
        this.addCountryRegion(region, address, ZoneType.National);
        break;
      case ZoneType.TerritoryGroup:
        this.addStateRegion(region, address);
        break;
      case ZoneType.National:
      case ZoneType.NationalRegionGroup:
      case ZoneType.NationalCountyGroup:
        this.addRegionRegion(region, address, usepostcode);
        break;
      case ZoneType.NationalOutcodeGroup:
        this.addOutCodeRegion(region, address);
        break;
      case ZoneType.NationalRegion:
        this.addLocalRegion(region, address, usepostcode);
        break;

    }
    console.log(address);

  }
  /**
 * create single continent from a map select,
 * @param id 
 */
  createContinent(id: number, parentregion: Region) {
    if (!parentregion.children) parentregion.children = [];
    let exists = parentregion.children.filter(c => c.continentid == id);
    if (exists.length == 0) {
      let cont = this.data.continents.filter(c => c.id == id)[0];
      let region = new Region(parentregion.companyid, ZoneType.Continental);
      region.name = cont.name;
      region.googlename = cont.googlename;
      region.parentId = parentregion.id;
      region.continentid = cont.id;
      region.gpscentreid = cont.gpscentreid;
      region.featureid = cont.featureid;
      region.regiontypeid = parentregion.regiontypeid + 1;
      region.zoom = cont.zoom;
      region.basesiteid = parentregion.basesiteid;

      if (!parentregion.children) parentregion.children = [];

      this.data.createRegion(region).subscribe((message: StatusMessage) => {

        if (message.success) {
          region = message.message;
          region.GPS = cont.GPS;
          region.Feature = cont.Feature;
          parentregion.children.push(region);
          this.tidyUpAfterAddRegion(parentregion, true);
        }
        else {
          this.tools.gracefulError(message.message);
        }

      }, err => {
        this.tools.gracefulError(err);
      })
    }
    else this.tools.snackMessage(exists[0].name + " is already in this zone");

  }

  /**
 * using a given address, find the base country and add it as region, open the parent folder so the addition is visible.
 * @param address 
 */
  addCountryRegion(parentregion: Region, address: Address, zonetype: ZoneType) {
    if(address.countryid){
      this.data.getCountryRegion(address.countryid).subscribe((message: StatusMessage) => {
        if (message.success) {
          if (message.message) {
            let exists = [];
            if (parentregion.children) {
              exists = parentregion.children.filter(c => c.countryid == message.message.countryid);
            }
            if (exists.length == 0) {
              let coregion: Region = message.message;
              let region = new Region(parentregion.companyid, zonetype);
              region.basesiteid = parentregion.basesiteid;
              region.countryid = address.countryid;
              region.gpscentreid = coregion.gpscentreid;
              region.parentId = parentregion.id;
              region.name = coregion.name;
              region.googlename = coregion.googlename;
              region.recordStatus = 0;
              region.regiontypeid = coregion.regiontypeid;
              if (!parentregion.children) parentregion.children = [];
              region.sequence = parentregion.children.length;
              region.code = coregion.code;
              region.continentid = parentregion.continentid;
              region.featureid = coregion.featureid;
              region.zoom = coregion.zoom;
              this.data.createRegion(region).subscribe((message: StatusMessage) => {
                if (message.success) {
                  parentregion.children.push(message.message);
                  this.events.createRegion.emit(parentregion);
                  parentregion._selected = false;
                  this.selectRegion(parentregion);
                }
                else {
                  this.tools.gracefulError(message.message);
                }
              }, err => {
                this.tools.gracefulError(err);
              })
            }
            else this.tools.snackMessage(exists[0].name + " is already part of this country group");
          }
          else {
            this.tools.gracefulError("We do not have information for this region, is it a territory of another country?");
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }
  
      }, err => {
        this.tools.gracefulError(err);
      })
  
  
    }
    else this.tools.gracefulError("Insuffient coverage for this region. If you provide a service here, please contact Freightology");
  }

  /**
 * if possible find an outcode using a given address and a postcode substring, use county or state depending on country
 * @param address 
 */
  addRegionRegion(region: Region, address: Address, usepostcode: boolean) {
    if (region.countryid == address.countryid) {
      if (address.postcode && usepostcode) {
        this.addOutCodeRegion(region, address);
      }
      else if (region.zonetype == ZoneType.National || region.zonetype == ZoneType.TerritoryGroup || region.zonetype == ZoneType.NationalRegionGroup) {
        this.addStateRegion(region, address)
      }
      else {
        this.addLocalityRegion(region, address);
      }
    }
    else {
      this.tools.gracefulError("Selected Region not within " + region.name);
    }

  }
  addStateRegion(parentregion: Region, address: Address) {
    let state = address.state;
    if (parentregion.zonetype == ZoneType.NationalRegionGroup) {
      state = address.county;

    }
    if (address.countryid != parentregion.countryid) {
      this.tools.gracefulError("Region not a part of the selected country");
      return;
    }
    let region = new Region(parentregion.companyid, ZoneType.NationalTerritory);
    region.name = state;
    region.googlename = region.name;
    region.regiontypeid = RegionTypes.State;
    let exists = parentregion.children && parentregion.children.filter(c => c.name == region.name && c.recordStatus < 3);
    if (exists && exists.length > 0) {
      this.tools.gracefulError("Region already exists");
    }
    else {
      region.zoom = 8;
      this.mapRegionToBaseAndSave(parentregion, region, address);
    }


  }
  addLocalityRegion(parentregion: Region, address: Address) {
    let county = address.county;
    if (!county || county == "") {
      county = address.town;
    }
    let region = new Region(parentregion.companyid, ZoneType.NationalCounty);
    region.name = county;
    region.googlename = county;
    region.regiontypeid = RegionTypes.Local;
    region.zoom = 10;
    this.mapRegionToBaseAndSave(parentregion, region, address);
  }
  addOutCodeRegion(parentregion: Region, address: Address) {
    let name = this.tools.postcodearea(address);
    if (parentregion.children) {
      let exists = parentregion.children.filter(c => c.googlename == name);
      if (exists.length > 0) {
        this.tools.gracefulError("Outcode already exists");
        return;
      }
    }
    let region = new Region(parentregion.companyid, ZoneType.NationalOutcode);
    region.name = name;
    region.googlename = name;
    region.regiontypeid = RegionTypes.Regional;
    region.zoom = 10;
    this.mapRegionToBaseAndSave(parentregion, region, address);
  }

  addLocalRegion(parentregion: Region, address: Address, usepostcode: boolean) {
    if (address.postcode && usepostcode) {
      this.addPostCodeRegion(parentregion, address);
    }
    else this.addLocalityRegion(parentregion, address);
  }



  addPostCodeRegion(parentregion: Region, address: Address) {
    let incode = this.tools.postcodearea(address);
    let region = new Region(parentregion.companyid, ZoneType.NationalOutcode);
    region.name = incode;
    region.googlename = incode;
    region.regiontypeid = RegionTypes.Local;
    region.zoom = 12;
    this.mapRegionToBaseAndSave(parentregion, region, address);
  }

  addIncodesToOutcode(region: Region) {
    return new Promise(resolve => {
      this.getBaseRegionId(region).then((id:number)=>{
        region.baseregionid = id;
        this.data.cloneBaseChildren(region).subscribe((message: StatusMessage) => {
          if (message.success && message.message) {
            let children: Region[] = message.message;
            children.forEach(ch => {
              if (!region.children) region.children = [];
              region.children.push(ch);
            })
            
          }
          else this.tools.gracefulError(message.message);
          resolve(region);
        })
      })
      
    })

  }

  //currently only intended for outcodes, if the outcode is using its children, split the region into two and split the postcodes accordingly.
  splitRegion(region: Region) {
    return new Promise(resolve => {
      let split = this.cloneRegion(region);
      this.data.splitRegion(region, split).subscribe((message: StatusMessage) => {
        let parentRegion = message.message;
        this.events.createRegion.emit(parentRegion);
      })
      resolve([region, split]);
    })
  }

  /**
 * try and find a base region with the same name and scale and use its features if found. Otherwise just create a new one.
 * @param region 
 * @param address 
 */
  mapRegionToBaseAndSave(parentregion: Region, region: Region, address: Address) {
    region.basesiteid = parentregion.basesiteid;
    region.countryid = address.countryid;
    region.parentId = parentregion.id;
    if (!parentregion.children) parentregion.children = [];
    region.sequence = parentregion.children.length;
    region.companyid = parentregion.companyid;
    region.continentid = parentregion.continentid;
    this.getBaseRegion(region).then((message: StatusMessage) => {
      if (message.success) {
        this.mapBaseRegionProperties(region, message.message);
      }
      this.addRegionAndGPS(parentregion, region, address);

    })




  }
  getBaseRegion(region) {
    return new Promise(resolve => {
      if (region.baseregionid) {
        this.data.getRegion(region.baseregionid).subscribe((message: StatusMessage) => {
          resolve(message);
        })
      }
      else {
        this.data.getBaseRegionByName(region.zonetype, region.name, region.parentId).subscribe((message: StatusMessage) => {
          resolve(message);
        })
      }
    })
  }
  getBaseRegionId(region){
    return new Promise(resolve=>{
      if(region.baseregionid) resolve(region.baseregionid);
      else{
        this.data.getBaseRegionByName(region.zonetype, region.name, region.parentId).subscribe((message: StatusMessage) => {
          if(message.success){
            resolve(message.message.id);
          }
          else{
            resolve(0);
          }
          
        })
      }
    })
  }

  mapBaseRegionProperties(region: Region, baseregion: Region) {
    region.featureid = baseregion.featureid;
    region.gpscentreid = baseregion.gpscentreid;
    region.GPS = baseregion.GPS;
    region.googlename = baseregion.googlename;
    region.baseregionid = baseregion.id;
  }

  //add gps if available, save and tidy up
  addRegionAndGPS(parentregion: Region, region: Region, address: Address) {

    if (!region.GPS) {
      region.GPS = new GPS();
      region.GPS.lat = address.gpslat;
      region.GPS.long = address.gpslong;
    }
    this.data.createOrReturnGPS(region.GPS).then((gpsid: number) => {
      region.gpscentreid = gpsid;
      this.data.createRegion(region).subscribe((message: StatusMessage) => {
        if (message.success) {
          parentregion.children.push(message.message);
          this.tidyUpAfterAddRegion(parentregion, true);
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    })
  }

  tidyUpAfterAddRegion(region: Region, emitevent?: boolean) {
    this.checkAdders(region);
    if (emitevent) {
      this.events.createRegion.emit(region);
    }
  }

  /**
 * call the regions component and let it know this region has been selected.
 */
  selectRegion(parentregion: Region) {
    this.events.selectRegion.emit(parentregion);
  }


  /**set up display info for regions using the diff colours palette based on child length
* this is recalculated every time a region is selected
*/
  setDisplayInfo(regions: Region[], depth: number, parentInfo?: RegionDisplayInfo) {

    for (let i = 0; i < regions.length; i++) {
      regions[i].RegionDisplayInfo = new RegionDisplayInfo(i, depth);
      if (depth == 0) {
        if (regions[i].children && regions[i].children.length > 0) {
          this.setPalette(regions[i].children.length);
        }
        regions[i].RegionDisplayInfo.colour = this.primaryColour;
        regions[i].RegionDisplayInfo.stroke = regions[i].recordStatus == RecordStatus.Active ? this.primaryColour : this.recordStatusColour(regions[i].recordStatus);
      }
      else if (depth == 1) {
        regions[i].RegionDisplayInfo.colour = this.palette[i];
        regions[i].RegionDisplayInfo.stroke = regions[i].recordStatus == RecordStatus.Active ? this.palette[i] : this.recordStatusColour(regions[i].recordStatus);
      }
      else {
        regions[i].RegionDisplayInfo.colour = parentInfo.colour;
        regions[i].RegionDisplayInfo.stroke = regions[i].recordStatus == 0 ? parentInfo.colour : this.recordStatusColour(regions[i].recordStatus);
      }
      if (regions[i].children && regions[i].children.length > 0) {
        this.setDisplayInfo(regions[i].children, depth + 1, regions[i].RegionDisplayInfo);
      }
    }

  }


  recordStatusColour(recordStatus: number): chroma.Color {
    if (recordStatus == RecordStatus.Suspended) return this.excludedColour;
    else return this.deletedColour;

  }
  /**
   * create a distinct colours pallet array of length: size
   * @param size 
   */
  setPalette(size: number) {
    this.palette = distinctColors({ count: size });
  }

  cloneRegion(region: Region) {
    let clone = new Region(region.companyid, region.zonetype);
    clone.name = region.name;
    clone.googlename = region.googlename;
    clone.parentId = region.parentId;
    clone.continentid = region.continentid;
    clone.gpscentreid = region.gpscentreid;
    clone.featureid = region.featureid;
    clone.regiontypeid = region.regiontypeid;
    clone.zoom = region.zoom;
    clone.basesiteid = region.basesiteid;
    clone.sequence = region.sequence ? region.sequence + 1 : 1;
    clone.drivingdistance = region.drivingdistance;

    return clone;
  }

}
