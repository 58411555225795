<mat-tab-group #settingstab (selectedIndexChange)="changeTab($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Providers
        </ng-template>
        <cs-providers></cs-providers>
        <!--
        <div class="body-wrap">
            <div class="loader" *ngIf="isLoadingResults">
                <mat-spinner></mat-spinner>
            </div>
            <div class="fl">
                <div>
                    <button type="button" mat-mini-fab aria-label="Add Company" color="primary" title="Add Company"
                        (click)="addCompany()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="f1">
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search on any field">
                    </mat-form-field>
                </div>

            </div>
            <div class="f1 fl fdc">
                <div class="f1">
                <table mat-table [dataSource]="customers" matSort class="mat-elevation-z8 fixed">

                    <ng-container matColumnDef="Provider.id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="id-field" colspan="1"> No. </th>
                        <td mat-cell *matCellDef="let element" class="id-field" colspan="1"> {{element.Provider.id}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Provider.name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="primary-cell" colspan="4"> Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="primary-cell pointer" colspan="4">
                            {{element.Provider.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Provider.companystatusid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="2"> Status </th>
                        <td mat-cell *matCellDef="let element" colspan="2">
                            {{element.Provider.CompanyStatus.description}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Provider.companytypeid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="2"> Type </th>
                        <td mat-cell *matCellDef="let element" colspan="2"> {{element.Provider.CompanyType.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Settings">
                        <th mat-header-cell *matHeaderCellDef class="id-field" colspan="1"></th>
                        <td mat-cell *matCellDef="let element" class="id-field" colspan="1">
                            <mat-icon color="primary" class="pointer" (click)="editCompany(element)">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" matRipple [cdkDetailRow]="row"
                        [cdkDetailRowTpl]="tpl"></tr>

                </table>
            </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                <div class="fl aic">
                    <span>{{timer}} ms</span>
                    <div class="f1"></div>
                </div>
            </div>
        </div>
        <ng-template #tpl let-element>
            <tr>
                <td [attr.colspan]="displayedColumns.length*2">
                    <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
                        <cs-company-detail [customer]="element.Provider"></cs-company-detail>
                    </div>
                </td>
            </tr>
        </ng-template>-->
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Customers
        </ng-template>
        <cs-providers [iscustomers]="true"></cs-providers>

    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Regions
        </ng-template>
        <ng-template matTabContent><!--all part of the fucking around with regions - see the ts file on tabselect change for details-->
        <div class="fl f1 overflow-scroll">
            <cs-regions></cs-regions>
        </div>
    </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Rates
        </ng-template>
        <ng-template matTabContent>
        <div class="fl f1">
            <cs-rates></cs-rates>
        </div>
    </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Services
        </ng-template>
        <div>
            <cs-services [services]="services" [showadd]="true"></cs-services>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Margins
        </ng-template>
        <ng-template matTabContent>
            <cs-margins></cs-margins>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Users
        </ng-template>
        <div class="fl h100r halfwrap">
            <div class="f1" *ngIf="company">
                <div *ngFor="let site of company.Sites">
                    <h3>{{site.name}}</h3>
                    <table>
                        <tr class="tal">
                            <th>First Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                        </tr>
                        <tr class="pointer" *ngFor="let user of site.Users" (click)="selectUser(user)"
                            class="user {{selectedUser && selectedUser.id==user.id?'selected':''}}">
                            <td>{{user.firstname}}</td>
                            <td>{{user.surname}}</td>
                            <td>{{user.email}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="selectedUser">
                <h3>User Details</h3>
                <form class="f1 fl fdc ifwrapped" [formGroup]="selectedUserForm" (ngSubmit)="submit()">
                    <div class="fl">
                        <mat-form-field class="">
                            <mat-label>First Name</mat-label>
                            <input matInput placeholder="First Name" formControlName="firstname">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Surname</mat-label>
                            <input matInput placeholder="Surname" formControlName="surname">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" formControlName="email">
                            <mat-error *ngIf="email.errors && email.errors.email">
                                valid email required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <cs-userdetail *ngIf="selectedUser" [user]="selectedUser"></cs-userdetail>
                    </div>
                    <div class="f1"></div>
                    <div class="tar"><button *ngIf="selectedUser" mat-raised-button color="primary" type="submit"
                            [disabled]="!selectedUserForm.dirty || !selectedUserForm.valid">Save</button></div>
                </form>
            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <ng-template matTabContent>
            <cs-document-management></cs-document-management>
        </ng-template>

    </mat-tab>

</mat-tab-group>