import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, Form } from '@angular/forms';
import { Service, ServicePort, Locode, ServiceWhereClause, ServiceUser, ServiceVia } from 'src/app/models/data';
import { DataService } from 'src/app/services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyComponent } from '../company/company.component';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { CompanyAssociation, Company, StatusMessage } from 'src/app/models/user';

import { Country, ConfirmOption, RecordStatus } from 'src/app/models/models';
import { ToolsService } from 'src/app/services/tools.service';
import { TransportModes, Rate, RateBreak, Surcharge } from 'src/app/models/rates';
import { PortSite } from 'src/app/models/ui';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';



@Component({
  selector: 'cs-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  serviceForm: FormGroup;
  public service: Service;

  public selectedRate:FormGroup;
  public editmode = true;

  public charges = false;

  transportModes = TransportModes;


  filteredProviders: Observable<CompanyAssociation[]>;
  filteredOrigins: Observable<Country[]>;
  filteredDestinations: Observable<Country[]>;
  filteredCurrencies: Observable<Country[]>;
  filteredCountryVias: Observable<Country[]>;

  originCountryLocodes: Locode[];
  filteredOriginLocodes: Observable<Locode[]>;
  destinationCountryLocodes: Locode[];
  filteredDestinationLocodes: Observable<Locode[]>;

  serviceViaLocodes: Locode[];

  filteredViaLocodes: Observable<Locode[]>;

  serviceUserCompanies: Company[];
  filteredServiceUserCompanies: Observable<CompanyAssociation[]>;

  //autocompleters
  providerGroup: FormGroup;
  providerName: FormControl;
  providerId: FormControl;
  originGroup: FormGroup;
  originName: FormControl;
  originId: FormControl;

  destinationGroup: FormGroup;
  destinationName: FormControl;
  destinationId: FormControl;

  viaGroup: FormGroup;
  viaName: FormControl;
  viaId: FormControl;

  currencyGroup: FormGroup;
  currencyName: FormControl;
  currencyId: FormControl;

  originLocodeControl: FormControl;
  destinationLocodeControl: FormControl;
  serviceUserControl: FormControl;

  serviceViasLocodeControl: FormControl;

  servicesurcharges:Surcharge[];

  public showmore = false;
  currencyCode="GBP";

  public serviceActive = false;

  public rateArray:FormArray;

  public rates:Rate[]=[];


  constructor(public dialogRef: MatDialogRef<CompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, public data: DataService, private formtools: FormtoolsService, private tools: ToolsService) {

    this.service = dialogData.Service;
    if(this.service.recordStatus==0) this.serviceActive = true;

    if(this.service.Currency) this.currencyCode = this.service.Currency.currencyCode;

    this.serviceForm = formtools.createServiceFormGroup(this.service);
    this.rateArray = <FormArray> this.serviceForm.get("rateFormArray");
    this.setStaticFormControls();
    //this.populateOptions();
    if (this.service.Origin && this.service.Origin.iso_alpha_2.length == 2) {
      this.populateLocodes(true);

    }
    if (this.service.Destination && this.service.Destination.iso_alpha_2.length == 2) {
      this.populateLocodes(false);

    }

    if (this.data.providers.length == 0) {
      this.data.listCustomers().subscribe((message:StatusMessage) => {
        if(message.success){
          let coassocs: CompanyAssociation[] = message.message;
        }
        this.populateOptions();
      },err=>{
        this.tools.gracefulError(err);
      });
    }
    //this.serviceForm.updateValueAndValidity({emitEvent:true});
    else {
      this.populateOptions();
    }



  }
  compareFreq(a,b){
    let ok = a == b;
    return ok;
  }

  ngOnInit(): void {
    if(this.service.id) this.listPortProviders();
  }

  changeTab(index:number){
    switch(index){
      case 1:

      break;
      case 2:
        if(this.rateArray.controls.length==0){
          if(this.service.id){
            this.data.listServiceRates(this.service.id,new Date()).subscribe((message:StatusMessage)=>{
              if(message.success){
                let rates:Rate[] = message.message.rates;
                this.servicesurcharges = message.message.surcharges;
                //TODO using a rate array if we expand the timeline for visible dates to include future ones
                //at present, just showing the one currently valid today.
                if(rates && rates.length>0){
                  rates.forEach(rate=>{
                    this.rateArray.push(this.formtools.createRateFormGroup(rate));
                  })
                  
                  this.rates = this.rateArray.value;
                } 
                
                /*rates.forEach(r=>{
                  ;
                })
                
                if(rates.length>0){
                  this.selectRate(rates[0]);
                }*/
              }
            })
          }
          
        }
      break;
    }
  }


  get originCountry(): FormControl {
    return <FormControl>this.serviceForm.get("originid");
  }
  get destinationCountry(): FormControl {
    return <FormControl>this.serviceForm.get("destinationid");
  }

  get transportMode(): FormControl {
    return <FormControl>this.serviceForm.get("transportMode");
  }

  setStaticFormControls() {
    this.providerGroup = <FormGroup>this.serviceForm.get('Provider');
    this.providerName = <FormControl>this.providerGroup.get('name');
    this.providerId = <FormControl>this.providerGroup.get('id');

    this.originGroup = <FormGroup>this.serviceForm.get('Origin');
    this.originName = <FormControl>this.originGroup.get("name");
    this.originId = <FormControl>this.originGroup.get("id");

    this.originLocodeControl = <FormControl>this.serviceForm.get("originLocodeControl");
    this.destinationLocodeControl = <FormControl>this.serviceForm.get("destinationLocodeControl");
    this.serviceUserControl = <FormControl>this.serviceForm.get("serviceUserControl");

    this.viaGroup = <FormGroup>this.serviceForm.get("Via");
    this.viaName = <FormControl>this.viaGroup.get("name");
    this.viaId = <FormControl>this.viaGroup.get("id");

    this.serviceViasLocodeControl = <FormControl>this.serviceForm.get("serviceViasLocodeControl");

    this.destinationGroup = <FormGroup>this.serviceForm.get('Destination');
    this.destinationName = <FormControl>this.destinationGroup.get("name");
    this.destinationId = <FormControl>this.destinationGroup.get("id");


    this.currencyGroup = <FormGroup>this.serviceForm.get('Currency');
    this.currencyName = <FormControl>this.serviceForm.get("Currency.name");
    this.currencyId = <FormControl>this.currencyGroup.get("id");

  }

  ////#region form getters
  get originServicePorts(): FormArray {
    return <FormArray>this.serviceForm.get("OriginServicePorts");
  }

  get destinationServicePorts(): FormArray {
    return <FormArray>this.serviceForm.get("DestinationServicePorts");
  }

  get serviceVias(): FormArray {
    return <FormArray>this.serviceForm.get("ServiceVias");
  }

  get serviceUsers(): FormArray {
    return <FormArray>this.serviceForm.get("ServiceUsers");
  }

  get serviceIsPublic(): FormControl {
    return <FormControl>this.serviceForm.get("isPublic");
  }
  ////#endregion

  populateOptions() {
    this.filteredProviders = this.providerName.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCompany(value))
      )
    this.filteredOrigins = this.originName.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCountry(value))
      )
    this.filteredDestinations = this.destinationName.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCountry(value))
      )
    this.filteredCurrencies = this.currencyName.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCountry(value))
      )
    this.filteredOriginLocodes = this.originLocodeControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterLocode(value, true))
      )
    this.filteredDestinationLocodes = this.destinationLocodeControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterLocode(value, false))
      )
    this.filteredServiceUserCompanies = this.serviceUserControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCompany(value))
      )
    this.filteredCountryVias = this.viaName.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCountry(value))
      )
    this.filteredViaLocodes = this.serviceViasLocodeControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterViaLocode(value))
      )
  }
   populateLocodes(origin: boolean, via?: boolean) {
    let where = new ServiceWhereClause();
    if (via) {
      where.countrycode = this.viaGroup.value.iso_alpha_2;
    }
    else {
      if (origin) {
        where.countrycode = this.originGroup.value.iso_alpha_2;
      }
      else {
        where.countrycode = this.destinationGroup.value.iso_alpha_2;
      }
    }

    let tm = this.tools.mapTransportModeToPortType(this.serviceForm.get("transportMode").value);
    where.functions = [tm.toString()];

    this.data.listLocodesByCountry(where).subscribe((message: StatusMessage) => {
      if (message.success) {
        if (via) {
          this.serviceViaLocodes = message.message;
          this.filterViaLocode("");
          this.serviceViasLocodeControl.updateValueAndValidity({ emitEvent: true });
        }
        else {
          if (origin) {
            this.originCountryLocodes = message.message;
            this.filterLocode("", true);
            this.originLocodeControl.updateValueAndValidity({ emitEvent: true });
          }
          else {
            this.destinationCountryLocodes = message.message;
            this.filterLocode("", false);
            this.destinationLocodeControl.updateValueAndValidity({ emitEvent: true });
          }
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    })
  }
  filterCompany(value: string | Company): CompanyAssociation[] {
    let filterValue = "";
    if (value) {
      filterValue = typeof value === "string" ? value.toLowerCase() : value.name.toLowerCase();
      const split = filterValue.split(' ');
      let prereg = "";
      split.forEach(sp => {
        prereg += "(?=.*" + sp + ")";
      })
      const regex = new RegExp(prereg);
      return this.data.providers.filter(({ Provider }) => Provider.name.toLowerCase().match(regex));

    }
    else return this.data.providers;

  }
  displayProviderWith(company: Company) {
    if (typeof company === "string") return company;
    return company && company.name ? company.name : '';
  }
  chooseProvider(e) {
    this.providerId.setValue(e.option.value.id);
    this.serviceForm.get("providerid").setValue(e.option.value.id);
  }
  chooseFrequency(e) {

  }

  filterCountry(value: string | Country): Country[] {
    let filterValue = "";
    if (value) {
      filterValue = typeof value === "string" ? value.toLowerCase() : value.name.toLowerCase();
      const split = filterValue.split(' ');

      //doesn't pick up United Kingdom from UK
      if (filterValue.indexOf("uk") >= 0) {
        split.push('gbr');
      }
      let prereg = "";
      split.forEach(sp => {
        prereg += "(?=.*" + sp + ")";
      })
      let orreg = split.join('|');
      const orregex = new RegExp(orreg);
      const regex = new RegExp(prereg);
      return this.data.countries.filter(country => country.name.toLowerCase().match(regex) || country.iso_alpha_2.toLowerCase().match(orregex) || country.iso_alpha_3.toLowerCase().match(orregex));
    }
    else return this.data.countries;

  }
  displayCountryWith(country: Country) {
    if (typeof country === "string") return country;
    return country && country.name ? country.name : "";
  }
  displayCurrencyWith(country: Country) {
    if (typeof country === "string") return country;
    return country && country.name && country.currencySymbol ? country.name + " (" + country.currencySymbol + ")" : "";
  }
  chooseOrigin(e) {
    this.originId.setValue(e.option.value.id);
    this.originCountry.setValue(e.option.value.id);
    //this.setCountryProperties(this.originGroup,e.option.value);
    //reset after displayWith
    this.originGroup.patchValue(e.option.value);
    //clear existing portcodes (different country now)
    this.originServicePorts.controls = [];
    this.originServicePorts.value.forEach(element => {
      element.recordStatus = RecordStatus.Deleted;
    });
    this.originServicePorts.markAsDirty();
    this.populateLocodes(true);
    this.originLocodeControl.setValue("");
  }
  chooseDestination(e) {
    this.destinationId.setValue(e.option.value.id);
    this.destinationCountry.setValue(e.option.value.id);
    //this.setCountryProperties(this.destinationGroup,e.option.value);
    this.destinationGroup.patchValue(e.option.value);
    //clear existing portcodes (different country now)
    this.destinationServicePorts.controls = [];
    this.destinationServicePorts.value.forEach(element => {
      element.recordStatus = RecordStatus.Deleted;
    });
    this.destinationServicePorts.markAsDirty();
    this.populateLocodes(false);

    this.destinationLocodeControl.setValue("");

  }
  chooseVia(e) {
    this.viaId.setValue(e.option.value.id);
    this.viaGroup.patchValue(e.option.value);
    this.populateLocodes(false, true);
  }
  setCountryProperties(group: FormGroup, value: any) {
    group.get("id").setValue(value.id);
    //group.get("name").setValue(value.name);
    group.get("iso_alpha_2").setValue(value.iso_alpha_2);
  }
  chooseCurrency(e) {
    this.currencyId.setValue(e.option.value.id);
    this.currencyGroup.patchValue(e.option.value);
  }

  addOriginPort() {
    let portgroupform = this.formtools.createServicePortFormGroup(true);
    this.originServicePorts.push(portgroupform);


  }
  addDestinationPort() {
    let portgroupform = this.formtools.createServicePortFormGroup(false);
    this.destinationServicePorts.push(portgroupform);
  }

  displayPortWith(locode: Locode) {
    if (typeof locode === "string") return locode;
    return locode && locode.name ? locode.name : '';
  }
  filterLocode(value: string | Locode, origin: boolean): Locode[] {
    let filterValue = "";
    if (value) {
      filterValue = typeof value === "string" ? value.toLowerCase() : value.name.toLowerCase();
      const split = filterValue.split(' ');

      let prereg = "";
      split.forEach(sp => {
        prereg += "(?=.*" + sp + ")";
      })
      const regex = new RegExp(prereg);
      if (origin) {
        return this.originCountryLocodes.filter(loc => loc.locode2.toLowerCase().match(regex) || loc.locode1.toLowerCase().match(regex) || loc.name.toLowerCase().match(regex) || loc.nodiacritic.toLowerCase().match(regex))
      }
      else {
        return this.destinationCountryLocodes.filter(loc => loc.locode2.toLowerCase().match(regex) || loc.locode1.toLowerCase().match(regex) || loc.name.toLowerCase().match(regex) || loc.nodiacritic.toLowerCase().match(regex))
      }
    }
    else {
      if (origin) {
        return this.originCountryLocodes ? this.originCountryLocodes : [];
      }
      else {
        return this.destinationCountryLocodes ? this.destinationCountryLocodes : [];
      }
    }

  }
  filterViaLocode(value: string | Locode): Locode[] {
    let filterValue = "";
    if (value) {
      filterValue = typeof value === "string" ? value.toLowerCase() : value.name.toLowerCase();
      const split = filterValue.split(' ');

      let prereg = "";
      split.forEach(sp => {
        prereg += "(?=.*" + sp + ")";
      })
      const regex = new RegExp(prereg);
      return this.serviceViaLocodes.filter(loc => loc.locode2.toLowerCase().match(regex) || loc.locode1.toLowerCase().match(regex) || loc.name.toLowerCase().match(regex) || loc.nodiacritic.toLowerCase().match(regex))

    }
    else {
      //this.serviceViasLocodeControl.updateValueAndValidity({onlySelf:false,emitEvent:true});
      return this.serviceViaLocodes ? this.serviceViaLocodes : [];
    }

  }

  choosePort(e, origin: boolean) {
    console.log(e, origin);
    let serviceport = new ServicePort();
    serviceport.serviceid = this.service.id;
    serviceport.origin = origin;
    serviceport.Locode = e.option.value;
    serviceport.locodeid = serviceport.Locode.id;
    if (origin) {
      let portgroupform = this.formtools.createServicePortFormGroup(origin, serviceport);
      this.originServicePorts.push(portgroupform);
      this.originLocodeControl.setValue("");
    }
    else {
      let portgroupform = this.formtools.createServicePortFormGroup(origin, serviceport);
      this.destinationServicePorts.push(portgroupform);
      this.destinationLocodeControl.setValue("");
    }
    //console.log(this.originServicePorts.controls[0]);
  }
  chooseViaPort(e) {
    let serviceVia = new ServiceVia();

    serviceVia.serviceid = this.service.id;
    serviceVia.countryid = this.viaGroup.value.id;
    serviceVia.Country = this.data.countries.filter(c => c.id == serviceVia.countryid)[0];
    serviceVia.Locode = e.option.value;
    serviceVia.locodeid = serviceVia.Locode.id;
    let svform = this.formtools.createServiceViaFormGroup(serviceVia);
    this.serviceVias.push(svform);
    this.serviceViasLocodeControl.setValue("");
  }
  removePort(index: number, origin: boolean) {
    if (origin) {
      this.originServicePorts.controls.splice(index, 1);
      this.originServicePorts.value[index].recordStatus = RecordStatus.Deleted;
      this.originServicePorts.markAsDirty();
    }
    else {
      this.destinationServicePorts.controls.splice(index, 1);
      this.destinationServicePorts.value[index].recordStatus = RecordStatus.Deleted;
      this.destinationServicePorts.markAsDirty();
    }
  }
  removeVia(index: number) {
    this.serviceVias.controls.splice(index, 1);
    this.serviceVias.value[index].recordStatus = RecordStatus.Deleted;
    this.serviceVias.markAsDirty();
  }
  chooseTransport(e) {
    console.log(e);
    if (this.originId.valid) {
      this.populateLocodes(true);
    }
    if (this.destinationId.valid) {
      this.populateLocodes(false);
    }
    if (this.viaId.value) {
      this.populateLocodes(false, true);
    }
  }
  chooseUser(e) {
    let serviceuser = new ServiceUser();
    serviceuser.serviceid = this.service.id;
    serviceuser.recordStatus = 0;
    serviceuser.userCompanyid = e.option.value.id;
    serviceuser.UserCompany = e.option.value;
    let serviceuserform = this.formtools.createServiceUserFormGroup(serviceuser);
    this.serviceUsers.push(serviceuserform);
    this.serviceUserControl.setValue("");
  }
  removeServiceUser(index: number) {
    this.serviceUsers.controls.splice(index, 1);
    this.serviceUsers.value[index].recordStatus = RecordStatus.Deleted;
    this.serviceUsers.markAsDirty();
  }

  partner(sp: ServicePort) {
    this.charges = !this.charges;
  }
  viewCharges() {
    this.charges = !this.charges;
  }

  logForm() {
    console.log(this.serviceForm);
  }
  submit() {
    
    let newservice: Service = this.serviceForm.value;
    this.rebuildCountries(newservice);
    this.formtools.serviceSetDirty(this.service, newservice);
    
    
    this.data.createOrUpdateService(newservice,this.serviceForm.dirty).then((servmessage:StatusMessage)=>{
      if(servmessage.success){
        //this.service = newservice; // this will kill rates etc do not do, but do update it before closing dialog.
        this.service.id = servmessage.message.id;
        this.serviceForm.get("id").setValue(this.service.id);

        let rateformarray:FormArray = <FormArray> this.serviceForm.get("rateFormArray");
        
        rateformarray.controls.forEach(r=>{
          r.get("serviceid").setValue(this.service.id);
        })
        
        let done = [false,false];
        
        this.data.createOrUpdateRates(rateformarray).then((ratemessage:StatusMessage)=>{
          if(ratemessage.success){
            //method updates rateform with ids if necessary
            done[0]=true;
            if(done.indexOf(false)==-1){
              this.resetForm();
            }
          }
          else this.tools.gracefulError(ratemessage.success);
        })

        let surchargeformarray = <FormArray> this.serviceForm.get("surchargeFormArray");
        surchargeformarray.controls.forEach(s=>{
          s.get("serviceid").setValue(this.service.id);
        })

        this.data.createOrUpdateSurcharges(surchargeformarray).then((surmessage:StatusMessage)=>{
          if(surmessage.success){
            //method updates rateform with ids if necessary
            done[1]=true;
            if(done.indexOf(false)==-1){
              this.resetForm();
            }
          }
          else this.tools.gracefulError(surmessage.success);
        })
      }
    })
  }
  resetForm(){
    this.serviceForm.reset(this.serviceForm.value);
    this.serviceForm.updateValueAndValidity();
    this.rates = this.rateArray.value;
  }
  done(){
    this.dialogRef.close(this.serviceForm.value);// hopefully we're already done with changes
  }
  listPortProviders(){
    this.data.listServicePortSites(this.service.id).subscribe((message:StatusMessage)=>{
      if(message.success){
        let portsites:PortSite[] = message.message;
        this.originServicePorts.controls.concat(this.destinationServicePorts.controls).forEach(spform=>{
          let providerarray = <FormArray> spform.get("Providers");
          let locodeid = spform.get("locodeid").value;
          this.formtools.createProviderForm(providerarray,portsites.filter(ps=>ps.locodeid == locodeid))
        })
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }
  serviceStatus(e){
    let rs = this.serviceForm.get("recordStatus");
    if(e.checked){
      rs.setValue(0)
    }
    else rs.setValue(RecordStatus.Suspended);
    rs.markAsDirty();
  }
  
  cancel() {
    if (this.serviceForm.dirty) {
      this.tools.confirm("You have unsaved information do you wish to discard it?", true, true).then((result: ConfirmOption) => {
        if (result == ConfirmOption.YES) {
          this.dialogRef.close();
        }
      })
    }
    else {
      this.dialogRef.close();
    }
  }
  rebuildCountries(service: Service) {
    service.Origin = this.data.countries.filter(c => c.id == service.originid)[0];
    service.Destination = this.data.countries.filter(c => c.id == service.destinationid)[0];
    service.Currency = this.data.countries.filter(c => c.id == service.Currency.id)[0];
    service.currencyid = service.Currency.id;
  }

  selectRate(rate:Rate) {
    
    this.rateArray.controls.some((c:FormGroup)=>{
      if(c.value.id==rate.id){
        if(this.selectedRate && this.selectedRate.value.id==c.value.id){
          this.selectedRate = null;
        }
        else this.selectedRate = c;
      }
    })
  }
  public tempregionid = 1;
  public tempparentid = 1;

  addRate() {
    let rate = new Rate(this.tempregionid, 1, this.transportMode.value);
    rate.parentregionid = this.tempparentid;
    rate.createdAt = new Date();
    rate.name = this.serviceForm.get("displayname").value;
    this.editmode = true;
    this.rateArray.push(this.formtools.createRateFormGroup(rate));
    this.rates = this.rateArray.value;
  }

  addDummyRate() {
    let rate = new Rate(this.tempregionid, 1, this.transportMode.value);
    rate.parentregionid = this.tempparentid;
    rate.createdAt = new Date();
    rate.name = this.serviceForm.get("displayname") + " (example)";
    rate.baseprice = 108;
    rate.baseIncludesVolume = 1;
    rate.baseIncludesWeight = 2000;
    rate.createdAt = new Date();
    this.editmode = true;
    
    let rb = new RateBreak();
    rb.maxweight = 22000;
    rb.maxvolume = 22;
    rb.price = 54;
    rb.weightbreak = 1000;
    rate.RateBreaks.push(rb);
    this.rateArray.push(this.formtools.createRateFormGroup(rate));
    this.rates = this.rateArray.value;
  }

}
