import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { StatusMessage, CompanyAssociation, Company, CompanySetting } from 'src/app/models/user';
import { GPS, TransportModes, Region, RatePriority, Rate, QuotationPrice, Margin, Discount, MarginAppliesTo, SurchargeAppliesTo, Surcharge, Size, MinimumMargin, ModeMetric, SavedQuote } from 'src/app/models/rates';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialog } from '@angular/material/dialog';
import { LocationpickerComponent } from 'src/app/dialogs/locationpicker/locationpicker.component';
import { DataService } from 'src/app/services/data.service';
import { CalculationsService } from 'src/app/services/calculations.service';
import { PreferredPorts, SiteRegion, Quotation, QuoteInput, Priority, Terms } from 'src/app/models/ui';
import { TransitStepsComponent } from 'src/app/snippets/transit-steps/transit-steps.component';
import ExchangeRate, { PortToPort } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormArray } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { MatTabGroup } from '@angular/material/tabs';
import { QuoteActionsComponent } from 'src/app/dialogs/quote-actions/quote-actions.component';


@Component({
  selector: 'cs-quoter',
  templateUrl: './quoter.component.html',
  styleUrls: ['./quoter.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('0.3s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class QuoterComponent implements OnInit {

  @Input() coid: number;
  @Input() customer = false;
  @Input() uselatest = false;
  @Input() incoming:number;

  @ViewChildren('tabportoptions') tabs: QueryList<MatTabGroup>;
  @ViewChild('transit-steps') transitsteps: TransitStepsComponent;
  @ViewChildren('itemstab') itemstabs: QueryList<MatTabGroup>;

  public showInput = false;

  public isInitial = true;

  public startGPS: GPS;
  public quoteInput: QuoteInput = new QuoteInput();
  public selectedItem = 0;

  private ratePriorities: RatePriority[] = [];

  //public selectedService: TransportSteps;

  private customerid = 0;
  private providermarginsdone: number[] = [];
  private margins: Margin[] = [];
  private discounts: Discount[] = [];
  private surcharges: Surcharge[] = [];

  public quote: Quotation;
  public quotation: FormGroup;

  public modes = TransportModes;

  public selectedService: PreferredPorts;

  //public steps: TransportSteps[] = [];

  public get portoptions(): FormArray {
    let po = <FormArray>this.quotation.get("portoptions");
    return po;
  }

  public collectionOptionsIndex = 0;
  public deliveryOptionsIndex = 0;

  public collectionPriorityIndex = 0;
  public deliveryPriorityIndex = 0;

  public balance = 5;
  public minmargin: MinimumMargin;

  public settings: CompanySetting[] = [];

  constructor(public tools: ToolsService, public dialog: MatDialog, public data: DataService, public calc: CalculationsService, public events: EventsService, public formtools: FormtoolsService) {

    this.events.companyselect.subscribe((co: CompanyAssociation) => {
      if (co.Customer) {
        this.data.selectedCustomer = co;
        this.customerid = co.Customer.id;
        this.getDiscounts().then(() => {
          this.applyDiscounts();
        });

      }
    })
  }

  ngOnInit(): void {
    if(this.incoming && this.coid){
      this.showInput = false;
      this.data.getSavedQuote(this.incoming).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.quote = this.calc.unmap(message.message);
          this.convertQuote();
        }
      })

      
    }
    if (this.data.dataIsReady) {
      this.setup();
    }
    else {
      this.data.dataReady.subscribe(() => {
        this.setup();
      })
    }
    this.events.quoteoptionselect.subscribe((index: number) => {
      this.quotation.get("selectedOption").setValue(index);
      this.quotation.markAsDirty();
    })

  }

  setup() {
    this.quoteInput.quoteItems[0].ratebracket = this.data.ratebrackets.filter(rb => rb.name == "Pallet")[0].id;
    this.settings = this.data.Company.CompanySettings || [];
    this.customerid = this.data.Company.id;
    if (!this.data.Company.Sites) {
      this.data.getCompanyPublic(this.data.Company.id).subscribe((message: StatusMessage) => {
        this.data.Company = message.message;
      })
    }
    if (this.data.selectedCustomer) {
      this.customerid = this.data.selectedCustomer.companyid;//another reason to fix provider/customer issue
    }

    this.data.listSurchargesCompany(this.data.Company.id, SurchargeAppliesTo.Company).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.surcharges = message.message;
      }
    })
    this.getDiscounts().then(() => {
      if (this.uselatest) this.fromStore();
      else if(!this.incoming) this.showInput = true;
      //else {
      //  this.defaultSize();
      //}
    })
    /*
    setTimeout(() => {
      this.sizer.first.setup();
    })*/


  }
  defaultSize() {
    this.quoteInput.quoteItems[0].weight = 300;
    this.quoteInput.quoteItems[0].quantity = 1;
    this.quoteInput.quoteItems[0].ratebracket = this.data.ratebrackets.filter(rb => rb.name == "Pallet")[0].id;
    this.quoteInput.quoteItems[0].width = 500;
    this.quoteInput.quoteItems[0].height = 500;
    this.quoteInput.quoteItems[0].depth = 500;
    this.quoteInput.quoteItems[0].stackable = true;
  }
  swap() {

  }
  /**
   * get a list of public and provider specific margins
   * @param cas CompanyAssociation
   * returns a list of margins to be filtered by appliesto and providerid
   */
  getInitialMargins(cas: CompanyAssociation[]) {
    return new Promise((resolve) => {
      let todo = cas.length;
      if (todo == 0) resolve(true);
      else {
        //let done = 0;
        let providers: number[] = [];
        if (this.providermarginsdone.indexOf(0) < 0) providers.push(0);
        cas.forEach(ca => {
          if (this.providermarginsdone.indexOf(ca.customerid) < 0) providers.push(ca.customerid);
        })

        this.data.listGlobalMarginsAllMultipleProviders(providers).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.providermarginsdone = this.providermarginsdone.concat(providers);
            this.margins = this.margins.concat(message.message);
          }
          else {
            this.tools.gracefulError(message.message);
          }
          //done++;
          //if (done == todo) {
          resolve(true);
          //}
        }, err => {
          this.tools.gracefulError(err);
          //done++;
          //if (done == todo) {
          resolve(true);
          //}
        })
      }

    })

  }
  getProviderMargins(providerid: number) {
    return new Promise((resolve) => {
      if (this.providermarginsdone.indexOf(providerid) >= 0) {
        resolve(this.margins.filter(m => m.providerid == providerid || m.providerid == 0));
      }
      else {
        this.data.listGlobalMargins(providerid).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.providermarginsdone.push(providerid);
            let margins = message.message;
            this.margins = this.margins.concat(margins);
          }
          resolve(this.margins.filter(m => m.providerid == providerid || m.providerid == 0));
        }, err => {
          this.tools.gracefulError(err);
          resolve(this.margins.filter(m => m.providerid == providerid || m.providerid == 0));
        })
      }
    })
  }

  /**
   * get a list of National Rate Priorities (express, standard, by 12pm Saturday) with which to hold rates.
   * @param cas Company Association
   * returns and array of RatePriorities
   */
  getPriorities(cas: CompanyAssociation[]) {
    return new Promise((resolve) => {
      let todo = cas.length;
      if (todo == 0) resolve(false);
      else {
        let done = 0;
        cas.forEach(ca => {
          this.data.listRatePriorities(ca.customerid, 3, this.customerid).subscribe((message: StatusMessage) => {
            if (message.success) {
              ca.Priorities = message.message;
            }
            else {
              this.tools.gracefulError(message.message);
            }
            done++;
            if (done == todo) {
              resolve(true);
            }
          }, err => {
            this.tools.gracefulError(err);
            done++;
            if (done == todo) {
              resolve(true);
            }
          })
        })
      }


    })
  }
  /**
   * get a list of customer specific or public discounts
   */
  getDiscounts() {
    return new Promise((resolve) => {
      this.data.listDiscountsAll(this.customerid).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.discounts = message.message;
          this.data.getMinimumMargin(this.customerid).subscribe((message: StatusMessage) => {
            if (message.success && message.message) {
              this.minmargin = message.message;
            }
            else {
              //this.tools.gracefulError(message.message);
              this.minmargin = new MinimumMargin(this.data.Company.id, this.customerid);
            }
            resolve(true);
          })
          //recalc all the pricing

        }
        else {
          resolve(true);
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err)
        resolve(true);
      }
      );
    })

  }
  applyDiscounts() {
    this.quote.portoptions.forEach(po => {
      if (po.originSites) {
        po.originSites.forEach(os => {
          if (os.hasCollection) {
            os.priorities.forEach(p => {
              this.calc.discount(p.collection, this.discounts, MarginAppliesTo.Collection_Rates, this.data.defaultCurrency.id);
            })

          }
          if (os.surcharges) {
            os.surcharges.forEach(surch => {
              this.calc.discount(surch, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id);
            })
          }
        })
      }
      po.servicerates.forEach(sr => {
        this.calc.discount(sr, this.discounts, MarginAppliesTo.Service_Charges, this.data.defaultCurrency.id);
      })
      po.servicesurcharges.forEach(surch => {
        this.calc.discount(surch, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id);
      })
      if (po.destinationSites) {
        po.destinationSites.forEach(os => {
          if (os.hasCollection) {
            os.priorities.forEach(p => {
              this.calc.discount(p.collection, this.discounts, MarginAppliesTo.Delivery_Rates, this.data.defaultCurrency.id);
            })

          }
          if (os.surcharges) {
            os.surcharges.forEach(surch => {
              this.calc.discount(surch, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id);
            })
          }
        })
      }
      po.quotesurcharges.forEach(qp => {
        this.calc.discount(qp, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id);
      })

    })
    this.quotation.patchValue(this.quote);
    let index = 0;
    this.portoptions.controls.forEach((poform: FormGroup) => {
      this.calculateTotals(poform, true, this.quote.portoptions[index]);
      index++;
    })
  }
  selectLocation(origin?: boolean) {
    const dialogRef = this.dialog.open(LocationpickerComponent, {
      width: 'auto',
      data: { locationtype: origin ? "collection" : "delivery" }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let address = this.tools.addressFromGooglePlace([result.place], this.data.countries);
        if (origin) this.quoteInput.origin = address;
        else this.quoteInput.destination = address;
        if (this.quoteInput.origin && this.quoteInput.destination) {
          //this.quoteInput.originWaypoint = { name: "origin", gps: new google.maps.LatLng(this.quoteInput.origin.gpslat, this.quoteInput.origin.gpslong) };
          //this.quoteInput.destinationWaypoint = { name: "destination", gps: new google.maps.LatLng(this.quoteInput.destination.gpslat, this.quoteInput.destination.gpslong) };

        }
      }
    });
  }
  resetTotals() {
    this.quoteInput.totalarea = 0;
    this.quoteInput.totalquantity = 0;
    this.quoteInput.totalvolume = 0;
    this.quoteInput.totalweight = 0;
    this.quoteInput.quoteItems.forEach(qi => {
      this.quoteInput.totalweight += qi.weight * qi.quantity;
      this.quoteInput.totalvolume += (qi.depth / 1000 * qi.width / 1000 * qi.height / 1000) * qi.quantity;
      this.quoteInput.totalquantity += qi.quantity;
      this.quoteInput.totalarea += (qi.depth / 1000 * qi.width / 1000) * qi.quantity;
    })
  }
  calculate2() {
    this.isInitial = true;
    this.quote = null;
    this.calc.defaultCurrency = this.data.defaultCurrency.id;

    this.getExchangeRates().then((success) => {
      if (success) {
        this.ratePriorities = [];
        this.showInput = false;

        this.resetTotals();

        if (this.quoteInput.origin.countryid == this.quoteInput.destination.countryid) {
          //national service
        }
        else if (this.quoteInput.origin.Country.continentid == this.quoteInput.destination.Country.continentid) {
          //continental service
        }
        else {
          //international service
          this.calculateInternational().then(success => {
            if (success) {

              this.eachPortOption(this.quote.portoptions, 0, this.quote.quoteInput.servicedate).then(success => {
                this.quote.portoptions.forEach(po => {
                  po.destinationSiteIndex = 0;
                  po.originSiteIndex = 0;
                })
                if (success) {
                  this.finish();
                }
              })
            }
            else this.tools.gracefulError("Failed to calculate rate");
          });
        }
      }
      else {
        this.tools.gracefulError("Failed to get current exchange rates");
      }
    })


  }
  eachPortOption(preferredports: PreferredPorts[], portIndex: number, servicedate: Date) {
    return new Promise(resolve => {
      if (portIndex < preferredports.length) {
        let po = preferredports[portIndex];
        if (po.originSites) {

          this.eachOriginSite(po.originSites, po, 0, true).then(() => {
            if (po.destinationSites) {
              this.eachOriginSite(po.destinationSites, po, 0, false).then(() => {
                this.handleServiceCharges(po, preferredports, portIndex, servicedate).then(success => {
                  resolve(success);
                });
              })
            }
            else {
              this.handleServiceCharges(po, preferredports, portIndex, servicedate).then(success => {
                resolve(success);
              });
            }
          })
        }
        else {
          if (po.destinationSites) {
            this.eachOriginSite(po.destinationSites, po, 0, false).then(() => {
              this.handleServiceCharges(po, preferredports, portIndex, servicedate).then(success => {
                resolve(success);
              });
            })
          }
          else {
            this.handleServiceCharges(po, preferredports, portIndex, servicedate).then(success => {
              resolve(success);
            });
          }
        }
      }
      else {
        resolve(true);
      }


    })
  }

  handleServiceCharges(po: PreferredPorts, preferredports: PreferredPorts[], portIndex: number, servicedate: Date) {
    return new Promise(resolve => {
      this.serviceCharges(po, servicedate).then(() => {
        portIndex++;
        this.eachPortOption(preferredports, portIndex, servicedate).then(success => {
          resolve(success);
        });
      })
    })

  }

  private originsdone: SiteRegion[] = [];
  private destinationsdone: SiteRegion[] = [];

  eachOriginSite(originSites: SiteRegion[], po: PreferredPorts, osIndex: number, origin: boolean) {
    return new Promise(resolve => {
      if (osIndex < originSites.length) {



        let os = originSites[osIndex];

        let existingsite: SiteRegion;
        if (origin) {
          let existingsites = this.originsdone.filter(od => od.site.id == os.site.id);
          if (existingsites.length == 1) existingsite = existingsites[0];
        }
        else {
          let existingsites = this.destinationsdone.filter(od => od.site.id == os.site.id);
          if (existingsites.length == 1) existingsite = existingsites[0];
        }

        if (existingsite) {
          os.rateRegion = existingsite.rateRegion;
          os.priorities = existingsite.priorities;
          os.surcharges = existingsite.surcharges;
          os.hasCollection = existingsite.hasCollection;
          os.hasSurcharges = existingsite.hasSurcharges;
          osIndex++;
          this.eachOriginSite(originSites, po, osIndex, origin).then(success => {
            resolve(success);
          });
        }
        else {
          this.populatePortSites(os, po, origin, po.provider).then((success) => {
            let company = new Company();
            let exists = this.data.providers.filter(p => p.customerid == os.site.companyid)[0].Provider;
            company.id = exists.id;
            company.name = exists.name;

            os.site.Company = company;
            if (origin) {
              this.originsdone.push(os);
            }
            else {
              this.destinationsdone.push(os);
            }
            if (!success) {
              console.log("failed to populate Port information for " + os.site.name + " service: " + po.service.id);
            }
            osIndex++;
            this.eachOriginSite(originSites, po, osIndex, origin).then(success => {
              resolve(success);
            });
          })

        }




      }
      else {
        resolve(true);
      }



    })
  }

  /**
   * clean up port option sites by removing sites with no collection or surcharges
   */
  finish() {
    this.quote.quoteInput = this.quoteInput;

    this.quote.portoptions.forEach(po => {
      if (po.originSites) {
        let oscount = po.originSites.length;
        for (var i = oscount - 1; i >= 0; i--) {
          if (!po.originSites[i].hasCollection && !po.originSites[i].hasSurcharges) {
            po.originSites.splice(i, 1);
          }
        }
      }
      if (po.destinationSites) {
        let dscount = po.destinationSites.length;
        for (var i = dscount - 1; i >= 0; i--) {
          if (!po.destinationSites[i].hasCollection && !po.destinationSites[i].hasSurcharges) {
            po.destinationSites.splice(i, 1);
          }
        }
      }
      if (!po.balance) {
        po.balance = new QuotationPrice();
      }
    })
    //calculate the totals here before writing the form
    //now write the CO2 (ideally do this before)
    this.writeForm(true, true);
  }
  writeForm(doall?: boolean, initial?: boolean) {
    console.log(this.quote);
    this.quotation = this.formtools.createQuotationForm(this.quote);
    let index = 0;
    this.portoptions.controls.forEach((pp: FormGroup) => {
      this.calculateTotals(pp, doall, initial ? this.quote.portoptions[index] : null);
      index++;
    })
    //do the CO2 calcs
    this.portoptionsteps(0).then(() => {
      this.calc.flagTheBest(this.quote);
      let saved = this.calc.mapSavedQuote(this.quote, this.data.Company.id, "Draft Quote", this.data.auth.user.id);
      this.tools.toStore("quote", saved);
      this.events.stopSpinner();
      this.events.quoteready.emit();
      setTimeout(() => {
        if (!this.tabs.first) {
        }
        else this.tabs.first.realignInkBar();
      });

    })

  }


  portoptionsteps(index: number) {

    return new Promise(resolve => {
      if (index < this.quote.portoptions.length) {
        let pp = this.quote.portoptions[index];
        this.calc.terms(pp);
        this.calc.calculateSteps(this.quote, pp).then(() => {
          index++;
          let stepindex = 0;
          pp.metrics = new ModeMetric();
          pp.steps.forEach(p => {
            pp.metrics.duration += p.duration;
            pp.metrics.co2 += p.co2;
            pp.metrics.km += p.km;
            p.index = stepindex;
            stepindex++;
          })
          this.portoptionsteps(index).then(success => {
            resolve(success);
          });
        })
      }
      else resolve(true);

    })


  }

  calculateTotals(option: FormGroup, doallcollections?: boolean, po?: PreferredPorts) {

    let totalqp = new QuotationPrice();

    totalqp.description = "Total";

    let osa: FormArray = <FormArray>option.get("originSites");

    let osi = option.get("originSiteIndex").value;

    if (doallcollections) {
      let cindex = 0;
      osa.controls.forEach((os: FormGroup) => {
        this.collectionTotal(os, totalqp, cindex != osi);
        cindex++;
      })

    }
    else {
      if (osa.controls.length > 0) {
        let os = <FormGroup>osa.controls[osi];
        this.collectionTotal(os, totalqp);
      }
    }


    let dsa: FormArray = <FormArray>option.get("destinationSites");
    let dsi = option.get("destinationSiteIndex").value;
    if (doallcollections) {
      let cindex = 0;
      dsa.controls.forEach((ds: FormGroup) => {
        this.collectionTotal(ds, totalqp, cindex != dsi);
        cindex++;
      })

    }
    else {
      if (dsa.controls.length > 0) {
        let ds = <FormGroup>dsa.controls[dsi];
        this.collectionTotal(ds, totalqp);
      }
    }

    let servicerates = <FormArray>option.get("servicerates");
    servicerates.controls.forEach(sr => {
      this.combineQuotationPrice(totalqp, sr.value);
    })
    let servicesurcharges = <FormArray>option.get("servicesurcharges");
    servicesurcharges.controls.forEach(ss => {
      this.combineQuotationPrice(totalqp, ss.value);
    })
    let quotesurcharges = <FormArray>option.get("quotesurcharges");
    quotesurcharges.controls.forEach(qsurch => {
      this.combineQuotationPrice(totalqp, qsurch.value);
    })
    totalqp.costprice = this.tools.cleanRound(totalqp.costprice, 2);
    totalqp.listprice = this.tools.cleanRound(totalqp.listprice, 2);
    totalqp.discountedprice = this.tools.cleanRound(totalqp.discountedprice, 2);
    totalqp.margin = this.tools.cleanRound((totalqp.discountedprice - totalqp.costprice) / totalqp.discountedprice * 100, 2);

    if (totalqp.margin < this.minmargin.percent || totalqp.discountedprice - totalqp.costprice < this.minmargin.fixed) {
      let currentdisc = totalqp.discountedprice;
      let fixeddiff = (currentdisc - totalqp.costprice) - this.minmargin.fixed;
      let balance = 0;
      if (fixeddiff < 0) {
        balance = -fixeddiff;
        totalqp.discountedprice += this.balance;
        totalqp.margin = this.tools.cleanRound((totalqp.discountedprice - totalqp.costprice) / totalqp.discountedprice * 100, 2);
      }
      if (totalqp.margin < this.minmargin.percent) {
        let price = (-100 * totalqp.costprice) / (this.minmargin.percent - 100);
        balance = this.tools.cleanRound(price - currentdisc, 2);
        totalqp.margin = this.minmargin.percent;
        totalqp.discountedprice = this.tools.cleanRound(price, 2);
      }
      let balanceform = option.get('balance');
      balanceform.get('discountedprice').setValue(balance);
      balanceform.get('margin').setValue(100);
      if (po) {
        po.balance = balanceform.value;
      }
    }
    option.get("total").patchValue(totalqp);
    if (po) {
      po.total.costprice = totalqp.costprice;
      po.total.discountedprice = totalqp.discountedprice;
      po.total.listprice = totalqp.listprice;
      po.total.margin = totalqp.margin;
    }

  }
  collectionTotal(os: FormGroup, totalqp: QuotationPrice, ignoretotal?: boolean) {

    if (os) {
      let subtotal = 0;
      let array: FormArray = <FormArray>os.get("priorities");
      let index = os.get("selectedPriority");
      if (array.controls && array.controls.length > 0) {
        let col = <QuotationPrice>array.controls[index.value].get("collection").value;
        if (col.discountedprice) {
          subtotal += this.tools.cleanRound(this.tools.cleanFloat(col.discountedprice), 2);
        }
        if (!ignoretotal) this.combineQuotationPrice(totalqp, col);
      }

      let dssurch = <FormArray>os.get("surcharges");
      dssurch.controls.forEach(surch => {
        let sv = <QuotationPrice>surch.value;
        subtotal += this.tools.cleanRound(this.tools.cleanFloat(sv.discountedprice), 2);
        if (!ignoretotal) this.combineQuotationPrice(totalqp, sv);
      })
      os.get('totalCollection').setValue(subtotal);
    }
  }
  combineQuotationPrice(total: QuotationPrice, price: QuotationPrice) {
    total.costprice += this.tools.cleanRound(price.costprice, 2);
    total.discountedprice += this.tools.cleanRound(price.discountedprice, 2);
    total.listprice += this.tools.cleanRound(price.listprice, 2);
    if (total.discountedprice >= 0) {
      total.margin = this.tools.cleanRound((total.discountedprice - total.costprice) / total.discountedprice * 100, 2);
    }
  }
  fromStore() {
    this.tools.fromStore("quote").then((q: SavedQuote) => {

      if (q) {
        this.quote = this.calc.unmap(q);
        this.convertQuote();
        //this.writeForm(true);
      }
      else {
        this.showInput = true;
      }
    });
  }
  convertQuote(){
    this.quoteInput = this.quote.quoteInput;
    if (!this.quote.currency) {
      this.quote.currency = this.data.defaultCurrency.id;
    }
    this.showInput = false;
    this.quotation = this.formtools.createQuotationForm(this.quote);
    console.log(this.quotation);
    console.log(this.quote);
  }

  /**find the relevent rate region for collection from origin address to port, and port to delivery address
   * TODO do all of these in one call to google to prevent timeout issues
   */
  populatePortSites(os: SiteRegion, po: PreferredPorts, isorigin: boolean, ca: CompanyAssociation) {
    return new Promise((resolve) => {
      let flatregions = this.calc.flattenRegions(os.regions);//isorigin ? this.quoteInput.origin : this.quoteInput.destination
      let latlng;
      if (isorigin) {
        latlng = new google.maps.LatLng(po.origin.geoLat, po.origin.geoLong);
      }
      else {
        latlng = new google.maps.LatLng(po.destination.geoLat, po.destination.geoLong);
      }
      this.calc.findRateRegion(flatregions, isorigin ? this.quoteInput.origin : this.quoteInput.destination, latlng).then((message: StatusMessage) => {
        if (message.success) {
          //we now know the region where a rate applies

          os.rateRegion = message.message;
          os.rateRegion = this.calc.getRateParent(os);

          //kill the useless regions
          os.regions = [];
          this.getProviderMargins(os.rateRegion.companyid).then((margins: Margin[]) => {
            this.getAppropriateRate(os.rateRegion, ca).then((message: StatusMessage) => {
              if (message.success) {
                let todo = ca.Priorities.length;
                let done = 0;
                ca.Priorities.forEach(rp => {

                  if (rp.Rates && rp.Rates.length > 0) {

                    let priorityrates = rp.Rates.filter(rr => rr.regionid == os.rateRegion.id);
                    if (priorityrates) {
                      //calculate the rates for each quoteitem
                      let totalqp = new QuotationPrice();
                      this.quoteInput.quoteItems.forEach((qi: Size) => {

                        let qivolume = (qi.width / 1000 * qi.height / 1000 * qi.depth / 1000);

                        let rate: Rate;
                        if (priorityrates.length > 1) {
                          rate = this.calc.filterRateBracket(priorityrates, qi, this.data.ratebrackets);
                          if (!rate) rate = priorityrates[0];
                        }
                        else {
                          rate = priorityrates[0];
                        }
                        for (let q = 0; q < qi.quantity; q++) {
                          this.combineQuotationPrice(totalqp, this.calc.calculateRatePrice(rate, qi.weight, qivolume, margins, this.discounts, isorigin ? MarginAppliesTo.Collection_Rates : MarginAppliesTo.Delivery_Rates, this.data.defaultCurrency.id));
                        }

                      })


                      os.hasCollection = true;
                      let priority = new Priority();
                      priority.name = rp.name;
                      priority.id = rp.id;
                      priority.collection = totalqp;
                      os.priorities.push(priority);
                    }


                  }
                  done++;
                  if (done == todo) {
                    if (os.site) {
                      this.portSurcharges(po.port2port.transportmode, os.site.id, os, isorigin).then((message: StatusMessage) => {
                        if (message.success) {
                          resolve(true);
                        }
                        else {
                          this.tools.gracefulError(message.message);
                        }
                      })
                    }
                    else resolve(true);
                  }
                })
              }
              else {
                this.tools.gracefulError(message.message);
                resolve(false);
              }
            })
          })



        }
        else {
          //handle port surcharges if available
          if (os.site) {
            this.portSurcharges(po.port2port.transportmode, os.site.id, os, isorigin).then((message: StatusMessage) => {
              if (message.success) {
                resolve(true);
              }
              else {
                this.tools.gracefulError(message.message);
              }
            })
          }
          else resolve(true);
        }
      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      })
    })

  }
  /**
   * calculate the port specific surcharges, held on SiteRegion.surcharges
   * set on the Providers site import and export charges tab
   * @param mode 
   * @param siteid 
   * @param os 
   */
  portSurcharges(mode: TransportModes, siteid: number, os: SiteRegion, out: boolean) {
    return new Promise((resolve) => {
      let sat: SurchargeAppliesTo;
      switch (mode) {
        case TransportModes.Air:
          sat = out ? SurchargeAppliesTo.AirportOut : SurchargeAppliesTo.AirportIn;
          break;
        case TransportModes.Shipping:
          sat = out ? SurchargeAppliesTo.PortOut : SurchargeAppliesTo.PortIn;
          break;
        default:
          sat = out ? SurchargeAppliesTo.PortOut : SurchargeAppliesTo.PortIn;
      }


      this.getProviderMargins(os.site.companyid).then((margins: Margin[]) => {
        this.data.listSurchargesPort(siteid, sat).subscribe((message: StatusMessage) => {
          if (message.success) {
            let surcharges: Surcharge[] = message.message;
            if (surcharges.length > 0) os.hasSurcharges = true;
            if (!os.surcharges) os.surcharges = [];
            surcharges.forEach(surch => {
              let qp = new QuotationPrice();
              qp.description = surch.SurchargeType.description;
              qp.costprice = surch.surchargeFixed;
              os.surcharges.push(this.calc.calculateSurchargePrice(surch, margins, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id, this.quoteInput));
            })
            resolve({ success: true, message: true });

          }
          else {
            this.tools.gracefulError(message.message);
            resolve({ success: false, message: message });
          }
          resolve(message);
        }, err => { resolve({ success: false, message: err }) })
      })

    })

  }
  /**
   * attach the rates and surcharges for the specified service to the preferred port
   * @param serviceid
   * returns a promise of status message
   */
  serviceCharges(preferredport: PreferredPorts, servicedate: Date) {
    return new Promise((resolve) => {
      //service charges
      this.getProviderMargins(preferredport.service.providerid).then((margins: Margin[]) => {
        this.data.listServiceRates(preferredport.service.id, servicedate, this.customerid).subscribe((message: StatusMessage) => {
          if (message.success) {
            let rates: Rate[] = message.message.rates;
            let rate: Rate;
            if (rates && rates.length > 0) {
              preferredport.servicerates = [];

              let totalqp = new QuotationPrice();
              this.quoteInput.quoteItems.forEach(qi => {
                if (rates.length > 1) {
                  rate = rates.filter(r => r.ratebrackettype == qi.ratebracket)[0];
                }
                else rate = rates[0];
                let volume = (qi.depth / 1000 * qi.width / 1000 * qi.height / 1000) * qi.quantity;
                if (rate) this.combineQuotationPrice(totalqp, this.calc.calculateRatePrice(rate, qi.weight * qi.quantity, volume, margins, this.discounts, MarginAppliesTo.Service_Charges, this.data.defaultCurrency.id))

              })

              preferredport.servicerates.push(totalqp);
              let surcharges: Surcharge[] = message.message.surcharges;
              preferredport.servicesurcharges = [];
              surcharges.forEach(surch => {
                let sqp = this.calc.calculateSurchargePrice(surch, margins, this.discounts, MarginAppliesTo.Surcharges, this.data.defaultCurrency.id, this.quoteInput);
                if (sqp.discountedprice > 0) {
                  preferredport.servicesurcharges.push(sqp);
                }

              })
              resolve({ success: true })
            }
            else resolve({ success: false, message: 'No available rates' });

          }
          else resolve({ success: false, message: 'failed to retrieve service rates' });
        }, err => resolve({ success: false, message: err }));
      })

    })
  }

  /**
   * Retrive service end points, identify the OD port pairs and vessel if given (set to default if not).
   * Retrieve or calculate the searoute port to port distance and durations.
   * List regions covering the respective ports from a collection and delivery perspective.
   * Return Promise of type Quotation (A PreferedPorts[]).
   */
  calculateInternational() {
    return new Promise((resolve) => {
      this.quote = new Quotation();
      this.data.listServicesEndPoints(this.quoteInput.origin.countryid, this.quoteInput.destination.countryid, this.coid).subscribe((message: StatusMessage) => {
        if (message.success) {
          let cas: CompanyAssociation[] = message.message;
          //console.log(cas);
          this.getInitialMargins(cas).then(() => {
            this.getPriorities(cas).then(() => {
              cas.forEach(ca => {
                if (ca.Services) {
                }
              })

              this.eachEndpointAssociation(cas, 0).then((message: StatusMessage) => {
                resolve(message);
              });
            })

          })

        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => this.tools.gracefulError(err))
    })

  }
  /**
   * identify the port origin destination pairs (there may be more than one port at each end)
   * geocode the port if there is no current gps
   * @param cas 
   * @param caindex 
   */
  eachEndpointAssociation(cas: CompanyAssociation[], caindex: number) {
    return new Promise(resolve => {
      if (caindex < cas.length) {
        let ca = cas[caindex];
        let groupedPorts = this.identifyPortODPairs(ca);

        this.eachPort(groupedPorts, cas, 0).then(() => {
          caindex++;
          this.events.updateSpinner();
          this.eachEndpointAssociation(cas, caindex).then(() => {
            resolve(true);
          })
        })
      }
      else resolve(true);

    })

  }

  /**
   * assign surcharges
   * geocode locode if no gps
   * @param groupedPorts 
   * @param cas 
   * @param portindex 
   */
  eachPort(groupedPorts: PreferredPorts[], cas: CompanyAssociation[], portindex: number) {
    return new Promise(resolve => {
      if (portindex < groupedPorts.length) {
        let preferredPorts = groupedPorts[portindex];
        preferredPorts.quotesurcharges = [];
        preferredPorts.total = new QuotationPrice();
        this.surcharges.forEach((surch) => {
          preferredPorts.quotesurcharges.push(this.calc.calculateSurchargePrice(surch, [], [], MarginAppliesTo.Global, this.data.defaultCurrency, this.quoteInput))
        });
        this.data.geocodeLocodes(preferredPorts).then((poppedPorts: PreferredPorts) => {
          preferredPorts = poppedPorts;
          this.seaRoutes(preferredPorts).then((ports: PortToPort) => {
            preferredPorts.port2port = ports;

            this.data.listRegionsWithPortCoverage(ports.originlocodeid, ports.destinationlocodeid, this.quoteInput.origin.countryid, this.quoteInput.destination.countryid).subscribe((message: StatusMessage) => {
              if (message.success) {
                let regions: Region[] = message.message;
                regions.forEach(region => {
                  if (region.BaseSite.portid == ports.originlocodeid) {
                    //origin sites
                    if (!preferredPorts.originSites) preferredPorts.originSites = [];
                    let siteregion: SiteRegion;
                    let exists = preferredPorts.originSites.filter(pp => pp.site.id == region.basesiteid);
                    if (exists.length > 0) {
                      siteregion = exists[0];
                    }
                    else {
                      siteregion = new SiteRegion();
                      siteregion.site = region.BaseSite;
                      preferredPorts.originSites.push(siteregion);
                    }

                    siteregion.regions.push(region);
                  }
                  else {
                    //destination sites
                    if (!preferredPorts.destinationSites) preferredPorts.destinationSites = [];
                    let siteregion: SiteRegion;
                    let exists = preferredPorts.destinationSites.filter(pp => pp.site.id == region.basesiteid);
                    if (exists.length > 0) {
                      siteregion = exists[0];
                    }
                    else {
                      siteregion = new SiteRegion();
                      siteregion.site = region.BaseSite;
                      preferredPorts.destinationSites.push(siteregion);
                    }
                    siteregion.regions.push(region);
                  }
                })
                this.quote.portoptions.push(preferredPorts);
                portindex++;
                this.eachPort(groupedPorts, cas, portindex).then(success => {
                  resolve(success);
                })
              }
              else {
                portindex++;
                this.eachPort(groupedPorts, cas, portindex).then(success => {
                  resolve(success);
                })
              }
            }, () => {
              portindex++;
              this.eachPort(groupedPorts, cas, portindex).then(success => {
                resolve(success);
              })
            })
          })
        })
      }
      else resolve(true);
    })

  }
  /**
   * given a region, use the region's provider to get a set of RatePriorities
   * then get all the rates associated and return a promise of type RatePriorities with the rates as a property
   * @param region 
   */
  getAppropriateRate(region: Region, ca: CompanyAssociation) {
    return new Promise((resolve) => {

      this.data.listRatesRegion(region.id, 0).subscribe((message: StatusMessage) => {
        if (message.success) {
          let rates: Rate[] = message.message;
          ca.Priorities.forEach((rp) => {
            rp.Rates = rates.filter(r => r.priority == rp.id);
          })
          resolve({ success: true, message: true })
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err)
      });
    })

  }

  /**
   * get a list of rate priorities 
   * @param region
   */
  setRatePriorities(region: Region) {
    return new Promise((resolve) => {
      let done = false;
      if (this.ratePriorities.length > 0) {
        let thisprovider = this.ratePriorities.filter(rp => rp.companyid == region.companyid);
        if (thisprovider.length > 0) {
          resolve({ success: true, message: thisprovider });
          done = true;
        }
      }
      if (!done) {
        this.data.listRatePriorities(region.companyid, region.regiontypeid, this.data.Company.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            let rps: RatePriority[] = message.message;
            //localise standard priorities
            rps.forEach(rp => {
              if (rp.companyid == 0) rp.companyid = region.companyid;
            })
            this.ratePriorities = this.ratePriorities.concat(message.message);
            resolve({ success: true, message: message.message })
          }
          else {
            resolve({ success: false, message: message.message })
          }
        }, err => resolve({ success: false, message: err }))
      }
    })
  }
  /**
   * given a list of a provider's services, group the ports/mode and vessel to reduce dbcalls
   * @param services 
   */
  identifyPortODPairs(ca: CompanyAssociation): PreferredPorts[] {
    let ports: PreferredPorts[] = [];
    ca.Services.forEach(s => {
      s.OriginServicePorts.forEach(osp => {
        s.DestinationServicePorts.forEach(dsp => {
          let preferred = this.tools.buildPreferredPort(osp, dsp, s);
          preferred.provider = ca;
          if (ports.length == 0) ports.push(preferred);
          else {
            let found = false;
            ports.some(pp => {
              if (pp.destination.id == preferred.destination.id && pp.origin.id == preferred.origin.id && pp.mode == preferred.mode && pp.vessel.id == preferred.vessel.id) {
                found = true;
                return true;
              }
            })
            if (!found) ports.push(preferred);
          }
        })
      })
    })
    return ports;
  }

  /**deprecated */
  /*
  calculate() {
    this.data.listServicesEndPoints(this.quoteInput.origin.countryid, this.quoteInput.destination.countryid, this.coid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let cas: CompanyAssociation[] = message.message;
        cas.forEach(ca => {
          ca.Services.forEach(s => {

            let preferredPorts = this.tools.getPortLocodesPreferred(s);
            this.data.geocodeLocodes(preferredPorts).then((poppedPorts: PreferredPorts) => {
              preferredPorts = poppedPorts;
              this.seaRoutes(preferredPorts).then((ports: PortToPort) => {
                preferredPorts.port2port = ports;
                if (!s.Destination) {
                  s.Destination = this.data.countries.filter(c => c.id == s.destinationid)[0];
                }
                this.data.getConsolidationCentres(this.coid, s.Destination).then((message: StatusMessage) => {
                  if (message.success) {
                    let sites: Site[] = message.message;
                    let destportgps = new google.maps.LatLng(preferredPorts.destination.geoLat, preferredPorts.destination.geoLong);
                    this.calc.getNearestSite(sites, destportgps).then((element: GoogleMatrixElement) => {
                      let site = sites[element.index];
                      this.calc.calculateSteps2(this.quoteInput.origin, this.quoteInput.destination, this.quoteInput.weight, preferredPorts, site).then((steps: TransportSteps) => {
                        //steps.service = s;
                        console.log(steps);
                        this.steps.push(steps);
                        if (!this.selectedService) this.selectedService = steps;
                      }, err => console.log(err));
                    })


                  }

                })

              })

            })


          })
        })

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })


  }*/
  /**
   * get or calculate and save the distance and duration of the sea route between origin port and destination port.
   * @param preferredPorts PreferredPorts
   * returns promise of type Port2Port
   */
  seaRoutes(preferredPorts: PreferredPorts) {
    return new Promise((resolve) => {
      this.data.getPortToPort(preferredPorts.origin.id, preferredPorts.destination.id, preferredPorts.mode).subscribe((message: StatusMessage) => {
        if (message.success) {
          let p2p: PortToPort = message.message;
          if (p2p) {
            p2p.duration = preferredPorts.service.transitTime * 24 * 60 * 60;
            resolve(p2p);
          }
          else {
            this.calc.calculateSeaRoute(preferredPorts).then((p2p: PortToPort) => {
              //async save
              this.data.createPortToPort(p2p).subscribe(() => { }, err => {
                console.log(err);
              });
              resolve(p2p);
            })
          }
        }
        else {
          resolve(false);
        }
      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      })

    })
  }
  /**
   * check (intitially destination) for absence of port options and add in a consolidation leg if none
   * @param preferredPorts 
   */
  consolidationCentres(preferredPorts: PreferredPorts) {
    return new Promise(resolve => {
      if (!preferredPorts.destinationSites || preferredPorts.destinationSites.length == 0) {
        let provider = this.data.providers.filter(p => p.Provider.id == preferredPorts.provider.id)[0].Provider;
        let site = provider.Sites.filter(s => s.portid == preferredPorts.destination.id)[0];
        this.data.listPortSiteConsolidation(site.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            let id = message.message[0].siteid;
            let consol = provider.Sites.filter(s => s.id == id)[0];
            let siteregion = new SiteRegion();
            this.data.listCountryVersionsWithRates(id).then((message: StatusMessage) => {
              if (message.success) {
                this.data.getRegionHierarchy(message.message[0].id).subscribe((message: StatusMessage) => {
                  siteregion.rateRegion = message.message;
                  siteregion.site = consol;
                  if (!preferredPorts.destinationSites) preferredPorts.destinationSites = [];
                  preferredPorts.destinationSites.push(siteregion);
                  resolve(true);
                })
              }
              else{
                resolve(true);
              } 
            })

          }
          else {
            resolve(false);
          }
        })
      }
      else resolve(true);
    })
  }

  changeOriginSite(e) {
    this.quoteInput.originSite = e.value;
    if (!this.quoteInput.originSite.Address.Country) {
      this.quoteInput.originSite.Address.Country = this.data.countries.filter(c => c.id == this.quoteInput.originSite.Address.countryid)[0];
    }
    this.quoteInput.origin = this.quoteInput.originSite.Address;
  }
  changeDestinationSite(e) {
    this.quoteInput.destinationSite = e.value;
    if (!this.quoteInput.destinationSite.Address.Country) {
      this.quoteInput.destinationSite.Address.Country = this.data.countries.filter(c => c.id == this.quoteInput.destinationSite.Address.countryid)[0];
    }
    this.quoteInput.destination = this.quoteInput.destinationSite.Address;
  }

  changeService(e) {
    this.selectedService = e.value;
  }

  save(description: string) {
    let steps: ModeMetric[][] = [];
    let metrics: ModeMetric[] = [];
    //hold the steps (not in the quote form)
    this.quote.portoptions.forEach(po => {
      steps.push(po.steps);
      //and the total metrics
      metrics.push(po.metrics)
    })


    this.quote = this.quotation.value;
    let index = 0;
    this.quote.portoptions.forEach(po => {
      po.steps = steps[index];
      po.metrics = metrics[index];
      this.calc.terms(po);
      index++;
    })

    this.quoteInput.customerid = this.customerid;
    this.quote.quoteInput = this.quoteInput;

    this.quote.selectedOption = this.tabs.first.selectedIndex;

    let saveableversion = this.calc.mapSavedQuote(this.quote, this.data.Company.id, description, this.data.auth.user.id);
    this.tools.toStore("quote", saveableversion);
    if (saveableversion.id) {
      this.data.updateSavedQuote(saveableversion).subscribe(() => {
        this.isInitial = false;
      }, err => {
        this.tools.gracefulError(err);
      })

    }
    else {
      this.data.createSavedQuote(saveableversion).subscribe((message: StatusMessage) => {
        this.isInitial = false;
        this.quote.id = message.message.id;
        this.quotation.get("id").setValue(this.quote.id);
      }, err => {
        this.tools.gracefulError(err);
      })

    }

  }
  actions() {
    const dialogRef = this.dialog.open(QuoteActionsComponent, {
      width: '30%',
      data: { quote: this.quote, userid: this.data.auth.user.id, coid: this.data.Company.id }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.option == 1) {

          this.save(result.value.description);
        }
        else if (result.option == 2) {
          this.tools.snackMessage("Sending to customer - not yet implemented for your company");
        }
      }
    })
  }

  quotePriceChange() {
    this.calcTotal();
  }

  calcTotal() {
    let tabindex = this.tabs.first.selectedIndex;
    this.calculateTotals(<FormGroup>this.portoptions.controls[tabindex]);
  }

  optionChange(e) {
    this.quote.selectedOption = e.index;
    this.quotation.get("selectedOption").setValue(e.index);
  }
  changeView(e) {
    if (e.index == 3) {
      this.tabs.first.selectedIndex = this.quote.selectedOption;
    }
  }

  changeSiteIndex(index: number, origin: boolean) {
    if (origin) {
      this.collectionOptionsIndex = index;
    }
    else this.deliveryOptionsIndex = index;
    this.calcTotal();
  }
  deliveryTabChange(e) {
    let portoption = <FormGroup>this.portoptions.controls[this.tabs.first.selectedIndex];
    let dsi = portoption.get("destinationSiteIndex");
    dsi.setValue(e.index);
    dsi.markAsDirty();
    this.calcTotal();
  }
  originSiteTabChange(e) {
    let portoption = <FormGroup>this.portoptions.controls[this.tabs.first.selectedIndex];
    let osi = portoption.get("originSiteIndex");
    osi.setValue(e.index);
    osi.markAsDirty();
    this.calcTotal();
  }
  deliveryPriorityChange(e) {
    let portoption = <FormGroup>this.portoptions.controls[this.tabs.first.selectedIndex];
    let dsi = portoption.get("destinationSiteIndex");
    let dsarray = <FormArray>portoption.get("destinationSites");
    let ds = dsarray.controls[dsi.value];
    let index = ds.get("selectedPriority");
    index.setValue(e.index);
    index.markAsDirty();
    this.calcTotal();
  }

  getExchangeRates() {
    return new Promise((resolve) => {
      if (this.calc.exchangerates.length == 0) {
        this.data.exchangeRates(this.data.defaultCurrency.currencyCode).subscribe((res: any) => {
          this.mapRates(res);
          resolve(true);
        }, err => {
          this.tools.gracefulError(err);
          resolve(false);
        }
        );
      }
      else resolve(true);

    })

  }
  mapRates(res: any) {

    Object.keys(res.rates).forEach((key) => {
      let country = this.data.countries.filter(co => co.currencyCode == key);
      if (country.length > 0) {
        let rate = new ExchangeRate();
        rate.country = country[0];
        rate.currency = key;
        rate.rate = res.rates[key];
        this.calc.exchangerates.push(rate);
      }
    })
  }
  changeItem(e) {
    if (e.index == this.quoteInput.quoteItems.length) {
      this.addItem();
      setTimeout(() => {
        this.itemstabs.first.selectedIndex = e.index;
        this.itemstabs.first.realignInkBar();
      })

    }
  }
  removeItem(index: number) {
    if (this.quoteInput.quoteItems.length > 1) {
      this.quoteInput.quoteItems.splice(index, 1);
      setTimeout(() => {
        if (index == 0) {
          this.itemstabs.first.selectedIndex = index;
        }
        else {
          this.itemstabs.first.selectedIndex = index - 1;
        }
        this.itemstabs.first.realignInkBar();
      })
    }
    else {
      this.tools.gracefulError("At least one item required");
    }

  }
  addItem() {
    this.quoteInput.quoteItems.push(new Size());
  }
}
