<div [formGroup]="serviceForm" class="mt">
    <p *ngIf="originServicePorts.controls.length==0 && destinationServicePorts.controls.length==0">
        <i>No Ports Selected</i>
    </p>
    <mat-tab-group formArrayName="OriginServicePorts" (selectedIndexChange)="changePort($event)">
        <mat-tab *ngFor="let oc of originServicePorts.controls;let i=index;" [formGroup]="oc" (click)="changePort(oc)">
            <ng-template mat-tab-label>
                <span title="{{oc.value.id}}">{{oc.value.Locode.nodiacritic}}</span>
            </ng-template>
            
            <div class="fl mt">
                <p *ngIf="!oc.controls.Providers || oc.controls.Providers.controls.length==0">
                    <i>no available providers, please add partners</i>
                </p>
                <mat-tab-group formArrayName="Providers" (selectedIndexChange)="changeSite($event)">
                    <mat-tab *ngFor="let prov of oc.controls.Providers.controls" [formGroup]="prov">
                        <ng-template mat-tab-label>
                            {{prov.value.name}}
                        </ng-template>
                        <div class="mt mb">
                            <div class="fl">
                                <cs-surcharge-view [surcharges]="surcharges"></cs-surcharge-view>
                                <div class="f1"></div>
                            </div>
                        </div>                       
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
        <mat-tab *ngFor="let dc of destinationServicePorts.controls;let i=index;" [formGroup]="dc">
            <ng-template mat-tab-label>
                {{dc.value.Locode.nodiacritic}}
            </ng-template>

            <div class="fl mt">
                <p *ngIf="!dc.controls.Providers || dc.controls.Providers.controls.length==0">
                    <i>no available providers, please add partners</i>
                </p>
                <mat-tab-group formArrayName="Providers" (selectedIndexChange)="changeSite($event)">
                    <mat-tab *ngFor="let prov of dc.controls.Providers.controls" [formGroup]="prov">
                        <ng-template mat-tab-label>
                            {{prov.value.name}}
                        </ng-template>
                        <div class="mt mb">
                            <div class="fl">
                                <cs-surcharge-view *ngIf="surcharges.length>0" [surcharges]="surcharges"></cs-surcharge-view>
                                <div class="f1"></div>
                            </div>
                        </div>                       
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>