import { Component, OnInit, Input } from '@angular/core';
import { UserRole, User, StatusMessage, UserSetting, Role } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ModuleUser } from 'src/app/models/models';

@Component({
  selector: 'cs-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.scss']
})
export class UserdetailComponent implements OnInit {
  @Input() user: User;
  constructor(public data: DataService, private tools: ToolsService) { }

  roleid: number;
  settingid: number;
  setting: UserSetting;
  moduleUsers: ModuleUser[] = [];

  ngOnInit(): void {
    this.setup();

  }
  setupModules(){
    if (!this.user.ModuleUsers) this.user.ModuleUsers = [];
    this.data.modules.forEach((mod) => {
      let um = this.user.ModuleUsers.filter(u => u.moduleid == mod.id);
      if (!um || um.length == 0) {
        let newmod = new ModuleUser();
        newmod.moduleid = mod.id;
        newmod.userid = this.user.id;
        newmod.recordStatus = 0;
        newmod.view = false;
        newmod.edit = false;
        newmod.Module = mod;
        this.moduleUsers.push(newmod);
      }
      else{
        um[0].Module = mod;
        this.moduleUsers.push(um[0]);
      } 
    })
  }
  setupRoles(){
    if (this.user.UserRoles && this.user.UserRoles.length > 0) {
      this.roleid = this.user.UserRoles[0].roleid;
    }
  }
  setupSettings(){
    if (this.user.UserSettings && this.user.UserSettings.length > 0) {
      this.setting = this.user.UserSettings[0];
      this.settingid = this.setting.usersettingtypeid;
    }
    else {
      if(!this.user.UserSettings) this.user.UserSettings = [];
      if (this.data.userSettingTypes.length > 0) {
        let userset = new UserSetting();
        userset.recordStatus = 0;
        userset.UserSettingType = this.data.userSettingTypes[0];
        this.settingid = userset.usersettingtypeid = userset.UserSettingType.id;
        userset.settingon = false;
        userset.setting = "";
        userset.userid = this.user.id;
        this.setting = userset;

      }
    }
  }
  setup(){
    this.setupRoles();
    this.setupModules();
    this.setupSettings();  
  }

  //currently each user only has one role, so we create a new one and replace the old on a change
  changeRole(e) {
    let urole = new UserRole();
    urole.roleid = e.value;
    urole.userid = this.user.id;
    this.data.updateUserRole(urole).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.user.UserRoles = [message.message];
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }
  changeSetting(setting: UserSetting) {
    this.settingid = setting.id;
    let us = this.user.UserSettings.filter(s => s.usersettingtypeid == this.settingid);
    if (!us || us.length == 0) {
      this.setting = new UserSetting();
      this.setting.userid = this.user.id;
      this.setting.usersettingtypeid = setting.id;
      this.setting.recordStatus = 0;
      this.setting.settingon = false;
      this.setting.setting = "";
      
    }
    else {
      this.setting = us[0];
    }


  }
  updateSetting() {
    this.data.updateUserSetting(this.setting).subscribe((message: StatusMessage) => {
      if (message.success) {
        if(!this.setting.id || this.setting.id==0){
          if(!this.user.UserSettings) this.user.UserSettings = [];
          this.user.UserSettings.push(message.message);
          if(this.user.id==this.data.auth.user.id){
            this.data.auth.checkTokenExpiry().then(()=>{
              this.tools.snackMessage("Your settings have been updated");
            })
          }
        }
      }
      else{
        this.tools.gracefulError(message.message);
      }
    })
  }
  updateModule(module) {
    this.data.updateModuleUser(module).subscribe((message: StatusMessage) => {
      if (!message.success) {
        this.tools.gracefulError(message.message);
      }
      else{
        if(this.user.id==this.data.auth.user.id){
          this.data.auth.checkTokenExpiry().then(()=>{
            this.tools.snackMessage("Your settings have been updated");
          })
        }
      }
    })
  }
}
