<div *ngIf="siteregionform">
    <table class="td1-250" [formGroup]="siteregionform">
        <tr>
            <th colspan="5" class="tal">
            </th>
            <th>Show Provider</th>
            <th>
                <mat-checkbox formControlName="include">
                </mat-checkbox>
            </th>
        </tr>
        <ng-container *ngIf="delivery">
            <tr>
                <th colspan="6" class="tal" *ngIf="siteregion.site">
                    {{mode==modes.Air?'Air':'Sea'}}port
                    Surcharges -
                    {{siteregion.site.name}}
                    {{siteregion.hasSurcharges?'':' (included)'}}
                </th>
            </tr>
        <ng-container formArrayName="surcharges" *ngFor="let surcharge of surcharges.controls" class="mt">
            <tr cs-quote-price [quoteprice]="surcharge" (onchange)="lineChange()">
            </tr>
        </ng-container>

        </ng-container>

        <tr *ngIf="!siteregion.hasCollection">
            <td colspan="6" class="tablenote">No {{delivery?'Delivery':'Collection'}} Option available, Customer to
                arrange
                {{delivery?'collection from':'delivery to'}} {{mode==modes.Air?'Air':'Sea'}}port.
            </td>
        </tr>
        <ng-container [formGroup]="siteregionform" *ngIf="siteregion.hasCollection">
            <tr>
                <th colspan="7" class="tal">
                    {{delivery?'Delivery':'Collection'}} Charges
                </th>
            </tr>
            <tr cs-quote-price [quoteprice]="collection" (onchange)="lineChange()">
            </tr>
        </ng-container>

        <ng-container *ngIf="!delivery">
            <tr>
                <th colspan="6" class="tal" *ngIf="siteregion.site">
                    {{mode==modes.Air?'Air':'Sea'}}port
                    Surcharges -
                    {{siteregion.site.name}}
                    {{siteregion.hasSurcharges?'':' (included)'}}
                </th>
            </tr>

            <ng-container formArrayName="surcharges" *ngFor="let surcharge of surcharges.controls" class="mt">
                <tr cs-quote-price [quoteprice]="surcharge" (onchange)="lineChange()">
                </tr>
            </ng-container>
        </ng-container>

    </table>
</div>