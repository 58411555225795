import { Component, OnInit, Inject } from '@angular/core';
import { RatePriority } from 'src/app/models/rates';
import { FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { StatusMessage } from 'src/app/models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cs-edit-priority',
  templateUrl: './edit-priority.component.html',
  styleUrls: ['./edit-priority.component.scss']
})
export class EditPriorityComponent implements OnInit {

  public priority:RatePriority;

  public priorityForm:FormGroup;

  constructor(public dialogRef: MatDialogRef<EditPriorityComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public formtools:FormtoolsService,public data:DataService,public tools:ToolsService) {
      this.priority = dialogdata.priority;
      if(this.priority.bytime){
        let dropsecs = this.priority.bytime.split(":");
        if(dropsecs.length>1){
          this.priority.bytime = dropsecs[0]+":"+dropsecs[1];
        }

      }
      this.priorityForm = this.formtools.createRatePriorityForm(this.priority);
    }

  ngOnInit(): void {
    
  }
  delete(){
    this.priorityForm.get("recordStatus").setValue(3);
    this.dialogRef.close(this.priorityForm.value);
  }
  cancel(){
    this.dialogRef.close();
  }
  submit(){
    this.dialogRef.close(this.priorityForm.value);
  }

}
