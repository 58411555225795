<form>
    <mat-form-field>
      <input matInput
             placeholder="Currency"
             aria-label="Currency"
             [matAutocomplete]="auto"
             [formControl]="currencyCtrl">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseCurrency($event)">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.name">
          <img aria-hidden [src]="'../../../assets/flags/'+country.flag+'.png'" height="25">
          <span class="mr">({{country.currencySymbol}})</span>
          <span>{{country.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
</form>
