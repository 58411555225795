import { Component, ViewChild, AfterViewInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Service, ServiceVia } from 'src/app/models/data';
import { TableGenerator, ColumnDescription, ColumnType } from 'src/app/models/models';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ToolsService } from 'src/app/services/tools.service';
import { TransportModes, MarginAppliesTo } from 'src/app/models/rates';
import { ObjectifierComponent } from 'src/app/importers/objectifier/objectifier.component';


@Component({
  selector: 'cs-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CSMatTableComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  @Input() objectSource: Service[] = [];
  @Input() tableid: string;
  @Input() showadd = true;
  @Input() tabgen: TableGenerator;
  @Input() appliesto: MarginAppliesTo;
  @Input() localclass = "";
  @Output() select = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();

  pageSizeOptions: number[] = [10, 20];



  constructor(private tools: ToolsService, public ref: ChangeDetectorRef) { }

  columnObjects: ColumnDescription[] = [];
  columnStrings: string[] = [];
  dataSource: MatTableDataSource<any>;

  @ViewChildren('objectifier') objectifier: QueryList<ObjectifierComponent>;

  ngAfterViewInit(): void {
    if (this.objectifier.first) {
      this.objectifier.first.add.subscribe((services: Service[]) => {
        this.reRenderAll(services);
      })
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objectSource']) {
      if (this.objectSource) {
        this.columnObjects = this.tabgen.columns;
        if (this.tabgen.pages) {
          this.pageSizeOptions = this.tabgen.pages;
        }
        this.dataSource = new MatTableDataSource(this.objectSource);
        this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        this.dataSource.filterPredicate = (data, filter: string) => this.tools.filterPredicate(data, filter);
        this.dataSource.sort = this.sort;
        if (this.tabgen && this.tabgen.columns) {
          this.columnStrings = this.tabgen.columns.map(col => col.path);
        }

        this.dataSource.paginator = this.paginator;
      }
    }
  }

  reRender(newservice?: Service) {
    if (newservice) {
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource(this.objectSource);
      }
      this.dataSource.data.unshift(newservice);
      this.table.renderRows();
      this.sort.sort({ id: 'id', start: 'desc', disableClear: false });
      this.tools.snackMessage("Service added, table sorted to show latest first");


    }
    else this.table.renderRows();
  }
  reRenderAll(services: Service[]) {

    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(this.objectSource);
    }
    if (services) {
      services.forEach(s => {
        this.dataSource.data.unshift(s);
      })
    }


    this.table.renderRows();
    this.sort.sort({ id: 'id', start: 'desc', disableClear: false });
    if (services && services.length > 0) {
      this.tools.snackMessage("Services added, table sorted to show latest first");
    }

    this.dataSource.paginator = this.paginator;

  }

  buildColumns() {
    this.tabgen.columns.forEach(col => {
      this.columnObjects.push(col);
    })
  }
  getProperty = (obj, prop) => {
    //give a co assoc obj, and property "Provider.name" for example
    let array = prop.split('.'); //[Customer,name];
    let depth = 0;
    let valobj;
    if (array[depth].indexOf('[') > 0) {
      let isarray = array[depth].split('[');
      let arrayindex = parseInt(isarray[1].replace(']', ''));
      valobj = obj[isarray[0]][arrayindex];
    }
    else valobj = obj[array[depth]];

    while (depth < array.length - 1) {
      depth++;
      if (!valobj) valobj = "";
      else valobj = valobj[array[depth]];
    }
    return valobj;
  }
  cellContent(element, definition: ColumnDescription) {
    let prop = this.getProperty(element, definition.path);
    let content: string = prop;
    switch (definition.type) {
      case ColumnType.BOOLEAN:
        content = "<span class='material-icons'>" + (prop ? 'check' : 'not_interested') + "</span>";
        break;
      case ColumnType.TRANSPORT_MODE:
        content = "<span class='tm tm" + prop + "'></span>";
        break;
      case ColumnType.SERVICE_VIAS:
        let servias: ServiceVia[] = prop;
        content = servias.map(sv => sv.Country.name).join(', ');
        content = content.substring(0, content.length);
        break;
      case ColumnType.RECORD_STATUS:
        content = "<span class='material-icons'>" + (prop == 0 ? 'check' : 'not_interested') + "</span>";
        break;
      case ColumnType.CURRENCY:
        content = "<span>" + this.tools.cleanRound(parseFloat(prop), 2) + "</span>";
        break;
      case ColumnType.DURATION:
        content = "<span>" + this.tools.duration(parseFloat(prop)) + "</span>";
        break;
      case ColumnType.ROUTE:
        if (prop) {
          content = prop;
        }
        else content = "direct";
        break;
      default:
        content = prop;
    }
    return content;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectRow(rowElement: any) {
    this.select.emit(rowElement);
  }

  addNew() {
    this.add.emit();
  }

}
