import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage, Address, Site, CompanyAssociation, Company, CompanyTypeAppliesTo } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { EventsService } from 'src/app/services/events.service';
import { PerformanceMetric } from 'src/app/models/data';
import { CompanyComponent } from 'src/app/dialogs/company/company.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cs-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ProvidersComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  displayedColumns: string[] = ['Provider.id', 'Provider.name', 'Provider.companytypeid', 'Provider.companystatusid', 'Settings'];
  
  @Input() iscustomers = false;
  customers;
  isLoading = false;

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  
  constructor(public data: DataService, public dialog: MatDialog,private tools: ToolsService, public events: EventsService) { }

  ngAfterViewInit(): void {
    if(this.data.dataIsReady){
      this.loadCustomers();
    }
    else{
      this.data.dataReady.subscribe(() => {
        this.loadCustomers();
    })
    }
    
  }

  getProperty = (obj, prop) => {
    //give a co assoc obj, and property "Provider.name"
    let array = prop.split('.'); //[Customer,name];
    let depth = 0;
    let valobj = obj[array[depth]];
    while (depth < array.length - 1) {
      depth++;
      valobj = valobj[array[depth]];
    }
    return valobj;
  }

  loadCustomers() {
      setTimeout(()=>{
        this.customers = new MatTableDataSource(this.iscustomers?this.data.customers:this.data.providers);
      this.customers.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.customers.filterPredicate = (data, filter: string) => this.tools.filterPredicate(data, filter);
      this.customers.sort = this.sort;
      this.customers.paginator = this.paginator;
      });
      
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customers.filter = filterValue.trim().toLowerCase();
  }
  editCompany(element: CompanyAssociation) {

    const dialogRef = this.dialog.open(CompanyComponent, {
      width: 'auto',
      data: { Provider: this.iscustomers?element.Customer:element.Provider,iscustomer:this.iscustomers }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if(this.iscustomers){
          if (result.Customer) {
            element = result as CompanyAssociation;
            let data = this.customers.data;
            //data.unshift(element);
            this.table.dataSource = data;
            this.data.customers.unshift(element);
          }
          else{
            element.Customer = result;
            this.data.updateCustomerAssociation(result);
          } 
        }
        else{
          if (result.Provider) {
            element = result as CompanyAssociation;
            let data = this.customers.data;
            //data.unshift(element);
            this.table.dataSource = data;
            this.data.providers.unshift(element);
          }
          else{
            element.Provider = result;
            this.data.updateProviderAssociation(result);
          } 
        }
        
        this.table.renderRows();
      }
    });

  }
  addCompany() {
    let coAssoc = new CompanyAssociation();
    if(this.iscustomers){
      coAssoc.customerid = this.data.Company.id;
    }
    else{
      coAssoc.companyid = this.data.Company.id;
    }
    
    let co = new Company();
    co.name = "New Company";
    co.recordStatus = 0;
    co.CompanyStatus = this.data.companyStatuses[0];
    co.companystatusid = co.CompanyStatus.id;
    co.CompanyType = this.data.companyTypes.filter(ct=>ct.appliesto==(this.iscustomers?CompanyTypeAppliesTo.Customer:CompanyTypeAppliesTo.Provider))[0];
    co.companytypeid = co.CompanyType.id;
    co._isDirty = true;
    let site = new Site();
    site.recordStatus = 0;
    site.SiteType = this.data.siteTypes[0];
    site.sitetypeid = site.SiteType.id;
    site.name = "Head Office";
    site._isDirty = true;
    site.Users = [];
    site.Invitations = [];
    let address = new Address();
    address.recordStatus = 0;
    address._isDirty = true;
    site.Address = address;
    co.Sites = [site];
    if(this.iscustomers){
      coAssoc.Customer = co;
    }
    else{
      coAssoc.Provider = co;
    }
    
    //this.customers.data.unshift(coAssoc);
    //this.table.renderRows();
    this.editCompany(coAssoc);

  }

}
