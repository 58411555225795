<h3>{{title}}</h3>
<mat-tab-group>
    <mat-tab *ngFor="let port of ports">
        <ng-template mat-tab-label>
            {{port.Locode.nodiacritic}}
        </ng-template>
        <div class="pad">
            <mat-tab-group>
                <mat-tab *ngFor="let prov of port.Providers">
                    <ng-template mat-tab-label>
                        <span title="{{prov.siteid}}">{{prov.name}}</span>
                    </ng-template>
                    <div>
                        <cs-rates-view [siteid]="prov.siteid" [providerid]="prov.providerid" [justrates]="true"></cs-rates-view>
                    </div>
                    <div>
                        <cs-consolidation [siteid]="prov.siteid" [justrates]="true"></cs-consolidation>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-tab>
</mat-tab-group>

