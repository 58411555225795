import { Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Company, Site, User, Address, Invitation, AddressComponent } from '../models/user';
import { Milestone, Issue, PortToPort } from '../models/models';
import { Locode, Service, ServicePort, ServiceUser, ServiceVia, Vessel } from '../models/data';
import { Rate, RateBreak, BandPremium, Surcharge, Region, RatePriority, Margin, Discount, MinimumMargin, QuotationPrice } from '../models/rates';
import moment from 'moment';
import { PortSite, Quotation, PreferredPorts, SiteRegion, Priority } from '../models/ui';



@Injectable({
  providedIn: 'root'
})
export class FormtoolsService {

  constructor(private formBuilder: FormBuilder) { }

  companySetDirty(company: Company, changed: Company): boolean {
    return this.companyIsDirty(company, changed);
  }
  //we do not remove Companies, Sites, Addresses or Users, only mark them for
  //deletion, so form will always have >= properties to model 
  companyIsDirty(model: Company, form: Company): boolean {
    let foundDirty = false;
    foundDirty = this.objectIsDirty(model, form);


    form.Sites.forEach((formsite: Site) => {
      if (!formsite.id || formsite.id == 0) {
        formsite.id = 0;
        formsite.companyid = model.id;
        formsite._isDirty = true;
        //model.Sites.push(formsite);
        foundDirty = true;
      }
      else {
        let modelsite = model.Sites.filter(s => s.id == formsite.id)[0];
        if (this.objectIsDirty(modelsite, formsite)) {
          foundDirty = true;
        };
        if (!formsite.addressid || formsite.addressid == 0) {
          formsite.id = 0;
          formsite.Address._isDirty = true;
          //modelsite.Address = formsite.Address;
          foundDirty = true;
        }
        else {
          if (this.objectIsDirty(modelsite.Address, formsite.Address)) {
            foundDirty = true;
          };
        }
        if (!modelsite.Users || modelsite.Users.length == 0) {
          //modelsite.Users = formsite.Users;
          //modelsite.Users.forEach(u=>{
          //u.id=0;
          //u._isDirty = true;
          //})
          formsite.Users.forEach(u => {
            u._isDirty = true;
          });
          foundDirty = true;
        }
        else {
          formsite.Users.forEach((formuser: User) => {
            let modeluser = modelsite.Users.filter(u => u.id == formuser.id)[0];
            if (this.objectIsDirty(modeluser, formuser)) {
              foundDirty = true;
              modeluser._isDirty = true;
            }
          })
        }

      }
    })
    return foundDirty;
  }
  //check two db object arrays to see if there are any new ones created on the form
  objectsAreNew(a: any[], b: any[]): any[] {
    let newObjects: any[] = [];
    if (a.length < b.length) {
      b.forEach(ob => {
        let possiblea = a.filter(each => each.id == ob.id);
        if (possiblea.length == 0) {
          ob._isDirty = true;
          newObjects.push(ob);
        }
      })
    }
    return newObjects;
  }

  //check two db object to see if they are actually dirty
  objectIsDirty(a: any, b: any): boolean {
    let foundDirty = false;
    Object.keys(a).some((key) => {

      if ((a[key] || a[key] == 0 || a[key] == null) && (b[key] || b[key] == 0 || b[key] == null) && typeof a[key] != "object") {
        if (a[key] != b[key]) {
          b._isDirty = true;
          foundDirty = true;
          return true;
        }
      }
    })
    return foundDirty;
  }
  controlsAreDirty(group: FormGroup) {
    let dirty = false;
    Object.keys(group.controls).some(key => {
      if (group.get(key).dirty) {
        dirty = true;
        return true;
      }
    })
    return dirty;
  }

  createFormArray(): FormArray {
    return this.formBuilder.array([]);
  }

  //generate a form for editing company, site, address, users and invites. If not empty, function will patch the existing values
  //set empty to true when adding a new company to avoid dirtying the company model prior to an update
  createCompanyForm(company: Company, empty?: boolean): FormGroup {
    let sitesFormArray: FormArray = this.createFormArray();
    let usersFormArray: FormArray = this.createFormArray();
    let invitationsFormArray: FormArray = this.createFormArray();
    if (!empty) {
      //console.log("Company is: ", company);
      company.Sites.forEach((site) => {
        let addressFormGroup = this.createAddressFormGroup(site.Address);
        if (site.Users) {
          site.Users.forEach(user => {
            usersFormArray.push(this.createUserFormGroup(user));
          });
        }
        if (site.Invitations) {
          site.Invitations.forEach(invite => {
            invitationsFormArray.push(this.createInvitationGroup(invite));
          })
        }

        let siteFormGroup = this.createSiteFormGroup(addressFormGroup, usersFormArray, invitationsFormArray, site);
        sitesFormArray.push(siteFormGroup);
      })
    }
    let companyForm = this.formBuilder.group({
      id: [0],
      name: [""],
      companystatusid: [1],
      companytypeid: [1],
      Sites: sitesFormArray
    })
    if (!empty) companyForm.patchValue(company);
    return companyForm;
  }

  //create the site part of the company editing form, pass in site to patchValues;
  createSiteFormGroup(addressFormGroup: FormGroup, usersFormArray: FormArray, invitesFormArray: FormArray, site?: Site): FormGroup {
    let siteFormGroup = this.formBuilder.group({
      id: [0],
      companyid: [""],
      name: ["New Site"],
      sitetypeid: [1],
      addressid: [1],
      Address: addressFormGroup,
      Users: usersFormArray,
      Invitations: invitesFormArray,
      surchargeImportSiteFormArray: this.formBuilder.array([]),
      surchargeExportSiteFormArray: this.formBuilder.array([])
    });
    if (site) {
      if (!site.Address) delete site.Address;
      siteFormGroup.patchValue(site);
      /*if(site.Users)
      {
        let userarray:FormArray = <FormArray>siteFormGroup.get("Users");
        site.Users.forEach((user:User)=>{
          userarray.push(this.createUserFormGroup(user));
        })
      }*/
    }
    return siteFormGroup;
  }

  //create the address part of the site editing form, pass in address to patchValues;
  createAddressFormGroup(address?: Address): FormGroup {
    //let addressComponentsFormArray: FormArray = this.createFormArray();
    let addressFormGroup = this.formBuilder.group({
      id: [0],
      flatOrSuiteNumber: [""],
      floorNumber: [0],
      houseNameOrNumber: ["", Validators.required],
      street: [""],
      town: [""],
      city: ["", Validators.required],
      county: [""],
      state: [""],
      countryid: ["", Validators.required],
      postcode: [""],
      what3words: [""],
      gpslat: [0],
      gpslong: [0],
      mapzoom: [0],
      addressstring:[]//,
      //AddressComponents:addressComponentsFormArray
    });

    if (address){
      /*if(address.AddressComponents){
        address.AddressComponents.forEach(addcomp=>{
          addressComponentsFormArray.push(this.createAddressComponentsGroup(addcomp));
        })
      }*/
      addressFormGroup.patchValue(address);
    } 
    return addressFormGroup;
  }

  //
  

  /**
   * this is a WIP, considering using an address component structure in the 
   * style of a google place to assist with complex region structures. Not yet necessary
   * but building blocks for the future.
   */
  createAddressComponentsGroup(address_component?:AddressComponent){
    let addressComponentsGroup = this.formBuilder.group({
      addressid:[],
      long_name:[""],
      short_name:[""],
      types:[[]],
      place_id:[""]
    })
    if(address_component){
      addressComponentsGroup.patchValue(address_component);
    }
    return addressComponentsGroup;
  }

  //create the user part of the site editing form, pass in user to patchValues;
  createUserFormGroup(user?: User): FormGroup {
    let userFormGroup = this.formBuilder.group({
      id: [0],
      firstname: ["", Validators.required],
      surname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      others: [""]
    })
    if (user) userFormGroup.patchValue(user);
    return userFormGroup;
  }

  //create an invitation form, always pass in invitation to patchValues;
  createInvitationGroup(invitation: Invitation): FormGroup {
    let invitationFormGroup = this.formBuilder.group({
      id: [0],
      salutation: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      siteid: [0, [Validators.required]],
      inviterid: [0, [Validators.required]],
      status: [0, [Validators.required]],
      roleid: [3, [Validators.required]],
      recordStatus: [0],
    })
    invitationFormGroup.patchValue(invitation);
    return invitationFormGroup;
  }

  createSimpleEmail():FormGroup{
    let emailform = this.formBuilder.group({
      email:["",[Validators.required,Validators.email]],
      salutation:["",[Validators.required]]
    })
    return emailform;
  }
  createSimpleSave():FormGroup{
    let saveform = this.formBuilder.group({
      description:["",[Validators.required]]
    })
    return saveform;
  }


  //create a form for editing milestones
  createMilestoneFormGroup(milestone?: Milestone): FormGroup {


    let milestoneFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      ownerid: ['', Validators.required],
      milestoneType: ['', Validators.required],
      summary: ['', Validators.required],
      description: ['', Validators.required],
      startDate: ['', Validators.required],
      dueDate: [''],
      status: ['', Validators.required],
      priority: ['', Validators.required],
      parentId: [''],
      hierarchyId: [''],
      previousParent: [''],
      recordStatus: [''],
    })
    if (milestone) milestoneFormGroup.patchValue(milestone);
    return milestoneFormGroup;
  }
  //create a form for editing milestones with subgroups
  xcreateMilestoneFormGroups(milestone?: Milestone): FormGroup {
    let privateGroup = this.formBuilder.group({
      id: ['', Validators.required],
      ownerid: ['', Validators.required],
      parentId: [''],
      hierarchyId: [''],
      previousParent: [''],
      recordStatus: ['']
    })
    let debouncedGroup = this.formBuilder.group({
      summary: ['', Validators.required],
      description: ['', Validators.required]
    })
    let instantGroup = this.formBuilder.group({
      milestoneType: ['', Validators.required],
      startDate: ['', Validators.required],
      dueDate: [''],
      status: ['', Validators.required],
      priority: ['', Validators.required],
    })

    let milestoneFormGroup = this.formBuilder.group({
      privateGroup, debouncedGroup, instantGroup
    })
    if (milestone) {
      privateGroup.patchValue(milestone);
      debouncedGroup.patchValue(milestone);
      instantGroup.patchValue(milestone);
    }
    //milestoneFormGroup.patchValue(milestone);
    return milestoneFormGroup;
  }
  //create a form for editing issues
  createIssueFormGroup(issue?: Issue): FormGroup {
    let issueFormGroup = this.formBuilder.group({

    })
    return issueFormGroup;
  }

  //create a form for adding and editing Services

  createServiceFormGroup(service?: Service): FormGroup {
    let form = this.formBuilder.group({
      id: [],
      providerid: [, Validators.required],
      description: ['', Validators.required],
      displayname: ['', Validators.required],
      originid: [, Validators.required],
      destinationid: [, Validators.required],
      transitTime: [, Validators.required],
      currencyid: [225, Validators.required],
      quoteValidity: [30, Validators.required],
      transportMode: [1, Validators.required],
      vesselid: [0],
      transportproviderid: [0],
      frequency: [[]],
      recordStatus: [0, Validators.required],
      isPublic: [true, Validators.required],
      includeC2P: [true, Validators.required],
      includeP2D: [true, Validators.required],
      includeP2P: [true, Validators.required],
      //autocomplete controls
      Provider: this.formBuilder.group({
        name: ['', Validators.required],
        id: [, Validators.required]
      }),
      Origin: this.formBuilder.group({
        name: ['', Validators.required],
        id: [, Validators.required],
        iso_alpha_2: []
      }),
      Destination: this.formBuilder.group({
        name: ['', Validators.required],
        id: [, Validators.required],
        iso_alpha_2: []
      }),
      Via: this.formBuilder.group({
        name: [''],
        id: [],
        iso_alpha_2: []
      }),
      Currency: this.formBuilder.group({
        name: ['', Validators.required],
        id: [, Validators.required],
        currencyCode:[]
      }),
      ServiceVias: this.formBuilder.array([]),
      OriginServicePorts: this.formBuilder.array([]),
      DestinationServicePorts: this.formBuilder.array([]),
      ServiceUsers: this.formBuilder.array([]),
      serviceViasLocodeControl: [],
      originLocodeControl: [],
      destinationLocodeControl: [],
      serviceUserControl: [],
      surchargeFormArray: this.formBuilder.array([]),
      surchargePortFormArray: this.formBuilder.array([]),
      rateFormArray: this.formBuilder.array([])
    });
    if (service) {
      form.patchValue(service);

      if (service.OriginServicePorts) {
        let ospGroup: FormArray = <FormArray>form.get("OriginServicePorts");
        service.OriginServicePorts.forEach(op => {
          let fg = this.createServicePortFormGroup(true, op);
          ospGroup.push(fg);

        })
      }
      if (service.DestinationServicePorts) {
        let ospGroup: FormArray = <FormArray>form.get("DestinationServicePorts");
        service.DestinationServicePorts.forEach(op => {
          let fg = this.createServicePortFormGroup(false, op);
          ospGroup.push(fg);

        })
      }
      if (service.ServiceUsers) {
        let suGroup: FormArray = <FormArray>form.get("ServiceUsers");
        service.ServiceUsers.forEach(op => {
          let fg = this.createServiceUserFormGroup(op);
          suGroup.push(fg);

        })
      }
      if (service.ServiceVias) {
        let svGroup: FormArray = <FormArray>form.get("ServiceVias");
        service.ServiceVias.forEach(sv => {
          let svg = this.createServiceViaFormGroup(sv);
          svGroup.push(svg);
        })
      }
      

    }
    return form;
  }
  createServicePortFormGroup(origin, servicePort?: ServicePort) {
    let portgroup = this.formBuilder.group({
      id: [],
      serviceid: [],
      locodeid: [, Validators.required],
      recordStatus: [0, Validators.required],
      origin: [origin, Validators.required],
      partnersiteid: [],
      Locode: this.formBuilder.group({
        id: [, Validators.required],
        name: [, Validators.required],
        nodiacritic: [],
        locode1: [],
        locode2: [],
        location: []
      }),
      Providers: this.formBuilder.array([])
    })
    if (servicePort) portgroup.patchValue(servicePort);
    return portgroup;
  }
  createProviderForm(providers: FormArray, portsites: PortSite[]) {

    portsites.forEach(ps => {
      let psform = this.formBuilder.group({
        siteid: [],
        partnersiteid: [],
        name: [""],
        locodeid: [],
        providerid: [],
        Surcharges: this.formBuilder.array([])
      })
      psform.patchValue(ps);
      providers.push(psform);
    })
  }
  createServiceUserFormGroup(serviceUser?: ServiceUser) {
    let usergroup = this.formBuilder.group({
      id: [],
      serviceid: [, Validators.required],
      userCompanyid: [, Validators.required],
      recordStatus: [0, Validators.required],
      UserCompany: this.formBuilder.group({
        id: [, Validators.required],
        name: [, Validators.required]
      })
    })
    if (serviceUser) usergroup.patchValue(serviceUser);
    return usergroup;
  }
  createServiceViaFormGroup(serviceVia?: ServiceVia) {
    let svgroup = this.formBuilder.group({
      id: [],
      serviceid: [, Validators.required],
      countryid: [, Validators.required],
      locodeid: [],
      recordStatus: [0, Validators.required],
      Country: this.formBuilder.group({
        id: [, Validators.required],
        name: [, Validators.required]
      }),
      Locode: this.formBuilder.group({
        id: [, Validators.required],
        name: [, Validators.required],
        nodiacritic: []
      })
    })
    if (serviceVia) {
      svgroup.patchValue(serviceVia);
    }
    return svgroup;
  }
  createSurchargesArray(surcharges: Surcharge[],empty?:boolean): FormArray {
    let surchargeArray = this.formBuilder.array([]);
    surcharges.forEach(s => {
      surchargeArray.push(this.createSurchargeForm(empty?null:s))
    })
    return surchargeArray;
  }
  createSurchargeForm(surcharge?: Surcharge): FormGroup {
    let surchargeForm = this.formBuilder.group({
      id: [],
      providerid: [],
      surchargetypeid: [1, Validators.required],
      surchargeMin: [0],
      surchargeFixed: [0],
      surchargePercent: [0],
      serviceid: [],
      siteid: [],
      perunit: [1],
      unit: [{ value: 0, disabled: true }],
      currencyid: [],
      expires: [],
      starts:[new Date()],
      recordStatus: [0],
      optional: [false],
      ratebracket:[0]
    })
    if (surcharge) {
      surchargeForm.patchValue(surcharge);
      if (surcharge.perunit > 1) {
        surchargeForm.get("unit").enable();
      }
    }
    return surchargeForm;
  }

  serviceSetDirty(model: Service, form: Service): boolean {
    let foundDirty = false;
    foundDirty = this.objectIsDirty(model, form);
    if (form.OriginServicePorts) {
      form.OriginServicePorts.forEach((formport: ServicePort) => {
        if (!formport.id || formport.id == 0) {
          formport.id = 0;
          formport._isDirty = true;
          foundDirty = true;
        }
        else {
          let modelport = model.OriginServicePorts.filter(sp => sp.id == formport.id)[0];
          if (this.objectIsDirty(modelport, formport)) {
            foundDirty = true;
          }
        }
      });
    }
    if (form.DestinationServicePorts) {
      form.DestinationServicePorts.forEach((formport: ServicePort) => {
        if (!formport.id || formport.id == 0) {
          formport.id = 0;
          formport._isDirty = true;
          foundDirty = true;
        }
        else {
          let modelport = model.DestinationServicePorts.filter(sp => sp.id == formport.id)[0];
          if (this.objectIsDirty(modelport, formport)) {
            foundDirty = true;
          }
        }
      });
    }
    if (form.ServiceUsers) {
      form.ServiceUsers.forEach((formuser: ServiceUser) => {
        if (!formuser.id || formuser.id == 0) {
          formuser.id = 0;
          formuser._isDirty = true;
          foundDirty = true;
        }
        else {
          let modeluser = model.ServiceUsers.filter(su => su.id == formuser.id)[0];
          if (this.objectIsDirty(modeluser, formuser)) {
            foundDirty = true;
          }
        }
      });
    }
    if (form.ServiceVias) {
      form.ServiceVias.forEach((formvia: ServiceVia) => {
        if (!formvia.id || formvia.id == 0) {
          formvia.id = 0;
          formvia._isDirty = true;
          foundDirty = true;
        }
        else {
          let modelvia = model.ServiceVias.filter(su => su.id == formvia.id)[0];
          if (this.objectIsDirty(modelvia, formvia)) {
            foundDirty = true;
          }
        }
      });
    }


    return foundDirty;
  }
  /**
     * create a form for with rate breaks
     * @param rate optional
     */
  createRateFormGroup(rate?: Rate): FormGroup {
    let rateBreaksFormArray: FormArray = this.createFormArray();
    if (rate && rate.RateBreaks) {
      rate.RateBreaks.forEach(rb => {
        rateBreaksFormArray.push(this.createRateBreakGroup(rb));
      })
    }
    let customerRatesFormArray: FormArray = this.createFormArray();

    let rateFormGroup = this.formBuilder.group({
      id: [],
      companyid: [],
      regionid: [],
      baseprice: [0, Validators.required],
      baseIncludesWeight: [0],
      baseIncludesVolume: [0],
      priority: [0],
      name: [],
      description: [],
      transportmode: [1],
      currency: [],
      breakbarrierincludes: [true],
      volumeequivalent: [1],
      priceperbreak: [false],
      parentregionid: [],
      envelopeid: [],
      siteid: [],
      serviceid: [],
      recordStatus: [0],
      ratebrackettype:[0],
      minimum:[0],
      createdAt: [new Date(), Validators.required],
      expires: [moment().add(1, 'year')],
      RateBreaks: rateBreaksFormArray,
      CustomerRates: customerRatesFormArray
    })
    if (rate) {
      rateFormGroup.patchValue(rate);
    }
    return rateFormGroup;
  }
  /**
   * if we load a rate into a rate form group, create a set of ratebreakgroups and add them to the rateformgroups' ratebreakformarray
   */
  patchRateBreaks(rateform: FormGroup, rate: Rate) {
    let array = <FormArray>rateform.get("RateBreaks");
    array.clear();
    rate.RateBreaks.forEach(rb => {
      array.push(this.createRateBreakGroup(rb));
    })
  }
  /**
   * create a form for with band premiums
   * potentially deprecated
   * @param rate optional
   */
  createRateFormGroupWithPremiums(bandPremiumsFormArray: FormArray, rate?: Rate): FormGroup {
    let rateBreaksFormArray: FormArray = this.createFormArray();
    let customerRatesFormArray: FormArray = this.createFormArray();

    let rateFormGroup = this.formBuilder.group({
      id: [],
      companyid: [],
      regionid: [],
      baseprice: [0, Validators.required],
      baseIncludesWeight: [0],
      baseIncludesVolume: [0],
      priority: [0],
      createdAt: [new Date(), Validators.required],
      expires: [moment().add(1, 'month')],
      RateBreaks: rateBreaksFormArray,
      BandPremiums: bandPremiumsFormArray,
      CustomerRates: customerRatesFormArray
    })
    if (rate) {
      rateFormGroup.patchValue(rate);
    }
    return rateFormGroup;
  }
  /**
   * 
   * @param ratebreak create a form for managing rate breaks
   */
  createRateBreakGroup(ratebreak?: RateBreak): FormGroup {
    let ratebreakPremiumsFormArray = this.createFormArray();
    let ratebreakGroup = this.formBuilder.group({
      id: [],
      rateid: [],
      breakbarrierincludes: [true, Validators.required],
      weightbreak: [0],
      volumebreak: [0],
      maxweight: [0],
      maxvolume: [0],
      price: [0, Validators.required],
      priceperbreak: [false],
      minweight: [0],
      minvolume: [0],
      fixed: [],
      recordStatus: [0],
      RateBreakPremiums: ratebreakPremiumsFormArray
    })
    if (ratebreak) {
      ratebreakGroup.patchValue(ratebreak);
    }
    return ratebreakGroup;
  }


  createBandPremiumGroup(bandPremium?: BandPremium): FormGroup {
    let bandpremium = this.formBuilder.group({
      rateid: [],
      premiumpercent: [1],
      premiumvalue: [0]
    })
    if (bandPremium) bandpremium.patchValue(bandPremium);
    return bandpremium;
  }

  createRateEditorForm(zone: Region[]) {
    let rateeditorform = this.formBuilder.group({
      zones: this.formBuilder.array([])
    })

  }
  createRegionRateForm(band: Region) {

  }
  createRatePriorityForm(priority?: RatePriority): FormGroup {
    let prioritygroup = this.formBuilder.group({
      id: [],
      recordStatus: [0],
      customerid: [0],
      companyid: [0],
      regionlevel: [1],
      bytime: ["18:00:00"],
      daysplus: [1],
      specificday:[],
      name: ["", Validators.required]
    })
    if (priority) {
      prioritygroup.patchValue(priority);
    }
    return prioritygroup;
  }
  createMarginsForm(): FormGroup {
    let marginsgroup = this.formBuilder.group({
      marginsArray: this.createFormArray(),
      surchargeArray: this.createFormArray()
    })
    return marginsgroup;
  }
  /**
   * unlike most forms margin will always have a default margin provided
   * id = 0 if it is meant to create a new one
   * @param margin 
   */
  createMarginForm(margin: Margin, title: string): FormGroup {
    let margingroup = this.formBuilder.group({
      id: [],
      title: [title],
      recordStatus: [0],
      companyid: [0],
      providerid: [0],
      fixed: [0],
      currency: [0],
      percent: [0],
      start: [],
      expires: [],
      appliesto: [0],
      surchargetype: []
    })

    margingroup.patchValue(margin);

    return margingroup;
  }
  createSurchargeMarginGroup(title: string): FormGroup {
    let smg = this.formBuilder.group({
      title: [title],
      marginArray: this.formBuilder.array([])
    })
    return smg;
  }
  createDiscountsForm(minmargin: MinimumMargin): FormGroup {
    let marginsgroup = this.formBuilder.group({
      discountsArray: this.createFormArray(),
      surchargeArray: this.createFormArray(),
      minimumMargin: this.createMinMarginGroup(minmargin)
    })
    return marginsgroup;
  }
  /**
   * unlike most forms discount will always have a default margin provided
   * id = 0 if it is meant to create a new one
   * @param margin 
   */
  createDiscountForm(discount: Discount, title: string): FormGroup {
    let discountgroup = this.formBuilder.group({
      id: [],
      title: [title],
      recordStatus: [0],
      companyid: [0],
      customerid: [0],
      fixed: [0],
      currency: [0],
      percent: [0],
      start: [],
      expires: [],
      appliesto: [0],
      surchargetype: []
    })

    discountgroup.patchValue(discount);

    return discountgroup;
  }
  createMinMarginGroup(minmargin: MinimumMargin): FormGroup {
    let mingroup = this.formBuilder.group({
      id: [],
      fixed: [0],
      percent: [0],
      recordStatus: [0],
      companyid: [],
      customerid: [],
      start: [],
      expires: [],
      currency: []
    })
    mingroup.patchValue(minmargin);
    return mingroup;
  }
  createSurchargeDiscountGroup(title: string): FormGroup {
    let smg = this.formBuilder.group({
      title: [title],
      discountArray: this.formBuilder.array([])
    })
    return smg;
  }


  createQuotationSurchargeGroup(): FormGroup {
    let qsgroup = this.formBuilder.group({
      price: []
    })
    return qsgroup;
  }

  createQuotationForm(quote: Quotation): FormGroup {
    let quotegroup = this.formBuilder.group({
      id:[],
      portoptions: this.createPortOptionArray(quote),
      selectedOption:[0],
      expires:[],
      currency:[]
    })
    /*patch at lower levels to allow for point to points
    if(!quote.pointtopoint){
      quotegroup.patchValue(quote);
    }
    else{
      //TODO patch Point To Point
    }*/
    return quotegroup;

  }
  createPortOptionArray(quote: Quotation): FormArray {
    let portoptionarray = this.formBuilder.array([]);
    quote.portoptions.forEach(po => {
      portoptionarray.push(this.createPortOptionGroup(po));
    })
    return portoptionarray;
  }
  createPortOptionGroup(portoption: PreferredPorts): FormGroup {
    let portgroup = this.formBuilder.group({
      mode: [portoption.mode],
      vessel: this.createVesselGroup(portoption.vessel),
      service: this.createServiceGroup(portoption.service),
      origin:this.createLocodeGroup(portoption.origin),
      originSites: this.createOriginSitesArray(portoption.originSites),
      originSiteIndex:[0],
      destination:this.createLocodeGroup(portoption.destination),
      destinationSites:this.createOriginSitesArray(portoption.destinationSites),
      destinationSiteIndex:[0],
      port2port:this.createPort2PortGroup(portoption.port2port),
      servicerates: this.createQuotePriceArray(portoption.servicerates),
      servicesurcharges:this.createServiceSurchargeArray(portoption.servicesurcharges),
      quotesurcharges:this.createServiceSurchargeArray(portoption.quotesurcharges),
      balance:this.createQuotePriceGroup(portoption.balance),
      total:this.createQuotePriceGroup(portoption.total)
    })
    return portgroup;
  }
  
  createLocodeGroup(locode:Locode):FormGroup{
    let locodegroup = this.formBuilder.group({
      id:[],
      countryid:[],
      geoLat:[],
      geoLong:[],
      locode1:[],
      locode2:[],
      name:[],
      nodiacritic:[]
    })
    if(locode) locodegroup.patchValue(locode);
    return locodegroup;
  }
  createPort2PortGroup(port2port:PortToPort):FormGroup{
    let p2pgroup = this.formBuilder.group({
      id:[],
      originlocodeid:[],
      destinationlocodeid:[],
      duration:[],
      metres:[],
      transportmode:[]
    })
    if(port2port) p2pgroup.patchValue(port2port);
    return p2pgroup;
  }
  createVesselGroup(vessel:Vessel) {
    let vesselgroup = this.formBuilder.group({
      id:[],
      name: []
    })
    if(vessel) vesselgroup.patchValue(vessel);
    return vesselgroup;
  }
  createServiceGroup(service:Service) {
    let servicegroup = this.formBuilder.group({
      id:[],
      displayname: [],
      description: [],
      quoteValidity: [],
      transitTime: [],
      frequency: [],
      providerid:[]

    })
    if(service) servicegroup.patchValue(service);
    return servicegroup;
  }
  createServiceSurchargeArray(qps:QuotationPrice[]):FormArray{
    let osarray = this.formBuilder.array([]);
    if(qps){
      qps.forEach(qp=>{
      osarray.push(this.createQuotePriceGroup(qp));
    })
    }
    return osarray;
  }
  createOriginSitesArray(sites: SiteRegion[]): FormArray {
    let osarray = this.formBuilder.array([]);
    if (sites && sites.length > 0) {
      sites.forEach(site => {
        let osg = this.createOriginSiteGroup(site);
        if(!site.rateRegion){
          osg.removeControl("rateRegion");
        }
        osarray.push(osg);
      })
    }
    if(sites && sites.length>0){
      osarray.patchValue(sites);
    } 
    return osarray;
  }
  createOriginSiteGroup(site: SiteRegion): FormGroup {
    let osgroup = this.formBuilder.group({
      site:this.createSiteRegionGroup(site.site),
      priorities:this.createPrioritiesArray(site),
      hasCollection:[false],
      rateRegion: site.rateRegion?[]:this.createRateRegionGroup(),
      surcharges: this.createOriginSurchargeArray(site),
      hasSurcharges:[false],
      totalCollection:[0],
      selectedPriority:[0],
      include:[true]
    })
    

    return osgroup;
  }
  createPrioritiesArray(site:SiteRegion):FormArray{
    let priorarray = this.formBuilder.array([]);
    if(site && site.priorities && site.priorities.length>0){
      site.priorities.forEach(p=>{
        priorarray.push(this.createPrioritiesGroup(p));
      })
    }
    
    return priorarray;
  }
  createPrioritiesGroup(priority:Priority):FormGroup{
    let priorgroup = this.formBuilder.group({
      name:[],
      id:[],
      collection: this.createQuotePriceGroup(priority.collection),
    })
    return priorgroup;
  }
  createSiteRegionGroup(site:Site):FormGroup{
    let sitegroup = this.formBuilder.group({
      id:[],
      name:[],
      Address:this.createAddressFormGroup(),
      Company:this.createPartnerCompanyForm()
    })
    return sitegroup;
  }

  createPartnerCompanyForm():FormGroup{
    let cogroup = this.formBuilder.group({
      id:[],
      name:[]
    })
    return cogroup;
  }
  createQuotePriceArray(quotepricearray: QuotationPrice[]): FormArray {
    let qparray = this.formBuilder.array([]);
    if (quotepricearray) {
      quotepricearray.forEach(qp => {
        qparray.push(this.createQuotePriceGroup(qp));
      })
    }
    return qparray;
  }
  createQuotePriceGroup(quoteprice:QuotationPrice): FormGroup {
    let qpgroup = this.formBuilder.group({
      id:[],
      appliesto:[],
      tableid:[],
      description: [],
      costprice: [0],
      discountedprice: [0],
      listprice: [0],
      margin: [],
      include: [true]
    })
    if(quoteprice) qpgroup.patchValue(quoteprice);
    return qpgroup;
  }
  createRateRegionGroup(): FormGroup {
    let rrgroup = this.formBuilder.group({
      id:[],
      name: []
    })
    return rrgroup;
  }
  createOriginSurchargeArray(site: SiteRegion): FormArray {
    let osa = this.formBuilder.array([]);
    site.surcharges.forEach(s => {
      osa.push(this.createQuotePriceGroup(s));
    })
    return osa;
  }

  createQuotationSurchargeFormGroup():FormGroup{
    let qsg = this.formBuilder.group({
      id:[],
      providerid:[],
      transportMode:[],
      surchargeQuotationFormArray:this.formBuilder.array([])
    })
    return qsg
  }

}
