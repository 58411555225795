<h3>Geocoder</h3>
<div class="fl">
    <mat-spinner *ngIf="isLoading" diameter="32"></mat-spinner>
    <mat-form-field class="mr">
        <mat-label>Origin</mat-label>
        <input matInput type="text" placeholder="Origin" [(ngModel)]="address">
    </mat-form-field>
    <button type="button" mat-mini-fab aria-label="geocode" color="primary" title="Geocode Address">
        <mat-icon (click)="calculate()">near_me</mat-icon>
    </button>
</div>
<div *ngIf="geoResponse">
    <div *ngFor="let geo of geoResponse">
        <div>{{geo.formatted_address}}</div>
        <div>Lat: {{geo.geometry.location.lat()}} Lng: {{geo.geometry.location.lng()}}</div>
        <table>
            <tr>
                <th>Long Name</th>
                <th>Short Name</th>
                <th>Types</th>
            </tr>
            <tr *ngFor="let add of geo.address_components">
                <td>{{add.long_name}}</td>
                <td>{{add.short_name}}</td>
                <td>
                    <ul>
                        <li *ngFor="let type of add.types">{{type}}</li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</div>