<h3>Port Finder</h3>
<div class="fl">
    <mat-form-field class="form-skinny">
        <mat-label>Country</mat-label>
        <mat-select [(ngModel)]="selectedCountry">
            <mat-option *ngFor="let country of data.countries" [value]="country">
                <img class="flag" src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Transport Mode</mat-label>
        <mat-select [(ngModel)]="selectedMode">
            <mat-option *ngFor="let type of transportModes | enumpipe" [value]="type.key">
                {{type.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Search Text</mat-label>
        <input matInput type="text" placeholder="Search" [(ngModel)]="text">
    </mat-form-field>
    <button type="button" mat-mini-fab aria-label="Search Locodes" color="primary" title="Search Locodes"
        (click)="search()" [disabled]="!text || text.length<2 || !selectedCountry">
        <mat-icon>search</mat-icon>
    </button>
</div>