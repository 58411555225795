import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Service, ServicePort, Locode } from 'src/app/models/data';
import { Site, Address, Company, StatusMessage, CompanyAssociation, SiteTypes } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { PortSite, PortProvider } from 'src/app/models/ui';
import { CompanySearchComponent } from 'src/app/widgets/company-search/company-search.component';
import { ppid } from 'process';
import { TransportModes } from 'src/app/models/rates';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-portpartners',
  templateUrl: './portpartners.component.html',
  styleUrls: ['./portpartners.component.scss']
})
export class PortpartnersComponent implements OnInit {
  @Input() serviceForm: FormGroup;
  @Input() editmode: boolean = false;

  @ViewChildren('cosearch') cosearch: QueryList<CompanySearchComponent>;

  public selectedProvider: CompanyAssociation;

  get service(): Service {
    return this.serviceForm.value;
  }
  get originServicePorts() {
    return <FormArray>this.serviceForm.get("OriginServicePorts");
  }
  get destinationServicePorts() {
    return <FormArray>this.serviceForm.get("DestinationServicePorts");
  }


  constructor(public data: DataService, public tools: ToolsService, public formtools: FormtoolsService, public events: EventsService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.events.companyselect.subscribe((co: CompanyAssociation) => {
        this.selectedProvider = co;
      })
    })
  }


  createServicePartner(port: FormGroup) {
    let company = this.data.Company;
    if (this.selectedProvider) company = this.selectedProvider.Provider;
    this.addSite(company, port);


  }
  addSite(company: Company, port: FormGroup) {
    let providerarray = <FormArray>port.get("Providers");
    let locode = port.value.Locode;
    let exists = false;
    providerarray.controls.some(c=>{
      let bob = c.value;
      if(bob.providerid==company.id && bob.locodeid==locode.id){
        exists = true;
        return true;
      }
    })

    if(!exists){

 
    this.data.addPortSite(locode, company, this.service.transportMode).then((message: StatusMessage) => {
      if (message.success) {
        //todo: not sure if we need to do this any more  
        
        let siteport = new PortSite();
        siteport.name = company.name;
        siteport.locodeid = locode.id;
        siteport.partnersiteid = message.message.id;
        siteport.siteid = message.message.id;
        this.formtools.createProviderForm(providerarray, [siteport]);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    })}
    else{
      this.tools.snackMessage("Partner site already exists");
    }
  }
  setPreferred(portform: FormGroup, portsite: FormGroup, e) {
    let siteid = portsite.value.siteid;
    if (!e.checked) siteid = null;
    portform.get("partnersiteid").setValue(siteid);
    portsite.markAsDirty();
    /*
    dsp.controls.concat(osp.controls).some((spform:FormGroup)=>{
      if(spform.get("locodeid").value==portsite.value.locodeid){
        let partner = spform.get("partnersiteid");
        if(e.checked){
          partner.setValue(portsite.value.siteid);
        }
        else partner.setValue(null);
        partner.markAsDirty();
        return true;
      }
    })*/
  }

}
