<div class="fl pad aic">
  <div class="fl">
    <button *ngIf="showadd" type="button" mat-mini-fab aria-label="Add New" color="primary" title="Add New"
      (click)="addNew()" class="mr">
      <mat-icon>add</mat-icon>
    </button>
    <cs-objectifier *ngIf="appliesto" #objectifier class="mr" [appliesto]="appliesto"></cs-objectifier>
  </div>
  <div class="f1"></div>
  <mat-form-field class="mr">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search on any field">
  </mat-form-field>


</div>

<table mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8 {{localclass}}">
  <ng-container *ngFor="let col of columnObjects" matColumnDef="{{col.path}}">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col.title}} </th>
    <td mat-cell class="pointer" *matCellDef="let element" (click)="selectRow(element)">
      <div [innerHTML]="cellContent(element,col)"></div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnStrings"></tr>
  <tr mat-row *matRowDef="let row; columns: columnStrings;"></tr>
</table>
<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>