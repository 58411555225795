import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Milestone, MilestoneStatus } from 'src/app/models/models';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { StatusMessage } from 'src/app/models/user';
import moment from 'moment';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss']
})
export class MilestoneComponent implements OnInit {

  @Input() milestone:Milestone;
  @Input() parentSelect:any;
  @Output() select = new EventEmitter<Milestone>();

  @Input() selectedId:number;
  @Input() folder:boolean = false;
  
  constructor(public data:DataService,private tools:ToolsService, public events:EventsService) { }

  ngOnInit() {
  }

  selectMilestone(milestone){
    //this.data.roadmapSettings.selected = milestone;
    //this.select.emit(milestone);
    this.events.selectedMilestone.emit(milestone);
  }

  get statusIcon(){
    if(this.milestone.status == MilestoneStatus.COMPLETED) return 2;
    if(moment(this.milestone.dueDate).diff(moment(),'d')<0) return 1;
    return 0;
  }

  addChild(){
    let subMilestone = new Milestone(this.data.auth.user.id,0);
    subMilestone.parentId = this.milestone.id;

    this.data.createMilestone(subMilestone).subscribe((message:StatusMessage)=>{
      if(message.success){
        let newsub = message.message;
        this.addChildLocally(newsub);
      }
      else{
        this.tools.gracefulError(message.message);
      }
      
    },
    err=>{
      this.tools.gracefulError(err);
    })
  }
  addChildLocally(newsub:Milestone){
    if(!this.milestone.children) this.milestone.children = [];
    this.milestone.children.push(newsub);
    this.milestone._folderOpen = true;
    this.events.addedChildMilestone.emit(newsub);
    this.events.selectedMilestone.emit(newsub);
  }


}
