import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cs-addregion',
  templateUrl: './addregion.component.html',
  styleUrls: ['./addregion.component.scss']
})
export class AddregionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
