<div *ngIf="quoteOptions" class="section">
    <h3>Selected Options</h3>
    <div class="fl fw">
        <ng-container *ngFor="let qo of quoteOptions">
            <div class="fl aic" *ngIf="qo.selected">
                <span class="field-title">{{qo.description}}</span>
                <cs-yes-no-view [value]="true"></cs-yes-no-view>
            </div>
        </ng-container>
<!-- 
        <div class="fl aic" *ngIf="quoteOptions[0].selected">
            <span class="field-title">Only show services with collection capability</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>
        <div class="fl aic" *ngIf="quoteOptions[1].selected">
            <span class="field-title">Only show services with delivery capability</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>
        <div class="fl aic" *ngIf="quoteOptions[2].selected">
            <span class="field-title">Collection requires tail lift</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>
        <div class="fl aic" *ngIf="quoteOptions[3].selected">
            <span class="field-title">Delivery requires tail lift</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>
        <div class="fl aic" *ngIf="quoteOptions[4].selected || 1==1">
            <span class="field-title">Collection address is residential</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>
        <div class="fl aic" *ngIf="quoteOptions[5].selected || 1==1">
            <span class="field-title">Delivery address is residential</span>
            <cs-yes-no-view [value]="true"></cs-yes-no-view>
        </div>-->
    </div>


</div>