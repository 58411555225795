import { Component, OnInit, Inject } from '@angular/core';
import { Region, RegionGroupType, GPS, CustomerRate } from 'src/app/models/rates';
import { Site, StatusMessage } from 'src/app/models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegiongrouperComponent } from '../../regiongrouper/regiongrouper.component';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-continental-grouper',
  templateUrl: './continental-grouper.component.html',
  styleUrls: ['./continental-grouper.component.scss']
})
export class ContinentalGrouperComponent implements OnInit {

  public parentRegion: Region;
  public regiongroups: RegionGroupType[] = [];
  public regionName: string = "";
  public bands: number = 5;

  public distanceFrom = false;
  public custom = true;
  public selectedGroup: RegionGroupType;

  constructor(public dialogRef: MatDialogRef<RegiongrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, private tools: ToolsService) {
    this.parentRegion = dialogdata.Region;
  }

  ngOnInit(): void {
    
    if(this.parentRegion.children && this.parentRegion.children.length>0){
      let currentzone = this.parentRegion.children[0].zonetype;
      
    }
    this.continentGroupTypes();
  }
  continentGroupTypes() {
    this.data.listRegionGroupTypes(this.parentRegion.continentid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let grouptypes: RegionGroupType[] = message.message;
        if (grouptypes.length > 0) {
          //override defaults.
          this.regiongroups = grouptypes;
        }
        else this.regiongroups = [];
        let distance = new RegionGroupType();
        distance.description = "Distance from "+this.data.selectedSupplier._baseSite.name;
        distance.id = -1;
        let custom = new RegionGroupType();
        custom.id = -2;
        custom.description = "Custom Groups";
        this.selectedGroup = custom;
        this.regiongroups.push(distance);
        this.regiongroups.push(custom);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  cancel() {
    this.dialogRef.close();
  }
  create() {
    this.dialogRef.close({ which: this.selectedGroup, bands: this.bands });
  }


}
