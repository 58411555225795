import { Injectable, EventEmitter } from '@angular/core';
import { Milestone, Document } from '../models/models';
import { Region, Rate, RateBreak } from '../models/rates';
import { DrawnPoly } from './google.service';
import { RateBreakIndex } from '../models/ui';
import { Company, CompanyAssociation, NewCompanySettingType } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public sessionexpired = new EventEmitter<boolean>();
  public addedChildMilestone = new EventEmitter<Milestone>();
  public selectedMilestone = new EventEmitter<Milestone>();
  public selectedDocument = new EventEmitter<Document>();
  public settingsTabChange = new EventEmitter<number>();
  public selectRegion = new EventEmitter<Region>();
  public contextMenuRegion = new EventEmitter<any>();
  public dropRegion = new EventEmitter<any>();
  public createRegion = new EventEmitter<Region>();
  public deleteRegion = new EventEmitter<Region>();
  public updateParentRegion = new EventEmitter<Region>();
  public startCreateRegion = new EventEmitter();
  public updateRegionFeature = new EventEmitter<Region>();
  public polygonClick = new EventEmitter<DrawnPoly>();
  public cancelLoading = new EventEmitter();
  public closeCard = new EventEmitter();
  public cancelRateImport = new EventEmitter();
  public rateCreation = new EventEmitter<Rate[]>();
  public globalRateParameters = new EventEmitter<Rate>();
  public addBreak = new EventEmitter<RateBreak>();
  public changeBreak = new EventEmitter<RateBreakIndex>();
  public companiesloaded = new EventEmitter();
  public companyselect = new EventEmitter<CompanyAssociation>();
  public addrate = new EventEmitter<Rate[]>();

  public quoteoptionselect = new EventEmitter<number>();
  public quoteready = new EventEmitter();

  public companysettingchange = new EventEmitter<NewCompanySettingType>();

  public spinneron = false;
  public updater = false;
  public todo = 0;
  public done = 0;
  
  
  constructor() { }

  public startSpinner(todo?:number){
    if(todo){
      this.done = 0;
      this.todo = todo;
      this.updater = true;
    }
    this.spinneron = true;
  }
  public updateSpinner(add?:number){
    if(add) this.done=add;
    else this.done++;
  }
  public stopSpinner(){
    this.done = 0;
    this.todo = 0;
    this.updater = false;
    this.spinneron = false;
  }
}
