export class Unsworth {
    origin_locode: string;
    destination_locode: string;
    group_id: string;
    group_name: string;
    transshipment: string;
    transit_time: number;
    remarks: string;
    wm_ratio: number;
    effective_date: Date;
    expiration_date: Date;
    origin: string;
    country_origin: string;
    destination: string;
    country_destination: string;
    mot: string;
    carrier: string;
    service_level: string;
    load_type: string;
    rate_basis: string;
    currency: string;
    bas: number;
    cdd: number;
    eca: number;
    lss: number;
    pss: number;
    createdAt:Date;
    updatedAt:Date;
    importaid:number;
    constructor() {
        this.origin_locode = "";
        this.destination_locode = "";
        this.group_id = "";
        this.group_name = "";
        this.transshipment = "";
        this.transit_time = 0;
        this.remarks = "";
        this.wm_ratio = 0;
        this.effective_date = new Date();
        this.expiration_date = new Date();
        this.origin = "";
        this.country_origin = "";
        this.destination = "";
        this.country_destination = "";
        this.mot = "";
        this.carrier = "";
        this.service_level = "";
        this.load_type = "";
        this.rate_basis = "";
        this.currency = "";
        this.bas = 0;
        this.cdd = 0;
        this.eca = 0;
        this.lss = 0;
        this.pss = 0;
    }
}
export class BigTable{
    stringfield1:string;
    stringfield2:string;
    stringfield3:string;
    stringfield4:string;
    stringfield5:string;
    stringfield6:string;
    stringfield7:string;
    stringfield8:string;
    stringfield9:string;
    stringfield10:string;
    integerfield1:number;
    integerfield2:number;
    integerfield3:number;
    integerfield4:number;
    integerfield5:number;
    integerfield6:number;
    integerfield7:number;
    integerfield8:number;
    integerfield9:number;
    integerfield10:number;
    decimalfield1:number;
    decimalfield2:number;
    decimalfield3:number;
    decimalfield4:number;
    decimalfield5:number;
    decimalfield6:number;
    decimalfield7:number;
    decimalfield8:number;
    decimalfield9:number;
    decimalfield10:number;
    datefield1:number;
    datefield2:number;
    datefield3:number;
    datefield4:number;
    datefield5:number;
    datefield6:number;
    datefield7:number;
    datefield8:number;
    datefield9:number;
    datefield10:number;
    providerid:number;
    importaid:number;

}
