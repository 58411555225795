<h3>Delivery Priority</h3>
<div [formGroup]="priorityForm">
    <mat-form-field>
        <mat-label>name</mat-label>
        <input matInput type="text" placeholder="name" formControlName="name" autocomplete="rate-priority-name">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Delivery Days</mat-label>
        <input matInput type="number" placeholder="name" formControlName="daysplus" autocomplete="rate-priority-days">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Specific Day</mat-label>
        <mat-select  formControlName="specificday">
            <mat-option [value]="1">
                Sunday
            </mat-option>
            <mat-option [value]="2">
                Monday
            </mat-option>
            <mat-option [value]="3">
                Tuesday
            </mat-option>
            <mat-option [value]="4">
                Wednesday
            </mat-option>
            <mat-option [value]="5">
                Thursday
            </mat-option>
            <mat-option [value]="6">
                Friday
            </mat-option>
            <mat-option [value]="7">
                Saturday
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>delivery by</mat-label>
        <input matInput placeholder="name" formControlName="bytime" autocomplete="rate-priority-time" [ngxTimepicker]="picker">
        <ngx-material-timepicker #picker></ngx-material-timepicker>
    </mat-form-field>

    <div class="jc">
        <button mat-raised-button color="warn" (click)="cancel()" type="button" class="mr">Cancel</button>
        <button mat-raised-button color="warn" (click)="delete()" type="button" class="mr"
            *ngIf="priority.id">Delete</button>
        <button mat-raised-button color="primary" type="button" (click)="submit()"
            [disabled]="!priorityForm.valid">{{priority.id?'Update':'Create'}}</button>
    </div>
</div>