import { Directive,ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[csSettingsContainer]'
})
export class SettingsContainerDirective {

  constructor(public containerRef:ViewContainerRef) { }

}
