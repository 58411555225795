<div [formGroup]="serviceForm" class="mt">
    <div class="fl aic">
        <div class="f1"></div>
        <div>selected provider:</div>
        <div>
            <cs-company-search #cosearch  [host]="'portpartners'"></cs-company-search>
        </div>
    </div>
    <p *ngIf="originServicePorts.controls.length==0 && destinationServicePorts.controls.length==0">
        <i>No Ports Selected</i>
    </p>
    <mat-tab-group formArrayName="OriginServicePorts">
        <mat-tab *ngFor="let oc of originServicePorts.controls;let i=index;" [formGroup]="oc">
            <ng-template mat-tab-label>
                {{oc.value.Locode.nodiacritic}}
            </ng-template>
            <div class="mt fl" formArrayName="Providers">
                <div>
                    <p>If no preferred partners are selected, the best match will be used.</p>
                <table>
                    <tr class="ma">
                        <th>Preferred</th>
                        <th>Partner</th>
                    </tr>
                    <tr *ngFor="let prov of oc.controls.Providers.controls" [formGroup]="prov">
                        <td class="tac">
                            <mat-checkbox [checked]="oc.value.partnersiteid==prov.value.siteid" (change)="setPreferred(oc,prov,$event)" ngDefaultControl></mat-checkbox>
                        </td>
                        <td>
                            {{prov.value.name}}
                        </td>
                    </tr>
                </table>                    
                </div>
                <div class="f1">

                </div>

            </div>
            <div class="mt mb">
                <button type="button" mat-mini-fab aria-label="Add Partner" color="primary"
                    title="Add Partner" (click)="createServicePartner(oc)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </mat-tab>
        <mat-tab *ngFor="let dc of destinationServicePorts.controls;let i=index;" [formGroup]="dc">
            <ng-template mat-tab-label>
                {{dc.value.Locode.nodiacritic}}
            </ng-template>
            <div class="mt fl" formArrayName="Providers">
                <div>
                    <p>If no preferred partners are selected, the best match will be used.</p>
                    <table>
                        <tr class="ma">
                            <th>Preferred</th>
                            <th>Partner</th>
                        </tr>
                        <tr *ngFor="let prov of dc.controls.Providers.controls" [formGroup]="prov">
                            <td class="tac">
                                <mat-checkbox [checked]="dc.value.partnersiteid==prov.value.siteid" (change)="setPreferred(dc,prov,$event)" ngDefaultControl></mat-checkbox>
                            </td>
                            <td>
                                {{prov.value.name}}
                            </td>
                        </tr>
                    </table>                    
                    </div>
                    <div class="f1">
    
                    </div>
            </div>
            <div class="mb">
                <button type="button" mat-mini-fab aria-label="Add Partner" color="primary"
                    title="Add Partner" (click)="createServicePartner(dc)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="mt"></div>
</div>