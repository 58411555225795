import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SurchargeAppliesTo, SurchargeType, Surcharge } from 'src/app/models/rates';
import { AddSurchargetypeComponent } from 'src/app/snippets/add-surchargetype/add-surchargetype.component';
import { CompanySearchComponent } from 'src/app/widgets/company-search/company-search.component';
import { FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';

@Component({
  selector: 'cs-quote-surcharges',
  templateUrl: './quote-surcharges.component.html',
  styleUrls: ['./quote-surcharges.component.scss']
})
export class QuoteSurchargesComponent implements OnInit {

  public providerid:number;
  public appliesto = SurchargeAppliesTo.Company;
  public form:FormGroup;

 
  constructor(public data:DataService,public formtools:FormtoolsService) { }

  ngOnInit(): void {
    this.providerid = this.data.Company.id;
    this.form = this.formtools.createQuotationSurchargeFormGroup();
  }

}
