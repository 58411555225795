import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { ConsolidationCentre } from 'src/app/models/rates';

@Component({
  selector: 'cs-consolidation',
  templateUrl: './consolidation.component.html',
  styleUrls: ['./consolidation.component.scss']
})
export class ConsolidationComponent implements OnInit {

  @Input() siteid:number;
  @Input() justrates:boolean;

  public centres:ConsolidationCentre[] = [];
 
  constructor(public data:DataService) { }

  ngOnInit(): void {
    this.data.listPortSiteConsolidation(this.siteid).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.centres = message.message;
      }
    },err=>{
      console.log(err);
    })
  }

}
