<div *ngIf="recordStatus<3" [formGroup]="surcharge" class="fl aic">
    
        <mat-form-field>
            <mat-label>Surcharge Type</mat-label>
            <mat-select formControlName="surchargetypeid">
                <mat-option *ngFor="let surchargetype of surchargeTypes" [value]="surchargetype.id">
                    {{surchargetype.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Fixed Charge</mat-label>
            <input type="number" matInput formControlName="surchargeFixed" csInputCurrency>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>% Charge</mat-label>
            <input type="number" matInput formControlName="surchargePercent">
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Fee Applies per</mat-label>
            <mat-select formControlName="perunit" (selectionChange)="checkPerUnit($event)">
                <mat-option [value]="priceAppliesTo.Shipment">
                    shipment
                </mat-option>
                <mat-option [value]="priceAppliesTo.Weight">
                    kg
                </mat-option>
                <mat-option [value]="priceAppliesTo.Volume">
                    m<sup>3</sup>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Charge per Unit {{perunit}}</mat-label>
            <input type="number" matInput formControlName="unit">
        </mat-form-field>
        <mat-form-field class="form-skinny" *ngIf="currencyView">
            <mat-label>Currency</mat-label>
            <input class="hidden" matInput formControlName="currencyid">
            <div class="fl aic">
                <div class="f1 jc">{{currencyView.code}}</div>
                <mat-icon class="inside-matform" (click)="selectCurrency()">payment</mat-icon>
            </div>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Item Bracket</mat-label>
            <mat-select formControlName="ratebracket">
                <mat-option [value]="0">
                    All
                </mat-option>
                <mat-option *ngFor="let rb of data.ratebrackets" [value]="rb.id">
                    {{rb.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="starts">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-skinny">
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="expires">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
         
        <div class="pad">
            <mat-checkbox formControlName="optional" ngDefaultControl>optional</mat-checkbox>
        </div>

        <mat-icon color="warn" class="pointer" (click)="deleteCharge()">delete</mat-icon>

</div>

