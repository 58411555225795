
<table class="rate c1lcnc" *ngIf="rates.length>0">
    <tr>
        <th>Type</th>
        <th>up to (kg)</th>
        <th>up to m<sup>3</sup></th>
        <th>Price per</th>
        <th>Cost Price</th>
        <th>List Price</th>
        <th>Margin %</th>
        
        <th *ngIf="showmore">Valid From</th>
        <th *ngIf="showmore">Valid To</th>
        <th *ngIf="showmore">Breaks Apply</th>
        <th *ngIf="showmore">m<sup>3</sup> Equiv</th>
        <th><mat-icon class="pointer" (click)="showmore=!showmore">{{showmore?'chevron_left':'chevron_right'}}</mat-icon></th>           
    </tr>
    <tbody *ngFor="let rate of rates" class="ratebody">
    <tr class="pointer" (click)="selectRate(rate)">
        <td title="{{rate.id}}">{{rate.name}}</td>
        <td><span *ngIf="rate.baseIncludesWeight>0">{{rate.baseIncludesWeight | number}}</span></td>
        <td><span *ngIf="rate.baseIncludesVolume>0">{{rate.baseIncludesVolume | number}}</span></td>
        <td>{{rate.priceperbreak?'band':'kg'}}</td>
        <td>{{rate.baseprice | currency:defaultcurrency.currencyCode}}</td>
        <td>{{rate.baseprice*1.1 | currency:defaultcurrency.currencyCode}}</td>
        <td>10%</td>
        
        <td *ngIf="showmore">{{rate.createdAt | date:'dd-MM-yyyy'}}</td>
        <td *ngIf="showmore">{{rate.expires | date:'dd-MM-yyyy'}}</td>
        <td *ngIf="showmore">{{rate.breakbarrierincludes?'<= next value':'< next value'}}</td>
        <td *ngIf="showmore">{{rate.volumeequivalent | number}}</td>
        <td></td>
        
    </tr>
    <tr *ngFor="let br of rate.RateBreaks" class="pointer" (click)="selectRate(rate)" >
        <td title="{{br.id}}"></td>
        <td><span *ngIf="br.maxweight>0">{{br.maxweight}}</span></td>
        <td><span *ngIf="br.maxvolume>0">{{br.maxvolume}}</span></td>
        <td>{{br.priceperbreak?'band':br.weightbreak==1?'kg':br.weightbreak+' kg'}}</td>
        <td>{{br.price | currency:defaultcurrency.currencyCode}}</td>
        <td>{{br.price*1.05 | currency:defaultcurrency.currencyCode}}</td>
        <td>5%</td>
        <td *ngIf="showmore"></td>
        <td *ngIf="showmore"></td>
        <td *ngIf="showmore"></td>
        <td *ngIf="showmore"></td>
        <td></td>
    </tr>
</tbody>
</table>
