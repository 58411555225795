import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { InvitationStatus, StatusMessage, Roles, Invitation } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';


@Component({
  selector: 'cs-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  @Input() parentForm: FormArray;
  @Input() invitation: FormGroup;
  @Input() index: number;

  public deleted: boolean = false;
  public roles = Roles;

  public invitationStatus = InvitationStatus;

  constructor(public data: DataService, private tools: ToolsService) {
  }

  get email() {
    return this.parentForm.controls[this.index].get("email");
  }
  get status() {
    return this.parentForm.controls[this.index].get("status").value;
  }

  get statusIcon() {

    switch (this.status) {
      case InvitationStatus.SENT:
        return "repeat";
      case InvitationStatus.REJECTED:
        return "not_interested";
      default:
        return "send";
    }
  }
  ngOnInit() {
    //console.log(this.parentForm);
  }
  save(){
    this.data.updateInvitation(this.invitation.value).subscribe((message:StatusMessage)=>{
      let invite:Invitation = this.invitation.value;
      if(message.success){
        //leave parentForm as invalid so save will repop customer

      }
    })
  }

  sendInvite() {
    let inviteform = this.parentForm.controls[this.index];
    let newinvite = inviteform.value;

    this.data.sendInvitation(newinvite).subscribe((message: StatusMessage) => {
      if (message.success) {
        //console.log(message.message);
        if (message.message.message) {
          if (message.message.message.emailresult.success) {
            let incoming = message.message.invite;
            inviteform.reset();
            inviteform.patchValue(incoming);
            this.tools.snackMessage("Invitation Sent");
          }
          else {
            let incoming = message.message.invite;
            inviteform.reset();
            inviteform.patchValue(incoming);
            this.tools.gracefulError(message.message.message.emailresult.message)
          }
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
      //console.log(message);
    })
  }
  resendInvite() {
    this.data.resendInvitation(this.invitation.value).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.tools.snackMessage("Invitation resent");
      }
      else{
        this.tools.gracefulError(message.message);
      }
    })
  }
  deleteInvite() {
    let invitation = this.invitation.value;
    invitation.recordStatus = 3;
    this.data.updateInvitation(invitation).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.deleted = true;
        this.parentForm.controls.splice(this.index,1);

      }
      else {
        this.tools.gracefulError(message.message);
      }
    })
  }

}
