import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { QuotationsComponent } from './pages/quotations/quotations.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { HomeComponent } from './pages/home/home.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { QuoteHistoryComponent } from './pages/quote-history/quote-history.component';

const routes: Routes = [{ path: 'dash', component: DashboardComponent },{ path: 'quote', component: QuotationsComponent },{ path: 'settings', component: SettingsComponent },{ path: 'settings/:tab', component: SettingsComponent },{ path: 'issues', component: IssuesComponent },{ path: 'roadmap', component: RoadmapComponent },{ path: 'tools', component: ToolsComponent },{ path: 'home', component: HomeComponent },{ path: 'quote-history', component: QuoteHistoryComponent },{ path: '**', component: HomeComponent },{ path: '', component: HomeComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
