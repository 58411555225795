import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ServicePort } from 'src/app/models/data';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-last-mile',
  templateUrl: './last-mile.component.html',
  styleUrls: ['./last-mile.component.scss']
})
export class LastMileComponent implements OnInit {

  @Input() ports:ServicePort[];
  public selectedPort:ServicePort;
  @Input() title:string;
  
  constructor() { }

  ngOnInit(): void {
    let ob = this.ports;
    if(this.ports && this.ports.length>0){
      this.selectedPort = this.ports[0];
      
    } 
  }

}
