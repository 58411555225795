<div class="mt fl aic">
    <div class="f1">
        <h3>List Price Management</h3>
    </div>
    <div class="f1">
        <button class="mr" type="button" mat-mini-fab aria-label="Save Rate" color="primary"
            title="Save Rate" (click)="saveRate()"
            [disabled]="!marginsForm || !marginsForm.valid || marginsForm.pristine">
            <mat-icon>save</mat-icon>
        </button>
    </div>
    <div class="mr">
        <mat-slide-toggle color="primary" [(ngModel)]="allproviders" ngDefaultControl (change)="setProvider()">
            <span>All Providers</span>
        </mat-slide-toggle>
    </div>
    <div>
        <cs-company-search *ngIf="!allproviders" [host]="'provider-margins'"></cs-company-search>
    </div>
</div>

<div class="fl fw">
    <div class="f1">
        <div *ngIf="marginArray">
            <div class="margin-section" *ngFor="let margingroup of marginArray.controls">
                <cs-margin [margingroup]="margingroup" [currencies]="data.currencies">
                </cs-margin>
            </div>
        </div>
    </div>
    <div class="f1">
        <div *ngIf="surchargeArray">
            <div class="surcharge-section" *ngFor="let surchargegroup of surchargeArray.controls">
                <div [formGroup]="surchargegroup">
                    <h3>{{surchargegroup.get("title").value}}</h3>
                    <div *ngFor="let group of surchargegroup.get('marginArray').controls">
                        <cs-margin [margingroup]="group" [currencies]="data.currencies">
                        </cs-margin>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
