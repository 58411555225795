import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Rate, TransportModes, Region, ImportObject, RateBreak } from 'src/app/models/rates';
import { ToolsService } from 'src/app/services/tools.service';
import { Priority } from 'src/app/models/ui';
import { EventsService } from 'src/app/services/events.service';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-rate-importer',
  templateUrl: './rate-importer.component.html',
  styleUrls: ['./rate-importer.component.scss']
})
export class RateImporterComponent implements OnInit {


  @Input() region: Region;
  @Input() priority: Priority;
  @Input() transportmode: TransportModes;
  @ViewChild('csvReader') csvReader: any;

  public globalbreakincludes = true;
  public globalpriceperbreak = true;

  public records: ImportObject[] = [];
  public headerRow: string[] = [];

  public zonerates: Rate[] = [];//what to attach it to?
  public zoneSimpleRates: Rate[] = [];

  public tolerance = "0";
  public selectedZone: Region;
  public selectedZoneIndex = 0;

  public currencyCode = "GBP";

  public simplified=false;
  public unsimplified=true
  
  public volumeequivalent = 1.666;
  public envelopename = "New Rate";


  constructor(public tools: ToolsService,public data:DataService, public events: EventsService) { }

  ngOnInit(): void {
    if (this.region.children && this.region.children.length > 0) {
      this.selectedZone = this.region.children[0];
    }
    this.region.children.forEach(child => {
      let rate = new Rate(child.id, this.priority.id);
      rate.parentregionid = this.region.id;
      rate.createdAt = new Date();
      this.zonerates.push(rate);
      let simpleRate = new Rate(child.id, this.priority.id);
      simpleRate.parentregionid = this.region.id;
      simpleRate.createdAt = new Date();
      this.zoneSimpleRates.push(simpleRate);
    })

  }

  uploadListener($event: any): void {

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.headerRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
        let index = 1;
        this.region.children.forEach(child => {
          this.breaksAsIs(index, child.id);
          this.breaksSimplified(index, child.id, 0.01);
          index++;
        })



      };

      reader.onerror = () => {
        this.tools.gracefulError("Error reading csv file.");
      };

    } else {
      this.tools.gracefulError("Valid .csv files only please.");
      this.fileReset();
    }
  }

  /*
if (j == 1) {
              if (currentRecord[j].indexOf('then') >= 0) {
                //handle then
                importObject.isthen = true;
                importObject.priceperbreak = false;
                importObject[this.headerRow[j]] = "then";
              }
              else importObject[this.headerRow[j]] = this.tools.cleanFloat(currentRecord[j]);
            }
            else {
              importObject[this.headerRow[j]] = this.tools.cleanFloat(currentRecord[j]);
            }
  */
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let csvArr = [];
    let previous = 0;
    let running = 0;
    for (let i = 1; i < csvRecordsArray.length; i++) {

      let currentRecord = (<string>csvRecordsArray[i]).split(',');
      if(currentRecord.length>2){//an empty row will still provide a record.
        let importObject: ImportObject = { min: 0, max: 0, breakbarrierincludes: true, priceperbreak: true, isthen: false, runningtotal: running };
        this.setRange(importObject, currentRecord[0], previous);
        previous = importObject.max;
        for (let j = 1; j < this.headerRow.length; j++) {
          if (currentRecord[j]) {
            importObject[this.headerRow[j]] = this.tools.cleanFloat(currentRecord[j]);
          }
          else {
            importObject[this.headerRow[j]] = 0;
          }
          if (i == 1) {
            this.startRateCalc(j - 1, importObject);
          }
  
        }
        running = importObject[this.headerRow[1]];
        csvArr.push(importObject);
      }
    }

    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  /**
   * form a string array of table headers
   * @param csvRecordsArr 
   */
  getHeaderArray(csvRecordsArr: any): string[] {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      if(j>0 && this.region.children.length>=j){
        headerArray.push(this.region.children[j-1].name);
      }
      else headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  changeGlobalPer() {
    this.records.forEach(rec => {
      rec.priceperbreak = this.globalpriceperbreak;
    })
    this.zonerates.forEach(zr => {
      zr.priceperbreak = this.globalpriceperbreak;
      zr.RateBreaks.forEach(zrb => {
        zrb.priceperbreak = this.globalpriceperbreak;
      })
    })
    this.zoneSimpleRates.forEach(zr => {
      zr.priceperbreak = this.globalpriceperbreak;
      zr.RateBreaks.forEach(zrb => {
        zrb.priceperbreak = this.globalpriceperbreak;
      })
    })
  }
  changeGlobalMin() {
    this.records.forEach(rec => {
      rec.breakbarrierincludes = this.globalbreakincludes;
    })
    this.zonerates.forEach(zr => {
      zr.breakbarrierincludes = this.globalbreakincludes;
      zr.RateBreaks.forEach(zrb => {
        zrb.breakbarrierincludes = this.globalbreakincludes;
      })
    })
    this.zoneSimpleRates.forEach(zr => {
      zr.breakbarrierincludes = this.globalbreakincludes;
      zr.RateBreaks.forEach(zrb => {
        zrb.breakbarrierincludes = this.globalbreakincludes;
      })
    })
  }
  changeZone(e) {
    this.selectedZone = e.value;
    let index = 0;
    this.region.children.some(ch => {
      if (ch.id == this.selectedZone.id) {
        this.selectedZoneIndex = index;
        return true;
      }
      index++;
    })
  }
  chooseSimplify(simplify:boolean){
    this.simplified = simplify;
    this.unsimplified = !this.simplified;
  }
  setRange(importObject: ImportObject, cell: string, previous: number) {
    cell = cell.trim();
    if (cell.indexOf('then') == 0) {
      let params = cell.split(' ');
      if (params[0]) {
        importObject.text = params[0];
      }
      if (params[1]) {
        importObject.max = this.tools.cleanFloat(params[1]);
      }
      if (params[2]) {
        importObject.each = this.tools.cleanFloat(params[2]);
      }
      importObject.min = previous;
      importObject.isthen = true;
      importObject.priceperbreak = false;
    }
    else {
      if (cell.indexOf('-') >= 0) {
        let range = cell.split('-');
        if (range.length == 2) {
          importObject.min = parseFloat(range[0]);
          importObject.max = parseFloat(range[1]);
        }
      }
      else {
        importObject.min = previous;
        importObject.max = parseFloat(cell);
      }
    }

    return importObject;
  }

  startRateCalc(index: number, importObject: ImportObject) {
    this.zonerates[index].baseIncludesWeight = importObject.max;
    this.zonerates[index].baseprice = importObject[this.headerRow[index + 1]];

    this.zoneSimpleRates[index].baseIncludesWeight = importObject.max;
    this.zoneSimpleRates[index].baseprice = importObject[this.headerRow[index + 1]];
  }

  changeTolerance(e) {
    console.log(e);
  }

  breaksAsIs(index: number, id: number) {
    let rate = this.zonerates[index - 1];
    let running = rate.baseprice;
    for (let i = 1; i < this.records.length; i++) {
      let rec = this.records[i];
      let ratebreak = new RateBreak();
      ratebreak.minweight = rec.min;
      ratebreak.maxweight = rec.max;
      ratebreak.weightbreak = rec.max - rec.min;
      ratebreak.price = this.tools.cleanRound(rec[this.headerRow[index]], 2);
      ratebreak.priceperbreak = rate.priceperbreak;
      ratebreak.breakbarrierincludes = rate.breakbarrierincludes;
      if (rec.isthen) {
        ratebreak.fixed = running;
        ratebreak.priceperbreak = false;
        ratebreak.weightbreak = rec.each;
      }
      running = ratebreak.price;
      rate.RateBreaks.push(ratebreak);
    }
    //this.region.children[index-1].Rate = rate;
  }
  breaksSimplified(index: number, id: number, tolerance: number) {

    let ratebreak: RateBreak;
    let rate = this.zoneSimpleRates[index - 1];
    let running = rate.baseprice;
    let currentaverage = 0;//rate.baseIncludesWeight==0?0:this.tools.cleanRound(rate.baseprice/rate.baseIncludesWeight,2);  
    let recordsinrate = 0; //keep a track of the number of records making up a rate break so we can calculate average more precisely

    for (let i = 1; i < this.records.length; i++) {
      let rec = this.records[i];
      let ppk = this.tools.cleanRound((rec[this.headerRow[index]] - running) / (rec.max - rec.min), 2);
      if (recordsinrate == 0) currentaverage = ppk;
      else {
        let averagetotal = currentaverage * recordsinrate;
        averagetotal += ppk;
        currentaverage = this.tools.cleanRound(averagetotal / (recordsinrate + 1), 2);
      }
      let abs = this.tools.cleanRound(Math.abs(ppk - currentaverage), 2);
      if (rate.RateBreaks.length == 0 || abs > tolerance || rec.isthen) {
        recordsinrate = 1;
        ratebreak = new RateBreak();
        ratebreak.priceperbreak = rate.priceperbreak;
        ratebreak.breakbarrierincludes = rate.breakbarrierincludes;
        ratebreak.minweight = rec.min;
        ratebreak.maxweight = rec.max;
        ratebreak.weightbreak = rec.max - rec.min;
        if (rec.isthen) {
          ratebreak.fixed = running;
          ratebreak.priceperbreak = false;
          ratebreak.weightbreak = rec.each;
        }
        rate.RateBreaks.push(ratebreak);
      }
      else {
        recordsinrate++;
        ratebreak.maxweight = rec.max;
      }

      ratebreak.price = rec.isthen ? rec[this.headerRow[index]] : this.tools.cleanRound(currentaverage, 2);
      running = rec[this.headerRow[index]];
    }

  }

  saveImportedRate() {
    let ratearray:Rate[];
    if(this.simplified){
      ratearray = this.zoneSimpleRates;
    }
    else ratearray = this.zonerates;
    ratearray.forEach(r=>{
      r.volumeequivalent = this.volumeequivalent;
    })
    this.data.createRates(ratearray,this.region.id,this.envelopename).then((message:StatusMessage)=>{
      if(message.success){
        if(message.message.errors && message.message.errors>0){
          this.tools.gracefulError(message.message.errors+" errors occurred in the creation of your rates");
          this.concludeRateSave(message.message.ratearray);
        }
        else{
          this.concludeRateSave(message.message.ratearray);
        }
      }
      else{
        this.tools.gracefulError("Rate creation failed");
      }
    })
  }
  concludeRateSave(ratearray:Rate[]){
    console.log(ratearray);
    this.events.rateCreation.emit(ratearray);
  }
  cancelImport() {
    this.events.cancelRateImport.emit();
  }
}
