<h3>Global Distance Calculator</h3>
<div class="fl pr">
    <div>
    <mat-spinner  *ngIf="isLoading" diameter="32"></mat-spinner>
    <div class="fl">
        <div class="f1 mr">
            <mat-form-field>
                <mat-label>Origin</mat-label>
                <input matInput type="text" placeholder="Origin" [(ngModel)]="origin">
            </mat-form-field>
        </div>
        <div class="f1">
            <mat-form-field>
                <mat-label>Destination</mat-label>
                <input matInput type="text" placeholder="Destination" [(ngModel)]="destination">
            </mat-form-field>
        </div>
        <div class="fl aic fwn neg-form-margin">
            <button [disabled]="!formvalid" type="button" mat-mini-fab aria-label="calculate" color="primary" title="Calculate Distance">
                <mat-icon (click)="calculate()">near_me</mat-icon>
            </button>
            
        </div>
    </div>
    <div class="results" *ngIf="geoResult">
        <div>
            <div>{{geoResult.geoOrigin.formatted_address}} - Lat: {{geoResult.geoOrigin.geometry.location.lat()}} Lng: {{geoResult.geoOrigin.geometry.location.lng()}}</div>
        </div>
        <div>
            <div>{{geoResult.geoDest.formatted_address}} - Lat: {{geoResult.geoDest.geometry.location.lat()}} Lng: {{geoResult.geoDest.geometry.location.lng()}}</div>
        </div>
        <div>Distance: {{geoResult.haversine | number}}km</div>
    </div>
    </div>

</div>
