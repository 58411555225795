<div class="fl">
    <div class="f1 fl">
        <div class="f1 mr">
            <h3>Address</h3>
            <cs-address-view *ngIf="site.Address" [address]="site.Address"></cs-address-view>
        </div>
        <div class="f1 mr" *ngIf="site.Address">
            <h3>Geo-location</h3>
            <p class="address-line" *ngIf="site.Address.gpslat">GPS Lat:
                {{site.Address.gpslat}}</p>
            <p class="address-line" *ngIf="site.Address.gpslong">GPS Long:
                {{site.Address.gpslong}}</p>
            <p class="address-line" *ngIf="site.Address.mapzoom">Map Zoom:
                {{site.Address.mapzoom}}</p>
            <p class="address-line" *ngIf="site.Address.what3words && site.Address.what3words.length>0">w3w:
                {{site.Address.what3words}}</p>
        </div>
        <div class="f1 mr">
            <h3>Useful Info</h3>
            <p>Currency: {{site.Address.Country?site.Address.Country.currencySymbol:''}}</p>
            <p>Exchange Rate:</p>
            <p>Local Time: </p>
        </div>

    </div>
    <div class="mr">
        <h3>Contacts</h3>
        <table>
            <tr *ngFor="let user of site.Users">
                <td>{{user.firstname}} {{user.surname}}</td>
                <td>{{user.email}}</td>
            </tr>
        </table>
    </div>
    <div class="f1 mr" *ngIf="importsurcharges.length>0">
        <h3>Import Surcharges</h3>
        <cs-surcharge-view [surcharges]="importsurcharges"></cs-surcharge-view>
    </div>
    <div class="f1" *ngIf="exportsurcharges.length>0">
        <h3>Export Surcharges</h3>
        <cs-surcharge-view [surcharges]="exportsurcharges"></cs-surcharge-view>
    </div>
</div>