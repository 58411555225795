import { Directive, HostListener, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[csInputCurrency]'
})
export class InputCurrencyDirective implements OnDestroy {

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat('en-GB',{maximumFractionDigits:2});
  }

  private formatter:Intl.NumberFormat;
  private destroy$ = new Subject();

  ngAfterViewInit(){
    this.setValue(this.formatPrice(this.ngControl.value));
    this.ngControl
      .control
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updateValue.bind(this));
  }

  updateValue(value:string){
    let inputVal = (value || "").toString();
    this.setValue(!!inputVal ? this.validateDecimalValue(inputVal.replace(/[^0-9.]/g,"")):"");
  }
  @HostListener('focus') onFocus(){
    this.setValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur') onBlur(){
    let value = this.ngControl.value || "";
    !!value && this.setValue(this.formatPrice(value));
  }

  formatPrice(v){
    return this.formatter.format(v);
  }
  unformatValue(v){
    return v.replace(/,/g,"");
  }
  validateDecimalValue(v){
    if(Number.isNaN(Number(v))){
      const stripped = v.slice(0,v.lenth-1);
      return Number.isNaN(Number(stripped))?"":stripped;
    }
    return v;
  }
  setValue(v){
    this.ngControl.control.setValue(v,{emitEvent:false});
  }
  ngOnDestroy(){
    this.setValue(this.unformatValue(this.ngControl.value));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
