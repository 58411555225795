import { AnObject, Country, PortToPort } from './models';
import { Company, Site, Address } from './user';
import { Locode, Service, Vessel } from './data';
import { CarbonBand, CurrencyView, TransportHubTypes } from './ui';

export interface ImportObject{
    [key:string]:any;
    min:number;
    max:number;
    breakbarrierincludes:boolean;
    priceperbreak:boolean;
    isthen:boolean;
    text?:string;
    each?:number;
    runningtotal:number;
}

export class RegionType extends AnObject{
    name:string;
    format:string;
}
export class GPS extends AnObject{
    lat:number;
    long:number;
    what3words:string;
    Polygons:Polygon[];
    constructor(){
        super();
        this.Polygons = [];
        this.lat = 0;
        this.long = 0;
    }
}

export class Feature extends AnObject{
    name:string;
    Polygons:Polygon[];
    FeaturePolygons:FeaturePolygon[];
    constructor(){
        super();
        this.Polygons = [];
        this.FeaturePolygons = [];
    }
}
export class FeaturePolygon extends AnObject{
    featureid:number;
    polygonid:number;
    Polygon:Polygon;
}
export enum MergeDirection{
    BottomToTop,LefToRight,TopToBottom,RightToLeft
}
export enum CornerIndices{
    NorthEast,SouthEast,SouthWest,NorthWest
}
export interface BoundsCorners{
    ne:google.maps.LatLng;
    se:google.maps.LatLng;
    sw:google.maps.LatLng;
    nw:google.maps.LatLng;
    centre:google.maps.LatLng;
}
/**whether the google.maps.api path is defined clockwise or anti-clockwise
 * intended to assist with joining two polygons.
 * Future Implementation
 */
export enum PathDirection{
    Clockwise,CounterClockwise
}
/**a helper object for google.maps.api providing details of a polygon
 * intended to assist with joining two polygons.
 * Future Implementation
 */
export interface PathInfo{
    path:google.maps.MVCArray<google.maps.LatLng>;
    centre:google.maps.LatLng;
    cornerindices:number[];
    isClockwise:boolean;
    length:number;
    startindex:number;
    endindex:number;
  }

/**a collection of polygon points mapping a region*/
export class Polygon extends AnObject{
    gpsid:number;
    featureid:number;
    major:boolean;
    PolygonPoints:PolygonPoint[];
    GPS:GPS;
    _fake:boolean;
    constructor(){
        super();
        this.PolygonPoints = [];
    }
}
/**a point on a FeaturePolygon */
export class PolygonPoint extends AnObject{
    polygonid:number;
    lat:number;
    long:number;
    _isDirty:boolean;
    constructor(){
        super();
    }
}
export class Region extends AnObject{
    companyid:number;
    basesiteid?:number;
    countryid?:number;
    continentid?:number;
    code:string;
    name:string;
    regiontypeid:number;
    gpscentreid:number;
    zoom:number;
    featureid:number;
    parentId?:number;
    hierarchyLevel?:number;
    children:Region[];
    sequence:number;
    drivingdistance:boolean;
    zonetype:ZoneType;
    googlename:string;
    baseregionid:number;

    _folderOpen:boolean;//move to regiondisplayinfo
    _selected:boolean;//move to regiondisplayinfo
    _distance:number;
    _canAdd:boolean = false;
    _canAddMap:boolean = false;
    _canAddDistance:boolean = false;

    RegionDisplayInfo:RegionDisplayInfo;

    GPS:GPS;
    RegionType:RegionType;
    Feature:Feature;
    Country:Country;
    Rate:Rate;
    BaseSite:Site;
    RegionDistancer:RegionDistancer;
    Rates:Rate[]=[];

    constructor(companyid:number,zonetype:ZoneType){
        super();
        this.children = [];
        this.companyid = companyid;
        this.zonetype = zonetype;
    } 
}
export class PriceBand{
    regionid:number;
    parentid:number;
    currencyid:number;
    name:string;
    Rates:Rate[];
    Children:PriceBand[];
    Currency:Country;
    _folderOpen:boolean;
    constructor(){
        this.Rates = [];
        this.Children = [];
        this._folderOpen = false;
    }
}
export class RegionDistancer extends AnObject{
    regionid:number;
    minkm:number;
    minmin:number;
    maxkm:number;
    maxmin:number;
    derives:number;
    morethan:boolean;
    constructor(){
        super();
    }
}

export class RegionDisplayInfo{
    folderOpen:boolean;
    selected:boolean;
    index:number;
    depth:number;
    colour:chroma.Color;
    stroke:chroma.Color;
    constructor(index:number,depth:number,colour?:chroma.Color,stroke?:chroma.Color){
        this.folderOpen = false;
        this.selected = false;
        this.index = index;
        this.depth = depth;
        if(colour) this.colour = colour;
        if(stroke) this.stroke = stroke;
    }
}

export class RegionGroupType extends AnObject{
    parentregionid:number;
    description:string;
    zonetype:ZoneType;
    RegionGroups:RegionGroup[];
    constructor(){
        super();
        this.RegionGroups = [];
    }
}

export class RegionGroup extends AnObject{
    regiongrouptypeid:number;
    featureid:number;
    description:string;

    RegionGroupChildren:RegionGroupChild[];
    constructor(){
        super();
        this.RegionGroupChildren = [];
    }
}

export class RegionGroupChild extends AnObject{
    regiongroupid:number;
    regionid:number;
    featureid:number;

    Region:Region;
    Feature:Feature;
    constructor(){
        super();
    }
}

export enum RegionTypes{
    International =1,Continental,National,State,Regional,Local,PostCode,Group,Excluded
}
/**defines how to group a region's children */
export enum ZoneType{
    International = 1,Continental,RegionalCountryGroup,PoliticalCountryGroup,DistanceCountryGroup,CustomCountryGroup,National,TerritoryGroup,NationalRegionGroup,NationalCountyGroup,NationalOutcodeGroup,NationalDistanceGroup,NationalCustomGroup,NationalRegion,NationalTerritory,NationalCounty,NationalOutcode,IncodeGroup,Incode,DrivingDistance
}
/**an envelope to hold the collection of region rates (there may be more than one for each region/transportmode/priority) */
export class RateEnvelope extends AnObject{
    regionid:number;
    name:string;
    version:number;
    Rates:Rate[];
    constructor(regionid:number){
        super();
        this.regionid = regionid;
        this.Rates=[];
    }
}

export class RateBreak extends AnObject{
    rateid:number;
    breakbarrierincludes:boolean;
    minweight:number;
    minvolume:number;
    weightbreak:number;
    volumebreak:number;
    maxweight:number;
    maxvolume:number;
    price:number;
    fixed:number;
    priceperbreak:boolean;
    _fixedmargin:number;
    _percentmargin:number;
    _listprice:number;
    _totalmargin:number;

    RateBreakPremiums:RateBreakPremium[];

    constructor(){
        super();
        this.RateBreakPremiums = [];
        this.breakbarrierincludes = true;
        this.minweight = 0;
        this.minvolume = 0;
        this.weightbreak = 0;
        this.volumebreak = 0;
        this.maxweight = 0;
        this.maxvolume = 0;
        this.price = 0;
        this.priceperbreak = false;
        this._totalmargin = 0;
        this._percentmargin = 0;
        this._listprice = 0;
        this._totalmargin = 0;
    }

}
export class RateBreakPremium extends AnObject{
    customerid:number;
    ratebreakid:number;
    pricepremium:number;
    constructor(){
        super();
    }
}

export class RatePriority extends AnObject{
    name:string;
    companyid:number;
    regionlevel:number;
    customerid:number;
    daysplus:number;
    bytime:string;
    specificday:number;
    parentid:number;//if we set up a company rate priority, use this to indicate there is a customer specific version of it
    sequence:number;

    Bands: PriceBand[];
    Rates:Rate[];
    IsPopped:boolean;
    constructor(){
        super();
    }
}


export class RateBracket extends AnObject{
    name:string;
    minweight:number;
    maxweight:number;
    minx:number;
    maxx:number;
    miny:number;
    maxy:number;
    minz:number;
    maxz:number;
    maxgirth:number;
    providerid:number;
    customerid:number;
    parentid:number;//if we set up a company rate bracket, use this to indicate there is a customer specific version of it
    sequence:number;
    constructor(){
        super();
    }
}

export class CustomerRate extends AnObject{
    rateid:number;
    customerid:number;
    expires:Date;

    Customer:Company;
    constructor(){
        super();
    }

}
export enum TransportModes{
    Road,Shipping,Air,Rail,Local
}
export enum PointModes{
    Shipping=1,Air,Rail
}
export enum SurchargeAppliesTo{
    Road,Shipping,Air,Rail,Local,RDC,PortIn,PortOut,AirportIn,AirportOut,RailCC,Site,Company,Rate
}

export class Rate extends AnObject{
    name:string;
    description:string;
    transportmode:TransportModes;//the transport mode
    siteid?:number;//the site the rate applies to - needed for consolidation centres etc.
    serviceid?:number;//where a rate is point to point we can hook it up here.
    baseprice:number;//the fixed element
    currency:number;//country code for the currency
    breakbarrierincludes:boolean;
    priceperbreak:boolean;
    baseIncludesWeight:number;//the weight included in the fixed price  
    baseIncludesVolume:number;//the volume included in the fixed price
    regionid:number;//the zone to which this rate applies
    parentregionid:number//the parent region - makes envelope retrieval a lot easier
    envelopeid:number;//an envelope to hold the collection of region rates (there may be more than one for each region/transportmode/priority)
    priority:number;//standard, express etc
    volumeequivalent:number;//ratio of volume to weight
    providerid:number;//hook to owner
    customerid:number;//hook to customer specific - 0 if public
    //ratebracketid:number;
    ratebrackettype:number; //optional parameter for size of shipment, package, pallet FCL etc
    minimum:number;
    expires:Date;
    durationmin:number;
    durationmax:number;
    createdAt:Date;
    _isDirty:boolean;

    _percentmargin:number;
    _fixedmargin:number;
    _list:number;
    _totalmargin:number;

    RateBreaks:RateBreak[];
    Company:Company;
    CustomerRates:CustomerRate[];
    Region:Region;
    BandPremia:BandPremium[];
    RateCardOverrides:RateCardOverride[];
    RateBracket:RateBracket;
    Currency:Country;

    constructor(regionid:number,priority:number,transportmode?:TransportModes){
        super();
        this.baseprice = 0;
        this.regionid = regionid;
        this.priority = priority;
        this.transportmode = transportmode || TransportModes.Road;
        this.baseIncludesWeight = 0;
        this.baseIncludesVolume = 0;
        this.priceperbreak = true;
        this.breakbarrierincludes = true;
        let end = new Date();
        end.setMonth(end.getMonth()+3);
        this.expires = end;  
        this.RateBreaks = [];
        this.CustomerRates = [];
        this.BandPremia = [];
        this.RateCardOverrides=[];
        this._fixedmargin = 0;
        this._percentmargin = 0;
        this._list = 0;
        this._totalmargin = 0;
    }

}

export class RateSite extends AnObject{
    rateid:number;
    siteid:number;
    origin:boolean;
    constructor(){
        super();
    }
}
export class RateMap extends AnObject{
    pastedname:string;
    regionname:string;
}
export class RatePremium {
    rateid:number;
    customerid:number;
    basepremium:number;
    baseIncludesWeightPremium:number;
    baseIncludesVolumePremium:number;
    allRateBreaksPremium:number;
    
}

export class BandPremium extends AnObject{
    rateid:number;
    regionid:number;
    premiumpercent:number;
    premiumvalue:number;

    _regioncode:string;

    constructor(){
        super();
        this.premiumpercent = 1;
        this.premiumvalue = 0;
    }

}

export class RateCardOverride extends AnObject{
    rateid:number;
    regionid:number;
    weightmin:number;
    weightmax:number;
    index:number;
    adjustment:number;
    _clean:number;
    constructor(){
        super();
    }

}

export class RateBreakEntry{
    weight:number;
    volume:number;
    price:number;
}


export class Size extends AnObject{
    width:number;
    height:number;
    depth:number;
    quantity:number;
    ratebracket:number;
    weight:number;
    stackable:boolean;

    RateBracket:RateBracket;
    
    constructor(){
        super();
        this.width = 100;
        this.height = 100;
        this.depth = 100;
        this.quantity = 1;
        this.weight = 200;
        this.stackable = true;
    }
/*
    get area(){
        if(!this.width || !this.depth) return 0;
        else return this.width * this.depth;
    }
    get volume(){
        if(!this.width || !this.depth || !this.height) return 0;
        else return this.width * this.depth * this.height;
    }*/
}

export interface ConsolidationCentre{
    name:string;
    siteid:number;
    providerid:number;
}

export class  QuotationPrice{
    id:number;
    appliesto:number;
    tableid:number;
    description:string;
    costprice:number;
    preexchange:number;
    exchanged:string;
    listprice:number;
    discountedprice:number;
    margin:number;
    include:boolean;


    constructor(){
        this.costprice = 0;
        this.listprice = 0;
        this.discountedprice = 0;
        this.margin = 0;
        this.description = "";
        this.include = true;
    }
}

export class PortSurcharge extends AnObject{
    proverid:number;
    locodeid:number;
    surchargetypeid:number;
    surchargeFixed:number;
    surchargePercent:number;
    SurchargeType:SurchargeType;
    expires:Date;

    CustomerSurcharges:CustomerSurcharge[];
}
export enum PriceAppliesTo{
    Shipment = 1,Weight,Volume
}
export class SurchargeType extends AnObject{
    name:string;
    description:string;
    appliesto:number;
    providerid:number;  
}
export class Surcharge extends AnObject{
    rateid:number;
    companyid:number;
    surchargetypeid:number;
    surchargeMin:number;
    surchargeFixed:number;
    surchargePercent:number;
    serviceid:number;
    siteid:number;
    providerid:number;
    perunit:number;
    howmanyunit:number;
    volumeequivalent:number;
    unit:number;
    portid:number;
    currencyid:number;
    optional:boolean;
    customerid:number;
    SurchargeType:SurchargeType;
    expires:Date;
    starts:Date;
    ratebracket:number;
    _isDirty:boolean;

    _currencyView:CurrencyView;

    CustomerSurcharges:CustomerSurcharge[];
}

export enum SurchargeRelatesTo{
    Shipment = 1,kg,m3,Item
}

export class CustomerSurcharge extends AnObject{
    surchargeid:number;
    customerid:number;
    expires:Date;

    Customer:Company;
}

export class SurchargePremium extends AnObject{
    surchargeid:number;
    companyid:number;
    fixedPremium:number;
    percentPremium:number;
    expires:Date;
}

export class Margin extends AnObject{
    companyid:number;
    providerid:number;
    fixed:number;
    currency:number;
    percent:number;
    start:Date;
    expires:Date;
    appliesto:MarginAppliesTo;
    surchargetype:number;

    surchargegroupindex:number;//holder for the margin's position in the surcharge group form. Needed to update the id for further changes
    placeinarray:number;
    
    constructor(appliesto:MarginAppliesTo,currency:number,companyid:number){
        super();
        this.appliesto = appliesto;
        this.currency = currency;
        this.companyid = companyid;
        
        this.fixed = 0;
        this.percent = 0;
        this.start = new Date();
        let end = new Date(this.start);
        end.setFullYear(this.start.getFullYear()+1);
        this.expires = end;

    }
}
export class Discount extends AnObject{
    companyid:number;
    customerid:number;
    fixed:number;
    currency:number;
    percent:number;
    start:Date;
    expires:Date;
    appliesto:MarginAppliesTo;
    surchargetype:number;

    placeinarray:number;//when creating a new discount, need to know where to update the form array
    
    constructor(appliesto:MarginAppliesTo,currency:number,companyid:number){
        super();
        this.appliesto = appliesto;
        this.currency = currency;
        this.companyid = companyid;
        
        this.fixed = 0;
        this.percent = 0;
        this.start = new Date();
        let fornextyear = new Date();
        let end = fornextyear;
        end.setFullYear(this.start.getFullYear()+1);
        this.expires = end;

    }
}


export enum MarginAppliesTo{
    Global, Service_Charges, Delivery_Rates,Collection_Rates,Surcharges
}

export class MinimumMargin extends AnObject{
    companyid:number;
    customerid:number;
    fixed:number;
    percent:number;
    currency:number;
    start:Date;
    expires:Date;
    constructor(companyid:number,customerid:number){
        super();
        this.fixed = 0;
        this.percent = 0;
        this.companyid = companyid;
        this.customerid = customerid;
        this.start = new Date();
        let end = this.start;
        end.setFullYear(this.start.getFullYear()+1);
        this.expires = end;

    }
}

export class SavedQuote extends AnObject{
    guid:string;
    description:string;
    companyid:number;
    customerid:number;
    userid:number;
    expires:Date;
    currency:number;
    quotestatus:QuoteStatus;
    selectedoptionid:number;
    pointtopoint:boolean;
    
    Customer:Company;
    QuoteInput:SavedQuoteInput;
    ServiceOptions:SavedQuoteServiceOption[];

    SelectedOption:SavedQuoteServiceOption;

    constructor(){
        super();
        this.ServiceOptions = [];
        this.QuoteInput = new SavedQuoteInput();
    }
    
}

export class SavedQuoteServiceOption extends AnObject{
    savedquoteid:number;
    destinationid:number;
    destinationsiteindex:number;
    mode:TransportModes;
    originid:number;
    originsiteindex:number;
    porttoportid:number;
    providerid:number;
    serviceid:number;
    vesselid:number;

    listprice:number;
    costprice:number;
    discountedprice:number;
    margin:number;

    duration:number;
    distance:number;
    co2:number;

    selected:boolean;
    greenest:boolean;
    fastest:boolean;
    cheapest:boolean;
    cheapestfull:boolean;

    Origin:Locode;
    Destination:Locode;
    PortToPort:PortToPort;
    Service:Service;
    Vessel:Vessel;
    Provider:Company;
    //QuoteLines:SavedQuoteLine[];
    OriginSites:SavedQuoteDestinationSite[];
    DestinationSites:SavedQuoteDestinationSite[];
    QuoteSurcharges:SavedQuoteLine[];
    ServiceRates:SavedQuoteLine[];
    ServiceSurcharges:SavedQuoteLine[];
    Steps:ModeMetric[];
    PointToPoints:SavedQuotePointToPoint[];
    

    constructor(){
        super();
        this.duration = 0;
        this.distance = 0;
        this.co2 = 0;
    }
}

export class SavedQuoteDestinationSite extends AnObject{
    serviceoptionid:number;
    isorigin:boolean;
    include:boolean;
    hascollection:boolean;
    hassurcharges:boolean;
    rateregionid:number;
    selectedpriority:number;
    siteid:number;
    totalcollection:number;//just a number for displaying on the tabs

    selected:boolean;

    Priorities:SavedQuotePriority[];
    RateRegion:Region;
    Site:Site;
    Surcharges:SavedQuoteLine[];

}

export class SavedQuotePriority extends AnObject{
    quotedestinationsiteid:number;
    quoteoptionid:number;
    priorityid:number;
    costprice:number;
    listprice:number;
    discountedprice:number;
    margin:number;
    preexchanged:number;
    exhanged:string;
    include:boolean;

    selected:boolean;
    
    Priority:RatePriority;
}
export class SavedQuotePointToPoint extends AnObject{
    savedquoteid:number;
    name:string;
    expires:Date;
    duration:number;
    costprice:number;
    listprice:number;
    discountedprice:number;
    preexchanged:number;
    exchanged:string;
    margin:number;
    currency:number;
    costlocal:number;
    currencylocal:number;
    distance:number;
    co2:number;
    priority:number;

    Priority:RatePriority;
    ServiceLegs:SavedQuotePointToPointLeg[];
    Messages:string[];
    Errors:string[]; 
}
export class SavedQuotePointToPointLeg extends AnObject{
    pointotpointid:number;
    name:string;
    providerid:number;
    duration:number;
    mode:number;
    costprice:number;
    listprice:number;
    discountedprice:number;
    preexchanged:number;
    exchanged:string;
    margin:number;
    currency:number;
    priorityid:number;
    distance:number;
    co2:number;

    Priority:RatePriority;
    Messages:string[];
    Errors:string[];
}
export class SavedQuoteInput extends AnObject{
    savedquoteid:number;
    destinationid:number;
    destinationsiteid:number;//only used when a site is set as a destination
    originid:number;
    originsiteid:number;//only used if site is set as an origin

    servicedate:Date;
    expires:Date;

    totalweight:number;
    totalvolume:number;
    totalarea:number;
    totalquantity:number;

    sortarray:number[];

    Items:SavedQuoteSize[];
    Destination:Address;
    DestinationSite:Site;
    Origin:Address;
    OriginSite:Site;
    Options:SavedQuoteOption[];

    constructor(){
        super();
        this.Items = [];
        this.Options=[];
    }
}

export class SavedQuoteOption extends AnObject{
    savedquoteinputid:number;
    optionid:number;
    value:boolean;
}
export class SavedQuoteSize extends AnObject{
    quoteinputid:number;
    weight:number;
    width:number;
    depth:number;
    height:number;
    quantity:number;
    ratebracket:number;
    stackable:boolean;
}

export class SavedQuoteLine extends AnObject{
    savedquoteoptionid:number;
    quotedestinationsiteid:number;
    description:string;
    appliesto:number;
    costprice:number;
    listprice:number;
    discountedprice:number;
    margin:number;
    include:boolean;
    preexchanged:number;
    exchanged:string;
}

export class ModeMetric extends AnObject {
    savedquoteoptionid:number;
    index:number;
    name:string;
    icon: string;
    hubtype:TransportHubTypes;
    mode: TransportModes;
    co2: number;
    co2band:CarbonBand;
    km: number;
    duration: number;
    cost: number;
    gpslat:number;
    gpslong:number;
    ishub:boolean;

    get latlng(){
        return new google.maps.LatLng(this.gpslat,this.gpslong);
    }

    constructor(){
        super();
        this.co2 = 0;
        this.km = 0;
        this.duration = 0;
        this.cost = 0;
    }
}

export class CalculationMessage extends AnObject{
    message:string;
    severity:number;
    appliesto:CalcMessageAppliesTo;
    tableid:number;
}

export enum QuoteLineAppliesTo{
    Quote,Balance,Total,Collection,CollectionSurcharge,CollectionConsolidation,CollectionConsolidationSurcharge,OriginPort,Service,ServiceSurcharge,DestinationPort,DestinationConsolidationSurcharge,DestinationConsolidation,DestinationSurcharge,Destination
}

export enum QuoteStatus{
    Draft,Sent,Acknowledged,UnderConsideration,Booked,InProgress,Completed,Reworked,Rejected
}

export enum CalcMessageAppliesTo{
    Item,Geography,Rate,Surcharge,Service
}

export class QuoteEvent extends AnObject{
    quotestatus:QuoteStatus;
    eventdate:Date;
}