import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cs-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  //@Input() address:Address;
  @Input() showMap = true;
  @Input() showDetail = false;

  @Input() addressForm:FormGroup;

  
  constructor(public data:DataService) { }

  ngOnInit() {
  }

  get houseNameOrNumber() { 
    return this.addressForm.get('houseNameOrNumber');
  }
  get city(){
    return this.addressForm.get('city');
  }
  get countryid(){
    return this.addressForm.get('countryid');
  }
  mapZoom(){
    console.log(this.addressForm.get('mapzoom').value);
  }


}
