<div class="dialog-container {{companyForm && companyForm.dirty?'changes':''}}">
    <form *ngIf="company" [formGroup]="companyForm" (ngSubmit)="submit()">
        <div class="fl fw companyForm.valid?'':'invalid'">
            <div>
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input matInput placeholder="Company Name" formControlName="name">
                </mat-form-field>
            </div>
            <div class="f1 tar">
                <mat-form-field>
                    <mat-label>Company Status</mat-label>
                    <mat-select formControlName="companystatusid">
                        <mat-option *ngFor="let stat of data_service.companyStatuses" [value]="stat.id">
                            {{stat.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Company Type</mat-label>
                    <mat-select formControlName="companytypeid">
                        <mat-option *ngFor="let type of companyTypes" [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div formArrayName="Sites">
            <div class="fl">
                <h3>Sites</h3>
                <div class="f1">
                </div>
                <div>
                    <button type="button" mat-mini-fab aria-label="Add Site" color="primary" title="Add Site"
                    (click)="addSite()">
                    <mat-icon>add</mat-icon>
                </button> 
                </div>
            </div>
            <mat-tab-group #siteTabs color="accent">
                <mat-tab *ngFor="let site of sites.controls;let i = index">
                    <ng-template mat-tab-label>
                        <span class="st{{site.value.sitetypeid}} {{site.valid?'':'invalid'}}">{{site.value.name}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <cs-site [site]="site" [index]="i" [parentForm]="sites"></cs-site>
                    </ng-template>
                </mat-tab>


            </mat-tab-group>
            <div class="close-dialog">
                <!--<button mat-raised-button class="bwip" (click)="logForm()" type="button">Log Form</button>-->
                <button mat-raised-button color="warn" (click)="cancel()" type="button">Cancel</button>
                <button
                    mat-raised-button color="primary" type="submit"
                    [disabled]="!companyForm.dirty || !companyForm.valid">Save</button>
            </div>
        </div>

    </form>

</div>
