<p class="address-line"
*ngIf="address.flatOrSuiteNumber && address.flatOrSuiteNumber.length>0">
{{address.flatOrSuiteNumber}}</p>
<p class="address-line" *ngIf="address.floorNumber">
{{address.floorNumber}}</p>
<p class="address-line">
    <span *ngIf="address.houseNameOrNumber && address.houseNameOrNumber.length>0">{{address.houseNameOrNumber}}</span>
    <span *ngIf="address.street && address.street.length>0"> {{address.street}}</span>
</p>
<p class="address-line" *ngIf="address.town && address.town.length>0">
{{address.town}}</p>
<p class="address-line" *ngIf="address.city && address.city.length>0">
{{address.city}}</p>
<p class="address-line" *ngIf="address.county && address.county.length>0">
{{address.county}}</p>
<p class="address-line" *ngIf="address.state && address.state.length>0">
{{address.state}}</p>
<p class="address-line" *ngIf="address.postcode && address.postcode.length>0">{{address.postcode}}</p>
<p class="address-line" *ngIf="address.Country">{{address.Country.name}}</p>
