import { Component, OnInit, Input } from '@angular/core';
import { Site, SiteTypes, StatusMessage } from 'src/app/models/user';
import { Surcharge, SurchargeAppliesTo } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-site-view',
  templateUrl: './site-view.component.html',
  styleUrls: ['./site-view.component.scss']
})
export class SiteViewComponent implements OnInit {

  @Input() site:Site;
  public SiteTypes = SiteTypes;
  public importsurcharges:Surcharge[]=[];
  public exportsurcharges:Surcharge[]=[];
  
  constructor(public data:DataService) { }

  ngOnInit(): void {
    if(this.site){
      if(this.site.sitetypeid==SiteTypes.AIRPORT || this.site.sitetypeid==SiteTypes.PORT){
        if(this.site.sitetypeid==SiteTypes.AIRPORT){
          this.data.listSurchargesPort(this.site.id,SurchargeAppliesTo.AirportIn).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.importsurcharges = message.message;
          }
        })
        this.data.listSurchargesPort(this.site.id,SurchargeAppliesTo.AirportOut).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.exportsurcharges = message.message;
          }
        })
        }
        if(this.site.sitetypeid==SiteTypes.PORT){
          this.data.listSurchargesPort(this.site.id,SurchargeAppliesTo.PortIn).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.importsurcharges = message.message;
          }
        })
        this.data.listSurchargesPort(this.site.id,SurchargeAppliesTo.PortOut).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.exportsurcharges = message.message;
          }
        })
        }
        
      }
    }
    
  }

}
