<div class="fl mt">
    <h3>Rate Editor - Port to Port</h3>
    <div class="f1 ml">
        <div class="fl aic jc">
            <button class="mr" type="button" mat-mini-fab aria-label="Add Rate" color="primary" title="Add Rate"
                (click)="addRate()" (dblclick)="addDummyRate()"
                [disabled]="!selectedVersion || showimport || showcard || !selectedService">
                <mat-icon>add</mat-icon>
            </button>
             <button class="mr" type="button" mat-mini-fab aria-label="Save Rate" color="primary" title="Save Rate"
                (click)="saveRate()" [disabled]="showimport || showcard || !rate">
                <mat-icon>save</mat-icon>
            </button>
            <button class="mr" *ngIf="rate && rate.id" type="button" mat-mini-fab aria-label="Copy Rate"
                color="primary" title="Copy Rate" (click)="copyRate()" [disabled]="showimport || showcard || !rate">
                <mat-icon>file_copy</mat-icon>
            </button>

            <button class="mr" *ngIf="selectedVersion && selectedMode>=0 && selectedService" type="button" mat-mini-fab
                aria-label="Import Rate" color="primary" title="Import Rates" (click)="showImport()">
                <mat-icon>cloud_upload</mat-icon>
            </button>
            <p class="note" *ngIf="!selectedVersion">Please add regions with banding before creating rates.</p>
        </div>
    </div>
    <mat-form-field>
        <mat-label>Transport Mode</mat-label>
        <mat-select name="selectedmode" [(ngModel)]="selectedMode" ngDefaultControl (selectionChange)="listServices()"
            [disabled]="showimport || showcard"><!--changeMode($event)-->
            <mat-option *ngFor="let type of transportmodes | enumpipe" [value]="type.key">
                {{type.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Version</mat-label>
        <mat-select name="selectedversion" [(ngModel)]="selectedVersion" ngDefaultControl (selectionChange)="listServices()"
            [disabled]="showimport || showcard"><!--changeVersion($event)-->
            <mat-option *ngFor="let region of versions" [value]="region">
                {{region.code}} - {{region.name}} - {{region.BaseSite?region.BaseSite.name:''}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="f1 mt fl ovh" *ngIf="selectedVersion">
    <mat-tab-group (selectedTabChange)="changeZone($event)" [selectedIndex]="zoneindex" ngDefaultControl class="mt" ><!--changeZone($event)-->
        <mat-tab *ngFor="let region of selectedVersion.children;let i = index">
            <ng-template mat-tab-label>
                {{region.name}}
            </ng-template>
            <ng-template matTabContent>
                <!--<cs-mat-table [objectSource]="services" [tabgen]="tabgen" (select)="selectService($event)" [showadd]="false"></cs-mat-table>-->
                <div *ngIf="selectedService"><h3>{{servicepath}}</h3></div>
                <div class="f1 fl ovh" *ngIf="selectedService">
                    <div class="f2 fl fdc overflow-scroll">
                        <div class="f1">
                            <!--<cs-rate-base *ngIf="rate" [rate]="rate" [editmode]="!rate.id" [unzoned]="true"></cs-rate-base>-->
                        </div>
                        <div class="f1">
                            <!--<cs-rate-breaks *ngIf="rate" [rate]="rate" [editmode]="!rate.id" [unzoned]="true"></cs-rate-breaks>-->
                        </div>
                    </div>
                    <div class="f1 fl fdc pad">
                        <p>Port to Port Surcharges</p>
                    </div>
                    <div class="f1 fl fdc pad">
                        <div class="f1"><h3>Origin Port Charges</h3></div>
                        <div class="f1"><h3>Destination Port Charges</h3></div>
                    </div>
                    <div class="f1 fl fdc pad">
                        <div class="f1"><h3>Collection to Port Charges</h3></div>
                        <div class="f1"><h3>Port to Delivery Charges</h3></div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>                       
    </mat-tab-group>   
</div>
