import { Injectable } from '@angular/core';
import { StatusMessage } from '../models/user';
import { PastedTable, PastedRow, PastedTableCollection } from '../models/ui';
import { DataService } from './data.service';
import { RatePriority, Region, RateBracket, Rate, TransportModes, RateBreak, SurchargeAppliesTo, Surcharge, PriceAppliesTo, RegionTypes } from '../models/rates';
import { ToolsService } from './tools.service';
import { Locode, Service } from '../models/data';
import { EventsService } from './events.service';
import { BigTable, Unsworth } from '../models/importers';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  private table: PastedTable;
  /*private providerid:number;
  private customerid:number;
  private ratebrackets:RateBracket[]=[];
  private priorities:RatePriority[]=[];
  private region:Region;*/

  constructor(public data: DataService, public tools: ToolsService, public events: EventsService) { }

  import(code: string, table: PastedTable,collection:PastedTableCollection) {
    return new Promise((resolve) => {
      this.table = table;
      if (!table) resolve({ success: false, message: "No results" });
      switch (code) {
        case "DX Delivery UK":
          this.dxDeliveryUK(table).then((message: StatusMessage) => {
            this.events.stopSpinner();
            resolve({ success: true, message: { ratemap: true, rates: message.message } });
          })
          break;
        case "UltraInterSea":
          this.ultraInterSea(table).then((message: StatusMessage) => {
            this.events.stopSpinner();
            resolve(message);
          })
          break;
        case "UnsworthFCL":
          let rows = table.datarows.concat(table.extrarows).length;
          this.events.startSpinner(rows);
          this.fauxCounter(100,rows);
          this.unsworthFCL(table).then((message: StatusMessage) => {
            this.events.stopSpinner();
            resolve(message);
          })
          break;
        default:
        collection.decode = code;  
        this.importcollection(collection).then((message:StatusMessage)=>{
            resolve(message);
          })

          
      }
    })
  }
  importcollection(collection: PastedTableCollection) {
    return new Promise((resolve) => {
      this.data.importCollection(collection).subscribe((message:StatusMessage)=>{
        resolve(message);
      })
    })
  }
  fauxCounter(add:number,until:number){
    if(add<until){
      setTimeout(()=>{
      this.events.updateSpinner(add);
      this.fauxCounter(add+100,until);
    },500)
    }
    
  }

  dxDeliveryUK(table: PastedTable) {
    return new Promise((resolve) => {
      let currency = this.data.defaultCurrency.id;
      let tablepriorities = this.getPriorities(table.headerrows);
      this.data.listRatePriorities(table.providerid, RegionTypes.National, table.customerid).subscribe((message: StatusMessage) => {
        if (message.success) {
          let dbpriorites: RatePriority[] = message.message;
          this.matchPriorities(dbpriorites, tablepriorities).then((message: StatusMessage) => {
            if (message.success) {
              let code = "";
              let priorities = message.message;
              if (table.titlerow && table.titlerow.cells.length > 0) code = this.zoneMap(table.titlerow.cells[0]);
              this.data.getRegionProviderCode(code, table.providerid).subscribe((message: StatusMessage) => {
                if (message.success) {
                  if (message.message) {
                    let region: Region = message.message;
                    let tablebrs: string[] = [... new Set(table.datarows.map(c => c.cells[0]))];
                    this.buildRateBrackets(tablebrs, table.providerid, table.customerid).then((message: StatusMessage) => {
                      if (message.success) {
                        let rbs: RateBracket[] = message.message;
                        let createdrates: Rate[] = [];
                        rbs.forEach(rb => {
                          let rates = this.ratesFromPastedTable(table, rb, region, priorities, TransportModes.Road, currency);
                          createdrates = createdrates.concat(rates);

                        })
                        let todo = createdrates.length;
                        this.events.startSpinner(todo);
                        let done = 0;
                        let newrates: Rate[] = [];
                        createdrates.forEach(r => {
                          r.createdAt = table.startDate;
                          r.expires = table.expires;
                          r.transportmode = table.mode;

                          this.data.createRate(r).subscribe((message: StatusMessage) => {
                            done++;
                            this.events.updateSpinner();
                            if (message.success) {
                              newrates.push(message.message);
                              if (done == todo) {
                                resolve({ success: true, message: newrates });
                              }
                            }


                          })
                        })
                      }
                      else console.log(message);
                    })
                  }
                  else {

                    resolve({ success: false, message: "Unable to determine rate region" });
                  }
                }
                else {
                  console.log(message);
                  resolve({ success: false, message: "Unable to determine rate region" });
                }
              })
            }
          })
        }
      })
    })
  }


  ultraInterSea(table: PastedTable) {
    return new Promise(resolve => {
      let gbp = this.data.defaultCurrency.id;
      let newservices: Service[] = [];
      this.events.startSpinner(table.datarows.length);
      this.importService(table, 0, gbp, newservices, TransportModes.Shipping).then((message: StatusMessage) => {
        resolve(message);
      });
    })
  }
  unsworthFCL(table: PastedTable) {
    return new Promise(resolve => {
      let unsworths: Unsworth[] = [];
      let rowindex = 1;
      table.datarows.concat(table.extrarows).forEach(dr => {
        let unsworth = new Unsworth();
        unsworth.origin_locode = dr.rawcells[0];

        if (unsworth.origin_locode == "TKBKK") unsworth.origin_locode = "THBKK";
        if (unsworth.origin_locode == "HKHKG") unsworth.origin_locode = "CNHKG";
        unsworth.destination_locode = dr.rawcells[1];
        if (unsworth.destination_locode == "TKBKK") unsworth.destination_locode = "THBKK";
        if (unsworth.destination_locode == "HKHKG") unsworth.destination_locode = "CNHKG";

        unsworth.wm_ratio = dr.rawcells[7] == "" ? 2 : parseFloat(dr.rawcells[7]);
        let pointdate = dr.rawcells[8].split('.');
        try {
          unsworth.effective_date = new Date(parseInt(pointdate[2]), parseInt(pointdate[1]), parseInt(pointdate[0]));
        }
        catch {
          unsworth.effective_date = new Date();
        }
        let pointdateexp = dr.rawcells[9].split('.');
        try {
          unsworth.expiration_date = new Date(parseInt(pointdateexp[2]), parseInt(pointdateexp[1])-1, parseInt(pointdateexp[0]));
        }
        catch {
          let date = new Date(unsworth.effective_date.getFullYear(), unsworth.effective_date.getMonth() + 1, unsworth.effective_date.getDate());
          unsworth.expiration_date = date;
        }
        if(unsworth.expiration_date<new Date()){
          let today = new Date();
          unsworth.expiration_date = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
        }
        unsworth.mot = dr.rawcells[14];
        unsworth.carrier = dr.rawcells[15];
        unsworth.load_type = dr.rawcells[17].replace(/_/g, ' ');
        unsworth.currency = dr.rawcells[19];
        unsworth.bas = dr.rawcells[20] == "" ? 0 : parseFloat(dr.rawcells[20]);
        unsworth.cdd = dr.rawcells[21] == "" ? 0 : parseFloat(dr.rawcells[21]);
        unsworth.eca = dr.rawcells[22] == "" ? 0 : parseFloat(dr.rawcells[22]);
        unsworth.lss = dr.rawcells[23] == "" ? 0 : parseFloat(dr.rawcells[23]);
        unsworth.pss = dr.rawcells[24] == "" ? 0 : parseFloat(dr.rawcells[24]);
        unsworth.createdAt = new Date();
        unsworth.updatedAt = new Date();
        let exists = unsworths.filter(u => u.origin_locode == unsworth.origin_locode && u.destination_locode == unsworth.destination_locode);
        if (exists.length > 0) {
          unsworth.importaid = exists[0].importaid;
        }
        else {
          unsworth.importaid = rowindex;
          rowindex++;
        }

        unsworths.push(unsworth);
      })
      this.data.createUnsworth(unsworths).subscribe((message: StatusMessage) => {
        resolve(message);
      })

    });
  }
  edgeFCL(table:PastedTable){
    let bigtable:BigTable[]=[];
    let ecaindex = 0;
    table.headerrows[0].cells.some(hr=>{
      if(hr.toLowerCase().indexOf("eca")>=0){
        return true;
      }
      ecaindex++;
    })
    table.datarows.forEach(dr=>{
      let bt = new BigTable();
      switch(dr.cells[0]){
        case "20DV":
          bt.stringfield1 = "FCL 20";
          break;
          case "40DV":
          bt.stringfield1 = "FCL 40";
          break;
          case "40HC":
          bt.stringfield1 = "FCL 40 HQ";
          break;

      }
      bt.stringfield2 = dr.cells[1];
      bt.stringfield3 = dr.cells[2];
      let freight = this.tools.extractCurrency(dr.cells[3]);
      bt.stringfield4 = freight.currencyCode;
      bt.decimalfield1 = freight.amount;
      
    })
  }
  importService(table: PastedTable, index: number, gbp: number, newservices: Service[], mode: TransportModes) {
    return new Promise((resolve) => {


      let datarows = table.datarows;
      let dr = datarows[index];
      let originport = dr.cells[0];
      let destinationports = this.splitPorts(dr.cells[3]);
      let normalisedports: string[] = [];
      destinationports.forEach(dp => {
        switch (dp) {
          case "STN":
            normalisedports.push("SOU");
            break;
          default:
            normalisedports.push(dp);
            break;
        }
      })
      destinationports = normalisedports;

      originport = originport.replace('/', '');

      let destinationcountry = "GB";
      let origincountry = this.countrycode(table.titlerow.cells[0]);
      this.findLocodes(origincountry, destinationcountry, [originport], destinationports, mode).then((message: StatusMessage) => {
        if (message.success) {

          let originlocodes: Locode[] = message.message.originlocodes;
          let destinationlocodes: Locode[] = message.message.destinationlocodes;
          let originports: number[] = originlocodes.map(ol => ol.id);
          let destinationports: number[] = destinationlocodes.map(ol => ol.id);
          this.findExistingService(table.providerid, originports, destinationports, origincountry, originlocodes, destinationlocodes, this.tools.cleanFloat(dr.cells[4]), TransportModes.Shipping).then((message: StatusMessage) => {
            if (message.success) {
              let newservice: Service = message.message;
              this.data.addMissingPortSites(newservice).then(success => {
                if (success) {
                  let rate = new Rate(0, 0, TransportModes.Shipping);
                  rate.name = newservice.displayname;
                  rate.currency = newservice.currencyid;
                  rate.createdAt = table.startDate;
                  rate.expires = table.expires;
                  rate.serviceid = newservice.id;
                  rate.volumeequivalent = 2;
                  rate.providerid = this.data.Company.id;
                  rate.customerid = 0;
                  
                  let defaultrb = this.data.ratebrackets.filter(rb=>rb.name=="Pallet");
                  if(defaultrb.length>0){
                    rate.ratebrackettype = defaultrb[0].id;
                  }
                  else rate.ratebrackettype = 0;
                  
                  
                  let rb = new RateBreak();
                  rb.weightbreak = 1000;
                  rb.volumebreak = 2;
                  rb.priceperbreak = false;
                  rb.price = this.tools.cleanFloat(dr.cells[1]);

                  rate.RateBreaks.push(rb);
                  this.data.createRate(rate).subscribe((message: StatusMessage) => {
                    if (message.success) {
                      let portsite = newservice.Provider.Sites.filter(ps => ps.portid == originports[0])[0];
                      this.data.findOrCreateSurchargeType("THC", "Termminal Handling Charge", SurchargeAppliesTo.PortOut).subscribe((message: StatusMessage) => {
                        let surch = new Surcharge();
                        surch.siteid = portsite.id;
                        surch.surchargetypeid = message.message.id;
                        surch.providerid = portsite.companyid;
                        surch.expires = table.expires;
                        surch.starts = table.startDate;
                        surch.surchargeFixed = this.tools.cleanFloat(dr.cells[5]);
                        surch.perunit = PriceAppliesTo.Shipment;
                        surch.unit = 1000;
                        surch.volumeequivalent = 2;
                        surch.currencyid = gbp;

                        this.data.createSurcharge(surch).subscribe((message: StatusMessage) => {
                          if (message.success) {
                            this.data.findOrCreateSurchargeType("Delivery Order", "Delivery Order", SurchargeAppliesTo.Shipping).subscribe((message2: StatusMessage) => {
                              if (message.success) {
                                let surch2 = new Surcharge();
                                surch2.serviceid = newservice.id;
                                surch2.surchargeFixed = this.tools.cleanFloat(dr.cells[6]);
                                surch2.surchargetypeid = message2.message.id;
                                surch2.providerid = newservice.providerid;
                                surch2.perunit = PriceAppliesTo.Shipment;
                                surch2.expires = table.expires;
                                surch2.starts = table.startDate;
                                surch2.currencyid = gbp;
                                this.data.createSurcharge(surch2).subscribe((message3: StatusMessage) => {
                                  if (message3.success) {
                                    if (!newservice._isDirty) {
                                      newservices.push(newservice);
                                    }

                                    index++;
                                    if (index < datarows.length) {
                                      this.events.updateSpinner();
                                      this.importService(table, index, gbp, newservices, mode).then((importmessage: StatusMessage) => {
                                        resolve(importmessage);
                                      });
                                    }
                                    else {
                                      resolve({ success: true, message: newservices });
                                    }



                                  }
                                  else resolve(message3);
                                }, err => {
                                  this.tools.gracefulError(err);
                                })
                              }
                              else this.tools.gracefulError(message.message);
                            }, err => {
                              this.tools.gracefulError(err);
                            });

                          }
                          else this.tools.gracefulError(message.message);
                        }, err => {
                          this.tools.gracefulError(err);
                        })
                      })
                    }
                    else this.tools.gracefulError(message.message);
                  }, err => {
                    this.tools.gracefulError(err);
                  })
                }
                else this.tools.gracefulError("Failed to add required port sites to the provider company");
              })
            }
            else resolve(message);
          })
        }
        else resolve(message);
      })
    })
  }
  countrycode(countryname: string) {
    let co = this.data.countries.filter(c => c.name.toLowerCase() == countryname.toLowerCase());
    if (co.length > 0) {
      return co[0].iso_alpha_2;
    }
    else return countryname;
  }
  findExistingService(providerid: number, originports: number[], destinationports: number[], origincountry: string, originlocodes: Locode[], destinationlocodes: Locode[], transittime: number, mode: TransportModes) {
    return new Promise(resolve => {
      this.data.findServiceFromPorts(providerid, originports, destinationports).subscribe((message: StatusMessage) => {
        if (message.success) {
          if (!message.message) {
            this.data.createMinimumService("GB", origincountry, this.tools.cleanFloat(transittime), this.data.Company.id, "USD", originlocodes, destinationlocodes, mode).then((message2: StatusMessage) => {
              resolve(message2);

            }, err => { resolve({ success: false, message: err }) });
          }
          else {
            let existing: Service = message.message;
            existing._isDirty = true;
            resolve(message);
          }

        }
        else resolve(message);
      }, err => {
        resolve({ success: false, message: err })
      });
    });

  }

  findLocodes(originlocode1: string, destinationlocode1, originports: string[], destinationports: string[], mode: TransportModes) {
    return new Promise(resolve => {

      this.data.locodeSearch(originlocode1, originports, mode).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.data.locodeSearch(destinationlocode1, destinationports, mode).subscribe((message1: StatusMessage) => {
            if (message1.success) {
              let originlocodes = message.message;
              let destinationlocodes = message1.message;
              if (originlocodes && originlocodes.length > 0) {
                if (destinationlocodes && destinationlocodes.length > 0) {
                  resolve({ success: true, message: { originlocodes: originlocodes, destinationlocodes: destinationlocodes } });
                }
                else resolve({ success: false, message: "no destination ports found" });
              }
              else {
                resolve({ success: false, message: "no origin ports found for " + originlocode1 });
              }
            }
            else {
              this.tools.gracefulError(message1.message);
            }
          }, err => {
            this.tools.gracefulError(err);
          }
          )
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    })
  }

  splitPorts(portstring: string): string[] {
    let split = portstring.split(' & ');
    if (split.length > 1) {
      return split;
    }
    else return [portstring];
  }


  ratesFromPastedTable(table: PastedTable, ratebracket: RateBracket, region: Region, priorities: RatePriority[], transportmode: TransportModes, currency: number): Rate[] {
    let ratelist: Rate[] = [];
    let index = table.datacolumns;
    let datarows = table.datarows.filter(dr => dr.cells[0] == ratebracket.name);

    let ratebrackettype = this.data.ratebrackets.filter(rb=>rb.name=="Pallet")[0];
    switch (ratebracket.name) {
      case "Dockpak":
      case "Nightpack":
      case "Parcels":
        ratebrackettype = this.data.ratebrackets.filter(rb=>rb.name=="Package")[0];
        break;
      case "Lengths over 3 metres":
        ratebrackettype = this.data.ratebrackets.filter(rb=>rb.name=="Lengths over 3 metres")[0];
        break;
      case "IDW":
        ratebrackettype = this.data.ratebrackets.filter(rb=>rb.name=="IDW")[0];
        break;
    }


    priorities.forEach(priority => {
      if (datarows.length == 1) {
        let raterow = datarows[0];
        if (raterow.cells[index] != "") {
          let rate = new Rate(region.id, priority.id);
          rate.name = ratebracket.name;
          rate.parentregionid = region.parentId;
          rate.providerid = table.providerid;
          rate.ratebrackettype = ratebrackettype.id;
          rate.transportmode = transportmode;
          rate.currency = currency;
          rate.baseprice = parseFloat(raterow.cells[index].replace(/[^.\d]/g, ''));
          ratelist.push(rate);
        }
      }
      else if (ratebrackettype.name != "Pallet") {
        if (datarows.length > 0) {
          let fixedrow = datarows[0];
          let rate = new Rate(region.id, priority.id);
          rate.name = ratebracket.name;
          rate.parentregionid = region.parentId;
          rate.providerid = table.providerid;
          rate.currency = currency;
          rate.ratebrackettype = ratebrackettype.id;
          rate.transportmode = transportmode;
          rate.baseprice = parseFloat(fixedrow.cells[index].replace(/[^.\d]/g, ''));
          if (datarows.length > 1) {
            rate.baseIncludesWeight = parseFloat(datarows[1].cells[index].replace(/[^.\d]/g, ''));
            if (datarows.length > 2) {
              let rb = new RateBreak();
              rb.price = parseFloat(datarows[2].cells[index].replace(/[^.\d]/g, ''));
              rb.priceperbreak = false;
              rb.minvolume = rate.baseIncludesWeight;
              rb.weightbreak = 1;
              rate.RateBreaks.push(rb);
            }
            if (datarows.length > 4) {
              let rb2 = new RateBreak();
              rb2.price = parseFloat(datarows[4].cells[index].replace(/[^.\d]/g, ''));
              if (rb2.price > 0) {
                let br = parseFloat(datarows[3].cells[index].replace(/[^.\d]/g, ''));
                rate.RateBreaks[rate.RateBreaks.length - 1].maxweight = br;
                rb2.priceperbreak = false;
                rb2.weightbreak = 1;
                rb2.minweight = br;
                rate.RateBreaks.push(rb2);
              }

            }
          }

          ratelist.push(rate);
        }
      }
      else if (ratebrackettype.name == "Pallet") {
        let rate = new Rate(region.id, priority.id);
        rate.name = ratebracket.name;
        rate.parentregionid = region.parentId;
        rate.providerid = table.providerid;
        rate.ratebrackettype = ratebrackettype.id;
        rate.transportmode = transportmode;
        rate.currency = currency;
        rate.baseprice = 0;//parseFloat(datarows[0].cells[index].replace(/[^.\d]/g, ''));
        let includes = 0;//parseFloat(datarows[0].cells[2].replace(/[^.\d]/g, ''));
        rate.baseIncludesWeight = 0;//includes;
        for (let i = 0; i < datarows.length; i++) {
          let rb = new RateBreak();
          rb.priceperbreak = true;
          rb.minweight = includes;
          rb.maxweight = parseFloat(datarows[i].cells[2].replace(/[^.\d]/g, ''));
          includes = rb.maxweight;
          rb.price = parseFloat(datarows[i].cells[index].replace(/[^.\d]/g, ''));
          if (rb.price > 0) {
            rate.RateBreaks.push(rb);
          }

        }
        ratelist.push(rate);
      }
      index++;
    })
    return ratelist;
  }

  buildRateBrackets(names: string[], providerid: number, customerid: number) {
    return new Promise((resolve) => {
      let have: RateBracket[] = [];
      let needed: RateBracket[] = [];

      this.data.listRateBracketsProvider(providerid).subscribe((message: StatusMessage) => {
        if (message.success) {
          let ratebrackets: RateBracket[] = message.message;
          let index = 0;
          names.forEach(n => {
            let rbs = ratebrackets.filter(r => r.name == n);
            if (rbs.length > 0) {
              have.push(rbs[0]);
            }
            else {
              needed.push(this.createRateBracket(n, providerid, customerid,index));
            }
          })
          if (needed.length > 0) {
            this.data.createRateBrackets(needed).subscribe((message: StatusMessage) => {
              if (message.success) {
                have = have.concat(message.message);
                resolve({ success: true, message: have });
              }
              else resolve(message);
            }, err => {
              resolve({ success: false, message: err });
            })
          }
          else {
            resolve({ success: true, message: have });
          }

        }
        else resolve({ success: false })
      })
    })

  }
  createRateBracket(name: string, providerid: number, customerid: number,sequence:number): RateBracket {
    let rb = new RateBracket();
    rb.name = name;
    rb.providerid = providerid;
    rb.customerid = customerid;
    rb.sequence = sequence;
    return rb;
  }


  getPriorities(headers: PastedRow[]) {
    let tablepriorities: string[] = [];
    let cellindex = 0;
    headers[0].cells.forEach(c => {
      if (c != "") {
        c = c.trim();
        if (headers.length > 0) {
          for (let i = 1; i < headers.length; i++) {
            if (headers[i].cells[cellindex] != "") {
              let bit = headers[i].cells[cellindex].trim();
              if (c.toLocaleLowerCase() != bit.toLocaleLowerCase()) {
                c += " " + bit;
              }

            }
          }
        }
        tablepriorities.push(c);
      }
      cellindex++;

    })
    return tablepriorities;
  }
  matchPriorities(dbpriorites: RatePriority[], tablepriorities: string[]) {
    return new Promise((resolve) => {
      let ordered: RatePriority[] = [];
      if (tablepriorities.length == 0) resolve({ success: true, message: ordered });
      let index = 0;
      tablepriorities.forEach((tbp) => {
        let exists = dbpriorites.filter(d => d.name == tbp);
        if (exists.length == 1) {
          ordered.push(exists[0]);
          if (ordered.length == tablepriorities.length) resolve({ success: true, message: ordered });
        }
        else {
          let newrp = new RatePriority();
          newrp.Bands = [];
          newrp.companyid = this.table.providerid;
          newrp.customerid = this.table.customerid;
          newrp.specificday = this.findDay(tbp);
          let time = this.findTime(tbp);
          if(time) newrp.bytime = time.toString();
          if (tbp.indexOf("Next day") >= 0 || newrp.specificday) {
            newrp.daysplus = 1;
          }
          else newrp.daysplus = 3;
          newrp.name = tbp;
          newrp.regionlevel = RegionTypes.National;
          newrp.sequence = index;
          index++;
          this.data.createRatePriority(newrp).subscribe((message: StatusMessage) => {
            if (message.success) {
              ordered.push(message.message);
              if (ordered.length == tablepriorities.length) {
                ordered.sort((a,b)=>{
                  if(a.sequence<b.sequence) return -1;
                  return 1;
                })
                resolve({ success: true, message: ordered });
              }
            }
          })
        }
      })

    })
  }
  findDay(header: string): number {
    if (header.indexOf("Sat ") >= 0 || header.indexOf("Saturday") >= 0) {
      return 7;
    }
    if (header.indexOf("Sun ") >= 0 || header.indexOf("Sunday") >= 0) {
      return 0;
    }
    else return null;
  }
  findTime(header: string): number {
    if (header.toLocaleLowerCase().indexOf("noon") >= 0) return 12;

    let timeex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);
    //try 12 hour
    let time = header.match(timeex);
    if (!time) return null;
    if (time.length == 5) {
      let hours = parseInt(time[2]);
      let mins = parseInt(time[3]);
      let timenum = hours + (mins / 100);
      if (time[4] == "am") {
        return timenum;
      }
      else return timenum + 12;
    }
    //try 24 hour
    timeex = new RegExp(/^(0?[1-9]|1[0-2]):[0-5][0-9]\d$/);
    time = header.match(timeex);
    if (!time) return null;
    if (time.length == 5) {
      return parseFloat(time[0]);
    }
    else return null;

  }
  zoneMap(zone: string) {
    let words = zone.split(' ');
    if (words.length > 1) {
      if (words[0].toLocaleLowerCase() == "zone") return words[1];
    }
    return "";
  }
  coreData() {

  }
}
