import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyComponent } from 'src/app/dialogs/company/company.component';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { Site, StatusMessage } from 'src/app/models/user';
import { MapRequest, ServiceVia, Service } from 'src/app/models/data';
import { Region, TransportModes, MarginAppliesTo } from 'src/app/models/rates';
import { ImporterService } from 'src/app/services/importer.service';
import { PastedRow, PastedTable, PastedTableCollection } from 'src/app/models/ui';
import { Country } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-paste-results',
  templateUrl: './paste-results.component.html',
  styleUrls: ['./paste-results.component.scss']
})
export class PasteResultsComponent implements OnInit {

  public ob: PastedTable;
  public collection: PastedTableCollection;
  public site: Site;
  public basesite: Site;
  public currency: number;
  public service: Service;
  public region: Region;
  public recognised: boolean = false;

  public description:string = "";
  public mapped: boolean = false;
  public map: string = "";
  public pdfratecard = false;



  public maprequest: MapRequest;

  public maps: MapRequest[] = [];

  public startDate = new Date();
  public expiryDate: Date;
  public selectedMode = TransportModes.Road;
  public transportmodes = TransportModes;
  public priorityid:number;

  public appliesto: MarginAppliesTo;

  public extrarows: PastedRow[] = [];

  constructor(public dialogRef: MatDialogRef<CompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, public data: DataService, private tools: ToolsService, public importer: ImporterService, public events: EventsService) {
    this.collection = dialogData.collection;
    if (this.collection.tables.length > 0) {
      this.ob = this.collection.tables[0];
    }
    this.site = dialogData.site;
    this.basesite = this.site;//TODO this is a stop gap logic required here.
    if (!this.site.Company) {
      this.site.Company = this.data.providers.filter(c => c.customerid == this.site.companyid)[0].Provider;
    }
    this.service = dialogData.service;
    this.region = dialogData.region;
    this.appliesto = dialogData.appliesto;
    if(dialogData.priority){
      this.priorityid = dialogData.priority.id;
    }
    
    if (this.appliesto == MarginAppliesTo.Service_Charges) {
      this.selectedMode = TransportModes.Shipping;
    }
  }

  ngOnInit(): void {
    this.currency = this.data.defaultCurrency.id;
    let preexpires = this.startDate;
    this.expiryDate = new Date(preexpires.getFullYear(), preexpires.getMonth() + 3, preexpires.getDate());

    if (this.collection && this.collection.title == "DPD UK Rates") {
      this.recognised = true;
      this.mapped = true;
      this.appliesto == MarginAppliesTo.Delivery_Rates;
      this.pdfratecard = true;
    }
    else {



      this.maprequest = new MapRequest();
      this.maprequest.companyid = this.data.Company.id;

      this.maprequest.consistentheaders = false;
      this.maprequest.consistentrows = false;
      this.maprequest.description = "";
      /*
      if(this.ob.datarows.length>10){
        let shortob = new PastedTable;
        shortob.customerid = this.ob.customerid;
        shortob.datacolumns = this.ob.datacolumns;
        shortob.datarows = [];
        for(let i = 0;i<10;i++){
          shortob.datarows.push(this.ob.datarows[i]);
        }
        shortob.expires = this.ob.expires;
        shortob.headerrows =this.ob.headerrows;
        shortob.mode = this.ob.mode;
        shortob.providerid = this.ob.providerid;
        shortob.titlerow = this.ob.titlerow;
        this.maprequest.json = JSON.stringify(shortob);
      }
      else {
        
      }*/
      if (this.ob) {

        this.extrarows = this.ob.extrarows;
        this.ob.extrarows = [];
        this.maprequest.json = JSON.stringify(this.ob);
        if (this.ob.titlerow.cells.length > 0) {
          this.maprequest.title = this.ob.titlerow.cells[0].split(' ')[0];
        }
        else this.maprequest.title = "";

        this.maprequest.columns = this.ob.datarows[0].rawcells.length;
        this.maprequest.datacolumns = this.ob.datacolumns;
        if (this.site) {
          this.maprequest.providerid = this.site.companyid;
        }
        if (this.service) {
          this.maprequest.serviceid = this.service.id;
          this.maprequest.providerid = this.service.providerid
        }
        else this.maprequest.serviceid = 0;
        if (this.region) {
          this.maprequest.regionid = this.region.id;
        }
        else this.maprequest.regionid = 0;
        this.maprequest.definer = this.ob.definer;

        this.data.findMap(this.maprequest).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.maps = message.message;
            if (this.maps.length > 0) {
              let existingmap = this.maps[0]
              this.ob.providerid = existingmap.providerid;
              this.ob.customerid = existingmap.companyid;
              this.recognised = true;
              this.description = existingmap.description;

              this.maps.some(m => {
                if (m.code) {
                  this.mapped = true;
                  return true;
                }
              })
            }
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }

    }

  }
  requestmap() {
    this.maprequest.transportmode = this.selectedMode;
    this.data.createMapRequest(this.maprequest).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.dialogRef.close({ success: true, message: { ratemap: true } });
      }
      else {
        this.dialogRef.close({ success: false, message: { ratemap: true } });
      }
    }, err => {
      console.log(err);
      this.dialogRef.close({ success: false, message: { ratemap: true } });
    });
  }
  import() {
    this.collection.basesiteid = this.basesite.id;
    this.collection.currency = this.currency;
    this.collection.regionid =  this.region ? this.region.id : undefined;
    this.collection.tables.forEach(ob => {
      ob.startDate = this.startDate;
      ob.expires = this.expiryDate;
      ob.mode = this.selectedMode;
      ob.extrarows = this.extrarows;
      if(this.priorityid){
          ob.priorityid = this.priorityid;
      }
      
    })
    if (this.collection.tables.length > 1) {
      this.events.startSpinner();
      this.importer.importcollection(this.collection).then((message: StatusMessage) => {
        if (message.success) {
          this.recognised = true;
          this.events.stopSpinner();
          this.dialogRef.close(message);
        }
        else this.tools.gracefulError(message.message);
      })
    }
    else {
      this.importer.import(this.maps[0].code, this.ob,this.collection).then((message: StatusMessage) => {
        if (message.success) {
          this.recognised = true;
          this.dialogRef.close(message);
        }
        else this.tools.gracefulError(message.message);
      })
    }



  }
  cancel() {
    this.dialogRef.close();
  }

}
