import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver, Type, Output, EventEmitter } from '@angular/core';
import { CompanySettingType, CompanySettingTypes, NewCompanySettingType } from 'src/app/models/user';
import { SettingsContainerDirective } from 'src/app/directives/settings-container.directive';
import { EnumChooserComponent } from 'src/app/settings-editors/enum-chooser/enum-chooser.component';
import { StringChooserComponent } from 'src/app/settings-editors/string-chooser/string-chooser.component';
import { SettingEditor } from 'src/app/settings-editors/setting-editor';
import { BooleanChooserComponent } from 'src/app/settings-editors/boolean-chooser/boolean-chooser.component';
import { CurrencyChooserComponent } from 'src/app/settings-editors/currency-chooser/currency-chooser.component';
import { DataService } from 'src/app/services/data.service';
import { ImageChooserComponent } from 'src/app/settings-editors/image-chooser/image-chooser.component';

@Component({
  selector: 'cs-company-settings-editor',
  templateUrl: './company-settings-editor.component.html',
  styleUrls: ['./company-settings-editor.component.scss']
})
export class CompanySettingsEditorComponent implements OnInit {

  @Input() settingtype:CompanySettingType;
  @Output() changes = new EventEmitter<NewCompanySettingType>();
  @ViewChild(SettingsContainerDirective,{static:true}) csSettingsContainer:SettingsContainerDirective

  constructor(private resolver: ComponentFactoryResolver,public data:DataService) { }

  ngOnInit(): void {
    let editor:Type<any>;
    let viewdata:any = {settingtype:this.settingtype,title:this.settingtype.description};
    if(this.data.auth.user.UserRoles[0].roleid<3) viewdata.editmode = true;
    switch(this.settingtype.id){
      case CompanySettingTypes.ShowSizeBracket:
        editor = BooleanChooserComponent;
        viewdata.default = 0;
        break;
      case CompanySettingTypes.DefaultSizeBracket:
        editor = EnumChooserComponent;
        viewdata.enum = this.data.ratebrackets;
        viewdata.default = this.data.ratebrackets.filter(rb=>rb.name=="Pallet")[0];
        break;
      case CompanySettingTypes.Logo:
        editor = ImageChooserComponent;
        break;
        case CompanySettingTypes.DefaultCurrency:
          editor = CurrencyChooserComponent;
          viewdata.currencies = this.data.currencies;
          viewdata.default = this.data.defaultCurrency;
          break;
      default:
        editor = StringChooserComponent;
    }

    const factory = this.resolver.resolveComponentFactory(editor);
    const viewConRef = this.csSettingsContainer.containerRef;
    viewConRef.clear();

    const componentRef = viewConRef.createComponent<SettingEditor>(factory);
    componentRef.instance.data = viewdata;

  }

}
