import { RateBreak, TransportModes, Rate, RatePriority, Region, QuotationPrice, Size, ModeMetric, SavedQuotePointToPoint } from './rates';
import { Locode, Vessel, Service } from './data';
import { PortToPort } from './models';
import { Site, CompanyAssociation, Address, Company } from './user';
import moment from 'moment';

export class View {
    id: number;
    name: string;
    description?: string;
}

export class Priority {
    id: number;
    code: number;
    name: string;
    collection:QuotationPrice;
}

export class RateBreakIndex {
    index: number;
    rateBreak: RateBreak;
}

export class BandedRegion {
    id: number;
    name: string;
}

export class RateCardRow {
    band: string;
    values: number[];
}

export class QuoteModel {

}

export class xTransportSteps {
    //service: Service;
    steps: any[];
    //waypoints: Waypoint[];
    total:ModeMetric;
    constructor() {
        this.steps = [];
        //this.waypoints = [];
        this.total = new ModeMetric();
        this.total.km = 0;
        this.total.co2 = 0;
        this.total.cost = 0;
        this.total.duration = 0;

    }
}

export class TransportHub {
    name:string;
    icon: string;
    hubtype: TransportHubTypes;
    cost: number;
    duration: number;
    //charges: Charge[];
    constructor(){
        //this.charges=[];
    }
}



export class xCharge {
    description: string;
    fee: number;
}

export interface CurrencyView {
    code: string;
    symbol: string;
    flag: string;
    appliesto:string;
}
/**
 * object to hold all the information provided for a quotation
 */
export class QuoteInput {
    public id:number;
    public origin: Address;
    public originSite: Site;
    public destination: Address;
    public destinationSite: Site;
    //public originWaypoint: Waypoint;
    //public destinationWaypoint: Waypoint;
    public quoteItems:Size[];
    public customerid:number;
    public totalweight:number;
    public totalvolume:number;
    public totalarea:number;
    public totalquantity:number;
    public servicedate:Date;
    public expires:Date;
    public sortarray:number[];
    public quoteOptions:QuoteOption[];
    
    constructor(){
        this.quoteItems = [new Size()];
        this.totalweight = 0;
        this.totalvolume = 0;
        this.totalarea = 0;
        this.totalquantity = 0;
        let now = moment();
        this.servicedate = now.add(1, 'day').toDate();
        this.expires = moment(this.servicedate).add(1, 'month').toDate();
        
        this.quoteOptions = [
            {id:0,description:"Must have collection capability",selected:false},
            {id:1,description:"Must have delivery capability",selected:false},
            {id:2,description:"Collection requires taillift",selected:false},
            {id:3,description:"Delivery requires taillift",selected:false},
            {id:4,description:"Collection is from a residential address",selected:false},
            {id:5,description:"Delivery is to a residential address",selected:false},
            {id:6,description:"Shipment is hazardous",selected:false}
        ];
    }
}

export class QuoteOption{
    id:number;
    description:string;
    selected:boolean;
}
/**
 * name and google.maps.latlng
 */
export class xWaypoint {
    name: string;
    gps: google.maps.LatLng;
}
/**
 * info from map select
 */
export class MapSelect {
    gps: google.maps.LatLng;
    usepostcode: boolean;
}
export interface ServiceRequest {
    home: number;
    mode: number;
    destinations: number[];
}
export interface ServiceResult {
    service: Service;
    rate: Rate;
}
export class PortProvider {
    portname: string;
    port: Locode;
    preferred: number;
    providers: PortSite[];
    constructor() {
        this.providers = [];
    }
}
export class PortSite {
    name: string;
    partnersiteid?: number;
    siteid: number;
    locodeid: number;
    providerid: number;
}
export class CO2 {
    co2: number;
    band: CarbonBand;
    constructor(){
        this.co2 = 0;
    }
}
export enum CarbonBand {
    VERYLOW = 1, LOWERAVERAGE, AVERAGE, HIGHERAVERAGE, HIGH
}
export enum TransportHubTypes {
    ORIGIN, DESTINATION, CONSOLIDATION, PORT, AIRPORT, RAILWAY_STATION
}

export class PastedTableCollection{
    title:string;
    decode:string;
    basesiteid:number;
    currency:number;
    regionid:number;  
    subtitles:string[];
    tables:PastedTable[];
}

export class PastedTable {
    rawrows: PastedRow[];
    titlerow: PastedRow;
    headerrows: PastedRow[];
    datarows: PastedRow[];
    extrarows:PastedRow[];
    analysis: string[];
    datacolumns: number;
    providerid: number;
    customerid: number;
    priorityid:number;
    startDate:Date;
    expires:Date;
    mode:number;
    iscsv:boolean;
    definer:string;


    constructor() {
        this.rawrows = [];
        this.titlerow = new PastedRow();
        this.headerrows = [];
        this.datarows = [];
        this.analysis = [];
        this.datacolumns = 0;
        this.startDate = new Date();
        this.iscsv = false;
    }
}
export class PastedRow {
    raw: string;
    cleaned: string;
    cells: string[];
    rawcells: string[];
    firstofcategory:boolean;
    constructor() {
        this.cells = [];
        this.rawcells = [];
    }
}

export class RateImporter {
    priorities: RatePriorityContainer[];
    services: Service[];

    constructor() {
        this.priorities = [];
        this.services = [];
    }
}
export class RatePriorityImporter {

}
export class RatePriorityContainer extends RatePriority {
    rates: Rate[];
    constructor() {
        super();
        this.rates = [];
    }
}

/**
 * an OD pair of ports with the associated service, origin and destination siteregions (containing the region hierarchy for those sites)
 * this forms the backbone of the quotation giving us access to rates and CO2
 */
export class PreferredPorts {
    id:number;
    mode: TransportModes;
    origin: Locode;
    destination: Locode;
    originSites: SiteRegion[];
    originSiteIndex: number;
    destinationSites: SiteRegion[];
    destinationSiteIndex: number;
    vessel: Vessel;
    port2port: PortToPort;
    service: Service;
    provider: CompanyAssociation;
    servicerates: QuotationPrice[];
    servicesurcharges: QuotationPrice[];
    quotesurcharges: QuotationPrice[];
    balance:QuotationPrice;
    total:QuotationPrice;
    steps:ModeMetric[];
    
    

    metrics:ModeMetric;

    PointToPoints:SavedQuotePointToPoint[];
    
    greenest:boolean;
    fastest:boolean;
    cheapest:boolean;
    cheapestfull:boolean;

    _terms:Terms;
    _sortmetrics:any[];

    constructor(){
        this.steps = [];
    }
}

export enum Terms{
    DoorToDoor,DoorToPort,PortToDoor,PortToPort
}

/**
 * A List of Preferred Ports including the service, it's provider and the possible origins and destinations
 */
export class Quotation {
    id:number;
    portoptions: PreferredPorts[] = [];
    quoteInput:QuoteInput;
    selectedOption:number;
    currency:number;
    pointtopoint:boolean;

    /*refactored to port option
    greenest:number;
    fastest:number;
    cheapest:number;
    cheapestfull:number;*/

    constructor(){
        this.portoptions = [];
        this.quoteInput = new QuoteInput();
        this.selectedOption = 0;
    }
}

export class SiteRegion {
    site: Site;
    regions: Region[] = [];
    rateRegion: Region;
    priorities:Priority[];
    hasCollection: boolean;
    surcharges: QuotationPrice[];
    hasSurcharges: boolean;
    totalCollection:number;
    include:boolean;
    selectedPriority:number;

    constructor() {
        this.regions = [];
        this.surcharges = [];
        this.priorities = [];
        this.hasCollection = false;
        this.hasSurcharges = false;
        this.totalCollection = 0;
        this.include = true;
        this.selectedPriority = 0;
    }
}

