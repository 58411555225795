import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cs-region-builder',
  templateUrl: './region-builder.component.html',
  styleUrls: ['./region-builder.component.scss']
})
export class RegionBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
