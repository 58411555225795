<h3>Driving Distance Calculator</h3>
<div class="fl pr">
    <div>
    <mat-spinner  *ngIf="isLoading" diameter="32"></mat-spinner>
    <div class="fl">
        <div class="f1 mr">
            <mat-form-field>
                <mat-label>Origin</mat-label>
                <input matInput type="text" placeholder="Origin" [(ngModel)]="origin">
            </mat-form-field>
            <mat-icon class="pointer small form-icon-margin" (click)="addOrigin()">add</mat-icon>
            <div class="fl aic" *ngFor="let or of origins;let o=index;">{{or}} <mat-icon class="pointer small" (click)="removeOrigin(o)" color="error">
                    delete</mat-icon>
            </div>
        </div>
        <div class="f1">
            <mat-form-field>
                <mat-label>Destination</mat-label>
                <input matInput type="text" placeholder="Destination" [(ngModel)]="destination">
            </mat-form-field>
            <mat-icon class="pointer small form-icon-margin" (click)="addDest()">add</mat-icon>
            <div class="fl aic" *ngFor="let de of destinations;let d=index;">{{de}} <mat-icon class="pointer small" (click)="removeDest(d)" color="error">
                delete</mat-icon>
        </div>
        </div>
        <div class="fl aic fwn neg-form-margin">
            <mat-icon *ngIf="formvalid" (click)="clearForm()" color="error" class="pointer">clear</mat-icon>
            <button [disabled]="!formvalid" type="button" mat-mini-fab aria-label="calculate" color="primary" title="Calculate Distance">
                <mat-icon (click)="calculate()">near_me</mat-icon>
            </button>
            
        </div>
    </div>
    <div class="results">
        <table *ngIf="matrix" class="grid">
            <tr>
                <th></th>
                <th *ngFor="let des of matrix.destinationAddresses">{{des}}</th>
            </tr>
            <tr *ngFor="let ori of matrix.originAddresses;let x = index;">
                <td class="bold">{{ori}}</td>
                <td class="tac" *ngFor="let el of matrix.rows[x].elements">
                    <span *ngIf="el.distance">{{el.distance.text}}  {{el.duration.text}}</span><mat-icon color="error" class="small ma" *ngIf="!el.distance">sentiment_dissatisfied</mat-icon></td>
            </tr>
        </table>
    </div>
    </div>
    <div class="f1"></div>
</div>
