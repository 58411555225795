<div class="fl">
    <div class="f1"><h3>{{surchargeappliesto}}</h3></div>
    <div class="fl aic mt" *ngIf="editmode">
        <button *ngIf="saveon" class="mr" type="button" mat-mini-fab aria-label="Update" color="primary"
            title="Update" (click)="save()" [disabled]="serviceForm.pristine">
            <mat-icon>save</mat-icon></button>
        <cs-company-search #cosearch *ngIf="!provider" [host]="'surcharge-container'"></cs-company-search>
        <button *ngIf="!addnew" class="mr" type="button" mat-mini-fab aria-label="Add Charge" color="primary"
            title="Add Surcharge" (click)="addSurcharge()">
            <mat-icon>add</mat-icon>
        </button>
        <mat-form-field *ngIf="!addnew">
            <mat-label>Surcharge Type</mat-label>
            <mat-select [(ngModel)]="selectedSurchargeType">
                <mat-option *ngFor="let surchargetype of surchargeTypes" [value]="surchargetype">
                    {{surchargetype.description}}
                </mat-option>
                <mat-option (click)="addOther()">
                    Other
                </mat-option>
            </mat-select>
        </mat-form-field>
        <cs-add-surchargetype #addtype class="{{addnew?'':'hidden'}}" [appliesto]="appliesto" [providerid]="serviceproviderid"></cs-add-surchargetype>
    </div>
</div>
<p *ngIf="!surcharges || surcharges.length==0">
    <i>no surcharges applicable</i>
</p>
<div *ngIf="surchargearray">
    <cs-surcharge *ngFor="let surcharge of surchargearray.controls" [surchargeTypes]="surchargeTypes"
        [surcharge]="surcharge" [editmode]="true"></cs-surcharge>
</div>