import { Component, OnInit, Input } from '@angular/core';
import { Rate } from 'src/app/models/rates';
import { Country } from 'src/app/models/models';

@Component({
  selector: 'cs-rate-table-header',
  templateUrl: './rate-table-header.component.html',
  styleUrls: ['./rate-table-header.component.scss']
})
export class RateTableHeaderComponent implements OnInit {

  @Input() rates:Rate[]=[];
  @Input() defaultcurrency:Country;

  public showmore = false;

  constructor() { }

  ngOnInit(): void {
  }
  selectRate(rate:Rate){

  }
}
