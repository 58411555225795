<h3>Driving Distance Boundaries</h3>
<p *ngIf="parentRegion && data.selectedSupplier">from : {{data.selectedSupplier._baseSite.Address.city}}</p>

<div class="fl" *ngIf="parentRegion">
    <div><mat-form-field class="form-skinny" *ngIf="parentRegion.children.length==0">
        <mat-label>Bands</mat-label>
        <input matInput type="number" placeholder="name" [(ngModel)]="bands" min="1" autocomplete="distance-bands">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Distance/Duration</mat-label>
        <mat-select [(ngModel)]="howderive">
            <mat-option [value]=1>
                Distance
            </mat-option>
            <mat-option [value]=2>
                Duration
            </mat-option>
            <mat-option [value]=3>
                Whichever Greater
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Min (km)</mat-label>
        <input matInput type="number" placeholder="name" [ngModel]="minkm" min="1" readonly="true">
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Max (km)</mat-label>
        <input matInput type="number" placeholder="name" [(ngModel)]="maxkm" min="1">
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Min minutes</mat-label>
        <input matInput type="number" placeholder="name" [ngModel]="minmin" readonly="true">
    </mat-form-field>
    <mat-form-field class="form-skinny">
        <mat-label>Max minutes</mat-label>
        <input matInput type="number" placeholder="name" [(ngModel)]="maxminutes" min="1">
    </mat-form-field>
</div>
    <div><button class="mr" mat-raised-button color="primary" (click)="createBands()" type="button" *ngIf="parentRegion.children.length==0">Auto Add</button>
        <button type="button" mat-mini-fab aria-label="Add Band" color="primary" title="Add Band"
                        (click)="addBand()">
                        <mat-icon>add</mat-icon>
                    </button></div>
    
</div>

<div class="fl">
    <div>
        <table *ngIf="parentRegion && parentRegion.children" (click)="logParent()">
            <tr>
                <th>Code</th>
                <th>Name</th>
                <th><></th>
                <th>Up to (km)</th>
                <th>Method</th>
                <th></th>
            </tr>
            <tr *ngFor="let band of filteredRegions;let i=index;" class="tr-mat-form">
                <td>
                    <mat-form-field class="form-skinny">
                        <mat-label>Code</mat-label>
                        <input matInput type="text" placeholder="code" [(ngModel)]="band.code">
                    </mat-form-field>
                </td>
                <td>
                    {{band.name}}
                </td>
                <td>
                    <mat-icon color="primary" class="pointer" (click)="setTitle(band)">{{band.RegionDistancer.morethan?'chevron_right':'chevron_left'}}</mat-icon>
                </td>
                <td>
                    <mat-form-field class="form-skinny">
                        <mat-label>{{band.RegionDistancer.morethan?'above km':'up to km'}}</mat-label>
                        <input matInput type="text" placeholder="code" [(ngModel)]="band.RegionDistancer.maxkm" (change)="updateName(i)">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="form-skinny">
                        <mat-label>{{band.RegionDistancer.morethan?'above mins':'up to mins'}}</mat-label>
                        <input matInput type="text" placeholder="code" [(ngModel)]="band.RegionDistancer.maxmin" (change)="updateName(i)">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Distance/Duration</mat-label>
                        <mat-select [(ngModel)]="band.RegionDistancer.derives" (selectionChange)="updateName(i)" class="c">
                            <mat-option [value]=1>
                                Distance
                            </mat-option>
                            <mat-option [value]=2>
                                Duration
                            </mat-option>
                            <mat-option [value]=3>
                                Whichever Greater
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-icon class="pointer" color="error" (click)=removeBand(i)>delete</mat-icon>
                </td>
            </tr>
        </table>
    </div>
    <div class="f1"></div>

</div>
<div class="tar">
    <button mat-raised-button color="warn" (click)="cancel()" type="button" class="mr">Cancel</button>
    <button mat-raised-button color="primary" (click)="done()" type="button">Done</button>
</div>
