<form *ngIf="service" [formGroup]="serviceForm" (ngSubmit)="submit()">

    <div class="f1">
        <div class="fl half-form">
            <div>
                <mat-form-field>
                    <mat-label>Service Name</mat-label>
                    <input type="search" matInput formControlName="displayname">
                </mat-form-field>
            </div>
            <div [formGroup]="providerGroup">
                <mat-form-field>
                    <mat-label>Service Provider</mat-label>
                    <input type="search" matInput formControlName="name" [matAutocomplete]="providerAuto">
                </mat-form-field>
                <mat-autocomplete #providerAuto="matAutocomplete" [displayWith]="displayProviderWith"
                    (optionSelected)="chooseProvider($event)">
                    <mat-option *ngFor="let company of filteredProviders | async" [value]="company.Provider">
                        {{company.Provider.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="tar">
                <mat-slide-toggle [(ngModel)]="serviceActive" [ngModelOptions]="{standalone: true}" color="primary" (change)="serviceStatus($event)">
                    <span>Active</span>
                </mat-slide-toggle>
            </div>
        </div>
        <mat-tab-group (selectedIndexChange)="changeTab($event)" class="min500">
            <mat-tab>
                <ng-template mat-tab-label>
                    Info
                </ng-template>
                <div class="mt">
                    <mat-form-field class="w100">
                        <mat-label>Description</mat-label>
                        <textarea type="search" matInput formControlName="description"></textarea>
                    </mat-form-field>

                    <div class="fl quarter-form">
                        <div>
                            <mat-form-field>
                                <mat-label>Transport Mode</mat-label>
                                <mat-select formControlName="transportMode" (selectionChange)="chooseTransport($event)">
                                    <mat-option *ngFor="let enum of transportModes | enumpipe" [value]="enum.key">
                                        {{enum.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div [formGroup]="currencyGroup">
                            <mat-form-field>
                                <mat-label>Service Currency</mat-label>
                                <input type="search" matInput formControlName="name" [matAutocomplete]="currencyAuto">
                            </mat-form-field>
                            <mat-autocomplete #currencyAuto="matAutocomplete" [displayWith]="displayCurrencyWith"
                                (optionSelected)="chooseCurrency($event)">
                                <mat-option *ngFor="let country of filteredCurrencies | async | withCurrency"
                                    [value]="country">
                                    <img class="flag"
                                        src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                                    {{country.currencySymbol}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Carrier</mat-label>
                                <mat-select formControlName="transportproviderid">
                                    <!--<mat-option *ngFor="let enum of transportProviders | enumpipe" [value]="enum.key">
                                        {{enum.value}}
                                    </mat-option>-->
                                    <mat-option value="0">Any</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>{{transportMode.value==2?"Plane":"Ship"}}</mat-label>
                                <mat-select formControlName="vesselid">
                                    <!--<mat-option *ngFor="let enum of vessels | enumpipe" [value]="enum.key">
                                        {{enum.value}}
                                    </mat-option>-->
                                    <mat-option value="0">Any</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <h3 class="nm">Legs to include</h3>
                    <div class="fl third-form mb">
                        <div>
                            <mat-slide-toggle color="primary" formControlName="includeC2P">Collection to Port
                            </mat-slide-toggle>
                        </div>
                        <div>
                            <mat-slide-toggle color="primary" formControlName="includeP2P">Port to Port
                            </mat-slide-toggle>
                        </div>
                        <div>
                            <mat-slide-toggle color="primary" formControlName="includeP2D">Port to Delivery
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="fl third-form">
                        <div [formGroup]="originGroup">
                            <mat-form-field>
                                <mat-label>Origin Country</mat-label>
                                <input type="search" matInput formControlName="name" [matAutocomplete]="originAuto" autocomplete="origin-id">
                            </mat-form-field>
                            <mat-autocomplete #originAuto="matAutocomplete" [displayWith]="displayCountryWith"
                                (optionSelected)="chooseOrigin($event)">
                                <mat-option *ngFor="let country of filteredOrigins | async" [value]="country">
                                    <img class="flag"
                                        src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div [formGroup]="destinationGroup">
                            <mat-form-field>
                                <mat-label>Destination Country</mat-label>
                                <input type="search" matInput formControlName="name"
                                    [matAutocomplete]="destinationAuto" autocomplete="destination-id">
                            </mat-form-field>
                            <mat-autocomplete #destinationAuto="matAutocomplete" [displayWith]="displayCountryWith"
                                (optionSelected)="chooseDestination($event)">
                                <mat-option *ngFor="let country of filteredDestinations | async" [value]="country">
                                    <img class="flag"
                                        src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div [formGroup]="viaGroup">
                            <mat-form-field>
                                <mat-label>Via Country</mat-label>
                                <input type="search" matInput formControlName="name" [matAutocomplete]="viaAuto">
                            </mat-form-field>
                            <mat-autocomplete #viaAuto="matAutocomplete" [displayWith]="displayCountryWith"
                                (optionSelected)="chooseVia($event)">
                                <mat-option *ngFor="let country of filteredCountryVias | async" [value]="country">
                                    <img class="flag"
                                        src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div>
                        <h3 class="servicecharges">{{originName.value}} to {{destinationName.value}} <span
                                *ngIf="serviceVias && serviceVias.length>0">via </span><span class="svia"
                                *ngFor="let via of serviceVias.controls">{{via.value.Country.name}}</span></h3>
                    </div>

                    <div class="fl half-form">
                        <div class="f1">
                            <div *ngIf="originCountry.valid">
                                <div class="fl aic">
                                    <h3 class="nm">Origin {{transportMode.value==2?"Airports":"Ports"}}</h3>
                                </div>
                                <div class="fl">
                                    <div class="f1">
                                        <mat-form-field>
                                            <mat-label>{{transportMode.value==2?"Airport":"Port"}}</mat-label>
                                            <input type="text" matInput formControlName="originLocodeControl"
                                                [matAutocomplete]="originPortAuto" name="originport"
                                                autocomplete="origin-port">
                                        </mat-form-field>
                                        <mat-autocomplete #originPortAuto="matAutocomplete"
                                            [displayWith]="displayPortWith" (optionSelected)="choosePort($event,true)">
                                            <mat-option *ngFor="let locode of filteredOriginLocodes | async"
                                                [value]="locode">
                                                {{locode.nodiacritic}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div formArrayName="OriginServicePorts">
                                    <div *ngFor="let oc of originServicePorts.controls;let i = index;" class="fl">
                                        <div class="f1" title="{{oc.value.Locode.locode2}}">{{oc.value.Locode.nodiacritic}}</div>
                                        <div>
                                            <mat-icon class="small pointer"
                                                class="{{oc.value.partnersiteid?'traffic-green':'traffic-orange'}}"
                                                (click)="partner(oc.value)">{{oc.value.partnersiteid?'link':'link_off'}}
                                            </mat-icon>
                                        </div>
                                        <div>
                                            <mat-icon class="small pointer" color="error" (click)="removePort(i,true)">
                                                delete
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="f1">
                            <div *ngIf="destinationCountry.valid">
                                <div class="fl aic">
                                    <h3 class="nm">Destination {{transportMode.value==2?"Airports":"Ports"}}</h3>
                                </div>
                                <div class="fl">
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{transportMode.value==2?"Airport":"Port"}}</mat-label>
                                            <input type="text" matInput formControlName="destinationLocodeControl"
                                                [matAutocomplete]="destinationPortAuto">
                                        </mat-form-field>
                                        <mat-autocomplete #destinationPortAuto="matAutocomplete"
                                            [displayWith]="displayPortWith" (optionSelected)="choosePort($event,false)">
                                            <mat-option *ngFor="let locode of filteredDestinationLocodes | async"
                                                [value]="locode">
                                                {{locode.nodiacritic}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div formArrayName="DestinationServicePorts">
                                    <div *ngFor="let dc of destinationServicePorts.controls;let j = index;" class="fl">
                                        <div class="f1">{{dc.value.Locode.nodiacritic}}</div>
                                        <div>
                                            <mat-icon class="small pointer"
                                                class="{{dc.value.partnersiteid?'traffic-green':'traffic-orange'}}"
                                                (click)="partner(dc.value)">{{dc.value.partnersiteid?'link':'link_off'}}
                                            </mat-icon>
                                        </div>
                                        <div>
                                            <mat-icon class="small pointer" color="error" (click)="removePort(j,false)">
                                                delete
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="f1">
                            <div>
                                <div class="fl aic">
                                    <h3 class="nm">Via {{transportMode.value==2?"Airports":"Ports"}}</h3>
                                </div>
                                <div class="fl">
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{transportMode.value==2?"Airport":"Port"}}</mat-label>
                                            <input type="text" matInput formControlName="serviceViasLocodeControl"
                                                [matAutocomplete]="viaPortAuto">
                                        </mat-form-field>
                                        <mat-autocomplete #viaPortAuto="matAutocomplete" [displayWith]="displayPortWith"
                                            (optionSelected)="chooseViaPort($event)">
                                            <mat-option *ngFor="let locode of filteredViaLocodes | async"
                                                [value]="locode">
                                                {{locode.nodiacritic}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div formArrayName="ServiceVias">
                                    <div *ngFor="let dc of serviceVias.controls;let j = index;" class="fl">
                                        <div class="f1">{{dc.value.Country.name}}({{dc.value.Locode.nodiacritic}})</div>
                                        <div>
                                            <mat-icon class="small pointer" color="error" (click)="removeVia(j)">delete
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fl third-form mr">
                        <div>
                            <mat-form-field>
                                <mat-label>Transit Time (days)</mat-label>
                                <input type="number" matInput formControlName="transitTime" autocomplete="transit-time">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>
                                    {{transportMode.value==2?"Flight Frequency":"Sail Frequency"}}
                                </mat-label>
                                <mat-select formControlName="frequency" multiple [compareWith]="compareFreq"
                                    (selectionChange)="chooseFrequency($event)">
                                    <mat-option value="1">
                                        Sunday
                                    </mat-option>
                                    <mat-option value="2">
                                        Monday
                                    </mat-option>
                                    <mat-option value="3">
                                        Tuesday
                                    </mat-option>
                                    <mat-option value="4">
                                        Wednesday
                                    </mat-option>
                                    <mat-option value="5">
                                        Thursday
                                    </mat-option>
                                    <mat-option value="6">
                                        Friday
                                    </mat-option>
                                    <mat-option value="7">
                                        Saturday
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Quote Validity (days)</mat-label>
                                <input type="number" matInput formControlName="quoteValidity"
                                    autocomplete="quote-validity">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Customers
                </ng-template>
                <div class="mt">
                    <div class="fl half-form mb">
                        <div>
                            <mat-slide-toggle color="primary" formControlName="isPublic">Available to all customers
                            </mat-slide-toggle>
                        </div>
                        <div *ngIf="!serviceIsPublic.value">
                            <div class="fl">
                                <div class="f1">
                                    <mat-form-field>
                                        <mat-label>Service Customer</mat-label>
                                        <input type="search" matInput formControlName="serviceUserControl"
                                            [matAutocomplete]="serviceUserAuto">
                                    </mat-form-field>
                                    <mat-autocomplete #serviceUserAuto="matAutocomplete"
                                        [displayWith]="displayProviderWith" (optionSelected)="chooseUser($event)">
                                        <mat-option *ngFor="let company of filteredServiceUserCompanies | async"
                                            [value]="company.Provider">
                                            {{company.Provider.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div formArrayName="ServiceUsers">
                                <div *ngFor="let co of serviceUsers.controls;let i = index;" class="fl">
                                    <div class="f1">{{co.value.UserCompany.name}}</div>
                                    <div>
                                        <mat-icon class="small pointer" color="error" (click)="removeServiceUser(i)">
                                            delete
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Charges
                </ng-template>
                <div class="mt">
                    <div class="f1 fl fdc">
                        <div class="f1">
                            <div class="fl">
                                <h3 class="f1">{{transportMode.value==2?"Airport":"Port"}} to
                                    {{transportMode.value==2?"Airport":"Port"}} Rates</h3>
                                <div class="buttons">
                                    <button class="mr" type="button" mat-mini-fab aria-label="Add Rate" color="primary" title="Add Rate"
                                        (click)="addRate()" (dblclick)="addDummyRate()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <table class="rate c1lcnc" *ngIf="rates.length>0">
                                    <tr>
                                        <th>Type</th>
                                        <th>up to (kg)</th>
                                        <th>up to m<sup>3</sup></th>
                                        <th>Price per</th>
                                        <th>Cost Price</th>
                                        <th>List Price</th>
                                        <th>Margin %</th>
                                        
                                        <th *ngIf="showmore">Valid From</th>
                                        <th *ngIf="showmore">Valid To</th>
                                        <th *ngIf="showmore">Breaks Apply</th>
                                        <th *ngIf="showmore">m<sup>3</sup> Equiv</th>
                                        <th><mat-icon class="pointer" (click)="showmore=!showmore">{{showmore?'chevron_left':'chevron_right'}}</mat-icon></th>           
                                    </tr>
                                    <tbody *ngFor="let rate of rates" class="ratebody"> 
                                    <tr class="pointer" (click)="selectRate(rate)">
                                        <td title="{{rate.id}}">{{rate.name}}</td>
                                        <td><span *ngIf="rate.baseIncludesWeight>0">{{rate.baseIncludesWeight | number}}</span></td>
                                        <td><span *ngIf="rate.baseIncludesVolume>0">{{rate.baseIncludesVolume | number}}</span></td>
                                        <td>{{rate.priceperbreak?'band':'kg'}}</td>
                                        <td>{{rate.baseprice | currency:currencyCode}}</td>
                                        <td>{{rate.baseprice*1.1 | currency:currencyCode}}</td>
                                        <td>10%</td>
                                        
                                        <td *ngIf="showmore">{{rate.createdAt | date:'dd-MM-yyyy'}}</td>
                                        <td *ngIf="showmore">{{rate.expires | date:'dd-MM-yyyy'}}</td>
                                        <td *ngIf="showmore">{{rate.breakbarrierincludes?'<= next value':'< next value'}}</td>
                                        <td *ngIf="showmore">{{rate.volumeequivalent | number}}</td>
                                        <td></td>
                                        
                                    </tr>
                                    <tr *ngFor="let br of rate.RateBreaks" class="pointer" (click)="selectRate(rate)" >
                                        <td title="{{br.id}}"></td>
                                        <td><span *ngIf="br.maxweight>0">{{br.maxweight}}</span></td>
                                        <td><span *ngIf="br.maxvolume>0">{{br.maxvolume}}</span></td>
                                        <td>{{br.priceperbreak?'band':br.weightbreak==1?'kg':br.weightbreak+' kg'}}</td>
                                        <td>{{br.price | currency:currencyCode}}</td>
                                        <td>{{br.price*1.05 | currency:currencyCode}}</td>
                                        <td>5%</td>
                                        <td *ngIf="showmore"></td>
                                        <td *ngIf="showmore"></td>
                                        <td *ngIf="showmore"></td>
                                        <td *ngIf="showmore"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <p class="note" *ngIf="rates.length>0">Creating a new rate will make existing rates expire on the new rate's start date.</p>
                            <div class="fl">
                        
                                <div class="f1">
                                    <cs-rate-base-form *ngIf="selectedRate" [rategroup]="selectedRate" [editmode]="editmode" [unzoned]="true"></cs-rate-base-form>
                                </div>
                                <div class="f1">
                                    <cs-rate-breaks-form *ngIf="selectedRate" [rategroup]="selectedRate" [editmode]="editmode" [unzoned]="true"></cs-rate-breaks-form>
                                </div>
                            </div>
                        </div>
                        <div class="f1">
                            
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Surcharges
                </ng-template>
                <div>
                    <p *ngIf="!serviceForm.value.transportMode">Please select a transport mode on the info tab</p>
                    <cs-surcharge-container *ngIf="serviceForm.value.transportMode" [serviceForm]="serviceForm" [editmode]="true" [appliesto]="serviceForm.value.transportMode" [servicesurcharges]="servicesurcharges"></cs-surcharge-container>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Partners
                </ng-template>
                <ng-template matTabContent>
                    <cs-portpartners [serviceForm]="serviceForm" [editmode]="true"></cs-portpartners>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{transportMode.value==2?"Airport":"Port"}} Charges
                </ng-template>
                <ng-template matTabContent>
                    <cs-port-surcharges [serviceForm]="serviceForm" [editmode]="true"></cs-port-surcharges>
                  </ng-template>
            </mat-tab>
            <mat-tab *ngIf="serviceForm.value.includeC2P">
                <ng-template mat-tab-label>
                    Collection
                </ng-template>
                <ng-template matTabContent>
                    <cs-last-mile [title]="'Collection Rates'" [ports]="serviceForm.value.OriginServicePorts"></cs-last-mile>
                </ng-template>
            </mat-tab>
            <mat-tab *ngIf="serviceForm.value.includeP2D">
                <ng-template mat-tab-label>
                    Delivery
                </ng-template>
                <ng-template matTabContent>
                    <cs-last-mile [title]="'Delivery Rates'" [ports]="serviceForm.value.DestinationServicePorts"></cs-last-mile>
                </ng-template>
            </mat-tab>

        </mat-tab-group>
    </div>
    <div class="fl">
        
        <div class="hidden">
            <!--<button mat-raised-button class="bwip" (click)="logForm()" type="button">Log Form</button>-->
            <cs-objectifier [service]="serviceForm.value"></cs-objectifier>
        </div>
        <div class="f1"></div>
        <div class="fl">
            <button mat-raised-button color="warn" (click)="cancel()" class="mr" type="button">Cancel</button>
            <button mat-raised-button color="primary" type="submit" *ngIf="serviceForm.dirty" [disabled]="!serviceForm.valid">Save</button>
            <button mat-raised-button color="primary" (click)="done()" type="button" *ngIf="!serviceForm.dirty">OK</button>
        </div>
    </div>



</form>