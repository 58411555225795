<div class="fl">
    <div class="f1 pad">
        <cs-address-short [address]="quote.quoteInput.origin" [title]="'Origin'"></cs-address-short>
    </div>
    <div class="f1 pad">
        <cs-address-short [address]="quote.quoteInput.destination" [title]="'Destination'"></cs-address-short>
    </div>
    <div class="f2 fl fdc pad">
        <cs-quote-options-view [quoteOptions]="quote.quoteInput.quoteOptions" [customer]="customer">
        </cs-quote-options-view>
    </div>
</div>
<div class="fl fw pad">
    <div class="item" *ngFor="let quoteitem of quote.quoteInput.quoteItems; let i = index;">
        <cs-size-view [size]="quoteitem" [customer]="true" [index]="i+1"></cs-size-view>
    </div>
</div>  
<div>
    <cs-pure-quote [portoption]="portoption" [currency]="quote.currency"></cs-pure-quote>
</div>
<div>
    <table>
        <ng-container *ngIf="notes">
        <tr>
            <td>
                <h4>Notes:</h4>
            </td>
        </tr>
        <tr>
            <td [innerHTML]="notes.html | safehtml">
                
            </td>
        </tr>
    </ng-container>
    <ng-container *ngIf="terms">
        <tr>
            <td>
                <h4>Terms:</h4>
            </td>
        </tr>
        <tr>
            <td [innerHTML]="terms.html | safehtml">
                
            </td>
        </tr>
    </ng-container>
    </table>
</div>