<div class="f1 fl fdc pad ovh pos-rel">
    <h3>Margin Management</h3>
    <div class="help-note">
        <mat-icon *ngIf="!showhelp" (click)="showhelp = true" class="help-icon">help</mat-icon>
        <div *ngIf="showhelp" class="help-content">
            <div class="fl">
                <div class="f1">
                    <h3>Margins</h3>
                </div>
                <div>
                    <mat-icon (click)="showhelp = false" class="warn pointer">close</mat-icon>
                </div>
            </div>

            <p>Margins work in a hierarchical fashion.</p>
            <p>Your Global Margin will be added to every provider charge unless you have added a margin more
                specifically (as below).</p>
            <p>Global Provider margins can be set for each service provider and will override global margins for
                every service they provide.</p>
            <p>Semi-Global Margins (which will override global margins) can be set on</p>
            <ul>
                <li>Service Charges</li>
                <li>Delivery & Collection Rates</li>
                <li>Surcharges
                    <ul>Service Surcharges</ul>
                    <ul>Delivery & Collection Surcharges</ul>
                    <ul>Port & Airport, Road & Rail Hub Surcharges</ul>
                    <ul>Custom Surcharges</ul>
                </li>
            </ul>
            <p>Specific Margins may also be added on each individual charge, overriding the list price for that
                charge.</p>
            <p>Margin additions essentially create the List Price for the services you offer to your customers.</p>

            <h3>Discounts</h3>
            <p>Discounts work in the opposite way for individual customers or groups of customers.</p>

            <p>Once you have generated a Quotation, the discount for each charge can then be overriden before the
                quote is sent to the customer.</p>
        </div>

    </div>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                Provider Margins
            </ng-template>
            <ng-template matTabContent>
                <cs-provider-margins></cs-provider-margins>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Customer Discounts
            </ng-template>
            <ng-template matTabContent>
                <cs-discounts></cs-discounts>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Quote Surcharges
            </ng-template>
            <ng-template matTabContent>
                <cs-quote-surcharges></cs-quote-surcharges>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

</div>