import { Component, OnInit, Input } from '@angular/core';
import { QuoteOption } from 'src/app/models/ui';

@Component({
  selector: 'cs-quote-options-view',
  templateUrl: './quote-options-view.component.html',
  styleUrls: ['./quote-options-view.component.scss']
})
export class QuoteOptionsViewComponent implements OnInit {

  @Input() quoteOptions:QuoteOption[];
  @Input() customer = false;

  constructor() { }

  ngOnInit(): void {
    let bob=1;
  }

}
