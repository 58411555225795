import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanySettingType, NewCompanySettingType } from 'src/app/models/user';
import { SettingEditor } from '../setting-editor';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-string-chooser',
  templateUrl: './string-chooser.component.html',
  styleUrls: ['./string-chooser.component.scss']
})
export class StringChooserComponent implements SettingEditor {

  @Input() data:any;
  public enum:any;
  public settingvalue = "";
  public settingtype:CompanySettingType;
  public editmode = false;
  
  constructor(public events:EventsService) { }

  ngOnInit(): void {
    this.enum = this.data.enum;
    this.settingtype = this.data.settingtype;
    if(this.settingtype.Setting){
      this.settingvalue = this.settingtype.Setting.setting;
    }
  }

  saveChanges(){
    let newsettingtype = new NewCompanySettingType();
    newsettingtype.newsetting = this.settingvalue;
    newsettingtype.settingtype = this.settingtype;
    this.events.companysettingchange.emit(newsettingtype);
  }
  cancel(){
    this.editmode = false;
    if(this.settingtype.Setting){
      this.settingvalue = this.settingtype.Setting.setting;
    }
  }

}
