import { Pipe, PipeTransform } from '@angular/core';
import { Region } from '../models/rates';

@Pipe({
  name: 'driving'
})
export class DrivingPipe implements PipeTransform {

  transform(value: Region[]): Region[] {
    let drivers = value.filter(v=>v.RegionDistancer);
    return drivers;
  }

}
