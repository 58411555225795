import { Component, OnInit, Input } from '@angular/core';
import { Rate, RateBreak } from 'src/app/models/rates';
import { Country } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';
import { RateBreakIndex } from 'src/app/models/ui';

@Component({
  selector: 'cs-rate-breaks',
  templateUrl: './rate-breaks.component.html',
  styleUrls: ['./rate-breaks.component.scss']
})
export class RateBreaksComponent implements OnInit {

  @Input() rate: Rate;
  @Input() currencies: Country[];
  @Input() editmode: boolean = false;
  @Input() index: number = 0;
  @Input() unzoned:boolean = false;

  public applytoall = true;

  public currencySymbol = "£";
  public currencyCode = "GBP";

  constructor(public events: EventsService) {
    this.events.addBreak.subscribe((rate: Rate) => {
      if (rate.regionid != this.rate.regionid) {
        let newbreak = new RateBreak();
        newbreak.rateid = this.rate.id;
        newbreak.breakbarrierincludes = this.rate.breakbarrierincludes;
        newbreak.priceperbreak = this.rate.priceperbreak;
        this.rate.RateBreaks.push(newbreak);
      }
    })
    this.events.changeBreak.subscribe((rbi: RateBreakIndex) => {
      if (this.rate.RateBreaks[rbi.index]) {
        let rb = this.rate.RateBreaks[rbi.index];
        rb.maxvolume = rbi.rateBreak.maxvolume;
        rb.maxweight = rbi.rateBreak.maxweight;
        rb.minvolume = rbi.rateBreak.minvolume;
        rb.minweight = rbi.rateBreak.minweight;
        rb.volumebreak = rbi.rateBreak.volumebreak;
        rb.weightbreak = rbi.rateBreak.weightbreak;
        rb.breakbarrierincludes = rb.breakbarrierincludes;
      }
    })

  }

  ngOnInit(): void {
    if (this.rate) {
      if (this.currencies && this.rate.currency) {
        let currencyco = this.currencies.filter(c => c.id == this.rate.currency)[0];
        this.currencySymbol = currencyco.currencySymbol;
        this.currencyCode = currencyco.currencyCode;
      }
    }
  }
  check(e,rb){
    console.log(e,rb);
  }
  addBreak() {
    let rb = new RateBreak();
    rb.rateid = this.rate.id;
    rb.breakbarrierincludes = this.rate.breakbarrierincludes;
    rb.priceperbreak = this.rate.priceperbreak;
    this.rate.RateBreaks.push(rb);
  }
  propogateBreakChange(rateBreak?: RateBreak, index?: number) {
    if (this.applytoall) {
      if (!rateBreak) {
        let eachindex=0;
        this.rate.RateBreaks.forEach(br=>{
          this.events.changeBreak.emit({index:eachindex,rateBreak:br});
          eachindex++;
        })
      }
      else {
        this.events.changeBreak.emit({ index: index, rateBreak: rateBreak });
      }
    }

  }

}
