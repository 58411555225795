<div class="fl aic">
    <div class="field-title">{{settingtype.description}}</div>
    <div class="setting-thumbnail" (dblclick)="editmode=true">
        <img *ngIf="settingtype.Setting && settingtype.Setting.setting" src="{{env.host}}resources{{settingtype.Setting.setting}}" alt="logo">
    </div>
    <input type="file" id="fileInputField" *ngIf="editmode">
    <div *ngIf="editmode" class="fl">
    <mat-icon class="pointer" (click)="saveChanges()" title="update" aria-label="update" color="primary">check</mat-icon>
    <mat-icon class="pointer" (click)="cancel()" title="cancel" aria-label="cancel update" color="error">cancel</mat-icon>
    </div>
</div>

