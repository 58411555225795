<div class="f1 fl ovh">
    <div class="f1 fl fdc ovh" id="regiontree">
        <div class="fl rateform mt aic">
            <h3>Rate Editor - {{view.name}}</h3>
            <div class="f1 ml">
                <div class="fl aic jc">
                    <button class="mr" type="button" mat-mini-fab aria-label="Add Rate" color="primary" title="Add Rate"
                        (click)="addRate()" (dblclick)="addDummyRate()"
                        [disabled]="!selectedVersion || showimport || showcard">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button class="mr" type="button" mat-mini-fab aria-label="Edit Rate" [color]="editmode?'accent':'primary'"  [ngClass]="{'active':editmode}" title="Edit Rate"
                        (click)="editRate()" [disabled]="!selectedEnvelope || showimport || showcard">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button class="mr" type="button" mat-mini-fab aria-label="Save Rate" color="primary" title="Save Rate"
                        (click)="saveRate()" [disabled]="!selectedEnvelope || showimport || showcard">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button class="mr" *ngIf="rate && rate.id" type="button" mat-mini-fab aria-label="Copy Rate"
                        color="primary" title="Copy Rate" (click)="copyRate()" [disabled]="showimport || showcard">
                        <mat-icon>file_copy</mat-icon>
                    </button>
                    <button class="mr" *ngIf="selectedEnvelope" type="button" mat-mini-fab aria-label="Show rate card" color="primary"
                        title="Show rate card" (click)="showCard()">
                        <mat-icon>table_view</mat-icon>
                    </button>
                    <button class="mr" *ngIf="selectedVersion && selectedMode>=0" type="button" mat-mini-fab
                        aria-label="Import Rate" color="primary" title="Import Rates" (click)="showImport()">
                        <mat-icon>cloud_upload</mat-icon>
                    </button>
                    
                </div>
            </div>
            <!--region defines the basesiteid
            <mat-form-field>
                <mat-label>Home Region</mat-label>
                <mat-select [(ngModel)]="homeSite.id" (selectionChange)="changeHome($event)"
                    [disabled]="showimport || showcard">
                    <mat-option *ngFor="let site of data.Company.Sites" [value]="site.id">
                        {{site.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        -->
        <cs-company-search #cosearch [host]="'rate-editor-regions'"></cs-company-search>    
        <mat-form-field>
                <mat-label>Transport Mode</mat-label>
                <mat-select name="transportmode" ngDefaultControl [(ngModel)]="selectedMode" (selectionChange)="changeMode($event)"
                    [disabled]="showimport || showcard">
                    <mat-option *ngFor="let type of transportmodes | enumpipe" [value]="type.key">
                        {{type.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!world">
                <mat-label>Region</mat-label>
                <mat-select name="selectedregion" ngDefaultControl [(ngModel)]="selectedRegion" (selectionChange)="changeRegion($event)"
                    [disabled]="showimport || showcard">
                    <mat-option *ngFor="let region of regions" [value]="region">
                        {{region.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Version</mat-label>
                <mat-select name="selectedversion" ngDefaultControl [(ngModel)]="selectedVersion" (selectionChange)="changeVersion($event)"
                    [disabled]="showimport || showcard">
                    <mat-option *ngFor="let region of versions" [value]="region">
                        {{region.code}} - {{region.name}} - {{region.BaseSite?region.BaseSite.name:''}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Priority</mat-label>
                <mat-select name="selectedpriority" ngDefaultControl [(ngModel)]="selectedPriority" (selectionChange)="changePriority($event)"
                    [disabled]="showimport || showcard">
                    <mat-option *ngFor="let priority of data.priorities" [value]="priority">
                        {{priority.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <p class="note" *ngIf="!selectedVersion">Please add regional zones before creating rates.</p>
        <div class="f1 fl fdc ovh">
            <div *ngIf="envelopes && envelopes.length>0">
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Version</th>
                        <th>Expires</th>
                        <th>Active</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let r of envelopes" (click)="selectEnvelope(r)" class="pointer">
                        <td class="tac">{{r.name}}</td>
                        <td class="tac">{{r.version || 1}}</td>
                        <td class="tac">{{r.createdAt | date:'dd-MM-yyyy'}}</td>
                        <td class="tac">0 quotes</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="selectedEnvelope" class="fl fdc ovh">
                <div class="fl">
                    <mat-form-field class="">
                        <mat-label>Rate Name</mat-label>
                        <input matInput placeholder="Rate name" name="ratename" type="text" ngDefaultControl [(ngModel)]="selectedEnvelope.name">
                    </mat-form-field>
                </div>
                <mat-tab-group (selectedTabChange)="changeZone($event)" [selectedIndex]="zoneindex" ngDefaultControl class="mt" >
                    <mat-tab *ngFor="let rate of selectedEnvelope.Rates;let i = index">
                        <ng-template mat-tab-label>
                            {{rate.name}}
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="fl ovh">
                            <div class="mt f1">
                                <cs-rate-base *ngIf="zoneindex==i" [rate]="rate" [editmode]="editmode" [index]="i"></cs-rate-base>
                            </div>
                            <div class="mt fl fdc f1 ovh">
                                <cs-rate-breaks *ngIf="zoneindex==i" [rate]="rate" [editmode]="editmode" [index]="i"></cs-rate-breaks>
                            </div>    
                            </div>
                            
                        </ng-template>
                    </mat-tab>                       
                </mat-tab-group>
            </div>
            <!--
            <div *ngIf="rate" class="fl mt rateform scrollable-tree">
                <div class="f1">
                    <div class="fl aic">
                        <h3 *ngIf="rate.id">Rate {{rate.name}}</h3>
                        <mat-form-field *ngIf="!rate.id" class="">
                            <mat-label>Rate Name</mat-label>
                            <input matInput placeholder="Rate name" type="text" [(ngModel)]="rate.name">
                        </mat-form-field>

                    </div>

                    <div *ngIf="rate.id">
                        <cs-rate-base [rate]="rate"></cs-rate-base>
                        <div class="fl mt">
                            <div class="f1">
                                <h4>Rate Breaks</h4>

                                <div *ngFor="let rb of rate.RateBreaks" class="fl aic">
                                    <div class="field-title">For each :</div>
                                    <div class="field-value">{{rb.weightbreak}} kg</div>
                                    <div class="field-title">or :</div>
                                    <div class="field-value super">{{rb.volumebreak}} m<sup>3</sup></div>
                                    <div class="field-title">up to <span *ngIf="rb.breakbarrierincludes">and
                                            including</span>:</div>
                                    <div class="field-value">{{rb.maxweight}} kg</div>
                                    <div class="field-title">or:</div>
                                    <div class="field-value super">{{rb.maxvolume}} m<sup>3</sup></div>
                                    <div class="field-title">Price:</div>
                                    <div class="field-value">{{rb.price | currency:currencyCode}} <span
                                            *ngIf="!rb.priceperbreak">per kg</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="f1 mt">
                            <h4>Band Premiums (percentage, value)</h4>
                            <div class="fl">
                                <div *ngFor="let bp of rate.BandPremia">
                                    <div class="field-title">Region {{bp._regioncode}}:</div>
                                    <div class="field-value">{{(bp.premiumpercent-1)*100 | number}} %
                                        {{bp.premiumvalue | currency:currencyCode}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fl tal ma mf-date formpadd" *ngIf="!rate.id">
                        <mat-form-field>
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="rate.createdAt">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Expiry Date</mat-label>
                            <input matInput [matDatepicker]="picker2" [(ngModel)]="rate.expires">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="fl formpadd" *ngIf="!rate.id">
                        <mat-form-field class="">
                            <mat-label>Minimum Charge</mat-label>
                            <input matInput placeholder="Fixed Price" type="number" [(ngModel)]="rate.baseprice">
                        </mat-form-field>

                        <mat-form-field class="">
                            <mat-label>Includes (kg)</mat-label>
                            <input matInput placeholder="Includes weigth" type="number"
                                [(ngModel)]="rate.baseIncludesWeight">
                        </mat-form-field>

                        <mat-form-field class="">
                            <mat-label>Includes (m<sup>3</sup>)</mat-label>
                            <input matInput placeholder="Includes weigth" type="number"
                                [(ngModel)]="rate.baseIncludesVolume">
                        </mat-form-field>

                    </div>

                    <div *ngIf="!rate.id">
                        <h3>Breaks</h3>
                        <div class="mt" *ngFor="let rb of rate.RateBreaks;let r = index;">
                            <div>
                                <p>Then for every subsequent: </p>
                                <div class="fl aic">
                                    <mat-form-field class="">
                                        <mat-label>kg (or fraction of)</mat-label>
                                        <input matInput placeholder="Weight Break" type="number"
                                            [(ngModel)]="rb.weightbreak">
                                    </mat-form-field>

                                    <span>or</span>
                                    <mat-form-field class="">
                                        <mat-label>m<sup>3</sup> or fraction of</mat-label>
                                        <input matInput placeholder="Volume Break" type="number"
                                            [(ngModel)]="rb.volumebreak">
                                    </mat-form-field>

                                    <mat-form-field class="">
                                        <mat-label>Price per unit</mat-label>
                                        <input matInput placeholder="Price" type="number" [(ngModel)]="rb.price">
                                    </mat-form-field>

                                </div>
                                <div class="fl aic">
                                    <mat-form-field class="">
                                        <mat-label>up to kg</mat-label>
                                        <input matInput placeholder="Weight Max" type="number"
                                            [(ngModel)]="rb.maxweight" (change)="buildAdjustmentGrid()">
                                    </mat-form-field>
                                    <mat-form-field class="">
                                        <mat-label>or m<sup>3</sup></mat-label>
                                        <input matInput placeholder="Volume Max" type="number"
                                            [(ngModel)]="rb.maxvolume" (change)="buildAdjustmentGrid()">
                                    </mat-form-field>
                                    <div class="f1 fl">
                                        <div class="f1 aic jc">
                                            <mat-slide-toggle color="primary" [(ngModel)]="rb.breakbarrierincludes">
                                                <span class="sym">{{rb.breakbarrierincludes?'&lt;':'&le;'}}</span>
                                            </mat-slide-toggle>
                                        </div>
                                        <div class="f1 aic jc">
                                            <mat-slide-toggle color="primary" [(ngModel)]="rb.priceperbreak">
                                                <span>{{rb.priceperbreak?'per break':'per kg'}}</span>
                                            </mat-slide-toggle>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div><button type="button" mat-mini-fab aria-label="Add Break" color="primary" title="Add Break"
                                (click)="addBreak()">
                                <mat-icon>add</mat-icon>
                            </button> </div>
                    </div>
                </div>
                <div class="f1" *ngIf="!rate.id && rate.BandPremia && rate.BandPremia.length>1">
                    <h3 (click)="logbps()">Band Premiums</h3>
                    <p>(setting <i>Apply to All (%)</i> to 5 will add 5% to region 1, 10% to region 2 etc.)</p>
                    <div *ngFor="let pb of rate.BandPremia;let bp = index;">
                        <div class="fl">
                            <mat-form-field class="">
                                <mat-label>{{bp==0?"Apply to All (%)":"Band "+bp+ " (%)"}}</mat-label>
                                <input matInput placeholder="Premium %" type="number" [(ngModel)]="pb.premiumpercent"
                                    (change)="bandValueChange($event,0,bp)">
                            </mat-form-field>

                            <mat-form-field class="">
                                <mat-label>
                                    {{bp==0?"Apply to All ("+currencyString+")":"Band "+bp+" ("+currencyString+")"}}
                                </mat-label>
                                <input matInput placeholder="Premium £" type="number" [(ngModel)]="pb.premiumvalue"
                                    (change)="bandValueChange($event,1,bp)">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>
<div class="rate-card-overlay" *ngIf="showcard && selectedVersion && selectedMode>=0">
    <div class="ratecard ovh fl fdc">
        <cs-rate-card-editor [envelope]="selectedEnvelope">
        </cs-rate-card-editor>
    </div>
</div>
<div class="rate-import-overlay" *ngIf="showimport && selectedVersion && selectedMode>=0">
    <div class="ratecard ovh fl fdc">
        <cs-rate-importer [region]="selectedVersion" [transportmode]="selectedMode" [priority]="selectedPriority">
        </cs-rate-importer>
    </div>
</div>
