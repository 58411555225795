<div class="fl f1 pad ovh mt">
    <mat-tab-group #ratestab (selectedTabChange)="viewChange($event)" [(ngModel)]="selectedView" ngDefaultControl
        class="mt">
        <mat-tab *ngFor="let view of views">
            <ng-template mat-tab-label>
                {{view.name}}
            </ng-template>
            <ng-template matTabContent>
                <cs-rate-editor-provider  [view]="view"></cs-rate-editor-provider><!--*ngIf="showrates && view.id<2"-->
            </ng-template>
        </mat-tab>

    </mat-tab-group>
</div>