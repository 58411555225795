<div class="fl">
    <mat-form-field>
        <mat-label>{{title}}</mat-label>
        <mat-select [(ngModel)]="settingvalue" (selectionChange)="saveChanges($event)">
            <mat-option *ngFor="let type of enum | enumpipe" [value]="type.key">
                {{type.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
