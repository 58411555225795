import { Component, OnInit, Input } from '@angular/core';
import { Quotation } from 'src/app/models/ui';

@Component({
  selector: 'cs-step-container',
  templateUrl: './step-container.component.html',
  styleUrls: ['./step-container.component.scss']
})
export class StepContainerComponent implements OnInit {
  
  @Input() quote:Quotation;
  constructor() { }

  ngOnInit(): void {
  }

}
