<div class="fl fdc h100">
    <div class="f1 fl fdc">
        <div class="fl aic">
            <button type="button" mat-mini-fab aria-label="add folder" color="primary" title="Add Document Group">
                <mat-icon (click)="addDocumentGroup()">add</mat-icon>
            </button>
            <div class="f1">
                
            </div>
            <h3 *ngIf="selectedDocument">{{selectedDocument.name}}</h3>
        </div>

        <div class="document-group-wrapper">
            <div class="document-group-control">
                <div *ngIf="documentGroups" class="fl fdc mt">

                    <cs-document-group *ngFor="let documentGroup of documentGroups" [documentGroup]="documentGroup">
                    </cs-document-group>
                </div>
            </div>
            <div class="document-editor">
                <div class="fake-quill" *ngIf="!selectedDocument">
                    <div>Select a document to edit it..</div>
                </div>
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Rich Text
                        </ng-template>
                        <div>
                            <quill-editor *ngIf="selectedDocument" [(ngModel)]="selectedDocument.html"
                                (onEditorCreated)="getEditorInstance($event)" (onEditorChanged)="checker($event)">
                            </quill-editor>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="selectedDocument">
                        <ng-template mat-tab-label>
                            Raw HTML
                        </ng-template>
                        <div>
                            <textarea class="rawhtml" [(ngModel)]="selectedDocument.html"></textarea>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <input type="file" id="fileInputField" class="hidden">
                <div class="fl mt" *ngIf="selectedDocument">

                    <div class="fl f1 fw">
                        <a *ngFor="let field of docfields"
                            (click)="addField(field.field)" class="quill-field">{{field.field}}</a>
                    </div>
                    <button class="divbutton" mat-raised-button color="primary" (click)="saveDoc()">Save</button>
                </div>
            </div>


        </div>
    </div>
</div>