<div *ngFor="let band of bands">
    <div class="{{selectedBand && band.regionid==selectedBand.regionid?'rate-container':''}}">
    <div class="fl aic" hover-class="show-add">
        <mat-icon (click)="clickFolder(band)" [ngClass]="['pointer folder',band.Children && band.Children.length>0?'active':'']">{{band._folderOpen?'folder_open':'folder'}}</mat-icon>
        <div class="mr title" title="{{band.regionid}}">{{band.name}}</div>
        <mat-icon class="add-child" color="primary" (click)="addRate(band)" title="add a rate to this band">add</mat-icon>
        <mat-icon color="warn" class="pointer" title="close rate editor" *ngIf="selectedBand && band.regionid==selectedBand.regionid" (click)="selectedBand=null">highlight_off</mat-icon>
        <!--<mat-icon *ngIf="bands.length>1" class="add-child" color="primary" (click)="addRateBands(band)" title="add a rate to all bands">arrow_downward</mat-icon>
        <mat-icon *ngIf="priorities>1" class="add-child" color="primary" (click)="addRatePriorities(band)" title="add a rate to all priorities">arrow_forward</mat-icon>
        <mat-icon *ngIf="priorities>1 && bands.length>1" class="rot90 add-child" color="primary" (click)="addRate3D(band)" title="add a rate to all bands and all priorities">call_split</mat-icon>-->
    </div>
    <form>
        <div class="fl">
            <!--FUCK UP - passing the same rate form to each rate band.-->
            <div class="f1">
                <cs-rate-base-form *ngIf="selectedBand && band.regionid==selectedBand.regionid" [rategroup]="rateform"
                    [editmode]="true"></cs-rate-base-form>
            </div>
            <div class="f1">
                <cs-rate-breaks-form *ngIf="selectedBand && band.regionid==selectedBand.regionid" [rategroup]="rateform"
                    [editmode]="true"></cs-rate-breaks-form>
            </div>
        </div>
    </form>
    <table class="rate c1lcnc" *ngIf="band.Rates && band.Rates.length>0">
        <tr class="rate-header-row">
            <th>Type</th>
            <th>up to</th>
            <th>up to</th>
            <th>Price per</th>
            <th>Cost Price</th>
            <th>Minimum</th>
            <th>List Price</th>
            <th>Margin %</th>
            
            <th *ngIf="showmore">Valid From</th>
            <th *ngIf="showmore">Valid To</th>
            <th *ngIf="showmore">Breaks Apply</th>
            <th *ngIf="showmore">m<sup>3</sup> Equiv</th>
            <th><mat-icon class="pointer" (click)="showmore=!showmore">{{showmore?'chevron_left':'chevron_right'}}</mat-icon></th>           
        </tr>
        <tbody *ngFor="let rate of band.Rates" class="ratebody">
        <tr class="pointer" (click)="editRate(rate,band)">
            <td title="{{rate.id}}">{{rate.RateBracket?rate.RateBracket.name:rate.name}}</td>
            <td><span *ngIf="rate.baseIncludesWeight>0">{{rate.baseIncludesWeight | number}}</span></td>
            <td><span *ngIf="rate.baseIncludesVolume>0">{{rate.baseIncludesVolume | number}}</span></td>
            <td>{{rate.priceperbreak?'fixed':'kg'}}</td>
            <td>{{rate.baseprice | currency:rate.Currency.currencyCode}}</td>
            <td>{{rate.minimum||0 | currency:rate.Currency.currencyCode}}</td>
            <td>{{rate._list | currency:rate.Currency.currencyCode}}</td>
            <td>{{rate._totalmargin | number}}%</td>
            
            <td *ngIf="showmore">{{rate.createdAt | date:'dd-MM-yyyy'}}</td>
            <td *ngIf="showmore">{{rate.expires | date:'dd-MM-yyyy'}}</td>
            <td *ngIf="showmore">{{rate.breakbarrierincludes?'<= next value':'< next value'}}</td>
            <td *ngIf="showmore">{{rate.volumeequivalent | number}}</td>
            <td></td>
            
        </tr>
        <tr *ngFor="let br of rate.RateBreaks" class="pointer" (click)="editRate(rate,band)" >
            <ng-container *ngIf="br.price && br.price>0">
            <td title="{{br.id}}"></td>
            <td><span *ngIf="br.maxweight>0">{{br.maxweight}}</span></td>
            <td><span *ngIf="br.maxvolume>0">{{br.maxvolume}}</span></td>
            <td>{{br.priceperbreak?'then each':br.weightbreak==1?'then per kg':'then per '+br.weightbreak+' kg'}}</td>
            <td>{{br.price | currency:rate.Currency.currencyCode}}</td>
            <td></td>
            <td>{{br._listprice | currency:rate.Currency.currencyCode}}</td>
            <td>{{br._totalmargin | number}}%</td>
            <td *ngIf="showmore"></td>
            <td *ngIf="showmore"></td>
            <td *ngIf="showmore"></td>
            <td *ngIf="showmore"></td>
            <td></td>
            </ng-container>
        </tr>
    </tbody>
    </table>
</div>
    <div *ngIf="band.Children && band._folderOpen" class="tabbed">    
        <cs-rate-list-form [bands]="band.Children" [rateform]="rateform" [priority]="priority" [priorities]="priorities"></cs-rate-list-form>
    </div>
    

    <!--
    <div *ngFor="let rate of band.RateList" class="fl">
        <div class="f1">
        <div class="fl f1 aic">
            <div class="fl">
                <div class="field-title">Start Date</div>
                <div class="field-value">{{rate.createdAt | date:'dd-MM-yyyy'}}</div>
                <div class="field-title">Expiry Date</div>
                <div class="field-value">{{rate.expires | date:'dd-MM-yyyy'}}</div>
            </div>
            <div class="fl aic">
                <div class="field-title">Minimum Charge</div>
                <div class="field-value">{{rate.baseprice | currency:currencyCode}}</div>
                <div class="field-title">Which includes</div>
                <div class="field-value">{{rate.baseIncludesWeight | number}} kg</div>
                <div class="field-title">and </div>
                <div class="field-value super">{{rate.baseIncludesVolume | number}} m<sup>3</sup></div>
            </div>
            <div class="fl aic">
                <div class="field-title">Pricing per</div>
                <div class="field-value">{{rate.priceperbreak?'band':'kg'}}</div>
                <div class="field-title">Pricing applies</div>
                <div class="field-value">
                    {{rate.breakbarrierincludes?'up to and including next value':'up to but not including next value'}}
                </div>
            </div>
            <div class="fl aic">
                <div class="field-title">Volume equivalent</div>
                <div class="field-value">{{rate.volumeequivalent | number}}</div>
            </div>
        </div>
        <div class="fl f1 aic">
            <div *ngFor="let br of rate.RateBreaks" class="fl break">
                <div class="field-title">for each </div>
                <div class="field-value">{{br.weightbreak}} {{br.priceperbreak?'band':'kg'}}</div>
                <div class="field-title">up to </div>
                <div class="field-value">{{br.maxweight}} kg</div>
                <div class="field-title">price </div>
                <div class="field-value">{{br.price | currency:currencyCode}}</div>
            </div>
        </div>
    </div>
        <div>
            <mat-icon class="mr" color="primary" (click)="editRate(rate,band)">edit</mat-icon>
        </div>
    </div>-->
    
</div>