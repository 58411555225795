import { Component, ViewChild, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Company, CompanyAssociation, StatusMessage, User, Site, Address } from 'src/app/models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CompanyComponent } from 'src/app/dialogs/company/company.component';
import { ToolsService } from 'src/app/services/tools.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import moment from 'moment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { EventsService } from 'src/app/services/events.service';
import { Service } from 'src/app/models/data';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'cs-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SettingsComponent implements AfterViewInit {

  //displayedColumns: string[] = ['Provider.id', 'Provider.name', 'Provider.companytypeid', 'Provider.companystatusid', 'Settings'];
  customers;
  company: Company;
  selectedUser: User;
  isLoadingResults = true;
  start = moment();
  timer: number = 0;
  services: Service[];

  locodes: any;

  selectedUserForm: FormGroup;

  @ViewChildren("settingstab") tabs:QueryList<MatTabGroup>;

  get email() {
    return this.selectedUserForm.get("email");
  }
/*
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
*/
  constructor(private route: ActivatedRoute,public data: DataService, public dialog: MatDialog, private tools: ToolsService, private formtools: FormtoolsService, public ref: ChangeDetectorRef, public events: EventsService) {
    data.auth.currentroute = "Settings - Providers";
    
    this.selectedUserForm = formtools.createUserFormGroup();
  }

  ngAfterViewInit() {
    this.route.params.subscribe((params:Params)=>{
      let index = params['tab'];
      if(index){
        this.tabs.first.selectedIndex = index;
        this.changeTab(index);
      }
      
    })
    
    /* moved to provider component
    
    if (this.data.auth.user.id > 0) {
      this.loadCustomers();
    }

    this.data.auth.userReady.subscribe(() => {
      this.loadCustomers();
    })*/
    if (!this.data.auth.user.token || this.data.auth.user.token.length==0) {
      this.data.auth.userReady.subscribe(() => {
        

      })
    }
  }

  changeTab(index: number) {
    this.events.settingsTabChange.emit(index);
    this.data.auth.currentroute = "Settings - "+this.data.settingstabs[index];
    if (index == 4) {
      this.loadServices();
    }
  }
/*
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customers.filter = filterValue.trim().toLowerCase();
  }
  getProperty = (obj, prop) => {
    //give a co assoc obj, and property "Provider.name"
    let array = prop.split('.'); //[Customer,name];
    let depth = 0;
    let valobj = obj[array[depth]];
    while (depth < array.length - 1) {
      depth++;
      valobj = valobj[array[depth]];
    }
    return valobj;
  }

  loadCustomers() {
    this.data.listCustomers().subscribe((message: StatusMessage) => {
      this.data.companies = message.message;
      let co = this.data.auth.user.Site.companyid;
      let me = this.data.companies.filter((c) => c.Provider.id == co);
      if (me.length > 0) {
        this.company = me[0].Provider;
      };
      this.customers = new MatTableDataSource(this.data.companies);
      this.customers.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.customers.filterPredicate = (data, filter: string) => this.tools.filterPredicate(data, filter);
      this.customers.sort = this.sort;
      this.customers.paginator = this.paginator;
      this.isLoadingResults = false;
      this.timer = moment().diff(this.start);
      this.events.companiesloaded.emit();


    }, err => {
      this.tools.gracefulError(err);
      this.isLoadingResults = false;
    })
  }
  editCompany(element: CompanyAssociation) {

    const dialogRef = this.dialog.open(CompanyComponent, {
      width: 'auto',
      data: { Customer: element.Provider }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.Provider) {
          element = result as CompanyAssociation;
          let data = this.customers.data;
          data.unshift(element);
          this.table.dataSource = data;
        }
        else element.Provider = result;
        this.table.renderRows();
      }
    });

  }
  addCompany() {
    let coAssoc = new CompanyAssociation();
    coAssoc.companyid = this.company.id;
    let co = new Company();
    co.name = "New Company";
    co.recordStatus = 0;
    co.CompanyStatus = this.data.companyStatuses[0];
    co.companystatusid = co.CompanyStatus.id;
    co.CompanyType = this.data.companyTypes[0];
    co.companytypeid = co.CompanyType.id;
    co._isDirty = true;
    let site = new Site();
    site.recordStatus = 0;
    site.SiteType = this.data.siteTypes[0];
    site.sitetypeid = site.SiteType.id;
    site.name = "Head Office";
    site._isDirty = true;
    site.Users = [];
    site.Invitations = [];
    let address = new Address();
    address.recordStatus = 0;
    address._isDirty = true;
    site.Address = address;
    co.Sites = [site];
    coAssoc.Provider = co;
    this.customers.data.unshift(coAssoc);
    this.table.renderRows();
    this.editCompany(this.customers.data[0]);

    --handled elsewhere
    this.data.createCompany(coAssoc).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.customers.unshift(message.message);
        this.table.renderRows();
      }
      else this.tools.gracefulError(message.message);
    },err=>{
      this.tools.gracefulError(err);
    })
  }*/
  selectUser(user: User) {
    this.selectedUser = user;
    this.selectedUserForm.patchValue(user);
  }

  submit() {
    if (this.selectedUserForm.valid && this.selectedUserForm.dirty) {
      let updatedUser: User = this.selectedUserForm.value;
      this.data.updateUser(updatedUser).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.selectedUser = updatedUser;
          this.selectedUserForm.reset();
          this.selectedUserForm.patchValue(this.selectedUser);
          this.replaceUser(this.selectedUser);
          if (this.data.auth.user.id == this.selectedUser.id) {
            this.data.auth.checkTokenExpiry().then(() => {
              this.tools.snackMessage("your settings have been updated");
            })
          }
          this.ref.detectChanges();
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }
  }
  replaceUser(newUser: User): User {
    let found: User;
    this.company.Sites.some((site) => {
      site.Users.some((user) => {
        if (user.id == newUser.id) {
          this.tools.patchAnUpdate(user, newUser);
          return true;
        }
        if (found) return true;
      })
    })
    return found;
  }

  loadServices() {
    this.data.listAllServices().subscribe((message: StatusMessage) => {
      if (message.success) {
        this.services = message.message;
      }
      else {
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }


}

