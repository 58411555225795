<mat-dialog-content class="fli ovh fdc">
<div class="dialog-container fl fw overflow-scroll">
    <div class="f2 pad ova">
        <h3>Pasted Info</h3>
        <div *ngFor="let eachob of collection.tables">
            <div class="fl title" *ngIf="eachob.titlerow && eachob.titlerow.cells && eachob.titlerow.cells.length>0">
                <div class="field-title">Title</div>
                <div class="field-value">{{eachob.titlerow.cells[0]}}</div>
            </div>
            <table *ngIf="eachob.headerrows.length>0" class="pastedtable c1lcnc">
                <tr *ngFor="let hr of eachob.headerrows">
                    <th *ngFor="let h of hr.cells">{{h}}</th>
                </tr>
                <tr *ngFor="let r of eachob.datarows" class="pastedrow {{r.firstofcategory?'firstcat':''}}">
                    <td *ngFor="let c of r.cells;let i=index">{{c}}</td>
                </tr>
            </table>
            <table *ngIf="eachob.headerrows.length==0">
                <tr *ngFor="let row of eachob.rawrows">
                    <td *ngFor="let cell of row.cells">{{cell}}</td>
                </tr>
            </table>
            <p *ngIf="eachob.iscsv && extrarows.length>0">....Plus {{extrarows.length}} additional rows.</p>
        </div>
        <div *ngIf="collection.tables.length==0">
            <h3 class="warn">We can't find any readable data in the document you've uploaded.</h3>
        </div>

    </div>
    <div class="f1 pad" *ngIf="collection.tables.length>0">
        <h3>Data to import</h3>
        <p *ngIf="site">Data for service provider: {{site.Company.name}}</p>
        <p *ngIf="service">Applies to service: {{service.displayname}}</p>
        <p *ngIf="region">Applies to zone: {{region.name}}</p>
        <p *ngIf="pdfratecard">PDF Rate Card - {{collection.title}}</p>
        <mat-form-field *ngIf="pdfratecard">
            <mat-label>Rates From Site:</mat-label>
            <mat-select [(ngModel)]="basesite">
                <mat-option *ngFor="let site of site.Company.Sites" [value]="site">
                    {{site.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Currency</mat-label>
            <mat-select [(ngModel)]="currency">
                <mat-option *ngFor="let country of data.currencies" [value]="country.id">
                    {{country.currencySymbol}} <img class="flag"
                        src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!pdfratecard">
            <mat-label>Transport Mode</mat-label>
            <mat-select [(ngModel)]="selectedMode">
                <mat-option *ngFor="let enum of transportmodes | enumpipe" [value]="enum.key">
                    {{enum.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!recognised">
            <p class="warn">Pasted data not recognised.</p>
            <p>Please provide a description of the data you are trying to import</p>
            <textarea [(ngModel)]="maprequest.description" class="w100"></textarea>
            <div class="fl aic">
                <mat-checkbox [(ngModel)]="maprequest.consistentheaders" class="mr">Consistent header text</mat-checkbox>
                <mat-checkbox [(ngModel)]="maprequest.consistentrows">Consistent number of rows</mat-checkbox>
                

            </div>
            <div class="fl aic jc">
                <button mat-raised-button color="primary" (click)="requestmap()">Request Data Map</button>
            </div>
        </div>
        <div *ngIf="recognised && mapped">
            <h3>Data Recognised</h3>
            <div class="fl">
                <div class="f1">
                    <mat-form-field>
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="f1">
                    <mat-form-field>
                        <mat-label>Expiry Date</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="expiryDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="recognised && !mapped">
            <h3>Data Recognised but not yet mapped</h3>
            <h2>{{description}}</h2>
            <p>Please allow 24hrs for Freightology to map your table.</p>
        </div>
    </div>
</div>
<div class="fl jc button-list mt">
    <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="import()" [disabled]="!recognised || !mapped || !expiryDate || (pdfratecard && !basesite)">Import</button>
</div>
</mat-dialog-content>