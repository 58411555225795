import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recordStatus',
  pure:false
})
export class RecordStatusPipe implements PipeTransform {

  transform(value: any, status?:number): unknown {
    if(!status) status=0;
    let res = value.filter(v=>v.recordStatus==status);
    return res;
  }

}
