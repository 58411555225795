<mat-tab-group>
    <mat-tab *ngFor="let site of customer.Sites">
        <ng-template mat-tab-label>
            <span class="st{{site.sitetypeid}}">{{site.name}}</span>
        </ng-template>
        <ng-template matTabContent>
            <cs-site-view [site]="site"></cs-site-view>
        </ng-template>
        
    </mat-tab>
</mat-tab-group>