import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Surcharge, SurchargeType } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage, Roles } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { Service } from 'src/app/models/data';
import { AddSurchargetypeComponent } from '../add-surchargetype/add-surchargetype.component';
import { FormArray, FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';

@Component({
  selector: 'cs-port-to-port-surcharge',
  templateUrl: './port-to-port-surcharge.component.html',
  styleUrls: ['./port-to-port-surcharge.component.scss']
})
export class PortToPortSurchargeComponent implements OnInit {

  @Input() editmode: boolean = true;
  @Input() serviceForm: FormGroup;

  @ViewChildren('addtype') addtype: QueryList<AddSurchargetypeComponent>;

  public surchargeTypes: SurchargeType[] = [];
  public selectedSurchargeType: SurchargeType;
  public surcharges: Surcharge[] = [];

  public newtype: string = "";
  public addnew: boolean = false;

  get surchargeFormArray(): FormArray {
    return <FormArray>this.serviceForm.get("surchargeFormArray");
  }
  get serviceproviderid() {
    return this.serviceForm.get("providerid").value;
  }
  get servicetransportMode() {
    return this.serviceForm.get("transportMode").value;
  }

  constructor(public data: DataService, public tools: ToolsService, public formtools: FormtoolsService) { }

  ngOnInit(): void {
    this.data.listSurchargeTypes(this.serviceproviderid,2).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.surchargeTypes = message.message;
        if (this.surchargeTypes.length > 0) {
          this.selectedSurchargeType = this.surchargeTypes[0];
        }
        this.data.listSurchargesService(this.serviceForm.get("id").value,2).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.surcharges = message.message;
            this.surcharges.forEach(s => {
              this.surchargeFormArray.push(this.formtools.createSurchargeForm(s));
            })
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }

  addSurcharge() {
    let surch = new Surcharge();
    surch.surchargetypeid = this.selectedSurchargeType.id;
    surch.serviceid = this.serviceForm.get("id").value;
    surch.perunit = 1;
    this.surcharges.push(surch);
    this.surchargeFormArray.push(this.formtools.createSurchargeForm(surch));
  }
  addOther() {
    this.addnew = true;
    this.addtype.first.add.subscribe((surchargetype: SurchargeType) => {
      this.surchargeTypes.push(surchargetype);
      this.addnew = false;
      this.selectedSurchargeType = surchargetype;
    })
  }

}
