<div class="fl mt">
    <div class="f1 section">
        
        <div class="scene" [ngStyle]="{'width.px':(quoteItem.width/scale),'height.px':(quoteItem.height/scale)}">
            
            <div class="cube show-top">
                <div class="cube__face cube__face--front fl"
                    [ngStyle]="{'font-size.px':(quoteItem.height/scale)/4,'width.px':(quoteItem.width/scale),'height.px':(quoteItem.height/scale),'transform':'rotateY(0deg) translateZ('+(cube/2+((quoteItem.depth/scale)-cube)/2)+'px) translateY('+(cube-(quoteItem.height/scale)-raise)/2+'px) translateX('+(cube-(quoteItem.width/scale))/2+'px)'}">
                    <div class="f1"></div>
                    <div class="f3 fl aic jc">x{{quoteItem.quantity}}</div>
                    <div class="f1">
                    </div>


                </div>
                <div class="cube__face cube__face--back"
                    [ngStyle]="{'line-height.px':(quoteItem.height/scale),'width.px':(quoteItem.width/scale),'height.px':(quoteItem.height/scale),'transform':'rotateY(180deg) translateZ('+(cube/2+((quoteItem.depth/scale)-cube)/2)+'px) translateY('+(cube-(quoteItem.height/scale)-raise)/2+'px) translateX('+(-(cube-(quoteItem.width/scale))/2)+'px)'}">
                    x{{quoteItem.quantity}}</div>
                <div class="cube__face cube__face--right"
                    [ngStyle]="{'line-height.px':(quoteItem.height/scale),'width.px':(quoteItem.depth/scale),'height.px':(quoteItem.height/scale),'transform':'rotateY(90deg) translateZ('+(cube/2+((quoteItem.width/scale)-cube)/2+((cube-(quoteItem.depth/scale))/2))+'px) translateY('+(cube-(quoteItem.height/scale)-raise)/2+'px)'}">
                    x{{quoteItem.quantity}}</div>
                <div class="cube__face cube__face--left"
                    [ngStyle]="{'line-height.px':(quoteItem.height/scale),'width.px':(quoteItem.depth/scale),'height.px':(quoteItem.height/scale),'transform':'rotateY(-90deg) translateZ('+(cube/2+((quoteItem.width/scale)-cube)/2-((cube-(quoteItem.depth/scale))/2))+'px) translateY('+(cube-(quoteItem.height/scale)-raise)/2+'px)'}">
                    x{{quoteItem.quantity}}</div>
                <div class="cube__face cube__face--top"
                    [ngStyle]="{'line-height.px':(quoteItem.depth/scale),'width.px':(quoteItem.width/scale),'height.px':(quoteItem.depth/scale),'transform':'rotateX(90deg) translateZ('+(cube/2-((cube-(quoteItem.height/scale)-raise)/2)-((cube-(quoteItem.depth/scale))/2))+'px) translateX('+(cube-(quoteItem.width/scale))/2+'px)'}">
                    x{{quoteItem.quantity}}</div>
                <div class="cube__face cube__face--bottom"
                    [ngStyle]="{'line-height.px':(quoteItem.depth/scale),'width.px':(quoteItem.width/scale),'height.px':(quoteItem.depth/scale),'transform':'rotateX(-90deg) translateZ('+(cube/2-((cube-(quoteItem.height/scale)+raise)/2)+((cube-(quoteItem.depth/scale))/2))+'px) translateX('+(cube-(quoteItem.width/scale))/2+'px)'}">
                    x{{quoteItem.quantity}}</div>
            </div>
        </div>
        <div class="fl aic jc">

        </div>
    </div>
    <div class="f1 fl fdc aic section">
        <div class="f1 fl fdc aic">

            <div *ngIf="showratebrackets">
                <mat-form-field>
                    <mat-label>Item Size</mat-label>
                    <mat-select [(ngModel)]="quoteItem.ratebracket" (selectionChange)="shipunit($event)">
                        <mat-option *ngFor="let rb of ratebrackets" [value]="rb.id">
                            {{rb.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--<mat-radio-group aria-label="Your Shipment Unit" [(ngModel)]="quoteItem.ratebracket"
                            (change)="shipunit($event)">
                            <table>
                                <tr>
                                    <th>Package</th>
                                    <th>Pallets</th>
                                    <th>FCL</th>
                                    <th><span (click)="showrb()">Custom</span></th>
                                </tr>
                                <tr>
                                    <td class="tac">
                                        <mat-radio-button [value]="ratebrackets.Package" [checked]="quoteItem.ratebracket==ratebrackets.Package" (click)="pallet=false;fcl=false;custom=false;quoteItem.ratebracket=ratebrackets.Package"></mat-radio-button>
                                    </td>
                                    <td class="tac">
                                        <mat-checkbox [checked]="pallet" (click)="quoteItem.ratebracket=ratebrackets.Europallet;pallet=true;fcl=false;custom=false;"></mat-checkbox>
                                    </td>
                                    <td class="tac">
                                        <mat-checkbox [checked]="fcl" (click)="quoteItem.ratebracket=ratebrackets.FCL20;pallet=false;fcl=true;custom=false;"></mat-checkbox>
                                    </td>
                                    <td class="tac">
                                        <mat-checkbox [checked]="custom" (click)="quoteItem.ratebracket=ratebrackets.IDW;pallet=false;fcl=false;custom=true;"></mat-checkbox>
                                    </td>
                                </tr>
                                <tr>
                                    
                                    <td colspan="4" *ngIf="pallet" class="tac">
                                        <mat-radio-button [value]="ratebrackets.Europallet" [checked]="quoteItem.ratebracket==ratebrackets.Europallet" class="mr">Euro</mat-radio-button>
                                        <mat-radio-button [value]="ratebrackets.Pallet"[checked]="quoteItem.ratebracket==ratebrackets.Pallet">Standard</mat-radio-button>
                                    </td>
                                    <td colspan="4" *ngIf="fcl" class="tac">
                                        <mat-radio-button [value]="ratebrackets.FCL20" [checked]="quoteItem.ratebracket==ratebrackets.FCL20" class="mr">20GP</mat-radio-button>
                                        <mat-radio-button [value]="ratebrackets.FCL40" [checked]="quoteItem.ratebracket==ratebrackets.FCL40" class="mr">40GP</mat-radio-button>
                                        <mat-radio-button [value]="ratebrackets.FCL40" [checked]="quoteItem.ratebracket==ratebrackets.FCL40">40 HC</mat-radio-button>
                                    </td>
                                    <td colspan="4" *ngIf="custom" class="tac">
                                        <mat-radio-button [value]="ratebrackets.Over3" [checked]="quoteItem.ratebracket==ratebrackets.Over3" class="mr">over 3m</mat-radio-button>
                                        <mat-radio-button [value]="ratebrackets.IDW" [checked]="quoteItem.ratebracket==ratebrackets.IDW">IDW</mat-radio-button>
                                    </td>
                                </tr>
                            </table>
                        </mat-radio-group>-->
            </div>
            <div class="f1 fl aic" *ngIf="!fcl">
                <mat-form-field class="form-skinny">
                    <mat-label>length (mm)</mat-label>
                    <input matInput type="number" placeholder="depth" [(ngModel)]="quoteItem.depth" ngDefaultControl
                        min="1" autocomplete="quote-depth" step="10">
                </mat-form-field>

                <mat-form-field class="form-skinny mr">
                    <mat-label>width (mm)</mat-label>
                    <input matInput type="number" placeholder="width" [(ngModel)]="quoteItem.width" ngDefaultControl
                        min="1" autocomplete="quote-width" step="10">
                </mat-form-field>

                <mat-form-field class="form-skinny mr">
                    <mat-label>height (mm)</mat-label>
                    <input matInput type="number" placeholder="height" [(ngModel)]="quoteItem.height" ngDefaultControl
                        min="1" autocomplete="quote-height" step="10">
                </mat-form-field>

            </div>
            <div class="f1 fl aic mt">
                <mat-form-field class="form-skinny mr" *ngIf="!fcl">
                    <mat-label>weight (kg)</mat-label>
                    <input matInput type="number" placeholder="weight" [(ngModel)]="quoteItem.weight" ngDefaultControl
                        min="1" autocomplete="quote-weight">
                </mat-form-field>
                <mat-form-field class="form-skinny">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="number" placeholder="quantity" [(ngModel)]="quoteItem.quantity"
                        ngDefaultControl min="1" autocomplete="quote-quantity">
                </mat-form-field>
                <mat-slide-toggle *ngIf="!fcl" color="primary" [(ngModel)]="quoteItem.stackable" ngDefaultControl class="mr">
                    <span>Stackable</span>
                </mat-slide-toggle>
            </div>
        </div>

    </div>
</div>