import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Title } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  app=0;
  cover = true;
  
  title = 'Welcome to Freightology';
  constructor(public auth:AuthService,titleService:Title,iconreg:MatIconRegistry,domSanitizer: DomSanitizer){
    auth.userReady.subscribe(()=>{
      this.cover = false;
      titleService.setTitle(auth.user.Site.Company.name);
      
    })
    iconreg.addSvgIcon('airport',domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/airport.svg"));
    iconreg.addSvgIcon('quote',domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/quote.svg"));
    iconreg.addSvgIcon('quote-history',domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/quote-history.svg"));
  }
  upApp(){
    this.app++;
    if(this.app>3) this.cover = false;
  }
}
