import { Component, OnInit, Inject } from '@angular/core';
import { Country } from 'src/app/models/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegiongrouperComponent } from '../regiongrouper/regiongrouper.component';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'cs-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent implements OnInit {

  currencyCtrl = new FormControl();
  filteredCountries: Observable<Country[]>;
  
  currencyCountries:Country[] = [];


  constructor(public dialogRef: MatDialogRef<RegiongrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
      this.currencyCountries = dialogdata.countries;     
    }

  ngOnInit(): void {
    this.filteredCountries = this.currencyCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this.filterCountry(value):this.currencyCountries.slice())
      )
  }

  private filterCountry(value:string):Country[]{
    const filtervalue = value.toLowerCase();
    return this.currencyCountries.filter(co=>co.name.toLowerCase().indexOf(filtervalue)>=0);
  }
  
  chooseCurrency(e){
    let co = this.currencyCountries.filter(c=>c.name==e.option.value)[0];
    this.dialogRef.close(co);
  }

}
