import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MilestoneStatus, Milestone, MilestoneType, RecordStatus, Priority, MomentBucket, TimeLineInputs, Module } from 'src/app/models/models';
import { DataService} from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import moment from 'moment';
import { FormGroup} from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { debounceTime } from 'rxjs/operators';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  public milestoneTypes = MilestoneType;
  public milestoneStatus = MilestoneStatus;
  public milestonePriority = Priority;
  public milestones: Milestone[] = [];
  public folders: Milestone[] = [];
  public selectedMilestone: Milestone;
  //public momentBucket: MomentBucket;

  public collapseForm = false;

  public projectStart: moment.Moment = moment();
  public projectEnd: moment.Moment = moment();
  public timelineWidth = 0;

  public selectedHasChildren = true;

  public editable: boolean = false;
  public viewable:boolean = false;

  public showArchive: boolean = true;

  public selectedStatus = "-1";

  public selectedForm: FormGroup;

  public calendarOffset: number = 0;
  
  public bucket: MomentBucket;
  public isWeeks: boolean = true;

  /*
  get xinstantGroup() {
    return this.selectedForm.get("instantGroup");
  }
  get xdebouncedGroup() {
    return this.selectedForm.get("debouncedGroup");
  }
  get xprivateGroup() {
    return this.selectedForm.get("privateGroup");
  }*/

  get todayOffset(){
    return ((moment().diff(this.data.roadmapSettings.startDate,'d')+this.data.roadmapSettings.additionalDays+0.5)*this.data.roadmapSettings.dayWidth)+this.calendarOffset;
  }

  constructor( public data: DataService, private tools: ToolsService, private formtools: FormtoolsService,private ref:ChangeDetectorRef,private events:EventsService) {

    this.events.addedChildMilestone.subscribe((newchild:Milestone)=>{
      this.parentUpdates(newchild);
    });
    this.events.selectedMilestone.subscribe((milestone:Milestone)=>{
      this.select(milestone);
    });

    if(data.dataIsReady){
      this.loadData();
    }
    else
    {
      this.editable = false;
      data.dataReady.subscribe(()=>{
        this.loadData();
      })     
    }
    this.selectedForm = this.formtools.createMilestoneFormGroup();
  }


  //load the milestones, strip out folders
  ngOnInit() {
    this.data.auth.currentroute = "Road Map";
    
  }
  loadData(){
    let modulePermission = this.data.auth.modulePermission("RoadMap");
    this.viewable = modulePermission.view;
    this.editable = modulePermission.edit;
    if(modulePermission.view){
      this.data.listMilestones(0).subscribe((message: StatusMessage) => {

        if (message.success) {
          this.milestones = message.message;
  
          //strip out folders
          this.stripOutFolders();
  
          //select first if available and subscribe to updaters
          if (this.milestones.length > 0) {
            this.select(this.milestones[0]);
            this.assignUpdaters();
          }
          else {
            this.assignUpdaters();
          }
          //get everything setup, with last week if there are no milestones yet
          if(this.milestones.length>0){
            this.getStartDate();
          }
          else{
            let start = moment().subtract(1,'w');
            let tempEnd = moment(start).add(this.data.roadmapSettings.monthsToView, 'M');
            this.setupCalendar(start,tempEnd);
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }

  }

  //strip out folders and shove to the bottom
  stripOutFolders() {
    //let start = moment();
    let x = this.milestones.length;
    while (x > 0) {
      x--;
      let checkForFolders = this.milestones[x];
      if (checkForFolders.milestoneType == MilestoneType.FOLDER) {
        let folder = this.milestones.splice(x, 1)[0];
        this.folders.push(folder);
      }

    }
    //let end = moment();
  }
  //an alternative function for stripping out folders on the db - slower than client
  stripOutFoldersDb() {

    let start2 = moment();
    this.data.listMilestoneFolders(0).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.folders = message.message;
        let end2 = moment();
        console.log("folders from db: ", end2.diff(start2));
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }

  //set up the observers for value changes in the milestone form, text fields debounce for 2 seconds to
  //avoid multiple updates when use is typing.
  assignUpdaters() {
    Object.keys(this.selectedForm.controls).forEach(key => {
      switch (key) {
        case "summary":
        case "description":
          this.selectedForm.get(key).valueChanges.pipe(debounceTime(2000)).subscribe(() => {
            this.save();
          });
          break;
        default:
          //setting a debounce time here is a hack for a known bug as of https://github.com/angular/angular/issues/12540 
          this.selectedForm.get(key).valueChanges.pipe(debounceTime(10)).subscribe(() => {
            this.save();
          });
      }
    })
  }

  //setup calendar and scroll to week before today
  getStartDate(){
    this.data.getStartDate().subscribe((message: StatusMessage) => {
      if (message.success) {
        let response = message.message.res[0][0];
        let startDate = moment(response.startDate);
        let endDate = moment(response.endDate);
        this.setupCalendar(startDate,endDate);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },
    err=>{
      this.tools.gracefulError(err);
    })
  }

  setupCalendar(startDate:moment.Moment,endDate:moment.Moment){
        this.projectStart = this.data.roadmapSettings.startDate = startDate;
        this.projectEnd = endDate.add(this.data.roadmapSettings.monthsToView, 'M');
        this.bucket = this.tools.makeAMomentBucket(this.projectStart, this.projectEnd);
        this.data.roadmapSettings.additionalDays = this.bucket.additionalDays;
        let today = moment();
        let fullweekstart = moment(this.projectStart).subtract(this.bucket.additionalDays, 'd');
        let todayOffset = today.diff(fullweekstart, 'd');
        //have I got this the wrong way around at todayOffset>0?
        if (todayOffset > 0) {
          let modulus = todayOffset % 7;
          let startOffset = todayOffset - modulus - 7;

          this.calendarOffset = -this.data.roadmapSettings.dayWidth * startOffset;
          if(this.calendarOffset>0){
            this.calendarOffset = 0;
          }
        }
        let totalwidth = this.projectEnd.diff(fullweekstart,'d');
        this.timelineWidth = totalwidth*this.data.roadmapSettings.dayWidth;
        
  }

  //select a milestone and load it into the detail form
  select = (milestone: Milestone) => {
    //this.data.roadmapSettings.selected = milestone;
    this.selectedMilestone = milestone;
    if (milestone.children && milestone.children.length > 0) {
      this.selectedHasChildren = true;
    }
    else {
      this.selectedHasChildren = false;
    }
     //reset and repatch to make form pristine
    this.selectedForm.reset();
    this.selectedForm.patchValue(milestone);
  }
  folderOpen(milestone:Milestone){
    milestone._folderOpen = !milestone._folderOpen;
  }

  //scroll the calendar
  moveCalendar(forward: boolean) {
    if (forward) this.calendarOffset += this.data.roadmapSettings.dayWidth * 7;
    else this.calendarOffset -= this.data.roadmapSettings.dayWidth * 7;

    if (this.calendarOffset > 0) this.calendarOffset = 0;

  }
  //change the scale of the calendar
  swapScale() {
    this.isWeeks = !this.isWeeks;
  }

  //create a new top level milestone
  newMilestone() {
    let milestone = new Milestone(this.data.auth.user.id,0);

    this.data.createMilestone(milestone).subscribe((message: StatusMessage) => {
      if (!message.success) {
        this.tools.gracefulError(message.message);
      }
      else {
        let newmilestone: Milestone = message.message;
        if(this.milestones.length==0){
          //reset calendar offset
          let newend = moment(newmilestone.dueDate.add(this.data.roadmapSettings.monthsToView, 'M'));
          if(this.projectEnd<newend){
            this.projectEnd = newend;
          }
          this.setupCalendar(moment(newmilestone.startDate),this.projectEnd);
        }
        this.milestones.push(newmilestone);
        this.select(newmilestone);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  //delete a milestone (set its record status to deleted)
  delete() {
    this.selectedMilestone.recordStatus = RecordStatus.Deleted;
    this.doUpdate(this.selectedMilestone).then((message: StatusMessage) => {
      if (message.success) {
        if (this.selectedMilestone.parentId) {
          let parent = this.findMilestone(this.selectedMilestone.parentId);
          this.removeFromParent(this.selectedMilestone.id, parent);
          this.select(parent);

        }
        else {
          let index = this.removeFromParent(this.selectedMilestone.id);
          this.selectedMilestone = null;
          if (index == 0) {
            if (this.milestones.length > 0) {
              this.select(this.milestones[0]);
            }
          }
          else {
            if (this.milestones.length > 0) {
              this.select(this.milestones[index - 1]);
            }
          }
        }

      }
    })
  }
  //find a milestone from its id
  findMilestone(id: number): Milestone {
    let found: Milestone;
    //let checkboth = this.milestones.concat(this.folders);
    this.milestones.some((milestone: Milestone) => {
      if (milestone.id == id) {
        found = milestone;
        return true;
      }
      if (!found) {
        if (milestone.children) {
          found = this.searchChildren(milestone.children, id);
        }
        if (found) {
          return true;
        }
      }
    });
    return found;
  }

  //search a milestone's children - recursive
  searchChildren(children: Milestone[], id: number): Milestone {
    let child: Milestone;
    children.some((milestone: Milestone) => {
      if (milestone.id == id) {
        child = milestone;
        return true;
      }
      if (!child) {
        if (milestone.children) {
          child = this.searchChildren(milestone.children, id);
        }
        if (child) return true;
      }
    })
    return child;
  }

  getMinDueDate(milestones:Milestone[],minimum:moment.Moment){
    
    milestones.forEach((milestone)=>{
      if(moment(milestone.dueDate)>minimum){
        minimum = moment(milestone.dueDate);
      }
      if(milestone.children) this.getMinDueDate(milestone.children,minimum);
    })

    return minimum;
  }

  //remove a milestone from its parent
  removeFromParent(id: number, parent?: Milestone): number {
    let index = 0;
    if (parent) {
      parent.children.some((milestone: Milestone) => {
        if (milestone.id == id) {
          return true;
        }
        index++;
      });
      parent.children.splice(index, 1);
    }
    else {
      this.milestones.some((milestone: Milestone) => {
        if (milestone.id == id) {
          return true;
        }
        index++;
      })
      this.milestones.splice(index, 1);
    }
    return index;
  }

  //archive a milestone - creating an archive folder if one does not exist
  archive() {

    let archiveGroup;

    this.folders.some((milestone: Milestone) => {
      if (milestone.status == MilestoneStatus.ARCHIVE) {
        archiveGroup = milestone;
        return true;
      }
    })
    if (!archiveGroup) {
      archiveGroup = new Milestone(this.data.auth.user.id,0);
      archiveGroup.summary = "Archived Milestones";
      archiveGroup.description = "A folder for milestones that have been archived";
      archiveGroup.milestoneType = MilestoneType.FOLDER;
      archiveGroup.status = MilestoneStatus.ARCHIVE;

      archiveGroup.children = [];
      this.data.createMilestone(archiveGroup).subscribe((message: StatusMessage) => {
        if (message.success) {
          archiveGroup = message.message;
          this.folders.push(archiveGroup);
          if (this.selectedMilestone.parentId) {
            this.removeFromParent(this.selectedMilestone.id, this.findMilestone(this.selectedMilestone.parentId))
          }
          else {
            this.removeFromParent(this.selectedMilestone.id);
          }
          this.addMilestoneToArchive(this.selectedMilestone, archiveGroup);
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      });
    }
    else {
      if (this.selectedMilestone.parentId) {
        this.removeFromParent(this.selectedMilestone.id, this.findMilestone(this.selectedMilestone.parentId))
      }
      else {
        this.removeFromParent(this.selectedMilestone.id);
      }
      this.addMilestoneToArchive(this.selectedMilestone, archiveGroup);
      //console.log(this.milestones);
    }


  }

  //return an archived milestone to its original parent
  unarchive() {
    let oldparent;
    let oldparentId = this.selectedMilestone.parentId;//the archive id or parent
    if(this.selectedMilestone.previousParent){
      this.selectedMilestone.parentId = null;
    }
    else
    {
      this.selectedMilestone.parentId = this.selectedMilestone.previousParent;
      oldparent = this.findMilestone(this.selectedMilestone.parentId);
      if(!oldparent){
        this.tools.gracefulError("Previous parent milestone has been removed. Restoring as a top level milestone");
        this.selectedMilestone.parentId = null;
      }
    }
    this.selectedMilestone.previousParent = null;
    
    
    this.doUpdate(this.selectedMilestone).then((message: StatusMessage) => {
       if(message.success){
         if(this.selectedMilestone.parentId){
          this.removeFromParent(this.selectedMilestone.id,this.findMilestone(oldparentId));
          if(!oldparent.children) oldparent.children=[];
          oldparent.children.push(this.selectedMilestone);
         }
         else{
          this.removeFromParent(this.selectedMilestone.id,this.findMilestone(oldparentId));
           this.milestones.push(this.selectedMilestone);
         }
       }
       else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    });

  }

  //do the actual milestone movement and update db
  addMilestoneToArchive(milestone: Milestone, archiveGroup: Milestone) {
    if (!archiveGroup.children) archiveGroup.children = [];
    milestone.previousParent = !milestone.parentId?0:milestone.parentId;
    milestone.parentId = archiveGroup.id;
    archiveGroup.children.push(milestone);
    this.doUpdate(milestone).then((message: StatusMessage) => {

    });
  }

  //save milestone if dirty
  save() {
    if (this.formtools.controlsAreDirty(this.selectedForm)) {
      //prevent due date being less than children
      let duedate = this.selectedForm.get("dueDate");
      let newdue = duedate.value;
      if(duedate.dirty){
        if(this.selectedMilestone.children){
          let mindate = this.getMinDueDate(this.selectedMilestone.children,newdue);
          if(newdue<mindate){
            this.selectedMilestone.dueDate = mindate;
            duedate.patchValue(this.selectedMilestone.dueDate);
            this.tools.gracefulError("Child milestones have a later due date, please change those first");
          }
          
        }
        
      }
      //prevent due date being less than start date
      let startdate = this.selectedForm.get("startDate").value;
      if(moment(startdate)>moment(newdue)){
        let diff = moment(this.selectedMilestone.dueDate).diff(moment(this.selectedMilestone.startDate),'d');
        duedate.setValue(moment(startdate).add(diff,'d'));
      }
      
      let amended: Milestone = this.selectedForm.value;
      this.doUpdate(amended).then((message: StatusMessage) => {
        if (message.success) {
          this.selectedForm.reset();
          this.selectedForm.patchValue(amended);         
          //update the local milestone
          amended.children = this.selectedMilestone.children;
          this.selectedMilestone = amended;
          let local = this.findMilestone(amended.id);
          Object.keys(amended).forEach((key)=>{
            local[key]=amended[key];
          })
          this.setChildrenStartDate(this.selectedMilestone);
          this.ref.detectChanges();
          
          //Object.keys(amended).forEach((key) => {
         //  this.data.roadmapSettings.selected[key] = amended[key];
          //})
          //make any necessary changes to the parents
          if(this.selectedMilestone.parentId){
            this.parentUpdates(this.selectedMilestone);
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }

  }

  //change the parents when a child's priority or due date change
  parentUpdates(milestone:Milestone){
    let parent = this.findMilestone(milestone.parentId);
    let changed = false;
    if(moment(parent.dueDate)<moment(milestone.dueDate)){
      parent.dueDate = moment(milestone.dueDate);
      changed = true;
    }
    if(parent.priority<milestone.priority){
      parent.priority = milestone.priority;
      changed = true;
    }
    if(changed){
      this.doUpdate(parent).then((message:StatusMessage)=>{
        if(message.success){
          this.ref.detectChanges();
          if(parent.parentId){
            this.parentUpdates(parent);
          }
        }
      })
    }

  }
  setChildrenStartDate(milestone:Milestone){
    if(milestone.children){
      milestone.children.forEach(child=>{
        if(moment(child.startDate)<moment(milestone.startDate) && child.status!=MilestoneStatus.COMPLETED){
          let diff = moment(child.dueDate).diff(moment(child.startDate),'d');
          child.startDate = moment(milestone.startDate);
          child.dueDate = moment(child.dueDate).add(diff,'d');
          this.data.updateMilestone(child).subscribe((message:StatusMessage)=>{
            if(!message.success){
              this.tools.gracefulError(message.message);
            }
            else{
              this.setChildrenStartDate(child);
            }
          });
        }
      })
    }
  }
  childUpdate(milestones){

  }

  //to the actual db update
  doUpdate(milestone: Milestone) {
    return new Promise((resolve, reject) => {
      this.data.updateMilestone(milestone).subscribe((message: StatusMessage) => {
        if (message.success) {
          //this.tools.gracefulError("Update successful");
          if (moment(milestone.startDate) < this.projectStart) this.projectStart = moment(milestone.startDate);
          resolve(message);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(message);
        }
      }, err => {
        this.tools.gracefulError(err);
        resolve({success:false,message:err});
      });
    })

  }

  force(){
    console.log("force me");
  }


}
