import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Quotation } from 'src/app/models/ui';
import { StatusMessage } from 'src/app/models/user';
import { CalculationsService } from 'src/app/services/calculations.service';
import { DataService } from 'src/app/services/data.service';
import { CompanyComponent } from '../company/company.component';

@Component({
  selector: 'cs-quote-view-dialog',
  templateUrl: './quote-view-dialog.component.html',
  styleUrls: ['./quote-view-dialog.component.scss']
})
export class QuoteViewDialog implements OnInit {

  public coid:number;
  public quoteid:number;
  public quote:Quotation;
  public customer = false;

  constructor(public dialogRef: MatDialogRef<CompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public data:DataService,public calc:CalculationsService) {
      this.quoteid = dialogdata.quoteid;
    }

  ngOnInit(): void {
    this.coid = this.data.Company.id;
  }
  changeView(e){

  }
  options(){

  }
  cancel(){
    this.dialogRef.close();
  }
}
