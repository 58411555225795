import { Component, OnInit, Input } from '@angular/core';
import { PriceBand, Rate, Region } from 'src/app/models/rates';
import { FormGroup } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { DataService } from 'src/app/services/data.service';



@Component({
  selector: 'cs-rate-list-form',
  templateUrl: './rate-list-form.component.html',
  styleUrls: ['./rate-list-form.component.scss']
})
export class RateListFormComponent implements OnInit {

  @Input() bands: Region[] = [];
  @Input() priority: number = 0;
  @Input() priorities: number = 1;
  @Input() rateform: FormGroup;
  @Input() ultimateparent:number;

  public showmore = false;

  //public currencyCode = "GBP";

  public selectedBand: PriceBand;

  constructor(public formtools: FormtoolsService, public data: DataService) { }

  ngOnInit(): void {
  }
  selectRate(rate: Rate, band: PriceBand) {

  }
  editRate(rate: Rate, band: PriceBand) {
    if (this.selectedBand && this.selectedBand.regionid == band.regionid) {
      this.selectedBand = null;
    }
    else {
      this.rateform.reset();
      this.rateform.patchValue(rate);
      if (rate.RateBreaks && rate.RateBreaks.length > 0) {
        this.formtools.patchRateBreaks(this.rateform, rate);
      }
      this.selectedBand = band;
    }

  }
  addRate(band: PriceBand) {
    let newrate = new Rate(band.regionid, this.priority);
    if(this.data.ratebrackets && this.data.ratebrackets.length>0){
      newrate.name = this.data.ratebrackets.filter(rb=>rb.name=="Pallet")[0].name;
    }
    if(band.currencyid){
      newrate.currency = band.currencyid;
      newrate.Currency = this.data.setCurrency(newrate.currency);
    }
    else{
      newrate.currency = this.data.defaultCurrency.id;
      newrate.Currency = this.data.defaultCurrency;
    }
    newrate.parentregionid = this.ultimateparent;
    newrate.createdAt = new Date();
    this.rateform.reset();
    this.rateform.patchValue(newrate);
    this.selectedBand = band;

  }
  addRateBands(band: PriceBand) {

  }
  addRatePriorities(band: PriceBand) {

  }
  addRate3D(band: PriceBand) {

  }
  setCurrency(rate: Rate) {
    /*if (rate.currency) {
      this.currencyCode = this.data.countries.filter(c => c.id == rate.currency)[0].currencyCode;
    }*/
  }

  clickFolder(band:PriceBand){
    band._folderOpen = !band._folderOpen;
  }
}
