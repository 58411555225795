import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumpipe'
})
export class EnumPipe implements PipeTransform {

  transform(value:any,minrole?:number): any {
    let array:any[]=[];
    if(!minrole) minrole = 0;
    Object.keys(value).forEach(key=>{
      let x = parseInt(key);
      if(x>=minrole){
        array.push({key:x,value:value[key]});
      }
    })
    return array;
  }

}


