import { Component, OnInit } from '@angular/core';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { FormGroup, FormArray } from '@angular/forms';
import { MinimumMargin, Discount, MarginAppliesTo } from 'src/app/models/rates';
import { Company, CompanyAssociation, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-discounts-test',
  templateUrl: './discounts-test.component.html',
  styleUrls: ['./discounts-test.component.scss']
})
export class DiscountsTestComponent implements OnInit {

  public discountsForm: FormGroup;
  public setminimum = false;
  public minmargin: MinimumMargin;
  public customer: Company;
  public discounts: Discount[] = [];

  public allcustomers = true;

  public customerid = 0;

  constructor(public formtools: FormtoolsService, public data: DataService, public events: EventsService, public tools: ToolsService) {
    events.companyselect.subscribe((assoc: CompanyAssociation) => {
      if (assoc.Customer) {
        this.data.selectedCustomer = assoc;
        this.customer = assoc.Customer;
        this.setup();
      }
    })
  }

  public get minMargin(): FormGroup {
    return <FormGroup>this.discountsForm.get("minimumMargin");
  }
  public get discountsArray(): FormArray {
    return <FormArray>this.discountsForm.get("discountsArray");
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    if (this.customer) {
      this.customerid = this.customer.id;
    }

    this.data.listDiscounts(this.customerid).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.discounts = message.message;
        this.data.getMinimumMargin(this.customerid).subscribe((message: StatusMessage) => {
          if (message.success) {
            if (message.message) {
              this.setminimum = true;
              this.minmargin = message.message;
            }
            else {
              this.minmargin = new MinimumMargin(this.data.Company.id, this.customerid);
            }

            this.discountsForm = this.formtools.createDiscountsForm(this.minmargin);
            this.buildDiscountArray();
            //this.addSurchargeDiscountControls();
          }
          else this.tools.gracefulError(message.message);
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => this.tools.gracefulError(err))
    /*
        let mm = new MinimumMargin(0,0);
        this.discountsForm = this.formtools.createDiscountsForm(mm);
        let dis1 = new Discount(MarginAppliesTo.Global,118,0);
        let dis2 = new Discount(MarginAppliesTo.Global,118,0);
        let df = this.formtools.createDiscountForm(dis1,"dis 1");
        let df2 = this.formtools.createDiscountForm(dis2,"dis 2");
        this.discountsArray.push(df);
        this.discountsArray.push(df2);*/
  }
  buildDiscountArray() {
    this.discountsArray.clear();
    let prefix = ""
    if (this.customer) {
      prefix = this.customer.name + " ";
    }
    this.addDiscountControl(prefix + "Global", MarginAppliesTo.Global);
    this.addDiscountControl(prefix + "Service", MarginAppliesTo.Service_Charges);
    this.addDiscountControl(prefix + "Delivery Rates", MarginAppliesTo.Delivery_Rates);
    this.addDiscountControl(prefix + "Collection Rates", MarginAppliesTo.Collection_Rates);
    this.addDiscountControl(prefix + "Surcharge", MarginAppliesTo.Surcharges);

  }

  addDiscountControl(title: string, appliesto: MarginAppliesTo) {
    let existingmargin = this.discounts.filter(g => g.appliesto == appliesto && !g.surchargetype);
    let discount: Discount;
    if (existingmargin.length == 1) discount = existingmargin[0];
    else discount = new Discount(appliesto, this.data.auth.user.Site.Address.countryid, this.data.Company.id);
    discount.customerid = this.customerid;
    let global: FormGroup = this.formtools.createDiscountForm(discount, title);
    this.discountsArray.push(global);
  }
  startSave() {

    if (this.minMargin.dirty) {
      let mm: MinimumMargin = this.minMargin.value;
      if (mm.id > 0) {
        this.data.updateMinimumMargin(mm).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.saveDiscounts();
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else {
        this.data.createMinimumMargin(mm).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.minMargin.get("id").setValue(message.message.id);
            this.saveDiscounts();
          }
          else this.tools.gracefulError(message.message);
        }, err => {
          this.tools.gracefulError(err);
        })
      }
    }
    else this.saveDiscounts();
  }

  saveDiscounts() {
    //ITS THE FUCKING CLONEY THING - PUSHING A FORM FUCKS STUFF!!!!!
    //let discountstoupdate = this.formtools.createFormArray();

    
    //use value instead
    let discountmodels:Discount[]=[];
    let index = 0; //need to set an id for any new ones so need to keep record of its place in the form array
    this.discountsArray.controls.forEach(discountform => {
      if (discountform.dirty) {
        let dis:Discount = discountform.value;
        dis.placeinarray = index;
        discountmodels.push(dis);
      }
      index++;
    })

    let todo = discountmodels.length;
    if (todo == 0) {
      this.discountsForm.markAsPristine();
      this.tools.snackMessage("Updates Complete");
    }

    else {
      let done = 0;
      discountmodels.forEach(discount => {
        if (discount.id > 0) {
          this.data.updateDiscount(discount).subscribe((message: StatusMessage) => {
            if (message.success) {
              done++;
              if (done == todo) {
                this.discountsForm.markAsPristine();
                this.tools.snackMessage("Updates Complete");
              }
            }
            else {
              done++;
              this.tools.gracefulError(message.message);
              if (done == todo) {
                this.tools.snackMessage("Updates Complete with errors");
              }
            }
          }, err => {
            done++;
            this.tools.gracefulError(err);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          })
        }
        else {
          this.data.createDiscount(discount).subscribe((message: StatusMessage) => {
            if (message.success) {
              done++;
              discount.id = message.message.id;
              this.discountsArray.controls[discount.placeinarray].get("id").setValue(discount.id);
              
              if (done == todo) {
                this.discountsForm.markAsPristine();
                this.tools.snackMessage("Updates Complete");
              }
            }
            else {
              done++;
              this.tools.gracefulError(message.message);
              if (done == todo) {
                this.tools.snackMessage("Updates Complete");
              }
            }
          }, err => {
            done++;
            this.tools.gracefulError(err);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          })
        }
      })
    }
  }
  setCustomer() {
    if (this.allcustomers) {
      this.customer = null;
      this.customerid = 0;
      this.setup();
    }
  }

  logform() {
    console.log(this.discountsForm);
  }

}
