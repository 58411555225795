import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { User, Role } from 'src/app/models/user';

@Component({
  selector: 'cs-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  
  @Input() parentForm:FormArray;
  @Input() user:FormGroup;
  @Input() index:number;


  more:boolean = false;

  
  constructor() {
  }

  get email(){
    return this.parentForm.controls[this.index].get("email");
  }

  ngOnInit() {
    //console.log(this.parentForm);
  }


}
