import { Component, OnInit } from '@angular/core';
import { } from 'googlemaps';
import { GoogleService } from 'src/app/services/google.service';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-geocoder',
  templateUrl: './geocoder.component.html',
  styleUrls: ['./geocoder.component.scss']
})
export class GeocoderComponent implements OnInit {

  public isLoading = false;
  public address:string;
  geoResponse:any;

  constructor(public google:GoogleService) { }

  ngOnInit(): void {
    
  }
  calculate(){
    this.google.geocode(this.address).then((message:StatusMessage)=>{
      this.geoResponse = message.message;
    })
  }

}
