import { Component, OnInit, Input } from '@angular/core';
import { SettingEditor } from '../setting-editor';
import { CompanySettingType, NewCompanySettingType } from 'src/app/models/user';
import { Country } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-currency-chooser',
  templateUrl: './currency-chooser.component.html',
  styleUrls: ['./currency-chooser.component.scss']
})
export class CurrencyChooserComponent implements SettingEditor {

  @Input() data:any;
  public settingvalue:number;
  public currencies:Country[]=[];
  public title = "";
  public settingtype:CompanySettingType;
  
  constructor(public events:EventsService) { }

  ngOnInit(): void {
    this.currencies = this.data.currencies;
    this.title = this.data.title;
    this.settingtype = this.data.settingtype;
    if(this.settingtype.Setting){
      this.settingvalue = parseInt(this.settingtype.Setting.setting);
    }
    else this.settingvalue = this.data.default;
  }

  saveChanges(e){
    let newsettingtype = new NewCompanySettingType();
    newsettingtype.newsetting = this.settingvalue.toString();
    newsettingtype.settingtype = this.settingtype;
    this.events.companysettingchange.emit(newsettingtype);
  }

}
