import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Company, StatusMessage, CompanyAssociation, CompanyType, CompanyTypeAppliesTo } from 'src/app/models/user';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { TesttoolsService } from 'src/app/testing/testtools.service';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { ConfirmOption } from 'src/app/models/models';
import { Surcharge } from 'src/app/models/rates';

@Component({
  selector: 'cs-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  companyForm: FormGroup;

  public company: Company;
  newSiteId: number = -1;
  iscustomer = false;

  public currentTab: number = 0;

  get sites(): FormArray {
    return <FormArray>this.companyForm.get('Sites');
  }

  get companyTypes(): CompanyType[] {
    let types = this.data_service.companyTypes.filter(c => c.appliesto == (this.iscustomer ? CompanyTypeAppliesTo.Customer : CompanyTypeAppliesTo.Provider));
    return types;
  }

  @ViewChild('siteTabs', { static: false }) siteTabs: MatTabGroup;

  constructor(public dialogRef: MatDialogRef<CompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public data_service: DataService, private tools: ToolsService, private formtools: FormtoolsService) {
    this.iscustomer = data.iscustomer;
    this.company = data.Provider;
    this.companyForm = formtools.createCompanyForm(this.company);
  }

  ngOnInit() {

  }

  cancel() {
    if (this.companyForm.dirty) {
      this.tools.confirm("You have unsaved information do you wish to discard it?", true, true).then((result: ConfirmOption) => {
        if (result == ConfirmOption.YES) {
          this.dialogRef.close();
        }
      })
    }
    else {
      //pick up invite changes
      //TODO factor this out with a better form validation process
      let coid: number = this.companyForm.value.id;
      if (!coid) {
        this.dialogRef.close();
      }
      else {
        if (this.iscustomer) {
          this.data_service.getCustomer(coid).subscribe((fresh: StatusMessage) => {
            if (fresh.success) {
              let refreshed = <Company>fresh.message.Provider;
              this.dialogRef.close(refreshed);
            }
            else {
              this.dialogRef.close();
            }
          }, err => {
            this.tools.gracefulError(err);
            this.dialogRef.close();
          })
        }
        else {
          this.data_service.getProvider(coid).subscribe((fresh: StatusMessage) => {
            if (fresh.success) {
              let refreshed = <Company>fresh.message.Provider;
              this.dialogRef.close(refreshed);
            }
            else {
              this.dialogRef.close();
            }
          }, err => {
            this.tools.gracefulError(err);
            this.dialogRef.close();
          })
        }
      }


    }
  }
  submit() {

    if (this.companyForm.dirty) {
      let newco: Company = <Company>this.companyForm.value;
      if (!newco.id || newco.id == 0) {
        let coAssoc = new CompanyAssociation();
        if (this.iscustomer) {
          coAssoc.customerid = this.data_service.Company.id;
          coAssoc.Customer = newco;
        }
        else {
          coAssoc.companyid = this.data_service.Company.id;
          coAssoc.Provider = newco;
        }

        this.data_service.createCompany(coAssoc).subscribe((coass: StatusMessage) => {
          if (coass.success) {
            let newco = coass.message as CompanyAssociation;
            this.dialogRef.close(newco);
          }
          else this.tools.gracefulError(coass.message);
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else {
        if (this.formtools.companySetDirty(this.company, newco)) {
          this.handleSurcharges().then((message: StatusMessage) => {
            if (message.success) {
              this.data_service.updateCompany(newco).subscribe((message: StatusMessage) => {
                if (message.success) {

                  if (this.iscustomer) {
                    this.data_service.getCustomer(newco.id).subscribe((fresh: StatusMessage) => {
                      let refreshed = <Company>fresh.message.Customer;
                      this.dialogRef.close(refreshed);
                    })
                  }
                  else {
                    this.data_service.getProvider(newco.id).subscribe((fresh: StatusMessage) => {
                      let refreshed = <Company>fresh.message.Provider;
                      this.dialogRef.close(refreshed);
                    })
                  }

                }
                else {
                  this.tools.gracefulError(message.message);
                }
              }, err => { console.log(err) })
            }
            else {
              this.tools.gracefulError(message.message);
            }
          })
        }
        else {
          this.tools.gracefulError("No changes made");
        }
      }
    }
    else {
      this.tools.gracefulError("No changes made");
    }
  }
  handleSurcharges() {
    return new Promise((resolve) => {
      let sitesform = <FormArray>this.companyForm.get("Sites");
      this.createOrUpdateSiteSurcharges(sitesform).then((message: StatusMessage) => {
        if (message.success) {
          resolve(message);
        }
        else {
          resolve({ success: false, message: message.message });
        }
      })
      /*
      let todo = 0;
      sitesform.controls.forEach(sf=>{
        if(sf.dirty){
          todo++;
          return true;
        }
      })
      if(!todo) resolve({success:true,message:''});
      else{
        
      }*/
    })
  }
  createOrUpdateSiteSurcharges(sitesform: FormArray) {
    return new Promise((resolve) => {
      let todo = sitesform.controls.length;
      let done = 0;
      sitesform.controls.forEach(sf => {



        let importsurchargeform = <FormArray>sf.get("surchargeImportSiteFormArray");
        if (importsurchargeform.dirty) {
          this.data_service.createOrUpdateSurcharges(importsurchargeform).then((message: StatusMessage) => {
            done++;
            if (done == todo) {
              resolve(message);
            }
          })
        }
        else {
          done++;
          if (todo == done) resolve({ success: true, message: importsurchargeform.value });
        }

        let exportsurchargeform = <FormArray>sf.get("surchargeExportSiteFormArray");
        if (exportsurchargeform.dirty) {
          this.data_service.createOrUpdateSurcharges(exportsurchargeform).then((message: StatusMessage) => {
            done++;
            if (done == todo) {
              resolve(message);
            }
          })
        }
        else {
          done++;
          if (todo == done) resolve({ success: true, message: exportsurchargeform.value });
        }
      })
    })
  }

  addSite() {

    let addressForm = this.formtools.createAddressFormGroup();

    let siteForm = this.formtools.createSiteFormGroup(addressForm, this.formtools.createFormArray(), this.formtools.createFormArray());
    this.sites.push(siteForm);
    //this.company.Sites.push(site);
    //console.log(this.companyForm);
    setTimeout(() => {
      this.siteTabs.selectedIndex = this.sites.length - 1;
    })

  }

  logForm() {
    console.log(this.companyForm, this.company);
  }

}
