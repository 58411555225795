import { Component, Input} from '@angular/core';

@Component({
  selector: 'cs-pie1',
  templateUrl: './pie1.component.html',
  styleUrls: ['./pie1.component.scss']
})
export class Pie1Component {
  
  @Input() data:any;

  

  constructor() {
    
  }

  view: any[] = [700, 400];

  // options
  public gradient: boolean = true;
  public showLegend: boolean = false;
  public showLabels: boolean = true;
  public isDoughnut: boolean = false;
  public legendPosition: string = 'below';
  public legendTitle:string = "Report";

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
