import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { StrongPassword, MustMatch } from 'src/app/directives/regex.directive';
import { DataService } from 'src/app/services/data.service';
import { Invitation, StatusMessage, User } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cs-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  guid:string;
  public salutation:string;
  public found = false;
  
  registerForm: FormGroup;

  constructor(fb: FormBuilder,private data:DataService,private tools:ToolsService,public dialogRef: MatDialogRef<RegisterComponent>,@Inject(MAT_DIALOG_DATA) public dialogData: any) {
    
    this.guid = dialogData.guid;
    this.registerForm = fb.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      siteid:new FormControl("",Validators.required),      
      screenname: new FormControl(""),
      firstname: new FormControl("", Validators.required),
      surname: new FormControl("", Validators.required),
      password: new FormControl("", [Validators.required, StrongPassword]),
      confirmPassword: new FormControl("", [Validators.required])
    }, {
      validator: MustMatch('password', 'confirmPassword')
    }
    );

  }

  get email(){
    return this.registerForm.get("email");
  }

  get password(){
    return this.registerForm.get("password");
  }

  get confirmPassword(){
    return this.registerForm.get("confirmPassword");
  }
  
  ngOnInit(): void {
    this.data.inviteFromGuid(this.guid).subscribe((message:StatusMessage)=>{
      if(message.success && message.message){
        this.found = true;
        let invite:Invitation = message.message;
        this.registerForm.get("email").setValue(invite.email);
        this.registerForm.get("siteid").setValue(invite.siteid);
        this.salutation = invite.salutation;
      }
      else{
        this.tools.gracefulError("This invitation cannot be found or has expired. Please request a new one");
      }
      
    })
  }
  cancel(){
    this.dialogRef.close();
  }

  submit(){
    if(this.registerForm.valid){
      let user:User = this.registerForm.value;
      this.data.auth.register(user).subscribe((message:StatusMessage)=>{
        if(message.success){
          
          this.data.auth.user = message.message;
          this.data.auth.user.password = user.password;
          this.data.auth.signin().then((message:StatusMessage)=>{
            if(message.success){            
              this.dialogRef.close();
            }
            else{
              this.tools.gracefulError(message.message);
            }
          })
        }
        else{
          this.tools.gracefulError(message.message);
        }
      })
    }
  }
}
