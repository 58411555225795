import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Country, RecordStatus, ConfirmOption } from 'src/app/models/models';
import { GPS, Polygon, PolygonPoint, Region, Feature, RegionTypes, PathInfo, CornerIndices, BoundsCorners, FeaturePolygon, ZoneType } from 'src/app/models/rates';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialog } from '@angular/material/dialog';
import { LocationpickerComponent } from 'src/app/dialogs/locationpicker/locationpicker.component';
import { Subject } from 'rxjs';
import simplifygeometry from 'simplify-geometry';





@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})

export class ToolsComponent implements OnInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  regions: Region[] = [];
  parents: Region[] = [];
  regionChange: Subject<boolean> = new Subject();

  constructor(public data: DataService, public tools: ToolsService, public dialog: MatDialog) { }

  polysEditable = false;
  createGPSOnSelect = false;
  showDeletedZones = false;
  jsonfile: string = "";
  namefield: string = "name";
  regionType: number = 1;
  public regionTypes = RegionTypes;
  mapAdded = false;
  map: google.maps.Map;
  p1 = new google.maps.LatLng(0, 0);
  zonetype:number = 0;
  zonetypes = ZoneType;

  //path = [new google.maps.LatLng(75.739564, 41.344855),new google.maps.LatLng(75.639564, 41.844855),new google.maps.LatLng(75.439564, 41.944855)];
  poly: google.maps.Polygon;
  overpoly: google.maps.Polygon;
  combinedPoly: google.maps.Polygon;
  infowindow = new google.maps.InfoWindow();

  selectedRegion: Region;
  selectedParent: Region;
  //selectedPolygon;
  searchText: string = "";
  searchResults = [];
  lastVertex: number;
  deletedVertices = [];

  selectedPaths: number[] = [];
  polysToDelete: Polygon[] = [];

  hideList = false;

  exportSelected = false;
  addZoneMode = false;


  ngOnInit(): void {
    setTimeout(() => this.addMap());
  }

  pickLocation(startGPS?:GPS) {
    if(!startGPS){
      let address = this.data.auth.user.Site.Address;
      startGPS = new GPS();
      startGPS.lat = address.gpslat;
      startGPS.long = address.gpslong;
    }
    

    const dialogRef = this.dialog.open(LocationpickerComponent, {
      width: 'auto',
      data: { GPS: startGPS }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }

  search(parents?: boolean) {
    this.data.searchRegions(this.searchText).subscribe((message: StatusMessage) => {
      if (message.success) {
        if (parents) this.parents = message.message;
        else this.regions = message.message;
      }
    })
  }
  heldregions: Region[] = [];
  selectRegion(region) {
    if (region.children && region.children.length > 0) {
      this.heldregions = this.regions;
      this.regions = region.children;
    }
    else {
      if (!region.id) {
        this.selectAndPanTo(region);
      }
      else {
        if (!region.GPS) {
          if (region.countryid) {
            let countries = this.data.countries.filter(c => c.id == region.countryid);
            if (countries.length > 0) {
              let country = countries[0];
              region.gpscentreid = country.gpsid;
              region.GPS = country.GPS;
              region.zoom = 4;
              this.data.updateRegion(region).subscribe((message: StatusMessage) => {
                if (message.success) {
                  this.selectAndPanTo(region);
                }
              })

            }
            else {
              this.geocodeRegion(region).then((region: Region) => {
                this.selectAndPanTo(region);
              });
            }
          }
          else if (this.createGPSOnSelect) {
            this.geocodeRegion(region).then((region: Region) => {
              this.selectAndPanTo(region);
            });
          }

        }
        else this.selectAndPanTo(region);
      }
    }


  }
  selectAndPanTo(region) {
    this.selectedRegion = region;
    if (region.GPS) {
      let gps = new google.maps.LatLng(region.GPS.lat, region.GPS.long);
      this.map.panTo(gps);
      if (region.zoom) this.map.setZoom(region.zoom);
      this.regionChange.next(true);
    }
    else {
      this.data.gpsFromBounds(region, true).then(() => {
        let gps = new google.maps.LatLng(region.GPS.lat, region.GPS.long);
        this.map.panTo(gps);
        if (region.zoom) this.map.setZoom(region.zoom);
        this.regionChange.next(true);
      })
    }


  }
  geocodeRegion(region: Region) {
    return new Promise((resolve) => {
      this.data.geo.geocode(region.name).then((message: StatusMessage) => {
        let latlng = new google.maps.LatLng(message.message);
        let gps = new GPS();
        gps.lat = latlng.lat();
        gps.long = latlng.lng();
        this.data.createGPS(gps).subscribe((message: StatusMessage) => {
          region.GPS = message.message;
          region.gpscentreid = region.GPS.id;
          if (region.id) {
            this.data.updateRegion(region).subscribe((message: StatusMessage) => {
              if (message.success) {
                this.selectedRegion = region;
                resolve(region);
              }
            })
          }
          else {
            this.selectedRegion = region;
            resolve(region);
          }

        })
      })
    })

  }
  selectParent(region) {
    this.selectedParent = region;
  }

  changeEditable() {
    setTimeout(() => {
      let ed = { editable: this.polysEditable };
      this.poly.setOptions(ed);
    })

  }

  addMap() {
    if (!this.mapAdded) {

      const mapProperties = {
        center: new google.maps.LatLng(0, 0),
        zoom: 4,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.map.addListener('click', (e) => {
        this.mapClick(e);
      });

      this.poly = new google.maps.Polygon({
        editable: this.polysEditable
      });
      this.poly.addListener('mouseup', (e) => { this.polyedit(e) });
      this.poly.addListener('dragend', (e) => { this.polyedit(e) });
      this.poly.addListener('click', (e) => { this.polyclick(e) });
      this.poly.setMap(this.map);
    }
  }

  /**take the current region zones and export them to JSON */
  exportZonesToJson() {
    if (this.exportSelected) {
      let feature = new Feature();
      feature.recordStatus = 0;
      feature.Polygons = [];
      this.selectedRegion.Feature.Polygons.forEach(p => {
        if (this.selectedPaths.indexOf(p.id) > -1) {
          feature.Polygons.push(p);
        }
      })
      let json = JSON.stringify(feature);
      console.log(json);
    }
    else {
      let json = JSON.stringify(this.selectedRegion.Feature);
      console.log(json);
    }

  }



  polyedit(e) {
    console.log(e);
    let polypath = this.poly.getPath();
    if (e.edge || e.vertex || e.vertex == 0) {


      let polyref = this.selectedPaths[e.path];//zone id;
      let polys: FeaturePolygon[] = this.selectedRegion.Feature.FeaturePolygons.filter(p => p.polygonid == polyref);


      if (polys.length > 0) {
        let poly = polys[0];
        if (e.edge) {
          poly.Polygon.PolygonPoints = [];
          polypath.forEach(p => {
            let newpoint = new PolygonPoint();
            newpoint.lat = p.lat();
            newpoint.long = p.lng();
            poly.Polygon.PolygonPoints.push(newpoint);
          })
        }
        else {
          let polypoint = poly.Polygon.PolygonPoints[e.vertex];
          polypoint.lat = e.latLng.lat();
          polypoint.long = e.latLng.lng();
          polypoint._isDirty = true;
        }

      }

    }
  }
  polyclick(e) {
    console.log(e);
    if (e.vertex || e.vertex == 0) {
      this.lastVertex = e.vertex;
    }
  }
  showPoly(poly: Polygon, overridedeselect?: boolean) {
    let index = this.selectedPaths.indexOf(poly.id);
    if (index < 0) {
      //this.selectedPolygon = poly;
      this.selectedPaths.unshift(poly.id);
      let patharray = new google.maps.MVCArray<google.maps.LatLng>();
      poly.PolygonPoints.sort((a, b) => {
        if (a.id < b.id) return -1;
        else return 1;
      })
      poly.PolygonPoints.forEach(pp => {
        let latlng = new google.maps.LatLng(pp.lat, pp.long);
        patharray.push(latlng);
      })
      let currentPath = this.poly.getPaths();
      currentPath.insertAt(0, patharray);

      if (!poly.GPS) {
        let bounds = new google.maps.LatLngBounds();
        patharray.forEach(path => {
          bounds.extend(path);
        });
        let gps = new GPS();
        let centre = bounds.getCenter();
        gps.lat = centre.lat();
        gps.long = centre.lng();
        poly.GPS = gps;
        this.map.panTo(new google.maps.LatLng(gps.lat, gps.long));
        /*this.data.createGPS(gps).subscribe((message: StatusMessage) => {
          if (message.success) {
            gps.id = message.message.id;
            poly.gpsid = gps.id;
            this.data.updatePolygon(poly).subscribe((message: StatusMessage) => {
              this.map.panTo(new google.maps.LatLng(gps.lat, gps.long));
            })
          }
        })*/
      }
      else {
        this.map.panTo(new google.maps.LatLng(poly.GPS.lat, poly.GPS.long));
      }
    }
    else {
      //remove path from map
      if (!overridedeselect) {
        let currentPaths = this.poly.getPaths();
        currentPaths.removeAt(index);
        if (currentPaths.getLength() > 1) {
          let bounds = new google.maps.LatLngBounds();
          currentPaths.getArray().forEach(path => {
            path.forEach(p => {
              bounds.extend(p);
            })
          })
          this.map.panTo(bounds.getCenter());
        }

        this.selectedPaths.splice(index, 1);
      }


    }
  }
  deletePoly(poly: Polygon, index: number) {
    if (!poly._fake) {
      poly.recordStatus = RecordStatus.Deleted;
      this.data.updatePolygon(poly).subscribe((message: StatusMessage) => {
        if (message.success) {
          if (this.selectedPaths.indexOf(poly.id) >= 0) {
            this.showPoly(poly);
          }
        }
      })
    }
    else {
      this.selectedRegion.Feature.Polygons.splice(index, 1);
    }

  }
  makeMajor(poly: Polygon) {

    poly.major = !poly.major;
    this.data.updatePolygon(poly).subscribe((message: StatusMessage) => {
      if (message.success) {

      }
    })
  }
  removeVertex() {
    let path = this.poly.getPath();
    if (path.getLength() < 4) {
      alert("deleting this vertex will break the polygon");
    }
    path.removeAt(this.lastVertex - 1);
    console.log(path);
  }
  undoRemoveVertex() {
  }
  mapClick(e) {
    console.log(e);
    console.log(this.map.getZoom());
    console.log(e.latLng, e.latLng.lat());
    if (this.overpoly && !this.addZoneMode) {
      this.overpoly.setMap(null);
      this.overpoly = null;
      if (this.polysToDelete.length > 0) {
        let nowselected = this.selectedPaths;
        this.selectedPaths = [];
        this.poly.setPaths([]);
        nowselected.forEach(s => {
          let found = false;
          this.polysToDelete.some(pd => {
            if (nowselected.indexOf(pd.id) >= 0) {
              found = true;
              return true;
            }
          })
          if (!found) {
            this.selectedPaths.push(s);
          }
        })
        this.polysToDelete = [];

      }
      this.infowindow.close();
      if (this.selectedRegion) {
        if (this.selectedPaths.length > 0) {
          this.selectedRegion.Feature.Polygons.forEach(p => {
            if (this.selectedPaths.indexOf(p.id) >= 0) {
              this.showPoly(p);
            }
          })
        }

      }


    }
    else {
      let clicklat = e.latLng.lat();
      let clicklng = e.latLng.lng();
      let newpath = new google.maps.MVCArray<google.maps.LatLng>();
      newpath.push(new google.maps.LatLng(clicklat + 0.5, clicklng + 0.5));
      newpath.push(new google.maps.LatLng(clicklat + 0.5, clicklng - 0.5));
      newpath.push(new google.maps.LatLng(clicklat - 0.5, clicklng - 0.5));
      newpath.push(new google.maps.LatLng(clicklat - 0.5, clicklng + 0.5));


      let newpoly = new google.maps.Polygon({
        editable: true,
        fillColor: "#FF0000",
        paths: newpath
      });

      newpoly.setMap(this.map);
      if (!this.addZoneMode) {
        newpoly.addListener('click', (e) => {
          this.groupPoly(e);
        })
        newpoly.addListener('dragend', (e) => {
          console.log("Drag Ended",e);
        })
        this.infowindow = new google.maps.InfoWindow({ pixelOffset: new google.maps.Size(-50, -  50) });
        let content = "<p>Resize your polygon then click to select included areas</p><p>Click again to delete them or somewhere else to cancel</p>";
        this.infowindow.setContent(content);
        this.infowindow.setPosition(e.latLng);
        this.infowindow.open(this.map);
        this.infowindow.addListener('closeclick', () => {
          console.log("do something on close")
        })
      }


      this.overpoly = newpoly;
    }

  }
  addZone() {
    let newpoly = new Polygon();
    newpoly.PolygonPoints = [];
    newpoly._fake = true;
    newpoly.featureid = this.selectedRegion.featureid;
    if(!newpoly.featureid){
      let feature = new Feature();
      this.selectedRegion.Feature = feature;
    }

    let centre = this.map.getCenter();

    let p1 = new PolygonPoint();
    p1.lat = centre.lat() - 0.5;
    p1.long = centre.lng() - 0.5;
    newpoly.PolygonPoints.push(p1);

    let p2 = new PolygonPoint();
    p2.lat = centre.lat() + 0.5;
    p2.long = centre.lng() - 0.5;
    newpoly.PolygonPoints.push(p2);

    let p3 = new PolygonPoint();
    p3.lat = centre.lat() + 0.5;
    p3.long = centre.lng() + 0.5;
    newpoly.PolygonPoints.push(p3);

    let p4 = new PolygonPoint();
    p4.lat = centre.lat() - 0.5;
    p4.long = centre.lng() + 0.5;
    newpoly.PolygonPoints.push(p4);
    let fp = new FeaturePolygon();
    fp.Polygon = newpoly;
    this.selectedRegion.Feature.FeaturePolygons.push(fp);
    this.showPoly(newpoly);


  }
  groupPoly(e) {
    if (this.polysToDelete.length > 0) {
      this.deleteEnclosed();
    }
    else {
      this.polysToDelete = [];
      let pt = google.maps.geometry.poly;
      console.log(e);
      if (this.selectedRegion) {
        this.selectedRegion.Feature.Polygons.forEach(p => {
          let inside = true;
          p.PolygonPoints.some(pp => {
            let latlng = new google.maps.LatLng(pp.lat, pp.long);
            if (!pt.containsLocation(latlng, this.overpoly)) {
              inside = false;
              return true;
            }
          })
          if (inside) {
            this.polysToDelete.push(p);
          }
        })
      }
      this.polysToDelete.forEach(p => {
        this.showPoly(p);
      })
      console.log(this.polysToDelete);
    }

  }
  deleteEnclosed() {
    let deletecount = this.polysToDelete.length;
    let deleted = 0;
    this.polysToDelete.forEach(p => {
      p.recordStatus = 3;
      this.data.updatePolygon(p).subscribe((message: StatusMessage) => {

        if (message.success) {
          deleted++;
          this.showPoly(p);
          if (deleted == deletecount) {
            this.polysToDelete = [];
            this.tools.snackMessage("Zones Deleted");
            this.overpoly.setMap(null);
          }
        }
      });
    })
  }
  /**
  * for a given path what are the indices of thee vertices closest to the corners of the bounds
  * @param path 
  */
  vertexIndexOfBoundsCorners(path: google.maps.MVCArray<google.maps.LatLng>): PathInfo {

    let corners = this.fourCorners(path);
    let index = 0;
    let offsets = [Infinity, Infinity, Infinity, Infinity];
    let indices = [-1, -1, -1, -1];
    path.forEach(p => {
      this.calcOffsets(index, 0, corners.ne, p, offsets, indices);
      this.calcOffsets(index, 1, corners.se, p, offsets, indices);
      this.calcOffsets(index, 2, corners.sw, p, offsets, indices);
      this.calcOffsets(index, 3, corners.nw, p, offsets, indices);
      index++;
    });

    let pathinfo: PathInfo = { path: path, centre: corners.centre, cornerindices: indices, isClockwise: this.getPathDirection(indices), length: path.getLength(), startindex: 0, endindex: 0 };
    return pathinfo;
  }

  fourCorners(path): BoundsCorners {
    let bounds = this.getPathBounds(path);
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let se = new google.maps.LatLng(sw.lat(), ne.lng());
    let nw = new google.maps.LatLng(ne.lat(), sw.lng());
    let bc: BoundsCorners = { ne: ne, se: se, sw: sw, nw: nw, centre: bounds.getCenter() };
    return bc;
  }

  logLatLng(name: string, latlng: google.maps.LatLng) {
    console.log("name: " + name + ", lat:" + latlng.lat() + " lng:" + latlng.lng());
  }

  getPathDirection(indices: number[]) {
    //sample points to get pathdirection
    let sample = 0;
    if (indices[CornerIndices.NorthEast] >= indices[CornerIndices.NorthWest]) {
      sample++;
    }
    if (indices[CornerIndices.SouthEast] >= indices[CornerIndices.NorthEast]) {
      sample++;

    }
    if (indices[CornerIndices.SouthWest] >= indices[CornerIndices.SouthEast]) {
      sample++;
    }
    if (sample >= 2) return true;
    return false;
  }
  calcOffsets(pointindex: number, cornerindex: number, origin: google.maps.LatLng, point: google.maps.LatLng, offsetsarray: number[], indexarray: number[]) {
    let sphere = google.maps.geometry.spherical;
    let offset = sphere.computeDistanceBetween(origin, point);
    console.log("Point " + pointindex + " is " + offset + "m from Corner " + cornerindex);
    if (offset < offsetsarray[cornerindex]) {
      offsetsarray[cornerindex] = offset;
      indexarray[cornerindex] = pointindex;
    }
  }


  getPathBounds(path: google.maps.MVCArray<google.maps.LatLng>): google.maps.LatLngBounds {
    let bounds = new google.maps.LatLngBounds();
    path.forEach(p => {
      bounds.extend(p);
    })
    return bounds;
  }

  mergePaths() {
    let paths = this.poly.getPaths();
    let newpath = this.getMergedPath(paths.getAt(0), paths.getAt(1));
    console.log(newpath);
    let newpoly = new google.maps.Polygon({
      editable: true,
      fillColor: "#FF0000",
      paths: newpath
    });
    newpoly.setMap(this.map);
  }
  bounds() {
    let paths = this.poly.getPaths();
    let c1 = this.fourCorners(paths.getAt(0));
    let c2 = this.fourCorners(paths.getAt(1));
    paths.push(new google.maps.MVCArray<google.maps.LatLng>([c1.ne, c1.se, c1.sw, c1.nw]));
    paths.push(new google.maps.MVCArray<google.maps.LatLng>([c2.ne, c2.se, c2.sw, c2.nw]));
  }
  clearAll() {
    this.selectedPaths = [];
    if (this.poly) {
      this.poly.setPaths([]);
    }
    if (this.combinedPoly) {
      this.combinedPoly.setPaths([]);
    }
    if (this.overpoly) {
      this.overpoly.setPaths([]);
    }

  }

  manuallyMerge() {
    let paths = this.poly.getPaths();
    let path1 = paths.getAt(0);
    let path2 = paths.getAt(1);

    let closest = this.getClosestNodes(path1, path2);
    path1 = this.makeIndexFirstOfPath(closest[0], path1);
    path2 = this.makeIndexFirstOfPath(closest[1], path2);

    let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    for (let i = 0; i < path1.getLength(); i++) {
      newpath.push(path1.getAt(i));
    }
    for (let i = 0; i < path2.getLength(); i++) {
      newpath.push(path2.getAt(i));
    }
    this.combinedPoly = new google.maps.Polygon({
      paths: newpath,
      editable: true,
      strokeColor: '#00FF00',
      strokeWeight: 2
    })
    this.combinedPoly.addListener('click', (e) => {
      this.mergeAndReplace(this.combinedPoly);
    })
    console.log(this.combinedPoly);
    this.combinedPoly.setMap(this.map);
    /*
        let checkline = new google.maps.Polyline({
          path: newpath,
          strokeColor: '#00FFFF',
          strokeWeight: 3
        })
        checkline.setMap(this.map);
        
        
        let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    
        let pathinfo1 = this.vertexIndexOfBoundsCorners(path1);
        let pathinfo2 = this.vertexIndexOfBoundsCorners(path2);
    
        this.setStartingVerticesFromHeading(pathinfo1, pathinfo2);
    
        this.buildMergePath(pathinfo1, pathinfo2, newpath);
    
        newpath.push(path1.getAt(pathinfo1.startindex));
    
        let newpoly = new google.maps.Polygon({
          editable: true,
          draggable: true,
          fillColor: "#FF0000",
          paths: newpath
        });
        newpoly.setMap(this.map);
    */
  }
  mergeAndReplace(mergedpoly: google.maps.Polygon) {

    let p1 = this.selectedRegion.Feature.Polygons.filter(p => p.id == this.selectedPaths[0])[0];
    let p2 = this.selectedRegion.Feature.Polygons.filter(p => p.id == this.selectedPaths[1])[0];
    if (p1 && p2) {
      p1.recordStatus = 3;
      p2.recordStatus = 3;
      this.data.updatePolygon(p1).subscribe(() => {

      })
      this.data.updatePolygon(p2).subscribe(() => {

      })
      let newpoly = new Polygon();
      newpoly.featureid = this.selectedRegion.featureid;
      let gps = new GPS();
      let path = mergedpoly.getPaths().getAt(0);
      let bounds = new google.maps.LatLngBounds();
      path.forEach(p => {
        bounds.extend(p);
      });
      path.forEach(point => {
        let polypoint = new PolygonPoint();
        polypoint.lat = point.lat();
        polypoint.long = point.lng();
        newpoly.PolygonPoints.push(polypoint);
      })
      let latlng = bounds.getCenter();
      gps.lat = latlng.lat();
      gps.long = latlng.lng();
      this.data.createGPS(gps).subscribe((message: StatusMessage) => {
        if (message.success) {
          newpoly.gpsid = message.message.id;

          this.data.createPolygon(newpoly).subscribe((message: StatusMessage) => {
            if (message.success) {
              this.showPoly(p1);
              this.showPoly(p2);
              this.selectedRegion.Feature.Polygons.unshift(message.message);
              if (this.combinedPoly) this.combinedPoly.setPaths([]);
              this.showPoly(this.selectedRegion.Feature.Polygons[0]);
            }
          })
        }
      })
    }


  }
  getClosestNodes(path1: google.maps.MVCArray<google.maps.LatLng>, path2: google.maps.MVCArray<google.maps.LatLng>): number[] {
    let distance = Infinity;
    let nodes;[-1, -1];
    let sphere = google.maps.geometry.spherical;
    let p1index = 0;
    let p2index = 0;
    path1.forEach(p => {
      p2index = 0;
      path2.forEach(p2 => {
        let offset = sphere.computeDistanceBetween(p, p2);
        if (offset < distance) {
          nodes = [p1index, p2index];
        }
        p2index++;
      })
      p1index++;
    })
    return nodes;
  }
  makeIndexFirstOfPath(index: number, path: google.maps.MVCArray<google.maps.LatLng>) {
    let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    for (let i = index; i < path.getLength() - 2; i++) {
      newpath.push(path.getAt(i));
    }
    for (let i = 0; i < index; i++) {
      newpath.push(path.getAt(i));
    }
    return newpath;
  }
  setStartingVerticesFromHeading(pathinfo1: PathInfo, pathinfo2: PathInfo) {
    let sphere = google.maps.geometry.spherical;
    let heading = sphere.computeHeading(pathinfo1.centre, pathinfo2.centre) + 180;

    if (heading >= 45 && heading < 135) {//angle of -135 to -45 from origin, origin east of destination
      pathinfo1.startindex = pathinfo1.cornerindices[CornerIndices.NorthEast];
      pathinfo1.endindex = pathinfo1.cornerindices[CornerIndices.SouthEast];
      pathinfo2.startindex = pathinfo2.cornerindices[CornerIndices.NorthWest];
      pathinfo2.endindex = pathinfo2.cornerindices[CornerIndices.SouthWest];
    }
    else if (heading >= 135 && heading < 225) {//angle of -45 to 45 from origin, origin south of destination
      pathinfo1.startindex = pathinfo1.cornerindices[CornerIndices.NorthWest];
      pathinfo1.endindex = pathinfo1.cornerindices[CornerIndices.NorthEast];
      pathinfo2.startindex = pathinfo2.cornerindices[CornerIndices.SouthWest];
      pathinfo2.endindex = pathinfo2.cornerindices[CornerIndices.SouthEast];
    }
    else if (heading >= 225 && heading < 315) {//angle of 45 to 135 from origin, destination east of origin
      pathinfo1.startindex = pathinfo1.cornerindices[CornerIndices.NorthEast];
      pathinfo1.endindex = pathinfo1.cornerindices[CornerIndices.SouthEast];
      pathinfo2.startindex = pathinfo2.cornerindices[CornerIndices.SouthWest];
      pathinfo2.endindex = pathinfo2.cornerindices[CornerIndices.SouthEast];
    }
    else { //angle 135 to -135 origin north of destination.
      pathinfo1.startindex = pathinfo1.cornerindices[CornerIndices.NorthEast];
      pathinfo1.endindex = pathinfo1.cornerindices[CornerIndices.NorthWest];
      pathinfo2.startindex = pathinfo2.cornerindices[CornerIndices.SouthEast];
      pathinfo2.endindex = pathinfo2.cornerindices[CornerIndices.SouthWest];
    }
  }
  buildMergePath(pathinfo1: PathInfo, pathinfo2: PathInfo, newpath) {
    if (pathinfo1.endindex < pathinfo1.startindex) {
      for (let i = pathinfo1.startindex; i < pathinfo1.length; i++) {
        newpath.push(pathinfo1.path.getAt(i));
      }
      for (let i = 1; i <= pathinfo1.endindex; i++) {
        newpath.push(pathinfo1.path.getAt(i));
      }
    }
    else {
      for (let i = pathinfo1.startindex; i <= pathinfo1.endindex; i++) {
        newpath.push(pathinfo1.path.getAt(i));
      }
    }

    if (pathinfo2.endindex < pathinfo2.startindex) {
      for (let i = pathinfo2.startindex; i < pathinfo2.length; i++) {
        newpath.push(pathinfo2.path.getAt(i));
      }

      for (let i = 1; i <= pathinfo2.endindex; i++) {
        newpath.push(pathinfo2.path.getAt(i));
      }
    }
    else {
      for (let i = pathinfo2.startindex; i <= pathinfo2.endindex; i++) {
        newpath.push(pathinfo2.path.getAt(i));
      }
    }
  }
  getMergedPath(patha: google.maps.MVCArray<google.maps.LatLng>, pathb: google.maps.MVCArray<google.maps.LatLng>): google.maps.MVCArray<google.maps.LatLng> {
    let sphere = google.maps.geometry.spherical;
    let pathinfoa = this.vertexIndexOfBoundsCorners(patha);
    /*let vertexpath = new google.maps.MVCArray<google.maps.LatLng>();
    pathinfoa.cornerindices.forEach(ci=>{
      vertexpath.push(pathinfoa.path.getAt(ci));
    })
    this.poly.getPaths().push(vertexpath);*/
    console.log("Path a length: ", pathinfoa.path.getLength(), pathinfoa);
    let pathinfob = this.vertexIndexOfBoundsCorners(pathb);
    /*let vertexpathb = new google.maps.MVCArray<google.maps.LatLng>();
    pathinfob.cornerindices.forEach(ci=>{
      vertexpathb.push(pathinfob.path.getAt(ci));
    })
    this.poly.getPaths().push(vertexpathb);*/

    console.log("Path b length: ", pathinfob.path.getLength(), pathinfob);

    /*
        if (heading >= 45 && heading < 135) {
          return this.mergeRightToLeft(pathinfoa, pathinfob)
        }
        else if (heading >= 135 && heading < 225) {
          return this.mergeBottomToTop(pathinfoa, pathinfob);
        }
        else if (heading >= 225 && heading < 315) {
          return this.mergeLeftToRight(pathinfoa, pathinfob);
        }
        else */
    return this.mergeTopToBottom(pathinfoa, pathinfob);


  }
  mergeTopToBottom(pathinfoa: PathInfo, pathinfob: PathInfo): google.maps.MVCArray<google.maps.LatLng> {
    let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    if (pathinfoa.isClockwise) {
      this.addClockwisePath(pathinfoa, newpath, pathinfoa.cornerindices[CornerIndices.NorthEast], pathinfoa.cornerindices[CornerIndices.NorthWest]);
      if (pathinfob.isClockwise) {
        this.addClockwisePath(pathinfob, newpath, pathinfob.cornerindices[CornerIndices.SouthWest], pathinfoa.cornerindices[CornerIndices.SouthEast]);
      }
      else {
        this.addCounterClockwisePath(pathinfob, newpath, pathinfob.cornerindices[CornerIndices.SouthWest], pathinfoa.cornerindices[CornerIndices.SouthEast], true);
      }
      newpath.push(pathinfoa.path.getAt(pathinfoa.cornerindices[CornerIndices.NorthEast]));
    }
    else {
      this.addCounterClockwisePath(pathinfoa, newpath, pathinfoa.cornerindices[CornerIndices.NorthWest], pathinfoa.cornerindices[CornerIndices.NorthEast]);
      if (pathinfob.isClockwise) {
        this.addClockwisePath(pathinfob, newpath, pathinfob.cornerindices[CornerIndices.SouthWest], pathinfoa.cornerindices[CornerIndices.SouthEast], true);
      }
      else {
        this.addCounterClockwisePath(pathinfob, newpath, pathinfob.cornerindices[CornerIndices.SouthWest], pathinfoa.cornerindices[CornerIndices.SouthEast]);
      }
      newpath.push(pathinfoa.path.getAt(pathinfoa.cornerindices[CornerIndices.NorthWest]));
    }

    return newpath;
  }
  addClockwisePath(pathinfo: PathInfo, newpath: google.maps.MVCArray<google.maps.LatLng>, startindex: number, endindex: number, reverse?: boolean) {
    if (endindex < startindex) {
      let length = pathinfo.path.getLength();
      for (let i = startindex; i < length - 1; i++) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
      for (let i = 0; i < endindex; i++) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
    }
    else {
      for (let i = startindex; i <= endindex; i++) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
    }
  }
  addCounterClockwisePath(pathinfo: PathInfo, newpath: google.maps.MVCArray<google.maps.LatLng>, startindex: number, endindex: number, reverse?: boolean) {
    if (startindex < endindex) {
      for (let i = endindex; i >= startindex; i--) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
    }
    else {
      for (let i = startindex; i >= 0; i--) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
      for (let i = pathinfo.length - 1; i >= endindex; i++) {
        if (reverse) newpath.insertAt(0, pathinfo.path.getAt(i));
        else newpath.push(pathinfo.path.getAt(i));
      }
    }
  }
  mergeBottomToTop() {
  }
  mergeRightToLeft() {
  }
  mergeLeftToRight() {
  }
  getPathPointsFromAtoB() {

  }
  polyInfo() {
    let paths = this.poly.getPaths();
    let p1 = paths.getAt(0);
    let pathinfo1 = this.vertexIndexOfBoundsCorners(p1);
    let startindex = pathinfo1.cornerindices[CornerIndices.SouthWest];
    let endindex = pathinfo1.cornerindices[CornerIndices.SouthEast];
    let length = p1.getLength();

    let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    this.clockpath(newpath, startindex, endindex, p1, length);
    let newpoly = new google.maps.Polygon({
      editable: true,
      fillColor: "#FF0000",
      paths: newpath
    });
    newpoly.setMap(this.map);
  }


  v2(p1, p2) {
    let pathinfo1 = this.vertexIndexOfBoundsCorners(p1);
    let pathinfo2 = this.vertexIndexOfBoundsCorners(p1);

    let startindex = pathinfo1.cornerindices[CornerIndices.NorthEast];
    let endindex = pathinfo1.cornerindices[CornerIndices.NorthWest];
    let length = p1.getLength();

    let newpath = new google.maps.MVCArray<google.maps.LatLng>();
    this.clockpath(newpath, startindex, endindex, p1, length);

    let startindex2 = pathinfo2.cornerindices[CornerIndices.SouthWest];
    let endindex2 = pathinfo2.cornerindices[CornerIndices.SouthEast];
    let length2 = p2.getLength();
    this.clockpath(newpath, startindex2, endindex2, p2, length2);

    let newpoly = new google.maps.Polygon({
      editable: true,
      fillColor: "#FF0000",
      paths: newpath
    });
    newpoly.setMap(this.map);

  }
  clockpath(newpath, startindex, endindex, oldpath, length) {
    console.log(startindex, endindex);
    if (endindex < startindex) {
      for (let i = startindex; i < length; i++) {
        newpath.push(oldpath.getAt(i));
      }
      for (let i = 0; i <= endindex; i++) {
        newpath.push(oldpath.getAt(i));
      }
    }
    else {
      for (let i = startindex; i <= endindex; i++) {
        newpath.insertAt(0, oldpath.getAt(i));
      }
    }
    console.log("newpath now ", newpath.getLength());
  }

  polygonsAbstract(zonetype?:ZoneType) {
    if(!zonetype) zonetype = ZoneType.NationalRegion;
    let geo: any;
    this.regions = [];
    this.data.regionPolys(this.jsonfile).subscribe((json: string) => {
      geo = json;
      console.log(geo);
      this.regionFromFeatures(geo,zonetype).then((regions: Region[]) => {
        regions = regions.sort((a,b)=>{
          if(a.name<b.name) return -1;
          else return 1;
        })
        this.regions = regions;
        console.log(regions);
      });


      console.log(this.regions);
    });
  }
  /*bit hacky this, zonetype is very similar to region type but didn't want to break
    existing functionality (and probably have)
   
  regionTypeToZone(regionType:RegionTypes){
    let zonetype:ZoneType;
    switch(regionType){
      case RegionTypes.Continental:
        zonetype = ZoneType.Continental;
        break;
      case RegionTypes.International:
        zonetype = ZoneType.International;
        break;
      case RegionTypes.
    }
  }*/

  /**
   * 
   * @param geo 
   * @param parent 
   */
  regionFromFeatures(geo: any,zonetype:ZoneType,parent?:Region) {
    return new Promise((resolve) => {
      let container: Region[] = [];
      let id = 1;
      let todo = geo.features.length;
      let index = 0;
      geo.features.forEach(ft => {
        let name = ft.properties[this.namefield];

        let region = new Region(parent?parent.companyid:this.selectedParent.companyid,zonetype);

        if (ft.properties.latlng) {
          let gps = new GPS();
          let latlng = ft.properties.latlng.split(',');
          gps.lat = latlng[0];
          gps.long = latlng[1];
          region.GPS = gps;
        }
        else if (ft.properties.lat && ft.properties.long) {
          let gps = new GPS();
          gps.lat = ft.properties.lat;
          gps.long = ft.properties.long;
          region.GPS = gps;
        }
        else if(ft.properties.geo_point_2d){
          let gps = new GPS();
          gps.lat = ft.properties.geo_point_2d[0];
          gps.long = ft.properties.geo_point_2d[1];
          region.GPS = gps;
        }
        region.companyid = 0;
        if(parent){
          region.parentId = parent.id;
          region.countryid = parent.countryid;
          region.continentid = parent.continentid;
        }
        else if (this.selectedParent) {
          region.parentId = this.selectedParent.id;
          region.continentid = this.selectedParent.continentid;
          region.countryid = this.selectedParent.countryid;
        }
        //region.countryid = country.id;
        region.name = name;
        region.regiontypeid = this.regionType;
        switch (this.regionType) {
          case RegionTypes.International:
            region.zoom = 1;
            break;
          case RegionTypes.National:
            region.zoom = 4;
            break;
          case RegionTypes.Regional:
            region.zoom = 6;
            break;
          case RegionTypes.Local:
            region.zoom = 8;
            break;
          default:
            region.zoom = 4;
        }
        let feature = new Feature();
        feature.name = name;
        if(ft.geometry){
          if (ft.geometry.type && ft.geometry.type == "MultiPolygon") {
            ft.geometry.coordinates.forEach(coord => {
              let newpoly: Polygon = new Polygon();
              newpoly.id = id;
              newpoly._fake = true;
              id++;
              let coordpoints = coord[0];
              coordpoints.forEach(cp => {
                let point = new PolygonPoint();
                point.lat = cp[1];
                point.long = cp[0];
                newpoly.PolygonPoints.push(point);
              })
              
              let fp = new FeaturePolygon();
              fp.Polygon = newpoly;
              feature.FeaturePolygons.push(fp);
            })
          }
          else if (ft.geometry.type && ft.geometry.type == "Polygon") {
            let newpoly: Polygon = new Polygon();
            newpoly.id = id;
            newpoly._fake = true;
            id++;
  
            ft.geometry.coordinates[0].forEach(coord => {
              let point = new PolygonPoint();
              point.lat = coord[1];
              point.long = coord[0];
              newpoly.PolygonPoints.push(point);
  
            })
            let fp = new FeaturePolygon();
              fp.Polygon = newpoly;
              feature.FeaturePolygons.push(fp);
          }
          else if (ft.geometry.geometries) {
            ft.geometry.geometries.forEach(g => {
              let newpoly = new Polygon();
              newpoly.id = id;
              id++;
              newpoly._fake = true;
              g.coordinates[0].forEach(c => {
                let point = new PolygonPoint();
                point.lat = c[1];
                point.long = c[0];
                newpoly.PolygonPoints.push(point);
              })
              let fp = new FeaturePolygon();
              fp.Polygon = newpoly;
              feature.FeaturePolygons.push(fp);
            })
          }
          region.Feature = feature;
          if (!region.GPS) {
            this.data.gpsFromBounds(region, true).then(() => {
              index++;
              container.push(region);
              if (index == todo) {
                resolve(container);
              }
            })
          }
          else {
            index++;
            container.push(region);
            if (index == todo) {
              resolve(container);
            }
  
          }
        }
        else{
          console.log(ft,index,todo);
          index++;
          if (index == todo) {
            resolve(container);
          }
        }

      });
    })

  }
  polygonsCountries() {

    let geo: any;
    this.data.regionPolys().subscribe((json: string) => {
      geo = json;
      let errors: string[] = [];


      geo.features.forEach(ft => {
        let name = ft.properties.Name;
        let iso2 = ft.properties.description.substring(ft.properties.description.indexOf('=') + 1).substring(0, 2);
        let countries: Country[] = this.data.countries.filter(c => c.iso_alpha_2 == iso2);
        if (countries.length == 1) {

          let country = countries[0];
          //create a gps centre
          if (!country.GPS) {
            let newgps = new GPS();
            let centre = ft.geometry.geometries.filter(g => g.type == "Point")[0];
            newgps.lat = centre.coordinates[1];
            newgps.long = centre.coordinates[0];
            country.GPS = newgps;
          }
          let region = new Region(0,ZoneType.National);
          region.GPS = country.GPS;
          region.countryid = country.id;
          region.name = country.name;
          region.regiontypeid = RegionTypes.National;
          let feature = new Feature();
          feature.name = name;

          //add the polygons
          let polys = ft.geometry.geometries.filter(g => g.type = "Polygon");
          if (polys.length > 0) {
            polys.forEach(p => {
              let poly = new Polygon();
              if (typeof p.coordinates[0] == "number") {
                //handle a single point
                let point = new PolygonPoint();
                point.lat = p.coordinates[1];
                point.long = p.coordinates[0];
                poly.PolygonPoints.push(point);
              }
              else {
                p.coordinates[0].forEach(coord => {
                  let point = new PolygonPoint();
                  point.lat = coord[1];
                  point.long = coord[0];
                  poly.PolygonPoints.push(point);
                });
              }
              feature.Polygons.push(poly);

            })
          }
          region.Feature = feature;
          this.regions.push(region);


        }
        else {
          errors.push("iso:" + iso2 + ",name:" + name);
        }

      })
      console.log(errors);
      console.log(this.regions);
    })

  }
  loadJSON() {
    this.data.regionPolys(this.jsonfile).subscribe((json: string) => {
      console.log(json);
    })

  }
  selectHeld(region){
    this.regions = region.children;
    this.updated = 0;
    this.doneSome = 0;
  }
  loadGithubPostcode() {
    let regions: Region[] = [];
    let resource = "https://raw.githubusercontent.com/missinglink/uk-postcode-polygons/master/geojson/";
    this.data.loadJson("pcode-links").subscribe((data: any) => {
      let todo = data.length;
      let done = 0;
      data.some(file => {
        let outcode = file.file.split('.')[0];
        this.data.getBaseRegionByName(4, outcode,ZoneType.NationalRegion).subscribe((message: StatusMessage) => {
          if (message.success) {
            let region = message.message;
            this.selectedParent = region;
            if (region) {
              if (!region.children || region.children.length == 0) {
                this.data.loadApiJson(file.file, resource).subscribe((geo: any) => {
                  this.regionFromFeatures(geo,region).then((children: Region[]) => {
                    region.children = children;
                    regions.push(region);
                    done++
                    if (done == todo) {
                      this.regions = regions;
                      console.log(this.regions, "Done");
                      return true;
                    }
                  })


                })
              }
              else{
                console.log("already done: ",region);
                done++;
              }
            }
            else{
              done++;
              console.log("error finding",outcode);
            } 

          }

        })

      })

      console.log("Postcode In Progress");
    })
  }
  saveRegion() {
    if (!this.selectedRegion.id) {
      this.createRegion();
    }
    else {
      this.data.updateRegion(this.selectedRegion).subscribe((message: StatusMessage) => {
        if (message.success) {
          if (this.selectedRegion.Feature) {
            if(this.selectedRegion.featureid){
              this.selectedRegion.Feature.FeaturePolygons.forEach(fp=>{
                if(fp.id){
                  this.data.updateFeaturePolygon(fp).subscribe((message:StatusMessage)=>{
                    
                    if(message.success){
                      fp = message.message;
                    }
                    else this.tools.gracefulError(message.message);
                  })
                  
                }
                else{
                    this.data.createFeaturePolygon(fp).subscribe((message:StatusMessage)=>{
                      if(message.success){
                        fp.id = message.message.id;
                        this.data.updateFeaturePolygon(fp).subscribe((message:StatusMessage)=>{
                          if(message.success){
                            fp = message.message;
                          }
                          else this.tools.gracefulError(message.message);
                        })                        
                      }
                    })
                  }
              })
              
            }
            else{
              this.data.createFeatureAndFeaturePolygons(this.selectedRegion.Feature,this.selectedRegion.id).subscribe((message:StatusMessage)=>{
                if(message.success){
                  this.selectedRegion.Feature = message.message;
                }
                else this.tools.gracefulError(message.message);
              })
            }
            /*if (this.selectedRegion.Feature.Polygons) {
              this.selectedRegion.Feature.Polygons.forEach(poly => {
                this.data.updatePolygon(poly).subscribe(() => {
                  poly.PolygonPoints.forEach(pp => {
                    pp._isDirty = false;
                  })
                })
              })
            }*/
          }
        }
      })
    }
  }
  createRegion() {
    if (this.selectedParent || this.regionType == 1) {
      this.tools.confirm("are you sure? Base Region creation, region:" + this.selectedRegion.name + " parent: " + this.selectedParent.name, true, true).then((res: ConfirmOption) => {
        if (res == ConfirmOption.YES) {
          this.selectedRegion.parentId = this.selectedParent.id;
          this.selectedRegion.regiontypeid = this.regionType;
          if (this.selectedRegion.regiontypeid == RegionTypes.National) this.selectedRegion.continentid = this.selectedParent.id;

          this.data.createRegionWithFeature(this.selectedRegion).subscribe((message: StatusMessage) => {
            if (message.success) {
              this.selectedRegion.id = message.message.id;
            }
          })
        }

      })

    }
    else {
      this.tools.gracefulError("Parent not set");
    }


  }
  createMasterRegion() {
    this.regions.forEach(r => {
      r.companyid = 0;
      r.countryid = this.selectedParent.countryid;
      r.continentid = this.selectedParent.continentid;

    })
  }
  /**
   * don't run this doesn't work
   */
  geoCodeCountries() {
    this.data.countries.forEach((co: Country) => {
      this.data.countryGPS(co).then(() => {

      })
    })
  }
  geoCodeCountriesFromBounds() {
    this.data.listCountriesByContinentPolys(this.selectedRegion.id).subscribe((message: StatusMessage) => {
      if (message.success) {
        let regions: Region[] = message.message;
        regions.forEach(r => {
          if (!r.gpscentreid) {
            this.data.gpsFromBounds(r).then(() => {

            })
          }

        })
      }
    })
  }
  mapCountriesToContinents() {
    this.data.listContinents().subscribe((message: StatusMessage) => {
      let continents: Region[] = message.message;
      this.data.countries.forEach((co: Country) => {
        this.data.getCountryRegion(co.id).subscribe((message: StatusMessage) => {
          let region = message.message;
          if (region && region.id) {
            let filtered = continents.filter(c => c.id == region.continentid);
            if (filtered.length > 0) {
              region.parentId = filtered[0].id;
              this.data.updateRegion(region).subscribe(() => { });
            }
          }

        })
      })
    })


  }

  public updated = 0;
  public iterateUpdate = false;
  public doSome = 1;
  public doneSome = 0;
  update() {
    let region = this.regions[this.updated];
    this.createGPS(region.GPS).then((gpsid: number) => {
      region.gpscentreid = gpsid;

      this.data.createRegionWithFeature(region).subscribe((message: StatusMessage) => {
        if (message.success) {
          console.log(message.message);
          this.updated++;
          if (this.updated < this.regions.length) {
            if (this.iterateUpdate) {
              this.doneSome++;
              if (this.doneSome == this.doSome) {
                this.doneSome = 0;
              }
              else {
                setTimeout(() => { this.update() });
              }


            }

          }
        }
        else {
          console.log(message);
        }
      }, err => {
        console.log(err);
      })
    })
    /*
      let country = this.data.countries[this.updated];
      this.data.updateCountry(country).subscribe((message:StatusMessage)=>{
        if(message.success){
          country = message.message;
          this.updated++;
        }
        else{
          console.log(country);
          console.log(message.message);
          this.updated++;
        }
      })*/

  }
  public skimSize: number = 5;
  skimTiny() {
    if (this.selectedRegion.Feature && this.selectedRegion.Feature.Polygons) {
      let totes = this.selectedRegion.Feature.Polygons.length;
      for (let i = totes - 1; i >= 0; i--) {
        let poly = this.selectedRegion.Feature.Polygons[i];
        if (poly.PolygonPoints && poly.PolygonPoints.length <= this.skimSize) {
          this.selectedRegion.Feature.Polygons.splice(i, 1);
        }
      }
    }

  }
  simplify(){
    if(this.selectedRegion && this.selectedRegion.Feature){
      let todo:FeaturePolygon[] = [];
      this.selectedPaths.forEach(sp=>{
        this.selectedRegion.Feature.FeaturePolygons.forEach(fp=>{
          if(fp.Polygon.id==sp){
            todo.push(fp);
          }
        })
      })
      todo.forEach(t=>{
        let points = t.Polygon.PolygonPoints.map(pp=>[pp.lat,pp.long]);
        let newpoints:any[] = simplifygeometry(points,this.skimSize);
        console.log("Post simplilfy",points.length,newpoints.length);
        t.Polygon.PolygonPoints = [];
        newpoints.forEach(element => {
          let newpolypoint = new PolygonPoint();
          newpolypoint.lat = element[0];
          newpolypoint.long = element[1];
          newpolypoint.recordStatus =0;
          t.Polygon.PolygonPoints.push(newpolypoint);
        });
      })
    }
    
  }
  mapPolysToRegion() {
    this.data.getBaseRegionByName(this.zonetype, this.selectedRegion.name,this.selectedRegion.parentId).subscribe((message: StatusMessage) => {

      let todo:FeaturePolygon[] = [];
      this.selectedPaths.forEach(sp=>{
        this.selectedRegion.Feature.FeaturePolygons.forEach(fp=>{
          if(fp.Polygon.id==sp){
            todo.push(fp);
          }
        })
      })
      this.selectedRegion.Feature.FeaturePolygons = todo;
      
      let region: Region = message.message;
      if (region) {
        region.Feature = this.selectedRegion.Feature;
        this.data.createFeatureAndFeaturePolygons(region.Feature,region.id).subscribe((message:StatusMessage)=>{
          let bob = message.message;
        })

      }

    })
  }
  loadFeature() {
    this.data.loadFeatureFromJson(this.jsonfile).subscribe((jsonFeature: Feature) => {
      this.selectedRegion.Feature = jsonFeature;
    })
  }
  
  /**
   * Promise for creating a GPS if required
   * @param gps GPS or null
   */
  createGPS(gps: GPS) {
    return new Promise((resolve, reject) => {
      if (!gps) resolve(0);
      else if(gps.id) resolve(gps.id);
      else {
        this.data.createGPS(gps).subscribe((message: StatusMessage) => {
          if (message.success) {
            resolve(message.message.id);
          }
          else {
            reject(message.message);
          }
        }, err => {
          reject(err);
        })
      }
    })
  }

  gpsCountries(){
    this.data.listContinents().subscribe((message:StatusMessage)=>{
      let conts = message.message;
      conts.forEach(cont=>{
        this.data.listCountriesByContinentPolys(cont.id).subscribe((message:StatusMessage)=>{
          let countries:Region[] = message.message;
          countries.forEach(co=>{
            if(!co.GPS){
              this.data.gpsFromBounds(co);
            }
          })
        })
      })
      
    })
    
  }
createChild(){
  let region = new Region(0,this.zonetype);
  region.name = this.selectedRegion.name;
  region.parentId = this.selectedParent.id;
  region.countryid = this.selectedParent.countryid;
  region.continentid = this.selectedParent.continentid;
  region.basesiteid = this.selectedParent.basesiteid;
  region.googlename = region.name;
  region.regiontypeid = this.selectedParent.regiontypeid+1; 
  this.data.createRegion(region).subscribe((message:StatusMessage)=>{
    this.mapPolysToRegion();
  })
}

}
