import { Component, OnInit, Input } from '@angular/core';
import { ModeMetric } from 'src/app/models/rates';
import { PreferredPorts } from 'src/app/models/ui';

@Component({
  selector: 'cs-transit-steps',
  templateUrl: './transit-steps.component.html',
  styleUrls: ['./transit-steps.component.scss']
})
export class TransitStepsComponent implements OnInit {

  @Input() port: PreferredPorts;

  public steps: ModeMetric[];

  public duration = 0;
  public co2 = 0;
  public km = 0;

  constructor() { }

  ngOnInit(): void {
    this.steps = this.port.steps;
    this.steps.forEach(step => {
      this.duration += step.duration;
      this.co2 += step.co2;
      this.km += step.km;

    })
  }



}
