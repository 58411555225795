import { Component, OnInit, Input } from '@angular/core';
import { Rate } from 'src/app/models/rates';
import { Country } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-rate-base',
  templateUrl: './rate-base.component.html',
  styleUrls: ['./rate-base.component.scss']
})
export class RateBaseComponent implements OnInit {

  @Input() rate:Rate;
  @Input() index:number = 0;
  @Input() currencies:Country[];
  @Input() editmode:boolean = false;
  @Input() unzoned:boolean = false;

  public applytoall = true;

  public currencySymbol = "£";
  public currencyCode = "GBP";

  constructor(public events:EventsService) {
    this.events.globalRateParameters.subscribe((mainrate:Rate)=>{
      if(this.rate.regionid!=mainrate.regionid){
        this.rate.breakbarrierincludes = mainrate.breakbarrierincludes;
        this.rate.priceperbreak = mainrate.priceperbreak;
        this.rate.volumeequivalent = mainrate.volumeequivalent;
        this.rate.createdAt = mainrate.createdAt;
        this.rate.expires = mainrate.expires;
        this.setRateBreakGlobals();
      }
      
    });
  }

  ngOnInit(): void {
    if(this.rate){
      if(this.currencies && this.rate.currency){
        let currencyco = this.currencies.filter(c=>c.id==this.rate.currency)[0];
        this.currencySymbol = currencyco.currencySymbol;
        this.currencyCode = currencyco.currencyCode;
      }
    }
  }
  propogateRateChange(){
    if(this.index==0 && this.applytoall){
      this.events.globalRateParameters.emit(this.rate);
    }
    this.setRateBreakGlobals();
  }
  setRateBreakGlobals(){
    this.rate.RateBreaks.forEach(rb=>{
      rb.priceperbreak = this.rate.priceperbreak;
      rb.breakbarrierincludes = this.rate.breakbarrierincludes;
    })
  }
}
