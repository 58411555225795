<div class="mt">
    <p class="warn" *ngIf="priorities.length==0">No direct delivery coverage from this provider</p>
</div>


<mat-tab-group (selectedTabChange)="changeVersion($event)" class="mt">
    <mat-tab *ngFor="let version of versions">
        <ng-template mat-tab-label>
            <span title="{{version.id}}">{{version.name}}</span>
        </ng-template>

        <mat-tab-group #prioritiestab (selectedTabChange)="changePriority($event)" class="mt">
            <mat-tab *ngFor="let priority of priorities | recordStatus">
                <ng-template mat-tab-label>
                    <span>{{priority.name}}</span>
                </ng-template>
                <div>
                    <p class="warn mt" *ngIf="versions.length==0">No coverage from this partner</p>

                    <div *ngFor="let band of selectedPriority.Bands;let x = index" class="mt">
                        <div class="fl aic">
                            <div class="mr title">{{band.name}}</div>
                        </div>
                        <div *ngFor="let rate of band.RateList;let bindex = index" class="fl">
                            <div class="fl">
                                <cs-rateview [rate]="rate"></cs-rateview>
                            </div>

                        </div>
                    </div>

                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-tab>
</mat-tab-group>