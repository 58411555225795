import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export function StrongPassword():ValidatorFn {

  const regex1 = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})';
  const regex:RegExp = new RegExp(regex1);
  return (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = regex.test(control.value);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };

}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
