import { Component, OnInit } from '@angular/core';
import { Margin, SurchargeType, MarginAppliesTo, SurchargeAppliesTo } from 'src/app/models/rates';
import { FormGroup, FormArray } from '@angular/forms';
import { Company, CompanyAssociation, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-provider-margins',
  templateUrl: './provider-margins.component.html',
  styleUrls: ['./provider-margins.component.scss']
})
export class ProviderMarginsComponent implements OnInit {
  public showhelp = false;
  public globals: Margin[] = [];
  public marginsForm: FormGroup;
  public providersForm: FormGroup;
  public discountsForm: FormGroup;
  public allproviders = true;
  public provider: Company;
  public providerid = 0;

  private surchargeTypes: SurchargeType[];

  get marginArray(): FormArray {
    return <FormArray>this.marginsForm.get("marginsArray");
  }
  get surchargeArray(): FormArray {
    return <FormArray>this.marginsForm.get("surchargeArray");
  }
  constructor(public data: DataService, public tools: ToolsService, public formtools: FormtoolsService, public events: EventsService) {
    
    
    this.marginsForm = formtools.createMarginsForm();
    if(this.data.selectedSupplier && this.data.selectedSupplier.Provider.id!=this.data.Company.id){
      this.providerid = this.data.selectedSupplier.Provider.id;
      this.provider = this.data.selectedSupplier.Provider;
      this.allproviders = true;
    }
    events.companyselect.subscribe((assoc: CompanyAssociation) => {
      if (assoc.Customer) {

      }
      else {
        this.provider = assoc.Provider;
        this.providerid = assoc.Provider.id;
        this.setup();
      }

    })
  }

  ngOnInit(): void {
    this.setup();
  }
  setup() {
    this.data.listSurchargeTypesAll(this.providerid).subscribe((message: StatusMessage) => {
      if (message.success) { 
        this.surchargeTypes = message.message;
        this.data.listGlobalMargins(this.providerid).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.globals = message.message;
            this.buildMarginArray();
            this.addSurchargeMarginControls();
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else this.tools.gracefulError(message.message);
    }, err => {
      this.tools.gracefulError(err);
    })

  }
  setProvider() {
    if (this.allproviders) {
      this.provider = null;
      this.providerid = 0;
      this.setup();
    }
  }

  buildMarginArray() {
    this.marginArray.clear();
    let prefix = ""
    if (this.provider) {
      prefix = this.provider.name + " ";
    }
    this.addMarginControl(prefix + "Global", MarginAppliesTo.Global);
    this.addMarginControl(prefix + "Service", MarginAppliesTo.Service_Charges);
    this.addMarginControl(prefix + "Delivery Rates", MarginAppliesTo.Delivery_Rates);
    this.addMarginControl(prefix + "Collection Rates", MarginAppliesTo.Collection_Rates);
    this.addMarginControl(prefix + "Surcharge", MarginAppliesTo.Surcharges);

  }

  addMarginControl(title: string, appliesto: MarginAppliesTo) {
    let existingmargin = this.globals.filter(g => g.appliesto == appliesto && !g.surchargetype);
    let margin: Margin;
    if (existingmargin.length == 1) margin = existingmargin[0];
    else margin = new Margin(appliesto, this.data.auth.user.Site.Address.countryid, this.data.Company.id);
    margin.providerid = this.providerid;
    let global: FormGroup = this.formtools.createMarginForm(margin, title);
    this.marginArray.push(global);
  }

  buildSurchargeArray() {

  }
  addSurchargeMarginControls() {
    this.surchargeArray.clear();
    let alltypes = SurchargeAppliesTo;
    let prefix = ""
    if (this.provider) {
      prefix = this.provider.name + " ";
    }
    Object.keys(alltypes).forEach((surchargegroup) => {
      let surchargetypes = this.surchargeTypes.filter(st => st.appliesto == alltypes[surchargegroup]);
      if (surchargetypes.length > 0) {
        let surchargemarginformgroup = this.formtools.createSurchargeMarginGroup(prefix + surchargegroup);
        let marginarray = <FormArray>surchargemarginformgroup.get("marginArray");
        surchargetypes.forEach(st => {
          let margin: Margin;
          let existing = this.globals.filter(g => g.appliesto == MarginAppliesTo.Surcharges && g.surchargetype == st.id);
          if (existing.length == 1) margin = existing[0];
          else {
            margin = new Margin(MarginAppliesTo.Surcharges, this.data.auth.user.Site.Address.countryid, this.data.Company.id);
            margin.surchargetype = st.id;
            margin.providerid = this.providerid;
          }
          let global: FormGroup = this.formtools.createMarginForm(margin, st.name);
          marginarray.push(global);
        })
        this.surchargeArray.push(surchargemarginformgroup);
      }
    })
    console.log(this.surchargeArray);
  }

  saveRate() {
    let marginstoupdate:Margin[]=[];
    //see discounts for explanation of this methodology - do not use formgroups.
    let index = 0;
    this.marginArray.controls.forEach(marginform => {
      if (marginform.dirty) {
        let nm = marginform.value;
        nm.placeinarray = index;
        marginstoupdate.push(nm);
      }
      index++;
    })

    let surchargegroupindex = 0;
    this.surchargeArray.controls.forEach(margingroup => {
      if (margingroup.dirty) {
        let surchargemarginarray = <FormArray>margingroup.get("marginArray");
        let surchargeindex = 0;
        surchargemarginarray.controls.forEach(marginform => {
          if (marginform.dirty) {
            let nm:Margin = marginform.value;
            nm.surchargegroupindex = surchargegroupindex;
            nm.placeinarray = surchargeindex;
            marginstoupdate.push(nm);
          }
          surchargeindex++;
        })
      }
      surchargegroupindex++;
    })
    let todo = marginstoupdate.length;
    let done = 0;
    marginstoupdate.forEach(margin => {
      if (margin.id > 0) {
        this.data.updateMargin(margin).subscribe((message: StatusMessage) => {
          if (message.success) {
            done++;
            if (done == todo) {
              this.marginsForm.markAsPristine();
              this.tools.snackMessage("Updates Complete");
            }
          }
          else {
            done++;
            this.tools.gracefulError(message.message);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          }
        }, err => {
          done++;
          this.tools.gracefulError(err);
          if (done == todo) {
            this.tools.snackMessage("Updates Complete");
          }
        })
      }
      else {
        this.data.createMargin(margin).subscribe((message: StatusMessage) => {
          if (message.success) {
            done++;
            if(margin.surchargegroupindex){
              //set id of surcharge group margin
              let surchargegroup = <FormGroup> this.surchargeArray.controls[margin.surchargegroupindex];
              let grouparray = <FormArray> surchargegroup.get("marginArray");
              let margingroup = <FormGroup> grouparray.controls[margin.placeinarray];
              margingroup.get("id").setValue(message.message.id);
            }
            else{
              //set id of margin in margin array
              let margingroup = <FormGroup> this.marginArray.controls[margin.placeinarray];
              margingroup.get("id").setValue(message.message.id);
            }
            if (done == todo) {
              this.marginArray.markAsPristine();
              this.tools.snackMessage("Updates Complete");
            }
          }
          else {
            done++;
            this.tools.gracefulError(message.message);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          }
        }, err => {
          done++;
          this.tools.gracefulError(err);
          if (done == todo) {
            this.tools.snackMessage("Updates Complete");
          }
        })
      }
    })
  }
}
