import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-GB';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {DragDropModule} from '@angular/cdk/drag-drop';

import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AddressComponent } from './components/address/address.component';
import { AddressViewComponent } from './components/address-view/address-view.component';
import { CompanyDetailComponent } from './components/company-detail/company-detail.component';
import { ExchangeRatesComponent } from './widgets/exchange-rates/exchange-rates.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IssueComponent } from './components/issue/issue.component';
import { MilestoneComponent } from './components/milestone/milestone.component';
import { SiteComponent } from './components/site/site.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { UserComponent } from './components/user/user.component';
import { CompanyComponent } from './dialogs/company/company.component';
import { LoginComponent } from './dialogs/login/login.component';
import { SnackComponent } from './dialogs/snack/snack.component';
import { HoverClassDirective } from './directives/hover-class.directive';
import { CdkDetailRowDirective } from './directives/table-detail-row.directive';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { QuotationsComponent } from './pages/quotations/quotations.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { EnumPipe } from './pipes/enum-pipe.pipe';
import { WithCurrencyPipe } from './pipes/with-currency.pipe';
import { HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { UserdetailComponent } from './components/userdetail/userdetail.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ServicesComponent } from './components/services/services.component';
import { ServiceComponent } from './dialogs/service/service.component';
import { LocodeSearchComponent } from './components/locode-search/locode-search.component';
import { CSMatTableComponent } from './components/mat-table/mat-table.component';
import { RegisterComponent } from './dialogs/register/register.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { DistanceComponent } from './widgets/distance/distance.component';
import { GeocoderComponent } from './widgets/geocoder/geocoder.component';
import { GlobalDistanceComponent } from './widgets/global-distance/global-distance.component';
import { DocumentManagementComponent } from './components/document-management/document-management.component';
import { DocumentGroupComponent } from './components/document-group/document-group.component';
import { QuillModule } from 'ngx-quill';
import { RegionsComponent } from './components/regions/regions.component';
import { RegionComponent } from './components/region/region.component';
import { PromptComponent } from './dialogs/prompt/prompt.component';
import { RegiongrouperComponent } from './dialogs/regiongrouper/regiongrouper.component';
import { RegiongroupeditorComponent } from './components/regiongroupeditor/regiongroupeditor.component';
import { LocationpickerComponent } from './dialogs/locationpicker/locationpicker.component';
import { SafehtmlPipe } from './pipes/safehtml.pipe';
import { SequencePipe } from './pipes/sequence.pipe';
import { AddregionComponent } from './dialogs/addregion/addregion.component';
import { RatesComponent } from './components/rates/rates.component';
import { RateEditorComponent } from './components/rate-editor/rate-editor.component';
import { RateCardEditorComponent } from './components/rate-card-editor/rate-card-editor.component';
import { RateEditorModelComponent } from './components/rate-editor-model/rate-editor-model.component';
import { QuoterComponent } from './widgets/quoter/quoter.component';
import { RateImporterComponent } from './importers/rate-importer/rate-importer.component';
import { RateBaseComponent } from './snippets/rate-base/rate-base.component';
import { RateEditorRegionsComponent } from './components/rate-editor-regions/rate-editor-regions.component';
import { RateBreaksComponent } from './snippets/rate-breaks/rate-breaks.component';
import { AddressShortComponent } from './snippets/address-short/address-short.component';
import { TransitStepsComponent } from './snippets/transit-steps/transit-steps.component';
import { DurationPipe } from './pipes/duration.pipe';
import { RegionBuilderComponent } from './dialogs/region-builder/region-builder.component';
import { RateEditorPortsComponent } from './components/rate-editor-ports/rate-editor-ports.component';
import { PortToPortSurchargeComponent } from './snippets/port-to-port-surcharge/port-to-port-surcharge.component';
import { CompanySearchComponent } from './widgets/company-search/company-search.component';
import { AddSurchargetypeComponent } from './snippets/add-surchargetype/add-surchargetype.component';
import { SurchargeComponent } from './snippets/surcharge/surcharge.component';
import { RateBaseFormComponent } from './snippets/rate-base-form/rate-base-form.component';
import { RateBreaksFormComponent } from './snippets/rate-breaks-form/rate-breaks-form.component';
import { RecordStatusPipe } from './pipes/record-status.pipe';
import { PortSurchargesComponent } from './components/port-surcharges/port-surcharges.component';
import { SurchargeContainerComponent } from './components/surcharge-container/surcharge-container.component';
import { PortpartnersComponent } from './components/portpartners/portpartners.component';
import { ObjectifierComponent } from './importers/objectifier/objectifier.component';
import { SurchargeViewComponent } from './snippets/surcharge-view/surcharge-view.component';
import { CurrencySelectComponent } from './dialogs/currency-select/currency-select.component';
import { SizerComponent } from './widgets/sizer/sizer.component';
import { SiteViewComponent } from './components/site-view/site-view.component';
import { DistanceGrouperComponent } from './dialogs/region-groupers/distance-grouper/distance-grouper.component';
import { DrivingPipe } from './pipes/driving.pipe';
import { RateEditorProviderComponent } from './components/rate-editor-provider/rate-editor-provider.component';
import { RateListFormComponent } from './components/rate-list-form/rate-list-form.component';
import { EditPriorityComponent } from './dialogs/edit-priority/edit-priority.component';
import { RateviewComponent } from './snippets/rateview/rateview.component';
import { RatesViewComponent } from './snippets/rates-view/rates-view.component';
import { LastMileComponent } from './components/last-mile/last-mile.component';
import { PasteResultsComponent } from './importers/paste-results/paste-results.component';
import { BasicGrouperComponent } from './dialogs/region-groupers/basic-grouper/basic-grouper.component';
import { ContinentalGrouperComponent } from './dialogs/region-groupers/continental-grouper/continental-grouper.component';
import { CountryGrouperComponent } from './dialogs/region-groupers/country-grouper/country-grouper.component';
import { MarginsComponent } from './components/margins/margins.component';
import { MarginComponent } from './snippets/margin/margin.component';
import { DiscountsComponent } from './components/discounts/discounts.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { ProviderMarginsComponent } from './components/provider-margins/provider-margins.component';
import { DiscountComponent } from './snippets/discount/discount.component';
import { DiscountsTestComponent } from './components/discounts-test/discounts-test.component';
import { RateTableHeaderComponent } from './snippets/rate-table-header/rate-table-header.component';
import { QuotePriceComponent } from './snippets/quote-price/quote-price.component';
import { HtmlvarDirective } from './directives/htmlvar.directive';
import { InputCurrencyDirective } from './directives/input-currency.directive';
import { InputDecimalDirective } from './directives/input-decimal.directive';
import { QuoteSurchargesComponent } from './components/quote-surcharges/quote-surcharges.component';
import { StepContainerComponent } from './components/step-container/step-container.component';
import { QuoteOverviewComponent } from './components/quote-overview/quote-overview.component';
import { PortDeliveryComponent } from './snippets/port-delivery/port-delivery.component';
import { QuoteOptionContainerComponent } from './components/quote-option-container/quote-option-container.component';
import { QuoteViewComponent } from './snippets/quote-view/quote-view.component';
import { SizeViewComponent } from './snippets/size-view/size-view.component';
import { YesNoViewComponent } from './snippets/yes-no-view/yes-no-view.component';
import { QuoteOptionsViewComponent } from './snippets/quote-options-view/quote-options-view.component';
import { ImportRateSettingsComponent } from './dialogs/import-rate-settings/import-rate-settings.component';
import { PureQuoteComponent } from './widgets/pure-quote/pure-quote.component';
import { ConsolidationComponent } from './snippets/consolidation/consolidation.component';
import { CompanySettingsComponent } from './dialogs/company-settings/company-settings.component';
import { UserSettingsComponent } from './dialogs/user-settings/user-settings.component';
import { CompanySettingsEditorComponent } from './snippets/company-settings-editor/company-settings-editor.component';
import { EnumChooserComponent } from './settings-editors/enum-chooser/enum-chooser.component';
import { SettingsContainerDirective } from './directives/settings-container.directive';
import { StringChooserComponent } from './settings-editors/string-chooser/string-chooser.component';
import { BooleanChooserComponent } from './settings-editors/boolean-chooser/boolean-chooser.component';
import { QuoteActionsComponent } from './dialogs/quote-actions/quote-actions.component';
import { QuoteHistoryComponent } from './pages/quote-history/quote-history.component';
import { QuoteHistoryListComponent } from './snippets/quote-history-list/quote-history-list.component';
import { CurrencyChooserComponent } from './settings-editors/currency-chooser/currency-chooser.component';
import { PortSearchComponent } from './widgets/port-search/port-search.component';
import { ImageChooserComponent } from './settings-editors/image-chooser/image-chooser.component';
import { QuoteViewDialog } from './dialogs/quote-view-dialog/quote-view-dialog.component';
import { PdfuploadDirective } from './directives/pdfupload.directive';
import { ConversionRateComponent } from './widgets/conversion-rate/conversion-rate.component';

import { Pie1Component } from './widgets/pie1/pie1.component';
import { Pie2Component } from './widgets/pie2/pie2.component';
import { MetricComponent } from './widgets/metric/metric.component';

import { NgxChartsModule }from '@swimlane/ngx-charts';
import { BarComponent } from './widgets/bar/bar.component';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { QuotePreviewComponent } from './widgets/quote-preview/quote-preview.component';


registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    AddressComponent,
    AddressViewComponent,
    AddressShortComponent,
    CompanyDetailComponent,
    ExchangeRatesComponent,
    FooterComponent,
    HeaderComponent,
    IssueComponent,
    MilestoneComponent,
    DocumentGroupComponent,
    SiteComponent,
    TimelineComponent,
    UserComponent,
    CompanyComponent,
    LoginComponent,
    SnackComponent,
    HoverClassDirective,
    CdkDetailRowDirective,
    DashboardComponent,
    HomeComponent,
    IssuesComponent,
    QuotationsComponent,
    RoadmapComponent,
    SettingsComponent,
    EnumPipe,
    WithCurrencyPipe,
    UserdetailComponent,
    InvitationComponent,
    ServicesComponent,
    ServiceComponent,
    LocodeSearchComponent,
    CSMatTableComponent,
    RegisterComponent,
    ToolsComponent,
    DistanceComponent,
    GeocoderComponent,
    GlobalDistanceComponent,
    DocumentManagementComponent,
    RegionsComponent,
    RegionComponent,
    PromptComponent,
    RegiongrouperComponent,
    RegiongroupeditorComponent,
    LocationpickerComponent,
    SafehtmlPipe,
    SequencePipe,
    AddregionComponent,
    RatesComponent,
    RateEditorComponent,
    RateCardEditorComponent,
    RateEditorModelComponent,
    QuoterComponent,
    RateImporterComponent,
    RateBaseComponent,
    RateEditorRegionsComponent,
    RateBreaksComponent,
    TransitStepsComponent,
    DurationPipe,
    RegionBuilderComponent,
    RateEditorPortsComponent,
    PortToPortSurchargeComponent,
    CompanySearchComponent,
    AddSurchargetypeComponent,
    SurchargeComponent,
    RateBaseFormComponent,
    RateBreaksFormComponent,
    RecordStatusPipe,
    PortSurchargesComponent,
    SurchargeContainerComponent,
    PortpartnersComponent,
    ObjectifierComponent,
    SurchargeViewComponent,
    CurrencySelectComponent,SizerComponent, SiteViewComponent, DistanceGrouperComponent, DrivingPipe, RateEditorProviderComponent,
    RateListFormComponent, EditPriorityComponent, RateviewComponent, RatesViewComponent, LastMileComponent, PasteResultsComponent,
    BasicGrouperComponent, ContinentalGrouperComponent, CountryGrouperComponent, MarginsComponent, MarginComponent,DiscountsComponent, ProvidersComponent, ProviderMarginsComponent, DiscountComponent, DiscountsTestComponent, RateTableHeaderComponent, QuotePriceComponent, HtmlvarDirective, InputCurrencyDirective, InputDecimalDirective, QuoteSurchargesComponent, StepContainerComponent, QuoteOverviewComponent, PortDeliveryComponent, QuoteOptionContainerComponent, QuoteViewComponent, SizeViewComponent, YesNoViewComponent, QuoteOptionsViewComponent, ImportRateSettingsComponent, PureQuoteComponent, ConsolidationComponent, CompanySettingsComponent, UserSettingsComponent, CompanySettingsEditorComponent, EnumChooserComponent, SettingsContainerDirective, StringChooserComponent, BooleanChooserComponent, QuoteActionsComponent, QuoteHistoryComponent, QuoteHistoryListComponent, CurrencyChooserComponent, PortSearchComponent, ImageChooserComponent,
    QuoteViewDialog,
    PdfuploadDirective,
    ConversionRateComponent,
    Pie1Component,
    Pie2Component,
    MetricComponent,
    BarComponent,
    SideMenuComponent,
    QuotePreviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,HttpClientModule,
    BrowserAnimationsModule, MatDialogModule,MatSnackBarModule,MatInputModule,MatButtonModule,MatIconModule,
    MatFormFieldModule,MatTabsModule,MatMenuModule,MatTableModule,MatPaginatorModule,MatSortModule,MatProgressSpinnerModule,MatSelectModule
    ,MatDatepickerModule,FormsModule,ReactiveFormsModule,MatMomentDateModule,MatSlideToggleModule,MatSliderModule,MatAutocompleteModule,MatRadioModule,MatTooltipModule,MatCheckboxModule,DragDropModule,
    QuillModule.forRoot(),NgxChartsModule,NgxMaterialTimepickerModule,MatExpansionModule
  ],
  providers: [{provide:MAT_DATE_LOCALE, useValue:'en-GB'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
