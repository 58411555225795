<div class="fl mt address" [formGroup]="addressForm">
    <div class="f1 fl fdc">

        <div *ngIf="showDetail">
            <mat-form-field>
                <mat-label>Flat or Suite Number</mat-label>
                <input matInput type="text" placeholder="Flat or Suite" formControlName="flatOrSuiteNumber">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Floor Number</mat-label>
                <input matInput type="text" placeholder="Floor" formControlName="floorNumber">
            </mat-form-field>
        </div>
        <mat-form-field>
            <mat-label>Building Name or Number</mat-label>
            <input matInput placeholder="Name or Number" formControlName="houseNameOrNumber" required>
            <mat-error *ngIf="houseNameOrNumber.errors && houseNameOrNumber.errors.required">
                Field Required
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Street</mat-label>
            <input matInput type="text" placeholder="Street" formControlName="street">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Town</mat-label>
            <input matInput type="text" placeholder="Town" formControlName="town">
        </mat-form-field>
        <mat-form-field>
            <mat-label>City</mat-label>
            <input matInput type="text" placeholder="City" formControlName="city" required>
            <mat-error *ngIf="city && city.errors && city.errors.required">
                Field Required
            </mat-error>
        </mat-form-field>
    </div>
    <div class="f1 fl fdc">
        <mat-form-field>
            <mat-label>County</mat-label>
            <input matInput type="text" placeholder="County" formControlName="county">
        </mat-form-field>
        <mat-form-field>
            <mat-label>State</mat-label>
            <input matInput type="text" placeholder="State" formControlName="state">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select formControlName="countryid">
                <mat-option *ngFor="let country of data.countries" [value]="country.id">
                    <img class="flag" src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="countryid && countryid.errors && countryid.errors.required">
                Field Required
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Postcode</mat-label>
            <input matInput type="text" placeholder="Postcode" formControlName="postcode">
        </mat-form-field>
    </div>
    <div class="f1 fl fdc">
        <div>
            <mat-form-field>
                <mat-label>GPS Latitude</mat-label>
                <input matInput type="number" placeholder="lat" formControlName="gpslat">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>GPS Longitude</mat-label>
                <input matInput type="number" placeholder="long" formControlName="gpslong">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Map Zoom</mat-label>
                <input matInput type="number" placeholder="zoom" formControlName="mapzoom" (change)="mapZoom()">
            </mat-form-field>
        </div>
        <div class="w3w">
            <mat-form-field>
                <mat-label>What 3 Words</mat-label>
                <input matInput type="text" placeholder="w3w" formControlName="what3words">
            </mat-form-field>
        </div>
    </div>
</div>
