import { Component, ViewChild, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Service } from 'src/app/models/data';

import { ToolsService } from 'src/app/services/tools.service';

import { MatDialog } from '@angular/material/dialog';
import { ServiceComponent } from 'src/app/dialogs/service/service.component';
import { TableGenerator, RecordStatus } from 'src/app/models/models';
import { CSMatTableComponent } from '../mat-table/mat-table.component';
import { EventsService } from 'src/app/services/events.service';
import { MarginAppliesTo } from 'src/app/models/rates';


@Component({
  selector: 'cs-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  @Input() services: Service[] = [];
  @Input() showadd = true;
  isLoadingResults = true;

  public appliesto = MarginAppliesTo.Service_Charges;

  public tabgen: TableGenerator = new TableGenerator();
  @ViewChild(CSMatTableComponent, { static: false }) serviceTable: CSMatTableComponent;

  constructor(public data: DataService, public tools: ToolsService, public dialog: MatDialog, public ref: ChangeDetectorRef, public events: EventsService) { }

  ngOnInit(): void {
    //this.events.settingsTabChange.subscribe((index)=>{
    //if(index==4){
    this.setup();
    //}
    //})
  }

  setup() {
    //this.data.listAllServices().subscribe((message: StatusMessage) => {
    //if (message.success) {
    this.tabgen.object = new Service();
    this.tabgen.pages = [10, 25, 50];
    this.tabgen.columns = this.tools.serviceColumns();

    //this.services = message.message;

    /*}
    else {
      this.tools.gracefulError(message.message);
    }*/
    //})
  }

  addService() {
    let service = new Service();
    service.recordStatus = RecordStatus.Suspended;
    service.Provider = this.data.auth.user.Site.Company;
    service.providerid = service.Provider.id;

    const dialogRef = this.dialog.open(ServiceComponent, {
      width: 'auto',
      data: { Service: service }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      
      if (result) {
        this.serviceTable.reRender(result);
        /*
        if (result.service) {
          newservice = result.service;
          this.data.createService(newservice).subscribe((res: StatusMessage) => {
            if (res.success) {
              if (result.rate) {
                let rate: Rate = result.rate;
                rate.serviceid = res.message.id;
                this.data.createRate(rate).subscribe((rateres: StatusMessage) => {
                  if (rateres.success) {

                  }
                  else {
                    this.tools.gracefulError(rateres.message);
                  }
                })
              }
              this.data.getService(res.message.id).subscribe((message: StatusMessage) => {
                if (message.success) {
                  this.serviceTable.reRender(message.message);
                }

              })

            }
            else this.tools.gracefulError(res.message);
          });
        }*/

      }

    });

  }
  editService(element: Service) {
    const dialogRef = this.dialog.open(ServiceComponent, {
      width: 'auto',
      data: { Service: element }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        element = Object.assign(element, result);
        this.serviceTable.reRender();
        /*if(result.service){
          changedservice = result.service;
        this.data.updateService(changedservice).subscribe((res: StatusMessage) => {
          if (res.success) {
            this.data.getService(result.id).subscribe((message: StatusMessage) => {
              if (message.success) {
                element = Object.assign(element,message.message);
                this.serviceTable.reRender();
              }
              else {
                console.log(message);
              }
            })
          }
          else {
            console.log(res);
          }

        })*/
      }
      else {
        console.log(result);
      }
    })
  }
}
