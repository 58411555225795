import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmOption } from 'src/app/models/models';

@Component({
  selector: 'cs-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>,@Inject(MAT_DIALOG_DATA) public data:any) { }

  public choose = ConfirmOption;

  ngOnInit(): void {
  }

  close(which:ConfirmOption){
    this.dialogRef.close(which);
  }

}
