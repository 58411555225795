<h3>Rate Breaks</h3>
<div class="fl f1 ovh">

    <div *ngIf="rate" class="mt fl fl overflow-scroll">
        <div>
            <table class="tac">
                <tr>
                    <th>Weight</th>
                    <th>Price</th>
                    <th>per</th>
                </tr>
                <tr *ngFor="let rb of rate.RateBreaks">
                    <td *ngIf="!rb.fixed">{{rb.maxweight | number}}</td>
                    <td *ngIf="rb.fixed">then</td>
                    <td><span *ngIf="rb.fixed">{{rb.fixed}} + </span>{{rb.price | currency:currencyCode}}</td>
                    <td *ngIf="!rb.fixed">{{rb.priceperbreak?'band':'kg'}}</td>
                    <td *ngIf="rb.fixed">{{rb.weightbreak}} kg</td>
                <tr>
            </table>
        </div>
        <div class="f1">

        </div>
    </div>
    <div class="f1 fl fdc ovh pad" *ngIf="rategroup && editmode" [formGroup]="rategroup">
        <div class="fl aic">
            <div class="f1"></div>
            <mat-checkbox *ngIf="index==0 && !unzoned && rateBreaks.controls.length>0" [(ngModel)]="applytoall" [ngModelOptions]="{standalone: true}" (change)="propogateBreakChange()"> Apply non-pricing values to
                all zones</mat-checkbox>
            <button *ngIf="editmode" class="mr" type="button" mat-mini-fab aria-label="Add Break" color="primary"
                title="Add Rate Break" (click)="addBreak()" [disabled]="!rategroup">
                <mat-icon>add</mat-icon>
            </button>
            
        </div>

        <div class="fl fl overflow-scroll pad"  formArrayName="RateBreaks">

            <div>
                <table class="tac">

                    <tr *ngFor="let rb of rateBreaks.controls;let rbi = index;" [formGroupName]="rbi" >
                        <td>
                            <mat-form-field class="form-skinny">
                                <mat-label>{{rb.get('priceperbreak').value?'unit':'kg'}} (or fraction)</mat-label>
                                <input matInput placeholder="Weight Break" type="number" formControlName="weightbreak"
                                    (change)="propogateBreakChange(rb,rbi)" autocomplete="kg (or fraction)">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="form-skinny">
                                <mat-label>up to {{rb.get('priceperbreak').value?'unit':'kg'}}</mat-label>
                                <input matInput placeholder="Weight Max" type="number" formControlName="maxweight"
                                    (change)="propogateBreakChange(rb,rbi)">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="form-skinny">
                                <mat-label>Price</mat-label>
                                <input matInput placeholder="Price" type="number" formControlName="price">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="form-select">
                                <mat-label>Price per</mat-label>
                                <mat-select formControlName="priceperbreak">
                                    <mat-option [value]="true">
                                        price band
                                    </mat-option>
                                    <mat-option [value]="false">
                                        W/M
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    <tr>
                </table>
            </div>
            <div class="f1">

            </div>
        </div>
    </div>
</div>
