<div class="fl dimension">
    <!--<div *ngIf="!customer" class="scene" [ngStyle]="{'width.px':(size.width/scale),'height.px':(size.height/scale)}">
        <div class="cube show-top">
            <div class="cube__face cube__face--front fl"
                [ngStyle]="{'font-size.px':(size.height/scale)/4,'width.px':(size.width/scale),'height.px':(size.height/scale),'transform':'rotateY(0deg) translateZ('+(cube/2+((size.depth/scale)-cube)/2)+'px) translateY('+(cube-(size.height/scale))/2+'px) translateX('+(cube-(size.width/scale))/2+'px)'}">
                <div class="f1"></div>
                <div class="f3 fl aic jc">x{{size.quantity}}</div>
                <div class="f1">
                </div>


            </div>
            <div class="cube__face cube__face--back"
                [ngStyle]="{'line-height.px':(size.height/scale),'width.px':(size.width/scale),'height.px':(size.height/scale),'transform':'rotateY(180deg) translateZ('+(cube/2+((size.depth/scale)-cube)/2)+'px) translateY('+(cube-(size.height/scale))/2+'px) translateX('+(-(cube-(size.width/scale))/2)+'px)'}">
                x{{size.quantity}}</div>
            <div class="cube__face cube__face--right"
                [ngStyle]="{'line-height.px':(size.height/scale),'width.px':(size.depth/scale),'height.px':(size.height/scale),'transform':'rotateY(90deg) translateZ('+(cube/2+((size.width/scale)-cube)/2+((cube-(size.depth/scale))/2))+'px) translateY('+(cube-(size.height/scale))/2+'px)'}">
                x{{size.quantity}}</div>
            <div class="cube__face cube__face--left"
                [ngStyle]="{'line-height.px':(size.height/scale),'width.px':(size.depth/scale),'height.px':(size.height/scale),'transform':'rotateY(-90deg) translateZ('+(cube/2+((size.width/scale)-cube)/2-((cube-(size.depth/scale))/2))+'px) translateY('+(cube-(size.height/scale))/2+'px)'}">
                x{{size.quantity}}</div>
            <div class="cube__face cube__face--top"
                [ngStyle]="{'line-height.px':(size.depth/scale),'width.px':(size.width/scale),'height.px':(size.depth/scale),'transform':'rotateX(90deg) translateZ('+(cube/2-((cube-(size.height/scale))/2)-((cube-(size.depth/scale))/2))+'px) translateX('+(cube-(size.width/scale))/2+'px)'}">
                x{{size.quantity}}</div>
            <div class="cube__face cube__face--bottom"
                [ngStyle]="{'line-height.px':(size.depth/scale),'width.px':(size.width/scale),'height.px':(size.depth/scale),'transform':'rotateX(-90deg) translateZ('+(cube/2-((cube-(size.height/scale))/2)+((cube-(size.depth/scale))/2))+'px) translateX('+(cube-(size.width/scale))/2+'px)'}">
                x{{size.quantity}}</div>
        </div>
    </div>

    <div>
        <table>
            <tr>
                <td class="field-title">Weight</td>
                <td class="field-value">{{size.weight}} kg</td>
                <td class="field-title">Width</td>
                <td class="field-value">{{size.width}} mm</td>
            </tr>
            <tr>
                <td class="field-title">Quantity</td>
                <td class="field-value">{{size.quantity}}</td>
                <td class="field-title">Depth</td>
                <td class="field-value">{{size.depth}} mm</td>
            </tr>
            <tr>
                <td class="field-title">Type</td>
                <td class="field-value">{{size.ratebracket}}</td>
                <td class="field-title">Height</td>
                <td class="field-value">{{size.height}} mm</td>
            </tr>
        </table>
    </div>
-->
<span class="field-title">Item {{index}}</span>
<span class="field-value">Qty: {{size.quantity}}, {{size.depth}}x{{size.width}}x{{size.height}}mm {{size.weight}}kg {{size.RateBracket?'('+size.RateBracket.name+')':''}}</span>
</div>