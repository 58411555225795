import moment from 'moment';
import { Locode, Vessel } from './data';
import { GPS } from './rates';

export class AnObject{
    id:number;
    recordStatus:RecordStatus;
    createdAt:Date;
    updatedAt:Date;
    constructor(){
        this.recordStatus = RecordStatus.Active;
    }
}

export class CentreToPort extends AnObject{
    siteid:number;
    locodeid:number;
    metres:number;
    duration:number;
}
/**
 * the distance, duration and transport mode of a Locode based OD pair.
 */
export class PortToPort extends AnObject{
    originlocodeid:number;
    destinationlocodeid:number;
    metres:number;
    duration:number;
    transportmode:number;
}

export class CoreData{
    user:boolean = false;
    types:boolean = false;
}

export class Country{
    id:number;
    iso_alpha_2: string;
    name: string;
    default_postcode: string;
    use_postcode: string;
    postcode_space: string;
    postcode_substring: number;
    google_distance: string;
    geocode: string;
    iso_alpha_3: string;
    iso_numeric: number;
    flag: string;
    currencyCode:string;
    currencySymbol:string;
    gpsid:number;
    recordStatus:RecordStatus = RecordStatus.Active;
    continentid:number;

    
    Locodes:Locode[] = [];
    GPS:GPS;
}

export class Document extends AnObject{
    documentgroupid:number;
    documentTypeid:number;
    name:string;
    companyid:number;
    html:string;    
    DocumentType:DocumentType;
    _editable:boolean;
    constructor(){
        super();
        this.html = "";
    }
}
export class DocumentGroup extends AnObject{
    name:string;
    parentId?:number;
    hierarchyLevel?:number;
    companyid:number;
    
    children:DocumentGroup[];
    Documents:Document[];
    _folderOpen:boolean;
}
export class DocumentType extends AnObject{
    name:string;
}

export enum DocumentTypes{
    General = 1,Invitation,HomePage,QuotationBody,QuotationLink,QuotationNotes,QuotationTerms
}

export class DocumentField extends AnObject{
    documenttypeid:number;
    field:string;
}

export default class ExchangeRate{
    currency:string;
    rate:number;
    country:Country;
}

export class ExchangeRates{
    rates:any;
    base:string;
    date:Date;
    constructor(){};

}

export class FindOptions{
    projectStart:boolean;
}

export class Issue{
    id:number;
    ownerid:number;
    moduleid:number;
    widgetid:number;
    issuetype:number;
    summary:string;
    description:string;
    stepsToReplicate:string;
    parentId?:number;
    hierarchyId?:number;
    status:number;
    priority:Priority;
    previousParent:number;
    recordStatus:RecordStatus;
    children:Issue[];
    constructor(ownerid:number){
        this.ownerid = ownerid;
        this.issuetype = 0;
        this.summary = "Issue Subject";
        this.description = "";
        this.stepsToReplicate = "";
        this.status = 0;
        this.priority = 0;
        this.recordStatus = 0;

    }
}

export class Milestone{
    id:number;
    ownerid:number;
    projectid:number;
    milestoneType:number;
    summary:string;
    description:string;
    startDate:moment.Moment;
    dueDate:moment.Moment;
    parentId?:number;
    hierarchyId?:number;
    status:MilestoneStatus;
    priority:Priority;
    previousParent:number;
    recordStatus:RecordStatus;
    children:Milestone[];
    createdAt:Date;
    updatedAt:Date;
    _folderOpen:boolean = false;

    constructor(ownerid:number,projectid:number){
        this.ownerid = ownerid;
        this.projectid = projectid;
        this.milestoneType = MilestoneType.MODULE;
        this.summary = "Title";
        this.startDate = moment().startOf('day');
        this.dueDate = moment().add(1,'d');
        this.status = MilestoneStatus.LOGGED;
        this.priority = Priority.LOW;
        this.recordStatus = RecordStatus.Active;
        this.children = [];
    }
}

export class Module{
    id:number;
    name:string;
    icon:string;
    route:string;
    recordStatus:RecordStatus;
}

export class ModuleUser{
    id:number;
    moduleid:number;
    userid:number;
    view:boolean;
    edit:boolean;
    viewOthers:boolean;
    editOthers:boolean;
    Module:Module;
    sequence:number;
    recordStatus:RecordStatus;
    constructor(){
         this.Module = new Module();
    }
}

export class MomentBucket{
    months:MomentMonth[];
    additionalDays:number;
    constructor(){
        this.months = [];
        this.additionalDays = 0;
    }
}
export class MomentMonth{
    monthNumber:number;
    monthName:string;
    weeks:MomentWeek[];
    constructor(moment:moment.Moment){
        this.weeks = [];
        this.monthNumber = moment.month();
        this.monthName = moment.format('MMM');
    }
}
export class MomentWeek{
 weekNumber:number;
 days:MomentDay[];
 monthName:string;
 
 constructor(moment:moment.Moment){
     this.weekNumber = moment.week();
     this.monthName = moment.format('MMM');
     this.days=[];
 }
}
export class MomentDay{   
    dayOfWeek:string;
    date:number;
    active:boolean;
    moment:moment.Moment;
    constructor(moment:moment.Moment,active:boolean){
        this.moment = moment;
        this.active = active;
        this.date = moment.date();
        this.dayOfWeek = moment.format('dddd');
    }
}

export class RoadMapSettings{
    //selected?:Milestone;
    dayWidth:number;
    startDate:moment.Moment;
    monthsToView:number;
    additionalDays:number;
    constructor(){
        this.dayWidth = 33;//16 cell width, 8 padding, 1 border.
        this.monthsToView = 3;//override with UserSetting.
        this.additionalDays = 0;
        this.startDate = moment();
    }
}

export class TableGenerator{
    object:any;
    pages:number[];
    columns:ColumnDescription[];
}
export class ColumnDescription{
    title:string;
    path:string;
    type:ColumnType
    constructor(title:string,path?:string,type?:ColumnType){
        this.title = title;
        this.path = path?path:title;
        this.type=type?type:ColumnType.TEXT;
    }
}


export class TimeLineInputs{
    milestone:Milestone;
    selectedId:number;
    dayWidth:number;
}

export class Widget{
    id:number;
    moduleid:number;
    description:string;
    recordStatus:RecordStatus;
}

export class WidgetUser{
    id:number;
    widgetid:number;
    userid:number;
    permissionLevel:PermissionLevel;
    recordStatus:RecordStatus;
}

export enum ColumnType{
    TEXT,BOOLEAN,TRANSPORT_MODE,SERVICE_VIAS,RECORD_STATUS,NULLABLE_OBJECT,CURRENCY,DURATION,ROUTE
}

export enum ConfirmOption{
    YES,NO,CANCEL
}

export enum IssueStatus{
    LOGGED,ACKNOWLEDGED,ACTIVE,CLOSED
}
export enum IssueType{
    MODULE,WIDGET,FUNCTIONALITY,DATA,STYLING,ARCHIVE
}

export enum MilestoneStatus{
    LOGGED,ACKNOWLEDGED,ACTIVE,BACKBURNERED,COMPLETED,ARCHIVE
}

export enum MilestoneType{
    MODULE,WIDGET,FUNCTIONALITY,DATA,STYLING,FOLDER
}

export enum PermissionLevel{
    NONE,VIEW,EDIT
}

export enum Priority{
    LOW,MEDIUM,HIGH,CRITICAL
}

export enum RecordStatus{
    Active,Archive,Suspended,Deleted,Test
}

export enum Roles{
    MASTER_ADMIN=1,COMPANY_ADMIN,COMPANY_USER,VIEW_ONLY
}



