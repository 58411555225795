import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { DataService } from 'src/app/services/data.service';
import { } from 'googlemaps';
import { Invitation, StatusMessage, SiteTypes } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { ConfirmOption } from 'src/app/models/models';
import { Surcharge, SurchargeAppliesTo } from 'src/app/models/rates';
import { GoogleService } from 'src/app/services/google.service';

@Component({
  selector: 'cs-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @ViewChild('search', { static: false }) searchBox: ElementRef;
  @Input() parentForm: FormGroup;
  @Input() site: FormGroup;
  @Input() index: number;

  mapAdded: boolean = false;
  map: google.maps.Map;

  appliestoin:number;
  appliestoout:number;

  get name() { 
    return this.site.get('name');
  }


  constructor(public formtools:FormtoolsService, public data:DataService, public tools:ToolsService, public geo:GoogleService) { }

  tabChange(index){
    if(index==1){
      /*
      let surchargeformarray = <FormArray> this.site.get("SurchargeSiteFormArray");
      if(!surchargeformarray.controls || surchargeformarray.controls.length==0){
        this.data.listSurchargesPort(this.site.value.id).subscribe((message:StatusMessage)=>{
          if(message.success){
            let surch:Surcharge[] = message.message;
            surch.forEach(s=>{
              surchargeformarray.push(this.formtools.createSurchargeForm(s));
            })
          }
          else{
            this.tools.gracefulError(message.message);
          }
        },err=>{this.tools.gracefulError(err)})
      }*/
      
    }
  }

  ngOnInit() {
    if(this.site.value.sitetypeid==SiteTypes.PORT)//port
    {
      this.appliestoin = SurchargeAppliesTo.PortIn;
      this.appliestoout = SurchargeAppliesTo.PortOut;
    }
    else if(this.site.value.sitetypeid==SiteTypes.AIRPORT){
      this.appliestoin = SurchargeAppliesTo.AirportIn;
      this.appliestoout = SurchargeAppliesTo.AirportOut;
    }
  }
  get Address(): FormGroup {
    return <FormGroup>this.site.get("Address");
  }
  get users(): FormArray {
    return <FormArray>this.site.get("Users");
  }
  get invitations(): FormArray {
    let invs = <FormArray>this.site.get("Invitations");
    return invs;
  }

  addMap() {
    let lat = this.Address.get('gpslat');
    let long = this.Address.get('gpslong');
    let zoom = this.Address.get('mapzoom');
    if (!this.mapAdded) {

      const mapProperties = {
        center: new google.maps.LatLng(lat.value, long.value),
        zoom: zoom.value,
        mapTypeControl:false,
        fullscreenControl:false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      if (google.maps.places) {
        //let input: HTMLInputElement = <HTMLInputElement>document.getElementById('pac-input');
        let searchBox = new google.maps.places.SearchBox(this.searchBox.nativeElement);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchBox.nativeElement);
        this.map.addListener('bounds_changed', () => {
          searchBox.setBounds(this.map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
          let places = searchBox.getPlaces();
          console.log(places);
          if (places.length > 0) {
            let choice = places[0];
            if (choice.geometry) {
              let centre = choice.geometry;
              this.map.setCenter(centre.location);
              this.map.fitBounds(choice.geometry.viewport);
            }
            else this.tools.gracefulError("Place has no geometry");
          }
          else this.tools.gracefulError("invalid place selection");

            this.confirmPlace(places);
          
        })
      };
      this.map.addListener('dragend', () => {
        let newcentre = this.map.getCenter();
        this.setAddressLatLng(newcentre);
        
      });
      this.map.addListener('zoom_changed', () => {
        let newzoom = this.map.getZoom();
        let zoom = this.Address.get('mapzoom');
        zoom.setValue(newzoom);
        zoom.markAsDirty();
      });
      this.map.addListener('click', (e) => {
        this.setAddressLatLng(e.latLng);
      });
      this.mapAdded = true;
    }
    else {
      //check for changes on the address form
      if (lat.dirty || long.dirty) {
        this.map.setCenter(new google.maps.LatLng(lat.value, long.value));
      }
      if (zoom.dirty) {
        this.map.setZoom(zoom.value);
      }
    }


  }

  confirmPlace(places:google.maps.places.PlaceResult[]){
    this.tools.confirm("Do you want to save this place as your site address?",true,true).then(confirm=>{
      if(confirm==ConfirmOption.YES){
        //save address
        let address = this.tools.addressFromGooglePlace(places,this.data.countries);
        if(!address.houseNameOrNumber){
          address.houseNameOrNumber = this.site.get("name").value;
        }
        address.mapzoom = this.map.getZoom();
        this.Address.patchValue(address);
        this.Address.markAsDirty();
      }

    })
  }

  setAddressLatLng(latlng:google.maps.LatLng){
    
    let gpsLat = this.Address.get('gpslat');
    gpsLat.setValue(latlng.lat());
    gpsLat.markAsDirty();
    let gpsLong = this.Address.get('gpslong')
    gpsLong.setValue(latlng.lng());
    gpsLong.markAsDirty();

    this.tools.confirm("Do you want to save this place as your site address?",true,true).then(confirm=>{
      if(confirm==ConfirmOption.YES){
        //save address
        let places = this.geo.reverseGeocode(latlng).then((message:StatusMessage)=>{
          if(message.success){
            let address = this.tools.addressFromGooglePlace([message.message],this.data.countries);
            address.mapzoom = this.map.getZoom();
            this.Address.patchValue(address);
            this.Address.markAsDirty();
          }
          else{
            this.tools.gracefulError("No place name found");
          }
          
        })
        
      }

    })
  }
  addUser() {
    let invitation = new Invitation();
    invitation.status = 0;
    invitation.siteid = this.site.get("id").value;
    invitation.inviterid = this.data.auth.user.id;
    invitation.recordStatus = 0;
    invitation.roleid = 3;
    if(this.users.length==0) invitation.roleid=2;
    this.data.createInvitation(invitation).subscribe((message:StatusMessage)=>{
      if(message.success){
        invitation = message.message;
        let invitationForm = this.formtools.createInvitationGroup(invitation);
        this.invitations.push(invitationForm);
        this.invitations.markAsDirty();
      }
      
    })


  }

}
