<div class="fl aic" hover-class="show-add" *ngIf="documentGroup">
    <div class="pointer f1">
        <div class="f1 fl aic" (dblclick)="editable=true">
            <mat-icon (click)="documentGroup._folderOpen=!documentGroup._folderOpen"
                [ngClass]="['folder',documentGroup.children && documentGroup.children.length>0?'active':'']">
                {{documentGroup._folderOpen?'folder_open':'folder'}}</mat-icon>
            <div *ngIf="!editable">{{documentGroup.name}}</div>
            <div *ngIf="editable" class="fl aic">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput type="text" placeholder="name" [(ngModel)]="documentGroup.name">
                </mat-form-field>
                <mat-icon (click)="update()" color="primary">check</mat-icon>
                <mat-icon (click)="delete()" color="error">delete</mat-icon>

            </div>
        </div>
    </div>
    <div *ngIf="role==1">
        <mat-icon (click)="addDoc()" class="add-child" color="primary" aria-label="add new document" title="add new document">
            note_add</mat-icon>
        <mat-icon (click)="addChild()" aria-label="add sub folder" title="add sub folder" class="add-child">add</mat-icon>
    </div>
</div>
<div *ngIf="documentGroup && documentGroup.Documents && documentGroup._folderOpen">
    <div class="fl aic pointer childstone" *ngFor="let doc of documentGroup.Documents;let d = index"
        (click)="selectDocument(doc)" (dblclick)="openUpdateDoc(doc)">
        <mat-icon class="doc lowkey">description</mat-icon>
        <div *ngIf="!doc._editable" class="f1">{{doc.name}} - {{doc.companyid==data.Company.id?'My ':''}}{{doc.DocumentType.name}}</div>
        <div *ngIf="doc._editable" class="f1 fl aic">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="name" [(ngModel)]="doc.name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Document Type</mat-label>
                <mat-select [(ngModel)]="doc.documentTypeid">
                    <mat-option *ngFor="let dt of data.documentTypes" [value]="dt.id">
                        {{dt.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-icon (click)="updateDoc(doc)" title="update" aria-label="update document" color="primary">check</mat-icon>
            <mat-icon (click)="deleteDoc(doc,d)" title="delete" aria-label="delete document" color="error">delete</mat-icon>
        </div>
        <mat-icon color="primary" *ngIf="role<4" title="copy" aria-label="copy document" (click)="copy(doc)">file_copy</mat-icon>
    </div>
</div>
<div class="tar" *ngIf="role==1">

</div>

<div *ngIf="documentGroup && documentGroup.children && documentGroup._folderOpen" class="childstone">
    <cs-document-group *ngFor="let child of documentGroup.children" id="csd-{{child.id}}" [documentGroup]="child">
    </cs-document-group>
</div>