import { Component, OnInit, Input } from '@angular/core';
import { SettingEditor } from '../setting-editor';
import { CompanySettingType, NewCompanySettingType } from 'src/app/models/user';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-boolean-chooser',
  templateUrl: './boolean-chooser.component.html',
  styleUrls: ['./boolean-chooser.component.scss']
})
export class BooleanChooserComponent implements SettingEditor {

  @Input() data:any;
  public settingvalue = false;
  public title:string;
  public settingtype:CompanySettingType;
  
  constructor(public events:EventsService) { }

  ngOnInit(): void {
    this.settingtype = this.data.settingtype;
    this.title = this.data.title;
    if(this.settingtype.Setting){
      this.settingvalue = this.settingtype.Setting.setting=="1";
    }
    else this.settingvalue = this.data.default;
  }

  saveChanges(){
    let newsettingtype = new NewCompanySettingType();
    newsettingtype.newsetting = this.settingvalue?"1":"0";
    newsettingtype.settingtype = this.settingtype;
    this.events.companysettingchange.emit(newsettingtype);
  }

}
