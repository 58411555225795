import { Component, OnInit, Input } from '@angular/core';
import { Region, Rate, RateBreak, BandPremium } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';
import { Priority, View, BandedRegion } from 'src/app/models/ui';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { FormGroup, FormArray } from '@angular/forms';
import { FormtoolsService } from 'src/app/services/formtools.service';

@Component({
  selector: 'cs-rate-editor',
  templateUrl: './rate-editor.component.html',
  styleUrls: ['./rate-editor.component.scss']
})
export class RateEditorComponent implements OnInit {

  @Input() view:View;
  @Input() siteid:number;
  
  public regions:BandedRegion[]=[];
  public selectedRegion:BandedRegion;

  public allVersions:Region[] = [];
  public versions:Region[]=[];
  public selectedVersion:Region;

  public selectedPriority:Priority;

  public rate:Rate;
  public rateForm:FormGroup;

  public currencyString = "£";
  
  constructor(public data:DataService,public tools:ToolsService,public formtools:FormtoolsService) { }

  ngOnInit(): void {
    this.selectedPriority = this.data.priorities[0];
    this.getBands();
  }

  get minimumcharge():number{
    return this.rateForm.get('baseprice').value;
  }
  get includesweight():number{
    return this.rateForm.get('baseIncludesWeight').value;
  }
  get includesvolume():number{
    return this.rateForm.get('baseIncludesVolume').value;
  }
  get ratebreaks(): FormArray {
    return <FormArray>this.rateForm.get("RateBreaks");
  }
  get bandpremiums(): FormArray {
    return <FormArray>this.rateForm.get("BandPremiums");
  }

  getBands(){
    switch(this.view.id){
      case 0:
      this.data.listCountryRegionsHavingBands(0).subscribe((message:StatusMessage)=>{
        if(message.success){
          let queryres = message.message;
          let result = queryres.res[1];
          this.regions = result.rows;
          if(this.regions.length){
            this.selectedRegion = this.regions[0];
            this.data.listRegionsStartup(3,this.selectedRegion.id,this.siteid).subscribe((message:StatusMessage)=>{
              if(message.success){
                this.versions = message.message;
                if(this.versions.length>0) this.selectedVersion = this.versions[0];
              }
              else{
                this.tools.gracefulError(message.message);
              }
            },err=>this.tools.gracefulError(err));
          }
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>this.tools.gracefulError(err));
    }
  }

  addRate(){
    this.rate = new Rate(this.data.Company.id,1);
    let bandpremiums = this.formtools.createFormArray();
    this.selectedVersion.children.forEach(ch=>{
      let bandpremium = new BandPremium();
      let bpgroup = this.formtools.createBandPremiumGroup(bandpremium);
      bandpremiums.push(bpgroup);
    })
    this.rateForm = this.formtools.createRateFormGroupWithPremiums(bandpremiums,this.rate);
  }
  addBreak(){
    let ratebreak = new RateBreak();
    
    let breakform = this.formtools.createRateBreakGroup(ratebreak);
    
    this.ratebreaks.push(breakform);
  }
  changeRegion(e){

  }
  changeVersion(e){

  }
  changePriority(e){

  }
  logForm(){
    console.log(this.rateForm);
  }
  bandValueChange(e,which,index){
    console.log(e,which,index);
  }
}
