import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { } from 'googlemaps';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { ToolsService } from 'src/app/services/tools.service';
import { GoogleDistanceMatrix } from 'src/app/models/data';

@Component({
  selector: 'cs-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.scss']
})
export class DistanceComponent implements OnInit {

  public origin: string = "";
  public origins: string[] = [];
  public destination: string = "";
  public destinations: string[] = [];

  public matrix:GoogleDistanceMatrix;

  distanceService: any;
  public isLoading = false;

  constructor(public tools: ToolsService, public ref:ChangeDetectorRef) {
    this.distanceService = new google.maps.DistanceMatrixService();
  }

  ngOnInit(): void {
  }

  calculate() {
    let valid = this.cleanLocations();
    if (valid) {
      this.isLoading = true;
      this.distanceService.getDistanceMatrix({
        origins: this.origins,
        destinations: this.destinations,
        travelMode: 'DRIVING'
      }, (r,s)=>this.callback(r,s));
    }
    else {
      this.tools.gracefulError("We need both an origin and a destination");
    }
  }

  get formvalid() {
    return (this.origins.length > 0 || this.origin.length > 0) && (this.destinations.length > 0 || this.destination.length > 0)
  }

  cleanLocations(): boolean {
    if (this.origins.length > 0) {
      if (this.origin.length > 0) {
        let found = false;
        this.origins.some(or => {
          if (or == this.origin) {
            found = true;
            return true;
          }
        })
        if (!found) {
          this.origins.push(this.origin);
        }
      }

    }
    else {
      if (this.origin.length > 0) this.origins.push(this.origin);
    }
    if (this.destinations.length > 0) {
      if (this.destination.length > 0) {
        let found = false;
        this.destinations.some(de => {
          if (de == this.origin) {
            found = true;
            return true;
          }
        })
        if (!found) {
          this.destinations.push(this.destination);
        }
      }

    }
    else {
      if (this.destination.length > 0) this.destinations.push(this.destination);
    }
    this.origin = "";
    this.destination = "";
    if (this.origins.length > 0 && this.destinations.length > 0) return true;
    else return false;
  }

  callback(response:GoogleDistanceMatrix, status:string) {
    this.matrix = response;
    console.log(response,status);
    this.isLoading = false;
    this.ref.detectChanges();
    
  }

  addOrigin() {
    this.origins.push(this.origin);
    this.origin = "";
  }
  addDest() {
    this.destinations.push(this.destination);
    this.destination = "";
  }

  removeOrigin(index: number) {
    this.origins.splice(index, 1);
  }
  removeDest(index: number) {
    this.destinations.splice(index, 1);
  }

  clearForm(){
    this.origin = "";
    this.destination = "";
    this.origins=[];
    this.destinations=[];
    this.matrix = null;
  }

}
