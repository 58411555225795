<div class="f1" [formGroup]="parentForm" *ngIf="!deleted">
    <div *ngIf="invitation" [formGroupName]="index">
        <div class="fl aic">
            <mat-form-field class="">
                <mat-label>Salutation</mat-label>
                <input matInput placeholder="Dear ....." formControlName="salutation" (blur)="save()">
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" (blur)="save()">
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>Role</mat-label>
                <mat-select formControlName="roleid" (selectionChange)="save()">
                    <mat-option *ngFor="let enum of roles | enumpipe:data.auth.user.UserRoles[0].roleid" [value]="enum.key">
                        {{enum.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button *ngIf="status<invitationStatus.SENT" type="button" mat-mini-fab aria-label="send invite"
                color="primary" title="Send Invite" [disabled]="!invitation.valid">
                <mat-icon class="pointer invite" (click)="sendInvite()">send</mat-icon>
            </button>
            <button *ngIf="status==invitationStatus.SENT || status==invitationStatus.REJECTED" type="button"
                mat-mini-fab aria-label="resend invite" color="primary" title="Resend Invite"
                [disabled]="!invitation.valid">
                <mat-icon class="pointer invite resend" (click)="resendInvite()">repeat</mat-icon>
            </button>
            <button *ngIf="status<invitationStatus.ACCEPTED" type="button" mat-mini-fab aria-label="resend invite"
                color="warning" title="Delete Invite">
                <mat-icon class="pointer invite" (click)="deleteInvite()">delete</mat-icon>
            </button>
        </div>
    </div>
</div>