<div class="fl">
    <mat-form-field>
        <mat-label>{{title}}</mat-label>
        <mat-select [(ngModel)]="settingvalue" (selectionChange)="saveChanges($event)">
            <mat-option *ngFor="let country of currencies" [value]="country.id">
                {{country.currencySymbol}} <img class="flag"
                src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
