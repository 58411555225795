<h3 class="tac">Welcome to Freightology</h3>
<p class="tac">Please register to access your portal.</p>

<form class="register" [formGroup]="registerForm" (ngSubmit)="submit()">
    <div *ngIf="!found">
        <p>Sorry, this link is invalid or has expired, please request a new invitation.</p>
    </div>
    <div *ngIf="found" class="fl fww">
        <div class="f1 pad">
            <mat-form-field class="w100">
                <mat-label>Your Screen Name</mat-label>
                <input id="screenname" name="cs_screen" matInput formControlName="screenname" required
                    autocomplete="new-password">
                <mat-hint>How you want to be know when using this site</mat-hint>
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-label>Your First Name</mat-label>
                <input id="firstname" name="cs_first" matInput formControlName="firstname" required
                    autocomplete="new-password">
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-label>Your Surname</mat-label>
                <input id="surname" name="cs_surname" matInput formControlName="surname" required
                    autocomplete="new-password">
            </mat-form-field>
        </div>
        <div class="f1 pad">

            <mat-form-field class="w100">
                <mat-label>Your Password</mat-label>
                <input matInput formControlName="password" required type="password" name="cs_password"
                    autocomplete="new-password">
                <mat-hint>We need at least 8 characters including a symbol, a number and both cases</mat-hint>
                <mat-error *ngIf="password.hasError('password') && !password.hasError('required')">
                    Please enter a valid password
                </mat-error>
                <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPassword" required type="password" name="cs_confirm"
                    autocomplete="new-password">
                <mat-error
                    *ngIf="confirmPassword.errors?.mustMatch && (confirmPassword.dirty || confirmPassword.touched)">
                    Passwords must match
                </mat-error>
            </mat-form-field>
            <p>Your email: {{email.value}}</p>
        </div>
    </div>
    <div class="fl aic jc ma-p">
        <button type="button" mat-raised-button color="warn" class="f1" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" class="f1" [disabled]="!this.registerForm.valid">Submit</button>
    </div>
</form>