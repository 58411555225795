<div>
<mat-form-field>
    <mat-label>User Role</mat-label>
    <mat-select (selectionChange)="changeRole($event)" [(ngModel)]="roleid" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let role of data.userRoles" [value]="role.id">
            {{role.description}}
        </mat-option>
    </mat-select>
</mat-form-field>
</div>



<div class="f1">
    <h3>Modules</h3>
    <table>
        <tr><th>Name</th><th>View</th><th>Edit</th></tr>
        <tr *ngFor="let modu of moduleUsers">
            <td>{{modu.Module.name}}</td>
            <td class="jc"><mat-slide-toggle
                class="example-margin"
                color="primary" [(ngModel)]="modu.view" (change)="updateModule(modu)">
            </mat-slide-toggle></td>
            <td class="jc"><mat-slide-toggle
                class="example-margin"
                color="primary" [(ngModel)]="modu.edit" (change)="updateModule(modu)">
            </mat-slide-toggle></td>
        </tr>
    </table>
</div>
<div class="f1" *ngIf="data.userSettingTypes && data.userSettingTypes.length>0">
    <h3>Settings</h3>
    <div class="fl">
        <div>
            <mat-form-field>
                <mat-label>User Setting</mat-label>
                <mat-select (selectionChange)="changeSetting($event)" [(ngModel)]="settingid" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let setting of data.userSettingTypes" [value]="setting.id">
                        {{setting.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>    
        </div>
        <div>
            <mat-form-field>
            <mat-label>Setting Value</mat-label>
            <input matInput placeholder="setting value"  [(ngModel)]="setting.setting" [ngModelOptions]="{standalone: true}" (blur)="updateSetting()">
        </mat-form-field>
    </div>
    <div>
        <mat-slide-toggle
                class="example-margin"
                color="primary" [(ngModel)]="setting.settingon" (change)="updateSetting()">
            </mat-slide-toggle>
    </div>
    </div>
</div>
