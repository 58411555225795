<div *ngIf="steps" class="fl section">
    <div *ngFor="let step of steps;let i=index;" class="fl {{step.ishub?'f1 aic':'f3'}}">
        <div *ngIf="step.ishub" class="fl fdc hts aic ma">
            <div class="fl aic">
                <mat-icon>{{step.icon}}</mat-icon>
            </div>
            <div><span *ngIf="i==0">km</span></div>
            <div>
                <span *ngIf="i==0">duration</span>
                <span *ngIf="i>0">tbc</span>
            </div>
            <div>
                <span *ngIf="i==0">CO<sub>2</sub></span>
            </div>
            <div>
                <span *ngIf="i==0">charges</span>
                <span *ngIf="i>0">{{step.cost | currency:'GBP'}}</span>
            </div>
        </div>

        <div *ngIf="!step.ishub" class="f1 fl fdc hts">
            <div class="f1 fl aic">
                <div class="f1 route carbon{{step.co2band}}"></div>
                <mat-icon>{{step.icon}}</mat-icon>
                <div class="f1 route carbon{{step.co2band}}"></div>
            </div>
            <div class="f1 fl jc">
                {{step.km | number:'1.1-1'}}
            </div>
            <div class="f1 fl jc" title="{{step.duration}}">
                {{step.duration | duration}}
            </div>
            <div class="f1 fl jc">
                {{step.co2 | number:'1.1-1'}}
            </div>
            <div class="f1 jc">
                {{step.cost | currency:'GBP'}}
            </div>
        </div>
    </div>
    <div class="fl f1 fdc aic hts">
        <div class="transit-header">Total</div>
        <div>{{km | number:'1.0-0'}} km</div>
        <div>{{duration | duration}}</div>
        <div>{{co2 | number:'1.1-1'}} kg</div>
        <div>{{port.total.discountedprice | currency:'GBP'}}</div>
        
    </div>
</div>