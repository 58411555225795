
<div class="fl pad fw tar">
    <div class="f1">
        <mat-autocomplete #serviceUserAuto="matAutocomplete" [displayWith]="displayCustomerWith"
            (optionSelected)="chooseCo($event)" name="customer-auto">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company" name="customer-select">
                {{company[iscustomers?'Customer':'Provider'].name}}
            </mat-option>
        </mat-autocomplete>
    </div>
    <div class="f1">
        <mat-form-field>
            <mat-label>{{iscustomers?'Customer':'Provider'}}</mat-label>
            <input id="companysearchinput" type="search" matInput [formControl]="coSearch" [matAutocomplete]="serviceUserAuto" (click)="pop($event)" name="customer-name-input">
        </mat-form-field>
    </div>
    <div class="f1" *ngIf="!hidetype">
        <mat-form-field>
            <mat-label>
                Company Type
            </mat-label>
            <mat-select multiple [(ngModel)]="selectedCoTypeIds" (selectionChange)="chooseType($event)" name="customer-type-select" ngDefaultControl>
                <mat-checkbox [(ngModel)]="selectAll" (change)="checkAll($event)" class="mat-option" name="customer-type-all-check" ngDefaultControl>All</mat-checkbox>
                <mat-option *ngFor="let type of companytypes" [value]="type.id" name="company-type-options">
                    {{type.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
