
<h3>Imported Data Recognised</h3>
<div>
    <p>Import for provider: {{provider.name}}</p>
    <p>Into region: {{region.name}}</p>
</div>

<div class="fl">
    <div class="f1">
        <mat-form-field>
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="f1">
        <mat-form-field>
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="expiryDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
</div>


<div class="close-dialog">
    <button mat-raised-button color="warn" (click)="cancel()" type="button">Cancel</button>
    <button
        mat-raised-button color="primary" (click)="import()">Save</button>
</div>

