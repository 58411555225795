<h3>Company Settings</h3>
<mat-tab-group>
    <mat-tab *ngFor="let cat of categories">
        <ng-template mat-tab-label>
            {{cat.description}}
        </ng-template>
        <div>
            <div *ngFor="let type of cat.CompanySettingTypes" class="mt">
                <cs-company-settings-editor [settingtype]="type"></cs-company-settings-editor>
            </div>
        </div>

    </mat-tab>
</mat-tab-group>
<div class="close-dialog">
    <button
        mat-raised-button color="primary" type="button" (click)="close()">OK</button>
</div>

