import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Region, RegionDistancer, ZoneType } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-distance-grouper',
  templateUrl: './distance-grouper.component.html',
  styleUrls: ['./distance-grouper.component.scss']
})
export class DistanceGrouperComponent implements OnInit {

  parentRegionId:number;
  public parentRegion: Region;
  public isself: boolean = false;
  public bands = 2;
  public maxkm = 50;
  public maxminutes = 60;
  public howderive = 1;

  private runningkm = 0;
  private runningmin = 0;

  public filteredRegions: Region[] = [];

  constructor(public dialogRef: MatDialogRef<DistanceGrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, public tools:ToolsService) {
      this.parentRegionId = dialogdata.parentregionid;
   }

  get minkm() {
    if (!this.filteredRegions || this.filteredRegions.length == 0) return 0;
    else return this.filteredRegions[this.filteredRegions.length - 1].RegionDistancer.maxkm;
  }
  get minmin() {
    if (!this.filteredRegions || this.filteredRegions.length == 0) return 0;
    else return this.filteredRegions[this.filteredRegions.length - 1].RegionDistancer.maxmin;
  }

  ngOnInit(): void {
    this.data.listRegionDistancerFromParent(this.parentRegionId).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.parentRegion = message.message;
        this.filterRegions();

      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>this.tools.gracefulError(err))
  }
  logParent() {
    console.log(this.parentRegion);
  }
  addBand() {
    let reg = new Region(this.parentRegion.companyid,ZoneType.DrivingDistance);
    reg.drivingdistance = true;
    reg.parentId = this.parentRegion.id;
    reg.sequence = this.parentRegion.children.length;
    reg.countryid = this.parentRegion.countryid;
    reg.continentid = this.parentRegion.continentid;
    reg.gpscentreid = this.parentRegion.gpscentreid;
    reg.regiontypeid = this.parentRegion.regiontypeid + 1;
    reg.basesiteid = this.parentRegion.basesiteid;
    let rd = new RegionDistancer();
    rd.minkm = this.minkm;
    rd.maxkm = rd.minkm*1 + this.maxkm*1;
    rd.minmin = this.minmin*1;
    rd.maxmin = rd.minmin*1 + this.maxminutes*1;
    rd.derives = this.howderive;
    reg.RegionDistancer = rd;
    this.generateName(reg);
    this.parentRegion.children.push(reg);
    this.filterRegions();
  }
  createBands() {
    for (let i = 0; i < this.bands; i++) {
      let reg = new Region(this.parentRegion.companyid,ZoneType.DrivingDistance);
      reg.drivingdistance = true;
      reg.parentId = this.parentRegion.id;
      reg.sequence = this.parentRegion.children.length;
      reg.countryid = this.parentRegion.countryid;
      reg.continentid = this.parentRegion.continentid;
      reg.gpscentreid = this.parentRegion.gpscentreid;
      reg.regiontypeid = this.parentRegion.regiontypeid + 1;
      reg.basesiteid = this.parentRegion.basesiteid;
      let rd = new RegionDistancer();
      rd.minkm = this.runningkm;
      rd.maxkm = this.maxkm * (this.filteredRegions.length + 1);
      rd.minmin = this.runningmin;
      rd.maxmin = this.maxminutes * (this.filteredRegions.length + 1);
      rd.derives = this.howderive;
      reg.RegionDistancer = rd;
      this.parentRegion.children.push(reg);
    }
    this.filterRegions();
    this.formRegionNameAndMins();
  }
  formRegionNameAndMins() {

    let runningkm = 0;
    let runningmin = 0;
    this.filteredRegions.forEach(b => {

      b.RegionDistancer.minkm = runningkm;
      b.RegionDistancer.minmin = runningmin;
      runningkm += this.maxkm;
      runningmin += this.maxminutes;
      b.RegionDistancer.maxkm = runningkm;
      b.RegionDistancer.maxmin = runningmin;
      this.generateName(b);
    })

  }

  removeBand(index: number) {
    let band = this.filteredRegions[index];
    band.recordStatus = 3;
    this.filterRegions();
  }
  updateRanges() {
    this.formRegionNameAndMins();
  }
  updateName(index:number){
    let zone = this.filteredRegions[index];
    this.generateName(zone);
  }
  generateName(zone:Region){
    let namestring = "";
    switch (zone.RegionDistancer.derives) {
      case 1:
        namestring = (zone.RegionDistancer.morethan?" > ":" < ") + zone.RegionDistancer.maxkm.toString() + " km";
        break;
      case 2:
        namestring = (zone.RegionDistancer.morethan?" > ":" < ") + zone.RegionDistancer.maxmin.toString() + " mins";
        break;
      case 3:
        namestring = (zone.RegionDistancer.morethan?" > ":" < ") + zone.RegionDistancer.maxkm.toString() + " km or " + zone.RegionDistancer.maxmin.toString() + " mins";
        break;
    }
    let address = this.data.selectedSupplier._baseSite.Address;
    let city = address.city;
    if(!address.city) city = address.town;
    zone.name = city + namestring;
  }
  setTitle(zone:Region){
    zone.RegionDistancer.morethan = !zone.RegionDistancer.morethan;
    this.generateName(zone);
  }

  cancel() {
    this.dialogRef.close();
  }
  done() {
    this.dialogRef.close(this.parentRegion);
  }

  filterRegions() {
    let filtered = this.parentRegion.children.filter(c => c.drivingdistance && c.recordStatus == 0);
    this.filteredRegions = filtered;
    this.filteredRegions.forEach(f=>{
      if(!f.RegionDistancer){
        f.RegionDistancer = new RegionDistancer();
      }
    })
  }

}
