import { Component, OnInit, Input, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { AddSurchargetypeComponent } from 'src/app/snippets/add-surchargetype/add-surchargetype.component';
import { SurchargeType, Surcharge, SurchargeAppliesTo } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { StatusMessage, CompanyAssociation } from 'src/app/models/user';
import { CompanySearchComponent } from 'src/app/widgets/company-search/company-search.component';
import { PortSite } from 'src/app/models/ui';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-surcharge-container',
  templateUrl: './surcharge-container.component.html',
  styleUrls: ['./surcharge-container.component.scss']
})
export class SurchargeContainerComponent implements OnInit {

  @Input() editmode: boolean = true;
  @Input() appliesto: number;
  @Input() serviceForm: FormGroup;
  @Input() provider: number;
  @Input() servicesurcharges: Surcharge[];
  @Input() saveon: boolean = true;

  @ViewChildren('addtype') addtype: QueryList<AddSurchargetypeComponent>;
  @ViewChildren('cosearch') cosearch: QueryList<CompanySearchComponent>;

  public surchargeTypes: SurchargeType[] = [];
  public selectedSurchargeType: SurchargeType;
  public surcharges: Surcharge[] = [];

  public surchargeappliesto = "";

  public newtype: string = "";
  public addnew: boolean = false;

  public surchargearray:FormArray;

  get serviceproviderid() {
    if (this.provider) return this.provider;
    return this.serviceForm.get("providerid").value;
  }
  get servicetransportMode() {
    return this.serviceForm.get("transportMode").value;
  }

  constructor(public data: DataService, public tools: ToolsService, public formtools: FormtoolsService, public events: EventsService) { }

  ngOnInit(): void {
    if (!this.provider) {
      setTimeout(() => {
        this.events.companyselect.subscribe((co: CompanyAssociation) => {
          this.changeProvider(co);
        })
      })
    }


    this.data.listSurchargeTypes(this.serviceproviderid, this.appliesto).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.surchargeTypes = message.message;
        if (this.surchargeTypes.length > 0) {
          this.selectedSurchargeType = this.surchargeTypes[0];
        }
        switch (this.appliesto) {
          case SurchargeAppliesTo.AirportIn:
          case SurchargeAppliesTo.PortIn:
            this.surchargeappliesto = "Import Surcharges";
            this.surchargearray = <FormArray>this.serviceForm.get("surchargeImportSiteFormArray");
            this.getSurcharges();
            break;
          case SurchargeAppliesTo.AirportOut:
          case SurchargeAppliesTo.PortOut:
            this.surchargeappliesto = "Export Surcharges";
            this.surchargearray = <FormArray>this.serviceForm.get("surchargeExportSiteFormArray");
            this.getSurcharges();

            break;
          case SurchargeAppliesTo.Company:
            this.surchargeappliesto = "Quotation Surcharges";
            this.surchargearray = <FormArray>this.serviceForm.get("surchargeQuotationFormArray");
            this.getSurchargesCompany();
            break;
          default:
            this.surchargeappliesto = "Service Surcharges";
            this.surchargearray = <FormArray>this.serviceForm.get("surchargeFormArray");
            //experimental

            if (this.servicesurcharges) {
              this.servicesurcharges.forEach(s => {
                this.surchargearray.push(this.formtools.createSurchargeForm(s));
              })
            }
            else {
              if (this.serviceForm.get("id").value && this.serviceForm.get("id").value > 0) {
                this.data.listSurchargesService(this.serviceForm.get("id").value, this.appliesto).subscribe((message: StatusMessage) => {
                  if (message.success) {
                    this.surcharges = message.message;
                    this.surcharges.forEach(s => {
                      this.surchargearray.push(this.formtools.createSurchargeForm(s));
                    })
                  }
                  else {
                    this.tools.gracefulError(message.message);
                  }
                }, err => {
                  this.tools.gracefulError(err);
                })
              }
            }


        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }

  getSurcharges() {
    this.data.listSurchargesPort(this.serviceForm.value.id, this.appliesto).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.surcharges = message.message;
        this.surcharges.forEach(s => {
          this.surchargearray.push(this.formtools.createSurchargeForm(s));
        })
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  getSurchargesCompany() {
    this.data.listSurchargesCompany(this.data.Company.id, this.appliesto).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.surcharges = message.message;
        this.surcharges.forEach(s => {
          this.surchargearray.push(this.formtools.createSurchargeForm(s));
        })
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  changeProvider(co: CompanyAssociation) {

  }

  addSurcharge() {
    let surch = new Surcharge();
    surch.surchargetypeid = this.selectedSurchargeType.id;
    surch.currencyid = this.data.countries.filter(c => c.iso_alpha_2 = "GB")[0].id;
    surch.perunit = 1;
    surch.expires = new Date(2021, 10, 30);
    //if we are not the provider, set the customerid to us - this prevents user changing the providers core fee structure.
    if (this.provider && this.provider != this.data.Company.id) {
      surch.customerid = this.data.Company.id;
    }
    this.surcharges.push(surch);

    if (this.appliesto == SurchargeAppliesTo.AirportIn || this.appliesto == SurchargeAppliesTo.PortIn || this.appliesto == SurchargeAppliesTo.AirportOut || this.appliesto == SurchargeAppliesTo.PortOut) {
      if (this.provider) {
        surch.siteid = this.serviceForm.value.id;
      }
    }
    else if (this.appliesto = SurchargeAppliesTo.Company) {
      surch.companyid = this.provider;
    }
    else {
      surch.serviceid = this.serviceForm.get("id").value;
    }
    this.surchargearray.push(this.formtools.createSurchargeForm(surch));
  }
  addOther() {
    this.addnew = true;
    this.addtype.first.add.subscribe((surchargetype: SurchargeType) => {
      this.surchargeTypes.push(surchargetype);
      this.addnew = false;
      this.selectedSurchargeType = surchargetype;
    })
  }
  save() {
    this.surchargearray.controls.forEach((sur: FormGroup) => {
      if (sur.dirty) {
        let surcharge: Surcharge = sur.value;
        surcharge.expires = surcharge.expires;
        if (surcharge.id > 0) {
          this.data.updateSurcharge(surcharge).subscribe((message: StatusMessage) => {
            if (message.success) {
              sur.reset(surcharge);
            }
            else {
              this.tools.gracefulError(message.message);
            }
          }, err => {
            this.tools.gracefulError(err);
          })
        }
        else {
          this.data.createSurcharge(surcharge).subscribe((message: StatusMessage) => {
            if (message.success) {
              sur.reset(message.message);
            }
            else {
              this.tools.gracefulError(message.message);
            }
          }, err => {
            this.tools.gracefulError(err);
          })
        }

      }
    })
  }
}
