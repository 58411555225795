import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentGroup,Document, DocumentType } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-document-group',
  templateUrl: './document-group.component.html',
  styleUrls: ['./document-group.component.scss']
})
export class DocumentGroupComponent implements OnInit {

  @Input() documentGroup:DocumentGroup;
  @Input() parentSelect:any;
  @Output() select = new EventEmitter<Document>();

  public editable = false;
  role:number=10;
  
  constructor(public data:DataService,private tools:ToolsService,public events:EventsService) { }

  ngOnInit(): void {
    this.role = this.data.role();
    if(this.documentGroup && this.documentGroup.Documents && this.documentGroup.Documents.length>0){
      //hide base doc if company specific doc is present
      //TODO smarter way to handle this
      let types = [];
      let indices = [];
      let index = 0;
      this.documentGroup.Documents.sort((a,b)=>a.documentTypeid - b.documentTypeid || a.companyid-b.companyid);
      this.documentGroup.Documents.forEach(doc=>{
        let exists = types.filter(type=>doc.documentTypeid==type.type);
        if(exists.length==1){
          indices.push(exists[0].index);
        }
        else if(exists.length==0){
          types.push({type:doc.documentTypeid,index:index});
        }
        index++;
      })
      for(let x = indices.length-1;x>=0;x--){
        this.documentGroup.Documents.splice(indices[x],1);
      }
      
    }
  }

  selectDocument(document:Document){
    this.events.selectedDocument.emit(document);
  }
  addChild(){
    let newchild = new DocumentGroup();
    newchild.name = "New";
    newchild.recordStatus = 0;
    newchild.parentId = this.documentGroup.id;
    this.data.createDocumentGroup(newchild).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.addChildLocally(message.message);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },
    err=>{
      this.tools.gracefulError(err);
    })

  }
  addChildLocally(child:DocumentGroup){
    if(!this.documentGroup.children) this.documentGroup.children = [];
    this.documentGroup.children.push(child);
    this.documentGroup._folderOpen = true;
  }

  update(){
    this.data.updateDocumentGroup(this.documentGroup).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.editable = false;
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  delete(){
    this.documentGroup.recordStatus = 3;
    this.data.updateDocumentGroup(this.documentGroup).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.documentGroup = null;
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  addDoc(){
    if(this.data.documentTypes){
      this.doAddDoc();
    }
    else{
      this.data.listDocumentTypes().subscribe((message:StatusMessage)=>{
        if(message.success){
          this.data.documentTypes = message.message;
          this.doAddDoc();
        }
        else{
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }
  }
  copy(doc:Document){
    let newdoc = new Document();
    newdoc.companyid = this.data.Company.id;
    newdoc.name = doc.name;
    newdoc.DocumentType = doc.DocumentType;
    newdoc.documentTypeid = doc.documentTypeid;
    newdoc.documentgroupid = doc.documentgroupid;
    newdoc.recordStatus = 0;
    newdoc.html = doc.html;
    this.data.createDocument(newdoc).subscribe((message:StatusMessage)=>{
      if(message.success){
        let created = message.message;
        created.DocumentType = newdoc.DocumentType;
        this.documentGroup.Documents.push(created);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  doAddDoc(){
    let newdoc = new Document();
    newdoc.documentgroupid = this.documentGroup.id;
    newdoc.companyid = this.data.Company.id;
    newdoc.name = "New Document";
    newdoc.documentTypeid = this.data.documentTypes[0].id;
    newdoc.DocumentType = this.data.documentTypes[0];
    newdoc.recordStatus = 0; 
    this.data.createDocument(newdoc).subscribe((message:StatusMessage)=>{
      if(message.success){
        message.message.DocumentType = newdoc.DocumentType;
        if(!this.documentGroup.Documents) this.documentGroup.Documents = [];
        this.documentGroup.Documents.push(message.message);
        this.documentGroup._folderOpen = true;
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  openUpdateDoc(document:Document){
    if(this.data.documentTypes){
      document._editable = true;
    }
    else{
      this.data.listDocumentTypes().subscribe((message:StatusMessage)=>{
        if(message.success){
          this.data.documentTypes = message.message;
          document._editable = true;
        }
        else{
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }
  }

  updateDoc(document:Document){
    
    this.data.updateDocument(document).subscribe((message:StatusMessage)=>{
      if(message.success){
        document._editable = false;
        document.DocumentType = this.data.documentTypes.find(dt=>dt.id==document.documentTypeid);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
    
  }

  deleteDoc(document:Document,index:number){
    document.recordStatus = 3;
    this.data.updateDocument(document).subscribe((message:StatusMessage)=>{
      if(message.success){
        document._editable = false;
        this.documentGroup.Documents.splice(index,1);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
}
