import { Component, OnInit, ElementRef, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { } from 'googlemaps';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage, CompanyAssociation, Site } from 'src/app/models/user';
import { GoogleService, DrawnPoly } from 'src/app/services/google.service';
import { EventsService } from 'src/app/services/events.service';
import { ToolsService } from 'src/app/services/tools.service';
import { Cities, GoogleDistanceResult, GoogleElement } from 'src/app/models/data';
import { Region, GPS, RegionTypes, Feature, RegionDisplayInfo, FeaturePolygon, ZoneType } from 'src/app/models/rates';
import { Country, RecordStatus } from 'src/app/models/models';
import { CompanySearchComponent } from 'src/app/widgets/company-search/company-search.component';
import { MapSelect } from 'src/app/models/ui';
import { RegionService } from 'src/app/services/region.service';
//import { Subscription } from 'rxjs';


@Component({
  selector: 'cs-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements AfterViewInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @ViewChild('cosearch') cosearch: CompanySearchComponent;
  @ViewChild('addMenu') addMenu: TemplateRef<any>;

  mapAdded: boolean = false;
  map: google.maps.Map;
  //overlayRef: OverlayRef | null;
  //sub: Subscription;

  //contextmenu for region additions
  public addon = { on: false, click: { x: 0, y: 0 }, region: null, options: [] };

  //public continents; = [{ id: 1, name: "Europe", latlng: new google.maps.LatLng(54.5, 15.2), zoom: 4 }, { id: 2, name: "Africa", latlng: new google.maps.LatLng(9.75, 18.56), zoom: 4 }, { id: 3, name: "South America", latlng: new google.maps.LatLng(-16.1, -59.8), zoom: 4 }, { id: 4, name: "North America", latlng: new google.maps.LatLng(49.1, -105.9), zoom: 4 }, { id: 5, name: "Asia", latlng: new google.maps.LatLng(33.9, 103), zoom: 4 }, { id: 6, name: "Oceania", latlng: new google.maps.LatLng(-11.95, 132.7), zoom: 4 }];

  /**UK cities by default */
  public majorCities: Cities[] = [{ id: 1, countryid: 225, name: "London", addressid: 0 }, { id: 2, countryid: 225, name: "Birmingham", addressid: 0 }, { id: 3, countryid: 225, name: "Glasgow", addressid: 0 }, { id: 4, countryid: 225, name: "Liverpool", addressid: 0, searchName: "Liverpool, UK" }, { id: 5, countryid: 225, name: "Bristol", addressid: 0 }, { id: 6, countryid: 225, name: "Manchester", addressid: 0 }, { id: 7, countryid: 225, name: "Sheffield", addressid: 0 }, { id: 8, countryid: 225, name: "Leeds", addressid: 0 }, { id: 9, countryid: 225, name: "Edinburgh", addressid: 0 }, { id: 10, countryid: 225, name: "Leicester", addressid: 0 }, { id: 11, countryid: 225, name: "Cardiff", addressid: 0 }, { id: 12, countryid: 225, name: "Belfast", addressid: 0 }, { id: 13, countryid: 225, name: "Hull", addressid: 0 }, { id: 14, countryid: 225, name: "Newcastle", addressid: 0, searchName: "Newcastle, UK" }, { id: 15, countryid: 225, name: "Southampton", addressid: 0 }, { id: 16, countryid: 225, name: "Portsmouth", addressid: 0 }, { id: 17, countryid: 225, name: "Aberdeen", addressid: 0 }, { id: 18, countryid: 225, name: "Inverness", addressid: 0 }, { id: 19, countryid: 225, name: "Land's End", addressid: 0, searchName: "Land's End, UK" }, { id: 20, countryid: 225, name: "John O'Groats", addressid: 0 }, { id: 21, countryid: 225, name: "Londonderry", addressid: 0 }, { id: 22, countryid: 225, name: "Great Yarmouth", addressid: 0 }, { id: 23, countryid: 225, name: "Anglesey", addressid: 0 }]

  public citiesloaded = false;

  public countries = [];

  public selectedView = "1";
  public selectedContinent: Region;
  public selectedCountry: Country;
  //public selectedSupplier: CompanyAssociation;

  public baseSite: Site;

  public excludedRegions: Region[] = [];
  public deletedRegions: Region[] = [];

  public imperial = true;

  public miles = 0.62137;

  public fixed = 0;
  public perMile = 0;
  public perHour = 0;
  public regions: Region[] = [];

  public regiontypes = RegionTypes;

  //public preLoaded = false;

  homemarker: google.maps.Marker;

  constructor(public data: DataService, public geo: GoogleService, public events: EventsService, public tools: ToolsService, public regionservice: RegionService) {

    this.events.companyselect.subscribe((coassoc: CompanyAssociation) => {
      this.changeOwnerCompany(coassoc);
      /*if (this.preLoaded) {
        setTimeout(() => {
          
        });
      }*/

    })


    this.events.selectRegion.subscribe((region: Region) => {
      this.selectRegion(region);
    })
    this.events.deleteRegion.subscribe((region: Region) => {
      this.deleteRegion(region);
    })
    this.events.dropRegion.subscribe((e: any) => {
      this.drop(e)
    })

    this.events.createRegion.subscribe((parentregion: Region) => {
      this.regionservice.setPalette(parentregion.children.length);
      parentregion.children.sort((a,b)=>{
        if(a.sequence<b.sequence) return -1;
        else return 1;
      })
      this.regionservice.setDisplayInfo(parentregion.children, parentregion.hierarchyLevel, parentregion.hierarchyLevel > 0 ? parentregion.RegionDisplayInfo : null);
      this.openParent(parentregion);
    })
    this.events.updateParentRegion.subscribe((parentRegion: Region) => {
      let updatable = this.findRegion(this.regions, parentRegion.id);
      updatable.children = parentRegion.children;

      updatable.children.forEach(ch => {
        if (ch.id > 0) {
          this.data.updateRegion(ch).subscribe((message: StatusMessage) => {
            if (!message.success) {
              this.tools.gracefulError(message.message);
            }
            else {
              if (ch.RegionDistancer) {
                this.data.createOrUpdateRegionDistancer(ch);
              }
            }
          })
        }
        else {
          this.data.createRegion(ch).subscribe((message: StatusMessage) => {
            if (message.success) {
              ch.id = message.message.id;
              ch.hierarchyLevel = message.message.hierarchyLevel;
              if (ch.RegionDistancer) {
                ch.RegionDistancer.regionid = message.message.id;
                this.data.createOrUpdateRegionDistancer(ch);
              }
            }
          })
        }
      })

    })
    /**
     * clicking on a region on the map includes or excludes it
     */
    this.events.polygonClick.subscribe((drawnpoly: DrawnPoly) => {
      let region = this.findRegion(this.regions, drawnpoly.regionid);
      if (this.regionservice.selectedRegion && this.regionservice.selectedRegion.id == region.id) {
        this.selectRegion(region);
      }
      else if (this.regionservice.selectedRegion && this.regionservice.selectedRegion.id == region.parentId) {
        if (region.recordStatus == RecordStatus.Suspended) {
          this.includeRegion(region);
        }
        else this.excludeRegion(region);
      }
      else this.selectRegion(region);

    })
    
    this.events.settingsTabChange.subscribe((index: number) => {
      if (index == 2) {
        let bob = 1;
        /* try without this
        if (!this.preLoaded) {
          setTimeout(() => {
            this.setup();
            this.addMap();
            this.preLoaded = true;
          }, 300)
        }*/
      }


    });
  }

  ngAfterViewInit(): void {

    if (!this.data.dataIsReady) {
      this.data.dataReady.subscribe(() => {
        this.setup();
        this.addMap();
      })
    }
    else{
      //add this to compensate for commenting out settingstabchange event above also removed the preloaded condition on included regions
      this.setup();
      this.addMap();
    }
  }


  get home(): google.maps.LatLng {
    return new google.maps.LatLng(this.data.selectedSupplier._baseSite.Address.gpslat, this.data.selectedSupplier._baseSite.Address.gpslong);
  }

  setup() {
    /**
     * get the continental regions
     */
    this.data.listContinents().subscribe((message: StatusMessage) => {
      if (message.success) {
        this.data.continents = message.message;
        this.setToHome();
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })


  }

  prepareAddOff() {
    setTimeout(() => {
      this.addon.on = false;
    }, 3000);
  }

  /**
   * set the intial country and continent to the user's site address
   */
  setToHome() {
    if(!this.data.selectedSupplier){
      this.data.setupSelectedProvider(); 
    }
    if(this.data.selectedSupplier._baseSite && this.data.selectedSupplier._baseSite.Address){
      this.selectedCountry = this.data.countries.filter(c => c.id == this.data.selectedSupplier._baseSite.Address.countryid)[0];
      this.selectedContinent = this.data.continents.filter(c => c.id == this.selectedCountry.continentid)[0];
      this.countries = this.data.countries.filter(c=>c.continentid==this.selectedContinent.id);
    }
    
    this.startupRegions(parseInt(this.selectedView)).then((success: boolean) => {
      this.map.setCenter(this.home);
      this.addHomeMarker();
    });

  }
  /**
   * get a view of regions from associated companies, trigger via the company-search snippet
   * @param co 
   */
  changeOwnerCompany(co: CompanyAssociation) {
    this.regionservice.clearMap();
    co._baseSite = co.Provider.Sites[0];
    this.data.selectedSupplier = co;
    this.setToHome();
    this.cosearch.setDisplayName(co.Provider.name);
  }
  /**get driving distance to major cities from site address */
  getMajorCities() {
    if (!this.citiesloaded) {
      this.tools.fromStore("wm-uk-cities").then((cities: Cities[]) => {
        if (cities) {
          this.majorCities = cities;
          this.citiesloaded = true;
        }
        else {
          let origin = [this.tools.googleAddress(this.data.selectedSupplier._baseSite.Address)];
          let destinations = this.majorCities.map(city => city.searchName ? city.searchName : city.name);
          this.geo.drivingDistance(origin, destinations, false, "DRIVING").then((message: StatusMessage) => {
            let result: GoogleDistanceResult = message.message.result;
            let index = 0;
            result.rows[0].elements.forEach((element: GoogleElement) => {
              this.majorCities[index].offset = element;
              index++;
            });
            this.citiesloaded = true;
            this.tools.toStore("wm-uk-cities", this.majorCities);
          })

        }
      })
    }

  }

  private dropIndex: number;

  //moved to region.component
  drop(e) {
    console.log(e);

    let foundRegion: Region;

    let moved: Region = e.item.data;
    if (moved.parentId) {

      let newParent: Region = e.container.data;
      if (newParent.hierarchyLevel == moved.hierarchyLevel) {
        newParent = this.findRegion(this.regions, newParent.parentId);
      }
      this.moveRegionBetweenParents(newParent, moved);

      //this.regions = this.regions;
      //console.log(foundRegion);
    }

  }
  dropInc(e) {
    console.log(e);
  }

  moveRegionBetweenParents(newParent: Region, tomove: Region) {
    let done = false;
    let oldParent: Region = this.findRegion(this.regions, tomove.parentId);
    if (newParent.id != oldParent.id) {
      if (newParent.hierarchyLevel == oldParent.hierarchyLevel) {
        tomove.parentId = newParent.id;
        let index = 0;
        //take it out of the UI for the old parent
        oldParent.children.some(c => {
          if (c.id == tomove.id) {
            return true;
          }
          index++;
        })
        oldParent.children.splice(index, 1);

        //add it to the ui for the new parent
        if (!newParent.children) newParent.children = [];
        newParent.children.unshift(tomove);

        done = true;
        this.data.moveRegionBetweenParents(tomove, oldParent.id).subscribe((message: StatusMessage) => {
          console.log(message);
          this.rebuildParentFeatures(oldParent);
          this.rebuildParentFeatures(newParent);
          //this.tools.snackMessage("Done, TODO... resequence child regions");
        }, err => {
          this.tools.gracefulError(err);
        })

      }
      else {
        this.tools.gracefulError("Regions can only be moved between regions at the same level");
      }
    }
    else {
      //resequence
      //this.tools.snackMessage("TODO... resequence needed.");
    }
    return done;
  }

  resequenceRegionChildren() {
    return new Promise((resolve, reject) => {

    })
  }
  /**
   * on the create of a new set of regions, bring the feature map into alignment for ancestors
   * NOT IMPLEMENTED
   * @param region 
   */
  openParent(region: Region) {
    region._folderOpen = true;
    /*this.deselectAll();
    region._selected = true;
    this.selectRegion(region);*/
    return;

    /*
    if (region.regiontypeid > 1) {
      let featurepolygons: FeaturePolygon[] = [];
      region.children.forEach(ch => {
        if (ch.Feature && ch.Feature.FeaturePolygons) {
          ch.Feature.FeaturePolygons.forEach(eachfp => {
            let newfp = new FeaturePolygon();
            newfp.featureid = region.featureid;
            newfp.polygonid = eachfp.polygonid;
            featurepolygons.push(newfp);
          });
        }
      })

      if (region.Feature && region.Feature.FeaturePolygons) {
        this.data.createFeaturePolygons(featurepolygons).subscribe((message: StatusMessage) => {
          if (message.success) {
            let newfps: FeaturePolygon[] = message.message;
            region.Feature.FeaturePolygons = region.Feature.FeaturePolygons.concat(newfps);
            if (region.parentId) {
              let parent = this.findRegion(this.regions, region.parentId);
              this.walkupParents(parent);
            }
            else {
              //region adding finished
              this.deselectAll();
              region._selected = false;
              this.selectRegion(region);
              this.isLoading = false;
            }
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })

      }
      else {
        let feature = new Feature();
        feature.name = region.name;
        feature.FeaturePolygons = featurepolygons;
        this.data.createFeatureAndFeaturePolygons(feature, region.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            region.Feature = message.message;
            region.featureid = region.Feature.id;
            if (region.parentId) {
              let parent = this.findRegion(this.regions, region.parentId);
              this.walkupParents(parent);
            }
            else {
              //region adding finished
              this.deselectAll();
              region._selected = false;
              this.selectRegion(region);
              this.isLoading = false;
            }
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
    }
    let featurepolygons: FeaturePolygon[] = [];
    region.children.forEach(ch => {
      if (ch.Feature && ch.Feature.FeaturePolygons) {
        ch.Feature.FeaturePolygons.forEach(eachfp => {
          let newfp = new FeaturePolygon();
          newfp.featureid = region.featureid;
          newfp.polygonid = eachfp.polygonid;
          featurepolygons.push(newfp);
        });
      }
    })

    if (region.Feature && region.Feature.FeaturePolygons) {
      this.data.createFeaturePolygons(featurepolygons).subscribe((message: StatusMessage) => {
        if (message.success) {
          let newfps: FeaturePolygon[] = message.message;
          region.Feature.FeaturePolygons = region.Feature.FeaturePolygons.concat(newfps);
          if (region.parentId) {
            let parent = this.findRegion(this.regions, region.parentId);
            this.walkupParents(parent);
          }
          else {
            //region adding finished
            this.deselectAll();
            region._selected = false;
            this.selectRegion(region);
            this.isLoading = false;
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })

    }
    else {
      let feature = new Feature();
      feature.name = region.name;
      feature.FeaturePolygons = featurepolygons;
      this.data.createFeatureAndFeaturePolygons(feature, region.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          region.Feature = message.message;
          region.featureid = region.Feature.id;
          if (region.parentId) {
            let parent = this.findRegion(this.regions, region.parentId);
            this.walkupParents(parent);
          }
          else {
            //region adding finished
            this.deselectAll();
            region._selected = false;
            this.selectRegion(region);
            this.isLoading = false;
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }*/
  }

  /**
   * rebuild the feature polygons of a region. The db side of things is taken care of already on the move of a child.
   * @param region 
   */
  rebuildParentFeatures(region: Region) {
    let featurepolygons: FeaturePolygon[] = [];
    region.children.forEach(ch => {
      if (!ch.Feature) {
        ch.Feature = new Feature();
        ch.Feature.FeaturePolygons = [];
      }
      if (!ch.Feature.FeaturePolygons) {
        ch.Feature.FeaturePolygons = [];
      }
      ch.Feature.FeaturePolygons.forEach(fp => {
        featurepolygons.push(fp);
      })
    })
    if (!region.Feature) region.Feature = new Feature();
    if (!region.Feature.FeaturePolygons) region.Feature.FeaturePolygons = [];

    region.Feature.FeaturePolygons = featurepolygons;
    this.regionservice.checkAdders(region);
    if (region.parentId) {
      this.rebuildParentFeatures(this.findRegion(this.regions, region.parentId));
    }
  }

  checkChildIndex(regions: Region[], searchIndex): Region {
    let foundRegion: Region;
    regions.some(r => {
      if (this.dropIndex == searchIndex) {
        foundRegion = r;
        return true;
      }
      this.dropIndex++;
      if (!foundRegion && r.children && r._folderOpen) {
        foundRegion = this.checkChildIndex(r.children, searchIndex);
      }
      if (foundRegion) return foundRegion;
    })
    return foundRegion;
  }
  findRegion(regions: Region[], id: number): Region {
    let region: Region;
    //depth can be daunting so start row by row rather than proper tree search
    regions.some(r => {
      if (r.id == id) {
        region = r;
        return true;
      }
    })
    if (!region) {
      regions.some(r => {
        if (r.children) {
          region = this.findRegion(r.children, id);
        }
        if (region) return region;
      })
    }
    return region;

  }
  checkChildren(regions: Region, id: number): Region {
    let region: Region;
    if (regions.children) {
      regions.children.some(r => {
        if (r.id == id) {
          region = r;
          return true;
        }
      })

    }

    if (regions) {

    }
    return region;
  }
  fee(offset: GoogleElement) {
    if (offset.status == "OK") return this.fixed + offset.distance.value / 1000 * this.perMile + offset.duration.value / 3600 * this.perHour;
    else return 0;
  }
  distance(offset) {
    if (offset.status == "OK") {
      return offset.distance.value / 1000 * this.miles;
    }
    else return 0;
  }
  time(offset) {
    if (offset.status == "OK") {
      //let hours = Math.floor(offset.duration.value/3600);
      //let mins = Math.round((offset.duration.value - hours*3600)/60);

      return offset.duration.value * 1000;
    }
    else return 0;
  }


  listRegions(parentid: number) {
    return new Promise((resolve, reject) => {
      this.data.listRegionsChildren(this.selectedCountry.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          resolve(message.message);
          /*this.regions3 = message.message;
          
          let allregions = message.message;
          this.regions1 = allregions.filter(r => r.regiontypeid == RegionTypes.International);
          this.regions2 = allregions.filter(r => r.regiontypeid == RegionTypes.Continental);
          this.regions3 = allregions.filter(r => r.regiontypeid == RegionTypes.National);
          switch (this.selectedView) {
            case "1":
              this.regions = this.regions1;
              break;
            case "2":
              this.regions = this.regions2;
              break;
            case "3":
              this.regions = this.regions3;
              break;
          }*/
        }
        else {
          reject(message.message);
        }
      }, err => {
        reject(err);
      })
    })
  }


  addMap() {
    /*if (this.address.gpslat && this.address.gpslong) {
      let lat = this.address.gpslat;
      let long = this.address.gpslong;
      this.sitelatlng = new google.maps.LatLng(lat, long);
    }
    else {
      this.tools.snackMessage("Set your site GPS location via Settings/Edit Company to calculate distances");
      this.sitelatlng = new google.maps.LatLng(0, 0);
    }*/

    //let zoom = 6;
    if (!this.mapAdded) {

      const mapProperties = {
        //center: this.sitelatlng,
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.map.addListener('click', (e) => {
        this.mapClick(e);
      })
    }



  }
  addHomeMarker() {
    if (this.homemarker) this.homemarker.setMap(null);
    this.homemarker = new google.maps.Marker({
      position: this.home,
      map: this.map,
      title: this.data.selectedSupplier._baseSite.name
    });
  }

  /**handle the switch from region level */
  viewChange(e) {
    this.selectedView = e.value;
    this.geo.clearAllPolys();
    this.startupRegions(parseInt(e.value));
  }

  /**
   * depending on the region type, get a hierarchy of Regions from the db
   * @param regiontype
   * db get interaction, async then selectRegion()
   */
  startupRegions(regiontype: RegionTypes) {
    return new Promise((resolve) => {
      let selectedStart: number;
      switch (regiontype) {
        case RegionTypes.Continental:
          selectedStart = this.selectedContinent.id;
          break;
        case RegionTypes.National:
          selectedStart = this.selectedCountry.id;
          this.getMajorCities();
          break;
      }
      this.data.listRegionsStartup(regiontype, selectedStart, this.data.selectedSupplier._baseSite.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.regions = message.message;

          this.regionservice.setDisplayInfo(this.regions, 0);
          if (this.regions.length > 0) {

            //this.selectRegion(this.regions[0]);

            resolve(true);
          }
          else {
            this.regionservice.selectedRegion = null;
            resolve(true);
          }
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);
        }

      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      })
    })

  }

  handleExcluded(regions: Region[], top: boolean) {
    if (top) {
      this.excludedRegions = [];
      this.deletedRegions = [];
    }
    regions.forEach(r => {
      if (r.recordStatus == RecordStatus.Suspended) {
        this.excludedRegions.push(r);
        //this.geo.excludedPolys.push({regionid:r.id,poly:null});
      }
      if (r.recordStatus == RecordStatus.Archive) {
        this.deletedRegions.push(r);
        //this.geo.deletedPolys.push({regionid:r.id,poly:null});
      }
      if (r.children) {
        this.handleExcluded(r.children, false);
      }
    })
  }






  changeContinent(e) {
    //this.selectedView = "2";
    let conti = this.data.continents.filter(c => c.id == e.value.id)[0];
    if (conti.GPS) {
      let latlng = new google.maps.LatLng(conti.GPS.lat, conti.GPS.long);
      this.map.panTo(latlng);
    }
    if (conti.zoom) {
      this.map.setZoom(conti.zoom);
    }

    this.selectedContinent = conti;
    if (this.selectedView == "2") {
      this.startupRegions(RegionTypes.Continental);
    }
    else {
      this.data.listRegionsStartup(RegionTypes.Continental, this.selectedContinent.id, this.data.selectedSupplier._baseSite.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          let countries: Region[] = message.message;
          if (countries.length > 0) {
            this.selectedCountry = this.data.countries.filter(c => c.id == countries[0].countryid)[0];
            this.startupRegions(RegionTypes.National);
          }
          else {
            this.tools.snackMessage("No Countries currently available for this continent");
          }
        }
        else {
          this.tools.gracefulError(message.message);
        }

      }, err => {
        this.tools.gracefulError(err);
      })

    }
  }
  getmapdets() {
    console.log(this.map.getCenter(), this.map.getZoom());
  }
  /**
   * run startup for national regions geocoding country if it doesn't already have one.
   * @param e 
   * db update if no existing GPS set for the country
   */
  changeCountry(e) {
    this.selectedView = "3";
    let country = e.value;
    if (country.GPS) {
      this.map.panTo(new google.maps.LatLng(country.GPS.lat, country.GPS.long));
      this.map.setZoom(country.zoom);
      this.startupRegions(RegionTypes.National);
    }
    else {
      this.geo.geocode(country.name).then((message: StatusMessage) => {
        let latlng: google.maps.LatLng = message.message[0].geometry.location
        this.map.panTo(latlng);
        this.map.setZoom(6);
        console.log("latlng:", latlng.lat(), latlng.lng());
        let gps = new GPS();
        gps.lat = latlng.lat();
        gps.long = latlng.lng();
        this.data.createGPS(gps).subscribe((message: StatusMessage) => {
          if (message.success) {
            country.gpsid = message.message.id;
            country.GPS = message.message;
            this.data.updateCountry(country).subscribe((message: StatusMessage) => {
              if (message.success) {
                this.startupRegions(RegionTypes.National);
              }
              else this.tools.gracefulError(message.message);
            })
          }
          else this.tools.gracefulError(message.message);
        },
          err => {
            this.tools.gracefulError(err);
          })


      },
        err => {
          this.tools.gracefulError(err);
        })
    }
  }

  changeBaseSite(e) {
    this.regionservice.selectedRegion.basesiteid = e.value;
    this.data.updateRegion(this.regionservice.selectedRegion).subscribe((message: StatusMessage) => {
      if (message.success) {
        let reg = message.message;
      }
    })
  }
  changeProviderSite(e) {
    this.regionservice.clearMap();
    this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites.filter(s => s.id == e.value)[0];
    this.setToHome();
    /*
    this.data.updateRegion(this.selectedRegion).subscribe((message: StatusMessage) => {
      if (message.success) {
        let reg = message.message;
      }
    })*/
  }

  createRegion() {

    switch (this.selectedView) {
      case "1":
        this.createWorldRegion();
        break;
      case "2":
        this.createContinentalRegion();
        break;
      case "3":
        this.createNationalRegion();
        break;
    }

  }
  /**
   * create a region encompassing the whole world.
   * No Features (its the whole thing)
   * GPS set to Head Office or first site
   * No Zoom (zoom to 2)
   * 
   */
  createWorldRegion() {

    let region = new Region(this.data.selectedSupplier.customerid, ZoneType.International);
    region.name = "The World";
    region.regiontypeid = RegionTypes.International;
    region.basesiteid = this.data.selectedSupplier._baseSite.id;
    /*
    let sites = this.data.selectedSupplier.Provider.Sites.filter(s => s.sitetypeid == SiteTypes.HEAD_OFFICE);
    if (sites.length > 0) {
      region.basesiteid = sites[0].id;
    }
    else region.basesiteid = this.data.selectedSupplier.Provider.Sites[0].id;*/
    this.data.createRegion(region).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.regions.push(message.message);
        this.selectRegion(message.message);
      }
    })

  }
  /**using the base continental region,clone it pan to and zoom */
  createContinentalRegion() {
    let region = new Region(this.data.selectedSupplier.customerid, ZoneType.Continental);
    this.events.startSpinner();
    let already = this.regions.filter(r => r.name.indexOf(this.selectedContinent.name) >= 0);
    if (already.length > 0) {
      region.name = this.selectedContinent.name + " " + (already.length + 1).toString();
    }
    else region.name = this.selectedContinent.name;
    region.regiontypeid = RegionTypes.Continental;
    region.continentid = this.selectedContinent.id;
    region.gpscentreid = this.selectedContinent.gpscentreid;
    region.GPS = this.selectedContinent.GPS;
    region.featureid = this.selectedContinent.featureid;
    region.Feature = this.selectedContinent.Feature;
    region.zoom = this.selectedContinent.zoom;
    region.basesiteid = this.data.selectedSupplier._baseSite.id;
    this.data.createRegion(region).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.regions.push(message.message);
        this.selectRegion(message.message);
        this.tools.snackMessage("Region Added");
        this.events.stopSpinner();
      }
      else {
        this.tools.gracefulError(message.message);
        this.events.stopSpinner();
      }
    }, err => {
      this.tools.gracefulError(err);
      this.events.stopSpinner();
    })
  }
  /**using the base continental region,clone it pan to and zoom */
  createNationalRegion() {
    this.events.startSpinner();
    let region = new Region(this.data.selectedSupplier.customerid, ZoneType.National);
    let already = this.regions.filter(r => r.name.indexOf(this.selectedCountry.name) >= 0);
    if (already.length > 0) {
      region.name = this.selectedCountry.name + " " + (already.length + 1).toString();
    }
    else region.name = this.selectedCountry.name;
    region.basesiteid = this.data.selectedSupplier._baseSite.id;
    region.regiontypeid = RegionTypes.National;
    region.continentid = this.selectedCountry.id;
    region.countryid = this.selectedCountry.id;
    this.data.getCountryRegion(region.countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let coreg: Region = message.message;
        region.gpscentreid = coreg.gpscentreid;
        region.GPS = coreg.GPS;
        region.featureid = coreg.featureid;
        region.Feature = coreg.Feature;
        region.zoom = coreg.zoom;
        this.data.createRegion(region).subscribe((message: StatusMessage) => {
          if (message.success) {
            region.id = message.message.id;
            region.hierarchyLevel = message.message.hierarchyLevel;
            this.regions.push(region);
            this.selectRegion(region);
            this.events.stopSpinner();
            this.tools.snackMessage("Region Added");
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }

  showPainted() {
    console.log(this.geo.selectedPolys);
    console.log(this.geo.excludedPolys);
  }
  /**
   * given an array of regions remove any drawn polys from the map 
   * @param array 
   * no db interaction
   */
  deselectAll(array?: Region[]) {
    if (!array) array = this.regions;
    array.forEach(r => {
      this.findAndClearAllPolys(r.id);
      r._selected = false;
      if (r.children) this.deselectAll(r.children);
    })
  }

  /**
   * 
   * @param regionid search selected and exluded polys bin and clear them from the map
   * no db interaction
   */
  findAndClearAllPolys(regionid: number) {
    this.findAndClearPolys(this.geo.selectedPolys, regionid);
    //this.findAndClearPolys(this.geo.excludedPolys, regionid);
  }
  /**
   * clear any polys in an array from the map
   * @param array either selected or excluded polys array
   * @param regionid
   * no db interaction
   */
  findAndClearPolys(array: DrawnPoly[], regionid: number) {
    let index = 0;
    let found = false;
    array.some(sp => {
      if (sp.regionid == regionid) {
        sp.poly.forEach(dp => {
          dp.setMap(null);
        })
        found = true;
        return true;
      }
      index++;
    })
    if (found) array.splice(index, 1);
  }


  /**
   * clear any polys, draw the new ones, select or deselect the region and pan and zoom to if available
   * @param region 
   */
  selectRegion(region: Region) {

    this.regionservice.clearMap();
    if (region._selected) {
      console.log("do something to deselect");
      region._selected = false;
      if (region.children) this.deselectAll(region.children);
    }
    else {
      if (!region.parentId && region.children) this.handleExcluded(region.children, true);
      this.deselectAll();

      region._selected = true;
      this.regionservice.selectedRegion = region;
      //console.log(region);
      if (region.Feature || (region.children && region.children.length > 0)) {
        this.events.startSpinner(region.children ? region.children.length : 0);
        this.paintPolygons(region).then(success => {
          this.events.stopSpinner();
          if (success) this.zone();
          else this.tools.gracefulError("no map information present");
        });
      }
      else {
        this.zone();
      }

    }

  }

  /**
   * remove region from its parent
   * @param region 
   */
  deleteRegion(region: Region) {

    if (region.parentId) {
      let parent = this.findRegion(this.regions, region.parentId);
      if (parent) {
        let index = 0;
        parent.children.some(ch => {
          if (ch.id == region.id) {
            return true;
          }
          index++;
        })
        parent.children.splice(index, 1);
        this.regionservice.checkAdders(parent);
      }
    }
    else {
      let index = 0;
      this.regions.some(r => {
        if (r.id == region.id) {
          return true;
        }
        index++;
      })
      this.regions.splice(index, 1);
    }
    
  }

  /**
   * set the bounds, centre and zoom for the currently visible regions.
   */
  zone() {
    let bounds = this.geo.getDisplayedBounds();
    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds);
    }
    else {
      if (this.regionservice.selectedRegion.GPS) {
        this.map.panTo(new google.maps.LatLng(this.regionservice.selectedRegion.GPS.lat, this.regionservice.selectedRegion.GPS.long));
      }
      else if (!this.regionservice.selectedRegion.parentId) {
        this.map.panTo(this.home);
      }
      if (this.regionservice.selectedRegion.zoom) {
        this.map.setZoom(this.regionservice.selectedRegion.zoom);
      }
    }
  }

  logPolys() {
    console.log(this.geo.selectedPolys);
  }

  /**
   * get all the polygons for a given feature
   * @param feature
   * db interaction list only, return promise
   */
  getPolygonsAsync(feature: Feature) {
    return new Promise((resolve, reject) => {
      if (feature.Polygons) {
        resolve(feature);
      }
      else {
        this.data.getFeaturePolygons(feature.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            feature = message.message;
            resolve(feature);
          }
          else {
            reject(message.message);
          }
        }, err => {
          reject(err);
        })
      }
    })
  }
  /**
   * Promise of type boolean (successful)
   * Manage the painting of the polygons
   * @param region 
   * paint either the feature polygons of the children or its own if childless
   * db intensive for first load of any region
   * async. will get on with it - use isLoading somewhere
   */
  paintPolygons(region: Region) {
    return new Promise(resolve => {
      this.regionservice.setDisplayInfo([region], 0);
      if (region.children && region.children.length > 0) {
        let todo = region.children.length;
        let done = 0;
        let childrenpainted = false;
        region.children.some(ch => {
          this.events.updateSpinner();
          if (ch.Feature && ch.Feature.FeaturePolygons && ch.Feature.FeaturePolygons.length > 0) {
            this.geo.addRegionFeaturePolys(this.map, ch);
            childrenpainted = true;
            done++;
            if (done == todo) {
              this.paintExlusions();
              resolve(true);
            }
          }
          else {
            if (ch.featureid) {
              this.data.getFeaturePolygons(ch.featureid).subscribe((message: StatusMessage) => {
                if (message.success) {
                  ch.Feature = message.message;
                  this.geo.addRegionFeaturePolys(this.map, ch);
                  childrenpainted = true;
                  done++;
                  if (done == todo) {
                    this.paintExlusions();
                    resolve(true);
                  }
                }
                else {
                  done++;
                  if (done == todo) {
                    console.log("Failed to draw polygons for " + ch.name);
                    this.paintExlusions();
                    resolve(true);
                  }

                }
              }, err => {
                done++;
                if (done == todo) {
                  console.log("Failed to draw polygons for " + ch.name);
                  this.paintExlusions();
                  resolve(true);
                }
              })
            }
            else {
              //the regions children have no featureid
              //make and save a new one if we need to - this will go away - only legacy regiongroups affected
              //will only be called if the child has no feature id
              if (ch.children && ch.children.length > 0) {
                let cando = true;
                ch.children.some(chh => {
                  if (!chh.featureid) {
                    cando = false;
                    return true;
                  }
                })
                if (cando) {
                  this.paintGrandchildren(region).then(success => {
                    if (success) {
                      done++;
                      childrenpainted = true;
                      if (done == todo) {
                        this.paintExlusions();
                        resolve(true);
                      }
                    }
                    else {
                      done++;
                      if (done == todo) {
                        this.paintExlusions();
                        if (!childrenpainted) {
                          this.revertToPaintingParentPolygons(region).then(success => {
                            resolve(success);
                          })
                        }
                        else {
                          console.log("Failed to draw polygons for " + ch.name);
                          resolve(true);
                        }

                      }

                    }
                  })
                  /*
                  deprecated - generate dynamically so changes are picked up without having to redraw with every change
                  this.data.passFeaturePolygonsToParent(ch.id).subscribe((message: StatusMessage) => {
                    if (message.success) {
                      let updated: Region = message.message;
                      ch.featureid = updated.featureid;
                      ch.Feature = updated.Feature; 
                      console.log("features recreated try selecting region again");
                    }
                    else{
                      this.tools.gracefulError(message.message);
                    }
                  },err=>this.tools.gracefulError(err))*/

                  resolve(true);
                }
                else {
                  this.revertToPaintingParentPolygons(region).then(success => {
                    resolve(success);
                  })
                }
              }
              else {
                //no polygons for this child
                //using the parent, paint the regions feature polygons
                if (ch.children) {
                  this.paintGrandchildren(ch).then(success => {
                    if (success) {
                      done++;
                      if (todo == done) {
                        this.paintExlusions();
                        resolve(true);
                      }
                    }
                    else {
                      done++;
                      if (todo == done) {
                        this.paintExlusions();
                        console.log("failed to draw polygons for region: " + ch.name);
                        resolve(true);
                      }
                    }
                  })
                }
                else {
                  done++;
                  if (todo == done) {
                    this.paintExlusions();
                    if (!childrenpainted) {
                      this.revertToPaintingParentPolygons(region).then(success => {
                        resolve(success);
                      })
                    }
                    else {
                      console.log("Failed to draw polygons for " + ch.name);
                      resolve(true);
                    }
                  }
                }

              }


            }

          }

        })
      }
      else {
        this.revertToPaintingParentPolygons(region).then(success => {
          resolve(success);
        })
      }
    })


  }
  revertToPaintingParentPolygons(region: Region) {
    return new Promise((resolve) => {
      //using the parent, paint the regions feature polygons
      if (region.Feature && region.Feature.FeaturePolygons) {
        this.geo.addRegionFeaturePolys(this.map, region);
        this.paintExlusions();
        resolve(true);
      }
      else {
        //we have a feature id but no polygons yet, get them.
        if (region.featureid) {
          this.data.getFeaturePolygons(region.featureid).subscribe((message: StatusMessage) => {
            if (message.success) {
              region.Feature = message.message;
              this.geo.addRegionFeaturePolys(this.map, region);
              this.paintExlusions();
              resolve(true);
            }
            else {
              console.log("failed to draw polygons for region: " + region.name);
              resolve(true);
            }
          }, err => {
            console.log("failed to draw polygons for region: " + region.name);
            resolve(true);
          })
        }
        else {
          console.log("failed to draw polygons for region: " + region.name);
          resolve(true);
        }
      }
    })

  }
  /**
   * children have no features, grandchildren do
   * this happens when there are region groups underneath the region with features on their children
   * had considered creating a polygon for the region group but seems too inflexible.
   * Monitor for performance
   * @param region 
   */
  paintGrandchildren(region: Region) {
    return new Promise((resolve) => {
      let featureids: number[] = [];
      region.children.forEach((child) => {
        if (child.children) {
          child.children.forEach(gc => {
            featureids.push(gc.featureid);
          })
        }
      })
      this.data.getFeaturePolygonsChildren(featureids).subscribe((message: StatusMessage) => {
        if (message.success) {
          let features: Feature[] = message.message;
          region.children.forEach(ch => {
            if (ch.children) {
              ch.Feature = new Feature();
              ch.children.forEach(gch => {
                let polys = features.filter(f => f.id == gch.featureid)[0].FeaturePolygons;
                polys.forEach(poly => {
                  ch.Feature.FeaturePolygons.push(poly);
                })

              })
            }

            this.geo.addRegionFeaturePolys(this.map, ch);
          })
          resolve(true);
        }
        else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      })

      /*ch.Feature = new Feature();
                  ch.children.forEach(gch => {
                    if (gch.Feature.FeaturePolygons) {
                      gch.Feature.FeaturePolygons.forEach(poly => {
                        ch.Feature.FeaturePolygons.push(poly);
                      })
                    }

                  })
                  this.geo.addRegionFeaturePolys(this.map, ch);*/
    })
  }
  paintExlusions() {
    this.excludedRegions.forEach(r => {
      if (r.Feature && r.Feature.FeaturePolygons) {
        r.Feature.FeaturePolygons.forEach(p => {
          this.geo.changePolysShade(p.polygonid, this.regionservice.excludedColour.hex());
        })
      }
    })
    this.deletedRegions.forEach(r => {
      if (r.Feature && r.Feature.FeaturePolygons) {
        r.Feature.FeaturePolygons.forEach(p => {
          this.geo.changePolysShade(p.polygonid, this.regionservice.deletedColour.hex());
        })
      }
    })
  }
  /**
   * if polygons are already loaded, paint them, else fetch them from the db and then paint.
   * @param r region
   */
  xcheckFeatureAsync(r: Region) {

    if (r.Feature.Polygons) {
      this.xselectAndPaint(r);
    }
    else {
      this.getPolygonsAsync(r.Feature).then((feature: Feature) => {
        r.Feature = feature;
        this.xselectAndPaint(r);
      }, err => {
        this.tools.gracefulError(err);
      })

    }
  }

  /**
   * change the border line style of the region's polys based on whether or not it is selected
   * @param region 
   */
  invertBorderSelect(region: Region) {
    let strokeWeight = region._selected ? 1 : 0;
    if (region.Feature && region.Feature.Polygons && region.Feature.Polygons.length > 0) {
      let sps = this.geo.selectedPolys.filter(sp => sp.regionid == region.id).concat(this.geo.excludedPolys.filter(ep => ep.regionid == region.id));
      sps.forEach(sp => {
        sp.poly.forEach(p => {
          p.setOptions({ strokeWeight: strokeWeight });
        })
      })

    }
  }
  /**
   * update the regions record status and move to the relevant bin
   * @param region 
   */
  excludeRegion(region: Region) {
    region.recordStatus = RecordStatus.Suspended;
    this.data.updateRegion(region).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.excludedRegions.push(region);
        //this.geo.excludedPolys.push({regionid:region.id,poly:null});
        //this.geo.movePolyAndChangeShade(region, this.excludedShade, true);
        if (region.Feature) {
          region.Feature.FeaturePolygons.forEach(fp => {
            this.geo.changePolysShade(fp.polygonid, this.regionservice.excludedColour.hex());
          })
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    }
    )
  }
  /**
   * update the regions record status and move to the relevant bin
   * @param region 
   */
  includeRegion(region: Region) {
    if (this.regionservice.selectedRegion) {
      this.removeFromExcluded(region);
      region.recordStatus = RecordStatus.Active;
      if (region.parentId == this.regionservice.selectedRegion.id) {
        this.data.updateRegion(region).subscribe((message: StatusMessage) => {
          if (message.success) {

          }
          else this.tools.gracefulError(message.message);
        }, err => { this.tools.gracefulError(err) })
      }
      else {
        let done = this.moveRegionBetweenParents(this.regionservice.selectedRegion, region);
        if (done) {
          this.regionservice.selectedRegion._selected = false;
          this.deselectAll();
          this.selectRegion(this.regionservice.selectedRegion);

        }
      }


      /*
      this.data.updateRegion(region).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.geo.movePolyAndChangeShade(region, this.selectedShade, false);
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      }
      )*/
    }
  }
  /**
   * mark the region as archived (not deleted as we would have no visible record here)
   * @param region 
   */
  removeRegion(region: Region) {
    this.removeFromExcluded(region);
    region.recordStatus = RecordStatus.Archive;

    this.addToDeleted(region);
    this.data.updateRegion(region).subscribe((message: StatusMessage) => {
      if (message.success) {
        if (region.Feature) {
          region.Feature.FeaturePolygons.forEach(fp => {
            this.geo.changePolysShade(fp.polygonid, this.regionservice.deletedColour.hex());
          })
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    }
    )
  }
  /**search the excluded and deleted region arrays and remove this region
   * no db interaction
   */
  removeFromExcluded(region: Region) {
    let index = 0;
    if (region.recordStatus == RecordStatus.Suspended) {
      this.excludedRegions.some(ex => {
        if (ex.id == region.id) {
          return true;
        }
        index++;
      })
      this.excludedRegions.splice(index, 1);
    }
    else if (region.recordStatus == RecordStatus.Archive) {
      this.deletedRegions.some(ex => {
        if (ex.id == region.id) {
          return true;
        }
        index++;
      })
      this.deletedRegions.splice(index, 1);
    }

  }
  addToDeleted(region: Region) {
    this.deletedRegions.push(region);
  }
  removeFromDeleted(region: Region) {
    let index = 0;
    this.deletedRegions.some(de => {
      if (de.id == region.id) {
        return true;
      }
      index++;
    })
    this.deletedRegions.splice(index, 1);
    this.excludeRegion(region);
  }


  mapClick(e) {
    console.log(e);
    //let countryid = this.selectedRegion.countryid;
    //let country = this.data.countries.filter(c=>c.id==countryid)[0];
    let mapselect = new MapSelect();
    //TODO logic here
    mapselect.usepostcode = false;//country.use_postcode=="yes";
    mapselect.gps = e.latLng;
    if (this.regionservice.selectedRegion._canAddMap) {
      this.regionservice.addFromPlace(mapselect);//TODO make this a promise and add a spinner
    }
  }


  /**
 * deprecated
 * @param region 
 */
  xbuildFeaturesAsync(region: Region) {
    if (!region.Feature) {
      if (region.children) {
        let combined = new Feature();
        let todo = region.children.length;
        let done = 0;
        region.children.some(child => {
          if (child.Feature) {
            if (!child.Feature.Polygons) {
              this.getPolygonsAsync(child.Feature).then((feature: Feature) => {
                if (feature.Polygons) {
                  child.Feature = feature;
                  this.xselectAndPaint(child);
                }
                //draw rather than combine (saves region falling out of sync with child on exclude)
                /*done++;
                child.Feature = feature;
                combined.Polygons = combined.Polygons.concat(child.Feature.Polygons);
                if (todo == done) {
                  if (combined.Polygons.length > 0) {
                    region.Feature = combined;
                    this.selectAndPaint(region);
                  }
                  else {
                    console.log("nothing to paint");
                  }
  
                }*/
              })
            }
            else this.xselectAndPaint(child);

          }
        })
      }
    }
  }
  /**
 * 
 * @param region 
 */
  xselectAndPaint(region) {
    region._selected = true;
    let releventarray = region.recordStatus == 0 ? this.geo.selectedPolys : this.geo.excludedPolys;
    let done = releventarray.filter(c => c.regionid == region.id);
    if (done.length == 0) {
      this.geo.xaddRegionPolys(this.map, region, region.recordStatus == 0);
    }
    else {
      alert("Something already painted - not expected to happen");
    }
  }
}
