import { Component, OnInit, Input } from '@angular/core';
import { Region, RegionTypes, RegionGroupType, RegionDisplayInfo, GPS, ZoneType } from 'src/app/models/rates';
import { RecordStatus } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { StatusMessage, Address } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';
import { RegiongrouperComponent } from 'src/app/dialogs/regiongrouper/regiongrouper.component';
import { GoogleService } from 'src/app/services/google.service';
import { GoogleDistanceMatrix } from 'src/app/models/data';
import { LocationpickerComponent } from 'src/app/dialogs/locationpicker/locationpicker.component';
import { environment } from 'src/environments/environment';
import { DistanceGrouperComponent } from 'src/app/dialogs/region-groupers/distance-grouper/distance-grouper.component';
import { ContinentalGrouperComponent } from 'src/app/dialogs/region-groupers/continental-grouper/continental-grouper.component';
import { CountryGrouperComponent } from 'src/app/dialogs/region-groupers/country-grouper/country-grouper.component';
import { RegionService } from 'src/app/services/region.service';
import { PromptComponent } from 'src/app/dialogs/prompt/prompt.component';
import { BasicGrouperComponent } from 'src/app/dialogs/region-groupers/basic-grouper/basic-grouper.component';


@Component({
  selector: 'cs-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {

  @Input() region: Region;
  @Input() displayInfo: RegionDisplayInfo;
  @Input() selectedId: number;

  constructor(public events: EventsService, public data: DataService, public tools: ToolsService, public dialog: MatDialog, public geo: GoogleService, public service: RegionService) { }

  public editable = false;
  public isLoading = false;
  public zonetypes = ZoneType;

  ngOnInit(): void {
    this.service.checkAdders(this.region);
  }

  get activechildren(): number {
    return this.region.children.filter(c => c.recordStatus < 3).length;
  }
  /**
   * open or close a folder, the method for lazy loading the child regions is commented out, currently the regions are directly loaded
   * with only the features lazy loaded if needed.
   */
  clickFolder() {
    if (this.region._folderOpen) {
      this.region._folderOpen = false;
    }
    else {
      this.region._folderOpen = true;
      /*
      this.data.listRegionsChildren(this.region.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.region.children = message.message;
          this.region._folderOpen = true;
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })*/
    }
  }

  addContext() {


    switch (this.region.zonetype) {
      case ZoneType.International:
        this.addContinents();
        break;
      case ZoneType.Continental:
        this.addCountryGroups();
        break;
      case ZoneType.National:
      case ZoneType.NationalTerritory:
        this.groupCountryRegions();
        break;
      case ZoneType.NationalOutcode:
        this.addIncodes();
      /*
      case ZoneType.RegionalCountryGroup:
        this.addRegions();
        break;
      case RegionTypes.Regional:
        this.addLocalities();
        break;
      default:
        this.addBasic();
        break;
        */
    }
  }

  setEditable() {
    if (this.region.drivingdistance) {
      this.openDistanceBander(true);
    }
    else {
      this.editable = !this.editable;
    }
  }

  /**
   * if we have regions already, allow another to be added, otherwise start the structured build process depending on region type
   */
  addChild() {
    if (!this.region.children || this.region.children.length == 0) {
      switch (this.region.regiontypeid) {
        case RegionTypes.International:
          this.addContinents();
          break;
        case RegionTypes.Continental:
          this.addCountryGroups();
          break;
        case RegionTypes.National:
          this.addRegions();
          break;
        case RegionTypes.Regional:
          this.addLocalities();
          break;
        default:
          this.addBasic();
          break;
      }
    }
    else {
      let child = new Region(this.data.Company.id, ZoneType.International);
      child.parentId = this.region.id;
      child.name = this.region.name + " Region " + (this.region.children ? this.region.children.length + 1 : 1).toString();
      child.basesiteid = this.region.basesiteid;

      child.regiontypeid = this.assignRegionTypeId()
      this.data.createRegion(child).subscribe((message: StatusMessage) => {
        if (message.success) {
          if (!this.region.children) this.region.children = [];
          this.region.children.push(message.message);
          this.service.tidyUpAfterAddRegion(this.region, true);
        }
        else this.tools.gracefulError(message.message);
      }, err => this.tools.gracefulError(err))
    }
  }

  openDistanceBander(parent?: boolean) {
    const dialogRef = this.dialog.open(DistanceGrouperComponent, {
      width: 'auto',
      data: { parentregionid: parent ? this.region.parentId : this.region.id }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let parent: Region = result;
        if (this.region.id == parent.id) {
          this.region.children = parent.children;

          parent.children.forEach((child: Region) => {
            if (child.id > 0) {
              this.data.updateRegion(child).subscribe((message: StatusMessage) => {
                if (child.RegionDistancer) {
                  this.data.createOrUpdateRegionDistancer(child);
                }
              })
            }
            else {
              this.data.createRegion(child).subscribe((message: StatusMessage) => {
                if (message.success) {
                  child.id = message.message.id;
                  child.hierarchyLevel = message.message.hierarchyLevel;

                  if (child.RegionDistancer) {
                    child.RegionDistancer.regionid = child.id;
                    this.data.createOrUpdateRegionDistancer(child);
                  }
                }
              })
            }
          })
        }
        else {
          this.events.updateParentRegion.emit(parent);
        }

      }
    });
  }



  assignRegionTypeId(): RegionTypes {
    //this needs to create a new child dependent on level:
    // regiontype1 (international) => 2 continent
    // regiontype2 (continent) => 6 countrygroup
    // regiontype3 (country) => 6 regiongroup
    // regiontype4 (region) => 5 locality
    // regiontype5 (locality)=> stop
    // regiontype6 (group) => depends on group's parent
    let newregiontypeid;
    switch (this.region.regiontypeid) {
      case RegionTypes.International: return RegionTypes.Continental;
      case RegionTypes.Continental:
      case RegionTypes.National: return RegionTypes.Group
      case RegionTypes.Regional: return RegionTypes.Local;
      case RegionTypes.Local: return RegionTypes.Local;
      case RegionTypes.Group:
        //if Country group return RegionTypes.Regional
        if (this.region.countryid) {//theoretically (!) must be a country group
          return RegionTypes.Regional;
        }
        //if Continental group return RegionTypes.National
        else return RegionTypes.National;
    }
    return newregiontypeid;
  }



  /**
   * update the name of the region.
   * @param region 
   */
  updateRegion(region: Region) {

    this.data.updateRegion(region).subscribe(() => {
      this.editable = false;
    })
  }
  /** mark a region for deletion */
  deleteRegion(region) {
    let undeletedChildren = 0;
    if (region.children) {
      region.children.some((r: Region) => {
        if (r.recordStatus < 3) {
          undeletedChildren++;
          return true;
        }
      })
    }

    if (undeletedChildren > 0) {
      this.tools.snackMessage("This region has children - please delete those first");
      return;
    }
    region.recordStatus = RecordStatus.Deleted;
    this.data.updateRegion(region).subscribe(() => {
      //trigger recalc of parent regions children
      this.events.deleteRegion.emit(region);
    })
  }
  /**add a region group for each continent */
  addContinents() {

    //need to allow zones so this is still relevent;

    let conts = this.data.continents.length;

    if (this.region.children && this.region.children.length > 0) {
      this.createCustomCountryGroups(1, this.region.children.filter(c => c.recordStatus < 3).length);
    }
    else {
      const dialogRef = this.dialog.open(BasicGrouperComponent, {
        width: 'auto',
        data: {
          region: this.region,
        }
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result || result==0) {
          if (result == 0) {
            this.createContinentalRegions();
          }
          else {
            this.createCustomCountryGroups(result);
          }
        }
      });
    }

  }
  /**
   * create regions based on the continents. Nothing fancy.
   */
  createContinentalRegions() {
    this.data.continents.forEach((cont: Region) => {
      let region = new Region(this.region.companyid, ZoneType.Continental);
      region.name = cont.name;
      region.googlename = cont.name;
      region.parentId = this.region.id;
      region.continentid = cont.id;
      region.gpscentreid = cont.gpscentreid;
      region.featureid = cont.featureid;
      region.regiontypeid = this.region.regiontypeid + 1;
      region.zoom = cont.zoom;
      region.basesiteid = this.region.basesiteid;
      if (!this.region.children) this.region.children = [];
      let todo = this.data.continents.length;
      let done = 0;
      let present = false;
      this.region.children.some(ch => {
        if (ch.continentid == region.continentid) {
          present = true;
        }
      })
      if (present) {
        done++;
        if (done == todo) {
          this.events.createRegion.emit(this.region);
        }
      }
      else {
        this.data.createRegion(region).subscribe((message: StatusMessage) => {
          done++;
          if (message.success) {
            region = message.message;
            region.GPS = cont.GPS;
            region.Feature = cont.Feature;
            this.region.children.push(region);
            this.service.tidyUpAfterAddRegion(this.region, true);
          }
          else {
            this.tools.gracefulError(message.message);
          }

        }, err => {
          this.tools.gracefulError(err);
        })
      }


    })
  }

  /**
   * open the dialog to decide how countries within a continent should be grouped. Group options are set by admin in /tools page
   */
  addCountryGroups() {
    if (this.region.children && this.region.children.length > 0) {
      let exists = this.region.children.filter(c => c.recordStatus < 0);
      if (exists.length > 0) {
        this.createCustomCountryGroups(1, exists.length);
      }
      else this.openContinentalGrouper();

    }
    else {
      this.openContinentalGrouper();
    }

  }
  openContinentalGrouper() {
    const dialogRef = this.dialog.open(ContinentalGrouperComponent, {
      width: 'auto',
      data: { Region: this.region }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        switch (result.which.id) {
          case -2://custom
            this.createCustomCountryGroups(result.bands);
            break;
          case -1://distance
            break;
          default:
            this.createRegionsFromGroup(result.which, RegionTypes.Continental);
        }
      }
    });
  }

  groupCountryRegions() {
    if (this.region.children && this.region.children.length > 0) {
      let exists = this.region.children.filter(c => c.recordStatus == 0);
      if (exists.length > 0) {
        let zt = exists[0].zonetype;
        let bits = exists[0].name.split(' ');
        let currentname:string;
        if (bits.length > 1) {
          currentname = "";
          for (let x = 0; x < bits.length-1; x++) {
            currentname+=bits[x]+" ";
          }
          currentname = currentname.trim();
        }

        this.createCustomRegionGroups(1, zt,currentname);
      }
      else {
        this.addRegions();
      }
    }
    else this.addRegions();

  }

  openCountryGrouper() {
    const dialogRef = this.dialog.open(CountryGrouperComponent, {
      width: 'auto',
      data: { Region: this.region }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        switch (result.which.id) {
          case -2://custom
            this.createCustomCountryGroups(result.bands);
            break;
          case -1://distance
            console.log("TODO generate distance from port");
            break;
          default:
            this.createRegionsFromGroup(result.which, RegionTypes.Continental);
        }
      }
    });
  }

  addIncodes() {
    this.service.addIncodesToOutcode(this.region).then((region: Region) => {
      this.region = region;
    })
  }
  split() {
    this.service.splitRegion(this.region).then((region: Region) => {
      this.region = region;
    })
  }

  /**
 * opens the region grouper dialog to ask how the user wishes to split the child regions into bands
 */
  addRegions() {
    const dialogRef = this.dialog.open(RegiongrouperComponent, {
      width: 'auto',
      data: { Region: this.region }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let feedback = result;
        console.log(feedback);
        if (feedback.mapper) {
          this.openAddFromMap();
        }
        else if (feedback.bands && !feedback.groups) {
          if (feedback.custom) {
            this.createCustomRegionGroups(feedback.bands, feedback.zonetype, feedback.overridename);
          }
          else if (feedback.distance) {
            this.createCustomRegionGroups(feedback.bands, ZoneType.NationalDistanceGroup, feedback.overridename);
          }
          else {
            this.createCustomRegionGroups(feedback.bands, feedback.zonetype, feedback.overridename);
          }

        }
        else {
          if (feedback.id) {
            if (feedback.id == -1) {
              //distance from site
              this.bandRegionsOnDistance(feedback.bands);
            }
            else if (feedback.id == -2) {
              //distance from location
              const dialogRef = this.dialog.open(LocationpickerComponent, {
                width: 'auto',
                data: { GPS: this.region.GPS, zoom: 4 }
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                  this.bandRegionsOnDistance(feedback.bands, result.GPS);
                }
              });
            }
            else {
              let selectedGroupType: RegionGroupType = feedback.groups.filter(g => g.id == feedback.id)[0];
              this.createRegionsFromGroup(selectedGroupType, RegionTypes.National);
            }
          }
          else {
            this.bandDrivingDistance(feedback.bands);
          }
        }

      }
    });



    /*
    this.data.listRegionsByCountry(this.region.countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let regions: Region[] = message.message;
        if (regions) {
          
        }

        else {
          this.tools.snackMessage("Sorry no more detailed information currently available");
          
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })*/


  }

  /**
   * take a region group and use its group children to create regions
   * @param group RegionGroupType, the grouptype selected from dialog options as preset by admin in the tools page
   */
  createRegionsFromGroup(group: RegionGroupType, regiontype: RegionTypes) {
    this.events.startCreateRegion.emit();
    //let zone = this.data.getZoneFromRegionGroup(group, regiontype);
    let usepostcode = this.data.countries.filter(c => c.id == this.region.countryid)[0].use_postcode == "yes";
    this.data.createRegionsFromRegionGroup(group, this.region, usepostcode).then((message: StatusMessage) => {
      if (message.success) {

        this.createChildrenAndGrandchildren(true).then((message: StatusMessage) => {
          this.events.createRegion.emit(this.region);
        });

      }
      else {
        this.tools.gracefulError(message.message);
      }

    }, err => {
      this.tools.gracefulError(err);
    })
  }


  /**async add children and grandchildren to the database post band creation */
  createChildrenAndGrandchildren(passFeaturesToParent?: boolean) {
    return new Promise((resolve) => {
      let allrequired = this.region.children.length;
      let alldone = 0;
      if (this.region.children.length == 0) {
        resolve({ success: true });
      }
      else {
        this.region.children.forEach(ch => {
          allrequired += ch.children.length;
        })

        this.region.children.forEach((child: Region) => {


          let childrequired = child.children.length;
          let childdone = 0
          this.data.createRegion(child).subscribe((message1: StatusMessage) => {
            if (message1.success) {
              child.hierarchyLevel = message1.message.hierarchyLevel;
              child.id = message1.message.id;
              if (child.children.length == 0) alldone++;
              if (alldone == allrequired) {
                resolve({ success: true });
              }
              child.children.forEach(grand => {
                grand.parentId = child.id;
                this.data.createRegion(grand).subscribe((message2: StatusMessage) => {

                  if (message2.success) {
                    grand.hierarchyLevel = message2.message.hierarchyLevel;
                    alldone++;
                    childdone++;
                    grand.id = message2.message.id;
                    if (childdone == childrequired) {
                      if (passFeaturesToParent) {

                        //done creating postcodes (for example) each with their own feature. create a new feature on the parent and add all of the children's feature polygons
                        this.data.passFeaturePolygonsToParent(child.id).subscribe((message: StatusMessage) => {
                          if (message.success) {
                            alldone++;
                            child.Feature = message.message.Feature;
                            child.featureid = message.message.featureid;
                            if (alldone == allrequired) {
                              resolve({ success: true });
                            }

                          }
                          else {
                            alldone++;
                            if (alldone == allrequired) {

                              this.tools.snackMessage("Regions added with errors");
                              resolve({ success: true });
                            }
                            this.tools.gracefulError(message.message);
                          }
                        }, err => {
                          alldone++;
                          if (alldone == allrequired) {
                            resolve({ success: true });
                            this.tools.snackMessage("Regions added with errors");
                          }
                          this.tools.gracefulError(err);
                        })
                      }
                      else {
                        childdone++;
                        alldone++;
                        if (alldone == allrequired) {
                          resolve({ success: true });
                          this.tools.snackMessage("Regions Added");
                        }
                      }

                    }
                  }
                  else {
                    childdone++;
                    alldone++;
                    this.tools.gracefulError(message2.message);
                    if (alldone == allrequired) {
                      resolve({ success: false });
                    }

                  }
                }, err2 => {
                  childdone++;
                  alldone++;
                  if (alldone == allrequired) {
                    resolve({ success: false })
                  }
                  this.tools.gracefulError(err2);
                })
              })
            }
            else {
              alldone++;
              alldone += child.children.length;
              if (alldone == allrequired) {
                resolve({ success: false });
                this.tools.gracefulError(message1.message);
              }

            }
          }, err1 => {
            alldone++;
            alldone += child.children.length;
            if (alldone == allrequired) {
              resolve({ success: false });
              this.tools.gracefulError(err1);
            }
          })
        })
      }

    })

  }

  bandCountriesOnDistance(bands: number, altgps?: GPS) {

    this.data.listCountriesByContinentPolys(this.region.continentid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let countries: Region[] = message.message;
        let gps: google.maps.LatLng;
        if (altgps) {
          gps = new google.maps.LatLng(altgps.lat, altgps.long);
        }
        else {
          let address = this.data.auth.user.Site.Address;
          if (address.gpslat && address.gpslong) {
            gps = new google.maps.LatLng(address.gpslat, address.gpslong);

          }
          else {
            this.tools.gracefulError("Please set your site's GPS via Settings");
          }
        }
        if (gps) {
          this.bandRegions(countries, bands, gps);
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  bandRegionsOnDistance(bands: number, altgps?: GPS) {
    this.events.startCreateRegion.emit();
    this.data.listRegionsByCountryPolys(this.region.countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let regions: Region[] = message.message;
        if (regions.length > 0) {
          let gps: google.maps.LatLng;
          if (altgps) {
            gps = new google.maps.LatLng(altgps.lat, altgps.long);
          }
          else {
            let address = this.data.auth.user.Site.Address;
            if (address.gpslat && address.gpslong) {
              gps = new google.maps.LatLng(address.gpslat, address.gpslong);

            }
            else {
              this.tools.gracefulError("Please set your site's GPS via Settings");
            }
          }
          if (gps) {
            this.bandRegions(regions, bands, gps);
          }
        }
        else {
          this.tools.snackMessage(`No more detailed regional information present, ${this.region.name} can have driving distance charging applied based on your selected site`);
          this.events.cancelLoading.emit();
        }

      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  createCustomCountryGroups(bands: number, index?: number) {
    if (!index) index = 0;
    let todo = bands;
    let done = 0;
    if (!this.region.children) this.region.children = [];
    this.events.startSpinner(bands);
    for (let i = 1; i <= bands; i++) {
      //TODO the tidy up stuff doesn't need to happen after each region creation, so it at the end of the process;
      let region = this.basicChild(this.region, ZoneType.CustomCountryGroup);
      region.name = "Zone " + (index + i);
      region.regiontypeid = RegionTypes.Group;
      region.sequence = (index + i);
      this.data.createRegion(region).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.region.children.push(message.message);
        }
        else {
          this.tools.gracefulError(message.message);
        }
        done++;
        this.service.tidyUpAfterAddRegion(this.region, true);
        if (done == todo) {
          this.events.stopSpinner();
          this.events.selectRegion.emit(this.region);
        }
        else {
          this.events.updateSpinner();
        }
      },
        err => {
          this.tools.gracefulError(err);
          done++;
          this.service.tidyUpAfterAddRegion(this.region, true);
          if (done == todo) {
            this.events.stopSpinner();
          }
        })
    }
  }
  createCustomRegionGroups(bands: number, zonetype: ZoneType, overridename: string) {
    if (!zonetype) zonetype = ZoneType.NationalCustomGroup;
    let todo = bands;
    let done = 0;

    if (!this.region.children) this.region.children = [];
    let groupnumber = this.region.children.length + 1;

    this.events.startSpinner(bands);
    for (let i = 0; i < bands; i++) {
      let region = this.basicChild(this.region, zonetype);
      region.sequence = i+1;
      let name: string;
      switch (zonetype) {
        case (ZoneType.TerritoryGroup):
          name = "Territory";
          break;
        case (ZoneType.NationalRegionGroup):
          name = "Region";
          break;
        case (ZoneType.NationalOutcodeGroup):
          name = "Outcode";
          break;
        default:
          name = "Region";
      }
      name = zonetype == ZoneType.TerritoryGroup ? 'Territory ' : 'Region ';
      region.name = name + "Group " + (groupnumber + i);
      if (overridename) {
        region.name = overridename + " " + (groupnumber + i).toString();
      }
      region.regiontypeid = RegionTypes.Group;
      region.basesiteid = this.region.basesiteid;
      region.gpscentreid = this.region.gpscentreid;
      this.data.createRegion(region).subscribe((message: StatusMessage) => {
        this.events.updateSpinner();
        if (message.success) {
          this.region.children.push(message.message);
        }
        else {
          this.tools.gracefulError(message.message);
        }
        done++;
        this.service.tidyUpAfterAddRegion(this.region, true);
        if (done == todo) {
          this.events.createRegion.emit(region);
          this.events.stopSpinner();
          this.events.selectRegion.emit(this.region);
        }
      },
        err => {
          this.tools.gracefulError(err);
          done++;
          if (done == todo) {
            this.events.stopSpinner();
            this.events.createRegion.emit(region);
            this.events.selectRegion.emit(this.region);
          }
        })
    }
  }

  createCustomCountyGroups(bands: number) {

  }
  createCustomCountryDistanceGroups(bands: number) {

  }

  basicChild(parent: Region, zonetype: ZoneType) {
    let region = new Region(parent.companyid, zonetype);
    region.basesiteid = parent.basesiteid;
    region.continentid = parent.continentid;
    region.countryid = parent.countryid;
    region.parentId = parent.id;
    return region;
  }

  bandRegions(regions: Region[], bands: number, home: google.maps.LatLng) {
    this.events.startCreateRegion.emit();
    let maxdistance = 0;
    let mindistance = Infinity;

    regions.forEach((region: Region) => {
      if (region.GPS) {
        let reggps = new google.maps.LatLng(parseFloat(region.GPS.lat.toString()), parseFloat(region.GPS.long.toString()));
        let distance = this.geo.haversineDifference(home, reggps);
        if (distance > maxdistance) maxdistance = distance;
        if (distance < mindistance) mindistance = distance;
        region._distance = distance;
      }
      else console.log("Missing Country GPS", region);


    })
    let sorted = regions.sort((a, b) => {
      if (a._distance < b._distance) return -1;
      else return 1;
    })
    let each = Math.floor(sorted.length / bands);
    let eachcount = 0;
    let total = 0;

    let bandedRegions: Region[] = [];
    let newreg: Region;

    let index = 1;
    sorted.forEach(r => {

      if (eachcount == 0) {
        newreg = new Region(this.region.companyid, ZoneType.DistanceCountryGroup);
        newreg.children = [];
        newreg.parentId = this.region.id;
        newreg.regiontypeid = RegionTypes.Group;
        newreg.hierarchyLevel = this.region.hierarchyLevel + 1;
        newreg.basesiteid = this.region.basesiteid;
        //padd with leading zeroes for sorting
        let sorter = "0" + index.toString();
        index++;
        sorter = sorter.substr(sorter.length - 2);
        newreg.code = sorter;
        newreg.name = "Distance " + Math.round(r._distance).toString() + " to ";
      }
      let copy = new Region(r.companyid, ZoneType.National);
      copy.zoom = r.zoom;
      copy.countryid = r.countryid;
      copy.continentid = r.continentid;
      copy.gpscentreid = r.gpscentreid;
      copy.featureid = r.featureid;
      copy.name = r.name;
      copy.googlename = r.googlename;
      copy.hierarchyLevel = newreg.hierarchyLevel + 1;
      copy.regiontypeid = RegionTypes.National;
      copy.basesiteid = r.basesiteid;
      newreg.children.push(copy);

      if (eachcount == each || total == sorted.length - 1) {
        newreg.name += Math.round(r._distance).toString() + " (km)";
        bandedRegions.push(newreg);
        eachcount = -1;
      }
      total++;
      eachcount++;
    })

    this.region.children = bandedRegions;
    this.createChildrenAndGrandchildren(true).then((message: StatusMessage) => {
      if (message.success) this.service.tidyUpAfterAddRegion(this.region, true);
    });

  }
  /**
   * sorts the regions by distance and creates the necessary bands
   * @param regions regions with the distance from origin set
   */
  createBands(regions: Region[], bands: number, dontwritetodb?: boolean) {
    let sorted = regions.sort((a, b) => {
      if (a._distance < b._distance) return -1;
      else return 1;
    })
    let each = Math.floor(sorted.length / bands);
    let eachcount = 0;
    let total = 0;

    let bandedRegions: Region[] = [];
    let newreg: Region;

    let index = 1;
    sorted.forEach(r => {

      if (eachcount == 0) {
        newreg = new Region(this.region.companyid, ZoneType.NationalDistanceGroup);
        newreg.children = [];
        newreg.countryid = this.region.countryid;
        newreg.continentid = this.region.continentid;
        newreg.drivingdistance = true;
        newreg.parentId = this.region.id;
        newreg.basesiteid = this.region.basesiteid;
        newreg.regiontypeid = RegionTypes.Group;
        //padd with leading zeroes for sorting
        let sorter = "0" + index.toString();
        sorter = sorter.substr(sorter.length - 2);
        newreg.code = sorter;
        newreg.name = "Distance " + Math.round(r._distance / 1000).toString() + " to ";
        index++;
      }
      let copy = new Region(r.companyid, ZoneType.NationalRegion);
      copy.zoom = r.zoom;
      copy.countryid = r.countryid;
      copy.continentid = r.continentid;
      copy.gpscentreid = r.gpscentreid;
      copy.featureid = r.featureid;
      copy.name = r.name;
      copy.regiontypeid = r.regiontypeid;
      copy.basesiteid = r.basesiteid;

      newreg.children.push(copy);

      if (eachcount == each || total == sorted.length - 1) {
        newreg.name += Math.round(r._distance / 1000).toString() + " (km)";
        bandedRegions.push(newreg);
        eachcount = -1;
      }
      total++;
      eachcount++;
    })

    this.region.children = bandedRegions;
    if (!dontwritetodb) this.createChildrenAndGrandchildren(true).then((message: StatusMessage) => {
      if (message.success) this.service.tidyUpAfterAddRegion(this.region, true);
    });
  }
  /**
   * gets the base region groupings and calculates the driving distance from the users site address
   * @param bands the number of bands the regions should be split into
   */
  bandDrivingDistance(bands: number) {
    this.events.startCreateRegion.emit();
    this.data.getCountryRegionAndChildren(this.region.countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let base: Region = message.message;
        let siteAddress = this.data.auth.user.Site.Address;
        if (siteAddress.gpslat && siteAddress.gpslong) {
          let origin = new google.maps.LatLng(siteAddress.gpslat, siteAddress.gpslong);
          let destinations: google.maps.LatLng[] = [];
          if (base.children && base.children.length) {
            base.children.forEach(ch => {
              destinations.push(new google.maps.LatLng(ch.GPS.lat, ch.GPS.long));
            })
            this.geo.drivingDistancesBetweenRegions([origin], destinations, environment.fakeDriving ? environment.fakeDriving : false).then((message: StatusMessage) => {
              if (message.success) {
                let resultindex = 0;
                let matrix: GoogleDistanceMatrix = message.message;
                base.children.forEach(ch => {
                  if (matrix.rows[0].elements[resultindex].status == "OK") {
                    ch._distance = matrix.rows[0].elements[resultindex].distance.value;
                  }
                  else {
                    console.log("Failed to get distance", ch);
                  }
                  resultindex++;
                })
                this.createBands(base.children, bands);
              }
              else this.tools.gracefulError("distance calculation error - terminating process");
            })
          }
          else {
            this.tools.gracefulError("No further region detail - please contact support if this is required");
          }
        }
        else {
          this.tools.gracefulError("Please set your site gps location via the map in company settings");
        }
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  /**
   * open the location picker dialog and subscribe to its mapclick method to add regions
   * scale specific, so continents will add countries, countries states etc..
   * deprecated, now on main map click
   */
  openAddFromMap() {
    this.region._folderOpen = true;
    this.service.selectedRegion = this.region;
    this.service.clearMap();
    this.tools.snackMessage("Click the map to add a region");
    return;
    /*
        let showpostcode = false;
        if (this.region.regiontypeid > RegionTypes.Continental) {
          showpostcode = true;
        }
        let gps = this.region.GPS;
        let pin: GPS;
    
        if (this.region.basesiteid && !this.region.BaseSite) {
          if (this.data.selectedSupplier && this.data.selectedSupplier._baseSite && this.data.selectedSupplier._baseSite.id == this.region.basesiteid) {
            this.region.BaseSite = this.data.selectedSupplier._baseSite;
          }
        }
        if (this.region.BaseSite && this.region.BaseSite.Address) {
          let address = this.region.BaseSite.Address;
          if (this.region.countryid == address.countryid) {
            pin = new GPS();
            pin.lat = address.gpslat;
            pin.long = address.gpslong;
          }
        }
    
    
    
        const dialogRef = this.dialog.open(LocationpickerComponent, {
          width: 'auto',
          position: { right: "24px" },
          data: { GPS: gps, zoom: this.tools.setZoomByZoneType(this.region.zonetype), dontCloseOnClick: true, mapClass: 'map-auto', searchOff: true, showpostcode: showpostcode, pin: pin }
        });
        dialogRef.componentInstance.mapclick.subscribe((result: MapSelect) => {
          //this.addFromPlace(result);
        })*/
  }

  /**
   * take a google place from the location picker and add the selected region dependent on level
   * open the region folder so the addition is visible
   * @param place 
   * @param usepostcode 
   */
  /*
  xaddChildFromGooglePlace(place: google.maps.places.PlaceResult, usepostcode: boolean) {
    let address = this.tools.addressFromGooglePlace([place], this.data.countries);
    this.region._folderOpen = true;
    switch (this.region.regiontypeid) {
      case RegionTypes.International:
        this.createContinent(address.Country.continentid);
        break;
      case RegionTypes.Continental:
        this.addCountryRegion(address);
        break;
      case RegionTypes.National:
        this.addRegionRegion(address, usepostcode);
        break;
      case RegionTypes.Regional:
        this.addLocalRegion(address, usepostcode);
        break;

    }
    console.log(address);

  }*/













  /**
   * at this level we take the base region's localities for this region, not anticipated that this should need to be more
   * configurable as driving distance would be applied should it need to be more granular
   */
  addLocalities() {
    this.data.getBaseRegionByName(RegionTypes.Regional, this.region.name, this.region.id).subscribe((message: StatusMessage) => {
      if (message.success) {
        let region = message.message;
        if (region.children && region.children.length > 0) {
          region.children.forEach(r => {
            let locality = new Region(this.region.companyid, ZoneType.NationalCounty);
            locality.basesiteid = this.region.basesiteid;
            locality.continentid = r.continentid;
            locality.countryid = r.countryid;
            locality.featureid = r.featureid;
            locality.gpscentreid = r.gpscentreid;
            locality.name = r.name;
            locality.googlename = r.googlename;
            locality.parentId = this.region.id;
            locality.regiontypeid = r.regiontypeid;
            locality.zoom = r.zoom;
            if (!this.region.children) this.region.children = [];
            this.data.createRegion(locality).subscribe((message: StatusMessage) => {
              if (message.success) {
                let newlocal = message.message;
                newlocal.GPS = r.GPS;
                newlocal.Feature = r.Feature;
                this.region.children.push(newlocal);
              }
              else {
                this.tools.gracefulError(message.message);
              }

            }, err => {
              this.tools.gracefulError(err);
            })
          })
        }
        else {
          console.log("TODO: handle base regions as they do not exist on this parent region")
          this.tools.snackMessage("Sorry, no further subdivisions possible, let support know if these are required.");
        }
      }
      else {
        this.tools.gracefulError(message.message);
      }

    }, err => {
      this.tools.gracefulError(err);
    })
  }

  addBasic() {
    this.tools.gracefulError("TODO....Not Implemented");
  }

  /**
   * lets the regions component know a drag event has happened
   * @param e 
   */
  drop(e) {
    //console.log(e);
    this.events.dropRegion.emit(e);
  }





  /*
          this.tools.confirm(`No more detailed regional information present for ${this.region.name} would you like to select a location to calculate driving distance from?`, true, false).then(result => {
            if (result == ConfirmOption.YES) {
              const dialogRef = this.dialog.open(LocationpickerComponent, {
                width: 'auto',
                data: { GPS: this.region.GPS, zoom: 4 }
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {

                  this.data.createGPS(result).subscribe((message: StatusMessage) => {
                    if (message.success) {
                      this.region.gpscentreid = message.message.id;
                      this.region.GPS = message.message;
                      this.data.updateRegion(this.region).subscribe((message: StatusMessage) => {
                        if (message.success) {
                          this.tools.snackMessage("New Location saved");
                        }
                        else {
                          this.tools.gracefulError(message.message);
                        }
                      }, err => {
                        this.tools.gracefulError(err);
                      })

                    }
                    else {
                      this.tools.gracefulError(message.message);
                    }
                  }, err => {
                    this.tools.gracefulError(err);
                  })
                }
              });
            }
          });*/

}
