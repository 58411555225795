<div *ngIf="rate">
    <h3 title="{{rate.id}}">{{rate.name}}</h3>
    <div class="fl">

        <div class="fl aic">
            <div class="field-title">Minimum Charge</div>
            <div class="field-value">{{rate.baseprice | currency:currencyCode}}</div>
            <div class="field-title">Which includes</div>
            <div class="field-value">{{rate.baseIncludesWeight | number}} kg</div>
            <div *ngIf="rate.baseIncludesVolume && rate.baseIncludesVolume>0" class="fl">
                <div class="field-title">and </div>
                <div class="field-value super">{{rate.baseIncludesVolume | number}} m<sup>3</sup></div>
            </div>
        </div>
        <div class="fl aic">
            <div class="field-title">Pricing per</div>
            <div class="field-value">{{rate.priceperbreak?'band':'kg'}}</div>

        </div>
        <div class="fl aic" *ngIf="rate.volumeequivalent">
            <div class="field-title">Volume equivalent</div>
            <div class="field-value">{{rate.volumeequivalent | number}}</div>
        </div>
        <mat-icon (click)="more=!more">{{more?'chevron_left':'chevron_right'}}</mat-icon>
        <div class="fl aic" *ngIf="more">
            <div class="field-title">Start Date</div>
            <div class="field-value">{{rate.createdAt | date:'dd-MM-yyyy'}}</div>
            <div class="field-title">Expiry Date</div>
            <div class="field-value">{{rate.expires | date:'dd-MM-yyyy'}}</div>
            <div class="field-title">Pricing applies</div>
            <div class="field-value">
                {{rate.breakbarrierincludes?'up to and including next value':'up to but not including next value'}}
            </div>
        </div>
    </div>
    <div class="fl f1 aic">
        <div *ngFor="let br of rate.RateBreaks" class="fl break">
            <div class="field-title">then <span *ngIf="!br.priceperbreak">for each</span></div>
            <div class="field-value">{{br.priceperbreak?'':br.weightbreak}} {{br.priceperbreak?'':'kg'}}</div>
            <div class="fl" *ngIf="br.maxweight && br.maxweight>0">
                <div class="field-title">up to</div>
                <div class="field-value">{{br.maxweight}} kg</div>
            </div>

            <div class="field-title">price </div>
            <div class="field-value">{{br.price | currency:currencyCode}}</div>
        </div>
    </div>
</div>