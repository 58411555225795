import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cs-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {

  
  view: any[] = [500, 300];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Account Manager';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;
  legendTitle:'Quote Success';

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };
  
  constructor() { }

  public data = [
    {
      "name": "Ethan",
      "series": [
        {
          "name": "Converted",
          "value": 310
        },
        {
          "name": "Lost",
          "value": 190
        }
      ]
    },
  
    {
      "name": "Jolyon",
      "series": [
        {
          "name": "Converted",
          "value": 290
        },
        {
          "name": "Lost",
          "value": 200
        }
      ]
    }
    ,
  
    {
      "name": "Becca",
      "series": [
        {
          "name": "Converted",
          "value": 340
        },
        {
          "name": "Lost",
          "value": 220
        }
      ]
    }
  ]

  ngOnInit(): void {
  }
    
  onSelect(e){

  }
}
