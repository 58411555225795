<div class="fl fw">
    <div *ngIf="!auth.sideon && auth.logo.length>0" class="logo pointer" routerLink="/" routerLinkActive="active"><img alt="white label logo"
            src="{{auth.logo}}" title="White Label Logo"></div>
    <div *ngIf="!auth.sideon && auth.logo.length==0" class="textlogo pointer" routerLink="/" routerLinkActive="active">
        <h1>{{auth.sitename}}</h1>
    </div>
    <div *ngIf="auth.sideon" class="page-title"><span>{{auth.currentroute}}</span></div>
    <div class="f1"></div>
    <div class="nav">
        <button mat-mini-fab aria-label="Deleter" class="quiet" (click)="killData()" *ngIf="auth.demo">
            <mat-icon>content_cut</mat-icon>
        </button>
        <div id="routeicons" *ngIf="auth.user && !auth.sideon" class="fl">
            <div *ngFor="let mod of auth.user.ModuleUsers">
                <button *ngIf="mod.view" mat-mini-fab [attr.aria-label]="mod.Module.name"
                    [color]="mod.Module.route==route?'accent':'primary'" title="/{{mod.Module.route}}"
                    [ngClass]="{'active':route==mod.Module.route}">
                    <mat-icon [routerLink]="[mod.Module.route]" routerLinkActive="active">{{mod.Module.icon}}</mat-icon>
                </button>
            </div>
        </div>
        <button mat-mini-fab aria-label="Profile" color="primary" (click)="openLogin()" title="{{usersummary}}">
            <mat-icon *ngIf="!auth.user || auth.user.id==0" class="header-profile-pic">person</mat-icon><span
                *ngIf="auth.user && auth.user.id>0"
                class="header-profile-name">{{auth.user.firstname?auth.user.firstname.substring(0,1):auth.user.email.substring(0,1)}}</span>
        </button>
        <!--<button mat-mini-fab aria-label="Setup" color="primary" (click)="setup()">
            <mat-icon>fingerprint</mat-icon>
        </button>-->
    </div>
</div>
<div *ngIf="events.spinneron" class="spinner">
    <div class="spinner-stuff">
        <mat-spinner></mat-spinner>
        <div class="spinner-info" *ngIf="events.updater">
            <span>{{events.done}}</span>/<span>{{events.todo}}</span>
        </div>
    </div>
</div>