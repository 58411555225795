<div class="fl fw">
    <div class="pad">
        <cs-address-short [address]="quote.quoteInput.origin" [title]="'Origin'"></cs-address-short>
    </div>
    <div class="pad">
        <cs-address-short [address]="quote.quoteInput.destination" [title]="'Destination'"></cs-address-short>
    </div>
    <div class="f1 fl fdc pad">
        <cs-quote-options-view [quoteOptions]="quote.quoteInput.quoteOptions">
        </cs-quote-options-view>
    </div>
    <div class="f1"></div>
    <div class="fl fwn aic pad">
        <div class="mr fl aic">
            <mat-icon class="traffic-green mr">eco</mat-icon>Greenest
        </div>
        <div class="mr fl aic">
            <mat-icon class="primary mr">attach_money</mat-icon>Cheapest
        </div>
        <div class="mr fl aic">
            <mat-icon class="accent mr">speed</mat-icon>Fastest
        </div>
        <div class="mr fl aic">
            <mat-icon class="primary mr">monetization_on</mat-icon>Cheapest Full Service
        </div>

    </div>
    
</div>
<div class="fl fw pad">
        <div class="item" *ngFor="let quoteitem of quote.quoteInput.quoteItems; let i = index;">
            <cs-size-view [size]="quoteitem" [customer]="true" [index]="i+1"></cs-size-view>
        </div>
    </div>
<div *ngIf="serviceoptions.length>0">
    <h3>Service Options</h3>
    <table class="mat-table">
        <tr class="mat-header-row">
            <th></th>
            <th>Service</th>
            <th>Provider</th>
            <th>Mode</th>
            <th>Origin Code</th>
            <th>Dest Code</th>
            <th>Transit Time</th>
            <th>Frequency</th>
            <th>Terms</th>
            <th>CO<sub>2</sub></th>
            <th>Price</th>
        </tr>
        <tr *ngFor="let option of serviceoptions;let i = index;"
            class="mat-row quote-option {{i==quote.selectedOption?'selected':''}}" (click)="selectOption(i)">
            <td>
                <mat-icon *ngIf="option.greenest" class="traffic-green">eco</mat-icon>
                <mat-icon *ngIf="option.cheapest" class="primary">attach_money</mat-icon>
                <mat-icon *ngIf="option.cheapestfull" class="primary">monetization_on</mat-icon>
                <mat-icon *ngIf="option.fastest" class="accent">speed</mat-icon>
            </td>
            <td title="{{option.service.id}}">{{option.service.description}}</td>
            <td class="tac">{{option.service.Provider?option.service.Provider.name:option.service.providerid}}</td>
            <td class="tac"><span class="tm tm{{option.mode}}"></span></td>
            <td class="tac">{{option.origin.locode1}}-{{option.origin.locode2}}</td>
            <td class="tac">{{option.destination.locode1}}-{{option.destination.locode2}}</td>
            <td class="tac">{{(option.metrics?option.metrics.duration:0) | duration}}</td>
            <td class="tac">{{option.service?option.service.frequency:''}}</td>
            <td class="tac">{{termdescription[option._terms]}}</td>
            <td class="tac">{{(option.metrics?option.metrics.co2:0) | number:'1.1-1'}} kg</td>
            <td class="tac">{{option.total.discountedprice | currency:'GBP'}}</td>
        </tr>
    </table>
</div>
<div *ngIf="point2pointoptions.length>0">
    <h3>Point to Point Options</h3>
    <table class="mat-table">
        <tr class="mat-header-row">
            <th></th>
            <th>Provider</th>
            <th>Mode</th>
            <th>Priority</th>
            <th>Transit Time</th>
            <th>Terms</th>
            <th>CO<sub>2</sub></th>
            <th>Price</th>
        </tr>
        <tr *ngFor="let option of point2pointoptions;let i = index">
            <td>
                <mat-icon *ngIf="option.greenest" class="traffic-green">eco</mat-icon>
                <mat-icon *ngIf="option.cheapest" class="primary">attach_money</mat-icon>
                <mat-icon *ngIf="option.cheapestfull" class="primary">monetization_on</mat-icon>
                <mat-icon *ngIf="option.fastest" class="accent">speed</mat-icon>
            </td>
            <td>{{option.provider.Provider.name}}</td>
            <td><span class="tm tm{{option.mode}}"></span></td>
            <td>{{(option.PointToPoints && option.PointToPoints.length>0)?(option.PointToPoints[0].Priority?option.PointToPoints[0].Priority.name:""  ):""}}</td>
            <td>{{(option.metrics?option.metrics.duration:0) | duration}}</td>
            <td>{{termdescription[0]}}</td>
            <td>{{(option.metrics?option.metrics.co2:0) | number:'1.1-1'}} kg</td>
            <td>{{option.total.discountedprice | currency:'GBP'}}</td>
        </tr>
    </table>
</div>