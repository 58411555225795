<div class="fl">
    <h3 class="f1">Select {{locationtype}} location</h3>
    <mat-icon *ngIf="!closeOnClick" (click)="cancel()" color="warn" class="pointer">highlight_off</mat-icon>
</div>
    
<p>Search, zoom and move the map to the required location.</p>
<div class="fl">
    <div class="f1"><p>Click the map to select.</p></div>
    <div *ngIf="showpostcode">
        <mat-checkbox [(ngModel)]="usepostcode">Attempt to use Post Code</mat-checkbox>
    </div>
</div>

<div class="pos-rel">
<input *ngIf="!searchOff" id="pac-input" class="controls" type="text" placeholder="start typing location...">
<div #map class="{{mapClass}}">

</div>
</div>