import { Component, OnInit } from '@angular/core';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { FormGroup, FormArray } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent implements OnInit {

  public quotationForm:FormGroup;

  public get surchargeArray():FormArray{
    return <FormArray> this.quotationForm.get("surchargeArray");
  }

  constructor(public formtools:FormtoolsService,public data:DataService) {
    data.auth.currentroute = "Quotations";
  }

  ngOnInit(): void {
    /*this.quotationForm = this.formtools.createQuotationForm();
    let sc1 = this.formtools.createQuotationSurchargeGroup();
    let sc2 = this.formtools.createQuotationSurchargeGroup();
    this.surchargeArray.push(sc1);
    this.surchargeArray.push(sc2);*/
  }
  save(){
    
    this.quotationForm.markAsPristine();
    console.log(this.quotationForm);
  }

}
