<div class="fl aic milestone {{selectedId==milestone.id?'milestone-selected':''}}" hover-class="show-add">
    <div class="pointer f1">
    <div class="f1 fl aic">
        <mat-icon (click)="milestone._folderOpen=!milestone._folderOpen" [ngClass]="['folder',milestone.children && milestone.children.length>0?'active':'']">{{milestone._folderOpen?'folder_open':'folder'}}</mat-icon>
        <div class="f1" (click)="selectMilestone(milestone)">{{!milestone.summary || milestone.summary.length==0?'empty':milestone.summary}}{{milestone.children && milestone.children.length>0?' - ('+milestone.children.length+')':''}}</div>  
        <mat-icon *ngIf="statusIcon>0" [ngClass]="[statusIcon==2?'completed':'overdue']">{{statusIcon==2?'check':'priority_high'}}</mat-icon>
    </div>
    </div>
    <div><mat-icon (click)="addChild()" class="add-child">add</mat-icon></div>
</div>


<div *ngIf="milestone.children && milestone._folderOpen" class="childstone">
<cs-milestone *ngFor="let child of milestone.children" id="csm-{{child.id}}" [milestone]="child" (select)="parentSelect(child)" [parentSelect]="parentSelect" [selectedId]="selectedId"></cs-milestone>
</div>
