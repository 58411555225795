import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Rate, RateBracket } from 'src/app/models/rates';
import { EventsService } from 'src/app/services/events.service';
import { DataService } from 'src/app/services/data.service';
import moment from 'moment';



@Component({
  selector: 'cs-rate-base-form',
  templateUrl: './rate-base-form.component.html',
  styleUrls: ['./rate-base-form.component.scss']
})
export class RateBaseFormComponent implements OnInit {

  @Input() rategroup:FormGroup;
  @Input() unzoned:boolean;
  @Input() editmode:boolean;
  @Input() index = 0;
  
  public rate:Rate;
  public applytoall = true;

  public currencyCode:string = "GBP"; //for formatting tables
  public selectedBracket:RateBracket;

  constructor(public events:EventsService,public data:DataService) { }

    
  ngOnInit(): void {
    if(!this.editmode){
      this.rate = this.rategroup.value;
    }
    if(this.unzoned) this.applytoall = false;
    this.events.globalRateParameters.subscribe((mainrate:Rate)=>{
      if(this.rategroup.get("regionid").value!=mainrate.regionid){
        this.rategroup.get("breakbarrierincludes").setValue(mainrate.breakbarrierincludes);
        this.rategroup.get("priceperbreak").setValue(mainrate.priceperbreak);
        this.rategroup.get("volumeequivalent").setValue(mainrate.volumeequivalent);
        this.rategroup.get("createdAt").setValue(mainrate.createdAt);
        this.rategroup.get("expires").setValue(mainrate.expires);
        this.setRateBreakGlobals();
      }     
    });
    this.setCurrency();
    this.setInitialBracket();
  }
  setCurrency(){
    let currencycountry = this.rategroup.value.currency;
    
    if(currencycountry>0){
      this.currencyCode = this.data.countries.filter(c=>c.id==currencycountry)[0].currencyCode;
    }
    else{
      this.currencyCode = this.data.defaultCurrency.currencyCode;
    }
  }
  setInitialBracket(){
    let rb = this.rategroup.get("ratebrackettype");
    if(rb.value){
      this.selectedBracket = this.data.ratebrackets.filter(r=>r.id==rb.value)[0];
    }
    else{
      this.selectedBracket = this.data.ratebrackets.filter(r=>r.name=="Pallet")[0];
      rb.setValue(this.selectedBracket.id);
    } 
  }
  startDateChange(){
    let expiry = this.rategroup.get("expires");
    let start = this.rategroup.get("createdAt");
    let holddate:moment.Moment = moment(start.value); //a moment
    expiry.setValue(holddate.add('M',3));
    this.propogateRateChange();
  }
  propogateRateChange(){
    if(this.index==0 && this.applytoall){
      this.events.globalRateParameters.emit(this.rategroup.value);
    }
    this.setRateBreakGlobals();
  }
  setRateBreakGlobals(){
    let rb = <FormArray> this.rategroup.get("RateBreaks");

    rb.controls.forEach(rb=>{
      rb.get("priceperbreak").setValue(this.rategroup.get("priceperbreak").value);
      rb.get("breakbarrierincludes").setValue(this.rategroup.get("breakbarrierincludes").value);
    })
  }
  setBracket(e){
    this.selectedBracket = this.data.ratebrackets.filter(rb=>rb.id==e.value)[0];
    let rb = this.rategroup.get("ratebrackettype");
    let name = this.rategroup.get("name");
    //hack needed - expression changed error, can't work out what's causing it but seems to be a bit of a bug in material.
    rb.setValue(this.selectedBracket.id);
    name.setValue(this.selectedBracket.name);
   
  }

}
