<!--<div class="cover" *ngIf="cover">
  <div class="centre" (click)="upApp()" title="freightology, masters of the science of logistics pricing">
</div>
</div>-->
<div class="f1 fl ovh">
  <div class="side-menu fl fdc" *ngIf="auth.sideon && auth.core.user"><cs-side-menu></cs-side-menu></div>
  <div class="f1 fl fdc h100r ovh"><!--ovh here needed for roadmap-->
    <div>
      <cs-header></cs-header>
    </div>
    <div class="f1 fl fdc ovh">
      <div class="f1 fl ovh">
        <div class="f1 fl ovh"><router-outlet></router-outlet></div>
      </div>
    </div>
    <div>
      <cs-footer></cs-footer>
    </div>
  </div>
</div>

