import { Component, OnInit, ViewChild } from '@angular/core';
import { View } from 'src/app/models/ui';
import { Region } from 'src/app/models/rates';
import { EventsService } from 'src/app/services/events.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'cs-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {

  @ViewChild('ratestab', { static: true }) tabs: MatTabGroup;

  public views: View[] = [{ id: 0, name: "National" }, { id: 1, name: "Continental" }, { id: 2, name: "International" }];//,{id:3,name:"Port To Port"}
  public selectedView: View = this.views[0];
  public bands: Region[];
  public showrates = false;
  public showcard = false;
  public preLoaded = false;

  constructor(public events: EventsService) {
    this.events.settingsTabChange.subscribe((index: number) => {
      if (index == 3) {
        if (!this.preLoaded) {

          this.showrates = true;
          this.tabs.realignInkBar();
          this.preLoaded = true;

        }
      }


    });
  }

  ngOnInit(): void {

  }

  viewChange(e) {
    this.selectedView = this.views[e.index];
  }

}
