<div class="page f1 fl fdc ovh">
    <div class="f1 fl ovh">
        <div class="f1 overflow-scroll">
            
            <div class="fl ma jc fw">
                <div class="f1"><cs-metric [description]="'Outstanding'" [change]="-2" value="10" [negativegood]="true"></cs-metric></div>
                <div class="f1"><cs-metric [description]="'Conversion Rate'" [change]="15" value="74%"></cs-metric></div>
                <div class="f1"><cs-metric [description]="'Margin'" [change]="1" value="12%"></cs-metric></div>
                <div class="f1"><cs-metric [description]="'Quote Time'" [change]="-91" value="3 mins" [negativegood]="true"></cs-metric></div>
            </div>

            <div class="f1 fl fdc">
                <div class="fl">
                    <div class="f1 fl jc widget"><cs-pie1 [data]="outstanding"></cs-pie1></div>
                    <div class="f1 fl jc widget"><cs-bar></cs-bar></div>
                </div>
                <div class="f1 fl jc widget">
                    <cs-conversion-rate *ngIf="pageinit" [data]="trend"></cs-conversion-rate>
                </div>
            </div>
            <div class="jc">
                 <div class="ma fl" *ngIf="exrates">
                    <div class="widget">
                        <cs-exchange-rates></cs-exchange-rates>
                    </div>
                </div>
                <div *ngIf="geog" class="fl jc ma">
                    <div class="widget">
                        <cs-geocoder></cs-geocoder>
                    </div>
                    <div class="widget">
                        <cs-global-distance></cs-global-distance>
                    </div>
                    <div class="widget">
                        <cs-distance></cs-distance>
                    </div>
                    <div class="widget">
                        <cs-port-search></cs-port-search>
                    </div>
                </div>
            </div>

        </div>
        <div class="fl fw fdc">
            <div class="mt"><button mat-mini-fab aria-label="exhange rates" color="{{exrates?'accent':'primary'}}"
                class="{{exrates?'active':''}}" title="exchange rates" (click)="exrates=!exrates">
                <mat-icon>monetization_on</mat-icon>
            </button></div>
        <div class="mt"><button mat-mini-fab aria-label="geography tools" color="{{geog?'accent':'primary'}}"
                class="{{geog?'active':''}}" title="geography tools" (click)="geog = !geog">
                <mat-icon>public</mat-icon>
            </button></div>
            <div class="f1"></div>
            <div class="mt"><button mat-mini-fab aria-label="global settings" color="primary" title="global settings"
                    (click)="showGlobal()">
                    <mat-icon>construction</mat-icon>
                </button></div>
            <div class="mt">
                <cs-objectifier [vertical]="true"></cs-objectifier>
            </div>


        </div>
    </div>
</div>