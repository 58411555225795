<h3>Rate Base</h3>
<div *ngIf="rate">
    <div class="fl">
        <div class="field-title">Start Date</div>
        <div class="field-value">{{rate.createdAt | date:'dd-MM-yyyy'}}</div>
        <div class="field-title">Expiry Date</div>
        <div class="field-value">{{rate.expires | date:'dd-MM-yyyy'}}</div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Minimum Charge</div>
        <div class="field-value">{{rate.baseprice | currency:currencyCode}}</div>
        <div class="field-title">Which includes</div>
        <div class="field-value">{{rate.baseIncludesWeight | number}} kg</div>
        <div class="field-title">and </div>
        <div class="field-value super">{{rate.baseIncludesVolume | number}} m<sup>3</sup></div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Pricing per</div>
        <div class="field-value">{{rate.priceperbreak?'band':'kg'}}</div>
        <div class="field-title">Pricing applies</div>
        <div class="field-value">
            {{rate.breakbarrierincludes?'up to and including next value':'up to but not including next value'}}</div>
    </div>
    <div class="fl aic" *ngIf="rate && !editmode">
        <div class="field-title">Volume equivalent</div>
        <div class="field-value">{{rate.volumeequivalent | number}}</div>
    </div>
</div>

<div *ngIf="rategroup && editmode" class="fl" [formGroup]="rategroup">
    <div>
        <div class="fl aib">
            <mat-form-field class="mr">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Item Bracket</mat-label>
                <mat-select formControlName="ratebrackettype" (selectionChange)="setBracket($event)">
                    <mat-option *ngFor="let rb of data.ratebrackets" [value]="rb.id">
                        {{rb.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="fl formfields">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="createdAt" (dateChange)="startDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Expiry Date</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="expires" (dateChange)="propogateRateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        

        <div class="fl formfields">
            <mat-form-field>
                <mat-label>Price per</mat-label>
                <mat-select formControlName="priceperbreak" (selectionChange)="propogateRateChange()">
                    <mat-option [value]="true">
                        price band
                    </mat-option>
                    <mat-option [value]="false">
                        kg
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Price applies</mat-label>
                <mat-select formControlName="breakbarrierincludes" (selectionChange)="propogateRateChange()">
                    <mat-option [value]="true">
                        up to and including next value
                    </mat-option>
                    <mat-option [value]="false">
                        up to but not including next value
                    </mat-option>  
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Currency</mat-label>
                <mat-select formControlName="currency" (selectionChange)="setCurrency()">
                    <mat-option *ngFor="let country of data.currencies" [value]="country.id">
                        {{country.currencySymbol}} <img class="flag"
                            src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      
        <div class="fl formfields">
            <mat-form-field class="">
                <mat-label>Volume Equivalent</mat-label>
                <input matInput placeholder="volume equivalent" type="number" formControlName="volumeequivalent" (change)="propogateRateChange()">
            </mat-form-field>
            <mat-checkbox *ngIf="index==0 && !unzoned" [(ngModel)]="applytoall" [ngModelOptions]="{standalone: true}" (change)="propogateRateChange()"> Apply non-pricing values to all zones</mat-checkbox>
        </div>
         <div class="fl formfields">
            <mat-form-field class="">
                <mat-label>Charge</mat-label>
                <input matInput placeholder="Charge" type="number" formControlName="baseprice">
            </mat-form-field>

            <mat-form-field class="form-skinnyish">
                <mat-label>Includes ({{rategroup.get('priceperbreak').value?'unit':'kg'}})</mat-label>
                <input matInput placeholder="Includes weight" type="number" formControlName="baseIncludesWeight">
            </mat-form-field>

            <mat-form-field class="form-skinnyish">
                <mat-label>Includes ({{rategroup.get('priceperbreak').value?'unit':'m3'}})</mat-label>
                <input matInput placeholder="Includes volume" type="number" formControlName="baseIncludesVolume">
            </mat-form-field>
            
            <mat-form-field class="form-skinny">
                <mat-label>Minimum</mat-label>
                <input matInput placeholder="Minimum" type="number" formControlName="minimum"> <!--csInputCurrency - causing expression changed errors-->
            </mat-form-field>
        </div> 
        <div class="f1"></div>


    </div>
</div>
