import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Company, CompanyAssociation, CompanyType, CompanyTypeAppliesTo, StatusMessage } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements AfterViewInit {

  @Input() hidetype: boolean = false;
  @Input() iscustomers = false;
  @Input() host: string;

  filteredCompanies: Observable<CompanyAssociation[]>;
  selectedCoTypeIds: number[] = [];
  coSearch = new FormControl();
  isPopped = false;
  public companytypes: CompanyType[] = []

  constructor(public data: DataService, public events: EventsService) {

  }

  ngAfterViewInit(): void {

    if (this.data.dataIsReady) {
      this.setup();
    }
    else this.data.dataReady.subscribe(() => {
      this.setup();
    })


  }
  setup() {
    setTimeout(() => {
      this.companytypes = this.data.companyTypes.filter(ct => ct.appliesto == (this.iscustomers ? CompanyTypeAppliesTo.Customer : CompanyTypeAppliesTo.Provider));
      this.selectedCoTypeIds = this.data.companyTypes.filter(ct => ct.appliesto == (this.iscustomers ? CompanyTypeAppliesTo.Customer : CompanyTypeAppliesTo.Provider)).map(ct => ct.id);
      let displayname;
      if (this.iscustomers) {
        if (this.data.selectedCustomer) {
          displayname = this.data.selectedCustomer.Customer.name;
        }
        else displayname = this.data.Company.name;
      }
      else {
        if (this.data.selectedSupplier) {
          displayname = this.data.selectedSupplier.Provider.name;
        }
        else {
          displayname = this.data.Company.name;
        }
      }

      this.setDisplayName(displayname);
    })

  }
  setDisplayName(name: string) {
    this.coSearch.setValue(name);
  }
  pop(e) {
    if (e.currentTarget) {
      this.coSearch.setValue("");
      if (!this.isPopped) {
        this.filteredCompanies = this.coSearch.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterCompanies(value))
          );
        this.isPopped = true;
      }
    }

  }


  filterCompanies(search: string | CompanyAssociation) {
    let prop = this.iscustomers ? 'Customer' : 'Provider';
    let res: CompanyAssociation[] = [];
    if (!search || search == "") {
      if (this.iscustomers) {
        res = this.data.customers.filter(c => this.selectedCoTypeIds.indexOf(c.Customer.companytypeid) >= 0);
      }
      else {
        res = this.data.providers.filter(c => this.selectedCoTypeIds.indexOf(c.Provider.companytypeid) >= 0);
      }
      return res;
    }
    else {
      if(this.iscustomers){
        let term: string = typeof search === "string" ? search.toLowerCase() : search.Customer.name.toLowerCase();
        res = this.data.customers.filter(c => c.Customer.name.toLowerCase().indexOf(term) >= 0 && this.selectedCoTypeIds.indexOf(c.Customer.companytypeid) >= 0);
      
      }
      else{
        let term: string = typeof search === "string" ? search.toLowerCase() : search.Provider.name.toLowerCase();
        res = this.data.providers.filter(c => c.Provider.name.toLowerCase().indexOf(term) >= 0 && this.selectedCoTypeIds.indexOf(c.Provider.companytypeid) >= 0);
      
      }
      return res;
    }

  }

  displayCustomerWith(coAssoc: CompanyAssociation) {
    if (!coAssoc) return "";
    if (typeof coAssoc === "string") return coAssoc;
    if (coAssoc && coAssoc.Customer) return coAssoc.Customer.name ? coAssoc.Customer.name : '';
    else return coAssoc && coAssoc.Provider.name ? coAssoc.Provider.name : '';
  }

  public selectAll = true;

  chooseType(e) {
    if (this.selectedCoTypeIds.length < this.data.companyTypes.filter(ct => ct.appliesto == (this.iscustomers ? CompanyTypeAppliesTo.Customer : CompanyTypeAppliesTo.Provider)).length) {
      this.selectAll = false;
    }
    else {
      this.selectAll = true;
    }
    this.coSearch.updateValueAndValidity({ emitEvent: true });
  }
  checkAll(e) {
    if (e.checked) {
      this.selectedCoTypeIds = this.data.companyTypes.filter(ct => ct.appliesto == (this.iscustomers ? CompanyTypeAppliesTo.Customer : CompanyTypeAppliesTo.Provider)).map(ct => ct.id);
    }
    else {
      this.selectAll = false;
      this.selectedCoTypeIds = [];
    }
    this.coSearch.updateValueAndValidity({ emitEvent: true });
  }
  chooseCo(e) {
    //TODO 
    if (this.iscustomers) {
      this.data.selectedCustomer = e.option.value.Customer;
      this.coSearch.setValue(e.option.value.Customer.name);
    }
    else this.coSearch.setValue(e.option.value.Provider.name);
    this.events.companyselect.emit(e.option.value);
  }

}
