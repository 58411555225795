import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreferredPorts, Quotation } from 'src/app/models/ui';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { FormGroup } from '@angular/forms';
import { element } from 'protractor';
import { CalculationsService } from 'src/app/services/calculations.service';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-quote-actions',
  templateUrl: './quote-actions.component.html',
  styleUrls: ['./quote-actions.component.scss']
})
export class QuoteActionsComponent implements OnInit {

  public index: number;
  public quote: Quotation;
  public coid: number;
  public userid:number;

  public emailform: FormGroup;
  public saveform: FormGroup;

  public email: string;
  public salutation: string;

  public autogenerate = false;

  constructor(public dialogRef: MatDialogRef<QuoteActionsComponent>, @Inject(MAT_DIALOG_DATA) public dialogdata: any, public formtools: FormtoolsService, public calc: CalculationsService, public data:DataService) {
    this.quote = dialogdata.quote;
    this.coid = dialogdata.coid;
    this.userid = dialogdata.userid;
  }

  public selectedOption;
  public exportformat = 1;

  ngOnInit(): void {
    this.emailform = this.formtools.createSimpleEmail();
    this.saveform = this.formtools.createSimpleSave();
  }
  quoteChoice(e) {
    if(e.value){
      this.selectedOption = e.value;
    }   
  }

  autoRef(){
    this.data.latestQuote().subscribe((message:StatusMessage)=>{
      let id = 1;
      if(message.success){
        id = message.message+1;
      }
      let ref = this.coid.toString()+"_"+this.userid.toString()+"_"+id.toString();
      this.saveform.get("description").setValue(ref);
    });
  }

  close() {
    this.dialogRef.close();
  }
  send() {
    if(!this.quote.id){
      
    }
  }
  save() {
    this.dialogRef.close({option:1,value:this.saveform.value});
  }
  export() {
    let anchor = document.createElement("a") as HTMLElement;
    switch (this.exportformat) {
      case 1:
        anchor.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.quote)));
        anchor.setAttribute("download", "quote_options.json");
        break;
      case 2:
        let savedversion = this.calc.mapForExport(this.quote, this.coid,"Provide Description",this.userid);
        anchor.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(savedversion)));
        anchor.setAttribute("download", "quote.json");
        break;
        case 3:
          anchor.setAttribute("href", "data:text/xml;charset=utf-8," + encodeURIComponent(this.calc.jsonToXML(this.quote)));
          anchor.setAttribute("download", "quote_options.xml");
          break;
      case 4:
        let savedversion2 = this.calc.mapForExport(this.quote, this.coid,"Provide Description",this.userid);
        let v2xml = this.calc.jsonToXML(savedversion2);
        anchor.setAttribute("href", "data:text/xml;charset=utf-8," + encodeURIComponent(v2xml));
        anchor.setAttribute("download", "quote.xml");
        break;
      
    }
    let ev = new MouseEvent("click");
    anchor.dispatchEvent(ev);
  }

}
