import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cs-yes-no-view',
  templateUrl: './yes-no-view.component.html',
  styleUrls: ['./yes-no-view.component.scss']
})
export class YesNoViewComponent implements OnInit {

  @Input() value = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
