import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanySettingType, CompanySetting, NewCompanySettingType } from 'src/app/models/user';
import { SettingEditor } from '../setting-editor';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-enum-chooser',
  templateUrl: './enum-chooser.component.html',
  styleUrls: ['./enum-chooser.component.scss']
})
export class EnumChooserComponent implements SettingEditor {

  @Input() data:any;
  public enum:any;
  public settingvalue:number;
  public title = "";
  public settingtype:CompanySettingType;

  constructor(public events:EventsService) { }

  ngOnInit(): void {
    this.enum = this.data.enum;
    this.title = this.data.title;
    this.settingtype = this.data.settingtype;
    if(this.settingtype.Setting){
      this.settingvalue = parseInt(this.settingtype.Setting.setting);
    }
    else this.settingvalue = this.data.default;
  }

  saveChanges(e){
    let newsettingtype = new NewCompanySettingType();
    newsettingtype.newsetting = this.settingvalue.toString();
    newsettingtype.settingtype = this.settingtype;
    this.events.companysettingchange.emit(newsettingtype);
  }

}
