import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SavedQuote } from 'src/app/models/rates';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { TableGenerator } from 'src/app/models/models';
import { CSMatTableComponent } from 'src/app/components/mat-table/mat-table.component';

@Component({
  selector: 'cs-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.scss']
})
export class QuoteHistoryComponent implements OnInit {

  public quotes:SavedQuote[] = [];

  constructor(public data:DataService,public tools:ToolsService) { }

  ngOnInit(): void {
    
    this.data.auth.currentroute = "Quotation History";
    
    this.data.listSavedQuotesStatus(0).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.quotes = message.message;      
      }
      else this.tools.gracefulError(message.message);
    },err=>this.tools.gracefulError(err));


  }

  changeView(e){

  }


}
