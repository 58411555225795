import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[htmlVar]',
})
export class HtmlvarDirective {
@Input()
set htmlVar(context: any) {
  this.context.$implicit = this.context.htmlVar = context;
  this.updateView();
}

context: any = {};

constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

updateView() {
  this.vcRef.clear();
  this.vcRef.createEmbeddedView(this.templateRef, this.context);
}
}
