import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SurchargeType } from 'src/app/models/rates';
import { Roles } from 'src/app/models/models';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-add-surchargetype',
  templateUrl: './add-surchargetype.component.html',
  styleUrls: ['./add-surchargetype.component.scss']
})
export class AddSurchargetypeComponent implements OnInit {

  @Input() appliesto:number;
  @Input() providerid:number;
  @Output() add = new EventEmitter<SurchargeType>();

  typename:string = "";

  constructor(public data:DataService,public tools:ToolsService) { }

  ngOnInit(): void {
  }
  addSurchargeType(){
    let newtype = new SurchargeType();
    if(this.data.role==Roles.MASTER_ADMIN.valueOf){
      newtype.providerid = 0;
    }
    else newtype.providerid = this.providerid;
    newtype.name = this.typename;
    newtype.description = this.typename;
    newtype.appliesto = this.appliesto;
    this.data.createSurchargeType(newtype).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.add.emit(message.message);
        this.typename = "";
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })

  }

}
