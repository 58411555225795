import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Company } from 'src/app/models/user';
import { Region } from 'src/app/models/rates';

@Component({
  selector: 'cs-import-rate-settings',
  templateUrl: './import-rate-settings.component.html',
  styleUrls: ['./import-rate-settings.component.scss']
})
export class ImportRateSettingsComponent implements OnInit {

  public startDate = new Date();
  public expiryDate:Date;
  public provider:Company;
  public region:Region;


  constructor(public dialogRef: MatDialogRef<ConfirmComponent>,@Inject(MAT_DIALOG_DATA) public dialogdata:any) {
    this.provider = dialogdata.provider;
    this.region = dialogdata.region;

  }

  ngOnInit(): void {
    let preexpires = this.startDate;
    this.expiryDate = new Date(preexpires.getDate(),preexpires.getMonth(),preexpires.getFullYear()+1);
  }
  cancel(){
    this.dialogRef.close();
  }

  import(){

  }

}
