import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RegisterComponent } from 'src/app/dialogs/register/register.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { Document } from 'src/app/models/models';

@Component({
  selector: 'cs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public cms = false;
  public cmsContent = "";
 
  constructor(private route: ActivatedRoute,private dialog: MatDialog, private data:DataService) { }

  ngOnInit(): void {
    this.data.auth.currentroute = "";
    this.route.queryParams.subscribe((params:Params)=>{
      
      if(params.invite){
        let guid = params.invite;
        const dialogRef = this.dialog.open(RegisterComponent, {
          width: 'auto',
          data: {guid: guid}
        });
        dialogRef.afterClosed().subscribe(()=>{
  
        })
      }
    })
    this.data.auth.userReady.subscribe(()=>{
      this.data.getDocumentByType(3).subscribe((message:StatusMessage)=>{
        if(message.success){
          if(message.message){
            this.cms = true;
            let doc:Document = message.message;
            doc.html = doc.html.replace("{Today's Date}",new Date().toLocaleDateString());
            this.cmsContent = doc.html;
          }
        }
      })
    })
    
    
  }

}
