<div *ngIf="address" class="section">
    <h3 *ngIf="title">{{title}}</h3>
    <div class="fl fw aic">
        <div class="field-title">Address</div>
        <div class="field-value">
            {{address.houseNameOrNumber}} {{address.street}} 
        </div>
        <!--<mat-icon *ngIf="address.street && address.street.length>0" class="tiny" [color]="refine?'accent':'primary'" (click)="refine=!refine">edit_road</mat-icon>-->
    </div>
    <div *ngIf="refine">

    </div>
    <div class="fl" *ngIf="address.postcode && address.postcode.length>0">
        <div class="field-title">Zone</div>
        <div class="field-value" *ngIf="address.Country">{{address.Country.iso_alpha_2}} {{address.postcode}}</div>
    </div>
    <div class="fl" *ngIf="!address.postcode || address.postcode.length==0">
        <div class="field-title">Zone</div>
        <div class="field-value" *ngIf="address.Country">{{address.Country.iso_alpha_2}} {{address.town}} {{address.state?address.state:""}}</div>
    </div>
    <div class="fl">
        <div class="field-title">GPS</div>
        <div class="field-value" *ngIf="address.gpslat">{{address.gpslat | number:'1.4-4'}},{{address.gpslong | number:'1.4-4'}}</div>
    </div>
    <div class="fl">
        <div class="field-title">Country</div>
        <div class="field-value">{{address.Country.name}}</div>
    </div>
</div>