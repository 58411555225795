import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage } from 'src/app/models/user';
import { DocumentGroup, Document, DocumentField } from 'src/app/models/models';
import { EventsService } from 'src/app/services/events.service';
import { ToolsService } from 'src/app/services/tools.service';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Quill from 'quill';


@Component({
  selector: 'cs-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.scss']
})
export class DocumentManagementComponent implements OnInit {

  public selectedDocument: Document;

  quillEditorRef: Quill;
  public documentGroups: DocumentGroup[];

  public docfields:DocumentField[] = [];
  public fields: string[] = ["Customer Company", "Customer Salutation", "Customer First Name", "Customer Surname", "Customer Address", "Your Company", "Your First Name", "Your Surname", "Your Address", "Today's Date", "Registration Link"]
  public invitefields: string[] = ["Customer Company", "Customer Salutation", "Customer Address", "Your Company", "Your First Name", "Your Surname", "Your Address", "Today's Date", "Registration Link"]



  constructor(public data: DataService, public tools: ToolsService, public events: EventsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    
    var Block = Quill.import('blots/block');
    Block.tagName = 'div';
    let AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    Quill.register(Block, true);
    
    if (this.data.auth && this.data.auth.core.user) {
      this.setup();
    }
    else {
      if (this.data.auth) {
        this.data.auth.userReady.subscribe(() => {
          this.setup();
        })
      }

    }

    this.events.selectedDocument.subscribe((doc: Document) => {
      this.selectedDocument = doc;
      this.getFields(doc);
    });


  }
  setup() {


    this.data.listDocumentGroups(this.data.auth.user.Site.companyid).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.documentGroups = message.message;
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;

    const toolbar = editorInstance.getModule('toolbar');
    /*
    let placeSelect:HTMLSelectElement = document.createElement("select");
    this.fields.forEach((f:string)=>{
      let placeOption:HTMLOptionElement = document.createElement("option");
      placeOption.value = f;
      placeOption.text = f;
      placeSelect.options.add(placeOption);
    })
    let tool:any=['placeholder',placeSelect];
    toolbar.controls.push(tool);
    */
    toolbar.addHandler('image', (image, callback) => {
      this.imageHandler(image, callback)
    });

  }

  imageHandler(image, callback) {
    const input = <HTMLInputElement>document.getElementById('fileInputField');
    input.onchange = () => {
      //input.form.submit();
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > 1000000) {
          this.tools.gracefulError("Image too large, please select a smaller one");
        }
        else {
          this.imageUpload(file);
          /*
          const reader = new FileReader();
          reader.onload = () => {
            const range = this.quillEditorRef  .getSelection();
            const img = '<img src="' + reader.result + '" />';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);*/
        }
      }
      else {
        this.tools.gracefulError("Images only please");
      }
    };

    input.click();
  }

  imageUpload(file: File) {
    const form: FormData = new FormData();
    form.append('file', file, this.selectedDocument.DocumentType.name);
    this.data.uploadDocumentImage(form).subscribe((message: StatusMessage) => {
      if (message.success) {
        const range = this.quillEditorRef.getSelection();
        const img = '<img src="' + environment.public + message.message + '" />';
        this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img, 'user');
      }
      else {
        this.tools.gracefulError(message.message);
      }
    },
      err => {
        this.tools.gracefulError(err);
      })
  }

  checker(e) {

  }

  addDocumentGroup() {
    let newchild = new DocumentGroup();
    newchild.name = "New";
    newchild.recordStatus = 0;

    this.data.createDocumentGroup(newchild).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.documentGroups.push(message.message);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    },
      err => {
        this.tools.gracefulError(err);
      })
  }

  saveDoc() {
    if (this.selectedDocument.documentTypeid == 2) {
      if (this.selectedDocument.html.indexOf('{Registration Link}') < 0) {
        this.tools.gracefulError("An invitation template must contain a registration link");
        return;
      }
    }
    if(this.selectedDocument.companyid==this.data.Company.id){
      this.data.updateDocument(this.selectedDocument).subscribe((message: StatusMessage) => {
        if (message.success) {
          //console.log(message);
        }
        else {
          this.tools.gracefulError(message.message);
        }
      },
        err => {
          this.tools.gracefulError(err);
        })
    }
    else{
      this.selectedDocument.companyid = this.data.Company.id;
      delete this.selectedDocument.id;
      this.data.createDocument(this.selectedDocument).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.selectedDocument = message.message;
        }
        else {
          this.tools.gracefulError(message.message);
        }
      },
      err => {
        this.tools.gracefulError(err);
      })
    }
    
  }


  addField(field: string) {

    this.quillEditorRef.focus();
    const range = this.quillEditorRef.getSelection();
    let quillfield = "{" + field + "}";
    let current = this.selectedDocument.html;
    this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, quillfield, 'user');
    //console.log(this.selectedDocument.html);
  }

  getFields(doc:Document){
    this.data.listDocumentFields(doc.documentTypeid).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.docfields = message.message;
      }
    })
  }
}
