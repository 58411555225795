import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'src/app/models/user';

@Component({
  selector: 'cs-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {

  @Input() address:Address
  
  constructor() { }

  ngOnInit() {
  }

}
