import { Component, OnInit, Input } from '@angular/core';
import { Rate } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-rateview',
  templateUrl: './rateview.component.html',
  styleUrls: ['./rateview.component.scss']
})
export class RateviewComponent implements OnInit {

  @Input() rate:Rate;

  public more = false;

  public currencyCode:string = "GBP";
  
  constructor(public data:DataService) { }

  ngOnInit(): void {
    if(this.rate.currency){
      this.currencyCode = this.data.countries.filter(c=>c.id==this.rate.currency)[0].currencyCode;
    }
    else{
      this.currencyCode = this.data.defaultCurrency.currencyCode;
    }

    
  }

}
