<div *ngIf="!data.token || data.token.length==0">
    <form class="login" [formGroup]="loginForm" (ngSubmit)="login()">
        <h2 *ngIf="tools.userHasExpired">Session Expired - please login again.</h2>
        <mat-form-field>
            <mat-label>Your User Name</mat-label>
            <input id="username" matInput formControlName="username" required  autocomplete="new-username">
            <mat-hint>Usually your email address</mat-hint>
            <mat-error *ngIf="username.invalid && (username.dirty || username.touched)">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="username.hasError('required')">
                User Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Your Password</mat-label>
            <input matInput formControlName="password" required type="password" autocomplete="current-password">
            <!--<mat-hint>We need at least 8 characters including a symbol, a number and both cases</mat-hint>-->
            <mat-error *ngIf="password.hasError('password') && !password.hasError('required')">
                Please enter a valid password
            </mat-error>
            <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                Password is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="fl aic jc ma-p">
            <button type="button" mat-raised-button color="warn" class="f1" (click)="cancel()">Cancel</button>
            <button mat-raised-button type="submit" color="primary" class="f1">Login</button>
        </div>
    </form>

</div>
<div *ngIf="data.token && data.token.length>0">
    <div *ngIf="!changePassOn">
        <div class="fl">
            <p><span title="{{auth.user.id}} - {{auth.user.email}}">{{auth.user.firstname}} {{auth.user.surname}}</span><br>
             <span title="{{auth.user.UserRoles[0].Role.id}}">({{auth.user.UserRoles[0].Role.description}})</span><br>
             <span title="{{auth.user.Site.Company.id}} site:( {{auth.user.Site.id}})">{{auth.user.Site.Company.name}}</span></p>
            <div class="f1"></div>
            <mat-slide-toggle color="primary" [(ngModel)]="auth.sideon">
                <span>Text Menu</span>
            </mat-slide-toggle>
        </div>
        <div class="fl aic jc ma-p">
            <button type="button" mat-raised-button color="warn" (click)="cancel()">Cancel</button>
            <button type="button" mat-raised-button color="warn" (click)="emptyStore()">Clear Session</button>
            <button type="button" mat-raised-button color="warn" (click)="logoff()">Log out</button>
            <button type="button" mat-raised-button color="primary" class="f1" (click)="openChangePass()">Change
                Password</button>
            <button type="button" mat-raised-button color="accent" (click)="refresh()">Refresh</button>
        </div>
    </div>
    <div *ngIf="changePassOn">
        <form class="login" [formGroup]="changePassForm">
            <div>
                <mat-form-field>
                    <mat-label>Existing Password</mat-label>
                    <input id="existing" matInput formControlName="existing" required type="password" autocomplete="new-fixed-discount">
                    <mat-error *ngIf="existing.hasError('required')">
                        Existing Password is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>New Password</mat-label>
                    <input matInput formControlName="newpassword" required type="password">
                    <mat-error *ngIf="newpassword.invalid && (newpassword.dirty || newpassword.touched)" autocomplete="new-password">
                        New Password is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="confirmpassword" required type="password" autocomplete="new-password">
                    <mat-error *ngIf="confirmpassword.invalid && (confirmpassword.dirty || confirmpassword.touched)">
                        Confirmed Password is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
        <div class="fl aic jc ma-p">
            <button type="button" mat-raised-button color="danger" (click)="cancelChangePass()">Cancel</button>
            <button type="button" mat-raised-button color="primary" class="f1" (click)="changePass()">Change
                Password</button>
        </div>
    </div>
</div>