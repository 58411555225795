import { Component, OnInit } from '@angular/core';
import { Company, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanySettingsComponent } from 'src/app/dialogs/company-settings/company-settings.component';

@Component({
  selector: 'cs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public exrates = false;
  public geog = false;
  company:Company;
  public pageinit = false;//charts render improperly before page has fully loaded

  constructor(public dialog: MatDialog,public data:DataService) {
    data.auth.currentroute = "Dashboard";
  }

  ngOnInit() {
    this.buildData();
    setTimeout(()=>{
      this.pageinit = true;
    })
  }
  
  showGlobal(){
    const dialogRef = this.dialog.open(CompanySettingsComponent, {
      width: 'auto'
    });

    dialogRef.afterClosed().subscribe((message: StatusMessage) => {
      if (message) {
      }
    });
  }

  buildData(){
    let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let trends = [{name:"Quotes",start:900,trend:2},{name:"Success",start:400,trend:4},{name:"Time Per Quote",start:600,trend:-5}];
    let series = [[400,390,420,430,900,880,960,1020,1000,1040,1100,1140],[120,100,150,180,400,440,540,500,600,760,820,900],[240,220,210,60,30,10,3,3,3,3,3,3]];
    let index = 0;
    trends.forEach(trend=>{
      let entry = {name:trend.name,series:[]}
      for(let i = 0;i<months.length;i++){
        let serie = {name:months[i],value:series[index][i]};
        entry.series.push(serie);
      }
      this.trend.push(entry);
      index++;
    })
  }

  public trend = [];
  public outstanding = [{ name: "Converted", value: 330 },  { name: "In Process", value: 15 },  { name: "Lost", value: 250 }];
}
