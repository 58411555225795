import { Component, Inject, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegiongrouperComponent } from '../regiongrouper/regiongrouper.component';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { GPS, Region } from 'src/app/models/rates';
import { MapSelect } from 'src/app/models/ui';
import { GoogleService } from 'src/app/services/google.service';
import { StatusMessage } from 'src/app/models/user';
import { EventsService } from 'src/app/services/events.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cs-locationpicker',
  templateUrl: './locationpicker.component.html',
  styleUrls: ['./locationpicker.component.scss']
})
export class LocationpickerComponent implements AfterViewInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @Output() mapclick = new EventEmitter<MapSelect>();

  home: GPS;
  map: google.maps.Map;
  zoom: number;
  place: google.maps.places.PlaceResult;
  locationtype = "delivery";
  searchOff = false;
  mapClass = "map";
  usepostcode = false;
  showpostcode = false;
  public closeOnClick: boolean = true;
  pin: GPS;

  private createsub:Subscription;


  constructor(public dialogRef: MatDialogRef<LocationpickerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, private tools: ToolsService,public geo:GoogleService,public events:EventsService) {
    //this.home = dialogdata.GPS; chrome violation without user input
    if (dialogdata.locationtype) this.locationtype = dialogdata.locationtype;
    if (dialogdata.dontCloseOnClick) this.closeOnClick = false;
    if (dialogdata.searchOff) this.searchOff = true;
    if (dialogdata.mapClass) this.mapClass = dialogdata.mapClass;
    if (dialogdata.showpostcode) this.showpostcode = dialogdata.showpostcode;
    if(dialogdata.pin) this.pin = dialogdata.pin;
    if(dialogdata.zoom) this.zoom = dialogdata.zoom;
    else this.zoom = 8;
    
    this.createsub = this.events.createRegion.subscribe((region:Region)=>{
      this.geo.addRegionFeaturePolys(this.map,region);
    })

  }

  ngAfterViewInit(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.home = new GPS();
          this.home.lat = position.coords.latitude;
          this.home.long = position.coords.longitude;
        }
        else{
          this.tools.snackMessage("We can't determine your location, please select from the map.")
        }
        this.setupMap();
      })
    }
    else {
      this.tools.snackMessage("We can't determine your location, please select from the map.")
    }
    


    
  }

  setupMap(){
    if (!this.home) {
      this.home = new GPS();
      this.home.lat = 0;
      this.home.long = 0;
      this.zoom = 2;
    }
    
    const mapProperties = {
      center: new google.maps.LatLng(this.home.lat, this.home.long),
      zoom: this.zoom,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_LEFT
      },
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    if (google.maps.places && !this.searchOff) {
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById('pac-input');
      let searchBox = new google.maps.places.SearchBox(input);
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(input);
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds());
      });
      searchBox.addListener('places_changed', () => {
        let places = searchBox.getPlaces();
        if (places.length > 0) {
          this.place = places[0];
          if (this.place.geometry) {
            let centre = this.place.geometry;
            this.map.setCenter(centre.location);
            this.map.setZoom(16);
          }
          else this.tools.gracefulError("Place has no geometry");
        }
        else this.tools.gracefulError("invalid place selecttion");

      })
    }

    if(this.pin) this.addPin();

    this.map.addListener('click', (e) => {
      this.mapClick(e);
    });
  }
  
  ngOnDestroy(){
    this.createsub.unsubscribe();
  }

  addPin() {
    
    let pin = new google.maps.Marker({
      position: new google.maps.LatLng(this.pin.lat,this.pin.long),
      map: this.map
    });
  }

  mapClick(e) {
    let selectedGPS = new GPS();
    selectedGPS.lat = e.latLng.lat();
    selectedGPS.long = e.latLng.lng();

    if (this.closeOnClick) {

      this.geo.reverseGeocode(e.latLng).then((message:StatusMessage)=>{
        if(message.success){
          this.dialogRef.close({ GPS: selectedGPS, place: message.message });
        }
        else{
          this.dialogRef.close({ GPS: selectedGPS, place: this.place });
        }
      })
      /*
      place id returns an address in the local language, TODO: look at this.
      
      if (e.placeId && e.placeId.length > 0) {
        this.geo.placeFromMap(e.placeId,this.map).then((message:StatusMessage)=>{
          if(message.success){
            this.dialogRef.close({ GPS: selectedGPS, place: message.message });
          }
          else{
            this.dialogRef.close({ GPS: selectedGPS, place: this.place });
          }
        })
      }
      else {
        this.geo.reverseGeocode(e.latLng).then((message:StatusMessage)=>{
          if(message.success){
            this.dialogRef.close({ GPS: selectedGPS, place: message.message });
          }
          else{
            this.dialogRef.close({ GPS: selectedGPS, place: this.place });
          }
        })
        
      }*/

    }
    else {
      this.mapclick.emit({ gps: e.latLng, usepostcode: this.usepostcode });
    }
  }
  cancel() {
    this.dialogRef.close();
  }

}
