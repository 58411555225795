<div class="fl">

    <div class="f1 mt" [formGroup]="parentForm">
        <div *ngIf="site" [formGroupName]="index">
            <div class="fl">
                <mat-form-field class="mr">
                    <mat-label>Site Name</mat-label>
                    <input matInput placeholder="Site Name" formControlName="name">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Site Type</mat-label>
                    <mat-select formControlName="sitetypeid">
                        <mat-option *ngFor="let type of data.siteTypes" [value]="type.id">
                            {{type.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>  
                <div class="f1"></div>
            </div>
            <div>
                <mat-tab-group class="site-detail" (selectedIndexChange)="tabChange($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="{{Address.valid?'':'invalid'}}">place</mat-icon>
                        </ng-template>
                        <cs-address [addressForm]="Address"></cs-address>
                    </mat-tab>
                    <mat-tab *ngIf="site.value.id && (site.value.sitetypeid==5 || site.value.sitetypeid==6)">
                        <ng-template mat-tab-label>
                            <mat-icon>payments</mat-icon><mat-icon>call_received</mat-icon>
                        </ng-template>
                        <div>
                            <cs-surcharge-container *ngIf="site" [editmode]="true" [appliesto]="appliestoin" [serviceForm]="site" [provider]="site.value.companyid"></cs-surcharge-container>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="site.value.id && (site.value.sitetypeid==5 || site.value.sitetypeid==6)">
                        <ng-template mat-tab-label>
                            <mat-icon>payments</mat-icon><mat-icon>call_made</mat-icon>
                        </ng-template>
                        <div>
                            <cs-surcharge-container *ngIf="site" [editmode]="true" [appliesto]="appliestoout" [serviceForm]="site" [provider]="site.value.companyid"></cs-surcharge-container>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>people</mat-icon>
                        </ng-template>
                        <div formArrayName="Users">
                            <div class="fl">
                                <h3>Users</h3>
                            </div>
                            <div *ngFor="let user of users.controls;let u=index">
                                <cs-user [user]="user" [index]="u" [parentForm]="users"></cs-user>
                            </div>

                        </div>
                        <div formArrayName="Invitations">
                            <div class="fl aic">
                                <h3>Invitations</h3>
                                <div class="f1 tar">
                                    <p class="warn tar" *ngIf="!site.value.id">Please save this company before adding new users</p>
                                </div>
                                <div>
                                    <button *ngIf="site.value.id" type="button" mat-mini-fab aria-label="add user" color="primary"
                                        title="Add User">
                                        <mat-icon (click)="addUser()">add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div *ngFor="let invitation of invitations.controls;let in=index;">
                                    <cs-invitation [invitation]="invitation" [index]="in" [parentForm]="invitations">
                                    </cs-invitation>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon (click)="addMap()">map</mat-icon>
                        </ng-template>
                        <div class="f1 h100 pos-rel">
                            <input #search id="pac-input" type="text" placeholder="Search Box">
                            <div #map class="map"></div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

</div>