import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'src/app/models/user';

@Component({
  selector: 'cs-address-short',
  templateUrl: './address-short.component.html',
  styleUrls: ['./address-short.component.scss']
})
export class AddressShortComponent implements OnInit {
  
  @Input() address:Address;
  @Input() title:string;

  public refine = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
