import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialog } from '@angular/material/dialog';
import { PasteResultsComponent } from '../paste-results/paste-results.component';
import { Site, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { Service } from 'src/app/models/data';
import { Region, MarginAppliesTo, Rate, RatePriority } from 'src/app/models/rates';
import { PastedTable, PastedRow, PastedTableCollection } from 'src/app/models/ui';
import { EventsService } from 'src/app/services/events.service';


@Component({
  selector: 'cs-objectifier',
  templateUrl: './objectifier.component.html',
  styleUrls: ['./objectifier.component.scss']
})
export class ObjectifierComponent implements OnInit {

  @Input() site: Site;
  @Input() region: Region;
  @Input() service: Service;
  @Input() appliesto: MarginAppliesTo;
  @Input() vertical: boolean;
  @Input() priority:RatePriority;

  @Output() add = new EventEmitter<any[]>();

  public objects: any[] = [];

  private partialcsv = 0;

  constructor(public tools: ToolsService, public dialog: MatDialog, public data: DataService, public events: EventsService) { }

  ngOnInit(): void {
  }

  generateObject() {
    this.partialcsv = 0;
    this.objects = [];
    navigator.clipboard.readText().then(t => {
      if (t) {
        if (t.indexOf("GALOBAKWL") >= 0) {
          let ptc:PastedTableCollection = this.generateCollectionFromUtterShite(t);
        }
        else {
          let rows: string[] = t.split("\n");
          let table = this.genPastedTable(rows);
          if (table.datarows && table.datarows.length > 0) {
            let ptc = new PastedTableCollection();
            ptc.tables = [table];
            this.fireResults(ptc);
          }
          else this.tools.gracefulError("No data, please copy a table from another document");
        }

      }
      else {
        this.tools.gracefulError("No data received, please copy a table from another document and try again");
      }
    })
  }
  generateObjectFromPdf() {

  }
  generateCollectionFromUtterShite(t: string) {
     let tablestrings = [];
     tablestrings = this.findTablesWithinUtterShite(t,tablestrings);
     let ptc = new PastedTableCollection();
     tablestrings.forEach(ts=>{
       let pt = new PastedTable();
       let dirtyrows = ts.table.bodystring.split('\n');
       dirtyrows.forEach(dr=>{
         dr = dr.replace('\t',' ');
         let dirtycells = dr.split(' ');
         let pr = new PastedRow();
         pr.cells = dirtycells;
         pt.datarows.push(pr);
       })
       ptc.tables.push(pt);
     })
     return ptc;     
  }
  findTablesWithinUtterShite(t:string,tablestrings:any[]){
    let tablestring = {table:{headerstring:"",bodystring:""}};
    let headerstart = t.indexOf("POSTCODE");
    let headerend = t.indexOf("£");
    if(headerstart>=0 && headerend>=0){
        tablestring.table.headerstring = t.substring(headerstart,(headerend-headerstart));
        let rest = t.substring(headerend);
        let another = rest.indexOf("POSTCODE");
        if(another>=0){
          let tablerest = rest.substring(0,another);
          tablestring.table.bodystring = tablerest;
          tablestrings.push(tablestring);
          return this.findTablesWithinUtterShite(rest.substring(another),tablestrings);

        }
        else{
          tablestring.table.bodystring = rest;
          tablestrings.push(tablestring);
          return tablestrings;
        }
    }
    else return tablestrings;   

    
  }
  objectify(table: PastedTable) {
    if (table.headerrows.length > 0) {

    }
    table.datarows.forEach(dr => {
      let index = 0;
      let obj: any = {};
      dr.cells.forEach(cell => {
        //obj[table.headerrow.cells[index]] = cell;
        index++;
      })
      this.objects.push(obj);
    })
  }
  fireResults(ptc: PastedTableCollection) {
    if (!this.site) {
      if (this.data.selectedSupplier) {
        if (this.data.selectedSupplier._baseSite) {
          this.site = this.data.selectedSupplier._baseSite;
          if (!this.site.Company) this.site.Company = this.data.selectedSupplier.Provider;
        }
        else {
          this.site = this.data.auth.user.Site;
        }
      }
      else this.site = this.data.auth.user.Site;
    }
    const dialogRef = this.dialog.open(PasteResultsComponent, {
      width: 'auto',
      data: { collection: ptc, site: this.site, service: this.service, region: this.region, appliesto: this.appliesto,priority:this.priority }
    });

    dialogRef.afterClosed().subscribe((message: StatusMessage) => {
      if (message) {
        if (message.message.ratemap) {
          if (message.success) {
            this.tools.snackMessage("Request Successful");
            this.add.emit(message.message);
          }
          else {
            this.tools.gracefulError("Request Failed, please try again or email support for further guidance");
          }
        }
        else {
          if (message.success) {
            this.add.emit(message.message);
            //console.log(message.message);
          }

        }
      }
    });
  }
  setSiteToUsers() {
    this.site = this.data.auth.user.Site;
  }

  /**replace multiple tabs with just one to handle merged cells */
  cleanTable(raw: string) {
    let clean = raw.replace(/\t+/g, '\t');
    return clean;
  }
  /**
   * calculate the size of the table and compare with those stored for the selected supplier
   * @param rows 
   */
  sizeTable(rows: string[]) {
    let index = 0;
    let rowlength = rows.length - 1; //always a dead footer in an excel paste
    let celllength = 0;
    let rowswithmaxcells = 0;
    rows.forEach(row => {
      let allcells = row.split("\t");
      console.log("row " + index + " has " + allcells.length + " cells");
      allcells.forEach(c => {
        console.log("cell value: " + c + " of length: " + c.length + " isEmpty: ", c == "");
      })
      let cells = allcells.filter(c => c != "");
      if (cells.length > celllength) {
        celllength = cells.length;
        rowswithmaxcells = 1;
      }
      else if (cells.length == celllength) {
        rowswithmaxcells++;
      }
      index++;
    })
    console.log("table is " + rowlength + " x " + celllength + " the useful data in the table has " + rowswithmaxcells + " rows");
  }
  /**
   * look for the bulk of the table - assuming those rows have the most columns
   * also assume header rows are at the top of the table
   * 
   * also assume there might be some info in the bottom rows
   * @param rows 
   */
  stripHeaders(rows: string[]) {
    let maxcells = 0;
    let rowswithmaxcells = 0;
    rows.forEach(row => {
      let cells = row.split("\t");
      if (cells.length > maxcells) {
        maxcells = cells.length;
        rowswithmaxcells = 1;
      }
      else if (cells.length == maxcells) {
        rowswithmaxcells++;
      }
    })

  }
  rawPastedTable(rows: string[]) {
    let pt = new PastedTable();
    rows.forEach(r => {
      let pr = new PastedRow();
      let ccells = r.split("\t");

      ccells.forEach(c => {
        pr.cells.push(c.replace(/[^\x20-\x7E]/g, "").trim());
      })
      pt.rawrows.push(pr);
    })
    return pt;
  }
  genPastedTable(rows: string[]) {
    let pt = new PastedTable();
    pt.datarows = [];
    //this.unformattedPdfCheck(pt, rows);
    if (pt.datarows.length > 0) {
      let bob = 1;
    }
    else {
      rows.forEach(r => {
        let pastedrow = new PastedRow();
        pastedrow.raw = r;
        pastedrow.rawcells = r.split("\t");
        pastedrow.cleaned = r.replace(/\t+/g, '\t');
        if (pastedrow.cleaned == "") {
          //handleUnstructuredPdf(pt)
        }
        let cells = pastedrow.cleaned.split("\t");

        let ccells = [];
        if (this.partialcsv) {
          let empty = true;
          pastedrow.rawcells.forEach(rc => {
            let newcell = rc.replace(/[^\x20-\x7E]/g, "").trim();
            if (newcell != "") empty = false;
            ccells.push(newcell);
          })
          if (empty) ccells = [];
        }
        else {
          cells.forEach(cell => {
            ccells.push(cell.replace(/[^\x20-\x7E]/g, "").trim());
          });
        }


        for (let i = ccells.length - 1; i >= 0; i--) {
          if (ccells[i] == "") {
            if (!this.partialcsv || i > this.partialcsv) {
              ccells.splice(i, 1);
            }
          }
          else break;
        }
        pastedrow.cells = ccells;
        if (ccells.length == 1 && ccells[0] != "") {
          pt.titlerow = pastedrow;
          if (pt.titlerow.cells[0] && pt.titlerow.cells[0].indexOf("Rates to") == 0) {
            this.partialcsv = 1;//don't kill column info cells less than index 1;
          }
        }
        else pt.datarows.push(pastedrow);
      })
      this.removeEmptyRows(pt.datarows);
      this.genHeaders(pt);
      this.genColumns(pt);
    }
    /*
    if (pt.headerrows) {
      let index = 1;
      if (pt.datarows.length > 0) {
        while (pt.headerrow.cells.length < pt.datarows[0].cells.length) {
          pt.headerrow.cells.push("Unknown_" + index);
          index++;

        }
      }

    }*/
    return pt;
  }

  unformattedPdfCheck(pt: PastedTable, rows: string[]): PastedTable {
    let unstructured = true;
    rows.some(row => {
      let columns = row.split("\t");
      if (columns.length > 1) {
        unstructured = false;
        return true;
      }
    })
    if (unstructured) return this.handleUnstructuredPdf(pt, rows)
    return pt;
  }

  private DPDRateBrackets = ["Expresspak", 'Parcel', 'Freight Parcel', 'Pallet'];
  private DPDClassicColumns = ["Transit Time", "Customs Clearance", "Export Return Fee", 'Parcel', "Expresspak", 'Freight Parcel', 'Pallet'];

  handleUnstructuredPdf(pt: PastedTable, rows: string[]): PastedTable {
    pt.titlerow = new PastedRow();
    let dataindex = 0;
    let dpdclassic = false;
    let dpdimportclassic = false;
    let dpdairclassic1 = false;
    let dpdairclassic2 = false;
    let airexpress = false;

    rows.some(row => {
      row = row.replace(/(\r\n|\n|\r)/gm, "");
      if (row.indexOf("DPD CLASSIC") == 0) {
        dpdclassic = true;
        return true;
      }
      else if (row.indexOf("Import Classic") >= 0) {
        dpdimportclassic = true;
        return true;
      }
      else if (row.indexOf("DPD") == 0) {
        return true;
      }
      else if (row.indexOf("AIR CLASSIC") >= 0) {
        dpdairclassic1 = true;
        return true;
      }
      else if (row.indexOf("Air Classic - Country") >= 0) {
        dpdairclassic2 = true;
        return true;
      }
      else if (row.indexOf("AIR EXPRESS") >= 0) {
        dpdairclassic1 = true;
        airexpress = true;
        return true;
      }
      else if (row.indexOf("Air Express - Country") >= 0) {
        dpdairclassic2 = true;
        airexpress = true;
        return true;
      }
      else {
        pt.titlerow.cells.push(row);
        dataindex++;
      }
    })
    if (dpdclassic) {
      pt.titlerow.cells = ["DPD Classic"];
      rows.splice(0, 1);
      this.dpdClassicTable(pt, rows);
    }
    else if (dpdimportclassic) {
      rows.splice(0, 3);
      let tr = new PastedRow();
      tr.cells.push("DPD Import Classic");
      pt.titlerow = tr;
      let th = new PastedRow();
      th.cells = ["Country", "Transit Time", "Per Parcel"];
      pt.headerrows.push(th);
      this.dpdImportClassic(pt, rows);

    }
    else if (dpdairclassic1) {
      if (airexpress) {
        pt.definer = "express";
        pt.titlerow.cells = ["DPD Air Express 1"];
      }
      else {
        pt.titlerow.cells = ["DPD Air Classic 1"];
      }

      rows.splice(0, 1);
      this.dpdAir1(pt, rows);
    }
    else if (dpdairclassic2) {
      if (airexpress) {
        pt.definer = "express";
        pt.titlerow.cells = ["DPD Air Express Country Specific"];
      }
      else {
        pt.titlerow.cells = ["DPD Air Classic Country Specific"];
      }

      rows.splice(0, 1);
      this.dpdAir2(pt, rows);
    }
    else {
      rows.splice(0, dataindex);
      pt.headerrows = [new PastedRow()];
      pt.headerrows[0].cells = [];
      let headindex = 0;
      let headerstring = "";
      rows.some(row => {
        //row = row.replace(/(\r\n|\n|\r)/gm,"");

        row = row.replace(/\t+/g, '\t').trim();

        let pack = false;
        this.DPDRateBrackets.some(pk => {
          if (pk == row) {
            pack = true;
            return true;
          }
        })
        if (pack) {
          return true;
        }
        else {
          headerstring += row;
          headindex++;
        }
      })
      rows.splice(0, headindex);
      let header2 = headerstring.split('DPD');

      let newheader: string[] = [];
      header2.forEach(h => {
        h = h.trim();
        if (h.indexOf('SATURDAY') >= 0) {
          let h3 = h.split('SATURDAY');
          if (h.indexOf('SATURDAY') > 0 && h3.length > 0) {
            pt.headerrows[0].cells.push("DPD " + h3[0]);
            h3.splice(0, 1);
          }
          h3.forEach(h4 => {
            if (h4.indexOf("SUNDAY") >= 0) {
              let h5 = h4.split("SUNDAY");
              if (h5.length > 0 && h5[0] == " ") {
                pt.headerrows[0].cells.push("SATURDAY");
                h5.splice(0, 1);
              }
              h5.forEach(h6 => {
                h6 = ("SUNDAY " + h6).trim();
                pt.headerrows[0].cells.push(h6);
              })

            }
            else {
              h4 = "SATURDAY " + h4;
              pt.headerrows[0].cells.push(h4);
            }
          })
          let z = 1;
        }
        else {
          if (h.length > 0) {
            h = "DPD " + h;
          }
          pt.headerrows[0].cells.push(h);
        }
      })
      //we have the header for this region, now split the rate brackets
      if (rows.length > 0) {
        let ratebracket = rows[0].trim();
        rows.splice(0, 2);//2nd row here is a description of the ratebracket
        this.createDPDRow(ratebracket, pt, rows);
      }
    }


    return pt;
  }
  //create a DPD ratebracket row
  createDPDRow(ratebracket: string, pt: PastedTable, rows: string[]): PastedTable {
    let pr = new PastedRow();
    pr.cells = [];
    pr.cells.push(ratebracket);

    let pr2 = new PastedRow();
    pr2.cells = [];

    let pr3 = new PastedRow();
    pr3.cells = [];

    let index = 0;//the raw row index (single column)
    let colindex = 0;//the cell index of the newly minted row

    let minimumInPlay = false;
    let subsequentInPlay = false;
    let columnMin = false;
    let columnSub = false;

    //check if there are subsequent or minimum rows in this rate bracket section.
    rows.some(r => {
      let packsection = false;
      r = r.trim();
      this.DPDRateBrackets.some(rb => {
        if (rb == r) {
          packsection = true;
          return true;
        }
        if (r.toLowerCase().indexOf('minimum') >= 0) {
          minimumInPlay = true;
        }
        if (r.toLowerCase().indexOf('subsequent') >= 0 || r.toLowerCase().indexOf('then') >= 0) {
          subsequentInPlay = true;
        }
      })
      if (packsection) return true;
    })

    rows.some(row => {
      row = row.trim();
      let pack = false;
      this.DPDRateBrackets.some(pk => {
        if (pk == row) {
          pack = true;
          return true;
        }
      })
      if (pack) {
        return true;
      }
      else {
        let rowstocheck: string[] = [];
        let nacheck = row.split("Not Available");
        if (nacheck.length > 1) {
          nacheck.forEach(na => {
            rowstocheck.push(na);
            rowstocheck.push('n/a');
          })
          rowstocheck.splice(rowstocheck.length - 1, 1);
        }
        else rowstocheck = [row];


        rowstocheck.forEach(r2 => {
          if (pr.cells[0] == "Pallet" || pr.cells[0] == "Parcel" || pr.cells[0] == "Freight Parcel") {
            if (r2.toLowerCase().indexOf('minimum') >= 0) {
              columnMin = true;
            }
            if (r2.toLowerCase().indexOf('subsequent') >= 0 || r2.toLowerCase().indexOf('then') >= 0) {
              columnSub = true;
            }
            if (pr2.cells.length == 0) {
              if (pr.cells[0] == "Parcel") {
                pr2.cells.push("subsequent");
              }
              else pr2.cells.push("then per kg")
            }
            if (pr3.cells.length == 0) pr3.cells.push("minimum");

            if (r2 == "n/a") {
              if (colindex == 0) {
                pr.cells.push(r2);
                //colindex++;//shouldn't have further rows if n/a?
                if (subsequentInPlay) {
                  pr2.cells.push("");
                }
                if (minimumInPlay) {
                  pr3.cells.push("");
                }
              }
              else if (colindex == 1) {
                if (subsequentInPlay) {
                  pr2.cells.push(r2);
                  colindex++;
                }
                else {
                  pr.cells.push(r2);
                  colindex = 1;
                }
              }
              else if (colindex == 2) {
                if (minimumInPlay) {
                  pr3.cells.push(r2);
                }
                else {
                  pr.cells.push(r2);
                }
                colindex = 1;
              }
            }
            else {
              //try to pick up missing pallet entries.
              /*
              if (colindex == 1 && r2.indexOf('per') >= 0 && r2.indexOf('then') < 0) {
                let missing = this.tools.cleanFloat(r2);
                if (typeof missing === "number" && !isNaN(missing)) {
                  if (missing > 1) {
                    while (pr2.cells.length < pr.cells.length) {
                      pr2.cells.push("");
                    }
                    while (pr3.cells.length < pr.cells.length) {
                      pr3.cells.push("");
                    }

                    colindex = 0;
                  }
                }
              }*/
              if (r2.indexOf("kg") < 0) {
                let clean = this.tools.cleanFloat(r2);
                if (typeof clean === "number" && !isNaN(clean)) {
                  if (colindex == 0) {
                    pr.cells.push(clean.toString());
                    colindex++;
                  }
                  else if (colindex == 1) {
                    if (columnSub) {
                      pr2.cells.push(clean.toString());
                      colindex++;
                      columnSub = false;
                      if (!minimumInPlay) {
                        colindex = 0;
                      }
                    }
                    //assuming if no sub then no minimum
                    else {
                      pr.cells.push(clean.toString());
                      if (subsequentInPlay) {
                        while (pr2.cells.length < pr.cells.length) {
                          pr2.cells.push("");
                        }

                      }
                      if (minimumInPlay) {
                        while (pr3.cells.length < pr.cells.length) {
                          pr3.cells.push("");
                        }
                      }
                      colindex = 0;
                    }
                  }
                  else if (colindex == 2) {
                    if (minimumInPlay) {
                      if (columnMin) {
                        pr3.cells.push(clean.toString());
                        columnMin = false;
                        colindex = 0;
                      }
                      else {
                        pr.cells.push(clean.toString());
                        pr3.cells.push("");
                        colindex = 1;
                      }
                    }
                    else {
                      pr.cells.push(clean.toString());
                      colindex = 1;
                    }

                  }



                  /*
                  else if (colindex == 1) {
                    if (subsequentInPlay) {
                      if (columnSub) {
                        pr2.cells.push(clean.toString());
                        colindex++;
                        columnSub = false;
                      }
                      else {
                        pr2.cells.push("");
                        pr.cells.push(clean.toString());
                      }
                    }
                    else {
                      pr.cells.push(clean.toString());
                      colindex = 1;
                    }
                  }
                  else if (colindex == 2) {
                    if (minimumInPlay) {
                      if (columnMin) {
                        pr3.cells.push(clean.toString());
                        colindex = 0;
                        columnMin = false;
                      }
                      else {
                        pr3.cells.push("");
                        pr.cells.push(clean.toString());
                        colindex = 1;
                      }

                    }
                    else {
                      pr.cells.push(clean.toString());
                      colindex = 1;
                    }
                  }*/

                }
              }

            }
          }
          else if (pr.cells[0] == "xParcel") {
            if (pr2.cells.length == 0) pr2.cells.push("subsequent");
            if (r2 == "n/a") {
              if (colindex == 0) {
                pr.cells.push(r2);
                colindex++;
              }
              else if (colindex == 1) {
                pr2.cells.push(r2);
                colindex = 0;
              }
            }
            else {
              let clean = this.tools.cleanFloat(r2);
              if (typeof clean === "number" && !isNaN(clean)) {
                if (colindex == 0) {
                  pr.cells.push(clean.toString());
                  colindex++;
                }
                else if (colindex == 1) {
                  pr2.cells.push(clean.toString());
                  colindex = 0;
                }
              }
            }
          }
          else {
            if (r2 == "n/a") {
              pr.cells.push(r2);
            }
            else {
              if (r2.toLowerCase().indexOf('subsequent') >= 0 || r2.toLowerCase().indexOf('then') >= 0) {
                subsequentInPlay = true;
                columnSub = true;
              }
              else if (r2.toLowerCase().indexOf('minimum') >= 0) {
                minimumInPlay = true;
                columnMin = true;
              }
              else {
                let clean = this.tools.cleanFloat(r2);
                if (typeof clean === "number" && !isNaN(clean)) {
                  pr.cells.push(clean.toString());
                }
              }

            }
          }




        })

        index++;
      }
    })
    pt.datarows.push(pr);
    if (subsequentInPlay) pt.datarows.push(pr2);
    if (minimumInPlay) pt.datarows.push(pr3);

    rows.splice(0, index);
    if (rows.length > 0) {
      ratebracket = rows[0].trim();
      rows.splice(0, 2);//2nd row is rate bracket description
      this.createDPDRow(ratebracket, pt, rows);
    }
    return pt;
  }

  //handle DPD Classic rates (Countries)
  dpdClassicTable(pt: PastedTable, rows: string[]) {
    let headerindex = 0
    rows.some(r => {
      if (r.indexOf("Pallet") >= 0) {
        return true;
      }
      headerindex++;
    })
    rows.splice(0, headerindex + 1);
    pt.headerrows.push(new PastedRow());
    pt.headerrows[0].cells = [""].concat(this.DPDClassicColumns);
    let countries: Array<string[]> = [];
    this.dpdCountries(pt, rows, countries);


    this.tools.snackMessage("Handling DPD Classic Table");

  }
  dpdCountries(pt: PastedTable, rows: string[], countries: Array<string[]>) {
    let pr = new PastedRow();
    let coindex = 0;
    let country = [];
    rows.some(r => {
      let regex = r.match(/\b[A-Z][a-zA-Z]*\w{2,}\b/g);
      if (regex) {
        if (country.length > 0) countries.push(country);
        let countryname = regex[0];
        for (let reg = 1; reg < regex.length; reg++) {
          countryname += " " + regex[reg];
        }
        let restofrow = r.replace(countryname, "");
        country = [countryname, restofrow];
      }
      else {
        country.push(r);
      }


    })
    countries.push(country);
    this.dpdCountry(pt, countries);
  }
  dpdCountry(pt: PastedTable, countries: Array<string[]>) {
    countries.forEach(country => {
      let pr = new PastedRow();
      let pr2 = new PastedRow();
      let pr3 = new PastedRow();
      pr.cells.push(country[0]);
      pt.datarows.push(pr);
      country.splice(0, 1);
      let additionalinplay = false;
      country.some(c => {
        if (c.indexOf('additional') >= 0) {
          additionalinplay = true;
          return true;
        }
      })
      if (additionalinplay) {
        pr2.cells = ["up to kg", "", "", "", ""];
        pr3.cells = ["then per kg", "", "", "", ""];
        pt.datarows.push(pr2);
        pt.datarows.push(pr3);
      }
      let c1s = country[0].split(' ');

      c1s = c1s.reverse();
      c1s.pop();
      country.splice(0, 1);
      c1s.forEach(c1 => {
        country.unshift(c1);
      });
      pr.cells.push(this.tools.latestFromRange(country[0]).toString());
      if (country[1].trim() == "N/A") pr.cells.push("N/A");
      else pr.cells.push(this.tools.cleanFloat(country[1]).toString());
      pr.cells.push(this.tools.cleanFloat(country[2]).toString());
      pr.cells.push(this.tools.cleanFloat(country[4]).toString());

      if (country[7]) {
        if (country[7].indexOf("pak") >= 0) { //express pak in play
          let bits = country[7].split(' ');
          pr.cells.push(bits[0]);
          country[7] = bits[3];
        }
        else {
          pr.cells.push("");
        }
        pr2.cells.push("");
        pr3.cells.push("");
        if (additionalinplay) {
          pr.cells.push(this.tools.cleanFloat(country[7]).toString());
          pr2.cells.push(this.tools.cleanFloat(country[9]).toString());
          pr3.cells.push(this.tools.cleanFloat(country[10]).toString());

          pr.cells.push(this.tools.cleanFloat(country[12]).toString());
          pr2.cells.push(this.tools.cleanFloat(country[14]).toString());
          pr3.cells.push(this.tools.cleanFloat(country[15]).toString());
        }
      }
      else {
        pr.cells.push("");
        pr.cells.push("");
        pr.cells.push("");
      }
    })
    console.log(countries);
  }

  dpdImportClassic(pt: PastedTable, rows: string[]) {
    rows.forEach(r => {
      let cells = r.split(' ');
      let country = "";
      if (cells.length > 5) {
        while (cells.length > 4) {
          country += " " + cells[0];
          cells.splice(0, 1);
        }
        cells.unshift(country);
      }
      if (cells.length == 5) {
        let pr = new PastedRow();
        pr.cells.push(cells[0]);
        pr.cells.push(this.tools.latestFromRange(cells[1]).toString());
        pr.cells.push(this.tools.cleanFloat(cells[2]).toString());
        pt.datarows.push(pr);
      }
    })
  }

  dpdAir1(pt: PastedTable, rows: string[]) {
    let pr = new PastedRow();
    pr.cells = ["EU Destinations", ""];
    for (let x = 1; x < 5; x++) {
      pr.cells.push("Zone " + x.toString());
    }
    let pr2 = new PastedRow();
    let row1 = rows[1].split(" ");
    if (row1.length == 9) {
      pr2.cells.push("parcel (up to " + this.tools.cleanFloat(row1[4]).toString() + "kg)");
      pr2.cells.push("");
      for (let x = 5; x < row1.length; x++) {
        pr2.cells.push(this.tools.cleanFloat(row1[x]).toString());
      }
    }
    let pr3 = new PastedRow();
    let row2 = rows[2].split(" ");
    if (row2.length == 9) {
      pr3.cells.push("then for each " + this.tools.cleanFloat(row2[4]).toString() + "kg");
      pr3.cells.push("");
      for (let x = 5; x < row2.length; x++) {
        pr3.cells.push(this.tools.cleanFloat(row2[x]).toString());
      }
    }

    let pr4 = new PastedRow();
    pr4.cells.push("Non - EU Destinations");
    for (let x = 5; x < 10; x++) {
      pr4.cells.push("Zone " + x.toString());
    }
    let pr5 = new PastedRow();
    let row3 = rows[4].split(" ");
    if (row3.length == 10) {
      pr5.cells.push("parcel (up to " + this.tools.cleanFloat(row3[4]).toString() + "kg)");
      for (let x = 5; x < row3.length; x++) {
        pr5.cells.push(this.tools.cleanFloat(row3[x]).toString());
      }
    }
    let pr6 = new PastedRow();
    let row4 = rows[5].split(" ");
    if (row4.length == 10) {
      pr6.cells.push("then for each " + this.tools.cleanFloat(row4[4]).toString() + "kg");
      for (let x = 5; x < row4.length; x++) {
        pr6.cells.push(this.tools.cleanFloat(row4[x]).toString());
      }
    }
    pt.headerrows = [pr];
    pt.datarows.push(pr2);
    pt.datarows.push(pr3);
    pt.datarows.push(pr4);
    pt.datarows.push(pr5);
    pt.datarows.push(pr6);
  }

  dpdAir2(pt: PastedTable, rows: string[]) {
    let pr = new PastedRow();
    pr.cells = ["Non-EU Specific Destinations", "price", "up to (kg)", "then", "per"];
    pt.headerrows.push(pr);
    rows.splice(0, 1);
    rows.forEach(r => {
      let country = r.split(' ');
      let name = "";
      if (country.length > 12) {
        while (country.length > 11) {
          name += " " + country[0];
          country.splice(0, 1);
        }
        country.unshift(name);
      }
      let cr = new PastedRow();
      cr.cells.push(country[0]);
      cr.cells.push(this.tools.cleanFloat(country[1]).toString());
      cr.cells.push(this.tools.cleanFloat(country[6]).toString());
      cr.cells.push(this.tools.cleanFloat(country[8]).toString());
      cr.cells.push(this.tools.cleanFloat(country[11]).toString() + "kg");
      pt.datarows.push(cr);
    })

  }
  /**
   * remove rows with no information - excel paste will always have a trailing empty row
   * @param rows 
   */
  removeEmptyRows(rows: PastedRow[]) {
    if (rows.length < 1) return;
    for (let index = rows.length - 1; index >= 0; index--) {
      if (rows[index].cells.length == 0) {
        rows.splice(index, 1);
      }
    }
  }
  /**
   * generate header rows - multiple rows will indicate sub groupings
   * @param rows 
   */
  genHeaders(table: PastedTable) {
    //this.checkForCSV(table);
    if (table.datarows.length < 2) return;
    let numberofconsistentrows = 1;
    let rows = table.datarows;
    let last = rows[rows.length - 1].rawcells.length - rows[rows.length - 1].cells.length;
    let consistency = [];
    for (let index = rows.length - 2; index >= 0; index--) {
      if (rows[index].rawcells.length - rows[index].cells.length === last) {
        numberofconsistentrows++;
      }
    }
    if (numberofconsistentrows == rows.length) {
      //1 for 1 columns, use row 0
      table.headerrows = table.datarows.splice(0, 1);
      table.datacolumns = table.headerrows[0].cells.length - 1;
      return;
    }
    if (numberofconsistentrows == rows.length - 1) {
      //easy single headerrow
      let headerrow = table.datarows.splice(0, 1)[0];
      table.headerrows = [headerrow];
      //get fields in line
      let rawindex = 0;
      let firstdata = table.datarows[0];
      headerrow.rawcells.forEach(raw => {
        if (raw == "" && firstdata.cells[rawindex] != "") {
          headerrow.cells.splice(rawindex + 1, 0, "Field" + rawindex);
        }
        rawindex++;
      })
      //bit of genericisation needed here (this is the Edge spreadsheet)
      if (headerrow.cells[2] == "Port Pairs" && table.datarows.length > 1 && table.datarows[0].rawcells.length == table.datarows[0].cells.length) {
        //sudo csv, ignore category header row
        table.headerrows = [];
        table.headerrows = table.datarows.splice(0, 1);
        if (table.datarows.length > 5) {
          table.extrarows = table.datarows.splice(5, table.datarows.length - 5);
          table.iscsv = true;
        }
      }
      table.datacolumns = table.headerrows[0].cells.length - 1;
      return;
    }
    //handle multiple rows
    let headerrowcount = 0;
    table.datarows.some(row => {
      if (row.cells[0] == "") {
        headerrowcount++;
      }
      else return true;
    })
    if (headerrowcount > 0) {
      if (headerrowcount == 2 && this.partialcsv) {
        table.headerrows = table.datarows.splice(0, 1);
      }
      else table.headerrows = table.datarows.splice(0, headerrowcount);

      table.datacolumns = table.headerrows[0].cells.length - 1;
      //pad as necessary
      table.headerrows.forEach(row => {
        let previous = "";
        let paddedcells = [];
        row.rawcells.forEach(cell => {
          let newcell = cell;
          let cleaned = cell.replace(/[^\x20-\x7E]/g, "").trim();
          if (cleaned == "") {
            newcell = previous;
          }
          else previous = cell;
          paddedcells.push(newcell);
        })
        row.cells = paddedcells;
      })
    }
    else {
      if (table.datarows.length > 0 && table.titlerow.cells.length == 0) {
        if (table.datarows[0].rawcells.length == table.datarows[0].cells.length) {
          table.headerrows = table.datarows.splice(0, 1);
          if (table.datarows.length > 10) {
            table.extrarows = table.datarows.splice(10, table.datarows.length - 10);
            table.iscsv = true;
          }

          /* investigate why this doesn't work at some point (string not mutated)
          table.headerrows[0].cells.forEach(cell=>{
            cell = cell.replace('_',' ');
            cell = this.tools.toTitleCase(cell);
          })*/

          for (let i = 0; i < table.headerrows[0].cells.length; i++) {
            let nicer = table.headerrows[0].cells[i];
            nicer = nicer.replace('_', ' ');
            nicer = this.tools.toTitleCase(nicer);
            table.headerrows[0].cells[i] = nicer;
          }
        }
      }
      else{
        if(table.headerrows.length==0 && table.datarows.length>2){
          if(table.datarows[0].cells.length<table.datarows[1].cells.length){
            table.datarows.splice(0,1);
            table.headerrows = table.datarows.splice(0,1);
          }
        }
      }
    }
  }
  checkForCSVWithHeader(table) {

  }


  genColumns(table: PastedTable) {
    //First add in prefixes
    if (table.datarows.length == 0) return;
    let previous = "";
    let previous2: string[] = [];
    //set the cell array to the right length;
    for (let y = 0; y < this.partialcsv; y++) {
      previous2.push("");
    }
    table.datarows.forEach(row => {
      if (row.cells[0] == "") row.cells[0] = previous;
      else previous = row.cells[0];
      if (this.partialcsv) {
        for (let x = 1; x < this.partialcsv + 1; x++) {
          if (row.cells[x] == "") row.cells[x] = previous2[x];
          else previous2[x] = row.cells[x];
        }
      }
    })
    if (this.partialcsv && table.datarows.length > 1 && table.datarows[0].cells[0] == "") {
      table.datarows[0].cells[0] = table.datarows[1].cells[0];
    }
    //then padding
    let cellrecord = [];

    table.datarows.forEach(row => {
      if (row.cells.length != row.rawcells.length && row.cells.length != table.headerrows[0].cells.length) {
        let index = 1;
        let previous = "";
        for (let x = index; x < row.rawcells.length; x++) {
          if (row.rawcells[x] == "") {
            row.cells.splice(index, 0, "");
          }
          index++;
        }

      }
      let exists = cellrecord.filter(cr=>cr.cellcount==row.cells.length);
      if(exists.length==1){
        exists[0].cellinstances++;
      }
      else{
        cellrecord.push({cellcount:row.cells.length,cellinstances:1});
      }
    })
    //final check
    if(cellrecord.length>1){
      if(cellrecord[0].cellcount==cellrecord[1].cellcount+1){
        table.datarows.forEach(dr=>{
          if(dr.cells.length==cellrecord[1].cellcount){
            dr.cells.unshift("");
          }
          if(cellrecord.length>2){
            if(dr.cells.length==cellrecord[2].cellcount){
              dr.cells.unshift("");
              dr.cells.push("");
            }
          }
        })
      }
    }
  }

  onFileDropped(e) {
    let file = e[0];

    const form: FormData = new FormData();

    let filename = this.data.auth.user.id.toString() + "_" + new Date().toISOString()
    form.append('file', file, filename);
    this.events.startSpinner();
    this.data.uploadPDF(form).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.data.parsePDF(message.message).subscribe((message: StatusMessage) => {
          this.events.stopSpinner();
          this.fireResults(message.message);
        }, err => {
          this.events.stopSpinner();
          this.tools.gracefulError("Conversion Error");
        })

      }
      else {
        this.events.stopSpinner();
        this.tools.gracefulError("Conversion Error");
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }
  handlePdf(files: any[]) {
    console.log("handle: ", files);
  }

  test() {
    this.data.testRegionClone().subscribe((message: StatusMessage) => {
      console.log(message);
    })
  }
}


