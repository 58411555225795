import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { SiteRegion } from 'src/app/models/ui';
import { TransportModes } from 'src/app/models/rates';

@Component({
  selector: 'cs-port-delivery',
  templateUrl: './port-delivery.component.html',
  styleUrls: ['./port-delivery.component.scss']
})
export class PortDeliveryComponent implements OnInit {

  @Input() siteregionform:FormGroup;
  @Input() delivery:boolean = false;
  @Input() mode:TransportModes;
  @Output() onchange = new EventEmitter<any>();

  public modes = TransportModes;

  //the static bits
  public siteregion:SiteRegion;

  public get collection():FormGroup{
    let array:FormArray = <FormArray> this.siteregionform.get("priorities");
    let index = this.siteregionform.get("selectedPriority").value;
    return <FormGroup> array.controls[index].get("collection");
  }

  public get surcharges():FormArray{
    return <FormArray> this.siteregionform.get("surcharges");
  }

  constructor() {
    
  }

  ngOnInit(): void {
    this.siteregion = this.siteregionform.value;
  }

  lineChange(){
    this.onchange.emit();
  }

}
