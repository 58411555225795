import { Injectable } from '@angular/core';
import { TransportModes, Region, Rate, Size, QuotationPrice, Margin, Discount, MarginAppliesTo, Surcharge, RegionTypes, SurchargeRelatesTo, SavedQuote, QuoteStatus, SavedQuoteInput, SavedQuoteOption, SavedQuoteLine, SavedQuoteSize, ZoneType, SavedQuoteServiceOption, SavedQuoteDestinationSite, SavedQuotePriority, QuoteLineAppliesTo, ModeMetric, RateBracket } from '../models/rates';
import { TransportHubTypes, CO2, CarbonBand, PreferredPorts, Quotation, QuoteInput, SiteRegion, Priority, Terms } from '../models/ui';
import { Company, SiteTypes, Site, StatusMessage, Address, CompanyAssociation } from '../models/user';
import { GoogleService } from './google.service';
import { GoogleDistanceMatrix, GoogleMatrixElement } from '../models/data';
import ExchangeRate, { ExchangeRates, PortToPort } from '../models/models';
import { ToolsService } from './tools.service';




@Injectable({
  providedIn: 'root'
})
export class CalculationsService {

  public serviceProvider: Company;
  public haversine = true;

  public exchangeratesByBase: ExchangeRates[] = [];

  public exchangerates: ExchangeRate[] = [];
  public defaultCurrency: number;

  modeicons = ["local_shipping","directions_boat","flight","rail"];

  constructor(public geo: GoogleService, public tools: ToolsService) { }

  /**
   * 
   * @param origin address
   * @param destination address
   * @param weight 
   * @param ports origin and destination info
   * @param consolidationsite destination port consolidation centre - the de-stuffing point if last leg is present on the service
   */
  calculateSteps(quote: Quotation, ports: PreferredPorts) {
    return new Promise((resolve, reject) => {
      let steps = this.originStep(quote.quoteInput.origin);
      let origsites;

      if (ports.originSites) {
        origsites = ports.originSites.filter(s => s.site.sitetypeid == SiteTypes.CONSOLIDATION_CENTRE);
      }
      this.consolidationCentre(quote.quoteInput.totalweight, steps, origsites).then((newsteps: ModeMetric[]) => {
        steps = newsteps;
        this.roadToPort(steps, ports, quote.quoteInput.totalweight).then((portsteps: ModeMetric[]) => {
          steps = portsteps;
          this.PortToPort(steps, ports, quote.quoteInput.totalweight).then((seasteps: ModeMetric[]) => {
            if (ports.destinationSites && ports.destinationSites.length > 0) {

              let dest = ports.destinationSites[ports.destinationSiteIndex];
              if (dest.site.sitetypeid == SiteTypes.CONSOLIDATION_CENTRE) {
                this.portToConsolidation(seasteps, quote.quoteInput.totalweight, dest.site).then((destportsteps: ModeMetric[]) => {
                  this.destinationStep(destportsteps, ports, quote.quoteInput).then((finalsteps: ModeMetric[]) => {
                    steps = finalsteps;
                    ports.steps = steps;
                    ports.metrics = new ModeMetric();
                    ports.steps.forEach(step => {
                      ports.metrics.duration += step.duration;
                      ports.metrics.km += step.km;
                      ports.metrics.co2 += step.co2;
                    })
                    /*
                    ports.steps.forEach(s => {
                      if (s.km) {
                        ports. += this.tools.cleanFloat(s.km);
                        steps.total.co2 += this.tools.cleanFloat(s.co2.co2);
                        steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                      }
                      else if (s.cost) {
                        steps.total.cost += this.tools.cleanFloat(s.cost);
                        steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                      }
                    })
                    ports.steps.total.cost = ports.total.discountedprice;*/
                    resolve(steps);
                  })

                })

              }
              else {
                steps = seasteps;
                resolve(steps);
              }
            }
            else {
              steps = seasteps;
              this.destinationStep(seasteps, ports, quote.quoteInput).then((finalsteps: ModeMetric[]) => {
                steps = finalsteps;
                ports.steps = steps;
                /*
                ports.steps.steps.forEach(s => {
                  if (s.km) {
                    steps.total.km += this.tools.cleanFloat(s.km);
                    steps.total.co2 += this.tools.cleanFloat(s.co2.co2);
                    steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                  }
                  else if (s.cost) {
                    steps.total.cost += this.tools.cleanFloat(s.cost);
                    steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                  }
                })
                ports.steps.total.cost = ports.total.discountedprice;*/

                resolve(steps);
              })
            }

          })
        })


      }, err => reject(err));
    })



  }
  calculateSteps2(ports: PreferredPorts, quote: Quotation) {
    return new Promise((resolve) => {
      let steps = this.originStep(quote.quoteInput.origin);
      //steps.service = ports.service; 
      this.addOriginConsolidation(ports, steps).then((steps: ModeMetric[]) => {
        this.consolidationToPort(steps, ports, quote.quoteInput.totalweight).then((steps: ModeMetric[]) => {
          this.PortToPort(steps, ports, quote.quoteInput.totalweight).then((steps: ModeMetric[]) => {
            this.addDestinationConsolidation(ports, steps).then((steps: ModeMetric[]) => {
              this.destinationStep(steps, ports, quote.quoteInput).then((steps: ModeMetric[]) => {
                ports.steps = steps;
                /*
                ports.steps.steps.forEach(s => {
                  if (s.km) {
                    steps.total.km += this.tools.cleanFloat(s.km);
                    steps.total.co2 += this.tools.cleanFloat(s.co2.co2);
                    steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                  }
                  else if (s.cost) {
                    steps.total.cost += this.tools.cleanFloat(s.cost);
                    steps.total.duration += this.tools.cleanFloat(s.duration | 0);
                  }
                })
                ports.steps.total.cost = ports.total.discountedprice;
                */
                resolve(steps);
              })
            })
          })


        })
      })
    })
  }
  /**
   * create the first waypoint and origin step
   * @param origin
   * sync no db
   */
  originStep(origin: Address): ModeMetric[] {
    let metrics: ModeMetric[] = [];
    let originmetric = new ModeMetric();
    originmetric.index = 0;
    originmetric.gpslat = origin.gpslat;
    originmetric.gpslong = origin.gpslong;
    originmetric.name = "origin";
    originmetric.hubtype = TransportHubTypes.ORIGIN;
    originmetric.icon = "trip_origin";
    originmetric.ishub = true;
    metrics.push(originmetric);
    return metrics;
  }

  /**check for a consolidation centre for CO2 calculation purposes */
  addOriginConsolidation(ports: PreferredPorts, steps: ModeMetric[]) {
    return new Promise((resolve) => {
      if (ports.originSites) {
        let originsite = ports.originSites[ports.originSiteIndex];
        if (originsite.site.sitetypeid == SiteTypes.CONSOLIDATION_CENTRE) {

        }
        resolve(steps);
      }
      else resolve(steps);
    });
  }
  addDestinationConsolidation(ports: PreferredPorts, steps: ModeMetric[]) {
    return new Promise((resolve) => {
      if (ports.destinationSites) {
        resolve(steps);
      }
      else resolve(steps);
    });
  }
  /**
   * given an origin return the nearest consolidation centre 
   * @param weight 
   * @param transportSteps
   * async promise, call to google driving distance 
   */
  consolidationCentre(weight: number, transportSteps: ModeMetric[], sites: SiteRegion[]) {
    return new Promise((resolve) => {
      if (sites && sites.length > 0) {
        //find nearest by driving distance
        this.getNearestSite(sites, transportSteps[0].latlng).then((element: GoogleMatrixElement) => {
          transportSteps.push(this.addRoadMetric(element, weight, TransportModes.Local, "local_shipping", 27.56));
          let metric = new ModeMetric();
          metric.index = 1;
          metric.icon = "home_work";
          metric.hubtype = TransportHubTypes.CONSOLIDATION;
          metric.ishub = true;
          let site = sites[element.index].site;
          metric.name = site.name;
          let chosensiteaddress = site.Address;
          metric.gpslat = chosensiteaddress.gpslat;
          metric.gpslong = chosensiteaddress.gpslong;
          transportSteps.push(metric);
          resolve(transportSteps);
        })


      }
      else resolve(transportSteps);

    })
  }
  getNearestSite(sites: SiteRegion[], from: google.maps.LatLng) {
    return new Promise((resolve, reject) => {
      let sitegpss: google.maps.LatLng[] = [];
      sites.forEach(site => {
        let sitegps = new google.maps.LatLng(site.site.Address.gpslat, site.site.Address.gpslong);
        sitegpss.push(sitegps);
      });
      this.geo.drivingDistance([from], sitegpss, true).then((message: StatusMessage) => {
        if (message.success) {
          let matrix: GoogleDistanceMatrix = message.message.result;
          let element = this.geo.nearestElement(matrix);
          resolve(element);
        }
        else {
          reject("failed to get driving distance");
        }
      }, err => {
        reject("failed to get driving distance: " + err);
      })
    })
  }
  /**
   * add waypoint and co2 for consolidation to origin port
   * return promise of type transport steps
   * will use origin address to port if no consolidation centre
   * @param steps 
   * @param service 
   */
  consolidationToPort(steps: ModeMetric[], ports: PreferredPorts, weight: number) {
    return new Promise((resolve) => {
      let waypoint = steps[steps.length - 1];
      //use the prefered port
      let originlocode = ports.origin;
      let originlocodegps = new google.maps.LatLng(originlocode.geoLat, originlocode.geoLong);
      let cost = 0;
      if (ports.originSites && ports.originSites.length > 0) {
        let os = ports.originSites[ports.originSiteIndex];
        if (os.hasCollection) {
          cost += os.priorities[os.selectedPriority].collection.discountedprice;
        }

        this.geo.drivingDistance([waypoint.latlng], [originlocodegps], this.haversine).then((message: StatusMessage) => {
          if (message.success) {
            let matrix: GoogleDistanceMatrix = message.message.result;
            let element = matrix.rows[0].elements[0];
            steps.push(this.addRoadMetric(element, weight, TransportModes.Road, "local_shipping", cost));

            let metric = new ModeMetric();
            metric.index = 2;

            if (ports.mode == TransportModes.Shipping) {
              metric.icon = "anchor";
              metric.hubtype = TransportHubTypes.PORT;
            }
            else if (ports.mode == TransportModes.Air) {
              metric.icon = "flight_takeoff";
              metric.hubtype = TransportHubTypes.AIRPORT;
            }
            metric.ishub = true;
            metric.name = os.site.name;

            if (os.hasSurcharges) {
              let portcost = 0;
              os.surcharges.forEach(surch => {
                portcost += surch.discountedprice;
              })
              metric.cost = portcost;
            }
            this.setLatLng(metric, originlocodegps);
            steps.push(metric);
          }
          resolve(steps);
        })
      }
      else resolve(steps);

    })
  }
  destinationStep(steps: ModeMetric[], ports: PreferredPorts, quoteInput: QuoteInput) {
    return new Promise((resolve) => {
      let waypoint = steps[steps.length - 1];

      let cost = 0;
      if (ports.destinationSites && ports.destinationSites.length > 0) {
        let ds = ports.destinationSites[ports.destinationSiteIndex];
        if (ds.hasCollection) {
          if (ds.priorities && ds.priorities.length > 0) {
            cost += ds.priorities[ds.selectedPriority].collection.discountedprice;
          }

        }

        if (ds.hasSurcharges) {
          ds.surcharges.forEach(surch => {
            cost += surch.discountedprice;
          })
        }
        let destinationgps = new google.maps.LatLng(quoteInput.destination.gpslat, quoteInput.destination.gpslong);
        this.geo.drivingDistance([waypoint.latlng], [destinationgps], this.haversine).then((message: StatusMessage) => {
          if (message.success) {
            let matrix: GoogleDistanceMatrix = message.message.result;
            let element = matrix.rows[0].elements[0];
            steps.push(this.addRoadMetric(element, quoteInput.totalweight, TransportModes.Local, "local_shipping", cost));
            let metric = new ModeMetric();
            metric.icon = "trip_origin";
            metric.hubtype = TransportHubTypes.DESTINATION;
            metric.ishub = true;
            this.setLatLng(metric, destinationgps);
            if (ports.quotesurcharges) {
              ports.quotesurcharges.forEach(qs => {
                metric.cost += qs.discountedprice;
              })
              if (ports.balance) {
                metric.cost += ports.balance.discountedprice;
              }
            }
            steps.push(metric);
          }
          resolve(steps);
        })
      }
      else resolve(steps);
    });
  }
  roadToPort(steps: ModeMetric[], ports: PreferredPorts, weight) {
    return new Promise((resolve) => {
      let waypoint = steps[steps.length - 1];
      //use the prefered port
      let originlocode = ports.origin;
      let originlocodegps = new google.maps.LatLng(originlocode.geoLat, originlocode.geoLong);
      this.geo.drivingDistance([waypoint.latlng], [originlocodegps], this.haversine).then((message: StatusMessage) => {
        if (message.success) {
          let matrix: GoogleDistanceMatrix = message.message.result;
          let element = matrix.rows[0].elements[0];
          let origin2portcost = 0;
          if (ports.originSites && ports.originSites.length > 0) {
            ports.originSites.some(os => {
              if (os.hasCollection) {
                if (os.priorities && os.priorities.length > 0) {
                  let priority = os.priorities[os.selectedPriority];
                  if (priority && priority.collection) {
                    origin2portcost = priority.collection.listprice;
                  }
                }
              }
            })
          }
          steps.push(this.addRoadMetric(element, weight, TransportModes.Road, "local_shipping", origin2portcost));
          let metric = new ModeMetric();
          metric.index = 2;
          if (ports.mode == TransportModes.Shipping) {
            metric.icon = "anchor";
            metric.hubtype = TransportHubTypes.PORT;
          }
          else if (ports.mode == TransportModes.Air) {
            metric.icon = "flight_takeoff";
            metric.hubtype = TransportHubTypes.AIRPORT;
          }
          metric.ishub = true;
          if (ports.originSites && ports.originSites.length > ports.originSiteIndex) {
            let port = ports.originSites[ports.originSiteIndex];
            port.surcharges.forEach(surch => {
              if (surch.include) metric.cost += surch.discountedprice;
            })
          }

          this.setLatLng(metric, originlocodegps);
          metric.name = originlocode.name;
          steps.push(metric);
        }
        resolve(steps);
      })

    })
  }
  /**
   * add waypoint and co2 for consolidation to destination port
   * @param steps 
   * @param service
   * return promise of type transport steps
   */
  portToConsolidation(steps: ModeMetric[], weight: number, consolidationsite: Site) {
    return new Promise((resolve) => {
      //this is the destination port
      let waypoint = steps[steps.length - 1];
      let sitegps = new google.maps.LatLng(consolidationsite.Address.gpslat, consolidationsite.Address.gpslong);
      this.geo.drivingDistance([waypoint.latlng], [sitegps], this.haversine).then((message: StatusMessage) => {
        if (message.success) {
          let matrix: GoogleDistanceMatrix = message.message.result;
          let element = matrix.rows[0].elements[0];
          steps.push(this.addRoadMetric(element, weight, TransportModes.Road, "local_shipping", 0));
          let metric = new ModeMetric();
          metric.icon = "home_work";
          metric.hubtype = TransportHubTypes.CONSOLIDATION;
          metric.ishub = true;
          metric.name = consolidationsite.name;
          this.setLatLng(metric, sitegps);
          steps.push(metric);
        }
        resolve(steps);
      })


    })
  }
  /**
 * add waypoint and co2 for port to port
 * @param steps 
 * @param service 
 */
  PortToPort(steps: ModeMetric[], ports: PreferredPorts, weight: number) {
    return new Promise((resolve) => {
      let stagecost = 0;
      if (ports.servicerates) {
        ports.servicerates.forEach(sr => {
          stagecost += sr.discountedprice;
        })
      }
      if (ports.servicesurcharges) {
        ports.servicesurcharges.forEach(ss => {
          stagecost += ss.discountedprice;
        })
      }
      steps.push(this.addModeMetric(ports.port2port.duration, ports.port2port.metres / 1000, weight, ports.mode, this.modeicons[ports.mode], stagecost));

      let metric = new ModeMetric();
      metric.name = ports.destination.nodiacritic;
      metric.gpslat = ports.destination.geoLat;
      metric.gpslong = ports.destination.geoLong;
      switch (ports.mode) {
        case TransportModes.Shipping:
          metric.hubtype = TransportHubTypes.PORT;
          metric.icon = "anchor";
          break;
        case TransportModes.Air:
          metric.hubtype = TransportHubTypes.AIRPORT;
          metric.icon = "flight_takeoff";
          break;
      }
      metric.ishub = true;
      steps.push(metric);
      resolve(steps);
    })
  }

  /**
   * build a metric
   * @param googleElement 
   * @param weight 
   * @param mode 
   * @param icon
   * sync no db 
   */
  addRoadMetric(googleElement: GoogleMatrixElement, weight: number, mode: TransportModes, icon: string, cost: number): ModeMetric {
    return this.addModeMetric(googleElement.duration.value, googleElement.distance.value / 1000, weight, mode, icon, cost);
  }
  /**
   * build a metric with duration and distance
   * @param duration 
   * @param distance 
   * @param weight 
   * @param mode 
   * @param icon 
   * sync no db
   */
  addModeMetric(duration: number, distance: number, weight: number, mode: TransportModes, icon: string, cost: number): ModeMetric {
    let met = new ModeMetric();
    met.icon = icon;
    met.km = distance;
    let co2 = this.co2(weight, mode, distance);
    met.co2 = this.tools.cleanRound(co2.co2 * met.km / 1000, 2);
    met.co2band = co2.band;
    met.duration = this.tools.cleanRound(duration, 2);
    met.mode = mode;
    met.cost = cost;
    return met;
  }

  /**
   * map the UI Quotation to a db version including the unselected options.
   * this doesn't feel ideal, but the ui has different requirements to the db version.
   * @param quote 
   */
  mapSavedQuote(quote: Quotation, companyid: number, description: string, userid: number): SavedQuote {
    let saved = new SavedQuote();
    saved.companyid = companyid;
    saved.customerid = quote.quoteInput.customerid;
    saved.description = description;
    saved.userid = userid;
    saved.currency = quote.currency;
    saved.quotestatus = QuoteStatus.Draft;
    saved.selectedoptionid = quote.selectedOption;


    saved.QuoteInput = new SavedQuoteInput();
    saved.QuoteInput.Destination = quote.quoteInput.destination;
    saved.QuoteInput.Origin = quote.quoteInput.origin;
    saved.QuoteInput.Options = [];

    saved.QuoteInput.totalarea = quote.quoteInput.totalarea;
    saved.QuoteInput.totalquantity = quote.quoteInput.totalquantity;
    saved.QuoteInput.totalvolume = quote.quoteInput.totalvolume;
    saved.QuoteInput.totalweight = quote.quoteInput.totalweight;
    saved.QuoteInput.servicedate = quote.quoteInput.servicedate;
    saved.QuoteInput.expires = quote.quoteInput.expires;
    saved.QuoteInput.sortarray = quote.quoteInput.sortarray;
    let serviceoptionindex = 0;
    quote.portoptions.forEach(po => {
      let spo = new SavedQuoteServiceOption();
      spo.destinationid = po.destination.id;
      spo.originid = po.origin.id;
      spo.destinationsiteindex = po.destinationSiteIndex;
      spo.originsiteindex = po.originSiteIndex;
      spo.mode = po.mode;
      spo.porttoportid = po.port2port.id;
      spo.providerid = po.service.providerid;
      spo.serviceid = po.service.id;
      spo.vesselid = po.vessel.id;
      spo.Vessel = po.vessel;
      spo.costprice = po.total.costprice;
      spo.listprice = po.total.listprice;
      spo.discountedprice = po.total.discountedprice;
      spo.margin = po.total.margin;
      po.steps.forEach(step => {
        spo.distance += step.km;
        spo.co2 += step.co2;
        spo.duration += step.duration;
      })
      spo.selected = saved.selectedoptionid == serviceoptionindex;

      spo.Service = po.service;

      spo.Origin = po.origin;
      spo.Destination = po.destination;

      spo.DestinationSites = [];
      let destinationsiteindex = 0;
      if (po.destinationSites) {
        po.destinationSites.forEach(ds => {
          let eachds = this.mapDestinationSite(ds, QuoteLineAppliesTo.DestinationPort);
          eachds.selected = po.destinationSiteIndex == destinationsiteindex;
          spo.DestinationSites.push(eachds);
          destinationsiteindex++;

        })
      }

      spo.OriginSites = [];
      let originsiteindex = 0;
      if (po.originSites) {
        po.originSites.forEach(os => {
          let eachos = this.mapDestinationSite(os, QuoteLineAppliesTo.OriginPort);
          eachos.selected = po.originSiteIndex == originsiteindex;
          spo.OriginSites.push(eachos);
          originsiteindex++;
        })
      }
      else {
        console.log("No origin sites for portoption - " + serviceoptionindex);
      }

      spo.QuoteSurcharges = [];
      po.quotesurcharges.forEach(qs => {
        spo.QuoteSurcharges.push(this.mapChargeToSavedQuoteLine(qs, QuoteLineAppliesTo.Quote));
      })
      spo.QuoteSurcharges.push(this.mapChargeToSavedQuoteLine(po.balance, QuoteLineAppliesTo.Balance))
      spo.ServiceRates = [];
      if (po.servicerates) {
        po.servicerates.forEach(sr => {
          spo.ServiceRates.push(this.mapChargeToSavedQuoteLine(sr, QuoteLineAppliesTo.Service));
        })
      }

      spo.ServiceSurcharges = [];
      if (po.servicesurcharges) {
        po.servicesurcharges.forEach(ss => {
          spo.ServiceSurcharges.push(this.mapChargeToSavedQuoteLine(ss, QuoteLineAppliesTo.ServiceSurcharge));
        })
      }


      spo.PortToPort = po.port2port;


      spo.Steps = po.steps;
      saved.ServiceOptions.push(spo);
      serviceoptionindex++;
    })

    quote.quoteInput.quoteOptions.forEach(qo => {
      if (qo.selected) {
        let savedOption = new SavedQuoteOption();
        savedOption.optionid = qo.id;
        savedOption.value = qo.selected;
        saved.QuoteInput.Options.push(savedOption);
      }
    })
    quote.quoteInput.quoteItems.forEach(qi => {

      let size = new SavedQuoteSize();

      size.depth = qi.depth;
      size.height = qi.height;
      size.quantity = qi.quantity;
      size.ratebracket = qi.ratebracket;
      size.weight = qi.weight;
      size.width = qi.width;
      size.stackable = qi.stackable;
      saved.QuoteInput.Items.push(size);
    })
    return saved;
  }

  mapDestinationSite(ds: SiteRegion, appliesto: QuoteLineAppliesTo): SavedQuoteDestinationSite {
    let sds = new SavedQuoteDestinationSite();
    sds.include = ds.include;
    sds.hascollection = ds.hasCollection;
    sds.hassurcharges = ds.hasSurcharges;
    if (ds.rateRegion) sds.rateregionid = ds.rateRegion.id;
    sds.selectedpriority = ds.selectedPriority;
    sds.siteid = ds.site.id;
    sds.Site = ds.site;
    sds.totalcollection = ds.totalCollection;
    sds.isorigin = appliesto == QuoteLineAppliesTo.OriginPort;
    sds.Priorities = [];
    let priorityindex = 0;
    ds.priorities.forEach(p => {
      let sp = new SavedQuotePriority();
      sp.priorityid = p.id;
      sp.costprice = p.collection.costprice;
      sp.listprice = p.collection.listprice;
      sp.discountedprice = p.collection.discountedprice;
      sp.margin = p.collection.margin;
      sp.selected = priorityindex == ds.selectedPriority;
      sds.Priorities.push(sp);
    })
    sds.Surcharges = [];
    ds.surcharges.forEach(sc => {
      sds.Surcharges.push(this.mapChargeToSavedQuoteLine(sc, appliesto));
    })
    return sds;
  }


  mapChargeToSavedQuoteLine(sc: QuotationPrice, appliesto: QuoteLineAppliesTo): SavedQuoteLine {
    let qs = new SavedQuoteLine();
    qs.costprice = sc.costprice;
    qs.description = sc.description;
    qs.discountedprice = sc.discountedprice;
    qs.include = sc.include;
    qs.listprice = sc.listprice;
    qs.margin = sc.margin;
    qs.appliesto = appliesto;
    qs.exchanged = sc.exchanged;
    qs.preexchanged = sc.preexchange;
    return qs;
  }

  unmap(saved: SavedQuote): Quotation {
    let unmapped = new Quotation();
    unmapped.id = saved.id;
    unmapped.currency = saved.currency;
    unmapped.selectedOption = saved.selectedoptionid;
    unmapped.quoteInput = this.unmapQuoteInput(saved.QuoteInput, saved.customerid);
    unmapped.portoptions = [];
    unmapped.pointtopoint = saved.pointtopoint;
    saved.ServiceOptions.forEach((so) => {
      unmapped.portoptions.push(this.unmapServiceOption(so));
    })
    this.flagTheBest(unmapped);
    return unmapped;
  }
  unmapQuoteInput(saved: SavedQuoteInput, customerid: number): QuoteInput {
    let qi = new QuoteInput();
    qi.id = saved.id;
    qi.customerid = customerid;
    qi.destination = saved.Destination;
    //qi.destinationWaypoint = new Waypoint();
    //qi.destinationWaypoint.name = "destination";
    //qi.destinationWaypoint.gps = new google.maps.LatLng(qi.destination.gpslat,qi.destination.gpslong);
    qi.destinationSite = saved.DestinationSite;
    qi.origin = saved.Origin;
    qi.originSite = saved.OriginSite;
    //qi.originWaypoint = new Waypoint();
    //qi.originWaypoint.name = "origin";
    //qi.originWaypoint.gps = new google.maps.LatLng(qi.origin.gpslat,qi.origin.gpslong);
    qi.quoteItems = [];
    saved.Items.forEach(item => {
      qi.quoteItems.push(this.unmapQuoteSize(item));
    })
    if (saved.Options.length > 0) {
      saved.Options.forEach(option => {

        let filtered = qi.quoteOptions.filter(qo => qo.id == option.optionid);
        if (filtered.length == 1) filtered[0].selected = option.value;
        else {
          console.log("Failed to unmap optionid: ", option.optionid);
        }
      })
    }



    qi.servicedate = saved.servicedate;
    qi.expires = saved.expires;
    qi.totalarea = saved.totalarea;
    qi.totalquantity = saved.totalquantity;
    qi.totalvolume = saved.totalvolume;
    qi.totalweight = saved.totalweight;
    qi.sortarray = saved.sortarray;
    return qi;
  }
  unmapQuoteSize(saved: SavedQuoteSize): Size {
    let qs = new Size();
    qs.id = saved.id;
    qs.depth = saved.depth;
    qs.height = saved.height;
    qs.quantity = saved.quantity;
    qs.ratebracket = saved.ratebracket;
    qs.recordStatus = saved.recordStatus;
    qs.stackable = saved.stackable;
    qs.weight = saved.weight;
    qs.width = saved.width;
    return qs;

  }
  unmapServiceOption(saved: SavedQuoteServiceOption): PreferredPorts {
    let portoption = new PreferredPorts();
    portoption.id = saved.id;

    portoption.mode = saved.mode;
    portoption.vessel = saved.Vessel;
    portoption.service = saved.Service;

    portoption.origin = saved.Origin;
    portoption.originSites = [];
    saved.OriginSites.forEach(o => {
      portoption.originSites.push(this.unmapDestinationSite(o, true));
    });
    portoption.originSiteIndex = saved.originsiteindex;

    portoption.destination = saved.Destination;
    portoption.destinationSites = [];
    saved.DestinationSites.forEach(d => {
      portoption.destinationSites.push(this.unmapDestinationSite(d, false));
    })
    portoption.destinationSiteIndex = saved.destinationsiteindex;

    portoption.port2port = saved.PortToPort;

    portoption.servicerates = [];
    saved.ServiceRates.forEach(sr => {
      portoption.servicerates.push(this.unmapSavedQuoteLine(sr));
    })
    portoption.servicesurcharges = [];
    saved.ServiceSurcharges.forEach(ss => {
      portoption.servicesurcharges.push(this.unmapSavedQuoteLine(ss));
    })
    let fauxAssoc = new CompanyAssociation();
    fauxAssoc.Provider = saved.Provider;
    portoption.provider = fauxAssoc;

    portoption.total = new QuotationPrice();
    portoption.total.appliesto = QuoteLineAppliesTo.Total;
    portoption.total.costprice = saved.costprice;
    portoption.total.description = "Total";
    portoption.total.discountedprice = saved.discountedprice;
    portoption.total.listprice = saved.listprice;
    portoption.total.margin = saved.margin;

    portoption.quotesurcharges = [];
    saved.QuoteSurcharges.forEach(qs => {
      if (qs.appliesto == QuoteLineAppliesTo.Balance) {
        portoption.balance = this.unmapSavedQuoteLine(qs);
      }
      else {
        portoption.quotesurcharges.push(this.unmapSavedQuoteLine(qs));
      }
    })
    portoption.PointToPoints = saved.PointToPoints;
    portoption.steps = saved.Steps;
    portoption.metrics = new ModeMetric();
    portoption.metrics.duration = saved.duration;
    portoption.metrics.co2 = saved.co2;
    portoption.metrics.km = saved.distance;
    this.terms(portoption);
    return portoption;
  }
  unmapSavedQuoteLine(ql: SavedQuoteLine): QuotationPrice {
    let qp = new QuotationPrice();
    qp.appliesto = ql.appliesto;
    qp.costprice = ql.costprice;
    qp.description = ql.description;
    qp.discountedprice = ql.discountedprice;
    qp.exchanged = ql.exchanged;
    qp.id = ql.id;
    qp.include = ql.include;
    qp.listprice = ql.listprice;
    qp.margin = ql.margin;
    qp.preexchange = ql.preexchanged;
    return qp;
  }

  unmapDestinationSite(ds: SavedQuoteDestinationSite, isorigin: boolean): SiteRegion {
    let sr = new SiteRegion();
    sr.hasCollection = ds.hascollection;
    sr.hasSurcharges = ds.hassurcharges;
    sr.include = ds.include;
    sr.priorities = [];
    sr.site = ds.Site;
    ds.Priorities.forEach(pr => {
      sr.priorities.push(this.unmapPriorities(pr, isorigin ? QuoteLineAppliesTo.Collection : QuoteLineAppliesTo.Destination))
    })
    sr.surcharges = [];
    ds.Surcharges.forEach(surch => {
      sr.surcharges.push(this.unmapSavedQuoteLine(surch));
    })

    return sr;
  }
  unmapPriorities(saved: SavedQuotePriority, appliesto: QuoteLineAppliesTo): Priority {
    let prior = new Priority();
    prior.id = saved.id;
    prior.collection = new QuotationPrice();
    prior.collection.appliesto = appliesto;
    if (saved.Priority) prior.name = saved.Priority.name;
    prior.collection.discountedprice = saved.discountedprice;
    prior.collection.exchanged = saved.exhanged;
    prior.collection.include = saved.include;
    prior.collection.listprice = saved.listprice;
    prior.collection.margin = saved.margin;
    prior.collection.preexchange = saved.preexchanged;
    return prior;
  }

  flagTheBest(quote: Quotation) {


    let index = 0;
    let lowestcost = Infinity;
    let lowestcarbon = Infinity;
    let lowestfull = Infinity;
    let quickest = Infinity;

    let costindex = 0;
    let carbonindex = 0;
    let fullindex = 0;
    let quickindex = 0;

    if (!quote.quoteInput.sortarray) quote.quoteInput.sortarray = [1, 2, 3];

    //sort first
    quote.portoptions.forEach(pp => {
      this.cleanRound(pp.total);
      if (pp.metrics.duration) {
        pp.metrics.duration = parseFloat(pp.metrics.duration.toString());
      }
      if (pp.metrics.co2) {
        pp.metrics.co2 = parseFloat(pp.metrics.co2.toString());
      }
      else pp.metrics.co2 = 0;

      let sortmetrics = [{ id: 1, value: pp.total.discountedprice }, { id: 2, value: pp.metrics.duration }, { id: 3, value: pp.metrics.co2 }];
      pp._sortmetrics = [];
      pp._sortmetrics.push(sortmetrics.filter(sm => sm.id == quote.quoteInput.sortarray[0])[0].value);
      pp._sortmetrics.push(sortmetrics.filter(sm => sm.id == quote.quoteInput.sortarray[1])[0].value);
      pp._sortmetrics.push(sortmetrics.filter(sm => sm.id == quote.quoteInput.sortarray[2])[0].value);
    })

    //then flag best
    quote.portoptions.sort((a, b) => a._sortmetrics[0] - b._sortmetrics[0] || a._sortmetrics[1] - b._sortmetrics[1] || a._sortmetrics[2] - b._sortmetrics[2]);

    quote.portoptions.forEach(pp => {

      if (pp.total.discountedprice < lowestcost) {
        costindex = index;
        lowestcost = pp.total.discountedprice;
      }
      if (pp.metrics.co2 < lowestcarbon) {
        carbonindex = index;
        lowestcarbon = pp.metrics.co2;
      }

      if (pp._terms == Terms.DoorToDoor) {
        if (pp.total.discountedprice < lowestfull) {
          fullindex = index;
          lowestfull = pp.total.discountedprice;
        }
      }
      if (pp.metrics.duration < quickest) {
        quickindex = index;
        quickest = pp.metrics.duration;
      }
      index++;
    })
    if (quote.portoptions.length > 0) {
      quote.portoptions[costindex].cheapest = true;
      quote.portoptions[carbonindex].greenest = true;
      if (lowestfull < Infinity) {
        quote.portoptions[fullindex].cheapestfull = true;
      }

      quote.portoptions[quickindex].fastest = true;
    }




  }

  /**
   * convert quote to export format
   * @param quote 
   * @param companyid 
   */
  mapForExport(quote: Quotation, companyid: number, description: string, userid: number) {
    return this.mapSavedQuote(quote, companyid, description, userid);
  }

  jsonToXML(obj: any) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += this.jsonToXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += this.jsonToXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
  }

  /**
   * given a weight and a transport mode, return the CO2 per km
   * sources: https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/4256/regulated.xls
   * Carbon Emission Factor – 1kg of Jet fuel translates into 3.15kg of CO2
   * source https://www.airportwatch.org.uk/air-freight/carbon-emissions-of-air-freight-compared-to-other-modes-of-transport/
   * @param weight 
   * @param mode 
   */
  co2(weight: number, mode: TransportModes, distance: number): CO2 {

    let co2 = new CO2();
    if (mode == TransportModes.Local) {
      if (weight < 1000) {
        //light van 200g/km
        co2.co2 = weight / 2000 * 200;
        co2.band = CarbonBand.AVERAGE;
        return co2;
      }
      else if (weight < 7500) {
        //7.5 tonne
        co2.co2 = weight / 7500 * 327;
        co2.band = CarbonBand.AVERAGE;
        return co2;
      }
      else {
        //hgv, class VI 740 g per km collection by hgv
        co2.co2 = weight / 28000 * 740;
        co2.band = CarbonBand.LOWERAVERAGE;
        return co2;
      }
    }
    else if (mode == TransportModes.Road) {
      //hgv, class VI 740 g per km post groupage
      co2.co2 = weight / 28000 * 740;
      co2.band = CarbonBand.LOWERAVERAGE;
      return co2;
    }
    else if (mode == TransportModes.Shipping) {
      //vlcv
      co2.co2 = weight / 1000 * 3;
      co2.band = CarbonBand.VERYLOW;
      return co2;
    }
    else if (mode == TransportModes.Air) {

      co2.band = CarbonBand.HIGH;
      if (distance < 1000) {
        co2.co2 = weight * 1.898 * 1.09;
      }
      else if (distance < 3700) {
        co2.co2 = weight * 1.316 * 1.09;
      }
      else {
        co2.co2 = weight * 0.606 * 1.09;
      }
      co2.co2 = this.tools.cleanRound(co2.co2, 2);
      return co2;
    }
  }
  public knots = 13; //= 6.69m/s  conversion = 1m/2 = 1.9438444924406knots 1knot  = 0.51444444444444 m/s
  public airmph = 878;
  /**
   * call api to calculate distance and duration of the journey between two ports
   * @param ports 
   */
  calculateSeaRoute(ports: PreferredPorts) {
    //call api or create one
    return new Promise((resolve) => {
      let pp = new PortToPort();
      pp.originlocodeid = ports.origin.id;
      pp.destinationlocodeid = ports.destination.id;
      pp.transportmode = ports.mode;
      if (ports.mode == TransportModes.Shipping) {
        let origingps = new google.maps.LatLng(ports.origin.geoLat, ports.origin.geoLong);
        let destinationgps = new google.maps.LatLng(ports.destination.geoLat, ports.destination.geoLong);
        pp.metres = Math.round(this.geo.haversineDifference(origingps, destinationgps) * 1000 * 2);
        console.log("Estimation of shipping distance");
        pp.duration = ports.service.transitTime * 24 * 60 * 60;

      }
      else if (ports.mode == TransportModes.Air) {
        let origingps = new google.maps.LatLng(ports.origin.geoLat, ports.origin.geoLong);
        let destinationgps = new google.maps.LatLng(ports.destination.geoLat, ports.destination.geoLong);
        pp.metres = Math.round(this.geo.haversineDifference(origingps, destinationgps) * 1000);
        pp.duration = ports.service.transitTime * 24 * 60 * 60;

      }

      pp.recordStatus = 0;
      resolve(pp);
    })
  }


  flattenRegions(regions: Region[]): Region[] {
    let lowest: Region[] = [];
    regions.forEach(r => {
      this.getLowestRegionDescendents(r, lowest);
    })
    return lowest;
  }
  getLowestRegionDescendents(region: Region, result: Region[]): Region[] {

    if (!region.children || region.children.length == 0) {
      result.push(region);
      return result;
    }
    else {
      region.children.forEach(ch => {
        result = this.getLowestRegionDescendents(ch, result);
      })
      return result;
    }
  }

  findRateRegion(regions: Region[], address: Address, portgps: google.maps.LatLng) {
    return new Promise((resolve) => {
      let distance = false;
      regions.some(r => {
        if (r.RegionDistancer) {
          distance = true;
          return true;
        }
      })
      if (distance) {
        this.getRegionMatchingDistance(regions, address, portgps).then((message: StatusMessage) => {
          resolve(message);
        })
      }
      else {
        let region = this.getRegionMatchingAddress(regions, address);
        if (region) {
          resolve({ success: true, message: region });
        }
        else resolve({ success: false, message: "no rates for this region set" });
      }
    })

  }
  getRegionMatchingDistance(regions: Region[], address: Address, portgps: google.maps.LatLng) {
    return new Promise((resolve) => {
      let km = 0;
      let gps = new google.maps.LatLng(address.gpslat, address.gpslong);
      this.geo.drivingDistance([portgps], [gps], false).then((message: StatusMessage) => {
        if (message.success) {
          let element = this.geo.nearestElement(message.message.result);
          km = element.distance.value / 1000;
        }
        else {
          km = this.geo.haversineDifference(gps, portgps);
        }
        let matched: Region;
        regions.some(r => {
          if (r.RegionDistancer) {
            if (r.RegionDistancer.minkm < km && r.RegionDistancer.maxkm > km) {
              matched = r;
            }
          }
        })
        if (matched) {
          resolve({ success: true, message: matched });
        }
        else resolve({ success: false, message: "No matches" });
      })

    })

  }
  getRegionMatchingAddress(regions: Region[], address: Address) {
    let sorted = regions.sort((a, b) => {
      if (a.regiontypeid > b.regiontypeid) return -1;
      else return 1;
    })
    let region: Region;
    sorted.some((r) => {
      if (r.countryid == address.countryid) {
        if (r.regiontypeid == RegionTypes.PostCode) {
          if (address.postcode.toLowerCase().indexOf(r.googlename.toLowerCase()) > 0) {
            region = r;
            return true;
          }
        }
        if (r.regiontypeid == RegionTypes.Local) {

        }
        if (r.regiontypeid == RegionTypes.State) {
          if (address.state.toLowerCase() == r.googlename.toLowerCase()) {
            region = r;
            return true;
          }
        }
      }
      else {

      }
    })
    return region;
  }
  getRateParent(siteregion: SiteRegion): Region {
    //TODO logic for this.
    if (siteregion.rateRegion.zonetype == ZoneType.NationalTerritory) {
      let parent = this.getAppropriateParent(siteregion.rateRegion.parentId, siteregion.regions);
      if (parent.zonetype == ZoneType.TerritoryGroup) {
        siteregion.rateRegion = parent;
      }
    }

    return siteregion.rateRegion;
  }
  getAppropriateParent(parentid: number, heirarchy: Region[]) {
    let found: Region;
    heirarchy.some(reg => {
      if (reg.id == parentid) {
        found = reg;
        return true;
      }
      if (reg.children) {
        found = this.getAppropriateParent(parentid, reg.children);
        if (found) {
          return true;
        }
      }
    })
    return found;
  }


  /**
   * given a rate and the package info and provided with the list of possibly relevent margins and discounts,
   * calculate the relevent quotation price object using total weight and volume
   * return null if nothing applies
   * @param rate 
   * @param weight 
   * @param size 
   * @param margins 
   * @param discounts 
   * @param mat 
   */
  xcalculateRatePrice(rate: Rate, weight: number, volume: number, margins: Margin[], discounts: Discount[], mat: MarginAppliesTo, currency: number): QuotationPrice {

    let qp = new QuotationPrice();
    qp.description = rate.name;
    qp.costprice = this.tools.cleanFloat(rate.baseprice);

    let weightleft = weight - this.tools.cleanFloat(rate.baseIncludesWeight);
    let volumeleft = volume - this.tools.cleanFloat(rate.baseIncludesVolume);

    let excessweightcharge = 0;
    let excessvolumecharge = 0;

    if (weightleft > 0 || volumeleft > 0) {


      rate.RateBreaks.some((rb) => {


        if (weightleft > 0) {
          rb.maxweight = this.tools.cleanFloat(rb.maxweight);
          if (!rb.maxweight) rb.maxweight = this.tools.cleanFloat(rb.weightbreak);

          if (rb.maxweight > weightleft) {
            excessweightcharge += Math.ceil(weightleft / this.tools.cleanFloat(rb.weightbreak)) * this.tools.cleanFloat(rb.price);
          }
          else {
            weightleft = weightleft - this.tools.cleanFloat(rb.maxweight);
            excessweightcharge += this.tools.cleanFloat(rb.price);
          }
        }
        if (volumeleft > 0) {
          rb.maxvolume = this.tools.cleanFloat(rb.maxvolume);
          if (!rb.maxvolume) rb.maxvolume = this.tools.cleanFloat(rb.volumebreak);

          if (rb.maxvolume > volumeleft) {
            excessvolumecharge += Math.ceil(volumeleft / this.tools.cleanFloat(rb.volumebreak)) * this.tools.cleanFloat(rb.price);
          }
          else {
            volumeleft = volumeleft - this.tools.cleanFloat(rb.maxvolume);
            excessvolumecharge += this.tools.cleanFloat(rb.price);
          }
        }

        if (volumeleft <= 0 && weightleft <= 0) return true;
      })
    }

    qp.costprice += Math.max(excessvolumecharge, excessweightcharge);

    qp.appliesto = mat;
    qp.tableid = rate.id;
    if (rate.currency && rate.currency != this.defaultCurrency) {
      this.performExchange(qp, rate.currency);
    }
    this.discountsAndMargins(qp, margins, discounts, mat, currency);

    return qp;


  }

  /**
   * given a rate and the package info and provided with the list of possibly relevent margins and discounts,
   * calculate the relevent quotation price object using total weight and volume
   * return null if nothing applies
   * @param rate 
   * @param weight 
   * @param size 
   * @param margins 
   * @param discounts 
   * @param mat
   * NB server equivalent sits in rates service. 
   */
  calculateRatePrice(rate: Rate, weight: number, volume: number, margins: Margin[], discounts: Discount[], mat: MarginAppliesTo, currency: number): QuotationPrice {

    let qp = new QuotationPrice();
    qp.description = rate.name;
    qp.costprice = this.tools.cleanFloat(rate.baseprice);

    let includesWeight = this.tools.cleanFloat(rate.baseIncludesWeight);
    let includesVolume = this.tools.cleanFloat(rate.baseIncludesVolume);

    let weightleft = weight - includesWeight;
    let volumeleft = volume - includesVolume;

    if (!rate.volumeequivalent) volumeleft = 0;

    let excessweightcharge = 0;
    let excessvolumecharge = 0;

    if (weightleft > 0 || volumeleft > 0) {

      if (includesVolume == 0 && includesWeight == 0 && !rate.RateBreaks || rate.RateBreaks.length == 0) {
        weightleft = 0;
        volumeleft = 0;
      }
      else {
        rate.RateBreaks.some((rb) => {

          if (rb.priceperbreak) {
            if (rb.minweight < weightleft && weightleft <= rb.maxweight) {
              excessweightcharge += this.tools.cleanFloat(rb.price);
              weightleft = 0;
            }
            if (rb.minvolume < volumeleft && volumeleft <= rb.maxvolume) {
              excessvolumecharge += this.tools.cleanFloat(rb.price);
              volumeleft = 0;
            }
            if (volumeleft == 0 && weightleft == 0) {
              return true;
            }
          }
          else {
            if (weightleft > 0) {
              rb.maxweight = this.tools.cleanFloat(rb.maxweight);
              if (!rb.maxweight) rb.maxweight = Infinity;

              if (rb.maxweight > weightleft) {
                excessweightcharge += Math.ceil(weightleft / this.tools.cleanFloat(rb.weightbreak)) * this.tools.cleanFloat(rb.price);
                weightleft = 0;
              }
              else {
                weightleft = weightleft - this.tools.cleanFloat(rb.maxweight);
                excessweightcharge += this.tools.cleanFloat(rb.price);
              }
            }
            if (volumeleft > 0 && rate.volumeequivalent) {
              rb.maxvolume = this.tools.cleanFloat(rb.maxvolume);
              if (!rb.maxvolume) rb.maxvolume = Infinity;

              if (rb.maxvolume > volumeleft) {
                excessvolumecharge += Math.ceil(volumeleft / this.tools.cleanFloat(rb.volumebreak)) * this.tools.cleanFloat(rb.price);
                volumeleft = 0;
              }
              else {
                volumeleft = volumeleft - this.tools.cleanFloat(rb.maxvolume);
                excessvolumecharge += this.tools.cleanFloat(rb.price);
              }
            }

            if (volumeleft <= 0 && weightleft <= 0) return true;
          }

        })
      }
    }
    if ((rate.volumeequivalent && volumeleft > 0) || weightleft > 0) {
      //handle excess volume
      console.warn("Exceeds permitted volume, handle this.");
    }

    qp.costprice += Math.max(excessvolumecharge, excessweightcharge);

    qp.appliesto = mat;
    qp.tableid = rate.id;
    if (rate.currency && rate.currency != this.defaultCurrency) {
      this.performExchange(qp, rate.currency);
    }
    this.discountsAndMargins(qp, margins, discounts, mat, currency);

    return qp;


  }



  calculateSurchargePrice(surcharge: Surcharge, margins: Margin[], discounts: Discount[], mat: MarginAppliesTo, currency, quoteInput: QuoteInput, percentof?: number) {
    let qp = new QuotationPrice();
    qp.costprice = 0;
    qp.appliesto = mat;
    qp.tableid = surcharge.id;
    qp.description = surcharge.SurchargeType.name;
    if (surcharge.ratebracket) {

    }
    if (surcharge.perunit == SurchargeRelatesTo.Shipment) {
      if (surcharge.ratebracket) {
        quoteInput.quoteItems.forEach(qi => {
          if (qi.ratebracket == surcharge.ratebracket) {
            qp.costprice += this.tools.cleanFloat(surcharge.surchargeFixed.toString());
            if (percentof) {
              let p = percentof * this.tools.cleanFloat(surcharge.surchargePercent) / 100;
              qp.costprice += p;
            }
          }
        })
      }
      else {
        qp.costprice = this.tools.cleanFloat(surcharge.surchargeFixed.toString());
        if (percentof) {
          let p = percentof * this.tools.cleanFloat(surcharge.surchargePercent) / 100;
          qp.costprice += p;
        }
      }

    }
    else if (surcharge.perunit == SurchargeRelatesTo.kg) {
      if (surcharge.ratebracket) {
        quoteInput.quoteItems.forEach(qi => {
          if (surcharge.ratebracket = qi.ratebracket) {
            qp.costprice += this.tools.cleanFloat((surcharge.surchargeFixed * qi.weight).toString());
            if (percentof) {
              let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
              qp.costprice += p;
            }
          }
        })
      }
      else {
        qp.costprice = this.tools.cleanFloat((surcharge.surchargeFixed * quoteInput.totalweight).toString());
        if (percentof) {
          let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
          qp.costprice += p;
        }
      }

    }
    else if (surcharge.perunit == SurchargeRelatesTo.m3) {
      if (surcharge.ratebracket) {
        quoteInput.quoteItems.forEach(qi => {
          if (qi.ratebracket = surcharge.ratebracket) {
            let m3 = (qi.depth / 1000 * qi.width / 1000 * qi.height / 1000) * qi.quantity;
            qp.costprice += this.tools.cleanFloat((surcharge.surchargeFixed * m3).toString());
            if (percentof) {
              let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
              qp.costprice += p;
            }
          }
        })
      }
      else {
        let m3 = quoteInput.totalvolume;
        qp.costprice = this.tools.cleanFloat((surcharge.surchargeFixed * m3).toString());
        if (percentof) {
          let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
          qp.costprice += p;
        }
      }

    }
    else if (surcharge.perunit == SurchargeRelatesTo.Item) {
      if (surcharge.ratebracket) {
        quoteInput.quoteItems.forEach(qi => {
          if (surcharge.ratebracket == qi.ratebracket) {
            qp.costprice += this.tools.cleanFloat((surcharge.surchargeFixed));
            if (percentof) {
              let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
              qp.costprice += p;
            }
          }
        })
      }
      else {
        qp.costprice = this.tools.cleanFloat((surcharge.surchargeFixed * quoteInput.quoteItems.length));
        if (percentof) {
          let p = percentof * this.tools.cleanFloat(((surcharge.surchargePercent) / 100));
          qp.costprice += p;
        }
      }
    }
    if (surcharge.currencyid && surcharge.currencyid != this.defaultCurrency) {
      this.performExchange(qp, surcharge.currencyid);
    }
    this.discountsAndMargins(qp, margins, discounts, mat, currency);
    return qp;

  }
  discountsAndMargins(qp: QuotationPrice, margins: Margin[], discounts: Discount[], mat: MarginAppliesTo, currency: number) {
    let margin = this.applicableMargins(margins, mat, currency);

    let mpercent = 1 + (this.tools.cleanFloat(margin.percent) / 100);
    qp.listprice = qp.costprice * mpercent;
    qp.listprice += this.tools.cleanFloat(margin.fixed);
    this.discount(qp, discounts, mat, currency);
    this.cleanRound(qp);
  }
  discount(qp: QuotationPrice, discounts: Discount[], mat: MarginAppliesTo, currency: number) {
    let discount = this.applicableDiscounts(discounts, mat, currency);
    let dpercent = 1 - (this.tools.cleanFloat(discount.percent) / 100);
    qp.discountedprice = qp.listprice * dpercent;
    qp.discountedprice -= this.tools.cleanFloat(discount.fixed);
    qp.discountedprice = this.tools.cleanRound(qp.discountedprice, 2);
    if (qp.discountedprice > 0) {
      let check = (qp.discountedprice - qp.costprice) / qp.discountedprice;
      qp.margin = this.tools.cleanRound(check * 100, 2);
    }

  }

  performExchange(qp: QuotationPrice, currency: number) {
    let exrate = this.exchangerates.filter(ex => ex.country.id == currency)[0];
    if (exrate) {
      qp.preexchange = qp.costprice;
      qp.exchanged = exrate.country.currencyCode;
      qp.costprice = qp.costprice / exrate.rate;
    }
    else this.tools.gracefulError("No exchange rates available for this service currency");
  }

  cleanRound(qp: QuotationPrice) {
    qp.costprice = this.tools.cleanRound(qp.costprice, 2);
    qp.listprice = this.tools.cleanRound(qp.listprice, 2);
    qp.discountedprice = this.tools.cleanRound(qp.discountedprice, 2);
  }


  applicableMargins(margins: Margin[], appliesto: MarginAppliesTo, currency: number, surchargetype?: number): Margin {
    let releventmargin: Margin;

    if (appliesto == MarginAppliesTo.Surcharges) {
      //check for specific surcharge margin provider
      releventmargin = this.filterMargins(margins, appliesto, true, surchargetype);
      if (releventmargin) return releventmargin;
      //then specific surcharge global
      releventmargin = this.filterMargins(margins, appliesto, false, surchargetype);
      if (releventmargin) return releventmargin;
      //then surcharges provider generally
      releventmargin = this.filterMargins(margins, appliesto, true);
      if (releventmargin) return releventmargin;
      //then surcharges global
      releventmargin = this.filterMargins(margins, appliesto, false);
      if (releventmargin) return releventmargin;

    }

    //check for appliesto & provider specifically,
    releventmargin = this.filterMargins(margins, appliesto, true);
    if (releventmargin) return releventmargin;

    //then applies to global
    releventmargin = this.filterMargins(margins, appliesto, false);
    if (releventmargin) return releventmargin;

    //then whole service & provider
    releventmargin = this.filterMargins(margins, MarginAppliesTo.Service_Charges, true);
    if (releventmargin) return releventmargin;

    //then whole service global
    releventmargin = this.filterMargins(margins, MarginAppliesTo.Service_Charges, false);
    if (releventmargin) return releventmargin;

    //then global provider
    releventmargin = this.filterMargins(margins, MarginAppliesTo.Global, true);
    if (releventmargin) return releventmargin;

    //then global global
    releventmargin = this.filterMargins(margins, MarginAppliesTo.Global, false);
    if (releventmargin) return releventmargin;

    else return new Margin(appliesto, currency, 0);
  }
  filterMargins(margins: Margin[], appliesto: MarginAppliesTo, provider: boolean, surchargetype?: number): Margin {
    let releventmargins;
    if (provider) {
      releventmargins = margins.filter(m => m.providerid != 0 && m.appliesto == appliesto && m.surchargetype == surchargetype);
    }
    else {
      releventmargins = margins.filter(m => m.providerid == 0 && m.appliesto == appliesto && m.surchargetype == surchargetype);
    }
    let releventmargin: Margin;
    if (releventmargins.length > 0) {
      releventmargin = releventmargins[0];
      return releventmargin;
    }
    return null;
  }
  applicableDiscounts(discounts: Discount[], appliesto: MarginAppliesTo, currency: number): Discount {
    //check for appliesto & provider specifically,

    let releventdiscount = this.filterDiscounts(discounts, appliesto, true);
    if (releventdiscount) return releventdiscount;

    //then applies to global
    releventdiscount = this.filterDiscounts(discounts, appliesto, false);
    if (releventdiscount) return releventdiscount;

    //then whole service & provider
    releventdiscount = this.filterDiscounts(discounts, MarginAppliesTo.Service_Charges, true);
    if (releventdiscount) return releventdiscount;

    //then whole service global
    releventdiscount = this.filterDiscounts(discounts, MarginAppliesTo.Service_Charges, false);
    if (releventdiscount) return releventdiscount;

    //then global provider
    releventdiscount = this.filterDiscounts(discounts, MarginAppliesTo.Global, true);
    if (releventdiscount) return releventdiscount;

    //then global global
    releventdiscount = this.filterDiscounts(discounts, MarginAppliesTo.Global, false);
    if (releventdiscount) return releventdiscount;

    else return new Discount(appliesto, currency, 0);
  }
  filterDiscounts(discounts: Discount[], appliesto: MarginAppliesTo, customer: boolean): Discount {
    let releventdiscounts;
    if (customer) {
      releventdiscounts = discounts.filter(m => m.customerid != 0 && m.appliesto == appliesto);
    }
    else {
      releventdiscounts = discounts.filter(m => m.customerid == 0 && m.appliesto == appliesto);
    }
    let releventdiscount: Discount;
    if (releventdiscounts.length > 0) {
      releventdiscount = releventdiscounts[0];
      return releventdiscount;
    }
    return null;
  }

  filterRateBracket(rates: Rate[], quoteitem: Size, ratebrackets: RateBracket[]): Rate {
    if (rates.length == 1) return rates[0];
    else if (quoteitem && quoteitem.ratebracket) {
      let rb = ratebrackets.filter(rb => rb.id == quoteitem.ratebracket);
      let filteredrates = rates.filter(r => r.ratebrackettype == quoteitem.ratebracket);
      return filteredrates[0];
    }
    else return rates[0];
  }

  setLatLng(metric: ModeMetric, gps) {
    metric.gpslat = gps.lat();
    metric.gpslong = gps.lng();
  }
  terms(option: PreferredPorts): string {

    let term = "";
    let origindoor = false;
    let destdoor = false;
    if (option.PointToPoints && option.PointToPoints.length > 0) {
      option._terms = Terms.DoorToDoor;
      term = "Door - Door";
      return term;
    }

    if (option.originSites && option.originSites[option.originSiteIndex]) {
      if (option.originSites[option.originSiteIndex].hasCollection) {
        term += "Door - ";
        origindoor = true;
      }
      else term += "PoO - ";
    }
    else term += "PoO - ";

    if (option.destinationSites && option.destinationSites[option.destinationSiteIndex]) {
      if (option.destinationSites[option.destinationSiteIndex].hasCollection) {
        term += "Door";
        destdoor = true;
      }
      else term += "PoD";
    }
    else {
      term += "PoD";
    }
    if (origindoor && destdoor) {
      option._terms = Terms.DoorToDoor;
    }
    else if (origindoor) {
      option._terms = Terms.DoorToPort;
    }
    else if (destdoor) {
      option._terms = Terms.PortToDoor;
    }
    else option._terms = Terms.PortToPort;
    return term;
  }
}
