import { RecordStatus, Country, AnObject } from './models';
import { Company, Site } from './user';
import { PortProvider } from './ui';

export class PerformanceMetric extends AnObject{
    name:string;
    user:number;
    starttime:Date;
    endtime:Date;
    records:number;

    constructor(name:string,user:number){
        super();
        this.name = name;
        this.user = user;
        this.starttime = new Date();
    }
}

export interface Cities{
    id:number;
    countryid:number;
    name:string;
    addressid:number;
    searchName?:string;
    offset?:GoogleElement;
}

export class GoogleDistanceMatrix{
    destinationAddresses:string[];
    originAddresses:string[];
    rows:GoogleMatrixRow[];
}
export interface GoogleDistanceResult{
    destinationAddresses:string[];
    originAddresses:string[];
    rows:GoogleDistanceRow[];
}
export interface GoogleDistanceRow{
    elements:GoogleElement[];
}
export interface GoogleElement{
    distance:TextValue;
    duration:TextValue;
    status:string;
}
export interface TextValue{
    text:string;
    value:number;
}

export interface GoogleDestinationAddress{

}
export interface GoogleOriginAddress{

}
export class GoogleMatrixRow{
    elements:GoogleMatrixElement[];
}
export class GoogleMatrixElement{
    distance:GoogleValue;
    duration:GoogleValue;
    status:string;
    index:number;
}
export class GoogleValue{
    text:string;
    value:number;
}
export class GoogleDistanceMatrixBuilder{
    origins:google.maps.LatLng[];
    destinationsarray:Array<google.maps.LatLng[]>;
    originindex:number;
    destinationsarrayindex:number;
    requestsmade:number;
    resultsMatrix:GoogleDistanceMatrix;
    haversine?:boolean;
}
export class Locode{
    id:number;
    locode1:string;
    locode2:string;
    name:string;
    nodiacritic:string;
    subdivision:string;
    function:string;
    status:LocodeStatus;
    date:string;
    iata:string;
    location:string;
    geoLat:number;
    geoLong:number;
    recordStatus:number;
    countryid:number;
    functions:number[];
}
export class Vessel extends AnObject{
    name:string;
    description:string;
    mode:number;
    capacity:number;
    speed:number;
    co2gkm:number;
}


export class Service{
    id:number;
    providerid:number;
    description:string;
    displayname:string;
    originid:number;
    destinationid:number;
    transitTime:number;
    durationmax:number;
    currencyid:number;
    quoteValidity:number;
    transportMode:number;
    vesselid:number;
    transportproviderid:number;
    frequency:number[];
    loadpartner:number;
    unloadpartner:number;
    recordStatus:RecordStatus;
    isPublic:boolean;
    includeC2P:boolean;
    includeP2D:boolean;
    includeP2P:boolean;
    importaid:number;
    _isDirty:boolean;

    Provider:Company;
    Origin:Country;
    Destination:Country;
    Currency:Country;
    OriginServicePorts:ServicePort[];
    DestinationServicePorts:ServicePort[];
    ServiceUsers:ServiceUser[];
    Vessel:Vessel;
    ServiceVias:ServiceVia[];

    constructor(){
        this.recordStatus = RecordStatus.Active;
        this.includeC2P = true;
        this.includeP2D = true;
        this.includeP2P = true;
        this.OriginServicePorts = [];
        this.DestinationServicePorts = [];
        this.ServiceVias = [];
        this.ServiceUsers = [];
    }
}
export class ServiceWhereClause{
    countrycode:string;
    functions:string[];
}

export class ServiceGroup{
    id:number;
    companyid:number;
    description:string;
    recordStatus:RecordStatus;
    _isDirty:boolean;

    Services:Service[];
}
export class ServiceGroupService{
    id:number;
    serviceid:number;
    servicegroupid:number;
    recordStatus:RecordStatus;
}
export class ServicePort{
    id:number;
    serviceid:number;
    locodeid:number;
    origin:boolean;
    Locode:Locode;
    partnersiteid:number;  
    recordStatus:RecordStatus;
    _isDirty:boolean;
    PartnerSite:Site;
    _portprovider:PortProvider;
}
export class ServiceUser{
    id:number;
    serviceid:number;
    userCompanyid:number;
    recordStatus:RecordStatus;

    UserCompany:Company;
    _isDirty:boolean;
}

export class ServiceVia extends AnObject{
    serviceid:number;
    countryid:number;
    locodeid:number;
    Country:Country;
    Locode:Locode;
    _isDirty:boolean;
}

export class MapRequest extends AnObject{
    companyid:number;
    providerid:number;
    description:string;
    json:string;
    consistentrows:boolean;
    consistentheaders:boolean;
    serviceid:number;
    regionid:number;
    transportmode:number;
    title:string;
    datacolumns:number;
    columns:number;
    code:string;
    definer:string;
}


export enum PortType{
    "Not Known","Port","Rail Terminal","Road Terminal","Airport","Post Office","Multi Modal","Fixed Transport","Border Crossing"
}
/*
export enum xTransportMode{
    NOT_KNOWN,PORT,RAIL_TERMINAL,ROAD_TERMINAL,AIRPORT,POSTAL_OFFICE,MULTI_MODAL,FIXED_TRANSPORT,BORDER_CROSSING
}*/

export enum LocodeStatus{
    "AA" = "Approved by competent national government agency",
"AC" = "Approved by Customs Authority",
"AF" = "Approved by national facilitation body",
"AI" = "Code adopted by international organisation (IATA or ECLAC)",
"AM" = "Approved by the UN/LOCODE Maintenance Agency",
"AS" = "Approved by national standardisation body",
"AQ" = "Entry approved, functions not verified",
"RL" = "Recognised location - Existence and representation of location name confirmed by check against nominated gazetteer or other reference work",
"RN" = "Request from credible national sources for locations in their own country",
"RQ" = "Request under consideration",
"RR" = "Request rejected",
"QQ" = "Original entry not verified since date indicated",
"UR" = "Entry included on user's request; not officially approved",
"XX" = "Entry that will be removed from the next issue of UN/LOCODE"
}
