import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { Region, RegionGroup, RegionTypes, RegionGroupType, ZoneType } from 'src/app/models/rates';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-regiongrouper',
  templateUrl: './regiongrouper.component.html',
  styleUrls: ['./regiongrouper.component.scss']
})
export class RegiongrouperComponent implements OnInit {

  public parentRegion: Region;
  public regiongroups: RegionGroupType[] = [];
  public regionName: string = "";
  public bands: number = 5;
  public groupname = "Region Group";
  public distance = false;
  public custom = false;
  public customzonetype:ZoneType = ZoneType.NationalRegionGroup;

  public applicablezones:RegionGroupType[] = [];

  constructor(public dialogRef: MatDialogRef<RegiongrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, private tools: ToolsService) {
    this.parentRegion = dialogdata.Region;
  }

  ngOnInit(): void {
    if (this.parentRegion) {
      switch (this.parentRegion.zonetype) {
        case ZoneType.Continental:
          this.regionName = "countries";
          this.continentGroupTypes(this.parentRegion.continentid);
          break;
        case ZoneType.National:
          this.regionName = "regions";
          this.countryGroupTypes(this.parentRegion.countryid);
          break;
        case ZoneType.NationalTerritory:
            this.regionName = "territory";
            this.regionGroupTypes();
            break;
        case ZoneType.NationalRegionGroup:
          this.regionName = "localities";
          this.regionGroupTypes();
          break;
        default:
          this.regionName = "regions";
      }


    }
  }

  continentGroupTypes(grouptypeid: number) {
    this.data.listRegionGroupTypes(grouptypeid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let grouptypes: RegionGroupType[] = message.message;

        if (grouptypes.length > 0) {
          //override defaults.
          this.regiongroups = grouptypes;
        }
        else this.regiongroups = [];
        /*
        let distance1 = new RegionGroupType();
        distance1.id = -1;
        distance1.description = "Distance from your site";
        let distance = new RegionGroupType();
        distance.id = -2;
        distance.description = "Distance from a chosen point";*/
        //this.regiongroups.push(distance1);
        //this.regiongroups.push(distance);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  /**
   * This is a hack - need to rewrite so country id on a region refers to a region not a country.
   */
  countryGroupTypes(countryid: number) {

    this.data.getCountryRegion(countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.data.listRegionGroupTypes(message.message.id).subscribe((message: StatusMessage) => {
          if (message.success) {
            let grouptypes: RegionGroupType[] = message.message;

            if (grouptypes.length > 0) {
              //override defaults.
              this.regiongroups = grouptypes;
              this.regiongroups.forEach((rg)=>{
                this.applicablezones.push(rg);
              })

            }
            else this.regiongroups = [];
            if (this.parentRegion.basesiteid && !this.parentRegion.BaseSite) {
              if (this.data.selectedSupplier && this.data.selectedSupplier._baseSite && this.data.selectedSupplier._baseSite.id == this.parentRegion.basesiteid) {
                this.parentRegion.BaseSite = this.data.selectedSupplier._baseSite;
              }
            }
            if (this.parentRegion.BaseSite && this.parentRegion.BaseSite.Address && this.parentRegion.BaseSite.Address.countryid == this.parentRegion.countryid) {
              let distance1 = new RegionGroupType();
              distance1.id = -1;
              distance1.description = "Distance from your site";
              distance1.zonetype = ZoneType.NationalDistanceGroup;
              this.regiongroups.push(distance1);
            }


            let custom1 = new RegionGroupType();
            custom1.id = -2;
            custom1.description = "Custom Groups";
            custom1.zonetype = ZoneType.NationalCustomGroup;
            this.regiongroups.push(custom1);
            /*
            let distance = new RegionGroupType();
            distance.id = -2;
            distance.description = "Distance from a chosen point";
            distance1.zonetype = ZoneType.NationalDistanceGroup;
            this.regiongroups.push(distance);
            */


            // = distance.RegionGroups.concat([distance1,distance]);
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
    })


  }
  regionGroupTypes() {
    
    this.data.listRegionGroupTypes(this.parentRegion.baseregionid).subscribe((message: StatusMessage) => {
      if (message.success) {
        let grouptypes: RegionGroupType[] = message.message;

        if (grouptypes.length > 0) {
          //override defaults.
          this.regiongroups = grouptypes;
          this.regiongroups.forEach((rg)=>{
            this.applicablezones.push(rg);
          })

        }
        else this.regiongroups = [];
        if (this.parentRegion.basesiteid && !this.parentRegion.BaseSite) {
          if (this.data.selectedSupplier && this.data.selectedSupplier._baseSite && this.data.selectedSupplier._baseSite.id == this.parentRegion.basesiteid) {
            this.parentRegion.BaseSite = this.data.selectedSupplier._baseSite;
          }
        }
        if (this.parentRegion.BaseSite && this.parentRegion.BaseSite.Address && this.parentRegion.BaseSite.Address.countryid == this.parentRegion.countryid) {
          let distance1 = new RegionGroupType();
          distance1.id = -1;
          distance1.description = "Distance from your site";
          distance1.zonetype = ZoneType.NationalDistanceGroup;
          this.regiongroups.push(distance1);
        }


        let custom1 = new RegionGroupType();
        custom1.id = -2;
        custom1.description = "Custom Groups";
        custom1.zonetype = ZoneType.NationalCustomGroup;
        this.regiongroups.push(custom1);
        /*
        let distance = new RegionGroupType();
        distance.id = -2;
        distance.description = "Distance from a chosen point";
        distance1.zonetype = ZoneType.NationalDistanceGroup;
        this.regiongroups.push(distance);
        */


        // = distance.RegionGroups.concat([distance1,distance]);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }

  selectGroup(e) {
    //this.choice = e.value;
    let group = this.regiongroups.filter(rg => rg.id == e.value)[0];
    if (group.zonetype == ZoneType.NationalDistanceGroup) {
      this.distance = true;
      this.customzonetype = ZoneType.DrivingDistance;
      this.applicablezones = [group];
    }
    else if(group.zonetype == ZoneType.NationalCustomGroup){
      this.custom = true;
      this.applicablezones = [];
      this.regiongroups.forEach((rg)=>{
        if(rg.id && rg.id>0){
          this.applicablezones.push(rg);
        }
        if(this.applicablezones.length>0){
          this.customzonetype = this.applicablezones[0].zonetype;
        }
      })
    }
    else {
      let choice = { id: e.value, groups: this.regiongroups, bands: 0, overridename:this.groupname };
      this.dialogRef.close(choice);
    }

  }
  
  doSplit() {
    let choice = { bands: this.bands,custom:this.custom,distance:this.distance,zonetype:this.customzonetype,overridename:this.groupname };
    this.dialogRef.close(choice);
  }
  nope() {
    this.dialogRef.close();
  }
  openmapper() {
    this.dialogRef.close({ mapper: true });
  }
}
