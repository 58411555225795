<div>
    <h3>Zoning Options</h3>
    <!--<div *ngIf="parentRegion">
        <p>{{parentRegion.name}}'s {{regionName}} can be divided up a number of ways.</p>
        <p>Please choose which option you would prefer to start with.</p>
        <p>You can move sub-regions around and rename groupings after deciding.</p>
    </div>
    <div>
        <mat-radio-group (change)="selectGroup($event)">
            <div class="pad" *ngFor="let group of regiongroups"><mat-radio-button [value]="group.id">{{group.description}}</mat-radio-button></div>
            <div class="pad"><mat-radio-button [value]="0">Custom</mat-radio-button></div>
        </mat-radio-group>
    </div>-->
    <div *ngIf="parentRegion.zonetype==zonetypes.Continental || parentRegion.zonetype==zonetypes.International">
        <p>Create Country Zones</p>
        <p>Click Continents to zone by continent.</p>
        <p>Or click Custom Zones and selected the number of zones for your own grouping</p>
        <p>You can edit group names and add countries after deciding</p>
    </div>
    <div class="fl aic">
        <mat-form-field class="mr">
            <mat-label>How many groups?</mat-label>
            <input matInput type="number" placeholder="name" [(ngModel)]="bands" min="2">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="cancel()" type="button" class="mr">Cancel</button>
        <button mat-raised-button color="primary" (click)="createContinents()" type="button" class="mr">Continents</button>
        <button mat-raised-button color="primary" (click)="create()" type="button">Custom Zones</button>
    </div>
</div>
