import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SavedQuote } from 'src/app/models/rates';
import { TableGenerator } from 'src/app/models/models';
import { CSMatTableComponent } from 'src/app/components/mat-table/mat-table.component';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialog } from '@angular/material/dialog';
import { QuoteViewDialog } from 'src/app/dialogs/quote-view-dialog/quote-view-dialog.component';

@Component({
  selector: 'cs-quote-history-list',
  templateUrl: './quote-history-list.component.html',
  styleUrls: ['./quote-history-list.component.scss']
})
export class QuoteHistoryListComponent implements OnInit {

  @Input() quotes:SavedQuote[] = [];

  public tabgen: TableGenerator = new TableGenerator();
  @ViewChild(CSMatTableComponent, { static: false }) quoteTable: CSMatTableComponent;

  constructor(public tools:ToolsService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.tabgen.object = new SavedQuote();
    this.tabgen.pages = [10,25,50];
    this.tabgen.columns = this.tools.quoteColumns();
  }

  
  showQuote(e){
    const dialogRef = this.dialog.open(QuoteViewDialog, {
      width: '80%',
      data:{quoteid:e.id}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
