import { Component, OnInit, Input } from '@angular/core';
import { RateBracket, Size } from 'src/app/models/rates';
import { CompanySetting, CompanySettingTypes } from 'src/app/models/user';
import { QuoteOption } from 'src/app/models/ui';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  selector: 'cs-sizer',
  templateUrl: './sizer.component.html',
  styleUrls: ['./sizer.component.scss']
})
export class SizerComponent implements OnInit {

  @Input() quoteItem: Size;
  @Input() quoteOptions: QuoteOption[];
  @Input() settings: CompanySetting[] = [];
  @Input() ratebrackets: RateBracket[] = [];

  public cube = 200;
  public defaultsize = 100;
  public scale = 8;
  public raise = 20;

  public trans: number = 100;
  public quantity = 1;

  public pallet = false;
  public fcl = false;
  public custom = false;

  public title = "sizer";

  public showratebrackets = true;

  constructor() { }

  ngOnInit(): void {

    this.setup();
  }
  setup() {
    if (this.quoteItem.ratebracket) {
      this.setParams(this.quoteItem.ratebracket);
    }
    else {
      if (this.settings && this.settings.length > 0) {
        let checkshowbrackets = this.settings.filter(s => s.companysettingtypeid == CompanySettingTypes.ShowSizeBracket);
        if (checkshowbrackets.length == 1) {
          this.showratebrackets = checkshowbrackets[0].setting == "0";
        }
        let checkbracketdefault = this.settings.filter(s => s.companysettingtypeid == CompanySettingTypes.DefaultSizeBracket);
        if (checkbracketdefault.length == 1) {
          this.quoteItem.ratebracket = parseInt(checkbracketdefault[0].setting);
        }
      }
    }

    /*
    let rb = this.ratebrackets.filter(r=>r.id==this.quoteItem.ratebracket)[0];
    if(rb){
      if(rb.name=="Pallet" || rb.name=="Europallet"){
        this.pallet = true;
      }
      else if(rb.name.indexOf("FCL")>=0){
        this.fcl = true;
      }
      else if(rb.name=="IDW" || rb.name=="Over3"){
        this.custom = true;
      }
    }
    else{
      rb = this.ratebrackets.filter(r=>r.name=="Pallet")[0];
    }

    this.setParams(rb.id);
    */
  }
  h(up: boolean) {
    if (up) {
      this.quoteItem.height = this.quoteItem.height + 10;
    }
    else {
      this.quoteItem.height = this.quoteItem.height - 10;
    }
  }
  shipunit(e) {
    this.setParams(e.value);
  }
  setParams(bracketid: number) {
    let bracket = this.ratebrackets.filter(r => r.id == bracketid)[0];
    if (bracket.name.indexOf("FCL") >= 0) {
      this.fcl = true;
      this.pallet = false;
      this.custom = false;
      this.scale = 40;
      switch (bracket.name) {
        case "FCL 20":
          this.quoteItem.depth = 6096;
          this.quoteItem.width = 2438;
          this.quoteItem.height = 2591;
          this.quoteItem.weight = 22000;
          break;
        case "FCL 40":
          this.quoteItem.depth = 12192;
          this.quoteItem.width = 2438;
          this.quoteItem.height = 2591;
          this.quoteItem.weight = 26000;
          break;
        case "FCL 40 HQ":
          this.quoteItem.depth = 12192;
          this.quoteItem.width = 2438;
          this.quoteItem.height = 2900;
          this.quoteItem.weight = 26000;
          break;
      }
      return;
    }
    else {
      this.fcl = false;
      this.scale = 8;
      switch (bracket.name) {
        case "Package":
          this.quoteItem.width = 200;
          this.quoteItem.depth = 200;
          this.quoteItem.height = 200;
          this.quoteItem.weight = 50;
          this.pallet = false;
          break;
        case "Europallet":
          this.quoteItem.width = 1000;
          this.quoteItem.depth = 800;
          this.quoteItem.height = 1200;
          this.quoteItem.weight = 300;
          this.pallet = true;
          break;
        case "Pallet":
          this.quoteItem.width = 1200;
          this.quoteItem.depth = 1000;
          this.quoteItem.height = 1200;
          this.quoteItem.weight = 300;
          this.pallet = true;
          break;
          case "Parcel":
            this.quoteItem.width = 300;
          this.quoteItem.depth = 300;
          this.quoteItem.height = 300;
          this.quoteItem.weight = 100;
            break;
        case "IDW":
          this.quoteItem.width = 2000;
          this.quoteItem.depth = 500;
          this.quoteItem.height = 500;
          this.quoteItem.weight = 2000;
          this.pallet = false;
          break;
        default:
          this.quoteItem.width = 1200;
          this.quoteItem.depth = 1000;
          this.quoteItem.height = 1200;
          this.quoteItem.weight = 300;
          this.pallet = true;
          break;
      }
    }

    this.quoteItem.stackable = true;


  }
  showrb() {
    //console.log(this.quoteItem.ratebracket);
  }
}
