import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'cs-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  public route: string;
  public settingspages = [{name:"Providers",icon:"people"},{name:"Customers",icon:"people_outline"},{name:"Regions",icon:"public"},{name:"Rates",icon:"money"},{name:"Services",icon:"directions_boat"},{name:"Margins",icon:"margin"},{name:"Users",icon:"people"},{name:"Documents",icon:"text_snippet"}];
  public panelOpenState = false;
  public closed = false;

  constructor(public auth:AuthService, public router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evs: any) => {
      if (evs instanceof NavigationEnd) {
        this.route = evs.url;
      }

    })
    
  }

}
