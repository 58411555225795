<div *ngIf="!loading;else loadwarning">
    <div *ngIf="baseCountry" class="fl fw aic">
        <div class="mr-half">
            <span class="ts3 mr-half control-align">Base: </span>
            <mat-form-field>
                <mat-select [(ngModel)]="baseCountry" (selectionChange)="changeBase()">
                    <mat-option *ngFor="let country of data.currencies" [value]="country">
                        {{country.currencySymbol}} <img class="flag"
                            src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="fl f1 aic">
            <mat-form-field class="short-int rj">
                <input matInput type="text" placeholder="Amount" [(ngModel)]="amount" type="number" min="0.01">
            </mat-form-field>
            <span class="mr-half">{{reverse?"of those":"of these"}}</span>
            <mat-icon class="pointer" (click)="reverse=!reverse">swap_vert</mat-icon>
            <div class="f1"></div>
            <span *ngIf="rate">@ {{rate.date | date:'dd-MM-yyyy'}}</span>
        </div>
    </div>
    <div *ngIf="rate" class="fl ex-rate fw">
        <div *ngFor="let exrate of rate.rates" class="f1">
            <div class="fl aic">
                <div class="f1 jc">
                    <img src="../../../assets/flags/{{exrate.country.flag}}.png" alt="{{exrate.country.name}} - flag">
                </div>
                <div class="f1 jc">
                    <div class="ts3">
                        <span *ngIf="reverse">{{amount}}</span> {{exrate.country.currencySymbol}} <span
                            *ngIf="reverse">=</span>
                    </div>
                    <div class="ts3" *ngIf="!reverse">{{exrate.rate * amount | number:'0.2-2'}}</div>
                    <div class="ts3" *ngIf="reverse">
                        {{baseCountry.currencySymbol}}{{amount/exrate.rate | number:'0.2-2'}}
                    </div>
                </div>
            </div>

            <div class="jc">{{exrate.country.name}}</div>


        </div>
    </div>
</div>
<ng-template #loadwarning>Loading Exhange Rate Data...</ng-template>