import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { EventsService } from 'src/app/services/events.service';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { MinimumMargin, Discount, MarginAppliesTo, SurchargeAppliesTo } from 'src/app/models/rates';
import { StatusMessage, CompanyAssociation, Company } from 'src/app/models/user';

@Component({
  selector: 'cs-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {

  public discountsForm:FormGroup;

  public setminimum = false;
  public minmargin:MinimumMargin;
  public discounts:Discount[]=[];

  public customer:Company;

  public allcustomers = true;

  public customerid = 0;

  constructor(public data:DataService,public tools:ToolsService,public events:EventsService,public formtools:FormtoolsService) {
    events.companyselect.subscribe((assoc: CompanyAssociation) => {
      if (assoc.Customer) {
        this.data.selectedCustomer = assoc;
        this.customer = assoc.Customer;
        this.setup();
      }
    })
  }

  public get minMargin():FormGroup{
    return <FormGroup> this.discountsForm.get("minimumMargin");
  }

  ngOnInit(): void {
    this.setup();    
    
  }

  get discountsArray(): FormArray {
    return <FormArray>this.discountsForm.get("discountsArray");
  }
  get surchargeArray(): FormArray {
    return <FormArray>this.discountsForm.get("surchargeArray");
  }

  setup(){
    
    if(this.customer){
      this.customerid = this.customer.id;
    }
    this.data.listDiscounts(this.customerid).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.discounts = message.message;  
        this.data.getMinimumMargin(this.customerid).subscribe((message:StatusMessage)=>{
            if(message.success){
              if(message.message){
                this.setminimum = true;
                this.minmargin = message.message;
              }
              else{
                this.minmargin = new MinimumMargin(this.data.Company.id,this.customerid);
              }
              
              this.discountsForm = this.formtools.createDiscountsForm(this.minmargin);
              this.buildDiscountArray();
              //this.addSurchargeDiscountControls();
            }
            else this.tools.gracefulError(message.message);
          },err=>{
            this.tools.gracefulError(err);
          })
        }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>this.tools.gracefulError(err))
    
    
  }


  buildDiscountArray() {
    this.discountsArray.clear();
    let prefix = ""
    if (this.customer) {
      prefix = this.customer.name + " ";
    }
    this.addDiscountControl(prefix + "Global", MarginAppliesTo.Global);
    this.addDiscountControl(prefix + "Service", MarginAppliesTo.Service_Charges);
    this.addDiscountControl(prefix + "Delivery Rates", MarginAppliesTo.Delivery_Rates);
    this.addDiscountControl(prefix + "Collection Rates", MarginAppliesTo.Collection_Rates);
    this.addDiscountControl(prefix + "Surcharge", MarginAppliesTo.Surcharges);

  }

  addDiscountControl(title: string, appliesto: MarginAppliesTo) {
    let existingmargin = this.discounts.filter(g => g.appliesto == appliesto && !g.surchargetype);
    let discount: Discount;
    if (existingmargin.length == 1) discount = existingmargin[0];
    else discount = new Discount(appliesto, this.data.auth.user.Site.Address.countryid, this.data.Company.id);
    discount.customerid = this.customerid;
    let global: FormGroup = this.formtools.createDiscountForm(discount, title);
    this.discountsArray.push(global);
  }

  /*
  addSurchargeDiscountControls() {
    this.surchargeArray.clear();
    let alltypes = SurchargeAppliesTo;
    let prefix = ""
    if (this.customer) {
      prefix = this.customer.name + " ";
    }
    Object.keys(alltypes).forEach((surchargegroup) => {
      let surchargetypes = this.surchargeTypes.filter(st => st.appliesto == alltypes[surchargegroup]);
      if (surchargetypes.length > 0) {
        let surchargemarginformgroup = this.formtools.createSurchargeMarginGroup(prefix + surchargegroup);
        let marginarray = <FormArray>surchargemarginformgroup.get("marginArray");
        surchargetypes.forEach(st => {
          let margin: Margin;
          let existing = this.globals.filter(g => g.appliesto == MarginAppliesTo.Surcharges && g.surchargetype == st.id);
          if (existing.length == 1) margin = existing[0];
          else {
            margin = new Margin(MarginAppliesTo.Surcharges, this.data.auth.user.Site.Address.countryid, this.data.Company.id);
            margin.surchargetype = st.id;
            margin.providerid = this.providerid;
          }
          let global: FormGroup = this.formtools.createMarginForm(margin, st.name);
          marginarray.push(global);
        })
        this.surchargeArray.push(surchargemarginformgroup);
      }
    })
    console.log(this.surchargeArray);
  }*/

  startSave(){
    if(this.minMargin.dirty){
      let mm:MinimumMargin = this.minMargin.value;
      if(mm.id>0 && mm.customerid==this.customerid){
        this.data.updateMinimumMargin(mm).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.minMargin.reset(mm);
            this.saveDiscounts();
          }
          else{
            this.tools.gracefulError(message.message);
          }
        },err=>{
          this.tools.gracefulError(err);
        })
      }
      else{
        delete(mm.id);
        mm.customerid = this.customerid;
        this.data.createMinimumMargin(mm).subscribe((message:StatusMessage)=>{
          if(message.success){
            this.minMargin.reset(message.message);
            this.saveDiscounts();
          }
          else this.tools.gracefulError(message.message);
        },err=>{
          this.tools.gracefulError(err);
        })
      }
    }
    else this.saveDiscounts();
  }
  saveDiscounts() {
    //ITS THE FUCKING CLONEY THING - PUSHING A FORM FUCKS STUFF!!!!!
    //let discountstoupdate = this.formtools.createFormArray();
   
    //use value instead
    let discountmodels:Discount[]=[];
    let index = 0; //need to set an id for any new ones so need to keep record of its place in the form array
    this.discountsArray.controls.forEach(discountform => {
      if (discountform.dirty) {
        let dis:Discount = discountform.value;
        dis.placeinarray = index;
        discountmodels.push(dis);
      }
      index++;
    })

    let todo = discountmodels.length;
    if (todo == 0) {
      this.discountsForm.markAsPristine();
      this.tools.snackMessage("Updates Complete");
    }

    else {
      let done = 0;
      discountmodels.forEach(discount => {
        if (discount.id > 0) {
          this.data.updateDiscount(discount).subscribe((message: StatusMessage) => {
            if (message.success) {
              done++;
              if (done == todo) {
                this.discountsForm.markAsPristine();
                this.tools.snackMessage("Updates Complete");
              }
            }
            else {
              done++;
              this.tools.gracefulError(message.message);
              if (done == todo) {
                this.tools.snackMessage("Updates Complete with errors");
              }
            }
          }, err => {
            done++;
            this.tools.gracefulError(err);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          })
        }
        else {
          this.data.createDiscount(discount).subscribe((message: StatusMessage) => {
            if (message.success) {
              done++;
              discount.id = message.message.id;
              this.discountsArray.controls[discount.placeinarray].get("id").setValue(discount.id);
              
              if (done == todo) {
                this.discountsForm.markAsPristine();
                this.tools.snackMessage("Updates Complete");
              }
            }
            else {
              done++;
              this.tools.gracefulError(message.message);
              if (done == todo) {
                this.tools.snackMessage("Updates Complete");
              }
            }
          }, err => {
            done++;
            this.tools.gracefulError(err);
            if (done == todo) {
              this.tools.snackMessage("Updates Complete");
            }
          })
        }
      })
    }
  }
  setCustomer(){
    if (this.allcustomers) {
      this.customer = null;
      this.customerid = 0;
      this.setup();
    }
  }

  logform(){
    console.log(this.discountsForm);
  }
}
