import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cs-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss']
})
export class MetricComponent implements OnInit {

  @Input() description:string;
  @Input() value:number;
  @Input() change:number;
  @Input() negativegood:boolean = false;

  public metricclass = "positive";

  constructor() { }

  ngOnInit(): void {
    let neg = this.change<0;
    if(neg){
      if(!this.negativegood) this.metricclass = "negative";
    }
  }

}
