import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Region, RegionGroup, RegionGroupChild, RegionGroupType, FeaturePolygon, ZoneType, RegionTypes } from 'src/app/models/rates';
import { StatusMessage } from 'src/app/models/user';
import { ToolsService } from 'src/app/services/tools.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'cs-regiongroupeditor',
  templateUrl: './regiongroupeditor.component.html',
  styleUrls: ['./regiongroupeditor.component.scss']
})
export class RegiongroupeditorComponent implements OnInit {

  @Input() parentRegion:Region;
  @Input() regionChange:Subject<boolean>;

  public grouptypes:RegionGroupType[]=[];

  public searchText:string = "";
  public searchResults:Region[]=[];

  public selectedGroupType:RegionGroupType;
  public selectedGroup:RegionGroup;

  public newGroup:RegionGroup; 
  public newGroupType:RegionGroupType;

  public missing:Region[]=[];

  public groupChildren:Region[]=[];

  public zonetypes = ZoneType;
  public regiontypes = RegionTypes;

  public newregion:string = "";
  public newzone:ZoneType;
  public newtype:RegionTypes

  iterations = 1;
  
  constructor(public data:DataService,public tools:ToolsService) {
    this.newGroup = new RegionGroup();
    this.newGroup.description = "";
    this.newGroupType = new RegionGroupType();
    this.newGroupType.description = "";
  }

  ngOnInit(): void {
    this.regionChange.subscribe(()=>{
      this.changeRegion();
    })
    if(this.parentRegion && this.parentRegion.id){
      this.changeRegion();
    }
    
  }
  changeRegion(){
    if(this.parentRegion && this.parentRegion.id){
      this.data.listRegionGroupTypes(this.parentRegion.id).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.grouptypes = message.message;
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>{
        this.tools.gracefulError(err);
      })
    }
    
  }
  
  search(){
    if(this.searchText.length>0){
      this.data.searchRegionsType(this.searchText,this.parentRegion.regiontypeid+1).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.searchResults = message.message;
          this.searchText="";
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>{
        this.tools.gracefulError(err);
      })
    }
    
  }
  createRegion(){
    let region = new Region(this.parentRegion.companyid,this.newzone);
    region.name = this.newregion;
    region.regiontypeid = this.newtype;
    region.countryid = this.parentRegion.countryid;
    region.continentid = this.parentRegion.continentid;
    region.parentId = this.parentRegion.id;
    this.data.createRegion(region).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.addChild(message.message);
      }
    })

  }
  select(group:RegionGroup){
    if(!this.selectedGroup || this.selectedGroup.id!=group.id){
      this.selectedGroup = group;
    }
    

  }
  update(group:RegionGroup){
    this.data.updateRegionGroup(group).subscribe((message:StatusMessage)=>{
      if(message.success){
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }
  delete(group:RegionGroup){
    group.recordStatus = 3;
    this.update(group);
  }
  selectType(grouptype:RegionGroupType){
    if(!this.selectedGroupType || this.selectedGroupType.id!=grouptype.id){
      this.selectedGroupType = grouptype;
      if(this.selectedGroupType.RegionGroups && this.selectedGroupType.RegionGroups.length>0){
        this.selectedGroup = this.selectedGroupType.RegionGroups[0];
      }
      else this.selectedGroup = null;
    }
    
  }
  updateType(grouptype:RegionGroupType){
    this.data.updateRegionGroupType(grouptype).subscribe((message:StatusMessage)=>{
      if(message.success){
        if(grouptype.recordStatus==3){
          let index = 0;
          this.grouptypes.some(gt=>{
            if(gt.id==grouptype.id){
              return true;
            }
            index++;
          })
          this.grouptypes.splice(index,1);
        }
        
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }
  deleteType(grouptype:RegionGroupType){
    grouptype.recordStatus = 3;
    this.updateType(grouptype);
  }

  addGroupType(){
    if(this.parentRegion){
      this.newGroupType.parentregionid = this.parentRegion.id;
      this.data.createRegionGroupType(this.newGroupType).subscribe((message:StatusMessage)=>{
        if(message.success){
          let grouptype:RegionGroupType = message.message;
          grouptype.RegionGroups = [];
          this.grouptypes.push(grouptype);
          this.newGroupType.description="";
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>{
        this.tools.gracefulError(err);
      })
    }
  }
  

  addGroup(){
    if(this.parentRegion){
      this.newGroup.regiongrouptypeid = this.selectedGroupType.id;
      this.data.createRegionGroup(this.newGroup).subscribe((message:StatusMessage)=>{
        if(message.success){
          let group:RegionGroup = message.message;
          group.RegionGroupChildren = [];
          this.selectedGroupType.RegionGroups.push(group);
          this.newGroup.description="";
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>{
        this.tools.gracefulError(err);
      })
    }
  }

  addChild(region:Region){
    let regionChild = new RegionGroupChild();
    regionChild.regiongroupid = this.selectedGroup.id;
    regionChild.regionid = region.id;
    regionChild.featureid = region.featureid;
    if(region.featureid){
      let newfps:FeaturePolygon[]=[];
      region.Feature.FeaturePolygons.forEach((fp)=>{
        let newfp = new FeaturePolygon();
        newfp.featureid = this.selectedGroup.featureid;
        newfp.polygonid = fp.polygonid;
        newfps.push(newfp);
      })
      this.data.addFeaturePolygonsToParent(newfps).subscribe((message:StatusMessage)=>{
        //Yorkshire now has YO's polygons
        
        if(message.success){
          this.createRegionChild(regionChild,region);
        }
        
      })
    }
    else this.createRegionChild(regionChild,region);

    
  }
  createRegionChild(child:RegionGroupChild,parent:Region){
    this.data.createRegionGroupChild(child).subscribe((message:StatusMessage)=>{
      if(message.success){
        let child = message.message;
        if(child.Feature) child.Feature.FeaturePolygons=[];
        this.selectedGroup.RegionGroupChildren.push(child);
        let index=0;
        this.groupChildren.some(ch=>{
          if(ch.id==parent.id){
            return true;
          }
          index++;
        })
        this.groupChildren.splice(index,1);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }
  deleteChild(child:RegionGroupChild,index:number){
    child.recordStatus = 3;
    this.data.updateRegionGroupChild(child).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.selectedGroup.RegionGroupChildren.splice(index,1);
      }
      else{
        this.tools.gracefulError(message.message);
      }
    },err=>{
      this.tools.gracefulError(err);
    })
  }

  /**
   * dont know what this is for!
   */
  regionalise(){
    let matches=0;
    this.data.listAdminRegions(2).subscribe((message:StatusMessage)=>{
      let parents:Region[] = message.message;
      this.data.listOrphanAdminRegions(3).subscribe((message:StatusMessage)=>{
        let children:Region[]=message.message;
        children.some((child:Region)=>{
          let daddys = parents.filter(p=>p.id==child.continentid);
          if(daddys.length>0){
            let daddy = daddys[0];
            child.parentId = daddy.id;
            this.data.updateRegion(child).subscribe(()=>{});
            matches++;
            if(matches==this.iterations){
              
              return true;
            } 
          }
        })
      })

    })
    
  }
  listRegions(){
    this.data.getBaseRegionChildrenByNameBasic(this.parentRegion.regiontypeid,this.parentRegion.name).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.groupChildren = message.message;
      }
    })
  }

}
