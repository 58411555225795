import { Component, OnInit, Inject } from '@angular/core';
import { Region, RegionGroupType, ZoneType } from 'src/app/models/rates';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegiongrouperComponent } from '../../regiongrouper/regiongrouper.component';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { Site } from 'src/app/models/user';

@Component({
  selector: 'cs-basic-grouper',
  templateUrl: './basic-grouper.component.html',
  styleUrls: ['./basic-grouper.component.scss']
})
export class BasicGrouperComponent implements OnInit {

  public parentRegion: Region;
  public regiongroups: RegionGroupType[] = [];
  public regionName: string = "";
  public bands: number = 5;
  public distance = false;
  public site:Site;
  public zonetypes = ZoneType;

  constructor(public dialogRef: MatDialogRef<RegiongrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, private tools: ToolsService) {
    this.parentRegion = dialogdata.region;
    this.site = this.parentRegion.BaseSite;
  }

  ngOnInit(): void {
  }
  selectGroup(e){

  }
  create(){
    this.dialogRef.close(this.bands);
  }
  createContinents(){
    this.dialogRef.close(0);
  }
  cancel(){
    this.dialogRef.close();
  }
}
