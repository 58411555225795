import { Module, ModuleUser, Country, RecordStatus, AnObject } from './models';
import { FormGroup, Form } from '@angular/forms';
import { Service, Locode } from './data';
import { Region, Margin, RatePriority } from './rates';
import { SpawnSyncOptionsWithStringEncoding } from 'child_process';

export class User{
    id:number;
    screenname:string;
    firstname:string;
    surname:string;
    email:string;
    password:string;
    siteid:number;
    Site?:Site;
    token:string;
    recordStatus:RecordStatus;
    UserRoles:UserRole[];
    UserSettings:UserSetting[];
    ModuleUsers:ModuleUser[];
    _isDirty:boolean = false;
    _formGroup:FormGroup;

    constructor(){
        this.id = 0;
        this.firstname = "";
        this.screenname = "";
        this.surname = "";
        this.email = "";
        this.password = "";
        this.siteid = 0;
        this.token = "";
        this.UserRoles = [];
        this.UserSettings = [];
        this.ModuleUsers=[];
    }
}

export class Invitation extends AnObject{
    salutation:string;
    email:string;
    status:number;
    siteid:number;
    companyid:number;
    inviterid:number;
    roleid:number;
    _isDirty:boolean = false;
}

export class ChangePasswordModel{
    id:number;
    existingpassword:string;
    newpassword:string;
    confirmpassword:string;
}

export enum InvitationStatus{
    PENDING,SENDABLE,SENT,REJECTED,ACCEPTED
}

export class Company{
    id:number;
    name:string;
    companytypeid: number;
    companystatusid:number;
    recordStatus:RecordStatus;
    CompanyType:CompanyType;
    CompanyContactInfo:CompanyContactInfo[];
    CompanySettings:CompanySetting[];
    CompanyStatus:CompanyStatus;
    Sites:Site[];
    _isDirty:boolean = false;
    _showDetail:boolean = false;
}

export class CompanyType{
    id:number;
    name:string;
    recordStatus:RecordStatus;
    appliesto:CompanyTypeAppliesTo;
    //CARRIER,SHIPPER,FREIGHT_FORWARDER,DEVELOPER,SERVICE_PROVIDER
}
export enum CompanyTypeAppliesTo{
    Provider,Customer
}

export class CompanyAssociation extends AnObject{
    companyid:number;//TODO rename this customerid
    customerid:number;//TODO rename this providerid
    Customer:Company;
    Provider:Company;
    Services:Service[];
    Priorities:RatePriority[];
    
    _baseSite:Site;
    constructor(){
        super();
        this.Services = [];
        this.Priorities = [];
    }
}

export class CompanyStatus{
    id:number;
    sequence:number;
    description:string;
    recordStatus:RecordStatus;
}

export class Site{
    id:number;
    name:string;
    companyid:number;
    sitetypeid:number;
    addressid:number;
    portid?:number;
    recordStatus:RecordStatus;
    Address:Address;
    SiteType:SiteType;
    Company:Company;
    Users:User[];
    Invitations:Invitation[];
    //_formGroup:FormGroup;
    _isDirty:boolean = false;
    Locode:Locode;
}
export class SiteType{
    id:number;
    description:string;
    recordStatus:RecordStatus;
    //
}
export class SiteToPort extends AnObject{
    siteid:number;
    locodeid:number;
}

export enum SiteTypes{
    HEAD_OFFICE=1,OFFICE,WAREHOUSE,DISTRIBUTION_CENTRE,PORT,AIRPORT,FACTORY,CONSOLIDATION_CENTRE
}


export class CompanyContactInfo{
    id:number;
    companyid:number;
    companycontactinfotypeid:number;
    information:string;
    recordStatus:RecordStatus;
    CompanyContactInfoType:CompanyContactInfoType;
    
}
export class SiteContactInfo{//TODO: not yet implemented
    id:number;
    siteid:number;
    companycontactinfotypeid:number;
    information:string;
    recordStatus:RecordStatus;
    CompanyContactInfoType:CompanyContactInfoType;
    
}

export class CompanyContactInfoType{
    id:number;
    description:string;
    recordStatus:RecordStatus;
    //PHONE_NUMBER,MOBILE_NUMBER,WEBSITE,EMAIL
}

export class Address{
    id:number;
    flatOrSuiteNumber:string;
    floorNumber:number;
    houseNameOrNumber:string;
    street:string;
    town:string;
    city:string;
    county:string;
    countryid:number;
    state:string;
    country:string;
    postcode:string;
    what3words:string;
    gpslat:number;
    gpslong:number;
    mapzoom:number;
    recordStatus:RecordStatus;
    Country:Country;
    _isDirty:boolean = false;
    addressstring:string;
    //AddressComponents:AddressComponent[];
    //_formGroup:FormGroup;
    constructor(){
        this.recordStatus = RecordStatus.Active;
        //this.AddressComponents = [];
    }
}

export class AddressComponent extends AnObject{
    
    addressid:number;
    long_name:string;
    short_name:string;
    types:string[];
    constructor(){
        super();
        this.types=[];
    }
}

export class Role{
    id:number;
    description:string;
    recordStatus:RecordStatus;
    
}

export enum Roles{
    MASTER_ADMIN=1,COMPANY_ADMIN,COMPANY_USER,VIEW_ONLY
}


export class UserRole{
    id:number;
    userid:number;
    roleid:number;
    recordStatus:RecordStatus;
    Role:Role;
}

export class UserSettingType{
    id:number;
    description:string;
    recordStatus:RecordStatus;
}

export class UserSetting{
    id:number;
    userid:number;
    usersettingtypeid:number;
    UserSettingType:UserSettingType;
    setting:string;
    settingon:boolean;
    recordStatus:RecordStatus;
}

export class CompanySettingsCategory extends AnObject{
    description:string;
    sequence:number;

    CompanySettingTypes:CompanySettingType[];
}

export enum CompanySettingCategories{

}

export class CompanySettingType extends AnObject{
    companysettingcategoryid:number;
    sequence:number;
    description:string;

    Setting:CompanySetting;
}

export enum CompanySettingTypes{
    Theme=1,Logo,DefaultCurrency,ShowSizeBracket,DefaultSizeBracket
}

export class CompanySetting extends AnObject{
    companyid:number;
    companysettingtypeid:number;
    setting:string;
    CompanySettingType:CompanySettingType;
}

export class NewCompanySettingType{
    settingtype:CompanySettingType;
    newsetting:string;
}

export class StatusMessage{
    success:boolean;
    message:any;
}


