<div *ngIf="auth.user" class="fl fdc">
    <div *ngIf="auth.logo.length>0" class="slogo pointer {{closed?'closed':''}}" routerLink="/" routerLinkActive="active"><img
            alt="white label logo" src="{{auth.logo}}" title="White Label Logo"></div>
    <div *ngIf="auth.logo.length==0" class="stextlogo pointer {{closed?'closed':''}}" routerLink="/" routerLinkActive="active">
        <span>{{auth.sitename}}</span>
    </div>
    <div *ngFor="let mod of auth.user.ModuleUsers" [ngClass]="{'side-active':route==mod.Module.route}">
        <div class="f1 side-menu-item {{closed?'closed':''}}" *ngIf="mod.Module.name!='Settings'">
            <div class="side-menu-icon">
                <mat-icon [routerLink]="[mod.Module.route]" routerLinkActive="active">{{mod.Module.icon}}</mat-icon>
            </div>
            <div class="side-menu-text {{closed?'closed':''}}" [attr.aria-label]="mod.Module.name" [title]="mod.Module.name">
                <a [routerLink]="[mod.Module.route]" routerLinkActive="active">{{mod.Module.name}}</a>
            </div>
        </div>

        <ng-container *ngIf="mod.Module.name=='Settings'">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header class="{{closed?'closed':''}}">
                        <mat-panel-description>
                            <div class="f1 side-menu-item {{closed?'closed':''}}">
                                <div class="side-menu-icon">
                                    <mat-icon [routerLink]="['settings']" routerLinkActive="active">settings</mat-icon>
                                </div>
                                <div class="side-menu-text {{closed?'closed':''}}" aria-label="Settings" title="Settings">
                                    <a [routerLink]="['settings']" routerLinkActive="active">Settings</a>
                                </div>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngFor="let page of settingspages;let s = index;" class="side-menu-item-sub {{closed?'closed':''}}">
                        <div class="side-menu-icon">
                            <mat-icon [routerLink]="['settings',s]" routerLinkActive="active"
                                [attr.aria-label]="page.name">
                                {{page.icon}}</mat-icon>
                        </div>
                        <div class="side-menu-text {{closed?'closed':''}}" [attr.aria-label]="page.name" [title]="page.name">
                            <a [routerLink]="['settings',s]" routerLinkActive="active">{{page.name}}</a>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </div>
</div>
<div class="f1"></div>
<div class="tar">
    <mat-icon (click)="closed=!closed" class="pointer">{{closed?'chevron_right':'chevron_left'}}</mat-icon>
</div>