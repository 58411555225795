<div class="quoter fl fdc">
    <!--Site-->
    <div *ngIf="showInput" [@inOutAnimation]>
        <div class="f1 fl">
            <div class="f1 fl fdc">
                <!--Locators-->
                <div class="fl pad">
                    <div class="f1 section">
                        <h2>Origin</h2>
                        <div class="f1 fl aic">
                            <div class="mr">
                                <div class="fl jc">
                                    <button class="mr" type="button" mat-mini-fab aria-label="geocode" color="primary"
                                        title="Geocode Address">
                                        <mat-icon (click)="selectLocation(true)">my_location</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <mat-form-field *ngIf="data.Company">
                                        <mat-label>Origin Site</mat-label>
                                        <mat-select [ngModel]="quoteInput.originSite" ngDefaultControl
                                            (selectionChange)="changeOriginSite($event)">
                                            <mat-option *ngFor="let site of data.Company.Sites" [value]="site">
                                                {{site.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div>
                                <cs-address-short [address]="quoteInput.origin"></cs-address-short>
                            </div>
                        </div>
                    </div>
                    <div class="f1 section">
                        <h2>Destination</h2>
                        <div class="f1 fl aic tar">
                            <div class="f1 tar">
                                <cs-address-short [address]="quoteInput.destination"></cs-address-short>
                            </div>
                            <div class="ml ">
                                <div class="fl jc">
                                    <button class="mr" type="button" mat-mini-fab aria-label="geocode" color="primary"
                                        title="Geocode Address">
                                        <mat-icon (click)="selectLocation()">my_location</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <mat-form-field *ngIf="data.Company">
                                            <mat-label>Destination Site</mat-label>
                                            <mat-select [ngModel]="quoteInput.destinationSite" ngDefaultControl
                                                (selectionChange)="changeDestinationSite($event)">
                                                <mat-option *ngFor="let site of data.Company.Sites" [value]="site">
                                                    {{site.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="f1 fl">
                    <mat-tab-group #itemstab class="f1 pad" (selectedTabChange)="changeItem($event)">
                        <mat-tab *ngFor="let item of quoteInput.quoteItems;let qi=index">
                            <ng-template mat-tab-label>
                                <span>Item {{qi+1}}</span>
                            </ng-template>
                            <div class="ovh">
                                <div class="tar">
                                    <mat-icon *ngIf="qi>0" class="warn pointer" (click)=removeItem(qi)>delete
                                    </mat-icon>
                                </div>
                                <cs-sizer *ngIf="item.ratebracket" #sizer [quoteItem]="item"
                                    [quoteOptions]="quoteInput.quoteOptions" [settings]="settings"
                                    [ratebrackets]="data.ratebrackets">
                                </cs-sizer>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon>add</mat-icon>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Options
                            </ng-template>
                            <div class="tal pad ovh">
                                <div class="mt">
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[0].selected"
                                        ngDefaultControl class="mr">
                                        <span>Only show services with collection capability</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[1].selected"
                                        ngDefaultControl class="mr">
                                        <span>Only show services with delivery capability</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[2].selected"
                                        ngDefaultControl class="mr">
                                        <span>Collection requires tail lift</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[3].selected"
                                        ngDefaultControl class="mr">
                                        <span>Delivery requires tail lift</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[4].selected"
                                        ngDefaultControl class="mr">
                                        <span>Collection address is residential</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[5].selected"
                                        ngDefaultControl class="mr">
                                        <span>Delivery address is residential</span>
                                    </mat-slide-toggle>
                                </div>
                                <div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="quoteInput.quoteOptions[6].selected"
                                        ngDefaultControl class="mr">
                                        <span>Shipment contains hazardous items</span>
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>

    </div>

    <div class="mt fl jc">
        <div class="fl mt f1 mw100">
            <div class="f1 mw100">
                <div class="fl">
                    <div class="fl ma">
                        <div class="fl" *ngIf="coid">
                            <cs-company-search [iscustomers]="true" class="mr" [host]="'quoter'">
                            </cs-company-search>
                            <!--<button mat-mini-fab aria-label="Quote" color="primary" (click)="fromStore()" class="mr">
                            <mat-icon>upload</mat-icon>
                        </button>-->
                            <mat-form-field>
                                <mat-label>Service Required</mat-label>
                                <input matInput [matDatepicker]="servicepicker" [ngModel]="quoteInput.servicedate">
                                <mat-datepicker-toggle matSuffix [for]="servicepicker"></mat-datepicker-toggle>
                                <mat-datepicker #servicepicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field *ngIf="quote">
                                <mat-label>Quote Expires</mat-label>
                                <input matInput [matDatepicker]="expirespicker" [ngModel]="quoteInput.expires">
                                <mat-datepicker-toggle matSuffix [for]="expirespicker"></mat-datepicker-toggle>
                                <mat-datepicker #expirespicker></mat-datepicker>
                            </mat-form-field>
                            <button mat-mini-fab aria-label="Quote" color="primary" (click)="calculate2()"
                                [disabled]="!quoteInput.origin || !quoteInput.destination" class="mr ml">
                                <mat-icon>calculate</mat-icon>
                            </button>
                        </div>
                        <button *ngIf="!showInput" mat-mini-fab aria-label="Show Input" color="primary"
                            (click)="showInput=true" class="mr">
                            <mat-icon>aspect_ratio</mat-icon>
                        </button>
                        <button mat-mini-fab aria-label="Save" color="primary" (click)="actions()" *ngIf="quote">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-tab-group *ngIf="quote" class="cancel-overflow" (selectedTabChange)="changeView($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Overview
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="pad">
                                <cs-quote-option-container [quote]="quote"></cs-quote-option-container>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <!--Customer View-->
                    <mat-tab *ngIf="quote && quote.portoptions && quote.portoptions.length>0">
                        <ng-template mat-tab-label>
                            Quotation
                        </ng-template>

                        <ng-template matTabContent>
                            <!--Struggling to see the value of these tabs being part of a form?
                                    tab change handle the selected option form update as well as object update
                                    trying below with raw object
                                    <div class="mt">
                                    <form *ngIf="quotation" [formGroup]="quotation">
                                        <mat-tab-group [selectedIndex]="quote.selectedOption"
                                            formArrayName="portoptions" (selectedTabChange)="optionChange($event)">
                                            <mat-tab *ngFor="let portoption of portoptions.controls;let i=index;"
                                                [formGroup]="portoption" class="mt">
                                                <ng-container *htmlVar="portoption.get('mode').value as mode">
                                                    <ng-container *htmlVar="portoption.get('total') as total">
                                                        <ng-template mat-tab-label>
                                                            Option {{i+1}}
                                                            <span class="ml tm tmw tm{{mode}}"></span>
                                                            ({{(option.metrics?option.metrics.duration:0) | duration}})
                                                            {{total.get('discountedprice').value | currency:data.defaultCurrency.currencyCode}}
                                                        </ng-template>

                                                    </ng-container>
                                                </ng-container>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </form>
                                </div>-->
                                
                            <div class="mt">
                                <form *ngIf="quotation">
                                    <mat-tab-group [selectedIndex]="quote.selectedOption"
                                        (selectedTabChange)="optionChange($event)">
                                        <mat-tab *ngFor="let portoption of quote.portoptions;let i = index;" class="mt">
                                            <ng-template mat-tab-label>
                                                Option {{i+1}}
                                                <span class="ml tm tmw tm{{portoption.mode}}"></span>
                                                ({{(portoption.metrics?portoption.metrics.duration:0) | duration}})
                                                {{portoption.total.discountedprice | currency:data.defaultCurrency.currencyCode}}
                                            </ng-template>
                                        </mat-tab>
                                    </mat-tab-group>
                                </form>
                            </div>
                            <div class="pad">
                            <cs-quote-view [quote]="quote" [setPortOptions]="quote.selectedOption"
                                [customer]="customer"></cs-quote-view>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="quote && quote.portoptions && quote.portoptions.length>0">
                        <ng-template mat-tab-label>
                            Service Legs
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="pad">
                                <cs-step-container [quote]="quote"></cs-step-container>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <!--Detail-->
                    <mat-tab *ngIf="!customer && quote && quote.portoptions && quote.portoptions.length>0">
                        <ng-template mat-tab-label>
                            Detail
                        </ng-template>
                        <div class="mt">
                            <div class="fl">
                                <div class="f1 pad">
                                    <cs-address-short [address]="quote.quoteInput.origin" [title]="'Origin'"></cs-address-short>
                                </div>
                                <div class="f1 pad">
                                    <cs-address-short [address]="quote.quoteInput.destination" [title]="'Destination'"></cs-address-short>
                                </div>
                                <div class="f2 fl fdc pad">
                                    <cs-quote-options-view [quoteOptions]="quote.quoteInput.quoteOptions" [customer]="customer">
                                    </cs-quote-options-view>
                                </div>
                            </div>
                            <div class="fl fw pad">
                                <div class="item" *ngFor="let quoteitem of quote.quoteInput.quoteItems; let i = index;">
                                    <cs-size-view [size]="quoteitem" [customer]="true" [index]="i+1"></cs-size-view>
                                </div>
                            </div>
                            <div class="pad"> 
                            <form *ngIf="quotation" [formGroup]="quotation">
                                <mat-tab-group #tabportoptions formArrayName="portoptions"
                                    (selectedTabChange)="optionChange($event)">
                                    <mat-tab *ngFor="let portoption of portoptions.controls;let i=index;"
                                        [formGroup]="portoption" class="mt">
                                        <ng-container *htmlVar="portoption.get('mode').value as mode">
                                            <ng-container *htmlVar="portoption.get('total') as total">
                                                <ng-template mat-tab-label>
                                                    Option {{i+1}}
                                                <span class="ml tm tmw tm{{mode}}"></span>
                                                {{total.get('discountedprice').value | currency:data.defaultCurrency.currencyCode}}
                                                </ng-template>
                                                <div class="mt">
                                                    <div>
                                                        <h3 class="tal primary" title="{{portoption.get('service').get('id').value}}">
                                                            {{portoption.get('service').get('description').value}}
                                                        </h3>
                                                        <table class="natural-width td1-250">
                                                            <tr>
                                                                <td colspan="7" class="enhanced-labels">
                                                                    <mat-tab-group #collectiontabs
                                                                        *ngIf="portoption.get('originSites')"
                                                                        (selectedTabChange)="originSiteTabChange($event);">
                                                                        <mat-tab
                                                                            *ngFor="let ositeregion of portoption.get('originSites').controls;index as j"
                                                                            id="colltab_{{i}}_{{j}}">
                                                                            <ng-template mat-tab-label>
                                                                                <div [formGroup]="ositeregion"
                                                                                    *ngIf="ositeregion">
                                                                                    {{ositeregion?ositeregion.value.site.Company.name:''}}
                                                                                    ({{ositeregion.get('totalCollection').value
                                                                                    |
                                                                                    currency:data.defaultCurrency.currencyCode}})
                                                                                </div>
                                                                            </ng-template>
                                                                            <cs-port-delivery
                                                                                [siteregionform]="ositeregion"
                                                                                (onchange)="quotePriceChange()"
                                                                                [mode]="mode"></cs-port-delivery>
                                                                        </mat-tab>
                                                                    </mat-tab-group>
                                                                </td>
                                                            </tr>
                                                            <ng-container formArrayName="servicerates">
                                                                <tr>
                                                                    <th colspan="5" class="tal">
                                                                        {{mode==modes.Air?'Air':'Sea'}}port to
                                                                        {{mode==modes.Air?'Air':'Sea'}}port Charges
                                                                    </th>
                                                                </tr>
                                                                <ng-container
                                                                    *ngFor="let sr of portoption.get('servicerates').controls">
                                                                    <tr cs-quote-price [quoteprice]="sr"
                                                                        (onchange)="quotePriceChange()"></tr>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container formArrayName="servicesurcharges">
                                                                <tr>
                                                                    <th colspan="5" class="tal">
                                                                        {{mode==modes.Air?'Air':'Sea'}}port to
                                                                        {{mode==modes.Air?'Air':'Sea'}}port
                                                                        Surcharges</th>
                                                                </tr>
                                                                <ng-container
                                                                    *ngFor="let sc of portoption.get('servicesurcharges').controls">
                                                                    <tr cs-quote-price [quoteprice]="sc"
                                                                        (onchange)="quotePriceChange()"></tr>
                                                                </ng-container>
                                                            </ng-container>

                                                            <tr>
                                                                <td colspan="7" class="enhanced-labels">
                                                                    <mat-tab-group #deliverytabs
                                                                        *ngIf="portoption.get('destinationSites')"
                                                                        (selectedTabChange)="deliveryTabChange($event);">
                                                                        <mat-tab
                                                                            *ngFor="let dsiteregion of portoption.get('destinationSites').controls">
                                                                            <ng-template mat-tab-label>
                                                                                <div [formGroup]="dsiteregion"
                                                                                    *ngIf="dsiteregion">
                                                                                    {{dsiteregion?dsiteregion.value.site.Company.name:''}}
                                                                                    ({{dsiteregion.get('totalCollection').value
                                                                                    |
                                                                                    currency:data.defaultCurrency.currencyCode}})
                                                                                </div>
                                                                            </ng-template>
                                                                            <div>
                                                                                <mat-tab-group #priorities
                                                                                    (selectedTabChange)="deliveryPriorityChange($event);">
                                                                                    <mat-tab
                                                                                        *ngFor="let prior of dsiteregion.get('priorities').controls">
                                                                                        <ng-template mat-tab-label>
                                                                                            {{prior.value.name}}
                                                                                        </ng-template>
                                                                                        <div>
                                                                                            <cs-port-delivery
                                                                                                [siteregionform]="dsiteregion"
                                                                                                [delivery]=true
                                                                                                [mode]="mode"
                                                                                                (onchange)="quotePriceChange()">
                                                                                            </cs-port-delivery>
                                                                                        </div>
                                                                                    </mat-tab>
                                                                                </mat-tab-group>

                                                                            </div>

                                                                        </mat-tab>
                                                                    </mat-tab-group>
                                                                </td>
                                                            </tr>
                                                            <ng-container formArrayName="quotesurcharges">
                                                                <tr>
                                                                    <th colspan="5" class="tal">Administration
                                                                        Surcharges
                                                                    </th>
                                                                </tr>
                                                                <ng-container
                                                                    *ngFor="let qs of portoption.get('quotesurcharges').controls">
                                                                    <tr cs-quote-price [quoteprice]="qs"
                                                                        (onchange)="quotePriceChange()"></tr>
                                                                </ng-container>
                                                            </ng-container>
                                                            <tr>
                                                                <th colspan="7" class="tal"> </th>
                                                            </tr>

                                                            <tr [formGroup]="portoption.get('balance')">
                                                                <td colspan="2">
                                                                    <div>Balance </div>
                                                                    <div class="small-note" *ngIf="minmargin">
                                                                        (minimum
                                                                        margin:
                                                                        {{minmargin.fixed |
                                                                        currency:data.defaultCurrency.currencyCode}}
                                                                        {{minmargin.percent | number:'1.1-2'}}%).
                                                                        Added
                                                                        to admin fee on Customer Quote</div>

                                                                </td>
                                                                <td>
                                                                    <mat-form-field class="form-skinny mr">
                                                                        <mat-label>Cost Price</mat-label>
                                                                        <input matInput placeholder="Cost" type="number"
                                                                            formControlName="costprice" readonly="true"
                                                                            csInputDecimal>
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field class="form-skinny mr">
                                                                        <mat-label>List Price</mat-label>
                                                                        <input matInput placeholder="List" type="number"
                                                                            formControlName="listprice"
                                                                            autocomplete="new-list-price"
                                                                            readonly="true" csInputDecimal>
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field class="form-skinny mr">
                                                                        <mat-label>Discounted Price</mat-label>
                                                                        <input matInput placeholder="Discount"
                                                                            type="number"
                                                                            formControlName="discountedprice"
                                                                            autocomplete="new-discounted-price"
                                                                            readonly="true" csInputDecimal>
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field class="form-skinny mr">
                                                                        <mat-label>Margin %</mat-label>
                                                                        <input matInput placeholder="Margin %"
                                                                            type="number" formControlName="margin"
                                                                            autocomplete="new-quote-price-margin"
                                                                            readonly="true">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr class="total" cs-quote-price [quoteprice]="total"
                                                                (onchange)="quotePriceChange()" [istotal]="true">
                                                            </tr>

                                                        </table>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </mat-tab>
                                </mat-tab-group>
                            </form>
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>


    </div>

</div>