<div class="ovh">
    <h3>Quote Options</h3>
    <mat-radio-group aria-label="Quote Options" (change)="quoteChoice($event)" [(ngModel)]="selectedOption" ngDefaultControl>
        
        <div class="pad">
            <mat-radio-button value="1" class="mr">Save Draft</mat-radio-button>
        </div>
        <form *ngIf="selectedOption==1" class="fl" [formGroup]="saveform">
            <div class="f1">
                <mat-form-field class="mr">
                    <mat-label>Your Reference</mat-label>
                    <input matInput type="text" placeholder="description" formControlName="description">
                </mat-form-field>
                <mat-checkbox [(ngModel)]="autogenerate" [ngModelOptions]="{standalone: true}" (change)="autoRef()">Auto Generate</mat-checkbox>
            </div>
            <div>
                <button type="button" mat-mini-fab aria-label="save quote" color="primary" title="Save Quote"
                    [disabled]="!saveform.valid">
                    <mat-icon class="pointer invite" (click)="save()">save</mat-icon>
                </button>
            </div>
        </form>
        <div class="pad">
            <mat-radio-button value="2" class="mr">Send Quote</mat-radio-button>
        </div>
        <form *ngIf="selectedOption==2" class="fl" [formGroup]="emailform">
            <div class="f1">
                <mat-form-field class="mr">
                    <mat-label>Email Address</mat-label>
                    <input matInput type="text" placeholder="email" formControlName="email">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Salutation</mat-label>
                    <input matInput type="text" placeholder="salutation" formControlName="salutation">
                </mat-form-field>
            </div>
            <div>
                <button type="button" mat-mini-fab aria-label="send quote" color="primary" title="Send Quote"
                    [disabled]="!emailform.valid">
                    <mat-icon class="pointer invite" (click)="send()">send</mat-icon>
                </button>
            </div>
        </form>
        <div class="pad">
            <mat-radio-button value="3" class="mr">Export</mat-radio-button>
        </div>
        <div *ngIf="selectedOption==3" class="fl mt ">
            <div class="f1">
                <mat-form-field>
                    <mat-label>Export Format</mat-label>
                    <mat-select [(ngModel)]="exportformat" ngDefaultControl>
                        <mat-option [value]="1">
                            Freightology Quote Options json
                        </mat-option>
                        <mat-option [value]="2">
                            Freightology Quote json
                        </mat-option>
                        <mat-option [value]="3">
                            Freightology Quote Options xml
                        </mat-option>
                        <mat-option [value]="4">
                            Freightology Quote xml
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <button type="button" mat-mini-fab aria-label="export quote" color="primary" title="Export Quote">
                    <mat-icon class="pointer invite" (click)="export()">get_app</mat-icon>
                </button>
            </div>


        </div>

    </mat-radio-group>
    <div class="close-dialog">
        <button mat-raised-button color="warn" (click)="close()" type="button">Cancel</button>
    </div>
</div>