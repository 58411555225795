import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[csInputDecimal]'
})
export class InputDecimalDirective {

  constructor(private ngControl: NgControl) { }

  ngAfterViewInit() {
    this.formatValue();
    setTimeout(() => {
      this.ngControl.valueChanges.subscribe(() => {
        this.formatValue();
      })
      
    })

  }

  formatValue(): string {
    if (!this.ngControl.value) return "0";

    let stringed = this.ngControl.value.toString();
    if (stringed.indexOf('.') < 0) return stringed += ".00";

    let dec: string[] = stringed.split('.');

    if (dec.length == 2) {
      let places = dec[1].length;
      if (places == 2) return stringed;
      if (places == 1) {
        stringed += "0";
      }
      if(places>2){
        let three = dec[1].substring(0,3);
        let floatthree = parseFloat(three);
        let floattwo = floatthree/10;
        stringed = dec[0]+"."+Math.round(floattwo).toString();
      }
    }
    this.ngControl.control.setValue(stringed);
  }
}
