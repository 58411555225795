import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Surcharge, PriceAppliesTo } from 'src/app/models/rates';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-surcharge-view',
  templateUrl: './surcharge-view.component.html',
  styleUrls: ['./surcharge-view.component.scss']
})
export class SurchargeViewComponent implements AfterViewInit {

  @Input() surcharges:Surcharge[]=[];

   

  
  constructor(public data:DataService) { }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.surcharges.forEach(s=>{
      s._currencyView = this.data.currencyView(s.currencyid,s.perunit);
    })
    })
    
  }

}
