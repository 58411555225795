import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Site, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { Region, RatePriority, Rate } from 'src/app/models/rates';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-rates-view',
  templateUrl: './rates-view.component.html',
  styleUrls: ['./rates-view.component.scss']
})
export class RatesViewComponent implements OnInit {

  //TODO this is a copy of rate-editor-provider without the editing functionality, abstract the two

  @Input() siteid:number;
  @Input() providerid:number;
  @Input() justrates:boolean;
  //@Output() noversions = new EventEmitter<number>();
  
  public versions:Region[] = [];
  public selectedVersion:Region;
  public bands:Region[]=[];
  public priorities:RatePriority[]=[];
  public selectedPriority:RatePriority;

  public selectedMode = 0;

  constructor(public data:DataService,public tools:ToolsService) { }

  ngOnInit(): void {
    this.getEverything().then(success=>{
      if(success){
        this.tidyupPriorities();
      }
    });
  }
    /**
   * versions, regions, priorities, bands, rates
   */
  getEverything() {
    return new Promise(resolve => {
      this.listVersions().then(success1 => {
        if (success1) {
          this.getRegions().then(success2 => {
            if (success2) {
              this.getPriorities().then(success3 => {
                if (success3) {
                  this.generatePriceBands();
                  this.getRates().then(success => {
                    if (success){
                      resolve(true);
                    } 
                    else resolve(false);
                  });
                }
                else resolve(false);
              });
            }
            else resolve(false);
          })

        }
        else resolve(false);
      });
    })
  }
  /**version has changed, reload everything */
  getNewVersionData() {
    return new Promise(resolve => {
      this.getRegions().then(success2 => {
        if (success2) {
          this.getPriorities().then(success3 => {
            if (success3) {
              this.generatePriceBands();
              this.getRates().then(success => {
                if (success) {
                  resolve(true);
                }
                else resolve(false);
              });
            }
            else resolve(false);
          });
        }
        else resolve(false);
      })
    });
  }
  listVersions(){
    return new Promise((resolve)=>{
      //
      this.data.listVersions(this.siteid,0,this.justrates).then((message:StatusMessage)=>{
        if(message.success){
          this.versions = message.message;
          if(this.versions.length>0){
            if(!this.selectedVersion)this.selectedVersion = this.versions[0];
            resolve(true);
          }
          else {
            //this.noversions.emit(this.siteid);
            resolve(false);
          }
        }
        else{
          resolve(false);
        }
      })
    })
  }
    /**
   * priorities by companyid,regiontype, customerid
   */
  getPriorities() {
    return new Promise((resolve) => {
      this.data.listRatePriorities(this.providerid, this.selectedVersion.regiontypeid, this.data.Company.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.priorities = message.message;
          if (this.priorities.length > 0) {
            this.selectedPriority = this.priorities[0];
            resolve(true);
          }
        }
        else {
          resolve(false);
          this.tools.gracefulError(message.message);
        }
      }, err => {
        resolve(false);
        this.tools.gracefulError(err);
      })
    })

  }
    /**
   * regions from selected version
   */
  getRegions() {
    return new Promise((resolve) => {
      this.data.listRegionsGrandchildren(this.selectedVersion.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.bands = message.message;
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);
        }
      },
        err => {
          this.tools.gracefulError(err);
          resolve(false);
        });
    })
  }
    /**
   * generate a list of regionids from either the regions returned in getRegions, or their children if driving distance is involved.
   */
  generatePriceBands() {
    this.selectedPriority.Bands = [];
    this.priorities.forEach(p => {
      p.IsPopped = false;
      p.Bands = [];
      this.bands.forEach(b => {


        if (b.children && b.children.length > 0) {
          let driving = false;
          b.children.forEach(c => {
            if (c.drivingdistance) {
              driving = true;
              let clone = this.tools.priceBand(c);
              p.Bands.push(clone);
            }

          })
          if (!driving) {
            let blone = this.tools.priceBand(b);
            p.Bands.push(blone);
          }
        }
        else {
          let blone = this.tools.priceBand(b);
            p.Bands.push(blone);
        }
      })

    })

  }
    /**
   * get a list of rates for all the relevant regions, filtered by the selected priority
   * TO CONSIDER - get everything and filter afterwards.
   */
  getRates() {
    return new Promise((resolve) => {
      let regionids: number[] = this.selectedPriority.Bands.map(b => b.regionid);
      this.data.listRatesFromRegionArray(regionids, this.selectedPriority.id,this.selectedMode ).subscribe((message: StatusMessage) => {
        if (message.success) {
          let rates: Rate[] = message.message;
          rates.forEach(r => {
            let band = this.selectedPriority.Bands.filter(b => b.regionid == r.regionid)[0];
            let exists = band.Rates.filter(re=>re.id==r.id);
            if(exists.length>0){
              let bob = rates;
            }
            else band.Rates.push(r);

          })
          this.selectedPriority.IsPopped = true;
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);

        }
      },
        err => {
          this.tools.gracefulError(err);
          resolve(false);
        }
      );
    })

  }
    /**
   * if we add the region by reference, the rates will also be passed by reference. Clone instead.
   * @param band 
   */
  cloneBand(band: Region): Region {
    let clone: Region = new Region(band.companyid,band.zonetype);
    clone.name = band.name;
    clone.id = band.id;
    clone.Rates = [];
    return clone;
  }
  changeSite(e) {
    this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites.filter(c => c.id == e.value)[0];
    this.getEverything().then(success=>{
      if(success){
        this.tidyupPriorities();
      }
    });
  }
  changeVersion(e) {
    this.selectedVersion = this.versions[e.index];
    this.getNewVersionData().then(success=>{
      if(success){
        this.tidyupPriorities();
      }
    });
  }

  changePriority(e) {
    this.selectedPriority = this.priorities[e.index];
    if (!this.selectedPriority.IsPopped) {
      this.getRates().then(success=>{
        if(success){
          this.tidyupPriorities();
        }
      });
    }

  }
  tidyupPriorities(){
  }
}
