import { Component, OnInit, Input } from '@angular/core';
import { Region, PointModes, Rate, Surcharge } from 'src/app/models/rates';
import { Site, StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ServiceRequest } from 'src/app/models/ui';
import { Service } from 'src/app/models/data';
import { TableGenerator} from 'src/app/models/models';

@Component({
  selector: 'cs-rate-editor-ports',
  templateUrl: './rate-editor-ports.component.html',
  styleUrls: ['./rate-editor-ports.component.scss']
})
export class RateEditorPortsComponent implements OnInit {

  @Input() siteid:number;
  
  public showcard = false;
  public showimport = false;
  public editmode = false;

  public selectedVersion: Region;
  public selectedService:Service;
  public selectedMode = 1;

  public transportmodes = PointModes;

  public versions: Region[] = [];

  public homeSite: Site;
  public zoneindex = 0;

  public rate: Rate;
  public servicepath:string;
  public services: Service[];
  public surcharges:Surcharge[]=[];

  public tabgen: TableGenerator = new TableGenerator();

  constructor(public data: DataService, public tools: ToolsService) { }

  ngOnInit(): void {
    this.homeSite = this.data.basesite;
    this.tabgen.object = new Service();
    this.tabgen.pages = [5,10,25];
    this.tabgen.columns = this.tools.serviceColumns();

    this.listRegions().then((allgood: boolean) => {
      if (allgood) {
        if (this.selectedVersion) {
          this.listServices();
        }

      }
    })
  }
  listRegions() {
    return new Promise((resolve) => {
      this.data.listRegionsStartup(1, 0,this.siteid).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.versions = message.message;
          if (this.versions.length > 0) this.selectedVersion = this.versions[0];
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);
        }
      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      }

      );
    })
  }
  listServices() {
    this.selectedService = null;
    this.rate = null;
    if(this.selectedVersion.children && this.selectedVersion.children.length>=this.zoneindex){
      let destinations = this.selectedVersion.children[this.zoneindex].children;
      if(destinations){
        let servicecountries: number[] = destinations.map(ch => ch.countryid);
        let homecountry: number = this.homeSite.Address.countryid;
        let servicerequest: ServiceRequest = { home: homecountry, destinations: servicecountries, mode: this.selectedMode };
        this.data.listServicesModeRegions(servicerequest).subscribe((message: StatusMessage) => {
          if (message.success) {
            this.services = message.message;
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
    }

  }

  addRate() {
    let rate = new Rate(this.selectedVersion.children[this.zoneindex].id,0,this.selectedMode);
    rate.serviceid = this.selectedService.id;
    rate.currency = this.selectedService.currencyid;
    rate.createdAt = new Date();
    rate.volumeequivalent = 1;
    this.rate = rate;

  }
  addDummyRate() {

  }

  editRate() {

  }
  copyRate(){

  }
  selectService(element:Service){
    this.selectedService = element;
    this.servicepath = this.tools.servicePath(this.selectedService);
  }
  saveRate() {
    if(this.rate.id){
      this.data.updateRate(this.rate).subscribe((message:StatusMessage)=>{

      })
    }
    else{
      this.data.createRate(this.rate).subscribe((message:StatusMessage)=>{

      })
    }
  }
  showImport() {

  }

  changeMode(e) {
    this.listServices();
  }
  changeVersion(e) {
    this.listServices();
  }
  changeZone(e) {
    this.zoneindex = e.index;
    this.listServices();
  }
}
