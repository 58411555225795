<div class="fl f1 pad ovh">
    <div class="f1 fl fdc ovh">
        <div class="fl mt aic">
            <div class="f1 fl aic">
                <mat-form-field>
                    <mat-label>Scale</mat-label>
                    <mat-select [(ngModel)]="selectedView" (selectionChange)="viewChange($event)">
                        <mat-option value="1">
                            International
                        </mat-option>
                        <mat-option value="2">
                            Continental
                        </mat-option>
                        <mat-option value="3">
                            National
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--<h3 *ngIf="selectedView=='2'">{{selectedContinent.name}}</h3>
                <mat-form-field>
                    <mat-label>Continent</mat-label>
                    <mat-select [(ngModel)]="selectedContinent" (selectionChange)="changeContinent($event)">
                        <mat-option *ngFor="let cont of data.continents" [value]="cont">
                            {{cont.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>-->
                <!--<h3 *ngIf="selectedView=='3'">{{selectedCountry.name}}</h3>-->
                <mat-form-field *ngIf="selectedView=='3'">
                    <mat-label>Countries in {{selectedContinent.name}}</mat-label>
                    <mat-select [(ngModel)]="selectedCountry" (selectionChange)="changeCountry($event)">
                        <mat-option *ngFor="let country of countries" [value]="country">
                            <img class="flag" src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <cs-company-search #cosearch [host]="'regions'"></cs-company-search>
            </div>
            <!--
            <mat-radio-group aria-label="View" class="fl mt fw" (change)="viewChange($event)"
                [(ngModel)]="selectedView">
                <div class="f1 fl aic">
                    <mat-radio-button value="1">International</mat-radio-button>
                </div>
                <div class="f2 fl aic">
                    <mat-radio-button value="2" class="mr">Continental</mat-radio-button>
                    <mat-form-field>
                        <mat-label>Continent</mat-label>
                        <mat-select [(ngModel)]="selectedContinent" (selectionChange)="changeContinent($event)">
                            <mat-option *ngFor="let cont of data.continents" [value]="cont">
                                {{cont.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="f2 fl aic">
                    <mat-radio-button value="3" class="mr">National</mat-radio-button>
                    <mat-form-field>
                        <mat-label>Country</mat-label>
                        <mat-select [(ngModel)]="selectedCountry" (selectionChange)="changeCountry($event)">
                            <mat-option *ngFor="let country of data.countries" [value]="country">
                                <img class="flag" src="../../../assets/flags/{{country.flag}}.png" />{{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>



            </mat-radio-group>-->
        </div>
        <div class="f1 fl ovh" id="regiontree">
            <div class="f1 ovh fl fdc">
                <div class="fl aic hasbutton">
                    <div class="ts3" *ngIf="selectedView=='3'">Regions within {{selectedCountry.name}}</div>
                    <div class="ts3" *ngIf="selectedView=='2'">Regions within {{selectedContinent.name}}</div>
                    <div class="ts3" *ngIf="selectedView=='1'">International Regions</div>
                    <div class="ml">
                        <button type="button" mat-mini-fab aria-label="create region map" color="primary"
                            title="Create Region">
                            <mat-icon (click)="createRegion()">add</mat-icon>
                        </button>
                    </div>
                </div>
                <!--<button type="button" mat-mini-fab color="primary"
                    title="Show GEO">
                    <mat-icon (click)="showPainted()">add</mat-icon>
                </button>
            [ngStyle]="{'max-height.px': panelheight}
            -->
                <div class="f1 mt ovh fl">
                    <div class="scrollable-tree" cdkDropListGroup>
                        <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="regions">

                            <div *ngFor="let region of regions;let i = index">
                                <cs-region [region]="region">
                                </cs-region>
                            </div>

                        </div>
                    </div>
                </div>
                <!--
                <div *ngIf="addon.on" class="addonmenu" [ngStyle]="{'top.px':addon.click.y,'left.px':addon.click.x}" (mouseout)="prepareAddOff()">
                    <div class="fl aic pointer" (click)="addOption1()">
                        <mat-icon>add</mat-icon> Add Child
                    </div>
                    <div class="fl aic pointer" (click)="addOption1()">
                        <mat-icon>add</mat-icon> Add Child
                    </div>
                    <div class="fl aic pointer" (click)="addOption1()">
                        <mat-icon>add</mat-icon> Add Child
                    </div>
                    <div class="fl aic pointer  " (click)="addOption1()">
                        <mat-icon>add</mat-icon> Add Child
                    </div>
                </div>-->
            </div>
            <div class="f1 fl fdc pad"> <!-- *ngIf="preLoaded" removed in conjunction with settings tab change removal-->
                <!--<div class="hasbutton fl aic mt">
                    <div class="ts3" *ngIf="selectedRegion" (dblclick)="logPolys()">{{selectedRegion?selectedRegion.name:"&nbsp;"}}
                        <div>
                        <span class="mr">from</span>
                        <mat-form-field *ngIf="!selectedRegion.parentId">
                            <mat-label>Site</mat-label>
                            <mat-select [(ngModel)]="selectedRegion.basesiteid"
                                (selectionChange)="changeBaseSite($event)">
                                <mat-option *ngFor="let site of data.selectedSupplier.Provider.Sites" [value]="site.id">
                                    {{site.name}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                        <span *ngIf="selectedRegion.parentId"> {{data.selectedSupplier._baseSite?data.selectedSupplier._baseSite.name:''}}</span>
                    </div>
                    </div>
                </div>-->
                    <div *ngIf="data.selectedSupplier">
                        <div class="fl aic">
                            <div class="f1">
                                
                            </div>
                            <div><h3 class="mr" title="{{data.selectedSupplier._baseSite.id}}">Zoned From</h3></div>
                            <div>
                                <mat-form-field>
                                    <mat-label>Site</mat-label>
                                    <mat-select [ngModel]="data.selectedSupplier._baseSite.id"
                                        (selectionChange)="changeProviderSite($event)">
                                        <mat-option *ngFor="let site of data.selectedSupplier.Provider.Sites" [value]="site.id">
                                            {{site.name}}
                                        </mat-option>
                                    </mat-select>
        
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                
                <div class="f1 ovh fl fdc">
                    <h3>Included Regions</h3>
                    <div *ngIf="regionservice.selectedRegion && regionservice.selectedRegion.children" class="scrollable-tree" cdkDropList
                        (cdkDropListDropped)="dropInc($event)" [cdkDropListData]="regionservice.selectedRegion.children">
                        <div *ngFor="let child of regionservice.selectedRegion.children" hover-class="show-add">
                            <div *ngIf="child.recordStatus<2 && child.regiontypeid!=regiontypes.Excluded" class="fl aic">
                                <div class="mapkey"
                                    [ngStyle]="{'backgroundColor':child.RegionDisplayInfo?child.RegionDisplayInfo.colour.hex():'#FFFFFF'}">
                                </div>
                                <div class="f1">
                                    {{child.name}}</div>
                                <div>
                                    <mat-icon class="pointer" (click)="excludeRegion(child)" class="add-child">
                                        arrow_downward</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="regionservice.selectedRegion && !regionservice.selectedRegion.children">
                        <div>All included</div>
                    </div>
                </div>
                <div class="f1 ovh fl fdc">
                    <h3>Excluded Regions</h3>
                    <div *ngIf="regionservice.selectedRegion" class="scrollable-tree">
                        <div *ngFor="let region of excludedRegions" hover-class="show-add">
                            <div class="fl">
                                <div class="mapkey border"
                                    [ngStyle]="{'backgroundColor':region.RegionDisplayInfo.colour.hex(),'borderColor':region.RegionDisplayInfo.stroke.hex()}">
                                </div>
                                <div class="f1">{{region.name}}</div>
                                <div>
                                    <mat-icon class="pointer" (click)="includeRegion(region)" class="add-child">
                                        {{region.hierarchyLevel==regionservice.selectedRegion.hierarchyLevel+1?'arrow_upward':'block'}}
                                    </mat-icon>
                                </div>
                                <div>
                                    <mat-icon class="pointer" (click)="removeRegion(region)" class="add-child">
                                        arrow_downward</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="f1 ovh fl fdc">
                    <h3>No Service Offered</h3>
                    <div *ngIf="regionservice.selectedRegion" class="scrollable-tree">
                        <div *ngFor="let region of deletedRegions" hover-class="show-add">
                            <div class="fl">
                                <div class="mapkey border"
                                    [ngStyle]="{'backgroundColor':region.RegionDisplayInfo.colour.hex(),'borderColor':region.RegionDisplayInfo.stroke.hex()}">
                                </div>
                                <div class="f1">{{region.name}}</div>
                                <div>
                                    <mat-icon class="pointer" (click)="removeFromDeleted(region)" class="add-child">
                                        {{region.hierarchyLevel==regionservice.selectedRegion.hierarchyLevel+1?'arrow_upward':'block'}}
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="f1">
                    <h3>Excluded Sub-Regions</h3>
                    <div *ngIf="selectedRegion && selectedRegion.children" class="scrollable-tree"
                        [ngStyle]="{'max-height.px': panelheight/2}">
                        <div *ngFor="let child of selectedRegion.children" hover-class="show-add">
                            <div *ngIf="child.recordStatus==2 || child.regiontypeid==7" class="fl">
                                <div class="f1">{{child.name}}</div>
                                <div>
                                    <mat-icon class="pointer" (click)="includeRegion(child)"
                                        class="add-child">arrow_upward</mat-icon>
                                </div>
                                <div>
                                    <mat-icon class="pointer" (click)="removeRegion(child)"
                                        class="add-child">arrow_downward</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>-->

                <!--
                <div class="f1">
                    <h3>No Service Offered</h3>
                    <div *ngIf="selectedRegion && selectedRegion.children" class="scrollable-tree"
                        [ngStyle]="{'max-height.px': panelheight/2}">
                        <div *ngFor="let child of selectedRegion.children" hover-class="show-add">
                            <div *ngIf="child.recordStatus==3" class="fl">
                                <div class="mapkey" [ngStyle]="{'backgroundColor':this.deletedShade}"></div>
                                <div class="f1">{{child.name}}</div>
                                <div>
                                    <mat-icon class="pointer" (click)="includeRegion(child)"
                                        class="add-child">arrow_upward</mat-icon>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            -->
            </div>
        </div>
        <!--
        <mat-tab-group class="f1">
            <mat-tab>
                <ng-template mat-tab-label>
                    Defined Regions
                </ng-template>
                <ng-template matTabContent>

                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Time/Distance
                </ng-template>
                <ng-template matTabContent>
                    <div class="fl">
                        <div class="f1">
                            <h3 class="jc">Example Cities</h3>
                            <table *ngIf="citiesloaded">
                                <tr>
                                    <th class="tal">City</th>
                                    <th>Distance ({{imperial?'miles':'km'}})</th>
                                    <th>Time</th>
                                    <th>Charge</th>
                                </tr>
                                <tr *ngFor="let city of majorCities">
                                    <td>{{city.name}}</td>
                                    <td class="tac">{{distance(city.offset) | number:'1.0-0'}}</td>
                                    <td class="tac">{{time(city.offset) | date:'H:mm':'UTC'}}</td>
                                    <td class="tac">{{fee(city.offset) | currency:'GBP'}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="f1">
                            <div class="mlr2 jc">
                                <h3>Smooth Fee Structure</h3>
                                <p>Calculated from Customer's location.</p>
                                <div>
                                    <mat-form-field>
                                        <mat-label>Fixed Charge</mat-label>
                                        <input matInput type="number" placeholder="Fixed Charge" [(ngModel)]="fixed"
                                            autocomplete="new-password">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <mat-label>Charge per mile</mat-label>
                                        <input matInput type="number" placeholder="Charge per mile"
                                            [(ngModel)]="perMile" autocomplete="new-password">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <mat-label>Charge per hour</mat-label>
                                        <input matInput type="number" placeholder="Charge per hour"
                                            [(ngModel)]="perHour" autocomplete="new-password">
                                    </mat-form-field>
                                </div>



                            </div>
                        </div>
                    </div>


                </ng-template>
            </mat-tab>

        </mat-tab-group>-->
    </div>
    <div class="f1 fl">
        <div #map class="map f1"></div>
    </div>
</div>