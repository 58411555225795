import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, ChangePasswordModel, StatusMessage } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'cs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(this.data.email,[Validators.required,Validators.email]),
    password: new FormControl(this.data.password,[Validators.required]),
  });

  changePassForm = new FormGroup({
    existing: new FormControl("",[Validators.required]),
    newpassword: new FormControl("",[Validators.required]),
    confirmpassword: new FormControl("",[Validators.required])
  });

  changePassOn:boolean = false;
 
  constructor(public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User, public auth:AuthService, public tools:ToolsService) { }

    get username() { return this.loginForm.get('username'); }

    get password() { return this.loginForm.get('password'); }

    get existing() { return this.changePassForm.get('existing'); }

    get newpassword() { return this.changePassForm.get('newpassword'); }

    get confirmpassword() { return this.changePassForm.get('confirmpassword'); }
  
    ngOnInit() {
  }
  cancel(): void {
    let message:string;
    if(this.auth.user.token==""){
      message = "logoff";
    }
    this.dialogRef.close(message);
  }
  login(){
    this.data.email = this.loginForm.controls["username"].value;
    this.data.password = this.loginForm.controls["password"].value;
    this.dialogRef.close(this.data);
  }

  openChangePass(){
    this.changePassOn = true;
  }
  changePass(){
    let model = new ChangePasswordModel();
    model.id = this.auth.user.id;
    model.existingpassword = this.existing.value;
    model.newpassword = this.newpassword.value;
    model.confirmpassword = this.confirmpassword.value;
    if(model.newpassword != model.confirmpassword){
      this.tools.gracefulError("Your new passwords don't match");
    }
    else{
      this.auth.changePass(model).subscribe((message:StatusMessage)=>{
        if(message.success){
          this.tools.snackMessage("Password changed, we suggest you log out and log back in again to avoid authentication errors");
          this.cancelChangePass();
        }
        else{
          this.tools.gracefulError("Sorry, update failed");
        }
      })
    }

  }
  cancelChangePass(){
    this.changePassForm.reset();
    this.changePassOn = false;
  }

  logoff(){
    this.data.token = "";
    this.auth.user = new User();
    this.auth.signout();
    this.dialogRef.close("logoff");
  }
  emptyStore(){
    this.tools.clearStore("quote");
    this.tools.snackMessage("Session data cleared, please refresh your browser");
    this.dialogRef.close();
  }

  refresh(){
    this.auth.checkTokenExpiry();
  }

}

