import { Component, OnInit, Input } from '@angular/core';
import { Surcharge, SurchargeType, PriceAppliesTo } from 'src/app/models/rates';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CurrencySelectComponent } from 'src/app/dialogs/currency-select/currency-select.component';
import { DataService } from 'src/app/services/data.service';
import { CurrencyView } from 'src/app/models/ui';

@Component({
  selector: 'cs-surcharge',
  templateUrl: './surcharge.component.html',
  styleUrls: ['./surcharge.component.scss']
})
export class SurchargeComponent implements OnInit {

  @Input() editmode:boolean = false;
  @Input() surcharge:FormGroup;
  @Input() surchargeTypes:SurchargeType[];

  public priceAppliesTo = PriceAppliesTo;

  public currencyView:CurrencyView;
  
  constructor(public dialog: MatDialog,public data:DataService) { }

  get perunit(){
    return this.surcharge.get("perunit").value;
  }
  get unit(){
    return this.surcharge.get("unit").value;
  }

  get recordStatus(){
    return this.surcharge.get("recordStatus").value;
  }

  ngOnInit(): void {
    this.setCurrency();
  }

  setCurrency(){
    let surch = this.surcharge.value;
    this.currencyView = this.data.currencyView(surch.currencyid,surch.perunit);
  }

  deleteCharge(){
    this.surcharge.get("recordStatus").setValue(3);
    this.surcharge.markAsDirty();
  }

  selectCurrency(){
    const dialogRef = this.dialog.open(CurrencySelectComponent, {
      width: 'auto',
      data: { countries: this.data.countries.filter(c=>c.currencyCode)}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let cgroup = this.surcharge.get("currencyid");
        cgroup.setValue(result.id);
        this.setCurrency();
        cgroup.markAsDirty();
      }
    });
  }
  checkPerUnit(e){
    let unit = this.surcharge.get("unit");
    if(e.value>1){
      unit.enable();
    }
    else{
      unit.setValue(0);
      unit.disable();
    }
    this.setCurrency();
  }
}
