<div class="fl">
    <h3>Rate Card</h3>
    <div class="f1"></div>
    <div>
        <mat-icon class="pointer" color="warn" title="Close" (click)="closeCard()">cancel</mat-icon>
    </div>
</div>
<div class="fl ovh">
    <div class="f1 fl ovh">
        <div class="f1 fl mt overflow-scroll" *ngIf="envelope && envelope.Rates && envelope.Rates.length>0">
            <div class="f1 raterow">
                <div class="header">price per {{envelope.Rates[0].priceperbreak?'band':'kg'}}</div>
                <div>0 - {{envelope.Rates[0].baseIncludesWeight}}</div>
                <div *ngFor="let band of envelope.Rates[0].RateBreaks">
                    <span *ngIf="band.fixed>0">then up to </span>
                    {{band.maxweight}}
                </div>
            </div>
            <div *ngFor="let rate of envelope.Rates;let i=index" class="f1 raterow">
                <div class="header">{{rate.name}}</div>
                <div>{{rate.baseprice | currency:currencyCode }}</div>
                <div *ngFor="let break of rate.RateBreaks">
                    <span *ngIf="break.fixed>0">{{break.fixed | currency:currencyCode}} + </span>
                    {{break.price | currency:currencyCode}}
                    <span *ngIf="break.fixed>0"> per kg over {{break.minweight}}kg</span>
                </div>
            </div>
        </div>
    </div>
    <div class="f1">

    </div>
</div>