import { Component, OnInit, Input } from '@angular/core';
import { Region, RateEnvelope } from 'src/app/models/rates';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-rate-card-editor',
  templateUrl: './rate-card-editor.component.html',
  styleUrls: ['./rate-card-editor.component.scss']
})
export class RateCardEditorComponent implements OnInit {

  @Input() envelope:RateEnvelope;

  public currencyString = "£";
  public currencyCode = "GBP";
  
  constructor(public events:EventsService) { }

  ngOnInit(): void {
  }
  
  buildCard(){

  }

  closeCard(){
    this.events.closeCard.emit();
  }

}
