import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { EventsService } from 'src/app/services/events.service';
import { RateBreak, Rate } from 'src/app/models/rates';
import { RateBreakIndex } from 'src/app/models/ui';
import { DataService } from 'src/app/services/data.service';
import { FormtoolsService } from 'src/app/services/formtools.service';

@Component({
  selector: 'cs-rate-breaks-form',
  templateUrl: './rate-breaks-form.component.html',
  styleUrls: ['./rate-breaks-form.component.scss']
})
export class RateBreaksFormComponent implements OnInit {

  @Input() rategroup:FormGroup;
  @Input() unzoned:boolean;
  @Input() editmode:boolean;
  @Input() index = 0;

  public rate:Rate;
  public currencyCode:string = "GBP";
  public applytoall = true;

  get rateBreaks():FormArray{
    return <FormArray> this.rategroup.get("RateBreaks");
  }

  constructor(public events: EventsService,public data:DataService,public formtools:FormtoolsService) {
    this.events.addBreak.subscribe((rate: Rate) => {
      let parentrate  = this.rategroup.value;
      if (rate.regionid != parentrate.regionid) {
        this.addBreak();
      }
    })
    this.events.changeBreak.subscribe((rbi: RateBreakIndex) => {
      let breaks:FormArray = <FormArray> this.rategroup.get("RateBreaks");
      if (breaks.controls[rbi.index]) {
        let rb = breaks.controls[rbi.index];
        rb.get("maxvolume").setValue(rbi.rateBreak.maxvolume);
        rb.get("maxweight").setValue(rbi.rateBreak.maxweight);
        rb.get("minvolume").setValue(rbi.rateBreak.minvolume);
        rb.get("minweight").setValue(rbi.rateBreak.minweight);
        rb.get("volumebreak").setValue(rbi.rateBreak.volumebreak);
        rb.get("weightbreak").setValue(rbi.rateBreak.weightbreak);
        rb.get("breakbarrierincludes").setValue(rbi.rateBreak.breakbarrierincludes);
      }
    })

  }

  ngOnInit(): void {
    if(!this.editmode) this.rate = this.rategroup.value;
    if(this.unzoned) this.applytoall = false;
    this.setCurrency();
  }
  setCurrency(){
    let currencycountry = this.rategroup.value.currencyid;
    if(currencycountry>0){
      this.currencyCode = this.data.countries.filter(c=>c.id==currencycountry)[0].currencyCode;
    }
    
  }
  check(e,rb){
    console.log(e,rb);
  }
  addBreak() {
    let parentrate  = this.rategroup.value;
    let newbreak = new RateBreak();
        newbreak.rateid = parentrate.id;
        newbreak.breakbarrierincludes = parentrate.breakbarrierincludes;
        newbreak.priceperbreak = parentrate.priceperbreak;
        let breakgroup = this.formtools.createRateBreakGroup(newbreak);       
        let breakarray = <FormArray>this.rategroup.get("RateBreaks");
        breakarray.push(breakgroup);
        breakgroup.valueChanges.subscribe((e)=>{
          breakarray.markAsDirty();
          this.rategroup.markAsDirty();
        })
  }
  propogateBreakChange(rateBreak?: FormGroup, index?: number) {
    if (this.applytoall) {
      if (!rateBreak) {
        let eachindex=0;
        let breakarray = <FormArray>this.rategroup.get("RateBreaks");
        breakarray.controls.forEach(br=>{
          this.events.changeBreak.emit({index:eachindex,rateBreak:br.value});
          eachindex++;
        })
      }
      else {
        this.events.changeBreak.emit({ index: index, rateBreak: rateBreak.value });
      }
    }

  }

}
