import { Component, OnInit, Inject } from '@angular/core';
import { Region, RegionGroupType } from 'src/app/models/rates';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegiongrouperComponent } from '../../regiongrouper/regiongrouper.component';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-country-grouper',
  templateUrl: './country-grouper.component.html',
  styleUrls: ['./country-grouper.component.scss']
})
export class CountryGrouperComponent implements OnInit {

  public parentRegion: Region;
  public regiongroups: RegionGroupType[] = [];
  public regionName: string = "";
  public bands: number = 5;

  public distanceFrom = false;
  public custom = true;
  public selectedGroup: RegionGroupType;

  constructor(public dialogRef: MatDialogRef<RegiongrouperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any, public data: DataService, private tools: ToolsService) {
    this.parentRegion = dialogdata.Region;
  }

  ngOnInit(): void {

    this.countryGroupTypes();
  }
  countryGroupTypes() {
    this.data.getCountryRegion(this.parentRegion.countryid).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.data.listRegionGroupTypes(message.message.parentId).subscribe((message: StatusMessage) => {
          if (message.success) {
            let grouptypes: RegionGroupType[] = message.message;
            if (grouptypes.length > 0) {
              //override defaults.
              this.regiongroups = grouptypes;
            }
            else this.regiongroups = [];
            let distance = new RegionGroupType();
            distance.description = "Distance from " + this.data.selectedSupplier._baseSite.name;
            distance.id = -1;
            let custom = new RegionGroupType();
            custom.id = -2;
            custom.description = "Custom Groups";
            this.selectedGroup = custom;
            this.regiongroups.push(distance);
            this.regiongroups.push(custom);
          }
          else {
            this.tools.gracefulError(message.message);
          }
        }, err => {
          this.tools.gracefulError(err);
        })
      }
    }, err => {
      this.tools.gracefulError(err);
    });
  }

  cancel() {
    this.dialogRef.close();
  }
  create() {
    this.dialogRef.close({ which: this.selectedGroup, bands: this.bands });
  }

}
