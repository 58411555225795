<div class="body-wrap">
    <div class="loader" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="fl">
        <div>
            <button type="button" mat-mini-fab aria-label="Add Company" color="primary" title="Add Company"
                (click)="addCompany()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="f1">
        </div>
        <div>
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search on any field">
            </mat-form-field>
        </div>

    </div>
    <div class="f1 fl fdc">
        <div class="f1">
        <table mat-table [dataSource]="customers" matSort class="mat-elevation-z8 fixed">

            <ng-container matColumnDef="Provider.id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="id-field" colspan="1"> No. </th>
                <td mat-cell *matCellDef="let element" class="id-field" colspan="1"> {{iscustomers?element.Customer.id:element.Provider.id}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Provider.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="primary-cell" colspan="4"> Name
                </th>
                <td mat-cell *matCellDef="let element" class="primary-cell pointer" colspan="4">
                    {{iscustomers?element.Customer.name:element.Provider.name}} </td>
            </ng-container>

            <ng-container matColumnDef="Provider.companystatusid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="2"> Status </th>
                <td mat-cell *matCellDef="let element" colspan="2">
                    {{iscustomers?element.Customer.CompanyStatus.description:element.Provider.CompanyStatus.description}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Provider.companytypeid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="2"> Type </th>
                <td mat-cell *matCellDef="let element" colspan="2"> {{iscustomers?element.Customer.CompanyType.name:element.Provider.CompanyType.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Settings">
                <th mat-header-cell *matHeaderCellDef class="id-field" colspan="1"></th>
                <td mat-cell *matCellDef="let element" class="id-field" colspan="1">
                    <mat-icon color="primary" class="pointer" (click)="editCompany(element)">edit</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" matRipple [cdkDetailRow]="row"
                [cdkDetailRowTpl]="tpl"></tr>

        </table>
    </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
</div>
<ng-template #tpl let-element>
    <tr>
        <td [attr.colspan]="displayedColumns.length*2">
            <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
                <cs-company-detail [customer]="iscustomers?element.Customer:element.Provider"></cs-company-detail>
            </div>
        </td>
    </tr>
</ng-template>
