import { Injectable } from '@angular/core';
import { GoogleDistanceMatrix, GoogleMatrixRow, GoogleMatrixElement, GoogleValue, GoogleDistanceMatrixBuilder, Locode } from '../models/data';
import { } from 'googlemaps';
import { StatusMessage } from '../models/user';
import { Region, Polygon } from '../models/rates';
import { EventsService } from './events.service';

export interface DrawnPoly {
  regionid: number;
  poly: google.maps.Polygon[];
}

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  public map: google.maps.Map;
  public matrix: GoogleDistanceMatrix;
  public geocoder: google.maps.Geocoder;
  distanceService: google.maps.DistanceMatrixService;

  public selectedPolys: DrawnPoly[] = [];
  public excludedPolys: DrawnPoly[] = [];
  //public deletedPolys: DrawnPoly[] = [];

  constructor(public events: EventsService) { }

  /**
   * simple geocode of an address:string, possible issue of multiple calls in quick succession
   * @param address
   * returns a status message with message of type google placeResult 
   */
  geocode(address: string) {
    return new Promise((resolve, reject) => {
      if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode({ 'address': address }, (r, s) => {
        let statmessage = new StatusMessage();

        if (r && r.length > 0) {
          statmessage.success = true;
          statmessage.message = r;
          resolve(statmessage);
        }
        else {
          statmessage.success = false;
          resolve(statmessage);
        }

      });
    })
  }

  /**
   * returns a promise of type google.maps.placeResult from the provided google.latlng
   * @param latlng 
   */
  reverseGeocode(latlng: google.maps.LatLng) {
    return new Promise((resolve) => {
      if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            resolve({success:true,message:results[0]});
          } else {
            resolve({success:false,message:"no result"});
          }
        }
        else resolve({success:false,message:"no result"});
      });
    });

  }
  /**
   * given a google placeid return it's details in a promise of type google.place
   * @param placeId 
   * @param map 
   */
  placeFromMap(placeId: string, map: google.maps.Map) {
    return new Promise((resolve)=>{
      let placeservice = new google.maps.places.PlacesService(map);
      placeservice.getDetails({ placeId: placeId, fields: ["name", "geometry", "address_components"] }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve({success:true,message:place});
        }
        else resolve({success:false,message:status});
      })
    })
    
  }

  /**
   * geocode two addresses and calculate the Haversine distance between them
   * @param address1 
   * @param address2 
   */
  geocode2(address1: string, address2: string) {
    return new Promise((resolve, reject) => {
      this.geocode(address1).then((message1: StatusMessage) => {
        this.geocode(address2).then((message2: StatusMessage) => {
          let statusMessage = new StatusMessage();
          statusMessage.success = true;
          statusMessage.message = { geoOrigin: message1.message[0], geoDest: message2.message[0], haversine: this.haversineDifference(message1.message[0].geometry.location, message2.message[0].geometry.location) }
          resolve(statusMessage);
        })
      })
    });
  }
  /**
   * 
   * @param origins an array of origins of type place:string or google latlng
   * @param destinations an array of destinations of type place:string or google latlng
   * @param requestindex if sending chunks of data, they may not be returned in the same order as requested
   * @param travelMode google travel mode enum
   */
  drivingDistance(origins, destinations, haversineInstead: boolean, travelMode?) {



    return new Promise((resolve) => {

      if (!travelMode) {
        travelMode = 'DRIVING';
      }
      if(haversineInstead){
        resolve(this.dummyDrivingDistance(origins,destinations));
      }
      else{
        if (!this.distanceService) {
          this.distanceService = new google.maps.DistanceMatrixService();
        }
        let message: StatusMessage = new StatusMessage();
        this.distanceService.getDistanceMatrix({
          origins: origins,
          destinations: destinations,
          travelMode: travelMode
        }, (r, s) => {
          console.log("google driving distance called "+origins.length*destinations.length+" elements");
          if (s == "OK") {
            message.success = true;
            message.message = { result: r, status: s };
            resolve(message);
          }
          else {
            message.success = false;
            message.message = { result: r, status: s };
            resolve(message);
          }
  
        });
      }
 
    });

  }
  /**
   * given a distance matrix, return the nearest of the elements
   * @param matrix
   */
  nearestElement(matrix: GoogleDistanceMatrix): GoogleMatrixElement {
    let elements = matrix.rows[0].elements;
    let el: GoogleMatrixElement;
    let mindist = Infinity;
    let index = 0;
    elements.forEach(element => {
      if (element.distance.value < mindist) {
        el = element;
        el.index = index;
      }
      index++;
    })
    return el;
  }
  /**
  * used for testing, returns a havesine distance rather than driving distance - must use latlng not string
  * @param origins an array of origins of type place:string or google latlng
  * @param destinations an array of destinations of type google latlng
  * @param travelMode google travel mode enum
  */
  dummyDrivingDistance(origins: google.maps.LatLng[], destinations: google.maps.LatLng[]) {

    return new Promise((resolve, reject) => {

      let message: StatusMessage = new StatusMessage();
      message.success = true;
      let matrix = new GoogleDistanceMatrix();
      matrix.rows = [];

      origins.forEach(o => {
        let row = new GoogleMatrixRow();
        row.elements = [];
        destinations.forEach(d => {
          let element = new GoogleMatrixElement();
          let km = this.haversineDifference(o, d);
          let elvaldist = new GoogleValue();
          elvaldist.text = km + "km";
          elvaldist.value = Math.round(km * 1000);//metres
          let elvaltime = new GoogleValue();
          elvaltime.value = km * 60;
          element.distance = elvaldist;
          element.duration = elvaltime;
          element.status = "OK";
          row.elements.push(element);

        })
        matrix.rows.push(row);
      })

      message.message = {result:matrix};
      resolve(message);

    });

  }
  /**
   * given a set of origins and destinations split them into chunks for the distanceMatrix service
   * @param fakeit //use haversine distance to avoid api calls
   */
  async drivingDistancesBetweenRegions(origins: google.maps.LatLng[], destinations: google.maps.LatLng[], fakeit?: boolean) {
    return new Promise((resolve, reject) => {
      let matrix = new GoogleDistanceMatrix();
      matrix.originAddresses = origins.map(o => "Lat: " + o.lat() + " Lng:" + o.lng());
      matrix.destinationAddresses = destinations.map(d => "Lat: " + d.lat() + " Lng:" + d.lng());
      matrix.rows = [];


      //let requestsneeded = Math.ceil(destinations.length / 25) * origins.length;

      let destinationsarray: Array<google.maps.LatLng[]> = []//array of arrays, each with a 25 long array of latlngs
      let chunk = 25;
      for (let i = 0, j = destinations.length; i < j; i += chunk) {
        destinationsarray.push(destinations.slice(i, i + chunk));
      }
      let builder = new GoogleDistanceMatrixBuilder();
      builder.origins = origins;
      builder.destinationsarray = destinationsarray;
      builder.originindex = 0;
      builder.destinationsarrayindex = 0;
      builder.requestsmade = 0;
      builder.resultsMatrix = matrix;
      builder.haversine = fakeit;
      this.performDrivingDistanceRequest(builder).then((message: StatusMessage) => {
        if (message.success) {
          resolve(message);
        }

      }, err => {
        let message = new StatusMessage();
        message.success = false;
        message.message = err;
        resolve(message);
      })

    })
  }

  performDrivingDistanceRequest(builder: GoogleDistanceMatrixBuilder) {
    return new Promise((resolve) => {
      if (builder.haversine) {
        let destinations: google.maps.LatLng[] = [];
        builder.destinationsarray.forEach(d => {
          d.forEach(de => {
            destinations.push(de);
          })

        })
        this.dummyDrivingDistance(builder.origins, destinations).then((message: StatusMessage) => {
          resolve(message);
        })
      }
      else {
        let origin = builder.origins[builder.originindex];
        let chunk = builder.destinationsarray[builder.destinationsarrayindex];



        this.drivingDistance([origin], chunk, builder.haversine).then((message: StatusMessage) => {
          if (message.success) {
            let result = message.message;
            let eachmatrix = result.result;
            if (builder.destinationsarrayindex == 0) {
              builder.resultsMatrix.rows.push(eachmatrix.rows[0]);
            }
            else {
              builder.resultsMatrix.rows[builder.originindex].elements = builder.resultsMatrix.rows[builder.originindex].elements.concat(eachmatrix.rows[0].elements);
            }
            builder.destinationsarrayindex++;
            if (builder.destinationsarrayindex == builder.destinationsarray.length) {
              if (builder.originindex == builder.origins.length - 1) {
                //final resolve();
                let message = new StatusMessage();
                message.success = true;
                message.message = builder.resultsMatrix;
                resolve(message);
              }
              else {
                builder.originindex++;
                builder.destinationsarrayindex = 0;
                setTimeout(() => {
                  this.performDrivingDistanceRequest(builder).then((eachbuilder: GoogleDistanceMatrixBuilder) => {
                    resolve(eachbuilder);
                  })
                }, 500)
              }

            }
            else {
              setTimeout(() => {
                this.performDrivingDistanceRequest(builder).then((eachbuilder: GoogleDistanceMatrixBuilder) => {
                  resolve(eachbuilder);
                })
              }, 500)
            }


          }
          else {
            console.log(message.message);
          }
        })
      }

    })

  }

  /**
 * given a map and a region with features, draw the features polygons onto the map
 * monitor this for api costs.
 * @param map 
 * @param region
 * no db interaction
 * sync 
 */
  addRegionFeaturePolys(map: google.maps.Map, region: Region) {

    if (region.Feature && region.Feature.FeaturePolygons) {
      let x = region.Feature.FeaturePolygons.length;
      let y = 0;
      region.Feature.FeaturePolygons.forEach(fp => {
        if (fp.Polygon.PolygonPoints) {
          let fillcolour: string;
          let strokecolour: string;
          if (region.name == "YO") {
            let bob = true;
          }
          /*
          let excluded = this.excludedPolys.map(ep=>ep.polygonid);
          if(excluded.indexOf(fp.featureid)>=0){
            fillcolour = "#FFFFFF";
          }*/
          //else{
          if (region.RegionDisplayInfo) {
            fillcolour = region.RegionDisplayInfo.colour.hex();
            strokecolour = region.RegionDisplayInfo.stroke.hex();
          }
          else {
            fillcolour = "#FF0000";
          }
          //}


          y++;
          fp.Polygon.PolygonPoints.sort((a, b) => {
            if (a.id < b.id) return -1;
            else return 1;
          })
          let shape = fp.Polygon.PolygonPoints.map(p => ({ lat: parseFloat(p.lat.toString()), lng: parseFloat(p.long.toString()) }));
          let polygon = new google.maps.Polygon({
            paths: shape,
            strokeColor: strokecolour,
            strokeOpacity: 0.75,
            strokeWeight: 2,
            fillColor: fillcolour,
            fillOpacity: 0.75
          });
          polygon.polygonid = fp.polygonid;
          polygon.setMap(map);
          this.addToSelected(region.id, polygon);
          polygon.addListener('click', (e) => { this.polyClick(e) });
        }
      })
    }
  }
  polyClick(e) {
    let found = false;
    this.selectedPolys.some(p => {

      p.poly.some(pol => {
        if (google.maps.geometry.poly.containsLocation(e.latLng, pol)) {
          this.events.polygonClick.emit(p);
          found = true;
          return true;
        }
      })
      if (found) return true;
    })
    if (!found) {
      this.excludedPolys.some(p => {
        if (p.poly) {
          p.poly.some(pol => {
            if (google.maps.geometry.poly.containsLocation(e.latLng, pol)) {
              this.events.polygonClick.emit(p);
              found = true;
              return true;
            }
          })
          if (found) return true;
        }

      })
    }
    if (!found) {
      console.log("Region not found");
    }
  }

  getDisplayedBounds(): google.maps.LatLngBounds {
    //console.time('bounds calculate');
    let pathsexamined = 0;
    let bounds = new google.maps.LatLngBounds();
    this.selectedPolys.forEach((p: DrawnPoly) => {
      p.poly.forEach(poly => {
        let paths = poly.getPaths();
        paths.forEach(path => {
          path.forEach(latlng => {
            pathsexamined++;
            bounds.extend(latlng);
          })

        })
      })
    })
    let end = new Date();
    //console.timeEnd('bounds calculate');
    //console.log("Paths: ",pathsexamined);
    return bounds;
  }

  /**
   * When a region is exluded or included in a selected region, move it in the selected/excluded arrays and change its shade rather than deleting and recreating
   * @param region 
   * @param shade 
   * @param fromSelectedToExlude 
   */
  xmovePolyAndChangeShade(region: Region, shade: string, fromSelectedToExlude: boolean) {
    let fromPolys = fromSelectedToExlude ? this.selectedPolys : this.excludedPolys;
    let toPolys = fromSelectedToExlude ? this.excludedPolys : this.selectedPolys;
    let index = 0;
    let found = false;
    fromPolys.some(fp => {
      if (fp.regionid == region.id) {
        found = true;
        return true;
      }
      index++;
    })
    if (found) {
      let poly = fromPolys.splice(index, 1)[0];
      toPolys.push(poly);
      poly.poly.forEach(p => {
        p.setOptions({ fillColor: shade });
      })
    }

  }

  changePolysShade(polygonid: number, shade: string) {
    this.selectedPolys.forEach((dp: DrawnPoly) => {
      dp.poly.some((poly: google.maps.Polygon) => {
        if (poly.polygonid == polygonid) {
          poly.setOptions({ strokeColor: shade });
        }
      })
    })
  }

  /**
   * Add a poly to the selected array to allow removal from the map;
   * @param regionid 
   * @param poly 
   */
  addToSelected(regionid: number, poly: google.maps.Polygon) {
    let regionPoly = this.selectedPolys.filter(sp => sp.regionid == regionid);
    if (regionPoly.length > 0) {
      regionPoly[0].poly.push(poly);
    }
    else {
      let newrp: DrawnPoly = { regionid: regionid, poly: [poly] };
      this.selectedPolys.push(newrp);
    }
  }
  /**
   * Add a poly to the excluded array to allow removal from the map;
   * @param regionid 
   * @param poly 
   */
  addToExcluded(regionid: number, poly: google.maps.Polygon) {
    let regionPoly = this.excludedPolys.filter(sp => sp.regionid == regionid);
    if (regionPoly.length > 0) {
      regionPoly[0].poly.push(poly);
    }
    else {
      let newrp: DrawnPoly = { regionid: regionid, poly: [poly] };
      this.excludedPolys.push(newrp);
    }
  }
  /**
   * unset and then remove any drawn polygons for this region in the selected array
   * @param regionid 
   */
  clearSelectedPoly(regionid: number) {
    let index = 0;
    let found = false;
    this.selectedPolys.some(poly => {
      if (poly.regionid == regionid) {
        poly.poly.forEach(polypoly => {
          polypoly.setMap(null);
        })
        found = true;
        return true;
      }
      index++;
    })
    if (found) {
      this.selectedPolys.splice(index, 1);
    }

  }
  /**
   * unset and then remove any drawn polygons for this region in the excluded array
   * @param regionid 
   */
  clearExcludedPoly(regionid: number) {
    let index = 0;
    let found = false;
    this.excludedPolys.some(poly => {
      if (poly.regionid == regionid) {
        poly.poly.forEach(polypoly => {
          polypoly.setMap(null);
        })

        found = true;
        return true;
      }
      index++;
    })
    if (found) {
      this.excludedPolys.splice(index, 1);
    }

  }
  /**
   * unset and then remove any drawn polygons on the current map
   * @param regionid 
   */
  clearAllPolys() {
    this.selectedPolys.forEach(poly => {
      poly.poly.forEach(polypoly => {
        polypoly.setMap(null);
      })
    })
    this.excludedPolys.forEach(poly => {
      poly.poly.forEach(polypoly => {
        polypoly.setMap(null);
      })
    })
    this.selectedPolys = [];
    this.excludedPolys = [];
  }

  /**
   * calculate the shortest arc between two geo points using the Haversine formula
   * @param regionid
   * returns number in km; 
   */
  haversineDifference(latlng1: google.maps.LatLng, latlng2: google.maps.LatLng) {
    const R = 6371e3; // metres
    const lat1 = latlng1.lat();
    const lng1 = latlng1.lng();
    const lat2 = latlng2.lat();
    const lng2 = latlng2.lng();
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lng2 - lng1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c / 1000;
  }




  /**
   * deprecated (old Feature to Polygon model)
   * given a map and a region with features, draw the features polygons onto the map
   * monitor this for api costs.
   * @param map 
   * @param region 
   */
  xaddRegionPolys(map: google.maps.Map, region: Region, selected: boolean) {

    if (region.Feature && region.Feature.Polygons) {
      let x = region.Feature.Polygons.length;
      let y = 0;
      region.Feature.Polygons.forEach(poly => {
        if (poly.PolygonPoints) {
          let fillcolour: string;
          if (region.name == "YO") {
            let bob = true;
          }
          if (region.RegionDisplayInfo) {
            fillcolour = region.RegionDisplayInfo.colour.hex();
          }
          else {
            fillcolour = "#FF0000";
          }


          y++;
          poly.PolygonPoints.sort((a, b) => {
            if (a.id < b.id) return -1;
            else return 1;
          })
          let shape = poly.PolygonPoints.map(p => ({ lat: parseFloat(p.lat.toString()), lng: parseFloat(p.long.toString()) }));
          let polygon = new google.maps.Polygon({
            paths: shape,
            strokeColor: '#FF0000',
            strokeOpacity: 0.75,
            strokeWeight: 0.5,
            fillColor: fillcolour,
            fillOpacity: 0.75
          });
          polygon.setMap(map);
          if (selected) {
            this.addToSelected(region.id, polygon);
          }
          else {
            this.addToExcluded(region.id, polygon);
          }

          polygon.addListener('click', (e) => {
            this.polyClick(e);
          })
        }
      })
    }
  }
}


