<div class="fl">
    <div>
        <div class="fl aic mt" *ngIf="editmode">
            <button *ngIf="!addnew" class="mr" type="button" mat-mini-fab aria-label="Add Charge" color="primary" title="Add Surcharge"
                (click)="addSurcharge()">
                <mat-icon>add</mat-icon>
            </button>
            <mat-form-field *ngIf="!addnew">
                <mat-label>Surcharge Type</mat-label>
                <mat-select [(ngModel)]="selectedSurchargeType" ngDefaultControl>
                    <mat-option *ngFor="let surchargetype of surchargeTypes" [value]="surchargetype">
                        {{surchargetype.name}}
                    </mat-option>
                    <mat-option (click)="addOther()">
                        Other
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <cs-add-surchargetype #addtype class="{{addnew?'':'hidden'}}" [appliesto]="servicetransportMode"
                [providerid]="serviceproviderid"></cs-add-surchargetype>

        </div>
    </div>
</div>
<p *ngIf="!surcharges || surcharges.length==0">
    <i>no route surcharges applicable</i>
</p>
<div>
<cs-surcharge *ngFor="let surcharge of surchargeFormArray.controls" [surchargeTypes]="surchargeTypes" [surcharge]="surcharge" [editmode]="true"></cs-surcharge>
</div>