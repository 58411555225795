<div class="f1 fl ovh">
    <div class="f1 fl fdc ovh" id="regiontree">
        <div class="fl rateform mt aic">
            <h3>Rate Editor - {{view.name}}</h3>
            <div class="f1 ml">
                <div class="fl aic jc" *ngIf="data.selectedSupplier">
                    <button class="mr" type="button" mat-mini-fab aria-label="Save Rate" color="primary"
                        title="Save Rate" (click)="saveRate()"
                        [disabled]="!rateform || !rateform.valid || rateform.pristine || showimport || showcard">
                        <mat-icon>save</mat-icon>
                    </button>
                    <cs-objectifier #objectifier [site]="data.selectedSupplier._baseSite" [region]="selectedVersion" [priority]="selectedPriority" class="mr fl fwn"></cs-objectifier>
                    <!--<button class="mr" type="button" mat-mini-fab aria-label="Show rate card"
                        *ngIf="selectedPriority.Bands && selectedPriority.Bands.length>0" color="primary" title="Show rate card" (click)="showCard()">
                        <mat-icon>table_view</mat-icon>
                    </button>
                    <button class="mr" *ngIf="selectedVersion" type="button" mat-mini-fab aria-label="Import Rate"
                        color="primary" title="Import Rates" (click)="showImport()">
                        <mat-icon>cloud_upload</mat-icon>
                    </button>-->

                    <mat-form-field>
                        <mat-label>Site</mat-label>
                        <mat-select name="basesite" ngDefaultControl [ngModel]="data.selectedSupplier._baseSite.id"
                            (selectionChange)="changeSite($event)">
                            <mat-option *ngFor="let site of data.selectedSupplier.Provider.Sites" [value]="site.id">
                                {{site.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="versions">
                        <mat-label>Version</mat-label>
                        <mat-select name="versions" ngDefaultControl [(ngModel)]="selectedVersion"
                            (selectionChange)="changeVersion($event)" [disabled]="showimport || showcard">
                            <mat-option *ngFor="let region of versions" [value]="region">
                                {{region.code}} - {{region.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Transport Mode</mat-label>
                        <mat-select [(ngModel)]="selectedMode" (selectionChange)="changeMode($event)" [disabled]="showimport || showcard">
                            <mat-option *ngFor="let type of transportmodes | enumpipe" [value]="type.key">
                                {{type.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <cs-company-search #cosearch [host]="'rate-editor-provider'"></cs-company-search>
                </div>
            </div>

        </div>
        <div *ngIf="!versions || versions.length==0">
            <p class="note">No rate zones defined.</p>
        </div>
        <mat-tab-group  *ngIf="selectedPriority && selectedVersion" #prioritiestab (selectedTabChange)="changePriority($event)">
            <mat-tab *ngFor="let priority of priorities | recordStatus">
                <ng-template mat-tab-label>
                    <span (dblclick)="editPriority(priority)" title="{{priority.id}}">{{priority.name}}</span>
                </ng-template>
                <div>
                    <cs-rate-list-form *ngIf="priority.Bands" [bands]="priority.Bands" [rateform]="rateform" [priority]="selectedPriority.id" [priorities]="priorities.length" [ultimateparent]="selectedVersion.id"></cs-rate-list-form>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon (click)="editPriority()" title="add new priority">add</mat-icon>
                </ng-template>
            </mat-tab>


        </mat-tab-group>
    </div>
</div>