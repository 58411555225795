import { Component, OnInit, Input } from '@angular/core';
import { Size } from 'src/app/models/rates';

@Component({
  selector: 'cs-size-view',
  templateUrl: './size-view.component.html',
  styleUrls: ['./size-view.component.scss']
})
export class SizeViewComponent implements OnInit {

  @Input() size:Size;
  @Input() customer:boolean = false;
  @Input() index:number = 0;

  public cube = 200;
  public defaultsize = 100;
  public scale = 12;

  constructor() { }

  ngOnInit(): void {
    let bob = 1;
  }

}
