<cs-quoter *ngIf="data.Company" [coid]="data.Company.id" [uselatest]="false" [incoming]="quoteid"></cs-quoter>
<!--
<mat-tab-group *ngIf="quote" class="cancel-overflow" (selectedTabChange)="changeView($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <ng-template matTabContent>
            <cs-quote-option-container [quote]="quote"></cs-quote-option-container>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="quote.portoptions && quote.portoptions.length>0">
        <ng-template mat-tab-label>
            Quotation
        </ng-template>
        <ng-template matTabContent>
            <cs-quote-view [quote]="quote" [customer]="customer"></cs-quote-view>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="quote.portoptions && quote.portoptions.length>0">
        <ng-template mat-tab-label>
            Quote Preview
        </ng-template>
        <ng-template matTabContent>
            <cs-quote-preview></cs-quote-preview>
        </ng-template>
    </mat-tab>
</mat-tab-group>
-->
<div class="mt fl jr">
    <!--<button mat-raised-button color="primary" class="mr" click="options()">Options</button>-->
    <button mat-raised-button color="warn" (click)="cancel()" type="button">Cancel</button>
</div>