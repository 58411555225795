import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cs-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  @Input() currencies:Country[]=[];
  @Input() discountgroup:FormGroup; 
  public history = false;

  get title():string{
    return this.discountgroup.get("title").value;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
