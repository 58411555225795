<div class="f1" [formGroup]="parentForm">
    <div *ngIf="user" [formGroupName]="index">
        <mat-form-field class="">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First Name" formControlName="firstname">
        </mat-form-field>
        <mat-form-field class="">
            <mat-label>Surname</mat-label>
            <input matInput placeholder="Surname" formControlName="surname">
        </mat-form-field>
        <mat-form-field class="">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email">
            <mat-error *ngIf="email.errors && email.errors.email">
                valid email required
            </mat-error>
        </mat-form-field>
        <mat-icon (click)="more=!more" class="pointer">{{more?'unfold_less':'unfold_more'}}</mat-icon>
    </div>
    <div *ngIf="more">
        <span class="wip">User Settings go here</span>
    </div>
</div>