import { Component, OnInit, Input } from '@angular/core';
import { Document,DocumentType, DocumentTypes } from 'src/app/models/models';
import { Quotation, PreferredPorts } from 'src/app/models/ui';
import { StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-quote-view',
  templateUrl: './quote-view.component.html',
  styleUrls: ['./quote-view.component.scss']
})
export class QuoteViewComponent implements OnInit {

  @Input() quote:Quotation;
  @Input() customer = false;
  @Input() set setPortOptions(quoteIndex){
    if(quoteIndex==undefined) return;
        this.portoption = this.quote.portoptions[quoteIndex];
  }
  
  
  public portoption:PreferredPorts;

  public notes:Document;
  public terms:Document;
  
  constructor(public data:DataService) {
    
  }

  ngOnInit(): void {
  
    this.data.getDocumentsByTypes([DocumentTypes.QuotationNotes,DocumentTypes.QuotationTerms]).subscribe((message:StatusMessage)=>{
      if(message.success){
        let docs:Document[] = message.message;
        if(docs.length>0){
          let notes = docs.filter(d=>d.documentTypeid==DocumentTypes.QuotationNotes);
          let terms = docs.filter(d=>d.documentTypeid==DocumentTypes.QuotationTerms);
          if(notes.length>0){
            notes.sort((a,b)=>b.companyid - a.companyid);
            this.notes = notes[0];
          }
          if(terms.length>0){
            terms.sort((a,b)=>b.companyid - a.companyid);
            this.terms = terms[0];
          }
        }
      }
    })
    
    if(!this.quote.currency) this.quote.currency = this.data.defaultCurrency.id;
    this.portoption = this.quote.portoptions[this.quote.selectedOption];
    this.quote.quoteInput.quoteItems.forEach(qi=>{
      let brackets = this.data.ratebrackets.filter(rb=>rb.id==qi.ratebracket);
      if(brackets.length==0){
        qi.RateBracket = brackets[0];
      }
    })
  }

}
