<div class="fl">
    <h3>Rate Importer</h3>
    <div class="f1"></div>
    <div>
        <button class="mr" type="button" mat-mini-fab aria-label="Save Rate" color="primary" title="Save Rate"
            (click)="saveImportedRate()" *ngIf="records.length>0">
            <mat-icon>save</mat-icon>
        </button>
        <button class="mr" type="button" mat-mini-fab aria-label="Cancel" color="warn" title="Close"
            (click)="cancelImport()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>
<div class="fl ovh">
    <div class="f1 ovh fl fdc pad">
        <div *ngIf="records.length==0">
            <p>Expecting a .csv file as below, please make sure your zones are in the right order.</p>
            <table *ngIf="region" class="tac">
                <tr>
                    <th></th>
                    <th *ngFor="let child of region.children">{{child.name}}</th>
                </tr>
                <tr>
                    <td>Break 1</td>
                    <td *ngFor="let i of [].constructor(region.children.length)">0.00</td>
                </tr>
                <tr>
                    <td>...</td>
                    <td *ngFor="let i of [].constructor(region.children.length)">0.00</td>
                </tr>
                <tr>
                    <td>Break <i>n</i></td>
                    <td *ngFor="let i of [].constructor(region.children.length)">0.00</td>
                </tr>
                <tr>
                    <td matTooltip="optional, include 'then' for breaks after those stated above, 'max' if there is a ceiling (a number default : infinity) and 'every' if the break is not per kg (a number default:1)"
                        matTooltipClass="tooltip">
                        <div class="fl aic jc">
                            <mat-icon color="primary" class="tiny">help</mat-icon><i>[then] [max] [every]</i>
                        </div>
                    </td>
                    <td *ngFor="let i of [].constructor(region.children.length)">0.00</td>
                </tr>
            </table>
            
        </div>
        <div class="{{records.length>0?'hidden':''}}">
        <input class="mt" type="file" #csvReader name="Upload CSV" id="txtFileUpload"
                (change)="uploadListener($event)" accept=".csv" />
        </div>
        <div *ngIf="records.length>0" class="fl aic">
            <div class="f1">
                <mat-slide-toggle color="primary" [(ngModel)]="globalbreakincludes" (change)="changeGlobalMin()">
                    {{globalbreakincludes?'up to and including next value':'up to but not including next value'}}</mat-slide-toggle>
            </div>
            <div class="f1">
                <mat-form-field class="">
                    <mat-label>Volume Conversion Factor</mat-label>
                    <input matInput placeholder="volume conversion factor" type="text"
                        [(ngModel)]="volumeequivalent">
                </mat-form-field>
            </div>


        </div>
        <div class="f1 mt overflow-scroll">

            <table class="tac">
                <tr *ngIf="headerRow && headerRow.length>0">
                    <th *ngFor="let cell of headerRow">{{cell}}</th>
                    <th>
                        <mat-slide-toggle color="primary" [(ngModel)]="globalpriceperbreak"
                            (change)="changeGlobalPer()">
                            {{globalpriceperbreak?'break price':'price per kg'}}</mat-slide-toggle>
                    </th>
                </tr>
                <tr *ngFor="let row of records;let r=index;">
                    <td *ngFor="let col of headerRow;let i=index;" title="{{row.runningtotal}}">
                        <span *ngIf="r==0 && i==0">0 to </span>
                        <span *ngIf="i>0 || !row.isthen">{{i==0?row.max:row[col] | number:'0.2-2'}}</span>
                        <span *ngIf="i==0 && row.isthen">{{row.text}}</span>
                    </td>
                    <td>
                        <mat-slide-toggle color="primary" [(ngModel)]="row.priceperbreak" [disabled]="row.isthen">
                            {{row.priceperbreak?'break price':'price per kg'}}
                        </mat-slide-toggle>
                    </td>

                </tr>
            </table>
        </div>
    </div>
    <div class="f1 fl fdc ovh pad">
        <div *ngIf="records.length>0" class="f1 fl fdc ovh">
            <h3>Rate Configuration</h3>
            <div class="fl">
                <div class="f1 jc">
                    <mat-form-field class="">
                        <mat-label>Rate Name</mat-label>
                        <input matInput placeholder="Rate name" type="text"
                            [(ngModel)]="envelopename">
                    </mat-form-field>    
                </div>
                <div class="f1 jc">
                    <mat-form-field>
                        <mat-label>Zone</mat-label>
                        <mat-select [(ngModel)]="selectedZone" (selectionChange)="changeZone($event)">
                            <mat-option *ngFor="let zone of region.children" [value]="zone">
                                {{zone.code}} - {{zone.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="f1 jc">
                    <mat-form-field>
                        <mat-label>Simplification Tolerance</mat-label>
                        <mat-select [(ngModel)]="tolerance" (selectionChange)="changeTolerance($event)">
                            <mat-option value="0">
                                Rounding Error
                            </mat-option>
                            <mat-option value="1">
                                2%
                            </mat-option>
                            <mat-option value="2">
                                5%
                            </mat-option>
                            <mat-option value="3">
                                10%
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="fl">
                <div class="f1">
                    <cs-rate-base [rate]="zonerates[selectedZoneIndex]"></cs-rate-base>
                </div>
            </div>

            <div class="fl ovh">
                <div class="f1 fl fdc ovh pad">
                    <h3>Price Bands as imported ({{zonerates[selectedZoneIndex].RateBreaks.length}}) <mat-checkbox [(ngModel)]="unsimplified" (change)="chooseSimplify(false)"></mat-checkbox></h3>
                    <div class="f1 overflow-scroll">
                        <div>
                            0 to {{zonerates[selectedZoneIndex].baseIncludesWeight}} kg =
                            {{zonerates[selectedZoneIndex].baseprice | currency:currencyCode}}
                        </div>
                        <div *ngFor="let br of zonerates[selectedZoneIndex].RateBreaks;let y = index;">
                            <span *ngIf="br.fixed>0">up to </span>{{br.maxweight}} kg = <span *ngIf="br.fixed>0">{{br.fixed | currency:currencyCode}} +
                            </span> {{br.price | currency:currencyCode}} <span *ngIf="br.fixed>0"> per
                                {{br.weightbreak | number}} kg over {{br.minweight}} kg</span>
                        </div>
                    </div>
                </div>
                <div class="f1 fl fdc ovh pad">
                    <h3>Simplified Price Breaks ({{zoneSimpleRates[selectedZoneIndex].RateBreaks.length}}) <mat-checkbox [(ngModel)]="simplified" (change)="chooseSimplify(true)"></mat-checkbox>
                    </h3>
                    <div class="f1 overflow-scroll">
                        <div>
                             0 to {{zoneSimpleRates[selectedZoneIndex].baseIncludesWeight}} kg =
                            {{zoneSimpleRates[selectedZoneIndex].baseprice | currency:currencyCode}}
                            then </div>
                        <div *ngFor="let br of zoneSimpleRates[selectedZoneIndex].RateBreaks;let z=index;" title="{{br.fixed}}">
                            {{br.minweight + (br.fixed>0?0:br.weightbreak)}} to {{br.maxweight}}kg = <span
                                *ngIf="br.fixed>0">{{br.fixed | currency:currencyCode}} + </span>
                            {{br.price | currency:currencyCode}} <span> per
                                {{br.weightbreak | number}} kg over {{br.minweight}} kg</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fl overflow-scroll">

            </div>
        </div>
    </div>
</div>