import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { LoginComponent } from 'src/app/dialogs/login/login.component';
import { StatusMessage, User, CompanySettingTypes } from 'src/app/models/user';
import { SnackComponent } from 'src/app/dialogs/snack/snack.component';
import { ToolsService } from 'src/app/services/tools.service';
import { EventsService } from 'src/app/services/events.service';
import { ConfirmComponent } from 'src/app/dialogs/confirm/confirm.component';
import { ConfirmOption } from 'src/app/models/models';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'cs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  route: string;
  

  public isLoading = false;

  get usersummary(){
    if(this.auth.user && this.auth.user.UserRoles && this.auth.user.UserRoles.length>0){
      return this.auth.user.firstname + " " + this.auth.user.surname+" "+this.auth.user.UserRoles[0].Role.description;
    }
    else return "";
  }

  constructor(public auth: AuthService, public dialog: MatDialog, private snack: MatSnackBar, public router: Router,private tools:ToolsService,public events:EventsService) { }

  ngOnInit() {
    this.router.events.subscribe((evs: any) => {
      if (evs instanceof NavigationEnd) {
        this.route = evs.url;
      }

    })
    this.auth.userReady.subscribe(()=>{
      let co = this.auth.user.Site.Company;
      if(co){
        this.auth.sitename = co.name;
        if(co.CompanySettings && co.CompanySettings.length>0){
          let logo = co.CompanySettings.filter(cs=>cs.companysettingtypeid==CompanySettingTypes.Logo);
          if(logo.length==1){
            this.auth.logo = environment.public+logo[0].setting;
          }
        }
      }
      
    })
    this.tools.events.sessionexpired.subscribe(()=>{
      this.auth.user.token="";
      console.log("session expired - fire open login");
      this.openLogin();
    })
  }


  openLogin(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: 'auto',
      data: this.auth.user
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == "logoff") {
          this.router.navigate(['']);
        }
        else {
          this.auth.user = result;
          this.auth.signin().then((msg: StatusMessage) => {
            if (msg.success) {
              
            }
            else {
              this.snack.openFromComponent(SnackComponent, { data: { html: "<p>Login Failed</p>" }, duration: 5000 });
              this.openLogin();
            }
          },err=>{
            this.tools.gracefulError(err.message || err);
            this.openLogin();
          })
        }
      }

    });
  }
  killData(){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: { message: "Are you sure you want to delete all the demo data?", yes: true, no: false, cancel: true }
    });

    dialogRef.afterClosed().subscribe((result: ConfirmOption) => {
      if(result == ConfirmOption.YES){
        this.tools.clearStore("quote");
        this.auth.killData().subscribe((message:StatusMessage)=>{
          if(message.success){
            this.tools.snackMessage("Data Cleansed. Please refresh your browser");
          }
          else{
            this.tools.gracefulError("Action Failed");
          }
        },err=>{
          this.tools.gracefulError("Action Failed");
        })
      }
    });
  }
  /*
  setup(){
    let testuser:User = new User();
    testuser.screenname = "Rich";
    testuser.firstname = "Rich"
    testuser.email = "rich@ideafoundry.uk";
    testuser.password = "Password1";
    testuser.siteid = 1;
    this.auth.register(testuser).subscribe((message:StatusMessage)=>{
      console.log(message);
    },err=>{
      console.log(err);
    })
  }*/
}
