import { Component, OnInit, Input } from '@angular/core';
import { Company, SiteTypes } from 'src/app/models/user';

@Component({
  selector: 'cs-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {

  @Input() customer:Company;

  
  constructor() { }

  ngOnInit(): void {
    
  }

}
