<p>{{prompttext}}</p>
<p class="jc">
<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput type="number" placeholder="{{label}}" [(ngModel)]="input">
</mat-form-field>
</p>
<div class="fl jc button-list">
    <button mat-raised-button color="accent" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="ok()">Create</button>
</div>
