import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): unknown {
    let formatted = "";
    value = value/60;//minutes is accurate enough
    if(value>1440){//we're talking days
      let days = Math.floor(value/1440);
      formatted += days.toString()+"d "+Math.round((value - days*1440)/60).toString()+"h";
    }
    else{//we're talking hours
      let hours = Math.floor(value/60);
      formatted += hours.toString()+"h "+Math.round((value-hours*60)).toString()+"mins";
    }
    return formatted;
  }

}
