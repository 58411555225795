import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';
import { FormtoolsService } from 'src/app/services/formtools.service';
import { FormGroup } from '@angular/forms';
import { IssueStatus, Issue, TableGenerator, ColumnDescription } from 'src/app/models/models';
import { StatusMessage } from 'src/app/models/user';
import * as moment from 'moment';
import { Service } from 'src/app/models/data';
import { Quotation, QuoteInput } from 'src/app/models/ui';

@Component({
  selector: 'cs-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {

  public issueTypes = IssueStatus;
  public issues: Issue[] = [];
  public selectedIssue: Issue;

  public selectedHasChildren = true;

  public editable:boolean = true;
  public showArchive:boolean = true;

  public selectedStatus = "-1";

  public selectedForm:FormGroup;

  public quote:Quotation;
  public quotation:FormGroup;
  public quoteInput:QuoteInput;


  constructor(public data: DataService, private tools: ToolsService, private formtools:FormtoolsService) {
    this.selectedForm = this.formtools.createIssueFormGroup();
  }

  ngOnInit() {   
        this.fromStore();
    
  }
  loadIssues(){
    this.data.listIssues().subscribe((message: StatusMessage) => {
      if (message.success) {
        this.issues = message.message;
        if(this.issues.length>0){
          this.select(this.issues[0]);
        }
        
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  fromStore() {
    this.tools.fromStore("quote").then((q: Quotation) => {
      if (q && q.quoteInput) {
        this.quote = q;
        this.quoteInput = q.quoteInput;
        this.quotation = this.formtools.createQuotationForm(this.quote);
        console.log(this.quotation);
      }
    });
  }
  select = (issue: Issue)=> {
    this.selectedIssue = issue;
    if(issue.children && issue.children.length>0){
      this.selectedHasChildren = true;
    }
    else{
      this.selectedHasChildren = false;
    }
    this.selectedForm.patchValue(issue);
  }
  newIssue() {
    let issue = new Issue(this.data.auth.user.id);
    issue.summary = "new Issue";
    issue.ownerid = this.data.auth.user.id;
    this.data.createIssue(issue).subscribe((message: StatusMessage) => {
      if (!message.success) {
        this.tools.gracefulError(message.message);
      }
      else {
        let newIssue: Issue = message.message;
        this.issues.push(newIssue);
      }
    }, err => {
      this.tools.gracefulError(err);
    })
  }
  delete(){

  }
  archive(){

  }
  save(){

  }
}
