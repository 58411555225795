import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../models/models';

@Pipe({
  name: 'withCurrency'
})
export class WithCurrencyPipe implements PipeTransform {

  transform(countries:Country[]): any {
    if(!countries) return [];
    return countries.filter(c=>c.currencySymbol);
  }

}
