<div class="f1 fl fdc ovh">
    <h3>Widget Development Zone</h3>
    <div class="f1 fl ovh">
        <div class="f1 overflow-scroll">
            <div>
                <button (click)="gpsCountries()">GPS Countries</button>
            </div>
            <cs-distance></cs-distance>
            <button (click)="polygonsCountries()">Polygons Country</button>
            <button (click)="polygonsAbstract()">Polygons Abstract</button>
            <mat-form-field>
                <mat-label>Region Type</mat-label>
                <mat-select [(value)]="regionType">
                    <mat-option *ngFor="let enum of regionTypes | enumpipe" [value]="enum.key">
                        {{enum.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <input type="text" [(ngModel)]="jsonfile">
            <input type="text" [(ngModel)]="namefield">
            <button (click)="loadJSON()">JSON</button>


            <button (click)="update()">Database</button>
            <button (click)="loadFeature()">Load Feature JSON</button>
            <button (click)="loadGithubPostcode()">Load UK Postcodes (github)</button>
            <p>{{updated}}</p><button (click)="updated=updated+1">+</button><button (click)="updated=updated-1">-</button><input type="checkbox" [(ngModel)]="iterateUpdate"><input [(ngModel)]="doSome">
            <p><button (click)="mapCountriesToContinents()">Map Countries to Continents</button><button (click)="geoCodeCountriesFromBounds()">GeoCode seletected Continent</button></p>
            <div class="fl">
                <div class="f1">
                    <input type="text" [(ngModel)]="searchText">
                    <button (click)="search()">Search</button>
                    <div class="fl">
                    <ul>
                        <li class="pointer" *ngFor="let region of heldregions" (click)="selectHeld(region)">{{region.id}}
                            {{region.name}}</li>
                    </ul>
                    <button (click)="regions = heldregions;heldregions=[];">back</button>
                    <ul>
                        <li class="pointer" *ngFor="let region of regions" (click)="selectRegion(region)">{{region.id}}
                            {{region.name}}</li>
                    </ul></div>
                    <button (click)="search(true)">Search Parents</button>
                    <ul>
                        <li class="pointer" *ngFor="let region of parents" (click)="selectParent(region)">{{region.id}}
                            {{region.name}}</li>
                    </ul>
                    <h3 *ngIf="selectedParent">{{selectedParent.name}} - {{selectedParent.id}}</h3>
                </div>
                <div class="f1">
                    <h3 *ngIf="selectedRegion">{{selectedRegion.name}} -
                        {{selectedRegion.Feature && selectedRegion.Feature.Polygons?selectedRegion.Feature.Polygons.length:0}}
                    </h3>
                    <div class="fl aic">
                        <input type="checkbox" [(ngModel)]="polysEditable" (click)="changeEditable()">Polys editable?
                        <input type="checkbox" [(ngModel)]="createGPSOnSelect">Create GPS?
                        <input type="checkbox" [(ngModel)]="showDeletedZones">Show Deleted Zones
                        <mat-icon (click)="saveRegion()" class="pointer" title="Update Region's Polygons">save</mat-icon>
                        <mat-icon (click)="hideList=!hideList">{{hideList?'unfold_more':'unfold_less'}}</mat-icon>
                    </div>
                    <div class="scrollable-panel {{hideList?'hidden':''}}"
                        *ngIf="selectedRegion && selectedRegion.Feature && selectedRegion.Feature.FeaturePolygons">
                        <div class="fl aic" *ngFor="let poly of selectedRegion.Feature.FeaturePolygons;let i = index;"
                            hover-class="show-icon">
                            <div class="f1 fl" *ngIf="poly.Polygon.recordStatus<3 || showDeletedZones">
                                <div [ngClass]="['f1 pointer',(selectedPaths.indexOf(poly.Polygon.id)>-1)?'active':'',poly.Polygon.recordStatus==3?'excluded':'']"
                                    (click)="showPoly(poly.Polygon)">Zone: {{poly.Polygon.id}} ({{poly.Polygon.PolygonPoints.length}})
                                </div>
                                <div>
                                    <mat-icon class="icon-child" (click)="makeMajor(poly.Polygon)"
                                        class="{{poly.major?'active':''}}">home</mat-icon>
                                    <mat-icon class="del-child" (click)="deletePoly(poly.Polygon ,i)">delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Last Vertex Index: {{lastVertex}}</p>
                    <button (click)="removeVertex()" [disabled]="!lastVertex">Delete Vertex</button>
                    <button (click)="undoRemoveVertex()" [disabled]="deletedVertices.length==0">Undo
                        ({{deletedVertices.length}})</button>
                    <button (click)="addZone()" [disabled]="!this.selectedRegion">Add Zone</button>
                    <input type="checkbox" [(ngModel)]="addZoneMode"><span>{{addZoneMode?'add zone':'del zone'}}</span>
                    <div><span *ngFor="let sp of selectedPaths;let si=index;">{{si}} - {{sp}}, </span></div>
                    <button (click)="manuallyMerge()" [disabled]="selectedPaths.length!=2">Merge Paths</button>
                    <button (click)="bounds()" [disabled]="selectedPaths.length!=2">Bounds</button>
                    <button (click)="clearAll()">Clear All</button>
                    <button (click)="mapPolysToRegion()" title="adds this feature to a base region of the same name and selected regiontypeid">Map Zones to SelectedRegion</button>
                    <button (click)="skimTiny()">Skim Tiny</button><button (click)="simplify()">Simplify</button> @ <input type="number" [(ngModel)]="skimSize">
                    
                    <button (click)="exportZonesToJson()">Export to JSON</button><input type="checkbox" [(ngModel)]="exportSelected"><span>Just Selected</span>
                    <button (click)="createMasterRegion()" [disabled]="!selectedParent">Create Region & Children</button>
                    <mat-form-field class="">
                        <mat-label>Zone</mat-label>
                        <mat-select [(ngModel)]="zonetype">
                            <mat-option *ngFor="let enum of zonetypes | enumpipe" [value]="enum.key">
                                {{enum.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button (click)="createChild()" [disabled]="!selectedParent">Create Child</button>
                </div>

            </div>
            <div>
                <button (click)="pickLocation()">Location Picker (popup)</button>
            </div>
            <div *ngIf="selectedRegion">
                <cs-regiongroupeditor [regionChange]="regionChange" [parentRegion]="selectedRegion"></cs-regiongroupeditor>
            </div>

        </div>
        <div class="f1 fl">
            <div #map class="map f1"></div>
        </div>
    </div>
</div>