import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { CompanySettingCategories, StatusMessage, CompanySettingType, NewCompanySettingType, CompanySetting, CompanySettingsCategory } from 'src/app/models/user';
import { EventsService } from 'src/app/services/events.service';
import { ToolsService } from 'src/app/services/tools.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CssSelector } from '@angular/compiler';

@Component({
  selector: 'cs-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {

  public categories:CompanySettingsCategory[]=[];
  constructor(public dialogRef: MatDialogRef<CompanySettingsComponent>,public data:DataService,public events:EventsService,public tools:ToolsService) { }

  ngOnInit(): void {  
    this.data.listCompanySettingsCategorys().subscribe((message:StatusMessage)=>{
      if(message.success){
        this.categories = message.message;
      }
    })
    this.events.companysettingchange.subscribe((ncs:NewCompanySettingType)=>{
      if(ncs.settingtype.Setting){
        ncs.settingtype.Setting.setting = ncs.newsetting;
      }
      else{
        let newsetting = new CompanySetting();
        newsetting.setting = ncs.newsetting;
        newsetting.recordStatus=0;
        newsetting.companysettingtypeid = ncs.settingtype.id;
        newsetting.companyid = this.data.Company.id;
        ncs.settingtype.Setting = newsetting;
      }
      this.data.updateCompanySetting(ncs.settingtype).subscribe((message:StatusMessage)=>{
        if(message.success){
          if(!ncs.settingtype.Setting.id){
            let found = false;
            this.categories.some((cat:CompanySettingsCategory)=>{
              cat.CompanySettingTypes.some(type=>{
                if(type.id == message.message.id){
                  type.Setting.id = message.message.Setting.id;
                  found = true;
                  return true;
                }
              })
              if(found) return true;
            })
          }
          //now do the local version
          let exists:CompanySetting;
          this.data.Company.CompanySettings.some(cs=>{
            if(cs.companysettingtypeid==ncs.settingtype.id){
              exists = cs;
              return true;
            }
          })
          if(exists){
            exists.setting = ncs.settingtype.Setting.setting;
          }
          else this.data.Company.CompanySettings.push(ncs.settingtype.Setting);
          
        }
        else{
          this.tools.gracefulError(message.message);
        }
      },err=>{
        this.tools.gracefulError(err);
      })
    })
  }
  close(){
    this.dialogRef.close();
  }

}
