import { Component, OnInit, Input, Inject } from '@angular/core';
import { Quotation, PreferredPorts } from 'src/app/models/ui';
import { DataService } from 'src/app/services/data.service';
import { MatDialog} from '@angular/material/dialog';
import { QuoteActionsComponent } from 'src/app/dialogs/quote-actions/quote-actions.component';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-quote-option-container',
  templateUrl: './quote-option-container.component.html',
  styleUrls: ['./quote-option-container.component.scss']
})
export class QuoteOptionContainerComponent implements OnInit {

  @Input() quote:Quotation;
  
  public serviceoptions:PreferredPorts[]=[];
  public point2pointoptions:PreferredPorts[] = [];

  public termdescription:string[] = ['Door - Door','Door - PoD', 'PoO - Door', 'PoO - PoD'];

  constructor(public data:DataService,public dialog: MatDialog,public events:EventsService) {
    events.quoteready.subscribe(()=>{
      this.drawQuote();
    })
  }

  ngOnInit(): void {
    this.drawQuote();
  }
  drawQuote(){
    this.serviceoptions = [];
    this.quote.portoptions.forEach(option=>{
      if(option.service){
        this.serviceoptions.push(option);
      }
      else{
        this.point2pointoptions.push(option);
      }
    })
  }
  
  
  actions(index:number){
    const dialogRef = this.dialog.open(QuoteActionsComponent, {
      width: '30%',
      data: { index:index,quote:this.quote }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result){

      }
    })
  }
  selectOption(index:number){
    this.quote.selectedOption=index;
    this.events.quoteoptionselect.emit(index);
  }
}
