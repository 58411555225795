import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalculationsService } from 'src/app/services/calculations.service';
import { FormGroup } from '@angular/forms';
import { ToolsService } from 'src/app/services/tools.service';


@Component({
  selector: '[cs-quote-price]',
  templateUrl: './quote-price.component.html',
  styleUrls: ['./quote-price.component.scss']
})
export class QuotePriceComponent implements OnInit {

  @Input() quoteprice;
  @Input() istotal:boolean;
  @Output() onchange = new EventEmitter<any>();

  constructor(public calc:CalculationsService,public tools:ToolsService) { }

  ngOnInit(): void {
    let bob=1;
  }

  calcMargin(quoteprice:FormGroup,margindriven?:boolean){
    let cost:number = quoteprice.get("costprice").value;
    let pricecontrol = quoteprice.get("discountedprice");
    let margincontrol = quoteprice.get("margin");

    
    if(margindriven){
      let reqmargin:number = margincontrol.value;
      let price = (-100*cost)/(reqmargin - 100);
      pricecontrol.setValue(this.tools.cleanRound(price,2));
    }
    else{
      let price:number = pricecontrol.value;
      if(price>0){
        let margin = this.tools.cleanRound((price - cost)/price*100,2);
        margincontrol.setValue(margin);
      }
      
    }
    this.onchange.emit();
  }

}
