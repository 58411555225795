import { Component, OnInit, Input } from '@angular/core';
import { Milestone, RoadMapSettings, MilestoneStatus } from 'src/app/models/models';
import { DataService } from 'src/app/services/data.service';
import moment from 'moment';

@Component({
  selector: 'cs-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() milestone:Milestone;
  @Input() selectedId:number;
  public roadmapSettings:RoadMapSettings;

  public duration:number = 0;

  public milestoneStatus = MilestoneStatus;



  constructor(public data:DataService) {
    this.roadmapSettings = data.roadmapSettings;

  }

  get completed(){
    if(this.milestone.status==MilestoneStatus.COMPLETED){
      return this.width;
    }
    else{
      let diff = moment().diff(moment(this.milestone.startDate),'d');
      if(diff<=0) return 0;
      return diff+0.5;
    }
  }

  get width(){
    let width = 1;
    if(this.milestone.dueDate){
      width = moment(this.milestone.dueDate).diff(moment(this.milestone.startDate),'days');
    }
    return width;
  }
  get offsetdays(){
    let offsetdays = 0;
    if(this.milestone.startDate){
      offsetdays =  moment(this.milestone.startDate).diff(this.roadmapSettings.startDate,'days')+this.roadmapSettings.additionalDays;
    }
    return offsetdays;
  }

  ngOnInit() {
    if(moment(this.milestone.dueDate)>moment()){
      let bob = 1;
    }
  }

}
