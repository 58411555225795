<div class="f1 fl fdc ovh">
    <div class="f1 fl fdc ovh" *ngIf="1==1 || viewable">
        <div class="title jr">
            <!--<div>Diff: {{todayOffset}}/{{calendarOffset}} Project Start: {{data.roadmapSettings.startDate}}, Filler Days: {{data.roadmapSettings.additionalDays}}</div>-->
            <mat-form-field>
                <mat-label>Filter by status</mat-label>
                <mat-select [(value)]="selectedStatus">
                    <mat-option value="-1">All</mat-option>
                    <mat-option *ngFor="let enum of milestoneTypes | enumpipe" [value]="enum.key">
                        {{enum.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="f1 fl overflow-scroll">

            <div class="milestones">
                <div class="calendar-header">
                    <div class="f1"><button type="button" mat-mini-fab aria-label="add milestone" color="primary" title="Add Milestone">
                        <mat-icon (click)="newMilestone()">create_new_folder</mat-icon>
                    </button></div>
                    <button type="button" mat-mini-fab aria-label="scroll left" color="primary"
                        title="Scroll Calendar Left" [disabled]="calendarOffset>=0">
                        <mat-icon (click)="moveCalendar(true)">chevron_left</mat-icon>
                    </button>
                    <button type="button" mat-mini-fab aria-label="scroll right" color="primary"
                        title="Scroll Calendar Right">
                        <mat-icon (click)="moveCalendar(false)">chevron_right</mat-icon>
                    </button>
                    <!--<button type="button" mat-mini-fab aria-label="swap scale" color="primary"
                        title="Switch between weeks and months">
                        <mat-icon (click)="swapScale()">{{isWeeks?'zoom_out':'zoom_in'}}</mat-icon>
                    </button>-->
                </div>
                <cs-milestone *ngFor="let milestone of milestones" id="csm-{{milestone.id}}" [milestone]="milestone"
                    (select)="select(milestone)" [parentSelect]="select" [selectedId]="selectedMilestone.id">
                </cs-milestone>
                <cs-milestone *ngFor="let folder of folders" id="csm-{{folder.id}}" [milestone]="folder"
                (select)="select(folder)" [parentSelect]="select" [selectedId]="selectedMilestone.id" [folder]="true"></cs-milestone>
            </div>
            <div class="timeline">
                <div *ngIf="bucket" class="calendar calendar-lines" [ngStyle]="{'left.px':calendarOffset}">
                    <div *ngFor="let month of bucket.months;let m = index;" class="calendar-month">
                        <div class="fl f1">
                            <div *ngFor="let week of month.weeks;let w=index;" class="calendar-week">
                                <div>{{week.monthName}}</div>
                                <div class="fl f1 border-bottom">
                                    <div *ngFor="let day of week.days;let d=index;" class="calendar-day {{day.active?'active':''}} {{d>0?'day-border':''}}">
                                        <div>{{day.dayOfWeek.substring(0,1)}}</div>
                                        <div id="week_{{week.weekNumber}}_{{d}}">{{day.date}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tl-lines" [ngStyle]="{'width.px':timelineWidth}">
                    <cs-timeline *ngFor="let milestone of milestones" [milestone]="milestone"
                    [selectedId]="selectedMilestone.id"></cs-timeline>
                    <cs-timeline *ngFor="let folder of folders" [milestone]="folder"
                    [selectedId]="selectedMilestone.id"></cs-timeline>
                </div>
                </div>
                <div class="today" [ngStyle]="{'left.px':todayOffset}"></div>
            </div>
        </div>
    </div>
</div>
<div class="milestone-form">
    <div *ngIf="selectedMilestone" [formGroup]="selectedForm" class="collapseForm {{collapseForm?'collapse':''}}">
        <div class="controls fl">
            <div><mat-icon class="pointer" [ngClass]="collapseForm?'collapse-arrow':''" (click)="collapseForm=!collapseForm">{{collapseForm?'chevron_left':'chevron_right'}}</mat-icon></div>
            <div class="f1"></div>
            <span class="hidden">invalid: {{selectedForm.invalid}} dirty: {{selectedForm.dirty}}</span>
            <button type="button" mat-mini-fab aria-label="edit" [color]="editable?'accent':'primary'"
                title="Edit Milestone" (click)="editable=!editable" [ngClass]="{'active':editable}">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="!selectedMilestone.previousParent && selectedMilestone.previousParent!=0" type="button" mat-mini-fab aria-label="archive" color="primary" title="Archive Milestone"
                (click)="archive()">
                <mat-icon>archive</mat-icon>
            </button>
            <button type="button" *ngIf="selectedMilestone.previousParent || selectedMilestone.previousParent==0" mat-mini-fab
                aria-label="restore from archive" color="primary" title="Restore Milestone from Archive"
                (click)="unarchive()">
                <mat-icon>unarchive</mat-icon>
            </button>
            <button type="button" mat-mini-fab aria-label="delete" color="warn"
                [title]="selectedHasChildren?'children present or protected folder':'Delete Milestone'"
                (click)="delete()" [disabled]="selectedHasChildren">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div>
            <div title="{{selectedMilestone.id}}">
                <mat-form-field class="w100">
                    <mat-label>Title</mat-label>
                    <input matInput placeholder="Milestone title" formControlName="summary">
                </mat-form-field>
            </div>
            <div>
            <mat-form-field>
                <mat-label>Priority</mat-label>
                <mat-select matNativeControl formControlName="priority">
                    <mat-option *ngFor="let enum of milestonePriority | enumpipe" [value]="enum.key">
                        {{enum.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
            <div>
                <mat-form-field class="w100">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Milestone Description" formControlName="description"
                        rows="5"></textarea>
                </mat-form-field>
            </div>
            <div class="fl jc ma">
                <mat-form-field>
                    <mat-label>Milestone Type</mat-label>
                    <mat-select formControlName="milestoneType">
                        <mat-option *ngFor="let enum of milestoneTypes | enumpipe" [value]="enum.key">
                            {{enum.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Milestone Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let enum of milestoneStatus | enumpipe" [value]="enum.key">
                            {{enum.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="fl jc ma mf-date">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Due Date</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="dueDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

