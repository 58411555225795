import { Component, OnInit } from '@angular/core';
import { StatusMessage } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-quote-preview',
  templateUrl: './quote-preview.component.html',
  styleUrls: ['./quote-preview.component.scss']
})
export class QuotePreviewComponent implements OnInit {

  public html:string = "";
  
  constructor(public data:DataService) { }

  ngOnInit(): void {
    this.data.getDocument(27).subscribe((message:StatusMessage)=>{
      if(message.success){
        this.html = message.message;
      }
    })
  }

}
