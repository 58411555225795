import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country } from 'src/app/models/models';


@Component({
  selector: 'cs-margin',
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.scss']
})
export class MarginComponent implements OnInit {

  @Input() currencies:Country[]=[];
  @Input() margingroup:FormGroup;
  public history = false;

  get title():string{
    return this.margingroup.get("title").value;
  }
  
  constructor() { }

  ngOnInit(): void {
    
  }


}
