<ng-container [formGroup]="quoteprice" class="fl">
    <td colspan="2">
        <input class="label" type="number" formControlName="tableid" class="hidden">
        <input matInput class="label {{istotal?'total':''}}" type="text" formControlName="description">
    </td>
    <td>
    <mat-form-field class="form-skinny mr">
        <mat-label>Cost Price</mat-label>
        <input matInput placeholder="Cost" type="number"
            formControlName="costprice"
            readonly="true" csInputDecimal>
    </mat-form-field>
</td>
    <td>
    <mat-form-field class="form-skinny mr">
        <mat-label>List Price</mat-label>
        <input matInput placeholder="List" type="number"
            formControlName="listprice" autocomplete="new-list-price"
            readonly="true" csInputDecimal>
    </mat-form-field>
</td>
    <td>
    <mat-form-field class="form-skinny mr">
        <mat-label>Discounted Price</mat-label>
        <input matInput placeholder="Discount" type="number"
            formControlName="discountedprice"
            autocomplete="new-discounted-price" (change)="calcMargin(quoteprice)" csInputDecimal>
    </mat-form-field>
</td>
    <td>
    <mat-form-field class="form-skinny mr">
        <mat-label>Margin %</mat-label>
        <input matInput placeholder="Margin %" type="number"
            formControlName="margin"
            autocomplete="new-quote-price-margin" (change)="calcMargin(quoteprice,true)">
    </mat-form-field>
</td>
<td>
    <mat-checkbox formControlName="include"></mat-checkbox>
</td>
</ng-container> 

