import { Injectable } from '@angular/core';
import { MomentBucket, MomentWeek, MomentMonth, MomentDay, ConfirmOption, Country, ColumnDescription, ColumnType } from '../models/models';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address } from '../models/user';
import { Rate, RateBreak, RateBreakPremium, CustomerRate, BandPremium, RateCardOverride, TransportModes, ZoneType, PriceBand, Region } from '../models/rates';
import { EventsService } from './events.service';
import { Service, Vessel, ServicePort } from '../models/data';
import { PreferredPorts } from '../models/ui';



@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  public userHasExpired = false;

  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, public events: EventsService) { }


  gracefulError(message: any) {
    if (message.errors) {
      message.errors.forEach(error => {
        let warning = "";
        if (error.message) {
          warning += error.message;
        }
        if (error.value) {
          warning += " (" + error.value + ")";
        }
        console.warn(warning);
      })
    }
    else if (message.error) {
      if (message.error.message) {
        if (message.error.message.errors) {
          let errors = "";
          message.error.message.errors.forEach(element => {
            errors += element.message + ", ";
          });
          this.snackbar.open(errors, '', { duration: 5000 });
        }
        else if (message.error.message.name) {
          this.snackbar.open(message.error.message.name, '', { duration: 5000 });
        }
        else {
          if (message.error.message == "Failed to authenticate token.") {
            //this.snackbar.open("Session has expired - returning to login in 5 seconds....", '', { duration: 5000 });
            if (!this.userHasExpired) {
              this.userHasExpired = true;

            }
            this.events.sessionexpired.emit(true);
          }
          if (message.error.message == "No token provided.") {
            //this.snackbar.open("Session has expired - returning to login in 5 seconds....", '', { duration: 5000 });
            if (!this.userHasExpired) {
              this.userHasExpired = true;
              this.events.sessionexpired.emit(true);
            }

          }
          else {
            if(message.error && message.error.message){
              this.snackbar.open(message.error.message, '', { duration: 5000 });
            }
            else if(message.error && message.message){
              this.snackbar.open('Unspecified error - support has been notified', '', { duration: 5000 });
            }
            else this.snackbar.open(message.error.message.toString(), '', { duration: 5000 });
          }
        }

      }
      else {
        console.log(message.error);
      }
    }
    else {
      if (message.name) {
        if (message.name.indexOf("Sequelize") >= 0) {
          message = "DB error - support have been notified. Apologies. Please refresh your browser.";
          this.snackbar.open(message, '', { duration: 5000 });
        }
        else {
          console.log(message);
        }
      }
      else {
        if (message.indexOf("session has expired") > 0 || message.indexOf("active session") > 0) {
          this.events.sessionexpired.emit(true);
        }
        console.log(message);
      }

      this.snackbar.open(message, '', { duration: 5000 });
    }
    this.events.cancelLoading.emit();
    this.events.stopSpinner();
  }
  snackMessage(message: string) {
    this.snackbar.open(message, '', { duration: 5000 });
  }

  confirm(message: string, yes: boolean, no?: boolean, cancel?: boolean) {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '250px',
        data: { message: message, yes: yes, no: no, cancel: cancel }
      });

      dialogRef.afterClosed().subscribe((result: ConfirmOption) => {
        resolve(result);
      });
    })

  }
  //table tools
  filterPredicate(data: any, filter: string) {
    const flattened = this.objectPropertiesAsString(data, "").trim().toLowerCase();
    const cleanFilter = filter.trim().toLowerCase();
    let x = flattened.indexOf(cleanFilter)
    return x !== -1;
  }
  objectPropertiesAsString(obj: any, current: string): string {
    if (!obj) return current;
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object') {
        current = this.objectPropertiesAsString(obj[key], current);
      }
      else {
        //this is angular's obscure unicode delimiter to make concatenation matches much less likely
        current += '◬' + obj[key];
      }
    })
    return current;
  }

  patchAnUpdate(existingOb: any, newOb: any) {
    Object.keys(newOb).forEach((key) => {
      if (typeof existingOb[key] != "object") {
        existingOb[key] = newOb[key];
      }
    })
  }

  //create an object to populate the milestones calendar
  makeAMomentBucket(startDate: moment.Moment, endDate: moment.Moment): MomentBucket {
    let bucket = new MomentBucket();
    let current = moment(startDate);
    let weekBucket: MomentWeek = new MomentWeek(startDate);

    let monthBucket: MomentMonth = new MomentMonth(startDate);

    let startDay = startDate.day();
    console.log("start date is: ", startDate, " day being: ", startDay);
    //add some inactive days so each week is complete
    while (startDay > 0) {
      let toadd = moment(startDate);
      toadd.subtract(startDay, 'd');
      let preday = new MomentDay(toadd, false);
      weekBucket.days.push(preday);
      bucket.additionalDays++;
      startDay--;
    }

    while (current <= endDate) {
      let dayBucket = new MomentDay(current, true);
      if (weekBucket.days.length > 0 && current.day() == 0) {
        monthBucket.weeks.push(weekBucket);
        weekBucket = new MomentWeek(current);
        if (current.date() < 7) {
          bucket.months.push(monthBucket);
          monthBucket = new MomentMonth(current);
        }
      }
      weekBucket.days.push(dayBucket);
      current.add(1, 'd');
    }

    if (weekBucket.days.length > 0) {
      //fill in the gaps and add it
      let stilltoadd = 8 - weekBucket.days.length;
      for (let i = 1; i < stilltoadd; i++) {
        let still = moment(endDate);
        still.add(i, 'd');
        let stillday = new MomentDay(still, false);
        weekBucket.days.push(stillday);
      }
      monthBucket.weeks.push(weekBucket);
    }
    if (monthBucket.weeks.length > 0) {
      bucket.months.push(monthBucket);
    }
    return bucket;
  }

  //number tools

  cleanFloat(numberstring: string | number) {
    if(!numberstring) return 0;
    numberstring = numberstring.toString();
    let float = parseFloat(numberstring.trim().replace(/[^\d.-]/g, ''));
    float = Math.round(float * 100);
    float = float / 100;
    return float;
  }
  cleanRound(float: number, decimals: number) {
    let num = float * (Math.pow(10, decimals));
    let roundnum = Math.round(num);
    return Math.round(float * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
  latestFromRange(range:string):number{
    let minmax = range.split('-');
    if(minmax.length==2){
      return this.cleanFloat(minmax[1]);
    }
    else return this.cleanFloat(range);
  }

  extractCurrency(cell: string) {
    let testcurrency = cell.split(' ');
    let currencyCode = "USD"//default;
    let amount = 0;
    if (testcurrency.length == 2) {
      if (testcurrency[0].length == 3) {
        currencyCode = testcurrency[0];
        amount = this.cleanFloat(testcurrency[1]);
      }
    }
    return ({ currencyCode: currencyCode, amount: amount });
  }

  duration(time: number) {
    let formatted = "";
    time = time / 60;//minutes is accurate enough
    if (time > 1440) {//we're talking days
      let days = Math.floor(time / 1440);
      formatted += days.toString() + "d " + Math.round((time - days * 1440) / 60).toString() + "h";
    }
    else {//we're talking hours
      let hours = Math.floor(time / 60);
      formatted += hours.toString() + "h " + Math.round((time - hours * 60)).toString() + "mins";
    }
    return formatted;
  }



  //tools to decode the JWT access token
  private urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        console.log('Illegal base64url string!');
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

  rateClone(rate: Rate) {
    let clone = new Rate(rate.regionid, rate.priority);
    clone.baseprice = parseFloat((rate.baseprice || 0).toString());
    clone.baseIncludesWeight = parseFloat((rate.baseIncludesWeight || 0).toString());
    clone.baseIncludesVolume = parseFloat((rate.baseIncludesVolume || 0).toString());
    clone.expires = rate.expires;
    clone.createdAt = rate.createdAt;
    clone.breakbarrierincludes = rate.breakbarrierincludes;
    clone.priceperbreak = rate.priceperbreak;
    clone.transportmode = rate.transportmode;
    clone.priority = rate.priority;
    clone.parentregionid = rate.parentregionid;
    if (rate.RateBreaks) {
      rate.RateBreaks.forEach(rb => {
        let rbclone = new RateBreak();
        rbclone.breakbarrierincludes = rb.breakbarrierincludes;
        rbclone.price = rb.price;
        rbclone.priceperbreak = rb.priceperbreak;
        rbclone.weightbreak = rb.weightbreak;
        rbclone.volumebreak = rb.volumebreak;
        rbclone.maxweight = rb.maxweight;
        rbclone.maxvolume = rb.maxvolume;
        if (rb.RateBreakPremiums) {
          rb.RateBreakPremiums.forEach(rbp => {
            let rbpclone = new RateBreakPremium();
            rbpclone.customerid = rbp.customerid;
            rbpclone.ratebreakid = rbp.ratebreakid;
            rbpclone.pricepremium = parseFloat((rbp.pricepremium || 0).toString());
            rbclone.RateBreakPremiums.push(rbpclone);
          })
        }


        clone.RateBreaks.push(rbclone);
      })
    }
    if (rate.CustomerRates) {
      rate.CustomerRates.forEach(cr => {
        let crclone = new CustomerRate();
        crclone.customerid = cr.customerid;
        crclone.expires = cr.expires;
        crclone.rateid = cr.rateid;
        clone.CustomerRates.push(crclone);
      })
    }
    if (rate.BandPremia) {
      rate.BandPremia.forEach(bp => {
        let bpclone = new BandPremium();
        bpclone.premiumpercent = parseFloat((bp.premiumpercent || 0).toString());
        bpclone.premiumvalue = parseFloat((bp.premiumvalue || 0).toString());
        bpclone.rateid = bp.rateid;
        bpclone.regionid = bp.regionid;
        clone.BandPremia.push(bpclone);
      })
    }
    if (rate.RateCardOverrides) {
      rate.RateCardOverrides.forEach(rc => {
        let rcclone = new RateCardOverride();
        rcclone.adjustment = parseFloat((rc.adjustment || 0).toString());
        rcclone.index = rc.index;
        rcclone.rateid = rc.rateid;
        rcclone.regionid = rc.regionid;
        rcclone.weightmin = parseFloat((rc.weightmin || 0).toString());
        rcclone.weightmax = parseFloat((rc.weightmax || 0).toString());
        clone.RateCardOverrides.push(rcclone);
      })
    }

    return clone;
  }

    /**
   * if we add the region by reference, the rates will also be passed by reference. Create a price band object instead.
   * @param band 
   */
  priceBand(band: Region): PriceBand {
    let priceband: PriceBand = new PriceBand();
    priceband.name = band.name;
    priceband.regionid = band.id;
    priceband.parentid = band.parentId;
    priceband.currencyid = band.countryid;//default
    priceband.Rates = band.Rates;
    if(band.children){
      band.children.forEach(ch=>{
        priceband.Children.push(this.priceBand(ch));
      })
    }
    return priceband;
  }
  /**
   * bit magic numbery 
   * @param transportmode 
   */
  mapTransportModeToPortType(transportmode: number) {
    switch (transportmode) {
      case 1: //shipping
        return 1; //seaport
      case 2: //air
        return 4; //airport
      case 3: //rail
        return 2; //railway station
      case 4: // local haulage
      case 0: // hub to hub haulage
        return 3; //road terminal
      default:
        return 1; //seaport
    }
  }

  /**
   * 
   * return the first service port if there is more than one
   * this is temporary - deprecated
   * @param service 
   */
  getPortLocodesPreferred(service: Service): PreferredPorts {
    let preferred = new PreferredPorts();
    preferred.mode = TransportModes.Shipping;
    if (service.Vessel) preferred.vessel = service.Vessel;
    else {
      preferred.vessel = this.defaultVessel(preferred.mode);
    }
    if (service.transportMode[0] == TransportModes.Air) {
      preferred.mode = TransportModes.Air;
    }
    if (service.OriginServicePorts && service.OriginServicePorts.length > 0) {
      preferred.origin = service.OriginServicePorts[0].Locode;
    }
    if (service.DestinationServicePorts && service.DestinationServicePorts.length > 0) {
      preferred.destination = service.DestinationServicePorts[0].Locode;
    }
    preferred.service = service;
    return preferred;
  }
  buildPreferredPort(origin: ServicePort, destination: ServicePort, service: Service) {
    let preferred = new PreferredPorts();
    preferred.mode = service.transportMode;
    preferred.vessel = service.Vessel ? service.Vessel : this.defaultVessel(service.transportMode);
    preferred.destination = destination.Locode;
    preferred.origin = origin.Locode;
    preferred.service = service;
    return preferred;
  }

  serviceColumns(): ColumnDescription[] {
    return [new ColumnDescription("id"), new ColumnDescription("name", "displayname"), new ColumnDescription("description"), new ColumnDescription("Provider", "Provider.name"), new ColumnDescription("Origin", "Origin.name"), new ColumnDescription("Destination", "Destination.name"), new ColumnDescription("Via", "ServiceVias", ColumnType.SERVICE_VIAS), new ColumnDescription("Transit Time", "transitTime"), new ColumnDescription("Currency", "Currency.currencyCode"), new ColumnDescription("Quote Validity", "quoteValidity"), new ColumnDescription("Mode", "transportMode", ColumnType.TRANSPORT_MODE), new ColumnDescription("Public", "isPublic", ColumnType.BOOLEAN), new ColumnDescription("C2P", "includeC2P", ColumnType.BOOLEAN), new ColumnDescription("P2P", "includeP2P", ColumnType.BOOLEAN), new ColumnDescription("P2D", "includeP2D", ColumnType.BOOLEAN), new ColumnDescription("Status", "recordStatus", ColumnType.RECORD_STATUS)];
  }
  quoteColumns(): ColumnDescription[] {
    return [new ColumnDescription("id"), new ColumnDescription("mode", "ServiceOptions[0].mode", ColumnType.TRANSPORT_MODE), new ColumnDescription("description"), new ColumnDescription("Provider", "ServiceOptions[0].Provider.name"), new ColumnDescription("Customer", "Customer.name"), new ColumnDescription("Origin", "QuoteInput.Origin.town"), new ColumnDescription("Destination", "QuoteInput.Destination.town"), new ColumnDescription("Route", "ServiceOptions[0].Service.displayname", ColumnType.ROUTE), new ColumnDescription("Price", "ServiceOptions[0].discountedprice", ColumnType.CURRENCY), new ColumnDescription("Margin", "ServiceOptions[0].margin", ColumnType.CURRENCY), new ColumnDescription("CO2", "ServiceOptions[0].co2", ColumnType.CURRENCY), new ColumnDescription("Duration", "ServiceOptions[0].duration", ColumnType.DURATION)];
  }
  servicePath(service: Service) {
    let path = service.Origin.name;
    if (service.OriginServicePorts && service.OriginServicePorts.length > 0) {
      path += " (";
      service.OriginServicePorts.forEach(osp => {
        path += osp.Locode.nodiacritic + " or ";
      });
      path = path.substring(0, path.length - 4);
      path += ") to ";
    }
    path += service.Destination.name;
    if (service.DestinationServicePorts && service.DestinationServicePorts.length > 0) {
      path += " (";
      service.DestinationServicePorts.forEach(dsp => {
        path += dsp.Locode.nodiacritic + " or ";
      });
      path = path.substring(0, path.length - 4);
      path += ")";
    }
    return path;

  }
  defaultVessel(mode: TransportModes): Vessel {
    if (mode == TransportModes.Shipping) {
      let vessel = new Vessel();
      vessel.id = 1;
      vessel.name = "VLCS";
      vessel.description = "Very Large Container Ship";
      vessel.capacity = 20000 * 18000;
      vessel.co2gkm = 3;
      vessel.mode = TransportModes.Shipping;
      return vessel;
    }
    else {
      let vessel = new Vessel();
      vessel.id = 0;
      vessel.name = "747";
      vessel.description = "Boing 747 Cargo";
      vessel.capacity = 18000 * 20;
      vessel.co2gkm = 0.606 * 1.09;
      vessel.mode = TransportModes.Air;
      return vessel;
    }
  }

  incode(address: Address): string {
    if (address.Country.use_postcode && address.postcode) {
      if (address.postcode.length > address.Country.postcode_substring) {
        return address.postcode.substring(address.Country.postcode_substring);
      }
    }
    else return address.town;
  }
  outcode(address: Address): string {
    if (address.Country.use_postcode && address.postcode) {
      let testuk = address.postcode.split(' ');
      if (testuk.length == 2) return testuk[0];
      if (address.postcode.length >= address.Country.postcode_substring) {
        return address.postcode.substring(0, address.Country.postcode_substring);
      }
    }
    else return address.town;
  }
  postcodearea(address: Address): string {
    if (address.Country.use_postcode && address.postcode) {
      let testuk = address.postcode.split(' ');
      if (testuk.length == 2) {
        return testuk[0].replace(/[0-9]/g, '');
      }
      if (address.postcode.length >= address.Country.postcode_substring) {
        return address.postcode.substring(0, address.Country.postcode_substring);
      }
    }
    else return address.town;
  }

  setZoomByZoneType(zonetype: ZoneType) {
    switch (zonetype) {
      case ZoneType.NationalOutcodeGroup:
        return 8;
      default:
        return 6;
    }
  }

  /**
 * turn locode location string into gps
 * @param location 
 */
  latlongfromLocode(location: string): number[] {
    if (location.length != 12) return [];
    else {
      let pair = location.split(' ');
      if (pair.length < 2) return [];
      let latstring = pair[0].substring(0, 4);
      let lat = parseFloat(latstring) / 100;
      if (pair[0].indexOf('S') > 0) lat = -lat;

      let longstring = pair[1].substring(0, 5);
      let long = parseFloat(longstring) / 100;
      if (pair[1].indexOf('W') > 0) long = -long;

      return [lat, long];

    }
  }

  googleAddress(address: Address) {
    if (address.gpslat && address.gpslong) {
      return new google.maps.LatLng(address.gpslat, address.gpslong);
    }
    else return address.houseNameOrNumber + "," + address.postcode;
  }
  /**
   * format a google place into a cs Address 
   * */
  addressFromGooglePlace(places: google.maps.places.PlaceResult[], countries: Country[]): Address {
    let address: Address = new Address();
    if (places.length > 0) {
      let place = places[0];
      if (place.address_components) {
        place.address_components.forEach(ac => {
          if (ac.types && ac.types.length > 0) {
            let type = ac.types[0];
            switch (type) {
              case "street_number":
              case "airport":
                address.houseNameOrNumber = ac.long_name;
                break;
              case "route":
                address.street = ac.long_name;
                break;
              case "locality":
                address.town = ac.long_name;
                break;
              case "postal_town":
                address.city = ac.long_name;
                break;
              case "administrative_area_level_2":
                address.county = ac.long_name;
                break;
              case "administrative_area_level_1":
                address.state = ac.long_name;
                break;
              case "country":
                address.country = ac.long_name;
                let countrylist = countries.filter(c => c.iso_alpha_2 == ac.short_name);
                if (countrylist.length == 1) {
                  address.Country = countrylist[0];
                  address.countryid = countrylist[0].id;
                }
                break;
              case "postal_code":
                address.postcode = ac.long_name;
                break;
            }
          }
        })
      }
      if (!address.town) {
        address.town = address.city;
      }
      if (place.geometry && place.geometry.location) {
        address.gpslat = place.geometry.location.lat();
        address.gpslong = place.geometry.location.lng();
      }
    }
    return address;
  }
  public decodeToken(token: string = '') {
    if (token === null || token === '') { return { 'upn': '' }; }
    const parts = token.split('.');
    if (parts.length !== 3) {

      console.log('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      console.log('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  //return storage object as a promise so different storage methods can be dropped in here.
  fromStore(key: string) {
    return new Promise((resolve) => {
      resolve(JSON.parse(localStorage.getItem(key)));
    });
  }

  //set storage object as a promise so different storage methods can be dropped in here.
  toStore(key: string, value: any) {
    return new Promise((resolve) => {
      localStorage.setItem(key, JSON.stringify(value));
      resolve(value);
    });
  }
  clearStore(key: string) {
    return new Promise((resolve) => {
      localStorage.removeItem(key);
      resolve(true);
    });
  }
}
