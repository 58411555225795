<div *ngIf="region && region.recordStatus<3" class="fl aic region {{selectedId==region.id?'region-selected':''}}" hover-class="show-add">
    <div cdkDrag [cdkDragData]="region" [cdkDragDisabled]="editable" class="pointer f1">
        <div class="f1 fl aic">
            <mat-icon (click)="clickFolder()"
                [ngClass]="['folder',region.children && region.children.length>0?'active':'']">
                {{region._folderOpen?'folder_open':'folder'}}</mat-icon>
            <div class="f1" class="{{'recstatus'+region.recordStatus}}" (click)="!editable?service.selectRegion(region):false" (dblclick)="setEditable()">
                <div *ngIf="!editable" class="{{region._selected?'title active':'title'}}" title="{{region.id}} {{region.code?'code is '+region.code:''}} {{region.zonetype?zonetypes[region.zonetype].toString():''}}">
                    <span class="code">{{region.code && region.code.length>0?region.code+' ':''}}</span>
                    {{region.name.length>0?region.name:'.....'}}{{region.children && region.children.length>0?' - ('+activechildren+')':''}}
                </div>
                <div *ngIf="editable" class="fl aic">
                    <mat-form-field class="short">
                        <mat-label>Code</mat-label>
                        <input matInput type="text" placeholder="name" [(ngModel)]="region.code">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput type="text" placeholder="name" [(ngModel)]="region.name">
                    </mat-form-field>
                    <mat-icon (click)="updateRegion(region)" title="update" aria-label="update region" color="primary">check</mat-icon>
                    <mat-icon (click)="deleteRegion(region)" title="delete" aria-label="delete region" color="error">delete</mat-icon>        
                </div>
            </div>
        </div>
    </div>
    <div>
        <mat-icon *ngIf="region._canAddDistance" (click)="openDistanceBander()" class="add-child add-map" color="primary">add_road</mat-icon>
        <mat-icon *ngIf="region._canAddMap" (click)="openAddFromMap()" class="add-child add-map" color="primary">map</mat-icon>
        <!--
            idea here was to split a postcode region into two, but you can do this by adding an outcode in one custom group, adding the incodes and then adding the outcode to another and moving the exceptions
            <mat-icon *ngIf="region.zonetype==zonetypes.NationalOutcode && region.children && region.children.length>0" (click)="split()" class="add-child" color="primary" title="Split Outcode">content_cut</mat-icon>-->
        <mat-icon *ngIf="region._canAdd" (click)="addContext()" class="add-child" color="primary">add</mat-icon>
    </div>
</div>
<div *ngIf="region && region.children && region._folderOpen" class="childregion" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="region" >
    <div *ngFor="let child of region.children">
    <cs-region [region]="child"></cs-region>
</div>
</div>