import { Injectable, EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { CompanyStatus, CompanyType, SiteType, UserSettingType, StatusMessage, CompanyAssociation, Company, Role, User, UserRole, UserSetting, Invitation, Address, Site, SiteTypes, CompanySettingsCategory, CompanySettingType, CompanySettingTypes } from '../models/user';
import { Country, RoadMapSettings, Milestone, Issue, ModuleUser, Module, DocumentGroup, DocumentType, Document, CentreToPort, PortToPort, RecordStatus } from '../models/models';
import { environment } from 'src/environments/environment';
import { Service, ServiceGroup, ServicePort, ServiceUser, ServiceGroupService, ServiceWhereClause, Locode, Vessel, MapRequest, PerformanceMetric } from '../models/data';
import { RegionType, GPS, Region, RateBreak, RateBreakPremium, Rate, RatePremium, SurchargeType, Surcharge, SurchargePremium, CustomerRate, CustomerSurcharge, Polygon, RegionTypes, Feature, RegionGroupChild, RegionGroup, RegionGroupType, FeaturePolygon, RateCardOverride, RateEnvelope, TransportModes, RegionDistancer, RatePriority, ZoneType, RateBracket, Margin, MinimumMargin, Discount, SavedQuote, SavedQuoteInput, SurchargeRelatesTo, SurchargeAppliesTo, PriceAppliesTo, RegionDisplayInfo, QuotationPrice, QuoteEvent } from '../models/rates';
import { ToolsService } from './tools.service';
import { GoogleService } from './google.service';
import { Priority, PreferredPorts, ServiceRequest, CurrencyView, PortSite, Terms, PastedTableCollection } from '../models/ui';
import { FormArray } from '@angular/forms';
import { Unsworth } from '../models/importers';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  public companyStatuses: CompanyStatus[] = [];
  public companyTypes: CompanyType[] = [];
  public documentTypes: DocumentType[];
  public countries: Country[] = [];
  public siteTypes: SiteType[] = [];
  public userSettingTypes: UserSettingType[] = [];
  public userRoles: Role[] = [];
  public modules: Module[];
  public providers: CompanyAssociation[] = [];
  public customers: CompanyAssociation[] = [];
  public continents: Region[] = [];
  public currencies:Country[]=[];
  public ratebrackets:RateBracket[]=[];

  public settingstabs = ["Providers","Customers","Regions","Rates","Services","Margins","Users","Documents"];


  public performance: PerformanceMetric[] = [];

  public defaultCurrency: Country;

  /**
   * default priority settings, replaced with the Rate Priority model
   * remove when tested properly.
   */
  public priorities: Priority[] = [{ id: 1, code: 0, name: "Standard", collection: new QuotationPrice() }, { id: 2, code: 0, name: "Express", collection: new QuotationPrice() }];



  public dataIsReady: boolean = false;
  /**
   * this event is fired when all the options and types are loaded.
   */
  public dataReady: EventEmitter<boolean> = new EventEmitter();


  public roadmapSettings: RoadMapSettings = new RoadMapSettings();

  /**
   * base site is initially the signed in user's site.
   */
  public basesite: Site;

  /**
   * the signed in user's company
   */

  public Company: Company;

  /**
   * selected Supplier can be set in multiple places, store it here for consistency
   * accross the application.
   * Rename to selected Provider when you have time.
   */

  public selectedSupplier: CompanyAssociation;

  /**
   * same for customer
   */
  public selectedCustomer: CompanyAssociation;

  /**
   * this first role of the signed in user
   */
  public role() {
    return this.auth.user.UserRoles[0].roleid;
  }

  constructor(public auth: AuthService, public tools: ToolsService, public geo: GoogleService) {

    if (this.auth.userReady) {//for testing purposes
      this.auth.userReady.subscribe(() => {
        this.Company = this.auth.user.Site.Company;
        if(this.Company.CompanySettings && this.Company.CompanySettings.length>0){
          let logos = this.Company.CompanySettings.filter(cs=>cs.companysettingtypeid==CompanySettingTypes.Logo);
          if(logos.length>0){
            this.auth.logo = environment.host+"resources"+logos[logos.length-1].setting;
          }
        }
        this.basesite = this.auth.user.Site;
        this.loadTypes().then(() => {
          this.listCustomers(true).subscribe((message: StatusMessage) => {
            this.customers = message.message;
            this.listCustomers(false).subscribe((message: StatusMessage) => {
              this.providers = message.message;
              this.setupSelectedProvider();
              this.dataIsReady = true;
              this.dataReady.emit(true);
            })
          })

        });
      })
    }
  }

  /**set up all of the types and enums */
  loadTypes() {
    return new Promise((resolve, reject) => {
      this.options().subscribe((message: StatusMessage) => {
        this.companyStatuses = message.message.statuses;
        this.companyTypes = message.message.types;
        this.countries = message.message.countries;
        this.defaultCurrency = this.countries.filter(c => c.iso_alpha_2 == "GB")[0];
        this.currencies = this.countries.filter(c=>c.currencySymbol);
        this.siteTypes = message.message.sitetypes;
        this.userSettingTypes = message.message.usersettingtypes;
        this.userRoles = message.message.roles;
        this.modules = message.message.modules;
        this.auth.core.types = true;
        this.ratebrackets = message.message.ratebrackets;
        resolve(message);
      }, err => {
        console.log(err);
      })
    })

  }

  /**on initialisation set the selected provider to the user's company and the user's personal site */
  setupSelectedProvider() {
    if (!this.selectedSupplier) {
      let supplier = this.providers.filter(ca => ca.Provider.id == this.Company.id)[0];
      supplier._baseSite = supplier.Provider.Sites[0];
      this.selectedSupplier = supplier;
    }

  }


  /**
   * returns the values that drive the enumerations CompanyType, CompanyStatus, SiteType plus a list of countries
   */
  options() {
    return this.auth.http.get(environment.host + 'companies/options', this.auth.header());
  }

  //returns the values that drive the enumerations CompanyType, CompanyStatus, SiteType plus a list of countries
  regioncountries() {
    return this.auth.http.get(environment.host + 'companies/regioncountries', this.auth.header());
  }

  //creates a company and and association that links that company to the user's company.
  createCompany(companyAssoc: CompanyAssociation) {
    return this.auth.http.post(environment.host + 'companies/create', companyAssoc, this.auth.header());
  }
  updateCountry(country: Country) {
    return this.auth.http.post(environment.host + 'companies/updatecountry', country, this.auth.header());
  }

  createCompanySettingsCategory(companysettingscategory: CompanySettingsCategory) {
    return this.auth.http.post(environment.host + 'companies/createcompanysettingscategory', companysettingscategory, this.auth.header());
  }

  updateCompanySettingsCategory(companysettingscategory: CompanySettingsCategory) {
    return this.auth.http.post(environment.host + 'companies/updatecompanysettingscategory', companysettingscategory, this.auth.header());
  }
  listCompanySettingsCategorys() {
    return this.auth.http.get(environment.host + 'companies/listcompanysettingscategorys', this.auth.header());
  }
  getCompanySettingsCategory(id: number) {
    return this.auth.http.get(environment.host + 'companies/getcompanysettingscategory/' + id, this.auth.header());
  }

  createCompanySettingType(companysettingtype: CompanySettingType) {
    return this.auth.http.post(environment.host + 'companies/createcompanysettingtype', companysettingtype, this.auth.header());
  }

  updateCompanySettingType(companysettingtype: CompanySettingType) {
    return this.auth.http.post(environment.host + 'companies/updatecompanysettingtype', companysettingtype, this.auth.header());
  }
  updateCompanySetting(companysettingtype: CompanySettingType) {
    return this.auth.http.post(environment.host + 'companies/updatecompanysetting', companysettingtype, this.auth.header());
  }
  listCompanySettingTypes() {
    return this.auth.http.get(environment.host + 'companies/listcompanysettingtypes', this.auth.header());
  }
  getCompanySettingType(id: number) {
    return this.auth.http.get(environment.host + 'companies/getcompanysettingtype/' + id, this.auth.header());
  }

  importCollection(collection:PastedTableCollection){
    return this.auth.http.post(environment.host + 'clip/uploadcollection', collection, this.auth.header());
  }


  /**
   * creates a company and and association that links that company to the user's company.
   * @param site 
   */
  createSite(site: Site) {
    return this.auth.http.post(environment.host + 'companies/createsite', site, this.auth.header());
  }

  /**create a new service using the minimum information needed to create a valid model */
  createMinimumService(destinationiso: string, originiso: string, transittime: number, providerid: number, currencycode: string, originlocodes: Locode[], destinationlocodes: Locode[], mode: TransportModes) {
    return new Promise(resolve => {
      let service = new Service();
      let destinationcountry = this.countries.filter(c => c.iso_alpha_2 == destinationiso)[0];
      service.destinationid = destinationcountry.id;
      let origincountry = this.countries.filter(c => c.iso_alpha_2 == originiso)[0];

      let originstring = "";
      originlocodes.forEach(ol => {
        originstring += ol.nodiacritic + ", ";
      })
      originstring = originstring.substring(0, originstring.length - 2);

      let destinationstring = "";
      destinationlocodes.forEach(ol => {
        destinationstring += ol.nodiacritic + ", ";
      })
      destinationstring = destinationstring.substring(0, destinationstring.length - 2);

      service.originid = origincountry.id;
      let modedesc = mode == TransportModes.Shipping ? "Sea" : "Air";
      service.description = modedesc + " Freight: " + origincountry.name + "(" + originstring + ") to " + destinationcountry.name + " (" + destinationstring + ")";
      service.displayname = originstring + " to " + destinationstring;
      service.transitTime = this.tools.cleanFloat(transittime);
      service.providerid = providerid;
      service.transportMode = TransportModes.Shipping;
      service.currencyid = this.countries.filter(c => c.currencyCode == currencycode)[0].id;
      service.quoteValidity = 30;
      service.OriginServicePorts = [];
      service.isPublic = true;
      service.recordStatus = RecordStatus.Active;
      originlocodes.forEach(ol => {
        let sp = new ServicePort();
        sp.locodeid = ol.id;
        sp.origin = true;
        sp.recordStatus = 0;
        service.OriginServicePorts.push(sp);
      })
      service.DestinationServicePorts = [];
      destinationlocodes.forEach(dl => {
        let sp = new ServicePort();
        sp.locodeid = dl.id;
        sp.origin = false;
        sp.recordStatus = 0;
        service.DestinationServicePorts.push(sp);
      });
      this.createService(service).subscribe((message: StatusMessage) => {
        resolve(message);
      }, err => {
        resolve({ success: false, message: err });
      })

    })

  }

  /**
   * upon the creation of a service, check if there are any ports that do not have corresponding sites
   * add them dynamically to the local instance of the provider
   */
  addMissingPortSites(service: Service) {
    return new Promise(resolve => {
      let portstocreate: ServicePort[] = [];
      service.OriginServicePorts.forEach(os => {
        let exists = false;
        service.Provider.Sites.some((s: Site) => {
          if (s.portid == os.locodeid) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          portstocreate.push(os);
        }
      })
      service.DestinationServicePorts.forEach(ds => {
        let exists = false;
        service.Provider.Sites.some((s: Site) => {
          if (s.portid == ds.locodeid) {
            exists = true;
            return true;
          }
        })
        if (!exists) {
          portstocreate.push(ds);
        }
      })
      let todo = portstocreate.length;
      if (todo == 0) {
        resolve(true);
      }
      else {
        let done = 0;
        portstocreate.forEach((port: ServicePort) => {
          if (port.Locode) {
            this.addPortSite(port.Locode, service.Provider, service.transportMode).then((message: StatusMessage) => {
              done++;
              if (message.success) {
                if (done == todo) {
                  resolve(true);
                }
              }
              else {
                if (done == todo) {
                  resolve(false);
                }
              }
            })
          }
          else {
            done++;
            if (done == todo) {
              resolve(false);
            }

          }

        })
      }

    })
  }
  findOrCreateSurchargeType(name: string, description: string, appliesto: SurchargeAppliesTo, providerid?: number) {
    let surchtype = new SurchargeType();
    surchtype.providerid = providerid | 0;
    surchtype.appliesto = appliesto;
    surchtype.name = name;
    surchtype.description = description;
    return this.auth.http.post(environment.host + 'rates/findorcreatesurchargetype', surchtype, this.auth.header());
  }
  /**
   * create a site on the provided company matching the locode of a particular port.
   * used for port surcharges such as THC
   * @param portlocode 
   * @param company 
   * @param transportMode 
   */
  addPortSite(portlocode: Locode, company: Company, transportMode: TransportModes) {

    return new Promise((resolve) => {
      let site = new Site();
      let locode = portlocode;
      site.companyid = company.id;
      site.name = locode.nodiacritic;
      site.portid = locode.id;

      let sitetype: SiteTypes;
      switch (transportMode) {
        case TransportModes.Air:
          sitetype = SiteTypes.AIRPORT;
          break;
        case TransportModes.Shipping:
          sitetype = SiteTypes.PORT;
          break;
        default:
          sitetype = SiteTypes.PORT;
      }
      site.sitetypeid = sitetype

      let country = this.countries.filter(c => c.iso_alpha_2 == locode.locode1)[0];

      this.locodeAddress(locode, sitetype, country).then((address: Address) => {

        if (!address) {
          //create address from locode;
          address = new Address();
          address.houseNameOrNumber = locode.nodiacritic;
          address.street = locode.nodiacritic;
          address.countryid = country.id;
          address.city = locode.nodiacritic;
          let gpspair = this.tools.latlongfromLocode(locode.location);
          if (gpspair.length == 2) {
            address.gpslat = gpspair[0];
            address.gpslong = gpspair[1];
          }
        }
        site.Address = address;
        company.Sites.push(site);
        this.createSite(site).subscribe((message: StatusMessage) => {
          if (message.success) {
            //oc.controls.Providers.controls
            let newsite: Site = message.message;
            site.id = newsite.id;
            site.addressid = newsite.addressid;
            site.Address.id = site.addressid;
            let provider = this.providers.filter(c => c.Provider.id == newsite.companyid)[0];
            provider.Provider.Sites.push(site);
            resolve({ success: true, message: site });
          }
          else resolve(message);
        }, err => {
          this.tools.gracefulError(err);
          resolve({ success: false, message: err });
        })
      })
    })

  }

  updateProviderAssociation(ca: Company) {
    let index = 0;
    this.providers.some(oldca => {
      if (oldca.Provider.id == ca.id) {
        return true;
      }
      index++;
    })

    if (this.selectedSupplier && this.selectedSupplier.Provider.id == ca.id) {
      this.selectedSupplier.Provider = ca;
    }
    this.providers[index].Provider = ca;
  }
  updateCustomerAssociation(ca: Company) {
    let index = 0;
    this.customers.some(oldca => {
      if (oldca.Customer.id == ca.id) {
        return true;
      }
      index++;
    })
    if (this.selectedCustomer && this.selectedCustomer.Customer.id == ca.id) {
      this.selectedCustomer.Customer = ca;
    }
    this.customers[index].Customer = ca;
  }

  /**
   * get a list of suppliers operating from the ports used in the given service
   * @param serviceid 
   */
  listServicePortSites(serviceid: number) {
    return this.auth.http.get(environment.host + 'companies/portsites/' + serviceid, this.auth.header());
  }

  /**
   * get any consolidation centres relevant to a given portsite
   * @param portsiteid 
   */
  listPortSiteConsolidation(portsiteid: number) {
    return this.auth.http.get(environment.host + 'companies/portconsolidation/' + portsiteid, this.auth.header());
  }

  getAddressFromPortSite(locodeid: number) {
    return this.auth.http.get(environment.host + 'companies/portsiteaddress/' + locodeid, this.auth.header());
  }

  /**
   * 
   * @param selectionid 
   * @param type 
   */
  getCompanyPublic(coid: number) {
    return this.auth.http.get(environment.host + 'companies/publicone/' + coid, this.auth.header());
  }

  getRegion(id:number)
  {
    return this.auth.http.get(environment.host + 'rates/region/' + id, this.auth.header());
  }

  /** return a region based on the international/continent/country/selection level
   * return promise of type Feature, will return empty Feature if not available or not implented
   * @param selectionid (either international, continent or country) 
  */
  getRegionFeatures(selectionid: number, type: RegionTypes) {
    return new Promise((resolve, reject) => {
      if (type == RegionTypes.National) {

      }
      else resolve(new Feature());
    });
  }
  createRegionWithFeature(region: Region) {
    return this.auth.http.post(environment.host + "rates/createregionfeature", region, this.auth.header());
  }

  /**
   * list the base continental regions
   */
  listContinents() {
    return this.auth.http.get(environment.host + "rates/listcontinents", this.auth.header());
  }
  /**
   * returns one or zero regions of given name, companyid=0, regiontype level full hierarchy - dodgy
   * @param level regiontypeid
   * @param name 
   */
  getBaseRegionByName(zonetype: number, name: string, parentid: number) {
    return this.auth.http.get(environment.host + "rates/baseregionbyname/" + zonetype + "/" + name + "/" + parentid, this.auth.header());
  }
  /**
   * returns one or zero regions of given name, companyid=0, regiontype level simple region
   * @param level regiontypeid
   * @param name 
   */
  getBaseRegionChildrenByNameBasic(level: number, name: string) {
    return this.auth.http.get(environment.host + "rates/baseregionchildrenbynamebasic/" + level + "/" + name, this.auth.header());
  }
  createRegionFeature(region: Region) {
    return this.auth.http.post(environment.host + "rates/createfeatureforregion", region, this.auth.header());
  }

  addFeaturePolygonsToParent(featurepolys: FeaturePolygon[]) {
    return this.auth.http.post(environment.host + "rates/addfeaturepolygons", featurepolys, this.auth.header());
  }
  /**
   * once a distance like banding has taken place, add the region's children's polygons to the parent
   * @param regionid
   * returns the region and its Feature and FeaturePolygons
   * db creates a new Feature, updates the region's feature id and inserts featurepolygons via a querey 
   */
  passFeaturePolygonsToParent(regionid: number) {
    return this.auth.http.get(environment.host + "rates/passregionfeaturestoparent/" + regionid, this.auth.header());
  }

  //return promise of type GPS
  /**
   * provide a country and get its GPS centre either from the existing geocode or via google maps api
   * @param country
   * @returns GPS object
   */
  countryGPS(country: Country) {
    return new Promise((resolve, reject) => {
      if (country.gpsid) {
        resolve(country.GPS);
      }
      else {
        if (country.geocode && country.geocode.length > 0) {
          let gps = country.geocode.split(',');
          if (gps.length == 2) {
            let dbgps = new GPS();
            dbgps.lat = parseFloat(gps[0]);
            dbgps.long = parseFloat(gps[1]);
            this.createGPS(dbgps).subscribe((message: StatusMessage) => {
              if (message.success) {
                let newgps: GPS = message.message;
                country.gpsid = newgps.id;
                this.updateCountry(country).subscribe((message: StatusMessage) => {
                  if (message.success) {
                    resolve(newgps);
                  }
                  else {
                    this.tools.gracefulError(message.message);
                    reject(message.message);
                  }
                })
              }
              else {
                this.tools.gracefulError(message.message);
                reject(message.message);
              }
            })

          }
          else {//invalid country geocode
            this.getNewGeoCode(country.name).then((gps: GPS) => {
              country.gpsid = gps.id;
              this.updateCountry(country).subscribe((message: StatusMessage) => {
                if (message.success) {
                  resolve(gps);
                }
                else reject(message.message);
              })

            }, err => {
              reject(err);
            })
          }
        }
        else {//invalid country geocode
          this.getNewGeoCode(country.name).then((gps: GPS) => {
            country.gpsid = gps.id;
            this.updateCountry(country).subscribe((message: StatusMessage) => {
              if (message.success) {
                resolve(gps);
              }
              else reject(message.message);
            })

          }, err => {
            reject(err);
          })
        }
      }

    })
  }
  getNewGeoCode(place: string) {
    return new Promise((resolve, reject) => {
      this.geo.geocode(place).then((message: StatusMessage) => {
        if (message.success) {
          let geo = message.message;
          let lat = geo[0].geometry.location.lat();
          let lng = geo[0].geometry.location.lng();
          let gps = new GPS();
          gps.lat = lat;
          gps.long = lng;
          this.createGPS(gps).subscribe((message: StatusMessage) => {
            if (message.success) {
              resolve(message.message);
            }
            else reject(message.message);
          })
        }
        else reject(message.message);
      })
    })
  }
  /**
   * given a locode, if no lat long, look it up via google geocode from the port name
   * @param locode 
   * @param mode 
   */
  geocodeLocode(locode: Locode, mode: TransportModes) {
    return new Promise((resolve, reject) => {
      if (locode.geoLat && locode.geoLong) {
        resolve(locode);
      }
      else {
        let searchcountry = locode.locode1;
        let countries = this.countries.filter(c=>c.iso_alpha_2==locode.locode1);
        if(countries.length==1){
          searchcountry = countries[0].name;
        }
        
        let place = this.nameForPlace(locode.nodiacritic, mode) + ", " + searchcountry;
        this.geo.geocode(place).then((message: StatusMessage) => {
          console.log("Call to google places");
          if (message.success) {
            let geo = message.message;
            locode.geoLat = geo[0].geometry.location.lat();
            locode.geoLong = geo[0].geometry.location.lng();
            this.updateLocode(locode).subscribe(() => {
              //assume successful
            })
          }
          resolve(locode);
        });
      }
    });
  }
  /**
   * geocode both ports
   * @param preferred a preferred ports object with origin and destination pair
   */
  geocodeLocodes(preferred: PreferredPorts) {
    return new Promise((resolve, reject) => {
      if (preferred.origin.geoLat && preferred.origin.geoLong && preferred.destination.geoLat && preferred.destination.geoLong) {
        resolve(preferred);
      }
      else {
        if (preferred.destination.geoLat && !preferred.origin.geoLat) {
          //do origin and resolve
          this.geocodeLocode(preferred.origin, preferred.mode).then((locode: Locode) => {
            preferred.origin = locode;
            resolve(preferred);
          })
        }
        else if (preferred.origin.geoLat && !preferred.destination.geoLat) {
          //do destination and resolve
          this.geocodeLocode(preferred.destination, preferred.mode).then((locode: Locode) => {
            preferred.destination = locode;
            resolve(preferred);
          })
        }
        else {
          //do both and resolve
          this.geocodeLocode(preferred.origin, preferred.mode).then((locode: Locode) => {
            preferred.origin = locode;
            this.geocodeLocode(preferred.destination, preferred.mode).then((locode2: Locode) => {
              preferred.destination = locode2;
              resolve(preferred);
            })
          })
        }
      }
    });
  }
  /**
   * try and build a searchable name from the given place name and transport mode
   * @param name 
   * @param mode 
   */
  nameForPlace(name: string, mode: TransportModes): string {
    if (mode == TransportModes.Shipping) {
      if (name.toLowerCase().indexOf(' port') < 0) {
        return name + " Port";
      }
      else return name;
    }
    if (mode == TransportModes.Air) {
      if (name.toLowerCase().indexOf(' airport') < 0) {
        return name + " Airport";
      }
      else return name;
    }
    return name;
  }
  gpsFromBounds(region: Region, nodatabase?: boolean) {
    return new Promise((resolve, reject) => {
      if (region.Feature) {
        let index = 0;
        let zones = 0;

        if (region.Feature.FeaturePolygons) {
          let polyindex = 0;
          region.Feature.FeaturePolygons.forEach(p => {

            if (p.Polygon.PolygonPoints) {
              let zonecount = p.Polygon.PolygonPoints.length;
              if (zonecount > zones) {
                zones = zonecount;
                index = polyindex;
              }
              polyindex++;
            }
          })
          let biggestpoly = region.Feature.FeaturePolygons[index];
          let bounds = new google.maps.LatLngBounds();
          biggestpoly.Polygon.PolygonPoints.forEach(pp => {
            bounds.extend(new google.maps.LatLng(pp.lat, pp.long));
          })
          let gps = bounds.getCenter();
          //resolve(region);

          let newgps = new GPS();
          newgps.lat = gps.lat();
          newgps.long = gps.lng();
          if (!nodatabase) {
            this.createGPS(newgps).subscribe((message: StatusMessage) => {
              if (message.success) {
                let result: GPS = message.message;
                region.gpscentreid = result.id;
                this.updateRegion(region).subscribe(() => {
                  resolve(region);
                })
              }
            })
          }
          else {
            region.GPS = newgps;
            resolve(region);
          }

        }
        resolve(region);


      }
    })

  }

  /**async create gps if necessary */
  createOrReturnGPS(gps: GPS) {
    return new Promise((resolve, reject) => {
      if (gps.id) resolve(gps.id);
      else {
        this.createGPS(gps).subscribe((message: StatusMessage) => {
          if (message.success) {
            resolve(message.message.id);
          }
          else {
            reject(message.message);
          }
        }, err => {
          reject(err);
        })
      }
    });
  }


  /**
   * given an array of regiongrouptypes, create a set of child regions with the relevant sub regions.
   * @param group RegionGroupType selected from options when adding a child to a region with such group types
   * @param parentRegion the parent region to which children and grandchildren will be added
   * db interaction for GPS but no regions actually created in db 
   */
  createRegionsFromRegionGroup(group: RegionGroupType, parentRegion: Region,usepostcode:boolean) {

    return new Promise((resolve, reject) => {
      /*let gpsneeded = 0;
      let gpsadded = 0;
      //get total subregions first so we can check all the GPS details have been added async
      group.RegionGroups.forEach(rg => {
        gpsneeded += rg.RegionGroupChildren.length;
      })
      if (!parentRegion.children) {
        parentRegion.children = [];
      }*/
      group.RegionGroups.forEach(rg => {
        let region: Region = new Region(parentRegion.companyid, group.zonetype);
        if (!parentRegion.children) parentRegion.children = [];
        parentRegion.children.push(region);

        region.parentId = parentRegion.id;
        region.continentid = parentRegion.continentid;
        region.countryid = parentRegion.countryid;
        region.regiontypeid = RegionTypes.Group;
        region.featureid = rg.featureid;
        region.name = rg.description;


        rg.RegionGroupChildren.forEach(rgc => {
          let zonetype = rgc.Region.zonetype;
          if(!zonetype){
            zonetype = this.getChildZoneType(group.zonetype,"RegionGroup",usepostcode);
          }
          let child: Region = new Region(region.companyid,zonetype);
          region.children.push(child);
          child.name = rgc.Region.name;
          child.regiontypeid = rgc.Region.regiontypeid;
          child.featureid = rgc.Region.featureid;
          child.countryid = rgc.Region.countryid;
          child.continentid = rgc.Region.continentid;
          child.zoom = rgc.Region.zoom;
          child.baseregionid = rgc.regionid;

          if (rgc.Region.gpscentreid) {
            child.gpscentreid = rgc.Region.gpscentreid;
            child.GPS = rgc.Region.GPS;
          }
          //why?
          /*
          else if (child.countryid) {
            let countries = this.countries.filter(c => c.id == child.countryid);
            if (countries.length > 0) {
              this.countryGPS(countries[0]).then((gps: GPS) => {
                child.GPS = gps;
                child.gpscentreid = gps.id;
                gpsadded++;
                if (gpsadded == gpsneeded) {
                  resolve({ success: true, message: "Regions Added" });
                }
              }, err => {
                reject({ success: false, message: err });
              })
            }
            else {
              if (gpsadded == gpsneeded) {
                resolve({ success: true, message: "Regions Added - some countries not GPSsed" });
              }
            }

          }*/

        })

      })
      resolve({ success: true, message: "Regions Added" });
    })


  }

  getChildZoneType(zone: ZoneType,grouptype:string,usepostcode?:boolean) {
    switch (zone) {
      case ZoneType.International:
        return ZoneType.Continental;
      case ZoneType.Continental:
        switch(grouptype){
          case "Regional":
            return ZoneType.RegionalCountryGroup;
          case "Political":
            return ZoneType.PoliticalCountryGroup;
          case "Distance":
            return ZoneType.DistanceCountryGroup;
          default: 
          return ZoneType.CustomCountryGroup;
        }
      case ZoneType.RegionalCountryGroup:
      case ZoneType.PoliticalCountryGroup:
      case ZoneType.DistanceCountryGroup:
      case ZoneType.CustomCountryGroup:
        return ZoneType.National;
      case ZoneType.National:
        switch(grouptype)  {
          case "TerritoryGroup":
            return ZoneType.TerritoryGroup;
          case "RegionGroup":
            return ZoneType.NationalRegionGroup;
          case "CountyGroup":
            return ZoneType.NationalCountyGroup;
          case "OutcodeGroup":
            return ZoneType.NationalOutcodeGroup;
          case "DistanceGroup":
            return ZoneType.NationalDistanceGroup;
          default:
            return ZoneType.NationalCustomGroup;
        }
      case ZoneType.TerritoryGroup:
        return ZoneType.NationalTerritory;
      case ZoneType.NationalRegionGroup:
        return ZoneType.NationalRegion;
      case ZoneType.NationalCountyGroup:
        return ZoneType.NationalCounty;
      case ZoneType.NationalOutcodeGroup:
        return ZoneType.NationalOutcode;
      case ZoneType.NationalDistanceGroup:
        return ZoneType.DrivingDistance;
      case ZoneType.NationalCustomGroup:
        if(usepostcode) return ZoneType.NationalOutcode;
        else return ZoneType.NationalRegion;
      case ZoneType.NationalTerritory:
        switch(grouptype)  {
          case "Region":
            return ZoneType.NationalRegion;
          case "County":
            return ZoneType.NationalCounty;
          case "Outcode":
            return ZoneType.NationalOutcode;
          case "Distance":
            return ZoneType.DrivingDistance;
        }
      case ZoneType.NationalOutcode:
        return ZoneType.IncodeGroup;
      case ZoneType.IncodeGroup:
        return ZoneType.Incode;
    }
  }
  /**deprecated - zonetype now on regiongrouptype object */
  getZoneFromRegionGroup(regiongroup: RegionGroupType, regiontype: RegionTypes) {
    switch (regiontype) {
      case RegionTypes.Continental:
        switch (regiongroup.description) {
          case "Geographical Regions":
            return ZoneType.RegionalCountryGroup;
          case "Political Regions":
            return ZoneType.PoliticalCountryGroup;
          default:
            return ZoneType.RegionalCountryGroup;
        }
      case RegionTypes.National:
        if (regiongroup.description == "Territories") {
          return ZoneType.TerritoryGroup;
        }
        else if (regiongroup.description == "Counties") {
          return ZoneType.NationalCountyGroup;
        }
        else if (regiongroup.description == "Out Codes") {
          return ZoneType.NationalOutcodeGroup;
        }
        else return ZoneType.NationalRegionGroup;
      default:
        return ZoneType.NationalRegionGroup;

    }

  }

  //returns a list of CompanyAssociations - the link between a user's company and its providers or customers
  listCustomers(customers?: boolean) {
    if (customers) return this.auth.http.get(environment.host + 'companies/listc', this.auth.header());
    else return this.auth.http.get(environment.host + 'companies/list', this.auth.header());
  }

  //a single provider, requires a CompanyAssociation to be active.
  getProvider(id: number) {
    return this.auth.http.get(environment.host + 'companies/one/' + id.toString(), this.auth.header());
  }
  //a single customer, requires a CompanyAssociation to be active.
  getCustomer(id: number) {
    return this.auth.http.get(environment.host + 'companies/onec/' + id.toString(), this.auth.header());
  }
  updateCompany(company: Company) {
    return this.auth.http.post(environment.host + 'companies/update', company, this.auth.header());
  }

  /**
   * list companies providing a service from a particular port
   * @param portid locode
   */
  listPortProviders(portid: number) {
    return this.auth.http.get(environment.host + 'companies/sitelistwithport/' + portid, this.auth.header());
  }

  updateUser(user: User) {
    return this.auth.http.post(environment.host + 'companies/updateuser', user, this.auth.header());
  }
  updateUserRole(userrole: UserRole) {
    return this.auth.http.post(environment.host + 'companies/updateuserrole', userrole, this.auth.header());
  }
  updateUserSetting(usersetting: UserSetting) {
    return this.auth.http.post(environment.host + 'companies/updateusersetting', usersetting, this.auth.header());
  }
  updateModuleUser(moduleuser: ModuleUser) {
    return this.auth.http.post(environment.host + 'companies/updatemoduleuser', moduleuser, this.auth.header());
  }
  createInvitation(invitation: Invitation) {
    return this.auth.http.post(environment.host + 'companies/createinvite', invitation, this.auth.header());

  }
  sendInvitation(invitation: Invitation) {
    return this.auth.http.post(environment.host + 'companies/newinvite', invitation, this.auth.header());

  }
  resendInvitation(invitation: Invitation) {
    return this.auth.http.get(environment.host + 'companies/resendinvite/' + invitation.id, this.auth.header());
  }
  updateInvitation(invitation: Invitation) {
    return this.auth.http.post(environment.host + 'companies/updateinvite', invitation, this.auth.header());
  }
  inviteFromGuid(guid: string) {
    return this.auth.http.get(environment.host + 'companies/invitefromguid/' + guid);
  }

  listMilestones(projectid: number) {
    return this.auth.http.get(environment.host + 'milestones/list/' + projectid.toString(), this.auth.header());
  }
  listMilestoneFolders(projectid: number) {
    return this.auth.http.get(environment.host + 'milestones/listfolders/' + projectid.toString(), this.auth.header());
  }
  updateMilestone(milestone: Milestone) {
    return this.auth.http.post(environment.host + 'milestones/update', milestone, this.auth.header());
  }

  createMilestone(milestone: Milestone) {
    return this.auth.http.post(environment.host + 'milestones/create', milestone, this.auth.header());
  }

  getStartDate() {
    return this.auth.http.get(environment.host + 'milestones/start', this.auth.header());
  }

  listIssues() {
    return this.auth.http.get(environment.host + 'issues/list', this.auth.header());
  }
  updateIssue(issue: Issue) {
    return this.auth.http.post(environment.host + 'issues/update', issue, this.auth.header());
  }

  createIssue(issue: Issue) {
    return this.auth.http.post(environment.host + 'issues/create', issue, this.auth.header());
  }
  /**
   * list services provided by the signed in user's company
   */
  listOwnServices() {
    return this.auth.http.get(environment.host + 'services/listowned', this.auth.header());
  }
  /**
   * list services provided by the signed in user's company or those with a service user = signed in user
   */
  listAllServices() {
    return this.auth.http.get(environment.host + 'services/listall', this.auth.header());
  }
  /**
   * public service (TODO signin required?)
   * list services provided by companies with association, either public services or those with
   * a serviceuser of coid
   * @param origincountryid 
   * @param destinationcountryid 
   * @param coid
   *  returns an array of CompanyAssociations, each with a set of Services including Origin and Destination Service Ports
   * is a concatenation of public services and services only available to the provided coid
   */
  listServicesEndPoints(origincountryid: number, destinationcountryid: number, coid: number) {
    return this.auth.http.get(environment.host + 'services/byendpoints/' + origincountryid + '/' + destinationcountryid + '/' + coid, this.auth.header());
  }

  findServiceFromPorts(providerid: number, originports: number[], destinationports: number[]) {
    let body = { providerid: providerid, originports: originports, destinationports: destinationports };
    return this.auth.http.post(environment.host + 'services/fromports', body, this.auth.header());
  }
  locodeSearch(locode1: string, originports: string[],mode:number) {
    let body = { locode1: locode1, originports: originports,mode:mode };
    return this.auth.http.post(environment.host + 'services/locodesearch', body, this.auth.header());
  }

  listServicesModeRegions(servicerequest: ServiceRequest) {
    return this.auth.http.post(environment.host + 'services/listservicesmoderegion', servicerequest, this.auth.header());
  }

  getService(id: number) {
    return this.auth.http.get(environment.host + 'services/get/' + id, this.auth.header());
  }

  createService(service: Service) {
    return this.auth.http.post(environment.host + 'services/create', service, this.auth.header());
  }
  /**
   * create or update a service
   * @param service
   * @param changes - pass in a changes parameter so calling function can operate on other objects if affected
   */
  createOrUpdateService(service: Service, changes: boolean) {
    return new Promise((resolve) => {
      if (!changes) resolve({ success: true, message: service })
      if (service.id && service.id > 0) {
        this.updateService(service).subscribe((message: StatusMessage) => {
          if (message.success) {
            resolve({ success: true, message: service });
          }
          else {
            this.tools.gracefulError(message.message);
            resolve({ success: false, message: message.message });
          }
        }, err => {
          this.tools.gracefulError(err);
          resolve({ success: false, message: err });
        })
      }
      else {
        this.createService(service).subscribe((message: StatusMessage) => {
          if (message.success) {
            service.id = message.message.id;
            resolve({ success: true, message: service });
          }
          else {
            this.tools.gracefulError(message.message);
            resolve({ success: false, message: message.message });
          }
        }, err => {
          this.tools.gracefulError(err);
          resolve({ success: false, message: err });
        })
      }
    })
  }


  updateService(service: Service) {
    return this.auth.http.post(environment.host + 'services/update', service, this.auth.header());
  }

  createServiceGroup(servicegroup: ServiceGroup) {
    return this.auth.http.post(environment.host + 'services/creategroup', servicegroup, this.auth.header());
  }


  updateServiceGroup(servicegroup: ServiceGroup) {
    return this.auth.http.post(environment.host + 'services/updategroup', servicegroup, this.auth.header());
  }

  createServiceGroupService(servicegroupservice: ServiceGroupService) {
    return this.auth.http.post(environment.host + 'services/creategroupservice', servicegroupservice, this.auth.header());
  }

  updateServiceGroupService(servicegroupservice: ServiceGroupService) {
    return this.auth.http.post(environment.host + 'services/updategroupservice', servicegroupservice, this.auth.header());
  }

  createServicePort(serviceport: ServicePort) {
    return this.auth.http.post(environment.host + 'services/createport', serviceport, this.auth.header());
  }

  updateServicePort(serviceport: ServicePort) {
    return this.auth.http.post(environment.host + 'services/updateport', serviceport, this.auth.header());
  }
  updateLocode(locode: Locode) {
    return this.auth.http.post(environment.host + 'services/updatelocode', locode, this.auth.header());
  }

  createServiceUser(serviceuser: ServiceUser) {
    return this.auth.http.post(environment.host + 'services/createuser', serviceuser, this.auth.header());
  }

  updateServiceUser(serviceuser: ServiceUser) {
    return this.auth.http.post(environment.host + 'services/updateuser', serviceuser, this.auth.header());
  }

  createVessel(vessel: Vessel) {
    return this.auth.http.post(environment.host + 'services/createvessel', vessel, this.auth.header());
  }

  updateVessel(vessel: Vessel) {
    return this.auth.http.post(environment.host + 'services/updatevessel', vessel, this.auth.header());
  }


  listLocodesByCountry(whereclause: ServiceWhereClause) {
    return this.auth.http.post(environment.host + 'services/locodescountry', whereclause, this.auth.header());
  }

  listUsefulCountries() {
    return this.auth.http.get(environment.host + "companies/usefulcountries", this.auth.header());
  }

  createDocument(document: Document) {
    return this.auth.http.post(environment.host + 'documents/create', document, this.auth.header());
  }

  updateDocument(document: Document) {
    return this.auth.http.post(environment.host + 'documents/update', document, this.auth.header());
  }


  createDocumentType(documenttype: DocumentType) {
    return this.auth.http.post(environment.host + 'documents/createtype', documenttype, this.auth.header());
  }

  updateDocumentType(documenttype: DocumentType) {
    return this.auth.http.post(environment.host + 'documents/updatetype', documenttype, this.auth.header());
  }
  getDocumentByType(documenttypeid: number) {
    return this.auth.http.get(environment.host + 'documents/getdocumentbytype/' + documenttypeid, this.auth.header());
  }
  getDocumentsByTypes(documenttypeids: number[]) {
    return this.auth.http.get(environment.host + 'documents/getdocumentsbytypes/' + documenttypeids.join('&'), this.auth.header());
  }
  listDocuments(companyid: number) {
    return this.auth.http.get(environment.host + 'documents/listdocuments/' + companyid, this.auth.header());
  }
  listDocumentGroups(companyid: number) {
    return this.auth.http.get(environment.host + 'documents/listgroups/' + companyid, this.auth.header());
  }
  createDocumentGroup(documentgroup: DocumentGroup) {
    return this.auth.http.post(environment.host + 'documents/creategroup', documentgroup, this.auth.header());
  }

  updateDocumentGroup(documentgroup: DocumentGroup) {
    return this.auth.http.post(environment.host + 'documents/updategroup', documentgroup, this.auth.header());
  }

  listDocumentTypes() {
    return this.auth.http.get(environment.host + 'documents/listtypes', this.auth.header());
  }
  uploadDocumentImage(form: FormData) {
    return this.auth.http.post(environment.host + 'documents/upload', form, this.auth.headerImage());
  }

  listDocumentFields(documenttypeid:number){
    return this.auth.http.get(environment.host + 'documents/listdocumentfields/'+documenttypeid, this.auth.header());
  }

  getDocument(documentid:number){
    return this.auth.http.get(environment.host + 'documents/constructdocument/'+documentid, this.auth.header());
  }

    //#region clipboard

    uploadPDF(form: FormData){
      return this.auth.http.post(environment.host + 'documents/uploadpdf',form, this.auth.headerImage());
    }
    parsePDF(guid:string){
      return this.auth.http.get(environment.host + 'clip/parse/'+guid, this.auth.header());
    }

    test(){
      return this.auth.http.post(environment.host + 'clip/dpdcheck',{coid:137,accnumber:"hello"}, this.auth.headerImage());
    }
  
    //#endregion

  createRegionType(regiontype: RegionType) {
    return this.auth.http.post(environment.host + 'rates/createregiontype', regiontype, this.auth.header());
  }

  updateRegionType(regiontype: RegionType) {
    return this.auth.http.post(environment.host + 'rates/updateregiontype', regiontype, this.auth.header());
  }

  getFeaturePolygons(featureid: number) {
    return this.auth.http.get(environment.host + 'rates/getfeaturepolygons/' + featureid, this.auth.header());
  }
  getFeaturePolygonsChildren(featureids: number[]) {
    return this.auth.http.post(environment.host + 'rates/getfeaturepolygonschildren', featureids, this.auth.header());
  }
  createFeaturePolygon(featurepolygon: FeaturePolygon) {
    return this.auth.http.post(environment.host + 'rates/createfeaturepolygon', featurepolygon, this.auth.header());
  }
  createFeaturePolygons(featurepolygons: FeaturePolygon[]) {
    return this.auth.http.post(environment.host + 'rates/createfeaturepolygons', featurepolygons, this.auth.header());
  }
  /**
   * create a new feature, update the region's feature id and add the feature polygons to the feature.
   * @param feature 
   * @param regionid
   * return the newly created feature and feature polygons 
   */
  createFeatureAndFeaturePolygons(feature: Feature, regionid: number) {
    return this.auth.http.post(environment.host + 'rates/createfeatureandfeaturepolygons/' + regionid, feature, this.auth.header());
  }


  updateFeaturePolygon(featurepolygon: FeaturePolygon) {
    return this.auth.http.post(environment.host + 'rates/updatefeaturepolygon', featurepolygon, this.auth.header());
  }

  createGPS(gps: GPS) {
    return this.auth.http.post(environment.host + 'rates/creategps', gps, this.auth.header());
  }

  updateGPS(gps: GPS) {
    return this.auth.http.post(environment.host + 'rates/updategps', gps, this.auth.header());
  }
  /**
   * returns full region no hierarchy but including feature and polygons
   * will not contain features if called without first creating or referencing existing feature
   * @param region 
   */
  createRegion(region: Region) {
    return this.auth.http.post(environment.host + 'rates/createregion', region, this.auth.header());
  }

  updateRegion(region: Region) {
    region = this.simpleRegion(region);
    return this.auth.http.post(environment.host + 'rates/updateregion', region, this.auth.header());
  }

  /**
   * 
   * @param region the region we are moving, only the parentid has changed
   * @param oldregionid the region id where the moving region used to be a child of
   */
  moveRegionBetweenParents(region: Region, oldregionid: number) {
    return this.auth.http.post(environment.host + 'rates/moveregionbetweenparents/' + oldregionid, region, this.auth.header());
  }

  /**
   * regions by type including hierarchy, gps and feature, no polygons.
   * @param typeid 
   */
  listRegionsByType(typeid: number) {
    return this.auth.http.get(environment.host + 'rates/listregions/' + typeid, this.auth.header());
  }
  /**
   * all regions for the signed in user, including hierarchy and polygons
   */
  listRegionsAllTypes() {
    return this.auth.http.get(environment.host + 'rates/listregionsall', this.auth.header());
  }
  /**
   * a list of child regions with the provided parentid, including features and polygons
   * @param parentid 
   */
  listRegionsChildren(parentid) {
    return this.auth.http.get(environment.host + 'rates/listregionschildren/' + parentid, this.auth.header());
  }
  /**
   * a list of child regions with the provided parentid, including features and polygons
   * @param parentid 
   */
  cloneBaseChildren(region:Region) {
    return this.auth.http.post(environment.host + 'rates/clonebasechildren',region, this.auth.header());
  }
  /**
   * a list of child regions with the provided parentid, including features and polygons
   * @param parentid 
   */
  listBaseRegionsChildren(parentid) {
    return this.auth.http.get(environment.host + 'rates/listregionschildren/' + parentid, this.auth.header());
  }
  /**
   * startup regions with immediate children, no polygons
   * @param level 
   * @param parentid 
   */
  listRegionsStartup(level: number, parentid: number, siteid: number) {
    return this.auth.http.get(environment.host + 'rates/listregionsstartup/' + level + "/" + parentid + "/" + siteid, this.auth.header());
  }
  listRegionCheck() {
    return this.auth.http.get(environment.host + 'rates/regioncheck', this.auth.header());
  }
  searchRegions(searchstring: string) {
    return this.auth.http.get(environment.host + 'rates/searchregions/' + searchstring, this.auth.header());
  }
  searchRegionsType(searchstring: string, regiontypeid: number) {
    return this.auth.http.get(environment.host + 'rates/searchregionstype/' + searchstring + "/" + regiontypeid.toString(), this.auth.header());
  }
  /**
   * give a region and it's unsaved clone, create a copy, rename the regions appropriately and split the children between the two.
   * @param region 
   * @param clone 
   */
  splitRegion(region:Region,clone:Region){
    let message = {region:region,clone:clone};
    return this.auth.http.post(environment.host + 'rates/splitregion',message, this.auth.header());
  }

  /**
   * List the different regional versions for this site and coverage level. Versions could be import or export for example
   * @param basesite //site from which to look for region coverage
   * @param viewid //what level to look (national = 0, continental = 1) refactor to avoid magic numbers
   */
  listVersions(basesite: number, viewid?: number, justrates?: boolean) {
    if (justrates) {
      return this.listCountryVersionsWithRates(basesite);
    }

    if (!viewid) viewid = 0;
    return new Promise((resolve) => {
      switch (viewid) {
        case 0:

          this.listCountryRegionsHavingBands(basesite).subscribe((message: StatusMessage) => {
            if (message.success) {
              message.message = message.message.res[0];
              resolve(message);
              /*this.versions = 
              if (this.versions.length > 0) {
                this.selectedVersion = this.versions[0];
                resolve(true);
              }
              else resolve(false);*/
            }
            else {
              this.tools.gracefulError(message.message);
              resolve(message);
            }
          },
            err => {
              this.tools.gracefulError(err)
              resolve({ success: false, message: err });
            }
          );

          break;
        case 1:

          this.listContinentsHavingBands(basesite).subscribe((message: StatusMessage) => {
            if (message.success) {
              message.message = message.message.res[0];
              resolve(message);
            }
            else {
              resolve(message);
            }
          },
            err => {
              this.tools.gracefulError({ success: false, message: err })
              resolve(false);
            }
          );
          break;
        case 2:
          this.listWorldZonesHavingBands(basesite).subscribe((message: StatusMessage) => {
            if (message.success) {
              message.message = message.message.res[0];
              resolve(message);
            }
            else {
              resolve(message);
            }
          },
            err => {
              this.tools.gracefulError({ success: false, message: err })
              resolve(false);
            }
          );
        break;
      }
    })

  }
  /**
 * List the different regional versions for this site and coverage level. Versions could be import or export for example
 * @param basesite //site from which to look for region coverage
 * @param viewid //what level to look (national = 0, continental = 1) refactor to avoid magic numbers
 */
  listCountryVersionsWithRates(basesite: number) {

    return new Promise((resolve) => {
      this.listCountryRegionsWithRates(basesite).subscribe((message: StatusMessage) => {
        if (message.success) {
          message.message = message.message.res[0];
          resolve(message);
          /*this.versions = 
          if (this.versions.length > 0) {
            this.selectedVersion = this.versions[0];
            resolve(true);
          }
          else resolve(false);*/
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(message);
        }
      },
        err => {
          this.tools.gracefulError(err)
          resolve({ success: false, message: err });
        }
      );

    })

  }

  /**
   * create a new regiondistancer
   * @param regiondistancer 
   */
  createRegionDistancer(regiondistancer: RegionDistancer) {
    return this.auth.http.post(environment.host + 'rates/createregiondistancer', regiondistancer, this.auth.header());
  }
  /**
   * update a regiondistancer
   * @param regiondistancer
   */
  updateRegionDistancer(regiondistancer: RegionDistancer) {
    return this.auth.http.post(environment.host + 'rates/updateregiondistancer', regiondistancer, this.auth.header());
  }

  /**
   * list all of the child regions of this parent with the associated regional distancer object.
   * @param parentregionid 
   */
  listRegionDistancerFromParent(parentregionid: number) {
    return this.auth.http.get(environment.host + 'rates/listregiondistancerbyparent/' + parentregionid, this.auth.header());
  }


  createOrUpdateRegionDistancer(child: Region) {

    if (child.RegionDistancer.id > 0) {
      this.updateRegionDistancer(child.RegionDistancer).subscribe((message: StatusMessage) => {
        if (!message.success) {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }
    else {
      this.createRegionDistancer(child.RegionDistancer).subscribe((message: StatusMessage) => {
        if (message.success) {
          child.RegionDistancer.id = message.message.id;
        }
        else {
          this.tools.gracefulError(message.message);
        }
      }, err => {
        this.tools.gracefulError(err);
      })
    }
  }

  /**
   * get the base country region, no children, feature and gps but no polygons
   * @param countryid 
   */
  getCountryRegion(countryid: number) {
    return this.auth.http.get(environment.host + 'rates/countryregion/' + countryid, this.auth.header());
  }
  getCountryRegionAndChildren(countryid: number) {
    return this.auth.http.get(environment.host + 'rates/countryregionandchildren/' + countryid, this.auth.header());
  }
  /**
   * return regions added as new to the national tab of the regions page. These are regions where the parent is a country and the children are some form of regional country grouping.
   * the db access is via a query rather than a model. This should be refactored when countries are refactored as regions rather than their own model.
   */
  listCountryRegionsHavingBands(basesiteid: number) {
    return this.auth.http.get(environment.host + 'rates/listcountrieshavingbands/' + basesiteid, this.auth.header());
  }
  /**
   * return regions
   */
  listCountryRegionsWithRates(basesiteid: number) {
    return this.auth.http.get(environment.host + 'rates/listversionswithrates/' + basesiteid, this.auth.header());
  }
  /**
   * list continental regions with bands created in the regions page, combine with ListCountries after refactoring
   * @param level = 2, add param on refactor
   */
  listContinentsHavingBands(basesiteid: number) {
    return this.auth.http.get(environment.host + 'rates/listcontinentshavingbands/' + basesiteid, this.auth.header());
  }
  /**
   * list world zones with bands created in the regions page, combine with ListCountries after refactoring
   * @param level = 1, add param on refactor
   */
  listWorldZonesHavingBands(basesiteid: number) {
    return this.auth.http.get(environment.host + 'rates/listworldzoneshavingbands/' + basesiteid, this.auth.header());
  }
  /**
   * get the region coverage for the origin and destination ports
   * @param originportid
   * @param destinationportid 
   */
  listRegionsWithPortCoverage(originportid: number, destinationportid, origincountry, destinationcountry) {
    return this.auth.http.get(environment.host + 'rates/listregionsportcoverage/' + originportid + "/" + destinationportid + "/" + origincountry + "/" + destinationcountry, this.auth.header());
  }

  /**
   * 
   * @param id 
   */
  getRegionDetail(region: Region) {
    return new Promise((resolve, reject) => {
      if (region.Feature && region.Feature.FeaturePolygons) resolve(region);
      else {
        this.auth.http.get(environment.host + 'rates/getregion/' + region.id, this.auth.header()).subscribe((message: StatusMessage) => {
          if (message.success) {
            resolve(message.message);
          }
          else {
            reject(message.message);
          }
        }, err => reject(err))
      }
    })

  }

  getRegionHierarchy(id: number) {
    return this.auth.http.get(environment.host + 'rates/getregionhierarchy/' + id, this.auth.header());
  }

  getRegionProviderCode(code: string, providerid: number) {
    return this.auth.http.get(environment.host + 'rates/getregioncodeprovider/' + code + '/' + providerid, this.auth.header());
  }
  createRegionGroupType(regiongrouptype: RegionGroupType) {
    return this.auth.http.post(environment.host + 'rates/createregiongrouptype', regiongrouptype, this.auth.header());
  }

  updateRegionGroupType(regiongrouptype: RegionGroupType) {
    return this.auth.http.post(environment.host + 'rates/updateregiongrouptype', regiongrouptype, this.auth.header());
  }
  listRegionGroupTypes(regionid: number) {
    return this.auth.http.get(environment.host + 'rates/listregiongrouptypes/' + regionid, this.auth.header());
  }
  listCountryRegionGroupTypes(regionid: number) {
    return this.auth.http.get(environment.host + 'rates/listcountryregiongrouptypes/' + regionid, this.auth.header());
  }
  listAdminRegions(regiontypeid: number) {
    return this.auth.http.get(environment.host + 'rates/listadminregions/' + regiontypeid, this.auth.header());
  }
  listOrphanAdminRegions(regiontypeid: number) {
    return this.auth.http.get(environment.host + 'rates/listorphanadminregions/' + regiontypeid, this.auth.header());
  }
  listCountriesByContinent(continentid: number) {
    return this.auth.http.get(environment.host + 'rates/listcountriesbycontinent/' + continentid, this.auth.header());
  }
  listCountriesByContinentPolys(continentid: number) {
    return this.auth.http.get(environment.host + 'rates/listcountriesbycontinentpolys/' + continentid, this.auth.header());
  }
  listRegionsByCountryPolys(continentid: number) {
    return this.auth.http.get(environment.host + 'rates/listregionsbycountrypolys/' + continentid, this.auth.header());
  }
  listRegionsByCountry(countryid: number) {
    return this.auth.http.get(environment.host + 'rates/listregionsbycountry/' + countryid, this.auth.header());
  }
  /**
   * list child and immediate grandchildren, no hierarchy, no features
   * @param parentid 
   */
  listRegionsGrandchildren(parentid: number) {
    return this.auth.http.get(environment.host + 'rates/listregionsgrandchildren/' + parentid, this.auth.header());
  }
  /**
   * list regions, hierarchy, no features
   * @param parentid 
   */
  listRegionsDeep(parentid: number) {
    return this.auth.http.get(environment.host + 'rates/listregionsdeep/' + parentid, this.auth.header());
  }
  listRegionsRates(parentid: number,priority:number,customerid:number) {
    return this.auth.http.post(environment.host + 'rates/listregionsandrates',{parentid:parentid,priority:priority,customerid:customerid}, this.auth.header());
  }
  createRegionGroupChild(regiongroupchild: RegionGroupChild) {
    return this.auth.http.post(environment.host + 'rates/createregiongroupchild', regiongroupchild, this.auth.header());
  }

  updateRegionGroupChild(regiongroupchild: RegionGroupChild) {
    return this.auth.http.post(environment.host + 'rates/updateregiongroupchild', regiongroupchild, this.auth.header());
  }

  createRegionGroup(regiongroup: RegionGroup) {
    return this.auth.http.post(environment.host + 'rates/createregiongroup', regiongroup, this.auth.header());
  }

  updateRegionGroup(regiongroup: RegionGroup) {
    return this.auth.http.post(environment.host + 'rates/updateregiongroup', regiongroup, this.auth.header());
  }

  listRegionGroups(regionid: number) {
    return this.auth.http.get(environment.host + 'rates/listregiongroupsbyregion/' + regionid, this.auth.header());
  }


  createRateBreak(ratebreak: RateBreak) {
    return this.auth.http.post(environment.host + 'rates/createratebreak', ratebreak, this.auth.header());
  }

  updateRateBreak(ratebreak: RateBreak) {
    return this.auth.http.post(environment.host + 'rates/updateratebreak', ratebreak, this.auth.header());
  }

  createRateBreakPremium(ratebreakpremium: RateBreakPremium) {
    return this.auth.http.post(environment.host + 'rates/createratebreakpremium', ratebreakpremium, this.auth.header());
  }

  updateRateBreakPremium(ratebreakpremium: RateBreakPremium) {
    return this.auth.http.post(environment.host + 'rates/updateratebreakpremium', ratebreakpremium, this.auth.header());
  }

  setCurrency(currencyid:number){
    let currencies = this.currencies.filter(c=>c.id==currencyid);
    if(currencies.length==1) return currencies[0];
    else return this.defaultCurrency;
  }

  createRate(rate: Rate) {
    return this.auth.http.post(environment.host + 'rates/create', rate, this.auth.header());
  }
  /**
   * promise to create or update a rate including rate breaks and premiums
   * @param rate
   * @param changes - pass in a changes param so calling function can handle further object updates if required.
   */
  createOrUpdateRate(rate: Rate, changes: boolean) {
    return new Promise((resolve) => {
      if (!changes || !rate) resolve({ success: true, message: rate });
      else {
        if (rate.id && rate.id > 0) {
          this.updateRate(rate).subscribe((message: StatusMessage) => {
            if (message.success) {
              resolve({ success: true, message: rate });
            }
            else {
              this.tools.gracefulError(message.message);
              resolve({ success: false, message: message.message });
            }
          }, err => {
            this.tools.gracefulError(err);
            resolve({ success: false, message: err });
          })
        }
        else {
          this.createRate(rate).subscribe((message: StatusMessage) => {
            if (message.success) {
              rate.id = message.message.id;
              resolve({ success: true, message: rate });
            }
            else {
              this.tools.gracefulError(message.message);
              resolve({ success: false, message: message.message });
            }
          }, err => {
            this.tools.gracefulError(err);
            resolve({ success: false, message: err });
          })
        }
      }
    })
  }
  createOrUpdateRates(rateform: FormArray) {
    return new Promise((resolve) => {

      let todo = rateform.controls.length;
      let done = 0;
      let success = true;
      if (rateform.controls.length == 0) resolve({ success: true, message: rateform });
      else {
        rateform.controls.forEach(c => {
          this.createOrUpdateRate(c.value, c.dirty).then((message: StatusMessage) => {
            if (message.success) {
              done++;
              c.reset(message.message);
              //c.get("id").setValue(message.message.id);
              if (done == todo) {
                resolve({ success: success, message: rateform });
              }
            }
            else {
              done++;
              success = false;
              if (done == todo) {
                resolve({ success: success, message: rateform });
              }
            }
          }, err => {
            done++;
            success = false;
            if (done == todo) {
              resolve({ success: success, message: rateform });
            }
          })
        })
      }

    })

  }
  /**
   * create a set of rates
   * @param ratearray an array of rates, sharing a parentregionid
   */
  createRates(ratearray: Rate[], regionid: number, ratename: string) {
    return new Promise((resolve) => {
      let todo = ratearray.length;
      let done = 0;
      let errors = 0;
      let resmessage: StatusMessage = new StatusMessage();
      let envelope = new RateEnvelope(regionid);
      envelope.name = ratename;

      this.createRateEnvelope(envelope).subscribe((message: StatusMessage) => {
        if (message.success) {
          let envid = message.message.id;
          resmessage.message = { ratearray: [], errors: 0 };
          ratearray.forEach((rate: Rate) => {
            rate.envelopeid = envid;
            this.createRate(rate).subscribe((message: StatusMessage) => {
              if (message.success) {
                rate = message.message;
                done++;
                if (done + errors == todo) {
                  resmessage.success = true;
                  resmessage.message.ratearray = message.message;
                  resmessage.message.errors = errors;
                  resolve(resmessage);
                }
              }
              else {
                errors++;
                if (done + errors == todo) {
                  resmessage.success = false;
                  resmessage.message.ratearray = message.message;
                  resmessage.message.errors = errors;
                  resolve(resmessage);
                }
              }
            }, err => {
              errors++;
              if (done + errors == todo) {
                resmessage.success = false;
                resmessage.message.errors = errors;
                resolve(resmessage);
              }
            })
          })
        }
        else resolve(message);
      })

    })
  }
  /**
   * return a list of rates with the relevent parameters, includes ratebreaks and bandpremiums - TODO include overrides
   * @param regionid the version region (essentially the band created in region management)
   * @param priority express, standard, next day etc
   */
  listRates(regionid: number, priority: number, mode: number) {
    return this.auth.http.get(`${environment.host}rates/list/${regionid}/${priority}/${mode}`, this.auth.header());
  }
  /**
   * return a list of rates by service
   * EXPERIMENTAL - also including surcharges so Statusmessage = {rate:rate,surcharges:surcharges};
   * @param serviceid 
   */
  listServiceRates(serviceid: number,servicedate:Date, customerid?: number) {
    if (!customerid) customerid = 0;
    return this.auth.http.get(`${environment.host}rates/listbyservice/${serviceid}/${customerid}/${servicedate.toISOString()}`, this.auth.header());
  }
  listRatesFromRegionArray(regionids: number[], priority: number, mode: number) {
    return this.auth.http.post(environment.host + 'rates/listfromregionarray/' + priority + "/" + mode, regionids, this.auth.header());
  }

  /**
   * list an array of rates, one for each child of a banded region
   * @param regionid 
   * @param priority 
   * @param mode 
   */
  listRateRegions(regionid: number, priority: number, mode: number) {
    return this.auth.http.get(`${environment.host}rates/listregions/${regionid}/${priority}/${mode}`, this.auth.header());
  }

  /**
   * list all rates associated with a region
   * @param regionid 
   * @param mode 
   */
  listRatesRegion(regionid: number, mode: number) {
    return this.auth.http.get(`${environment.host}rates/listratesregion/${regionid}/${mode}`, this.auth.header());
  }

  updateRate(rate: Rate) {
    return this.auth.http.post(environment.host + 'rates/update', rate, this.auth.header());
  }

  createRateEnvelope(rateenvelope: RateEnvelope) {
    return this.auth.http.post(environment.host + 'rates/createrateenvelope', rateenvelope, this.auth.header());
  }

  updateRateEnvelope(rateenvelope: RateEnvelope) {
    return this.auth.http.post(environment.host + 'rates/updaterateenvelope', rateenvelope, this.auth.header());
  }

  /**
   * list an array of rates, one for each child of a banded region encapsulated in an envelope selected by the parent region
   * @param regionid 
   * @param priority 
   * @param mode 
   */
  listRateEnvelopes(regionid: number, priority: number, mode: number) {
    return this.auth.http.get(`${environment.host}rates/listrateenvelopes/${regionid}/${priority}/${mode}`, this.auth.header());
  }


  createRatePremium(ratepremium: RatePremium) {
    return this.auth.http.post(environment.host + 'rates/createratepremium', ratepremium, this.auth.header());
  }

  updateRatePremium(ratepremium: RatePremium) {
    return this.auth.http.post(environment.host + 'rates/updateratepremium', ratepremium, this.auth.header());
  }
  listRateCardOverrides(rateid: number) {
    return this.auth.http.get(`${environment.host}rates/listratecardoverrides/${rateid}`, this.auth.header());
  }
  createRateCardOverride(ratecardoverride: RateCardOverride) {
    return this.auth.http.post(environment.host + 'rates/createratecardoverride', ratecardoverride, this.auth.header());
  }

  updateRateCardOverride(ratecardoverride: RateCardOverride) {
    return this.auth.http.post(environment.host + 'rates/updateratecardoverride', ratecardoverride, this.auth.header());
  }
  createRatePriority(ratepriority: RatePriority) {
    return this.auth.http.post(environment.host + 'rates/createratepriority', ratepriority, this.auth.header());
  }

  updateRatePriority(ratepriority: RatePriority) {
    return this.auth.http.post(environment.host + 'rates/updateratepriority', ratepriority, this.auth.header());
  }
  listRatePriorities(companyid: number, regiontypeid: number, customerid?: number) {
    if (!customerid) customerid = 0;
    return this.auth.http.get(`${environment.host}rates/listratepriorities/${companyid}/${regiontypeid}/${customerid}`, this.auth.header());
  }


  createSurchargeType(surchargetype: SurchargeType) {
    return this.auth.http.post(environment.host + 'rates/createsurchargetype', surchargetype, this.auth.header());
  }

  updateSurchargeType(surchargetype: SurchargeType) {
    return this.auth.http.post(environment.host + 'rates/updatesurchargetype', surchargetype, this.auth.header());
  }
  listSurchargeTypes(providerid: number, appliesto: number) {
    if (!providerid) providerid = 0;
    return this.auth.http.get(`${environment.host}rates/listsurchargetypes/${providerid}/${appliesto}`, this.auth.header());
  }
  listSurchargeTypesAll(providerid: number) {
    return this.auth.http.get(`${environment.host}rates/listsurchargetypesall/${providerid}`, this.auth.header());
  }

  createSurcharge(surcharge: Surcharge) {
    return this.auth.http.post(environment.host + 'rates/createsurcharge', surcharge, this.auth.header());
  }

  updateSurcharge(surcharge: Surcharge) {
    return this.auth.http.post(environment.host + 'rates/updatesurcharge', surcharge, this.auth.header());
  }
  listSurchargesService(serviceid: number, appliesto: number) {
    return this.auth.http.get(`${environment.host}rates/listsurchargesservice/${serviceid}/${appliesto}`, this.auth.header());
  }
  listSurchargesPort(siteid: number, appliesto: number) {
    return this.auth.http.get(`${environment.host}rates/listsurchargesport/${siteid}/${appliesto}`, this.auth.header());
  }
  listSurchargesCompany(companyid: number, appliesto: number) {
    return this.auth.http.get(`${environment.host}rates/listsurchargescompany/${companyid}/${appliesto}`, this.auth.header());
  }
  createOrUpdateSurcharges(surcharges: FormArray) {
    return new Promise((resolve) => {
      let todo = surcharges.length;
      let done = 0;
      let success = true;
      if (surcharges.controls.length == 0) resolve({ success: success, message: surcharges });
      surcharges.controls.forEach(s => {
        this.createOrUpdateSurcharge(s.value, s.dirty).then((message: StatusMessage) => {
          if (message.success) {
            s.reset(message.message);
            done++;
            if (done == todo) {
              resolve({ success: success, message: surcharges });
            }
          }
          else {
            done++;
            success = false;
            if (done == todo) {
              resolve({ success: success, message: surcharges });
            }
          }
        }, err => {
          done++;
          success = false;
          if (done == todo) {
            resolve({ success: success, message: surcharges });
          }
        })
      })
    })

  }
  /**
   * create or update surcharge 
   * @param surcharge 
   */
  createOrUpdateSurcharge(surcharge: Surcharge, changes: boolean) {
    return new Promise((resolve) => {
      if (!changes) resolve({ success: true, message: surcharge });
      else {
        if (surcharge.id && surcharge.id > 0) {
          this.updateSurcharge(surcharge).subscribe((message: StatusMessage) => {
            if (message.success) resolve({ success: true, message: surcharge });
            else resolve({ success: false, message: surcharge });
          }, err => {
            resolve({ success: false, message: err });
          })
        }
        else {
          this.createSurcharge(surcharge).subscribe((message: StatusMessage) => {
            if (message.success) resolve({ success: true, message: message.message });
            else resolve({ success: false, message: message.message });
          }, err => {
            resolve({ success: false, message: err });
          })
        }
      }
    })
  }

  createSurchargePremium(surchargepremium: SurchargePremium) {
    return this.auth.http.post(environment.host + 'rates/createsurchargepremium', surchargepremium, this.auth.header());
  }

  updateSurchargePremium(surchargepremium: SurchargePremium) {
    return this.auth.http.post(environment.host + 'rates/updatesurchargepremium', surchargepremium, this.auth.header());
  }

  createCustomerRate(customerrate: CustomerRate) {
    return this.auth.http.post(environment.host + 'rates/createcustomerrate', customerrate, this.auth.header());
  }

  updateCustomerRate(customerrate: CustomerRate) {
    return this.auth.http.post(environment.host + 'rates/updatecustomerrate', customerrate, this.auth.header());
  }

  createCustomerSurcharge(customersurcharge: CustomerSurcharge) {
    return this.auth.http.post(environment.host + 'rates/createcustomersurcharge', customersurcharge, this.auth.header());
  }

  updateCustomerSurcharge(customersurcharge: CustomerSurcharge) {
    return this.auth.http.post(environment.host + 'rates/updatecustomersurcharge', customersurcharge, this.auth.header());
  }

  createPolygon(polygon: Polygon) {
    return this.auth.http.post(environment.host + 'rates/createpolygon', polygon, this.auth.header());
  }

  updatePolygon(polygon: Polygon) {
    return this.auth.http.post(environment.host + 'rates/updatepolygon', polygon, this.auth.header());
  }



  simpleRegion(region: Region): Region {
    let simpleregion = new Region(region.companyid, region.zonetype);
    simpleregion.code = region.code;
    simpleregion.id = region.id;
    simpleregion.basesiteid = region.basesiteid;
    simpleregion.continentid = region.continentid;
    simpleregion.drivingdistance = region.drivingdistance;
    simpleregion.featureid = region.featureid;
    simpleregion.gpscentreid = region.gpscentreid;
    simpleregion.hierarchyLevel = region.hierarchyLevel;
    simpleregion.name = region.name;
    simpleregion.parentId = region.parentId;
    simpleregion.recordStatus = region.recordStatus;
    simpleregion.regiontypeid = region.regiontypeid;
    simpleregion.sequence = region.sequence;
    simpleregion.zoom = region.zoom;
    return simpleregion;
  }
  createCentreToPort(centretoport: CentreToPort) {
    return this.auth.http.post(environment.host + 'companies/createcentretoport', centretoport, this.auth.header());
  }

  updateCentreToPort(centretoport: CentreToPort) {
    return this.auth.http.post(environment.host + 'companies/updatecentretoport', centretoport, this.auth.header());
  }
  getCentreToPort(consolidationsiteid, portlocodeid) {
    return this.auth.http.get(environment.host + 'companies/centretoport/' + consolidationsiteid + "/" + portlocodeid, this.auth.header());

  }
  createPortToPort(porttoport: PortToPort) {
    return this.auth.http.post(environment.host + 'companies/createporttoport', porttoport, this.auth.header());
  }

  updatePortToPort(porttoport: PortToPort) {
    return this.auth.http.post(environment.host + 'companies/updateporttoport', porttoport, this.auth.header());
  }
  getPortToPort(originlocodeid: number, destinationlocodeid: number, transportmode: number) {
    return this.auth.http.get(environment.host + 'companies/getporttoport/' + originlocodeid + "/" + destinationlocodeid + "/" + transportmode, this.auth.header());
  }
  /**provide a list of consolidation centres available to this service provider in the destination country - must be public
   * Currently a placholder method using country gps
   */
  getConsolidationCentres(serviceprovider: number, destinationcountry: Country) {
    return new Promise((resolve, reject) => {
      console.log("Placeholder method - get Consolidation Centre");
      let site = new Site();
      site.name = destinationcountry.name + " " + "consolidation";
      site.Address = new Address();
      site.Address.gpslat = destinationcountry.GPS.lat;
      site.Address.gpslong = destinationcountry.GPS.long;
      resolve({ success: true, message: [site] });

    })
  }
  /**
   * create a request for pasted data to be mapped to data objects
   * @param maprequest 
   */
  createMapRequest(maprequest: MapRequest) {
    return this.auth.http.post(environment.host + 'companies/createmaprequest', maprequest, this.auth.header());
  }

  findMap(maprequest: MapRequest) {
    return this.auth.http.post(environment.host + 'companies/findmaprequests', maprequest, this.auth.header())
  }

  createRateBracket(ratebracket: RateBracket) {
    return this.auth.http.post(environment.host + 'rates/createratebracket', ratebracket, this.auth.header());
  }
  createRateBrackets(ratebrackets: RateBracket[]) {
    return this.auth.http.post(environment.host + 'rates/createratebrackets', ratebrackets, this.auth.header());
  }

  updateRateBracket(ratebracket: RateBracket) {
    return this.auth.http.post(environment.host + 'rates/updateratebracket', ratebracket, this.auth.header());
  }
  listRateBracketsProvider(providerid: number) {
    return this.auth.http.get(environment.host + 'rates/listratebracketsprovider/' + providerid, this.auth.header());
  }



  public currencyView(id: number, appliesto: number): CurrencyView {
    if (!id){
      return {code:this.defaultCurrency.currencyCode,symbol:this.defaultCurrency.currencySymbol,flag:this.defaultCurrency.flag,appliesto:this.unitapplies(appliesto)};
    }
    else {
      let co = this.countries.filter(c => c.id == id)[0];
      return { code: co.currencyCode, symbol: co.currencySymbol, flag: co.flag, appliesto: this.unitapplies(appliesto) };
    }
  }
  public currencyCountry(id: number): Country {
    if (!id) return this.countries.filter(c => c.iso_alpha_2 == "GB")[0];
    else return this.countries.filter(c => c.id == id)[0];
  }

  public unitapplies(applies: number) {
    switch (applies) {
      case PriceAppliesTo.Shipment:
        return "shipment";
      case PriceAppliesTo.Weight:
        return "kg";
      case PriceAppliesTo.Volume:
        return "m3";
    }
  }

  /**
   * check db for address, if fails, reverse geocode, if fails use basic locode info.
   * @param locode
   * returns address
   * TODO: errors
   */
  locodeAddress(locode: Locode, sitetype: SiteTypes, country: Country) {
    return new Promise((resolve) => {
      this.getAddressFromPortSite(locode.id).subscribe((message: StatusMessage) => {
        if (message.success && message.message && message.message.Address) {
          resolve(message.message.Address);
        }
        else {
          let geostring = locode.locode1 + " " + locode.locode2 + " " + country.name + " " + SiteTypes[sitetype];
          this.geo.geocode(geostring).then((geomessage: StatusMessage) => {
            if (geomessage.success) {
              let geoplace: google.maps.places.PlaceResult[] = geomessage.message;
              resolve(this.tools.addressFromGooglePlace(geoplace, this.countries));
            }
            else resolve(null);

          })
        }

      })


    })
  }

  createMargin(margin: Margin) {
    return this.auth.http.post(environment.host + 'rates/createmargin', margin, this.auth.header());
  }

  updateMargin(margin: Margin) {
    return this.auth.http.post(environment.host + 'rates/updatemargin', margin, this.auth.header());
  }

  /**
   * provider specific margins, does not return public margins unless providerid=0
   * @param providerid 
   */
  listGlobalMargins(providerid?: number) {
    if (!providerid) providerid = 0;
    return this.auth.http.get(environment.host + 'rates/listmargins/' + providerid, this.auth.header());
  }
  /**
   * public and provider specific margins
   * @param providerid 
   */
  listGlobalMarginsAll(providerid: number) {
    return this.auth.http.get(environment.host + 'rates/listmarginsall/' + providerid, this.auth.header());
  }
  /**
   * public and provider specific margins for a collection of providers
   * @param providerids 
   */
  listGlobalMarginsAllMultipleProviders(providerids: number[]) {
    return this.auth.http.post(environment.host + 'rates/listmarginsallmultiple', providerids, this.auth.header());
  }

  /**
   * provider specific margins for a specific thing, does not return public margins unless providerid=0
   * @param providerid 
   */
  listGlobalMarginsSpecific(appliesto:number) {
    
    return this.auth.http.get(environment.host + 'rates/listmarginsspecific/'+appliesto, this.auth.header());
  }

  createDiscount(discount: Discount) {
    return this.auth.http.post(environment.host + 'rates/creatediscount', discount, this.auth.header());
  }

  updateDiscount(discount: Discount) {
    return this.auth.http.post(environment.host + 'rates/updatediscount', discount, this.auth.header());
  }
  /**
   * returns a list of discounts for the specified customer which if not provided defaults to zero.
   * @param customerid 
   */
  listDiscounts(customerid?: number) {
    if (!customerid) customerid = 0;
    return this.auth.http.get(environment.host + 'rates/listdiscounts/' + customerid, this.auth.header());
  }
  /**
     * returns a list of public and private discounts for the specified customer
     * @param customerid 
     */
  listDiscountsAll(customerid: number) {
    return this.auth.http.get(environment.host + 'rates/listdiscountsall/' + customerid, this.auth.header());
  }

  createMinimumMargin(minimummargin: MinimumMargin) {
    return this.auth.http.post(environment.host + 'rates/createminimummargin', minimummargin, this.auth.header());
  }

  updateMinimumMargin(minimummargin: MinimumMargin) {
    return this.auth.http.post(environment.host + 'rates/updateminimummargin', minimummargin, this.auth.header());
  }
  /**
   * returns either a customer specific (if exists) or public minimummargin object
   * @param customerid 
   */
  getMinimumMargin(customerid: number) {
    return this.auth.http.get(environment.host + 'rates/getminimummarginall/' + customerid, this.auth.header());
  }

  latestQuote(){
    return this.auth.http.get(environment.host + 'rates/latestquote/', this.auth.header());
  }

  createSavedQuote(savedquote: SavedQuote) {
    return this.auth.http.post(environment.host + 'rates/createsavedquote', savedquote, this.auth.header());
  }

  updateSavedQuote(savedquote: SavedQuote) {
    return this.auth.http.post(environment.host + 'rates/updatesavedquote', savedquote, this.auth.header());
  }
  listSavedQuotes() {
    return this.auth.http.get(environment.host + 'rates/listsavedquotes', this.auth.header());
  }
  listSavedQuotesStatus(status:number) {
    return this.auth.http.get(environment.host + 'rates/listsavedquotes/'+status, this.auth.header());
  }
  getSavedQuote(id: number) {
    return this.auth.http.get(environment.host + 'rates/getsavedquote/' + id, this.auth.header());
  }

  createSavedQuoteInput(savedquoteinput: SavedQuoteInput) {
    return this.auth.http.post(environment.host + 'rates/createsavedquoteinput', savedquoteinput, this.auth.header());
  }

  updateSavedQuoteInput(savedquoteinput: SavedQuoteInput) {
    return this.auth.http.post(environment.host + 'rates/updatesavedquoteinput', savedquoteinput, this.auth.header());
  }
  listSavedQuoteInputs() {
    return this.auth.http.get(environment.host + 'rates/listsavedquoteinputs', this.auth.header());
  }
  getSavedQuoteInput(id: number) {
    return this.auth.http.get(environment.host + 'rates/getsavedquoteinput/' + id, this.auth.header());
  }
  createQuoteEvent(quoteevent:QuoteEvent){
    return this.auth.http.post(environment.host + 'rates/createquoteevent', quoteevent, this.auth.header());
  }

updateQuoteEvent(quoteevent:QuoteEvent){
    return this.auth.http.post(environment.host + 'rates/updatequoteevent', quoteevent, this.auth.header());
  }
listQuoteEvents(){
    return this.auth.http.get(environment.host + 'rates/listquoteevents', this.auth.header());
  }
getQuoteEvent(id:number){
    return this.auth.http.get(environment.host + 'rates/getquoteevent/'+id, this.auth.header());
  }

  createUnsworth(unsworth:Unsworth[]){
    return this.auth.http.post(environment.host + 'services/createunsworth', unsworth, this.auth.header());
  }

  testRegionClone(){
    return this.auth.http.get(environment.host + 'clip/testclone', this.auth.header());
  }


  nodegeo(address?:string){
    let body = {address:'1 Sycamore Drive, Ballycumber'};
    return this.auth.http.post(environment.host+"quote/geo",body, this.auth.header());
  }

  //third party apis
  exchangeRates(base: string) {
    return this.auth.http.get("https://api.exchangeratesapi.io/latest?base=" + base);
  }

  //statics
  regionPolys(filename?: string) {
    if (!filename) filename = "countries_world";
    return this.auth.http.get("../assets/regionpolys/" + filename + ".json");
  }

  loadFeatureFromJson(filename?: string) {
    if (!filename) filename = "countries_world";
    return this.auth.http.get("../assets/regionpolys/" + filename + ".json");
  }

  loadJson(filename: string) {
    return this.auth.http.get("../assets/regionpolys/" + filename + ".json");
  }

  loadApiJson(filename: string, resource: string) {
    return this.auth.http.get(resource + filename);
  }


}
