<div class="f1 fl fdc h100">
    <div class="f1 fl halfwrap aic ovh">
        <div class="f1">
            <h1 class="pad" style="margin-left:30px;">Decrease quote times, improve productivity and your success rate with Freightology. The rate management
                and cost comparison tool that uses <strong>your data</strong></h1>
            <div class="fl jc aic">
                <div class="f1">
                    <img class="home-image deep-shadow"
                        src="assets/home.jpg"
                        alt="happy quoting">
                </div>
                <div class="f1 tal">
                    <h1>Easier & Quicker quoting for your Logistics business</h1>
                    <p>More efficient quoting for your Logistics business.</p>
                    <h3>Software developed by freight professionals, for freight professionals.</h3>
                    <p class="lh175">Using your rates, from your suppliers, our Platform gives you instant visibility of
                        all your service options for any shipment. Fully multimodal, the Platform can compare all
                        applicable modes of transport from one enquiry.
                    </p>
                    <a class="demo-link" href="http://freightology.io/book-a-demo" target="_blank">BOOK A DEMO
                        <mat-icon>arrow_forward</mat-icon></a>
                </div>
            </div>
        </div>
        <div class="f1 fl jc h100 ovh">
            <div class="f1 fl ovh">
                <cs-quoter [customer]=true></cs-quoter>
            </div>
        </div>
    </div>
</div>