<div *ngIf="portoption" style="margin-top: 10px; margin-bottom: 10px;">

    <div>
        <table class="natural-width">
            <col style="width:90%">
            <col style="width:10%">
            <tr *ngIf="portoption.service">
                <td colspan="2"> <strong class="tal">{{portoption.service.description}}</strong></td>
            </tr>
            <tr *ngIf="portoption.PointToPoints">
                <td colspan="2"><strong class="tal">Point To Point</strong></td>
            </tr>
            <ng-container *ngIf="portoption.PointToPoints && portoption.PointToPoints.length>0">
                <ng-container *ngFor="let p2p of portoption.PointToPoints">
                    <tr>
                        <td class="tal">{{p2p.name}}</td>
                        <td>
                            <span>{{p2p.discountedprice | currency:currencyCode}}</span>
                        </td>
                    </tr>
                    <ng-container *ngIf="p2p.Surcharges">
                        <!--TODO-->
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="portoption.originSites && portoption.originSites.length>0">
                <ng-container *htmlVar="portoption.originSites[portoption.originSiteIndex] as os">
                    <tr>
                        <td class="tal table-section" colspan="2">
                            Collection Charges
                        </td>
                    </tr>
                    <tr *ngIf="!os.hasCollection">
                        <td colspan="2">No Collection Option available, Customer to arrange delivery to port.</td>
                    </tr>
                    <tr *ngIf="os.hasCollection">
                        <td class="tal">{{os.priorities[os.selectedPriority].collection.description}}</td>
                        <td>
                            <span
                                *ngIf="os.priorities[os.selectedPriority].collection.include">{{os.priorities[os.selectedPriority].collection.discountedprice
                                | currency:currencyCode}}</span>
                            <span *ngIf="!os.priorities[os.selectedPriority].collection.include">(inc)</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="tal table-section" colspan="2">
                            Export Port Charges
                        </td>
                    </tr>
                    <tr *ngFor="let surch of os.surcharges">
                        <td class="tal">{{surch.description}}</td>
                        <td>
                            <span *ngIf="surch.include">{{surch.discountedprice | currency:currencyCode}}</span>
                            <span *ngIf="!surch.include">(inc)</span>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="portoption.servicerates && portoption.servicerates.length>0">
                <tr>
                    <td class="tal table-section" colspan="2" class="trcolor">
                        Port to Port Charges
                    </td>
                </tr>
                <tr *ngFor="let sr of portoption.servicerates">
                    <td class="tal">{{sr.description}}</td>
                    <td>
                        <span *ngIf="sr.include">{{sr.discountedprice | currency:currencyCode}}</span>
                        <span *ngIf="!sr.include">(inc)</span>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="portoption.servicesurcharges && portoption.servicesurcharges.length>0">
                <tr class="trcolor">
                    <td class="tal table-section" colspan="2" class="trcolor">
                        Port to Port Surcharges
                    </td>
                </tr>
                <tr *ngFor="let ss of portoption.servicesurcharges">
                    <td class="tal">{{ss.description}}</td>
                    <td>
                        <span *ngIf="ss.include">{{ss.discountedprice | currency:currencyCode}}</span>
                        <span *ngIf="!ss.include">(inc)</span>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="portoption.destinationSites && portoption.destinationSites.length>0">
                <ng-container *htmlVar="portoption.destinationSites[portoption.destinationSiteIndex] as ds">
                    <tr>
                        <td class="tal table-section" colspan="2" class="trcolor">
                            Import Port Charges
                        </td>
                    </tr>
                    <tr *ngIf="ds.surcharges.length==0">
                        <td>

                        </td>
                        <td>
                            (inc)
                        </td>
                    </tr>
                    <tr *ngFor="let surch of ds.surcharges">
                        <td class="tal">{{surch.description}}</td>
                        <td>
                            <span *ngIf="surch.include">{{surch.discountedprice | currency:currencyCode}}</span>
                            <span *ngIf="!surch.include">(inc)</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="tal table-section" colspan="2" class="trcolor">
                            Delivery Charges
                        </td>
                    </tr>
                    <tr *ngIf="!ds.hasCollection">
                        <td colspan="2">No Delivery Option available, Customer to arrange collection from port.</td>
                    </tr>
                    <tr *ngIf="ds.hasCollection">
                        <td class="tal">{{ds.priorities[ds.selectedPriority].collection.description}}</td>
                        <td>
                            <span
                                *ngIf="ds.priorities[ds.selectedPriority].collection.include">{{ds.priorities[ds.selectedPriority].collection.discountedprice
                                | currency:currencyCode}}</span>
                            <span *ngIf="!ds.priorities[ds.selectedPriority].collection.include">(inc)</span>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="portoption.quotesurcharges && portoption.quotesurcharges.length>0">
                <tr>
                    <td class="tal table-section" colspan="2" class="trcolor">
                        Administration Surcharges
                    </td>
                </tr>
                <tr *ngIf="combinedAdmin.length == 0 && balanceAdmin ==0">
                    <td></td>
                    <td>(inc)</td>
                </tr>
                <tr *ngFor="let qs of combinedAdmin">
                    <td class="tal">{{qs.description}}</td>
                    <td>
                        <span *ngIf="qs.include">{{qs.discountedprice | currency:currencyCode}}</span>
                        <span *ngIf="!qs.include">(inc)</span>
                    </td>
                </tr>
                <tr *ngIf="balanceAdmin>0">
                    <td class="tal">Administration Fee</td>
                    <td>
                        <span>{{balanceAdmin | currency:currencyCode}}</span>
                    </td>
                </tr>
            </ng-container>
            <tr class="total">
                <td class="tal field-title total">Total</td>
                <td class="total">
                    <strong>{{portoption.total.discountedprice | currency:currencyCode}}</strong>
                </td>
            </tr>
        </table>

    </div>
</div>