<div>
    <h3>Zoning Options</h3>
    <div *ngIf="parentRegion && regiongroups.length>0">
        <p>{{parentRegion.name}}'s countries can be divided up a number of ways.</p>
        <p>Please choose which option you would prefer to start with.</p>
        <p>You can move countries around and rename groupings after deciding.</p>
    </div>
    <div *ngIf="selectedGroup">
        <mat-radio-group name="grouptype" [(ngModel)]="selectedGroup">
            <div class="pad" *ngFor="let group of regiongroups">
                <mat-radio-button [value]="group">{{group.description}}</mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
    <div class="fl aic mt jc" *ngIf="selectedGroup">
        <mat-form-field class="mr" *ngIf="selectedGroup.id<0">
            <mat-label>How many bands?</mat-label>
            <input matInput type="number" placeholder="name" [(ngModel)]="bands" min="1">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="cancel()" type="button" class="mr">Cancel</button>
        <button mat-raised-button color="primary" (click)="create()" type="button">Create</button>
    </div>
</div>
