import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { StatusMessage, Site, CompanyAssociation } from 'src/app/models/user';
import { View, Priority, BandedRegion } from 'src/app/models/ui';
import { RateEnvelope, TransportModes, Region, Rate, RateBreak, RateBreakEntry } from 'src/app/models/rates';
import { ToolsService } from 'src/app/services/tools.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cs-rate-editor-regions',
  templateUrl: './rate-editor-regions.component.html',
  styleUrls: ['./rate-editor-regions.component.scss']
})
export class RateEditorRegionsComponent implements OnInit {

  @Input() view: View;
  //@Input() siteid:number;

  public showcard = false;
  public showimport = false;
  public editmode = false;

  public rates: Rate[] = [];

  public envelopes: RateEnvelope[] = [];
  public selectedEnvelope: RateEnvelope;

  public transportmodes = TransportModes;
  public regions: BandedRegion[] = [];
  //public rateCardOverrides: RateCardOverride[] = [];
  //public adjustmentGrid: Array<Array<RateCardOverride>> = [];
  public selectedRegion: BandedRegion;

  public world = false;

  public allVersions: Region[] = [];
  public versions: Region[] = [];
  public selectedVersion: Region;
  public fixedBreaks: RateBreakEntry[] = [];

  public selectedPriority: Priority;
  public selectedMode = 0;

  public homeSite: Site;

  public selectedZone: Region;

  public rate: Rate;
  public bandPremium = 1;

  public currencyString = "£";
  public currencyCode = "GBP";

  public cardedit = false;
  public zoneindex = 0;

  //public panelheight = 550;

  constructor(public data: DataService, public tools: ToolsService, public events: EventsService) { }

  ngOnInit(): void {
    if (this.data.selectedSupplier) {
      this.homeSite = this.data.selectedSupplier._baseSite;
    }
    else this.homeSite = this.data.basesite;
    this.selectedPriority = this.data.priorities[0];
    this.startIfEmpty();
    this.events.createRegion.subscribe(() => {
      if (!this.selectedVersion) {
        this.startIfEmpty();
      }
    })
    this.events.cancelRateImport.subscribe(() => {
      this.showimport = false;
    })
    this.events.rateCreation.subscribe((ratearray: Rate[]) => {
      this.showimport = false;
      this.listRates();
    })
    this.events.closeCard.subscribe(() => {
      this.showcard = false;
    })
    this.events.companyselect.subscribe((co: CompanyAssociation) => {
      if (!this.data.selectedSupplier || this.data.selectedSupplier.Provider.id != co.id) {
        this.data.selectedSupplier = co;
        this.data.selectedSupplier._baseSite = this.data.selectedSupplier.Provider.Sites[0];
        this.startIfEmpty();
      }


    })
  }
  startIfEmpty() {
    this.getBands().then((success: boolean) => {
      if (success) {
        this.listRates();
      }
    });
  }

  listRates() {


    this.data.listRateEnvelopes(this.selectedVersion.id, this.selectedPriority.id, this.selectedMode).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.envelopes = message.message;
        //console.log(message.message);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })

  }
  help() {
    let bob = "bob";
    let check = this.rates;
  }
  selectRate(rate: Rate) {
    this.rate = rate;
    this.cardedit = false;
    //this.fixedBreaks = this.calcBreaks();
    this.orderBandPremia();
    this.buildAdjustmentGrid();

    console.log(rate);
  }
  selectEnvelope(envelope: RateEnvelope) {
    this.selectedEnvelope = envelope;
    this.selectedEnvelope.Rates.forEach(r => {
      let region = this.selectedVersion.children.filter(c => c.id == r.regionid)[0];
      r.name = region.name;
    })
  }
  /**
   * order and code band premiums as regions (order of regions may change after creation)
   * @param rate 
   */
  orderBandPremia() {
    /*
    if (this.selectedVersion) {
      let index = 0;
      let orderedPremiums: BandPremium[] = [];
      this.selectedVersion.children.forEach(ch => {
        let bp = this.rate.BandPremia.filter(p => p.regionid == ch.id);
        if (bp.length > 0) {
          bp[0]._regioncode = ch.code ? ch.code : index.toString();
          orderedPremiums.push(bp[0]);
        }
        else {
          let dummy = new BandPremium();
          dummy._regioncode = ch.code ? ch.code : index.toString();
          dummy.premiumpercent = 1;
          dummy.premiumvalue = 0;
          dummy.rateid = this.rate.id;
          dummy.regionid = ch.id;
          orderedPremiums.push(dummy);
        }
        index++;
      })
      this.rate.BandPremia = orderedPremiums;
    }*/

  }
  buildAdjustmentGrid() {
    /*
        this.adjustmentGrid = [];
        let basearray: RateCardOverride[] = [];
        let bandindex = 0;
        this.selectedVersion.children.forEach(ch => {
          let rcobase = new RateCardOverride();
          rcobase.rateid = this.rate.id;
          rcobase.regionid = ch.id;
          rcobase.index = 0;
          rcobase._clean = this.rate.BandPremia[bandindex].premiumpercent * this.rate.baseprice + this.rate.BandPremia[bandindex].premiumvalue * 1;
          if (this.rate.RateCardOverrides) {
            let adjusts = this.rate.RateCardOverrides.filter(rc => rc.regionid == ch.id && rc.index == 0);
            if (adjusts.length > 0) {
              let adjust = adjusts[0];
              rcobase.id = adjust.id;
              rcobase.weightmin = adjust.weightmin * 1;
              rcobase.weightmax = adjust.weightmax * 1;
              rcobase.adjustment = adjust.adjustment * 1;
            }
            else {
              rcobase.weightmin = this.rate.baseIncludesWeight * 1;
              rcobase.adjustment = 0;
            }
          }
          else {
            rcobase.weightmin = this.rate.baseIncludesWeight * 1;
            rcobase.adjustment = 0;
          }
          basearray.push(rcobase);
          bandindex++;
        })
        this.adjustmentGrid.push(basearray);
        let index = 1;
        this.breaks.forEach(br => {
          let array: RateCardOverride[] = [];
          let childbandindex = 0;
          this.selectedVersion.children.forEach(ch => {
            let rco = new RateCardOverride();
            rco.rateid = this.rate.id;
            rco.index = index;
            rco.regionid = ch.id;
            rco._clean = this.rate.BandPremia[childbandindex].premiumpercent * br.price + this.rate.BandPremia[childbandindex].premiumvalue * 1;
    
            if (this.rate.RateCardOverrides) {
              let adjusts = this.rate.RateCardOverrides.filter(rc => rc.regionid == ch.id && rc.index == index);
              if (adjusts.length > 0) {
                let adjust = adjusts[0];
                rco.id = adjust.id;
                rco.weightmin = adjust.weightmin;
                rco.weightmax = adjust.weightmax;
                rco.adjustment = adjust.adjustment;
              }
              else {
                rco.weightmin = br.weight;
                rco.adjustment = 0;
              }
            }
            else {
              rco.weightmin = br.weight;
              rco.adjustment = 0;
            }
    
            array.push(rco);
            childbandindex++;
          })
          this.adjustmentGrid.push(array);
          index++;
        })*/
  }

  getBands() {
    return new Promise((resolve) => {
      //todo this can be abstracted when countries are refactored to be regions.
      let base = this.data.basesite.id;
      if (this.data.selectedSupplier) {
        base = this.data.selectedSupplier._baseSite.id;
      }

      switch (this.view.id) {
        case 0:
          this.world = false;

          this.data.listCountryRegionsHavingBands(base).subscribe((message: StatusMessage) => {
            if (message.success) {
              this.loadBands(message, 3).then((success: boolean) => {
                resolve(success);
              });
            }
            else {
              this.tools.gracefulError(message.message);
              resolve(false);
            }
          },
            err => {
              this.tools.gracefulError(err)
              resolve(false);
            }
          );
          break;
        case 1:
          this.world = false;
          this.data.listContinentsHavingBands(base).subscribe((message: StatusMessage) => {
            if (message.success) {
              this.loadBands(message, 2).then((success: boolean) => {
                resolve(success);
              });
            }
            else {
              this.tools.gracefulError(message.message);
              resolve(false);
            }
          },
            err => {
              this.tools.gracefulError(err)
              resolve(false);
            }
          );
          break;
        case 2:
          //this.world = true;
          //this.data.listRegionsStartup(1, null,this.data.Company.id).subscribe((message: StatusMessage) => {
          //if (message.success) {
          //  this.versions = message.message;
          //  if (this.versions.length > 0) this.selectedVersion = this.versions[0];
          //}
          //})
          break;
      }
    })

  }

  loadBands(message: StatusMessage, level: number) {
    return new Promise((resolve) => {
      let queryres = message.message;
      let result = queryres.res[1];
      this.regions = result.rows;
      if (this.regions.length) {
        this.selectedRegion = this.regions[0];
        this.listRegions(level).then(success => {
          if (success) resolve(true);
          else resolve(false);
        })
      }
    })

  }
  listRegions(level: number) {
    return new Promise((resolve) => {
      this.data.listRegionsStartup(level, this.selectedRegion.id, this.homeSite.id).subscribe((message: StatusMessage) => {
        if (message.success) {
          this.versions = message.message;
          if (this.versions.length > 0) this.selectedVersion = this.versions[0];
          resolve(true);
        }
        else {
          this.tools.gracefulError(message.message);
          resolve(false);
        }
      }, err => {
        this.tools.gracefulError(err);
        resolve(false);
      }

      );
    })
  }

  addRate() {
    let newenvelope = new RateEnvelope(this.selectedVersion.id);
    newenvelope.name = "New Rate";
    this.selectedVersion.children.forEach(ch => {
      let rate = new Rate(ch.id, this.selectedPriority.id, this.selectedMode);
      rate.parentregionid = this.selectedVersion.id;
      rate.createdAt = new Date();
      rate.name = ch.name;
      newenvelope.Rates.push(rate);
    })
    this.envelopes.push(newenvelope);
    this.selectedEnvelope = newenvelope;
    this.editmode = true;
  }

  addDummyRate() {
    /*this.rate = new Rate(this.selectedVersion.id, this.selectedPriority.id);
    this.selectedVersion.children.forEach(ch => {
      let bandpremium = new BandPremium();
      bandpremium.regionid = ch.id;
      this.rate.BandPremia.push(bandpremium);
    })
    this.rate.baseprice = 12.5;
    this.rate.baseIncludesVolume = 1;
    this.rate.baseIncludesWeight = 5;
    this.rate.createdAt = new Date();
    this.rate.name = "Example";
    for (let i = 1; i < 4; i++) {
      let rb = new RateBreak();
      rb.maxweight = 5 + i * 5;
      rb.maxvolume = 1;
      rb.price = 2.5;
      rb.weightbreak = 1;
      this.rate.RateBreaks.push(rb);
    }
    //this.buildAdjustmentGrid();
*/
  }
  showCard() {
    this.showcard = !this.showcard;
    this.showimport = false;
  }
  showImport() {
    this.showcard = false;
    this.showimport = !this.showimport;
  }
  editCard() {
    this.cardedit = !this.cardedit;
  }
  addBreak() {
    let ratebreak = new RateBreak();
    this.rate.RateBreaks.push(ratebreak);

  }
  changeHome(e) {

  }
  changeZone(e) {
    this.zoneindex = e.index;
  }
  changeRegion(e) {
    this.clearDown();
    let level = 3;
    if (this.view.id == 1) level = 2;

    this.listRegions(level).then((success: boolean) => {
      if (success) {
        this.listRates();
      }
    });
  }
  changeVersion(e) {
    this.clearDown();
    this.listRates();
  }
  changePriority(e) {
    this.clearDown();
    this.listRates();
  }
  changeMode(e) {
    this.clearDown();
    this.listRates();
  }
  editRate() {
    this.editmode = !this.editmode;
  }
  clearDown() {
    this.rate = null;
    //this.adjustmentGrid = [];
    this.cardedit = false;
  }

  get breaks() {
    return null;
  }

  hackNumber(rb: RateBreak) {
    rb.maxvolume = parseFloat((rb.maxvolume || 0).toString());
    rb.maxweight = parseFloat((rb.maxweight || 0).toString());
    rb.price = parseFloat((rb.price || 0).toString());
    rb.volumebreak = parseFloat((rb.volumebreak || 0).toString());
    rb.weightbreak = parseFloat((rb.weightbreak || 0).toString());

  }

  bandValueChange(e, which, index) {
    console.log(e, which, index);
    if (which == 0) {
      let uplift = parseFloat(e.srcElement.value);

      if (index == 0) {
        let multiplier = 1 + (uplift / 100);
        for (let i = 1; i < this.rate.BandPremia.length; i++) {
          if (i == 1) this.rate.BandPremia[i].premiumpercent = multiplier;
          else {
            let val = this.rate.BandPremia[i - 1].premiumpercent * multiplier;
            let rounded = Math.round(val * 100) / 100;
            this.rate.BandPremia[i].premiumpercent = rounded;
          }
        }
      }
    }
    else {
      let uplift = parseFloat(e.srcElement.value);

      if (index == 0) {
        let adder = uplift;
        for (let i = 1; i < this.rate.BandPremia.length; i++) {
          this.rate.BandPremia[i].premiumvalue = adder * i;
        }
      }
    }

  }
  logbps() {
    console.log(this.rate.BandPremia);
  }
  saveRate() {

    alert("Update or Create Rate Envelope");
    //this.rate.BandPremia[0].premiumpercent = 1;
    //this.rate.BandPremia[0].premiumvalue = 0;
    this.selectedEnvelope.Rates.forEach(r => {

    })
    /* deprecated with the creation of rate envelopes
    this.data.createRate(this.rate).subscribe((message: StatusMessage) => {
      if (message.success) {
        this.rate = message.message;
        this.selectRate(this.rate);
        this.rates.unshift(this.rate);
      }
      else {
        this.tools.gracefulError(message.message);
      }
    }, err => {
      this.tools.gracefulError(err);
    })*/
  }
  allowedit(region: Region, index: number, e) {
    console.log(e);
    let div = e.srcElement;

    let tb = e.srcElement.parentNode.getElementsByTagName("input")[0];
    tb.style.zIndex = 10;
    let current = div.textContent.substring(1);
    tb.value = parseFloat(current);

  }
  xadjustment(e, adjust: any) {
    let input = e.srcElement;
    let clean = parseFloat(input.placeholder);
    let newvalue = Math.round((parseFloat(input.value) - clean) * 100) / 100;
    adjust.adjustment = newvalue;
    if (adjust.id) {
      this.data.updateRateCardOverride(adjust).subscribe(() => {

      })
    }
    else {
      this.data.createRateCardOverride(adjust).subscribe((message: StatusMessage) => {
        if (message.success) {
          adjust.id = message.message.id;
          this.rate.RateCardOverrides.push(adjust);
        }
        else {
          this.tools.gracefulError(message.message);
        }

      }, err => {
        this.tools.gracefulError(err);
      })
    }
    console.log(adjust);
  }
  copyRate() {
    let newenv = new RateEnvelope(this.selectedEnvelope.regionid);
    newenv.name = this.selectedEnvelope.name + " (1)";
    newenv.version = 1;
    this.selectedEnvelope.Rates.forEach(r => {
      let newrate = this.tools.rateClone(r);
      newenv.Rates.push(newrate);
    })
    this.envelopes.push(newenv);
    this.selectedEnvelope = newenv;
  }
  logBreaks() {
    console.log(this.breaks);
  }

}
