import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public now = new Date().getFullYear();
  
  constructor(public data:DataService) { }

  ngOnInit(): void {
  }
  logPerf(){
    console.log(this.data.performance);
  }
}
