import { Component, OnInit } from '@angular/core';
import { GoogleService } from 'src/app/services/google.service';
import { StatusMessage } from 'src/app/models/user';

@Component({
  selector: 'cs-global-distance',
  templateUrl: './global-distance.component.html',
  styleUrls: ['./global-distance.component.scss']
})
export class GlobalDistanceComponent implements OnInit {
  geoResult;
  origin:string = "";
  destination:string = "";
  isLoading = false;

  constructor(public google:GoogleService) { }

  get formvalid(){
    return this.origin.length>0 && this.destination.length>0;
  }

  ngOnInit(): void {
  }
  calculate(){
    this.isLoading = true;
    this.google.geocode2(this.origin,this.destination).then((message:StatusMessage)=>{
      this.geoResult = message.message;
      this.isLoading = false;
    })
  }

}
